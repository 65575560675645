import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7782 12.7022C21.7782 15.3657 20.409 17.712 18.3294 19.0963C18.7103 19.2287 19.0844 19.3797 19.4481 19.5493C20.1568 19.8818 20.8293 20.2857 21.4454 20.764C22.0013 21.1949 22.5075 21.6835 22.9536 22.2298C23.096 22.4046 23.2316 22.5861 23.3619 22.7727C23.0994 23.0238 22.7579 23.19 22.3856 23.2409C22.3067 23.2528 22.2261 23.2579 22.1437 23.2579H19.5733C18.9745 23.2579 18.4993 23.5243 18.1938 23.9127C17.631 24.627 17.6465 25.7585 18.5405 26.3998L20.62 27.8945C21.0902 28.2321 21.351 28.7682 21.3442 29.3213H4.23026C3.55079 29.3213 3 28.7766 3 28.1049C3 24.8815 4.40184 22.4063 6.51749 20.764C7.44919 20.0413 8.50958 19.4865 9.63346 19.0963C7.55385 17.712 6.18461 15.3657 6.18461 12.7022C6.18461 8.44902 9.67465 5 13.9814 5C18.2882 5 21.7782 8.44902 21.7782 12.7022ZM26.5312 21.3985C26.4522 21.1577 26.109 21.1577 26.0301 21.3985L24.8599 24.9561C24.8256 25.0647 24.7243 25.1359 24.6094 25.1359H20.8259C20.5703 25.1359 20.4639 25.46 20.6698 25.6076L23.7326 27.808C23.8252 27.8741 23.8629 27.9912 23.8287 28.0981L22.6585 31.6574C22.5795 31.8983 22.8575 32.0968 23.0634 31.9492L26.1262 29.7488C26.2188 29.6826 26.3424 29.6826 26.435 29.7488L29.4978 31.9492C29.6008 32.0238 29.7226 32.0102 29.8084 31.9474C29.8924 31.8864 29.9422 31.7778 29.9027 31.6574L28.7325 28.0981C28.6983 27.9912 28.736 27.8741 28.8286 27.808L31.8914 25.6076C32.0973 25.46 31.9909 25.1359 31.7353 25.1359H27.9518C27.8369 25.1359 27.7356 25.0647 27.7013 24.9561L26.5312 21.3985Z"
      fill="currentColor"
    />
  </svg>
);
