import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import Skeleton from '../../../../components/skeleton';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import Spinner from '../../../../components/spinner';
import Trans from '../../../../components/trans';

const SponsorButtonsControl = ({
  sponsor,
  loading,
  userSponsors,
  loadingSilent,
  requestJoinSponsor,
  cancelRequestJoinSponsor,
  userTeam,
  getSponsor,
  role,
}) => {
  const { id } = useParams();
  const userSponsor = userSponsors?.find((item) => item.id === id);

  const userTeamId = userTeam && userTeam.id;
  const teamState = sponsor?.team_state;

  const goToSettings = () =>
    history.push({
      pathname: Pages.organizers.settings.main.replace(':id', id),
      state: sponsor.id,
    });
  const handleRequestJoinTeam = async () => {
    await requestJoinSponsor(id, userTeamId);
    await getSponsor(id);
  };

  const handleCancelRequestJoinTeam = async () => {
    await cancelRequestJoinSponsor(id, userTeamId);
    await getSponsor(id);
  };

  return loading ? (
    <Skeleton className="sk-owner-btn" />
  ) : (
    <>
      {userSponsor && userSponsor.user_role ? (
        <Button onClick={goToSettings} className="setting" variant="second">
          {Trans('actions.settings')}
        </Button>
      ) : null}

      {role && (teamState === 'participant' || teamState === 'guest') ? (
        <Button onClick={handleCancelRequestJoinTeam} className="leave" variant="second">
          {loadingSilent ? <Spinner size={20} /> : Trans('organizers.members.leave')}
        </Button>
      ) : null}

      {role && teamState === 'request' ? (
        <Button onClick={handleCancelRequestJoinTeam} className="leave" variant="second">
          {loadingSilent ? <Spinner size={20} /> : Trans('organizers.members.cancelApplication')}
        </Button>
      ) : null}

      {role && !teamState ? (
        <Button onClick={handleRequestJoinTeam} className="enter" variant="save">
          {loadingSilent ? <Spinner size={20} /> : Trans('organizers.members.apply')}
        </Button>
      ) : null}
    </>
  );
};

export default SponsorButtonsControl;
