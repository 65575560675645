import axios from 'axios';

const { host } = window.location;
const baseURL =
  host === 'new.airsoft.net' || host === 'airsoft.net'
    ? 'https://api.airsoft.net/v1'
    : 'https://devapi.airsoft.net/v1';

export const cancelTokenSource = axios.CancelToken.source();

const instance = axios.create({
  baseURL,
  cancelToken: cancelTokenSource.token,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const postAuth = (data) => instance.post('/auth', data);
export const postGetUserData = (data) => instance.post('/users', data);
export const postSaveProfile = (data) => instance.post('/profile', data);
export const postPrivate = (data) => instance.post('/user_private', data);
export const postCamouflages = (data) => instance.post('/team/camouflage', data);
export const postSecurity = (data) => instance.post('/user_security', data);
export const postRegion = (data) => instance.post('/region/region', data);
export const postSocial = (url, data) => instance.post(`/user_social/${url}`, data);
export const postUserAction = (url, data) => instance.post(`/user_action/${url}`, data);
export const postTeams = (url, data) => instance.post(`/team/${url}`, data);
