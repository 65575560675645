import React from 'react';
import { isEmpty } from 'lodash';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import modalService from '../../../../services/modalService';

const SocialMobile = ({ providers, social, handleUnLink }) => {
  const handleOpenModal = (type, action, soc, url) =>
    modalService.openBottomMenu({
      type,
      action,
      social: soc,
      url,
      unlink: handleUnLink,
      providers,
    });

  return (
    <div className="settings-links-mobile">
      {!isEmpty(social) &&
        !isEmpty(providers) &&
        providers.map((item) => {
          return (
            <div key={item.id} className="settings-links-mobile__row">
              <img src={item.icon} className="settings-links-mobile__icon" alt={item.title} />
              <div className="settings-links-mobile__row__wrap">
                <span className="settings-links-mobile__name">{item.title}</span>

                {social[item.id].network_id ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={social[item.id].network_link}
                    className="settings-links-mobile__link"
                  >
                    {`@id${social[item.id].network_id}`}
                  </a>
                ) : (
                  <span className="settings-links-mobile__link">Привяжите аккаунт</span>
                )}
              </div>

              {social[item.id].network_id ? (
                <Button
                  onClick={() => handleOpenModal('social', 'unlink', item.id)}
                  className="settings-links-mobile__actions"
                  variant="icon"
                >
                  <Icon type="moreMobile" />
                </Button>
              ) : (
                <Button
                  onClick={() => handleOpenModal('social', 'link', item.id, item.url)}
                  className="settings-links-mobile__actions"
                  variant="icon"
                >
                  <Icon type="add" />
                </Button>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default SocialMobile;
