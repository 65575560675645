import React, { useEffect, useRef, useState } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { usersActions } from '../../../../store/users';
import { Pages } from '../../../../routes/pages';
import useDebounce from '../../../../hooks/useDebounce';
import { globalCancelFetch } from '../../../../api/v2';

import UsersList from '../../components/usersList';

const OutgoingFriends = ({ search, ...params }) => {
  const { isFilterOpened, handleVisibleFilter } = params;

  const { getOutgoingFriends, cancelOutgoing, clearUsers } = useActions(usersActions);
  const [page, setPage] = useState(1);
  const { outgoing, outgoingPagination, loading } = useSelector((state) => state.users);
  const containerEl = useRef(null);
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    getOutgoingFriends({ page, query: debouncedSearchTerm || null });
    setPage(1);
  }, [debouncedSearchTerm]);

  useEffect(
    () => () => {
      clearUsers();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const fetchPaginated = () => {
    setPage(page + 1);
    getOutgoingFriends({ page: page + 1, query: debouncedSearchTerm || null });
  };

  const hasMore =
    (outgoingPagination && Number(outgoingPagination.current_page)) <
    (outgoingPagination && Number(outgoingPagination.total_pages));

  useEffect(() => {
    if (
      outgoing.length &&
      hasMore &&
      document.body.offsetHeight - 220 > containerEl.current.offsetHeight
    ) {
      fetchPaginated();
    }
  }, [outgoing]);

  const handleCancelRequest = async (id) => {
    await cancelOutgoing(id);
  };

  return (
    <UsersList
      containerRef={containerEl}
      users={outgoing}
      fetchPaginated={fetchPaginated}
      hasMore={hasMore}
      loading={loading}
      removeFriend={handleCancelRequest}
      disableFilters
      page={Pages.users.outRequests}
      isFilterOpened={isFilterOpened}
      handleVisibleFilter={handleVisibleFilter}
    />
  );
};
export default OutgoingFriends;
