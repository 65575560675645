import { catchWrapper } from '../../../helpers/utility';
import { postSecurity } from '../../../api';

export default (data) => async (dispatch) => {
  dispatch({ type: 'SETTINGS/SUBMIT_EMAIL/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'submitEmailCode');
  formData.append('email', data.email);
  formData.append('password', data.password);
  const result = await catchWrapper(postSecurity(formData));
  if (result && result.result) {
    dispatch({ type: 'SETTINGS/SUBMIT_EMAIL/SUCCESSFUL' });
    dispatch({ type: 'SETTINGS/CHANGE_STEP/SECURITY', payload: 'codeEmail' });
  } else {
    dispatch({ type: 'SETTINGS/SUBMIT_EMAIL/FAILED', payload: result.message[0] });
  }
};
