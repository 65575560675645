import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import he from 'he';
import ModalHeader from '../components/modalHeaderBlog';
import CustomInput from '../../components/input';
import './styles.scss';
import loadOptionsOrg from '../addBlogVideo/loadOptionsOrg';
import loadOptionsBlogger from '../addBlogVideo/loadOptionsBlogger';
import SelectAsyncPaginate from '../../components/selectAsyncPaginate';

const init = {
  title: null,
  link: null,
  description: null,
};

const AddBlogAlbumModal = ({
  onCreateBlogAlbum,
  onCancel,
  getBlogAlbumList,
  blogId,
  clearBlogAlbums,
  onEditBlogAlbum,
  isEdit,
  album,
  parentType,
}) => {
  const [state, setState] = useState(init);
  const selectedSponsors = [];
  const { errors } = useSelector((s) => s?.blog);
  useEffect(() => {
    setState({ ...state, title: album?.profile?.title });
  }, [isEdit]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  const filterOption = (option) => {
    const { value } = option;
    const t = selectedSponsors.find((s) => s.id === value.id);
    return t ? null : value;
  };
  const onSubmit = async () => {
    // const data = { title: he.decode(state?.title || ''), id: Number(album?.id) };
    const data = {
      title: he.decode(state?.title || ''),
      id: isEdit ? Number(album?.id) : Number(blogId),
      parent_id: Number(blogId),
      event_id: state.eventId,
      type: parentType,
    };
    if (isEdit) {
      await onEditBlogAlbum(data);
    } else {
      await onCreateBlogAlbum(data);
    }
    await clearBlogAlbums();
    await getBlogAlbumList({ page: 1, parentId: Number(blogId), parentType });
    await onCancel();
  };
  const handleOnChangeSelect = (value) => {
    setState({ ...state, eventId: Number(value?.id) });
  };
  return (
    <div className="modal-wrapper">
      <ModalHeader
        title={isEdit ? 'Изменить название' : 'Новый альбом'}
        buttonTitle={isEdit ? 'Сохранить' : 'Создать альбом'}
        onSubmit={onSubmit}
        onCancel={onCancel}
        close={onCancel}
        disabledButton={!state?.title?.length}
        getBlogAlbumList={getBlogAlbumList}
        clearBlogAlbums={clearBlogAlbums}
      />
      <div className="modal-content">
        <CustomInput
          onChange={handleChangeInput}
          value={he.decode(state.title || '')}
          label="Название"
          labelPosition="top"
          placeholder="Укажите название альбома"
          name="title"
          required
          error={errors?.title}
        />
        <SelectAsyncPaginate
          name="event"
          loadOptions={parentType === 'org' ? loadOptionsOrg : loadOptionsBlogger}
          onChange={handleOnChangeSelect}
          filterOption={filterOption}
          label="Привязать к мероприятию"
          placeholder="Привязать к мероприятию"
          required
          error={errors?.event}
          labelPosition="top"
        />
      </div>
    </div>
  );
};

export default AddBlogAlbumModal;
