import React from 'react';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import CamouflageCard from '../../../../components/camouflageCard';
import SkeletonGrid from '../../../../../components/skeletonsGrid';
import Icon from '../../../../../components/icon';

import '../../skeleton.scss';
import Trans from '../../../../../components/trans';

const CamouflagesList = ({
  isMobile,
  camouflages,
  fetchPaginated,
  hasMore,
  loading,
  openAddCamouflageModal,
}) => (
  <>
    {loading && !hasMore ? (
      <SkeletonGrid classNameElement="sk-camouflage--element" classNameGrid="sk-camouflage--grid" />
    ) : (
      !isEmpty(camouflages) && (
        <InfiniteScroll
          dataLength={camouflages && camouflages.length}
          next={fetchPaginated}
          hasMore={hasMore}
          loader={<></>}
          className=""
        >
          {isMobile ? null : (
            <div onClick={openAddCamouflageModal} role="button" className="add-camouflage">
              <Icon type="plusLarge" />
              <span className="add-camouflage__text">{Trans('camouflage.card.addNew')}</span>
            </div>
          )}

          {camouflages?.map((camouflage) => (
            // eslint-disable-next-line react/no-array-index-key
            <CamouflageCard key={camouflage.id} camouflage={camouflage} />
          ))}
        </InfiniteScroll>
      )
    )}
  </>
);

export default CamouflagesList;
