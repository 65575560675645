import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';

import { Pages } from '../../../routes/pages';
import history from '../../../routes/history';

import BlockHeader from '../blockHeader';
import ArticleInfoCard from '../../blog/components/articleCard';
import ButtonArrow from '../../../components/button/buttonArrow';

import './styles.scss';

const Articles = ({ articles, blogHolder }) => {
  const { id } = useParams();
  const setting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <ButtonArrow />,
    prevArrow: <ButtonArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const goToCreateArticlePage = useCallback(() => {
    history.push({
      pathname: Pages.blog.createArticle.replace(':id', id),
      state: id,
    });
  }, [id]);

  return (
    <div className="articles-block">
      <BlockHeader
        title="Статьи"
        count={articles?.length}
        isAddButton={blogHolder}
        addButtonAction={goToCreateArticlePage}
      />
      <div className="articles-block__wrap">
        <Slider {...setting}>
          {articles?.map((item) => (
            <ArticleInfoCard key={item.id} article={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default memo(Articles);
