import React from 'react';

import { classNames as cx } from '../../../../helpers/utility';

import Icon from '../../../../components/icon';

import './styles.scss';

const AccountVerification = ({ user }) => {
  const classNames = cx('status-value', {
    'status-value_confirmed': user?.verify,
  });

  return (
    <div className="account-verification">
      <div className="account-verification__status">
        <span className="account-verification__status__text">Аккаунт</span>
        {user?.verify ? <Icon type="okNoBox" /> : <Icon type="reject" />}

        <span className={classNames}>{user?.verify ? 'Авторизован' : 'Не авторизован'}</span>
      </div>
      <span className="account-verification__id">{user.id ? `ID${user.id}` : 'ID'}</span>
    </div>
  );
};

export default AccountVerification;
