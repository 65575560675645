import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions } from '../../../../hooks/store';
import { teamsActions } from '../../../../store/teams';
import { useResponsive } from '../../../../hooks/useResponsive';
import { Pages } from '../../../../routes/pages';
import history from '../../../../routes/history';

import SubNavigation from '../../../components/subNavi';
import History from './sections/history';
import Charter from './sections/charter';
import Equipment from './sections/equipment';
import Demands from './sections/demands';

import './styles.scss';
import Trans from '../../../../components/trans';

const getNaviMap = (location, id) => [
  {
    id: '1',
    label: Trans('teams.about.history'),
    route: {
      pathname: Pages.team.about.replace(':id', id),
      state: location.state,
      hash: '#history',
    },
  },
  {
    id: '2',
    label: Trans('teams.about.rules'),
    route: {
      pathname: Pages.team.about.replace(':id', id),
      state: location.state,
      hash: '#charter',
    },
  },
  {
    id: '3',
    label: Trans('teams.about.kit'),
    route: {
      pathname: Pages.team.about.replace(':id', id),
      state: location.state,
      hash: '#equipment',
    },
  },
  {
    id: '4',
    label: Trans('teams.about.qualifications'),
    route: {
      pathname: Pages.team.about.replace(':id', id),
      state: location.state,
      hash: '#demands',
    },
  },
];

const renderSections = (hash, teamId) => {
  switch (hash) {
    case '#history':
      return <History teamId={teamId} />;
    case '#charter':
      return <Charter teamId={teamId} />;
    case '#equipment':
      return <Equipment teamId={teamId} />;
    case '#demands':
      return <Demands teamId={teamId} />;
    default:
      return <History teamId={teamId} />;
  }
};

const AboutTeamPage = () => {
  const { location } = history;
  const { id } = useParams();
  const { onGetTeamSettingsHistory } = useActions(teamsActions);
  const { isMobile } = useResponsive();
  useEffect(() => {
    if (id) {
      onGetTeamSettingsHistory('5', id, 'team', '1');
    }
  }, [id]);

  return (
    <div className="team-page--charter">
      {!isMobile && <SubNavigation naviMap={getNaviMap(location, id)} />}
      <div className="team-page--charter__editor">
        <div>{renderSections(location.hash, id)}</div>
      </div>
    </div>
  );
};

export default AboutTeamPage;
