import { catchWrapper } from '../../../helpers/utility';
import { uploadBlogAlbumImage } from '../../../api/v2';

export default ({ data, files, count }) => async (dispatch) => {
  const forEachLoop = () => {
    [...files].forEach(async (file) => {
      dispatch({ type: 'BLOG/UPLOAD_BLOG_IMAGE/REQUESTED' });

      const formData = new FormData();
      formData.append('element', JSON.stringify(data));
      formData.append('image', file);
      const result = await catchWrapper(uploadBlogAlbumImage(formData));
      if (result && result.result) {
        // eslint-disable-next-line no-param-reassign
        count.current += 1;
        dispatch({ type: 'BLOG/UPLOAD_BLOG_IMAGE/SUCCEEDED', payload: result.image });
        formData.delete('image');
      } else {
        // eslint-disable-next-line no-param-reassign
        dispatch({
          type: 'BLOG/UPLOAD_BLOG_IMAGE/FAILED',
          payload: result && result.message && result.message[0],
        });
        formData.delete('image');
      }
    });
  };
  await forEachLoop();
  // dispatch({ type: 'BLOG/UPLOAD_BLOG_IMAGE/REQUESTED' });
  // const result = await catchWrapper(uploadBlogAlbumImage(formData));
  // if (result && result.result) {
  //   dispatch({ type: 'BLOG/UPLOAD_BLOG_IMAGE/SUCCEEDED', payload: result && result });
  // } else {
  //   dispatch({
  //     type: 'BLOG/UPLOAD_BLOG_IMAGE/FAILED',
  //     payload: result && result.message && result.message[0],
  //   });
  // }
};
