import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="6" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 29C24.0751 29 29 24.0751 29 18C29 11.9249 24.0751 7 18 7C11.9249 7 7 11.9249 7 18C7 24.0751 11.9249 29 18 29ZM16.5 13.5C16.5 13.0341 16.5 12.8011 16.5761 12.6173C16.6776 12.3723 16.8723 12.1776 17.1173 12.0761C17.3011 12 17.5341 12 18 12C18.4659 12 18.6989 12 18.8827 12.0761C19.1277 12.1776 19.3224 12.3723 19.4239 12.6173C19.5 12.8011 19.5 13.0341 19.5 13.5V16.5H22.5C22.9659 16.5 23.1989 16.5 23.3827 16.5761C23.6277 16.6776 23.8224 16.8723 23.9239 17.1173C24 17.3011 24 17.5341 24 18C24 18.4659 24 18.6989 23.9239 18.8827C23.8224 19.1277 23.6277 19.3224 23.3827 19.4239C23.1989 19.5 22.9659 19.5 22.5 19.5H19.5V22.5C19.5 22.9659 19.5 23.1989 19.4239 23.3827C19.3224 23.6277 19.1277 23.8224 18.8827 23.9239C18.6989 24 18.4659 24 18 24C17.5341 24 17.3011 24 17.1173 23.9239C16.8723 23.8224 16.6776 23.6277 16.5761 23.3827C16.5 23.1989 16.5 22.9659 16.5 22.5V19.5H13.5C13.0341 19.5 12.8011 19.5 12.6173 19.4239C12.3723 19.3224 12.1776 19.1277 12.0761 18.8827C12 18.6989 12 18.4659 12 18C12 17.5341 12 17.3011 12.0761 17.1173C12.1776 16.8723 12.3723 16.6776 12.6173 16.5761C12.8011 16.5 13.0341 16.5 13.5 16.5H16.5V13.5Z"
      fill="white"
    />
  </svg>
);
