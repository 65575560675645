import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4246 20.0118C22.0731 18.9313 23.1575 17.0973 23.1575 15.0167C23.1575 11.6938 20.3915 9 16.9796 9C13.5677 9 10.8018 11.6938 10.8018 15.0167C10.8018 17.0973 11.8863 18.9313 13.5347 20.0118C12.6435 20.3177 11.8041 20.7505 11.0657 21.3155C9.39009 22.5976 8.27871 24.5318 8.27871 27.05C8.27871 27.5746 8.71543 28 9.25416 28H24.8613V27.9879C25.3258 27.9151 25.6806 27.5229 25.6806 27.0499C25.6806 24.5317 24.5693 22.5976 22.8935 21.3155C22.1553 20.7505 21.3158 20.3177 20.4246 20.0118ZM6.10939 28H1.85186C1.38139 28 1 27.6302 1 27.1739C1 24.9841 1.97058 23.3023 3.43394 22.1873C4.07873 21.6961 4.8118 21.3198 5.59009 21.0538C4.15051 20.1142 3.20347 18.5194 3.20347 16.7102C3.20347 13.8207 5.61894 11.4783 8.59856 11.4783C9.11494 11.4783 9.61439 11.5486 10.0872 11.68C9.51933 12.7376 9.19968 13.9317 9.19968 15.1957C9.19968 16.7754 9.69898 18.2461 10.5582 19.4773C10.2929 19.6597 10.0353 19.8544 9.78638 20.0613C7.63313 21.8515 6.18555 24.5277 6.10939 28ZM27.3788 28H32.1482C32.6187 28 33 27.6302 33 27.1739C33 24.9841 32.0295 23.3023 30.5661 22.1873C29.9213 21.6961 29.1882 21.3198 28.4099 21.0538C29.8495 20.1142 30.7966 18.5194 30.7966 16.7102C30.7966 13.8207 28.3811 11.4783 25.4015 11.4783C24.8902 11.4783 24.3956 11.5472 23.9268 11.6761C24.5038 12.7346 24.8286 13.9302 24.8286 15.1957C24.8286 16.8491 24.2741 18.3831 23.3272 19.6483C23.5013 19.78 23.6717 19.9176 23.8381 20.0613C25.9115 21.8515 27.3054 24.5277 27.3788 28Z"
      fill="currentColor"
    />
  </svg>
);
