import React from 'react';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import useVisible from '../../../../hooks/useVisible';
import { classNames as cx } from '../../../../helpers/utility';
import modalService from '../../../../services/modalService';

const RequestControls = ({
  event,
  onManageEventRequest,
  team,
  teamRole,
  userBlog,
  teamMembers,
}) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const eventRequest = event?.request;
  const eventTeamRequest = event?.request_team;

  const classNames = cx('apply-event', {
    'apply-event--open': isVisible,
  });

  const handleToggle = () => setIsVisible(!isVisible);

  const onCancelRequest = (removeSingleTeamRequest) => {
    onManageEventRequest({
      data: {
        event_id: event.id,
        singleRequest: !!removeSingleTeamRequest,
      },
      url: 'remove',
      remove: true,
    });
  };

  const onCancelTeamRequest = () => {
    modalService.openConfirmModal({
      title: 'Подтверждение',
      content: 'Вы действительно хотите отменить заявку?',
      submit: onCancelRequest,
    });
  };

  const onCancelBlogRequest = () => {
    onManageEventRequest({
      data: {
        event_id: event.id,
      },
      url: 'remove_blog',
      remove: true,
    });
  };

  const onCancelSingleRequest = () => {
    onManageEventRequest({
      data: {
        event_id: event.id,
      },
      url: 'remove_single',
      remove: true,
    });
  };

  const openTeamModal = ({ isEdit, isSingle }) => {
    modalService.openEventRegistration({
      team,
      teamRole,
      event,
      teamMembers,
      isEdit,
      singleInTeam: isSingle,
      actions: {
        onManageEventRequest,
        onCancelTeamRequest,
      },
    });
    setIsVisible(!isVisible);
  };

  const openBlogModal = () => {
    modalService.openEventRegistration({
      event,
      userBlog,
      actions: {
        onManageEventRequest,
        onCancelTeamRequest,
      },
    });
    setIsVisible(!isVisible);
  };

  const openSingleModal = () => {
    modalService.openEventRegistration({
      event,
      single: true,
      actions: {
        onManageEventRequest,
        onCancelTeamRequest,
      },
    });
    setIsVisible(!isVisible);
  };

  return (
    <div ref={ref} className="apply-event__wrap">
      {/*{eventRequest && !teamRole ? (*/}
      {/*  <Button onClick={onCancelRequest} variant="second" className={classNames}>*/}
      {/*    <span>Отменить заявку</span>*/}
      {/*  </Button>*/}
      {/*) : null}*/}

      {event.allow_me_as_blogger ||
      event.allow_me_as_club ||
      event.allow_me_as_guest ||
      event.allow_me_as_single ? (
        <Button onClick={handleToggle} variant="main" className={classNames}>
          <span>Подать заявку</span>
          <Icon type="chevron24" />
        </Button>
      ) : null}

      {isVisible ? (
        <div className="apply-event__list-wrap">
          <ul className="apply-event__list">
            {event.allow_me_as_single ? (
              <li>
                {eventRequest && !eventRequest.team && !eventRequest.is_blog ? (
                  <Button onClick={onCancelSingleRequest} variant="list">
                    Отменить заявку
                  </Button>
                ) : (
                  <Button onClick={openSingleModal} variant="list">
                    Подать заявку как одиночка
                  </Button>
                )}
              </li>
            ) : null}

            {(event.allow_me_as_club || event.allow_me_as_guest) && teamRole ? (
              <li>
                {eventTeamRequest && teamRole ? (
                  <Button onClick={() => openTeamModal({ isEdit: true })} variant="list">
                    Редактировать заявку команды
                  </Button>
                ) : (
                  <Button onClick={openTeamModal} variant="list">
                    Подать заявку от команды
                  </Button>
                )}
              </li>
            ) : null}

            {event.allow_me_as_club || event.allow_me_as_guest ? (
              <li>
                {eventRequest && eventRequest.team && !eventRequest.is_blog ? (
                  <Button onClick={() => onCancelRequest(true)} variant="list">
                    Отменить заявку из состава команды
                  </Button>
                ) : (
                  <Button onClick={() => openTeamModal({ isSingle: true })} variant="list">
                    Подать заявку в составе команды
                  </Button>
                )}
              </li>
            ) : null}

            {/* eslint-disable-next-line no-nested-ternary */}
            {event?.allow_me_as_blogger ? (
              event?.request?.is_blog ? (
                <li>
                  <Button onClick={onCancelBlogRequest} variant="list">
                    Отменить заявку блогера
                  </Button>
                </li>
              ) : (
                <li>
                  <Button onClick={openBlogModal} variant="list">
                    Подать как блогер
                  </Button>
                </li>
              )
            ) : null}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default RequestControls;
