import { isEmpty } from 'lodash';
import formatISO from 'date-fns/formatISO';

import { catchWrapper, concatenationDateAndTime } from '../../../helpers/utility';
import { createEvent, editEvent } from '../../../api/v2';
import { getPayments } from '../helpers';
import { validationCreateEvent } from '../validation';
import onChangeField from './onChangeField';
import { goToEvent } from '../../../routes/actions';

export default () => async (dispatch, getState) => {
  const { eventTempImage } = getState().events;
  const {
    sponsor,
    sponsors,
    sides,
    profile,
    type,
    location,
    dates,
    size,
    close_reg: closeReg,
    choose_side: chooseSide,
    payments,
    allow,
    id,
    previewRemove,
  } = getState().events.create;
  const { event } = getState().events;
  const formData = new FormData();

  if (eventTempImage?.preview) {
    const { blob, file } = eventTempImage?.preview;
    formData.append('previewBlob', blob);
    formData.append('previewFile', file);
  }

  if (eventTempImage?.banner) {
    const { blob, file } = eventTempImage?.banner;
    formData.append('bannerBlob', blob);
    formData.append('bannerFile', file);
  }

  const sidesT = sides.map((s) => {
    return {
      title: s.title,
      qty: s.qty,
      excess: s.excess,
      id: s.id,
    };
  });

  const dateStart = concatenationDateAndTime(dates.eventDateStart, dates.timeStart);
  const dateEnd = concatenationDateAndTime(dates.eventDateEnd, dates.timeEnd);
  const dateArrival = concatenationDateAndTime(dates.arrivalDate, dates.arrivalTime);
  const dateDeparture = concatenationDateAndTime(dates.departureDate, dates.departureTime);
  const dateRegStart = concatenationDateAndTime(dates.regDateStart, dates.regTimeStart);
  const dateRegEnd = concatenationDateAndTime(dates.regDateEnd, dates.regTimeEnd);

  const allPayments = getPayments(payments);

  const payload = {
    previewRemove,
    sponsor: sponsor.id,
    sides: sidesT,
    profile,
    type: type?.id,
    size,
    location,
    date_start: dateStart,
    date_end: dateEnd,
    date_arrival: dateArrival,
    date_departure: dateDeparture,
    date_reg_start: dateRegStart,
    date_reg_end: dateRegEnd,
    close_reg: closeReg,
    choose_side: chooseSide,
    is_club: allow.is_club,
    is_guest: allow.is_guest,
    is_single: allow.is_single,
    is_rules: allow.is_rules,
    is_spec_rules: allow.is_spec_rules,
    is_agreement: allow.is_agreement,
    is_spec_agreement: allow.is_spec_agreement,
  };

  if (id) {
    payload.id = id;
  }

  if (!isEmpty(sponsors)) {
    payload.sponsors = sponsors.map((s) => s.id);
  }

  if (allPayments) {
    payload.prices = allPayments;
  }

  const error = validationCreateEvent({
    images: eventTempImage,
    eventImages: event.images,
    profile,
    type,
    size,
    dateStart,
    dateEnd,
    dateRegStart,
    dateRegEnd,
    sides,
    location,
    allow,
  });

  if (error) {
    dispatch(onChangeField(error, 'errors'));
  } else {
    formData.append('element', JSON.stringify(payload));

    dispatch({ type: 'EVENTS/CREATE_EVENT/REQUESTED' });

    const request = id ? editEvent(formData) : createEvent(formData);

    const result = await catchWrapper(request);

    if (result?.result) {
      dispatch({ type: 'EVENTS/CREATE_EVENT/SUCCEEDED' });
      if (result?.event?.id) {
        goToEvent(result?.event?.id);
      }
    } else {
      dispatch({
        type: 'EVENTS/CREATE_EVENT/FAILED',
        payload: result?.message && result.message[0],
      });
    }
  }
};
