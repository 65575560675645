import { catchWrapper } from '../../../helpers/utility';
import { getSponsorList } from '../../../api/v2';

export default ({ page, query, type, size, geoLocal }) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/LOAD_ALL_SPONSORS/REQUESTED' });

  const payloadRequest = {
    page,
    objects_per_page: 20,
    filter: {
      query,
      sponsor_type: type || null,
      size_from: size ? size.min : null,
      size_to: size ? size.max : null,
      regions:
        geoLocal?.country && !geoLocal?.regionArr?.length
          ? [
              {
                country: geoLocal.country.id || null,
                region: null,
                city: null,
              },
            ]
          : geoLocal?.regionArr && geoLocal?.regionArr?.length
          ? geoLocal.regionArr.map((item) => {
              return {
                country: geoLocal.country.id,
                region: item.region_id,
                city: item.isRegion !== true ? item.id : null,
              };
            })
          : null,
    },
  };

  const result = await catchWrapper(getSponsorList(payloadRequest));

  if (result && result.result) {
    dispatch({ type: 'SPONSOR/LOAD_ALL_SPONSORS/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'SPONSOR/LOAD_ALL_SPONSORS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
