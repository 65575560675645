import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0845 10.3701C24.8466 9.45251 23.1022 10.3461 23.1022 11.8979V12.7386C19.688 12.7386 17.3495 14.1955 15.9096 16.5584C14.5073 18.8599 14 21.9467 14 25.1881C14 25.6365 14.3596 26 14.8031 26C15.2467 26 15.6063 25.6365 15.6063 25.1881C15.6063 23.6925 16.5891 22.0017 18.1267 20.6397C19.6568 19.2841 21.551 18.422 23.1022 18.422V19.5334C23.1022 21.0852 24.8466 21.9788 26.0845 21.0611L31.2342 17.2433C32.2553 16.4864 32.2553 14.9449 31.2342 14.1879L26.0845 10.3701Z"
      fill="#C0B4AF"
    />
  </svg>
);
