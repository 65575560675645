import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useResponsive } from '../../hooks/useResponsive';
import { Pages } from '../../routes/pages';
import history from '../../routes/history';
import TeamsSectionMobile from './mobile';
import TeamsSectionDesktop from './desktop';
import { useActions, useSelector } from '../../hooks/store';
import { teamsActions } from '../../store/teams';
import { getIdFromUrl } from '../../helpers/utility';
import { getMaps } from './helpers/navigations';
import { getActions, getIsDisabled } from './helpers/actions';
import { editorActions } from '../../store/editor';

const TeamsPage = () => {
  const { location } = history;

  const isTeamPages = useRouteMatch(Pages.team.base);
  const isTeamsPages = !!useRouteMatch(Pages.teams.base);
  const isCreatePages = !!useRouteMatch(Pages.team.create.base);

  const isSettingPages = !!useRouteMatch(Pages.team.settings.base);
  const isSettingLinksPages = !!useRouteMatch(
    Pages.team.settings.links.replace(':id', getIdFromUrl(location.pathname)),
  );
  const isRecruitingPage = useRouteMatch(Pages.teams.recruiting.all);
  const isAddRequestPage = useRouteMatch(Pages.teams.recruiting.add);
  const isViewRequestPage = useRouteMatch(Pages.teams.recruiting.view);

  const teamState = useSelector((state) => state?.teams);
  const { user, userRecruiting } = useSelector((state) => state.profile);
  const actions = useActions(teamsActions);
  const { deleteTextEditorImages } = useActions(editorActions);

  const { isMobile } = useResponsive();

  const onSubmit = getActions({
    location,
    teamId: getIdFromUrl(location.pathname),
    actions,
    teamState,
    deleteTextEditorImages,
  });

  const isDisabled = getIsDisabled({
    location,
    teamId: getIdFromUrl(location.pathname),
    teamState,
  });

  return isMobile ? (
    <TeamsSectionMobile
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      isTeamPages={isTeamPages}
      isTeamsPages={isTeamsPages}
      isCreatePages={isCreatePages}
      isSettingPages={isSettingPages}
      isSettingLinksPages={isSettingLinksPages}
      isAddRequestPage={isAddRequestPage}
      isViewRequestPage={isViewRequestPage}
      user={user}
      userRecruiting={userRecruiting}
      navigationMap={getMaps({
        isTeamPages,
        isSettingPages,
        isCreatePages,
        location,
        isMobile,
        user,
      })}
    />
  ) : (
    <TeamsSectionDesktop
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      isTeamPages={isTeamPages}
      isTeamsPages={isTeamsPages}
      isCreatePages={isCreatePages}
      isSettingPages={isSettingPages}
      isSettingLinksPages={isSettingLinksPages}
      isRecruitingPage={isRecruitingPage}
      actions={actions}
      user={user}
      userRecruiting={userRecruiting}
      navigationMap={getMaps({
        isTeamPages,
        isSettingPages,
        isCreatePages,
        location,
        user,
      })}
    />
  );
};

export default TeamsPage;
