import { catchWrapper } from '../../../helpers/utility';
import { postSaveTeamMember } from '../../../api/v2';
import onCheckAuth from '../../auth/impl/checkAuth';

export default () => async (dispatch, getState) => {
  const { teamMembers } = getState().teams;

  dispatch({ type: 'TEAMS/SAVE_TEAM_MEMBERS/REQUESTED' });

  const result = await catchWrapper(postSaveTeamMember(teamMembers));

  if (result && result.result) {
    dispatch({ type: 'TEAMS/SAVE_TEAM_MEMBERS/SUCCEEDED' });
    dispatch(onCheckAuth());
  } else {
    dispatch({
      type: 'TEAMS/SAVE_TEAM_MEMBERS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
