import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { classNames as cx } from '../../../../../helpers/utility';

import Icon from '../../../../../components/icon';

import './styles.scss';

const EventStage = ({ percentage = 66, isFinishedEvent, howManyDays, sign }) => {
  const style = {
    color: `#${sign?.color}`,
  };

  return (
    <div className="event-stage__wrap">
      <div style={style} className="event-stage__status">
        {/*{statusIcon ? <Icon type={statusIcon} /> : null}*/}
        {sign?.title || ''}
      </div>
      {isFinishedEvent ? (
        <Icon type="finished" />
      ) : (
        <CircularProgressbar
          className="event-stage__progress"
          value={percentage}
          text={`${howManyDays}д`}
        />
      )}
    </div>
  );
};

export default EventStage;
