import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import { Pages } from '../../../routes/pages';

import { PrivateRoute } from '../../../routes/privateRoute';
import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';

import ConversationsMobile from './conversations';
import DialogsMobile from './dialogs';
import history from '../../../routes/history';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import HeaderMobile from '../components/header-mobile';

function ChatMobile() {
  const { location } = history;
  const isDialogPage = useMemo(() => location.pathname !== Pages.msg.conversations);
  return (
    <>
      <HeaderConstructor className="chat-mobile">
        <HeaderLeft>
          {isDialogPage ? (
            <>
              <GoBackButton />
              <HeaderMobile />
            </>
          ) : (
            <SidebarButton />
          )}
        </HeaderLeft>
        {isDialogPage ? null : (
          <HeaderRight>
            <Controls />
            <Menu />
          </HeaderRight>
        )}
      </HeaderConstructor>
      <div className="page-container page-container--mobile page-container--mobile-msg ">
        <Switch>
          <PrivateRoute exact path={Pages.msg.base} component={DialogsMobile} />
          <PrivateRoute exact path={Pages.msg.conversations} component={ConversationsMobile} />
        </Switch>
      </div>
    </>
  );
}

export default ChatMobile;
