import history from '../../../routes/history';
import { editArticle } from '../../../api/v2';
import { catchWrapper } from '../../../helpers/utility';
import { Pages } from '../../../routes/pages';

export default (data, image) => async (dispatch) => {
  const formData = new FormData();
  formData.append('element', JSON.stringify(data));

  if (image) {
    const { blob, file } = image;
    formData.append('imageBlob', blob);
    formData.append('imageFile', file);
  }

  dispatch({ type: 'BLOG/EDIT_BLOG_ARTICLE/REQUESTED' });
  const result = await catchWrapper(editArticle(formData));
  if (result && result.result) {
    const articleId = result?.article?.id;
    dispatch({ type: 'BLOG/EDIT_BLOG_ARTICLE/SUCCEEDED', payload: result && result });
    history.push({
      pathname: Pages.blog.article.replace(':id', articleId),
      state: articleId,
    });
  } else {
    dispatch({
      type: 'BLOG/EDIT_BLOG_ARTICLE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
