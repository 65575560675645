import React from 'react';
import he from 'he';
import modalService from '../../../../../services/modalService';
import UserCardActions from '../../../../friends/components/userCardActions';
import './styles.scss';

const AlbumCard = ({
  album,
  parentId,
  parentType,
  blogHolder,
  openAddBlogAlbumModal,
  deleteAlbum,
}) => {
  const handleOpenBlogAlbumModal = () => {
    modalService.openAlbum({
      parentId,
      parentType,
      title: album?.profile?.title,
      albumId: album?.id,
      blogHolder,
    });
  };
  const schema = [
    {
      id: '1',
      label: 'Изменить',
      action: () => openAddBlogAlbumModal({ parentId, parentType, album, isEdit: true }),
    },
    {
      id: '2',
      label: 'Удалить',
      action: () => deleteAlbum(album?.id, parentId, parentType),
    },
  ];

  return (
    <div className="album-card album-card__container">
      <div className="album-card__header">
        <span className="album-card__title" onClick={handleOpenBlogAlbumModal}>
          {he.decode(album?.profile?.title || '')}
        </span>
        {blogHolder && <UserCardActions schema={schema} id={parentId} />}
      </div>
      {album?.images_preview?.length ? (
        <div className="album-card__content">
          {album?.images_preview?.map((image) => (
            <div
              className="album-card__image-wrap"
              key={image?.id}
              onClick={handleOpenBlogAlbumModal}
            >
              <img src={image?.image?.preview} alt="" />
            </div>
          ))}
        </div>
      ) : (
        <div className="album-card__no-content">
          <span className="album-card__no-content-text">
            В альбоме нет фото. Нажмите на название альбома чтоб загрузить фото.
          </span>
        </div>
      )}
    </div>
  );
};

export default AlbumCard;
