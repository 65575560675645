import { catchWrapper } from '../../../helpers/utility';
import { getCommentsList } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ page, id, contentType }) => async (dispatch, getState) => {
  const payloadRequest = {
    page,
    objects_per_page: 20,
    id: Number(id),
    type: contentType,
  };
  dispatch({ type: 'SOCIAL/GET_COMMENTS_LIST/REQUESTED' });
  const result = await catchWrapper(getCommentsList(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'SOCIAL/GET_COMMENTS_LIST/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'SOCIAL/GET_COMMENTS_LIST/FAILED',
      payload: result && result?.message && result.message[0],
    });
  }
};
