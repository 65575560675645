import React, { useCallback, useState } from 'react';

import { useActions } from '../../hooks/store';
import { authActions } from '../../store/auth';

import ModalHeader from '../components/modalHeaderBlog';
import StepEmail from './stepEmail';
import Button from '../../components/button';
import StepCode from '../securityModal/stepCode';
import StepPassword from './stepPassword';

import './styles.scss';

const renderSteps = ({ step, onChangeInput, onChangeCode, state, resendCode, clearError }) => {
  switch (step) {
    case 'email':
      return <StepEmail onChange={onChangeInput} email={state.email} error={state.error} />;
    case 'code':
      return (
        <StepCode
          code={state.code}
          onChange={onChangeCode}
          error={state.error}
          numFields={6}
          type="text"
          action={resendCode}
          clearError={clearError}
        />
      );
    case 'pass':
      return (
        <StepPassword
          onChange={onChangeInput}
          password={state.password}
          rePassword={state.rePassword}
          error={state.error}
        />
      );
    default:
      return <StepEmail onChange={onChangeInput} email={state.email} error={state.error} />;
  }
};

const getTitle = (step) => {
  switch (step) {
    case 'email':
      return 'Восстановление пароля';
    case 'code':
      return 'Введите код подтверждения';
    case 'pass':
      return 'Новый пароль';
    default:
      return 'Смена пароля';
  }
};

const getButtonLabel = (step) => {
  switch (step) {
    case 'email':
      return 'Отправить код';
    case 'code':
      return 'Подтвердить';
    case 'pass':
      return 'Сменить пароль';
    default:
      return 'Отправить код';
  }
};

const getAction = ({ step, actions }) => {
  switch (step) {
    case 'email':
      return actions.email;
    case 'code':
      return actions.code;
    case 'pass':
      return actions.pass;
    default:
      return null;
  }
};

const getDisabledSubmit = ({ step, state }) => {
  if (step === 'email') {
    return !state.email || state.loading;
  }

  if (step === 'code') {
    return !(state?.code?.length === 6) || state.loading;
  }

  if (step === 'pass') {
    return !(state.password && state.rePassword);
  }
  return false;
};

const init = {
  email: null,
  code: null,
  error: null,
  password: null,
  rePassword: null,
};

const ChangePasswordModal = ({ onCancel }) => {
  const [step, setStep] = useState('email');
  const [state, setState] = useState(init);

  const { changePassword } = useActions(authActions);

  const handleCleanError = () => setState({ ...state, error: null });

  const handleChangeInput = useCallback((e) => {
    const { name, value } = e.target;

    if (state.error) {
      setState({
        ...state,
        error: null,
        [name]: value,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  });

  const handleChangeCode = (value) => {
    setState({
      ...state,
      code: value,
    });
  };

  const onSubmitEmail = async () => {
    const payload = {
      action: 'remindPassword',
      step,
      email: state.email,
    };

    const response = await changePassword(payload);

    if (response?.error) {
      setState({
        ...state,
        error: response?.message,
      });
    } else {
      handleCleanError();
      setStep('code');
    }
  };

  const onSubmitCode = async () => {
    const payload = {
      action: 'checkCode',
      step,
      email: state.email,
      code: state.code,
    };

    const response = await changePassword(payload);

    if (response?.error) {
      setState({
        ...state,
        error: response?.message,
      });
    } else {
      handleCleanError();
      setStep('pass');
    }
  };

  const onSubmitPassword = async () => {
    const payload = {
      action: 'applyPassword',
      step,
      email: state.email,
      code: state.code,
      password: state.password,
      rePassword: state.rePassword,
    };

    const response = await changePassword(payload);

    if (response?.error) {
      setState({
        ...state,
        error: response?.message,
      });
    }
  };

  const actions = {
    email: onSubmitEmail,
    code: onSubmitCode,
    pass: onSubmitPassword,
  };

  const onSubmit = getAction({ step, actions });
  const disabled = getDisabledSubmit({ step, state });

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title={getTitle(step)} />
      <div className="modal-content">
        {renderSteps({
          step,
          onChangeInput: handleChangeInput,
          onChangeCode: handleChangeCode,
          state,
          resendCode: actions.email,
          clearError: handleCleanError,
        })}
      </div>
      <div className="modal-footer">
        <Button onClick={onSubmit} disabled={disabled} tabIndex="0" variant="main">
          {getButtonLabel(step)}
        </Button>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
