import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { globalCancelFetch } from '../../../../api/v2';
import { useActions, useSelector } from '../../../../hooks/store';
import { teamsActions } from '../../../../store/teams';

import TeamUserCard from '../../components/teamUserCard';

import './styles.scss';
import Trans from '../../../../components/trans';

const IncomingRequest = () => {
  const { teamRequests, loading } = useSelector((state) => state.teams);
  const { userId } = useSelector((state) => state.auth);
  const { getTeamIncomingRequests, onClearGeneralPages, onUserRequestManagement } = useActions(
    teamsActions,
  );
  const { id } = useParams();

  useEffect(() => {
    getTeamIncomingRequests(id);

    return () => {
      onClearGeneralPages();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const handleAction = (action, userIds) => {
    onUserRequestManagement({ action, user_id: userIds });
  };

  const schema = [
    {
      id: '1',
      label: Trans('actions.accept'),
      action: (userIds) => handleAction('approveUser', userIds),
    },
    {
      id: '2',
      label: Trans('actions.reject'),
      action: (userIds) => handleAction('rejectUser', userIds),
    },
  ];

  return (
    <div className="team-request-page">
      <div className="team-request-page__incoming">
        {!isEmpty(teamRequests) &&
          teamRequests.map((r) => {
            return <TeamUserCard key={r.id} user={r} userId={userId} withActions schema={schema} />;
          })}
      </div>
    </div>
  );
};

export default IncomingRequest;
