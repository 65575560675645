import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CometChat } from '@cometchat-pro/chat';
import { useResponsive } from '../../hooks/useResponsive';
import { Routes } from '../../routes';

import Navigations from '../../components/navigations';

import modalService from '../../services/modalService';
import ChatPanel from '../../components/chat';
import { loginUserMsg as loginUserMsgAction } from '../../store/msg/actions/authentication';

function Authorised({ user, loginUserMsg, userMsg }) {
  const { isDesktop } = useResponsive();

  useEffect(() => {
    if (user?.id && user?.need_confirmation) {
      modalService.openAuthEmailConfirm({ user });
    }
  }, [user]);

  useEffect(() => {
    if (user?.chat?.uid) {
      if (CometChat.isInitialized()) {
        loginUserMsg();
      }
    }
  }, [user?.chat?.uid, loginUserMsg]);

  return (
    <div className="main-container">
      <Navigations />
      {isDesktop && userMsg?.uid ? <ChatPanel /> : null}
      <div className="main-layout">
        <Routes />
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.profile?.user,
    userMsg: state.msg.user,
  }),
  {
    loginUserMsg: loginUserMsgAction,
  },
)(Authorised);
