import { catchWrapper } from '../../../helpers/utility';
import { postPrivate } from '../../../api';

export default () => async (dispatch, getState) => {
  const { privacy, groupKey } = getState().settings;

  dispatch({ type: 'SETTINGS/SAVE_PRIVATE/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'updatePrivate');
  Object.keys(privacy).forEach((i) => {
    if (groupKey === i) {
      Object.keys(privacy[i]).forEach((j) => {
        formData.append(`${groupKey}[${j}]`, privacy[i][j]);
      });
    } else {
      formData.append(i, privacy[i]);
    }
  });

  const result = await catchWrapper(postPrivate(formData));

  if (result && result.result) {
    dispatch({ type: 'SETTINGS/SAVE_PRIVATE/SUCCESSFUL' });
  } else {
    dispatch({ type: 'SETTINGS/SAVE_PRIVATE/FAILED', payload: result.message[0] });
  }
};
