import React from 'react';

export default () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9474 0H1.68421C0.757895 0 0 0.736364 0 1.63636V12.2727C0 12.7227 0.378947 13.0909 0.842105 13.0909C1.30526 13.0909 1.68421 12.7227 1.68421 12.2727V2.45455C1.68421 2.00455 2.06316 1.63636 2.52632 1.63636H10.9474C11.4105 1.63636 11.7895 1.26818 11.7895 0.818182C11.7895 0.368182 11.4105 0 10.9474 0ZM11.4442 3.75545L15.5116 7.70727C15.8232 8.01 16 8.42727 16 8.86091V16.3636C16 17.2636 15.2421 18 14.3158 18H5.04421C4.11789 18 3.36842 17.2636 3.36842 16.3636L3.37684 4.90909C3.37684 4.00909 4.12632 3.27273 5.05263 3.27273H10.2484C10.6947 3.27273 11.1242 3.44455 11.4442 3.75545ZM10.9474 9H14.7368L10.1053 4.5V8.18182C10.1053 8.63182 10.4842 9 10.9474 9Z"
      fill="#EB9330"
    />
  </svg>
);
