import React from 'react';
import { isEmpty } from 'lodash';
import Button from '../../../components/button';
import Icon from '../../../components/icon';

const MobileLinksContent = ({ socialService, services, openModal }) => {
  return (
    socialService &&
    !isEmpty(services) &&
    services.map((item) => {
      return (
        // eslint-disable-next-line react/jsx-key
        <div className="settings-links-mobile__row">
          <img src={item.icon} className="settings-links-mobile__icon" alt={item.title} />
          <div className="settings-links-mobile__row__wrap">
            <span className="settings-links-mobile__name">{item.title}</span>

            {socialService[item.key].value ? (
              <a
                target="_blank"
                rel="noreferrer"
                className="settings-links-mobile__link"
                href={socialService[item.key].value}
              >{`${socialService[item.key].value}`}</a>
            ) : (
              <span className="settings-links-mobile__link">Укажите ссылку на страницу</span>
            )}
          </div>

          {socialService[item.key].value ? (
            <Button
              onClick={() => openModal('service', item.key, socialService[item.key].value)}
              className="settings-links-mobile__actions"
              variant="icon"
            >
              <Icon type="moreMobile" />
            </Button>
          ) : (
            <Button
              onClick={() => openModal('service', item.key, '')}
              className="settings-links-mobile__actions"
              variant="icon"
            >
              <Icon type="add" />
            </Button>
          )}
        </div>
      );
    })
  );
};

export default MobileLinksContent;
