import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { classNames as cx } from '../../../../helpers/utility';

const AwardsBlock = ({ type, link, className, title, count, collection }) => {
  const classNames = cx('awards-block', className, {
    [`awards-block--${type}`]: type,
  });

  return (
    <div className={classNames}>
      <Link to={link} className="awards-block__link">
        <span className="awards-block__title">{title}</span>
        <span className="awards-block__count">{count}</span>
      </Link>
      <div className="awards-block__items">
        {collection?.map((a) => {
          return (
            <div className="awards-block__item" key={a.id}>
              <img src={a?.image?.image} alt={title} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(AwardsBlock);
