import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';
// eslint-disable-next-line import/no-cycle
import { authActions } from '../index';

export default (social) => async (dispatch) => {
  dispatch({ type: 'AUTH/UN_LINK_SOCIAL/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'unlinkProvider');
  formData.append('provider', social);

  const result = await catchWrapper(postSocial('social', formData));

  if (result && result.result) {
    batch(() => {
      dispatch({ type: 'AUTH/UN_LINK_SOCIAL/SUCCEEDED' });
      dispatch(authActions.getSocial());
    });
  } else {
    dispatch({ type: 'AUTH/UN_LINK_SOCIAL/FAILED' });
  }
};
