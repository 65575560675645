import React from 'react';

export default () => (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.04C0 0.4656 0.488392 0 1.09091 0H16.9091C17.5115 0 18 0.4656 18 1.04V11.96C18 12.5343 17.5115 13 16.9091 13H1.09091C0.488392 13 0 12.5343 0 11.96V1.04ZM9.06166 6.10436L2.65796 1.01696C2.31086 0.741153 1.7949 0.785905 1.50566 1.11681C1.21635 1.44777 1.26323 1.93959 1.6104 2.21539L8.51741 7.70255C8.81482 7.95564 9.26973 7.96968 9.58522 7.71906L16.5099 2.21775C16.8571 1.942 16.904 1.45012 16.6147 1.11922C16.3254 0.788252 15.8095 0.743564 15.4624 1.01937L9.06166 6.10436Z"
      fill="currentColor"
    />
  </svg>
);
