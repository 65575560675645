import { isEmpty } from 'lodash';
import { catchWrapper } from '../../../helpers/utility';

import { getRecruitingList } from '../../../api/v2';

export default ({ page, age, geoLocal, isFirstRequest, query }) => async (dispatch) => {
  dispatch({ type: 'TEAMS/LOAD_ALL_RECRUIT/REQUESTED' });

  const payload = {
    objects_per_page: 20,
    page,
    filter: {
      query,
      age_from: age?.min,
      age_to: age?.max,
      regions: [],
    },
  };

  if (geoLocal?.country && isEmpty(geoLocal?.regionArr)) {
    payload.filter.regions[0] = { country: geoLocal.country.id || null, region: null, city: null };
  }

  if (!isEmpty(geoLocal?.regionArr)) {
    // eslint-disable-next-line no-unused-expressions
    geoLocal?.regionArr.forEach((reg, index) => {
      payload.filter.regions[index] = {
        country: geoLocal.country.id,
        region: reg.region_id,
        city: !reg.isRegion ? reg.id : null,
      };
    });
  }

  const result = await catchWrapper(getRecruitingList(payload));

  if (result && result.result) {
    dispatch({
      type: 'TEAMS/LOAD_ALL_RECRUIT/SUCCEEDED',
      payload: {
        items: result.items,
        pagination: result.pagination,
        isFirstRequest,
      },
    });
  } else {
    dispatch({
      type: 'TEAMS/LOAD_ALL_RECRUIT/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
