import React, { useEffect, useState } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { teamsActions } from '../../../../store/teams';
import history from '../../../../routes/history';

import CustomInput from '../../../../components/input';
import CamouflageSelect from '../../../components/camouflageSelect';
import Button from '../../../../components/button';

import './styles.scss';
import Trans from '../../../../components/trans';

const CreateRequestPage = () => {
  const [camouflages, setCamouflages] = useState([]);
  const [about, setAbout] = useState('');

  const { goBack } = history;

  const { user, userRecruiting } = useSelector((state) => state.profile);

  useEffect(() => {
    if (userRecruiting) {
      setAbout(userRecruiting?.text);
      setCamouflages(userRecruiting?.camouflages);
    }
  }, [userRecruiting]);

  const { getAllRecruiting, onCreateRequestToTeam, onClearGeneralPages } = useActions(teamsActions);

  const handleAddCamouflage = (value) => setCamouflages(value);
  const handleRemoveCamouflage = (id) => {
    const temp = camouflages.filter((c) => c.id !== id);
    setCamouflages(temp);
  };

  const handleChangeAbout = (e) => {
    setAbout(e?.target?.value);
  };

  const onSubmit = async () => {
    await onCreateRequestToTeam(
      {
        text: about,
        location: user?.location,
        camouflages,
        user,
      },
      !!userRecruiting,
    );
    await goBack();
  };

  const onCancelRequest = async () => {
    await onCreateRequestToTeam(userRecruiting, false, true);
    await goBack();
  };

  return (
    <div className="create-request-page">
      <div className="row-wrap">
        <span className="content__label">{Trans('common.nname')}</span>
        <span className="content__value">{user?.nickname}</span>
      </div>
      <CustomInput
        className="city"
        label={Trans('common.city')}
        labelPosition="top"
        value={user?.location?.city?.title}
        disabled
      />
      <CustomInput
        className="bd"
        label={Trans('common.bdate')}
        labelPosition="top"
        value={user?.birth_date}
        disabled
      />
      <CamouflageSelect
        selectedCamouflages={camouflages}
        onChange={handleAddCamouflage}
        onRemoveCamouflage={handleRemoveCamouflage}
      />
      <CustomInput
        onChange={handleChangeAbout}
        value={about}
        textRows={6}
        type="textarea"
        className="about"
        label={Trans('common.about')}
        labelPosition="top"
        name="about"
        placeholder={Trans('common.aboutPlaceholder')}
      />
      {userRecruiting ? (
        <Button onClick={onCancelRequest} variant="text" className="cancel-request">
          {Trans('actions.cancel')}
        </Button>
      ) : null}
      <Button onClick={onSubmit} variant="main">
        {userRecruiting ? Trans('actions.save') : Trans('actions.publish')}
      </Button>
    </div>
  );
};

export default CreateRequestPage;
