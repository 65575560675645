import React from 'react';
import { Link } from 'react-router-dom';

import { classNames as cx, rankToName } from '../../../../helpers/utility';
import { Pages } from '../../../../routes/pages';

import Avatar from '../../../components/avatar';
import UserCardActions from '../../../friends/components/userCardActions';
import Icon from '../../../../components/icon';

import './styles.scss';
import Trans from '../../../../components/trans';

const TeamUserCard = ({ user, rank, userId: currentUserId, withActions, schema }) => {
  const userId = user?.id;
  const userLink = {
    pathname:
      currentUserId === userId ? Pages.profile.base : Pages.profile.main.replace(':id?', userId),
    state: userId,
  };

  const classNames = cx('card team-user-card', {
    'team-user-card--action': withActions,
  });

  const image = user?.image_avatar?.preview;
  const status = user?.state_online;
  const role = user?.player_role;

  return (
    <div className={classNames}>
      <Avatar image={image} status={status} withStatus />
      <div className="team-user-card__info">
        <Link to={userLink}>
          <span className="team-user-card__info-nickname">
            {user?.nickname || Trans('common.nname')}
          </span>
        </Link>
        <span className="team-user-card__info-rank">{rankToName[rank] || ''}</span>
      </div>
      {withActions ? <UserCardActions schema={schema} id={userId} /> : null}

      <div className="team-user-card__class">
        <Icon type={role || 'stormtrooper'} />
      </div>
    </div>
  );
};

export default TeamUserCard;
