import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { postAuth } from '../../../api';
import { localStorageService } from '../../../services/localStorageService';
import { goToEvents, goToProfile } from '../../../routes/actions';
import modalService from '../../../services/modalService';

export default (data) => async (dispatch) => {
  if (data.step === 'pass') {
    dispatch({ type: 'AUTH/CHECK/REQUESTED' });
  }

  const formData = new FormData();

  formData.append('action', data.action);

  if (data.email) {
    formData.append('email', data.email);
  }

  if (data.code) {
    formData.append('code', data.code);
  }

  if (data.password) {
    formData.append('password', data.password);
  }

  if (data.rePassword) {
    formData.append('re-password', data.rePassword);
  }

  const result = await catchWrapper(postAuth(formData));

  if (data.step === 'pass' && result.auth && result.result) {
    const authInfo = {
      token: result.user_salt,
      userId: result.current_user.id,
    };

    const authData = {
      userSponsors: result?.user_sponsors,
      userRecruiting: result?.user_recruiting,
      userBlog: result?.user_blog,
      currentEvent: result?.current_event,
    };

    const user = result?.current_user;

    localStorageService.setAuthData(authInfo);
    batch(() => {
      dispatch({ type: 'AUTH/LOGIN/SUCCEEDED', payload: authInfo });
      dispatch({ type: 'PROFILE/SET_USER', payload: user });
      dispatch({ type: 'PROFILE/SET_AUTH_DATA', payload: authData });
    });
    goToEvents();
    modalService.closeAll();
    modalService.openNotificationsModal({
      type: 'success',
      content: 'Пароль успешно изменен',
    });
  }

  if (!result.result && data.step === 'pass') {
    dispatch({ type: 'AUTH/CHECK/FAILED' });
  }

  return result && result;
};
