import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';
import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const SelectAsyncPaginate = ({
  value,
  loadOptions,
  onChange,
  label,
  labelPosition,
  className,
  name,
  filterOption,
  required,
  error,
  ...props
}) => {
  const { t } = useTranslation();
  const classNamesWrapper = cx('select-pagination__wrapper', className, {
    [`select-pagination__wrapper__label_${labelPosition}`]: labelPosition,
    [`select-pagination__${name}`]: name,
  });

  const classNamesLabel = cx('select-pagination__label', className, {
    [`select-pagination__label_${labelPosition}`]: labelPosition,
  });
  const customLabel = required ? `${t(label)} *` : t(label);
  return (
    <div className={classNamesWrapper}>
      {(customLabel || (error && required)) && (
        <span className={classNamesLabel}>{error || customLabel}</span>
      )}
      <AsyncPaginate
        value={value}
        loadingMessage={() => null}
        loadOptions={loadOptions}
        onChange={onChange}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option}
        additional={{
          page: 1,
        }}
        className="select__container"
        classNamePrefix="select"
        noOptionsMessage={() => null}
        components={{
          IndicatorSeparator: () => null,
        }}
        filterOption={filterOption}
        isLoading={false}
        {...props}
      />
    </div>
  );
};

export default SelectAsyncPaginate;
