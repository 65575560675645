import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

import HeaderConstructor from '../../../components/headerConstructor';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';

import HeaderLeft from '../../../components/headerConstructor/components/left';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderRight from '../../../components/headerConstructor/components/right';

import GoBackButton from '../../../components/headerConstructor/components/goBackButton';

import BlogsAllPage from '../desktop/all';
import BlogPage from '../../blog/desktop/main';

const BlogsSectionMobile = () => {
  const { location } = history;

  const isCamouflageMainPage = useRouteMatch(Pages.camouflage.main.replace(':id', location.state));

  const pageTitle = 'Блог';

  return (
    <>
      <HeaderConstructor className="camouflages mobile">
        <HeaderLeft>
          {isCamouflageMainPage ? <GoBackButton /> : <SidebarButton />}
          {isCamouflageMainPage ? <HeaderTitle title={pageTitle} /> : null}
        </HeaderLeft>
        <HeaderRight>
          {isCamouflageMainPage ? null : (
            <>
              <Controls />
              <Menu />
            </>
          )}
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.blog.all}
            component={BlogsAllPage}
            // extraProps={{ search }}
          />
          <PrivateRoute exact path={Pages.blog.main} component={BlogPage} />
        </Switch>
      </div>
    </>
  );
};

export default BlogsSectionMobile;
