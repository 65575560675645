import Edit from './edit';
import QuestionMark from './questionMark';
import Message from './message';
import Search from './search';
import Check from './ckeck';
import Uncheck from './unckeck';
import Qr from './qr';
import Download from './download';
import Copy from './copy';
import RemoveCam from './removeCam';
import Default from './default';
import PlusLarge from './plusLarge';
import Dummy from './dummy';
import DummySmall from './dummySmall';
import ArrLeft from './arr-left';
import ArrRight from './arr-right';

export default {
  edit: Edit,
  questionMark: QuestionMark,
  message: Message,
  search: Search,
  check: Check,
  uncheck: Uncheck,
  qr: Qr,
  copy: Copy,
  download: Download,
  removeCam: RemoveCam,
  default: Default,
  plusLarge: PlusLarge,
  dummy: Dummy,
  dummySmall: DummySmall,
  arrLeft: ArrLeft,
  arrRight: ArrRight,
};
