import React, { useState } from 'react';
import uuid from 'react-uuid';

import Avatar from '../../../../../components/avatar';
import Button from '../../../../../../components/button';
import SelectAsyncPaginate from '../../../../../../components/selectAsyncPaginate';

import './styles.scss';

const init = {
  isVisible: false,
};

const SponsorSelect = ({ loadOptions, onSetSponsor, onRemoveSponsor, sponsor, sponsors }) => {
  const [state, setState] = useState(init);

  const handleOnVisible = () => setState({ ...state, isVisible: true });

  const onChange = (value) => {
    onSetSponsor(value);
    setState({ ...state, isVisible: false });
  };

  const selectedSponsors = [sponsor, ...sponsors];

  const filterOption = (option) => {
    const { value } = option;

    const t = selectedSponsors.find((s) => s.id === value.id);

    return t ? null : value;
  };

  return (
    <div className="sponsor-select sponsor-select__container">
      {selectedSponsors.map((s, index) => {
        if (index < 6) {
          return (
            <div key={uuid()} className="sponsor-select__selected">
              <Avatar image={s?.images?.image?.image || null} />
              <span className="sponsor-select__type">Организатор</span>
              <span className="sponsor-select__name">{s?.title}</span>
              {index !== 0 ? (
                <Button
                  onClick={() => onRemoveSponsor(s.id)}
                  className="remove-sponsor"
                  variant="text"
                >
                  Удалить
                </Button>
              ) : null}
            </div>
          );
        }
      })}

      {state.isVisible ? (
        <SelectAsyncPaginate
          name="sponsor"
          loadOptions={loadOptions}
          onChange={onChange}
          filterOption={filterOption}
        />
      ) : null}

      {selectedSponsors.length < 6 ? (
        <Button onClick={handleOnVisible} className="add-sponsor" variant="second">
          Добавть организатора
        </Button>
      ) : null}
    </div>
  );
};

export default SponsorSelect;
