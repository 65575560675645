import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import HeaderConstructor from '../../../../components/headerConstructor';
import HeaderLeft from '../../../../components/headerConstructor/components/left';
import SidebarButton from '../../../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../../../components/headerConstructor/components/title';
import HeaderRight from '../../../../components/headerConstructor/components/right';
import Controls from '../../../../components/headerConstructor/components/controls';
import Menu from '../../../../components/headerConstructor/components/menu';
import { getDangerouslyHtml } from '../../../../helpers/utility';

import './styles.scss';

const TextPageMobile = ({ title, text }) => {
  const { user } = useSelector((state) => state.profile);

  return (
    <>
      <HeaderConstructor className="mobile">
        <HeaderLeft>
          <SidebarButton />
        </HeaderLeft>
        <HeaderRight>
          <>
            <Controls />
            {!isEmpty(user) && <Menu />}
          </>
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container page-container--mobile text-page-mobile">
        <HeaderTitle title={title} />
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(text || '')} />
      </div>
    </>
  );
};

export default TextPageMobile;
