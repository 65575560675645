import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';
import { localStorageService } from '../../../services/localStorageService';
import {goToEvents, goToLogin} from '../../../routes/actions';

export default (data) => async (dispatch) => {
  dispatch({ type: 'AUTH/SOCIAL/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'processSocial');
  Object.keys(data).forEach((i) => {
    formData.append(i, data[i]);
  });

  const result = await catchWrapper(postSocial('social', formData));

  if (result && result.auth && result.result) {
    const authInfo = {
      token: result?.user_salt,
      userId: result?.current_user.id,
    };

    localStorageService.setAuthData(authInfo);

    const authData = {
      userSponsors: result?.user_sponsors,
      userRecruiting: result?.user_recruiting,
      userBlog: result?.user_blog,
      currentEvent: result?.current_event,
    };

    batch(() => {
      dispatch({ type: 'AUTH/SOCIAL/SUCCEEDED', payload: authInfo });
      dispatch({ type: 'PROFILE/SET_USER', payload: result.current_user });
      dispatch({ type: 'PROFILE/SET_AUTH_DATA', payload: authData });
      dispatch({
        type: 'THEME/TOGGLE',
        payload: {
          theme: result?.theme ?? 0,
          eventView: result?.event_view ?? 1,
        },
      });
    });

    goToEvents();
  } else {
    dispatch({
      type: 'AUTH/SOCIAL/FAILED',
      payload: { message: result.message[0] },
    });
    goToLogin();
  }
};
