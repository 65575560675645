import React, { useEffect } from 'react';
import { Switch, useParams } from 'react-router-dom';
import { PrivateRoute } from '../../../routes/privateRoute';

import { Pages } from '../../../routes/pages';

import EventMainPage from '../desktop/main';
import EventParticipantsPage from '../desktop/participants';
import Scenario from '../desktop/eventsPages/scenario';
import Access from '../desktop/eventsPages/access';
import AgreementsEvents from '../desktop/eventsPages/agreement';
import InfoEventPage from '../desktop/eventsPages/info';
import Polygon from '../desktop/eventsPages/polygon';
import Results from '../desktop/eventsPages/results';
import Offer from '../desktop/eventsPages/offer';
import history from '../../../routes/history';
import { useActions } from '../../../hooks/store';
import { eventsActions } from '../../../store/events';
import { globalCancelFetch } from '../../../api/v2';
import { getIdFromUrl } from '../../../helpers/utility';
import PricesPage from '../desktop/prices';

const EventContainer = () => {
  const { location } = history;
  const { getEvent, clearEvents } = useActions(eventsActions);

  useEffect(() => {
    getEvent(getIdFromUrl(location.pathname));

    return () => {
      clearEvents();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  return (
    <Switch>
      <PrivateRoute exact path={Pages.event.main} component={EventMainPage} />
      <PrivateRoute exact path={Pages.event.scenario} component={Scenario} />
      <PrivateRoute exact path={Pages.event.participants} component={EventParticipantsPage} />
      <PrivateRoute exact path={Pages.event.price} component={PricesPage} />
      <PrivateRoute exact path={Pages.event.allow} component={Access} />
      <PrivateRoute exact path={Pages.event.agreement} component={AgreementsEvents} />
      <PrivateRoute exact path={Pages.event.info} component={InfoEventPage} />
      <PrivateRoute exact path={Pages.event.results} component={Results} />
      <PrivateRoute exact path={Pages.event.location} component={Polygon} />
      <PrivateRoute exact path={Pages.event.offer} component={Offer} />
    </Switch>
  );
};

export default EventContainer;
