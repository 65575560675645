import { catchWrapper } from '../../../helpers/utility';
import { getVideoList } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ page, parentId, parentType }) => async (dispatch) => {
  const payloadRequest = {
    page,
    objects_per_page: 20,
    parent_id: Number(parentId),
    type: parentType,
  };
  dispatch({ type: 'BLOG/GET_BLOG_VIDEO_LIST/REQUESTED' });
  const result = await catchWrapper(getVideoList(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'BLOG/GET_BLOG_VIDEO_LIST/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'BLOG/GET_BLOG_VIDEO_LIST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
