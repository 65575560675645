import Driver from './driver';
import Engineer from './engineer';
import Medic from './medic';
import Support from './support';
import Photographer from './photographer';
import Stormtrooper from './stormtrooper';

export default {
  driver: Driver,
  engineer: Engineer,
  medic: Medic,
  support: Support,
  photographer: Photographer,
  stormtrooper: Stormtrooper,
};
