import React from 'react';
import { Link } from 'react-router-dom';
import { Pages } from '../../../../../routes/pages';

import { useResponsive } from '../../../../../hooks/useResponsive';
import { classNames as cx, getFormattedDate } from '../../../../../helpers/utility';

import Icon from '../../../../../components/icon';
import Skeleton from '../../../../../components/skeleton';

import './styles.scss';

const Place = ({ event, place, dateStart, dateEnd, location, isShort, className, loading }) => {
  const { isMobile } = useResponsive();

  const date = getFormattedDate(dateStart, 'dd MMMM');
  const timeStart = getFormattedDate(dateStart, 'HH:mm');
  const timeEnd = getFormattedDate(dateEnd, 'HH:mm');

  const calendarIcon = 'calendarNew';
  const locationIcon = 'locationNew';
  const lentaIcon = 'discoveryNew';

  const classNames = cx('event-place__wrap event-place', className, {
    'event-place__wrap--short': isShort,
  });

  return (
    <div className={classNames}>
      <div className="event-place__row">
        {isMobile && isShort ? null : <Icon type={calendarIcon} />}

        {loading ? (
          <Skeleton className="event-info-sk__place" />
        ) : (
          <span className="event-place__value">{`${date} ${timeStart}-${timeEnd}`}</span>
        )}
      </div>
      <div className="event-place__row">
        {isMobile && isShort ? null : <Icon type={locationIcon} />}

        {loading ? (
          <Skeleton className="event-info-sk__place" />
        ) : (
          <span className="event-place__value">{location?.city?.title}</span>
        )}
      </div>
      <div className="event-place__row">
        {isMobile && isShort ? null : <Icon type={lentaIcon} />}

        {loading ? (
          <Skeleton className="event-info-sk__place" />
        ) : (
          <>
            {event?.ground?.id ? (
              <Link to={Pages.event.location.replace(':id', event.id)}>
                {event.ground.profile.title}
              </Link>
            ) : (
              <span className="event-place__value">{place}</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Place;
