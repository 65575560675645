import React from 'react';
import './styles.scss';
import Icon from '../../../components/icon';
import Button from '../../../components/button';

const title = 'Разнообразный и богатый опыт рамки и место обучения кадров и богатый';
const text =
  'Разнообразный и богатый опыт рамки и место обучения кадров позволяет выполнять важные задания по разработке модели развития. Разнообразный и богатый опыт консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом начало повседневной работы. Разнообразный и богатый опыт консультация с широким активом обеспечивает.';

const NewOrgGroupCard = ({ userData }) => {
  const acaUrl = userData && `${userData.avatar_url}${userData.avatar_preview}`;
  return (
    <div className="card org-group-card">
      <div className="org-group-card__header">
        <div className="org-group-card__user-info">
          <div>
            <Button onClick={() => {}} variant="submenu">
              <div className="org-group-card__content">
                <div className="org-group-card__image">
                  {acaUrl ? <img src={acaUrl} alt="" /> : <Icon type="profile" />}
                </div>
              </div>
            </Button>
          </div>
          <div className="org-group-card__info-text">
            <div className="org-group-card__nickname">
              <span>{userData?.nickname}</span>
            </div>
            <div className="org-group-card__timestamp">
              <span>Сегодня в 12:45</span>
            </div>
          </div>
        </div>
        <div className="org-group-card__status">
          <span className="org-group-card__status__text">Орггруппа</span>
        </div>
      </div>
      <div className="org-group-card__title">
        <span className="org-group-card__title__text">{title}</span>
      </div>
      <div className="org-group-card__info">
        <span className="org-group-card__info__text">{text}</span>
      </div>
      <div className="org-group-card__price">
        <div className="org-group-card__price-group">
          <div className="org-group-card__price-group-before">1000 ₽</div>
          <div className="org-group-card__price-group-after">1200 ₽ на полигоне</div>
        </div>
        <div>
          <Button onClick={() => {}} variant="event-register">
            <div className="org-group-card__register-button">
              <span className="org-group-card__register-button__name">Регистрация</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewOrgGroupCard;
