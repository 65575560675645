import React from 'react';
import { useResponsive } from '../../../../../hooks/useResponsive';

import Icon from '../../../../../components/icon';
import SidebarButton from '../../../../../components/headerConstructor/components/sidebarButton';
import ThemeSwitch from '../../../../../components/themeSwitch';

import './styles.scss';
import LanguageButton from '../../../../components/languageButton';
import PortalLanguage from '../../../../settings/components/portalLang';

const AuthHeader = () => {
  const { isDesktop } = useResponsive();

  return (
    <div className="auth-header">
      {!isDesktop ? <SidebarButton /> : null}
      <div className="auth-header__logo">
        <Icon type="fullLogo" />
      </div>
      <PortalLanguage />
      <ThemeSwitch />
    </div>
  );
};

export default AuthHeader;
