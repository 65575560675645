import React, { useLayoutEffect, useRef, useState } from 'react';

import useSmoothScroll from 'react-smooth-scroll-hook';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useResponsive } from '../../../../hooks/useResponsive';

import ImagesHeader from './components/imagesHeader';

import './styles.scss';
import Trans from '../../../../components/trans';

const GroundImages = ({ images }) => {
  const { isMobile } = useResponsive();
  const [state, setState] = useState({
    currentItem: 0,
    totalItem: images.length,
  });
  const [photoIndex, setIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const imagesBig = images.map((item) => item.image.image);
  const ref = useRef(null);

  const { scrollTo } = useSmoothScroll({
    ref,
    speed: 50,
    direction: 'x',
  });
  const handleClickRight = () => {
    const disabledItem = state.totalItem - 1 === state.currentItem;
    const width =
      ref?.current?.scrollWidth - (ref?.current?.offsetWidth + ref?.current?.scrollLeft);
    const disabledWidth = width < 1;
    if ((isMobile && disabledItem) || (!isMobile && disabledWidth)) return false;

    scrollTo(`#item-${state.currentItem + 1}`);
    setState({ ...state, currentItem: state.currentItem + 1 });
  };
  const handleClickLeft = () => {
    scrollTo(`#item-${state.currentItem - 1}`);
    setState({ ...state, currentItem: state.currentItem - 1 });
  };
  const handleImageClick = (i) => {
    setIndex(i);
    setOpen(true);
  };

  useLayoutEffect(() => {
    if (isMobile) {
      const style = document.createElement('style');
      const width = document.getElementById('groundBlock').offsetWidth;
      style.type = 'text/css';
      style.innerHTML = `.ground-image { width: ${width}px !important; } .ground-image img { width: ${width}px !important; }`;
      document.getElementById('groundBlock').appendChild(style);
    }
  }, [isMobile]);
  return (
    <div className="ground-images-block" id="groundBlock">
      <ImagesHeader
        count={images.length}
        state={state}
        onClickLeft={handleClickLeft}
        onClickRight={handleClickRight}
        title={Trans('fields.cards.photos')}
        isMobile={isMobile}
        pageQty={isMobile ? 1 : 2}
        wrapRef={ref}
        itemWidth={460}
      />
      <div ref={ref} className="ground-images-block__wrap">
        {images.map((item, i) => {
          const style = {
            background: `url(${item.image.preview}) center no-repeat`,
            backgroundSize: 'cover',
          };
          return (
            <div
              onClick={() => handleImageClick(i)}
              style={style}
              className="ground-image"
              key={`${item.id}_1`}
              id={`item-${i}`}
            />
          );
        })}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={imagesBig[photoIndex]}
          nextSrc={imagesBig[(photoIndex + 1) % imagesBig.length]}
          prevSrc={imagesBig[(photoIndex + imagesBig.length - 1) % imagesBig.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default GroundImages;
