export const TEXT_MESSAGE_RECEIVED = 'onTextMessageReceived';
export const MEDIA_MESSAGE_RECEIVED = 'onMediaMessageReceived';
export const CUSTOM_MESSAGE_RECEIVED = 'onCustomMessageReceived';
export const MESSAGE_DELIVERED = 'onMessagesDelivered';
export const MESSAGE_READ = 'onMessagesRead';
export const MESSAGE_DELETED = 'onMessageDeleted';
export const MESSAGE_EDITED = 'onMessageEdited';
export const TRANSIENT_MESSAGE_RECEIVED = 'onTransientMessageReceived';

export const INCOMING_CALL_RECEIVED = 'onIncomingCallReceived';
export const OUTGOING_CALL_ACCEPTED = 'onOutgoingCallAccepted';
export const OUTGOING_CALL_REJECTED = 'onOutgoingCallRejected';
export const INCOMING_CALL_CANCELLED = 'onIncomingCallCancelled';

export const GROUP_MEMBER_SCOPE_CHANGED = 'onGroupMemberScopeChanged';
export const GROUP_MEMBER_KICKED = 'onGroupMemberKicked';
export const GROUP_MEMBER_BANNED = 'onGroupMemberBanned';
export const GROUP_MEMBER_UNBANNED = 'onGroupMemberUnbanned';
export const GROUP_MEMBER_ADDED = 'onMemberAddedToGroup';
export const GROUP_MEMBER_LEFT = 'onGroupMemberLeft';
export const GROUP_MEMBER_JOINED = 'onGroupMemberJoined';

export const USER_ONLINE = 'onUserOnline';
export const USER_OFFLINE = 'onUserOffline';

export const TYPING_STARTED = 'onTypingStarted';
export const TYPING_ENDED = 'onTypingEnded';

export const CUSTOM_TYPE_POLL = 'extension_poll';
export const CUSTOM_TYPE_STICKER = 'extension_sticker';
export const CUSTOM_TYPE_DOCUMENT = 'extension_document';
export const CUSTOM_TYPE_WHITEBOARD = 'extension_whiteboard';
export const CUSTOM_TYPE_MEETING = 'meeting';

export const ACTIONS = {
  MESSAGES_INITIAL_FETCH: 'messageInitialFetch',
  MESSAGES_FETCHED: 'messageFetched',
  MESSAGE_SENT: 'messageSent',
};

export const EVENTS = {
  UPDATE_LAST_MESSAGE: 'updateLastMessage',
  NEW_MESSAGES: 'newMessagesArrived',
  CLEAR_UNREAD_MESSAGES: 'clearUnreadMessages',
};

export const KEYS = {
  METADATA: 'metadata',
  INCREMENT_UNREAD_COUNT: 'incrementUnreadCount',
};
