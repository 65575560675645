import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.57817 13.3925L14.6303 22.4232C15.4011 23.1923 16.651 23.1923 17.4218 22.4232C18.1927 21.6542 18.1927 20.4073 17.4218 19.6382L9.76554 12L17.4218 4.36178C18.1927 3.59273 18.1927 2.34584 17.4218 1.57679C16.651 0.807736 15.4011 0.807736 14.6303 1.57679L5.57817 10.6075C4.80728 11.3766 4.80728 12.6234 5.57817 13.3925Z"
      fill="currentColor"
    />
  </svg>
);
