import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import DynamicLabelRow from '../../../components/dynamicLabelRow';
import ButtonGroup from '../../../../components/buttonGroup';
import Button from '../../../../components/button';
import { useActions, useSelector } from '../../../../hooks/store';
import { blogActions } from '../../../../store/blog';
import { authActions } from '../../../../store/auth';
import modalService from '../../../../services/modalService';
import { useResponsive } from '../../../../hooks/useResponsive';
import { Pages } from '../../../../routes/pages';
import history from '../../../../routes/history';

const ControlsSettingMain = ({ blog, handleChangeTags, tags }) => {
  const { location } = history;
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { onSaveBlogService, getBlog } = useActions(blogActions);
  const { getServices } = useActions(authActions);
  const isCreatingBlogPage = location.pathname === Pages.blog.createBlog;
  const { services } = useSelector((state) => state?.auth);

  useEffect(() => {
    if (!isCreatingBlogPage) {
      getBlog(id);
    }
    getServices();
  }, [getBlog, getServices, id, isCreatingBlogPage]);

  const handleOpenModal = (key) =>
    modalService.openChangeLinkModal({
      service: key,
      onSave: onSaveBlogService,
    });

  const handleRemoveLink = (service) => {
    const payload = {
      service,
      value: '',
    };
    onSaveBlogService(payload);
  };

  return (
    <div className="blog-page--settings__controls">
      <div className="blog-page--settings__editor-container">
        {!isMobile && (
          <div className="settings-links">
            {blog?.social_service &&
              !isEmpty(services) &&
              services.map((item) => (
                <div key={item.id} className="settings-links__row settings-links__row_vk">
                  <img src={item.icon} className="settings-links__icon" alt={item.title} />
                  <span className="settings-links__name">{item.title}</span>
                  {blog?.social_service[item.key].value ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="settings-links__link"
                      href={blog?.social_service[item.key].value}
                    >{`${blog?.social_service[item.key].value}`}</a>
                  ) : null}
                  {blog?.social_service[item.key].value ? (
                    <Button
                      onClick={() => handleRemoveLink(item.key)}
                      variant="text"
                      className="settings-links__link settings-links__link_remove"
                    >
                      Удалить
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleOpenModal(item.key)}
                      variant="text"
                      className="settings-links__link settings-links__link_add"
                    >
                      Добавить ссылку
                    </Button>
                  )}
                </div>
              ))}
          </div>
        )}
        <DynamicLabelRow page="blog-tags" label="Теги">
          <ButtonGroup tags={tags && tags} handleChangeTags={handleChangeTags} />
        </DynamicLabelRow>
      </div>
    </div>
  );
};

export default ControlsSettingMain;
