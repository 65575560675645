import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru'; // the locale you want

import { classNames as cx } from '../../helpers/utility';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

registerLocale('ru', ru);

const CustomDatePicker = (props) => {
  const { className, disabled } = props;

  const classNames = cx('custom-date-picker__wrap', className, {
    'custom-date-picker--disabled': disabled,
  });

  return (
    <div className={classNames}>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        placeholderText="дд.мм.гггг"
        className="custom-date-picker"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        locale="ru"
        yearDropdownItemNumber={40}
        scrollableYearDropdown
        {...props}
      />
    </div>
  );
};

export default CustomDatePicker;
