import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { eventsActions } from '../../../../../../../store/events';
import { useActions, useSelector } from '../../../../../../../hooks/store';
import { getDangerouslyHtml } from '../../../../../../../helpers/utility';
import { useResponsive } from '../../../../../../../hooks/useResponsive';

const AdditionalAgreementText = () => {
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { onGetEventSettingsInfoAgreeAdd } = useActions(eventsActions);
  const { eventSettings } = useSelector((state) => state.events);
  const additionalAgreementText = eventSettings?.additionalAgreement?.text;

  useEffect(() => {
    if (id) {
      onGetEventSettingsInfoAgreeAdd('11', Number(id), 'event', '1');
    }
  }, [id]);

  return (
    <div className="events-text">
      {!isMobile && <div className="info-caption">Дополнительные соглашения</div>}
      {additionalAgreementText && (
        <div
          className="info-text"
          dangerouslySetInnerHTML={getDangerouslyHtml(additionalAgreementText)}
        />
      )}
    </div>
  );
};

export default AdditionalAgreementText;
