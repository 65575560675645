import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import uuid from 'react-uuid';
import SelectWhoCanSee from '../../components/whoCanSee';
import { useActions, useSelector } from '../../../../hooks/store';
import { settingsActions } from '../../../../store/settings';
import SkeletonPrivate from '../../components/skeletonPrivate';

import './styles.scss';

const SettingsPrivateMobile = () => {
  const { privacy, privacyConfig, privacyConfigValues, loading } = useSelector(
    (state) => state.settings,
  );
  const { getPrivateData, onChangePrivateField, getPrivateSettings } = useActions(settingsActions);

  useEffect(() => {
    getPrivateData();
    getPrivateSettings();
  }, []);

  const handleChange = (name, elem, groupKey) => {
    onChangePrivateField(name, elem, groupKey);
  };

  const configLen = privacyConfig && privacyConfig.length;

  return (
    <div className="page-private-mobile">
      {!loading ? (
        !isEmpty(privacy) &&
        !isEmpty(privacyConfig) &&
        privacyConfig.map((config, index) => {
          return (
            <div key={uuid()} className="setting-private__section">
              <h3 className="setting-private__title">{config.title}</h3>
              {Object.keys(config.items).map((item) => {
                const value = config.group_key ? privacy[config.group_key][item] : privacy[item];
                return (
                  <>
                    <span className="setting-private__label">{config.items[item].title}</span>
                    <SelectWhoCanSee
                      data={config.items[item].values}
                      isMobile
                      name={item}
                      key={uuid()}
                      valuesName={privacyConfigValues}
                      onChange={handleChange}
                      value={value}
                      groupKey={config.group_key}
                      menuOrientation={index + 1 === configLen}
                    />
                  </>
                );
              })}
            </div>
          );
        })
      ) : (
        <SkeletonPrivate />
      )}
    </div>
  );
};

export default SettingsPrivateMobile;
