import React from 'react';
import Button from '../../../../../components/button';

import './styles.scss';
import Trans from '../../../../../components/trans';

const AuthFooter = ({ step, changeStep }) => {
  const handleChangeStep = () => {
    if (step === 'login') {
      changeStep('registration');
    } else {
      changeStep('login');
    }
  };
  return (
    <div className="auth-footer">
      <span className="auth-footer__text">
        {step === 'login' ? Trans('login.noAccount') : Trans('login.haveAccount')}
      </span>
      <Button onClick={handleChangeStep} className="auth-footer__button" variant="text">
        {step === 'login' ? Trans('login.register') : Trans('login.signIn')}
      </Button>
    </div>
  );
};

export default AuthFooter;
