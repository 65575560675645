import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.34783 9.46C9.34783 9.60132 9.35338 9.74138 9.3643 9.88H6.73913C5.77864 9.88 5 10.6322 5 11.56V23.32C5 24.2479 5.77863 25 6.73913 25H23.2609C24.2214 25 25 24.2479 25 23.32V11.56C25 10.6322 24.2214 9.88 23.2609 9.88H20.6357C20.6466 9.74138 20.6522 9.60132 20.6522 9.46C20.6522 6.44453 18.1217 4 15 4C11.8784 4 9.34783 6.44453 9.34783 9.46ZM11.5217 9.46C11.5217 9.60224 11.5309 9.74241 11.5487 9.88H18.4514C18.4691 9.74241 18.4783 9.60224 18.4783 9.46C18.4783 7.60432 16.921 6.1 15 6.1C13.079 6.1 11.5217 7.60432 11.5217 9.46ZM16.7401 15.34C16.7401 16.0112 16.3325 16.5906 15.7435 16.8596C15.7775 16.9153 15.806 16.9769 15.8279 17.0442L16.9016 20.3377C17.0781 20.879 16.6597 21.43 16.0722 21.43H13.9248C13.3374 21.43 12.9189 20.879 13.0954 20.3377L14.1691 17.0442C14.1913 16.9762 14.2202 16.9141 14.2546 16.8579C13.6676 16.5881 13.2618 16.0098 13.2618 15.34C13.2618 14.4121 14.0404 13.66 15.001 13.66C15.9615 13.66 16.7401 14.4121 16.7401 15.34Z"
      fill="currentColor"
    />
  </svg>
);
