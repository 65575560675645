import React from 'react';

import Skeleton from '../../../components/skeleton';

const SkeletonFriends = () => {
  return (
    <div className="friends-block">
      <div className="friends-block__top">
        <Skeleton className="friends-block-sk-text" />
        <Skeleton className="friends-block-sk-btn" />
      </div>
      <div className="avatars-block">
        <Skeleton className="friends-block-sk-circle" />
        <Skeleton className="friends-block-sk-circle" />
        <Skeleton className="friends-block-sk-circle" />
        <Skeleton className="friends-block-sk-circle" />
        <Skeleton className="friends-block-sk-circle" />
      </div>
    </div>
  );
};

export default SkeletonFriends;
