import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions, useSelector } from '../../../../hooks/store';
import { msgActions } from '../../../../store/msg';
import { classNames as cx } from '../../../../helpers/utility';
import MessageInput from '../input/input';
import ButtonIcon from '../buttons/button-icon';

import './styles.scss';

function MessagesFooter({ isEditMode, offEditMode, editMessage, messageToBeEdited }) {
  const [text, setText] = useState('');

  const { t } = useTranslation();

  const { sendMessage } = useActions(msgActions);

  useEffect(() => {
    if (messageToBeEdited) {
      setText(messageToBeEdited.text);
    }
  }, [messageToBeEdited]);

  const handleChangeText = (e) => setText(e.target.value);

  const handleClick = () => {
    if (text) {
      if (isEditMode) {
        editMessage(text);
        offEditMode();
        setText('');
      } else {
        sendMessage(text);
        setText('');
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && text) {
      if (isEditMode) {
        editMessage(text);
        offEditMode();
        setText('');
      } else {
        sendMessage(text);
        setText('');
      }
    }
  };

  const offEditModeAction = () => {
    offEditMode();
    setText('');
  };

  const classNames = cx('msg-footer', {
    'msg-footer--edit': isEditMode,
  });

  return (
    <div className={classNames}>
      <div className="msg-footer__input-wrapper">
        <div className="msg-footer__row">
          <div className="msg-input-wrapper">
            <div className="msg-footer__edit-block">
              <div className="msg-footer__edit-block-wrap">
                <span className="msg-footer__edit-block-title">{t('pages.msg.editMessage')}</span>
                <span className="msg-footer__edit-block-text">{messageToBeEdited?.text}</span>
              </div>
              <ButtonIcon onClick={offEditModeAction} iconName="cross" />
            </div>
            <MessageInput
              onPressEnter={handleKeyDown}
              value={text}
              onChange={handleChangeText}
              placeholder={t('pages.msg.inputPlaceholder')}
            />
          </div>

          <ButtonIcon disabled={!text} onClick={handleClick} iconName="telegram" />
        </div>
      </div>
    </div>
  );
}

export default MessagesFooter;
