import React from 'react';
import { Link } from 'react-router-dom';

import { classNames as cx } from '../../../../helpers/utility';
import { Pages } from '../../../../routes/pages';

import dummy from '../../../../assets/image/user.svg';

const UserCardInfo = ({ blog }) => {
  const imageAvatar = blog?.user?.image_avatar || blog?.blog?.image;
  const classNames = cx('user-card__user-container__image', {
    'user-card__user-container__image_dummy': !(imageAvatar && imageAvatar.preview),
  });
  const urlPreview = imageAvatar && imageAvatar.preview ? imageAvatar.preview : dummy;
  const url = {
    pathname: Pages.profile.main.replace(':id?', blog?.user?.id),
    state: blog?.user?.id,
  };

  const teamUrl = {
    pathname: Pages.team.main.replace(':id', blog?.user?.team?.id),
    state: blog?.user?.team?.id,
  };

  const blogUrl = {
    pathname: Pages.blog.main.replace(':id', blog?.blog?.id),
    state: blog?.blog?.id,
  };

  return (
    <div className="user-card__user-container">
      {blog?.user?.image_avatar && (
        <Link to={url}>
          <div className={classNames}>
            <img src={urlPreview} alt="avatar" />
          </div>
        </Link>
      )}
      {blog?.blog?.image && (
        <Link to={blogUrl}>
          <div className={classNames}>
            <img src={urlPreview} alt="avatar" />
          </div>
        </Link>
      )}
      {!blog?.user?.image_avatar && !blog?.blog?.image && (
        <Link to={url}>
          <div className={classNames}>
            <img src={urlPreview} alt="avatar" />
          </div>
        </Link>
      )}
      <div className="user-card__user-container__info">
        {blog?.user?.nickname && (
          <Link to={url}>
            <div className="user-card__user-container__info__nickname">{blog?.user?.nickname}</div>
          </Link>
        )}
        {blog?.blog?.profile?.title && (
          <Link to={blogUrl}>
            <div className="user-card__user-container__info__nickname">
              {blog?.blog?.profile?.title}
            </div>
          </Link>
        )}
        {blog?.user?.team && (
          <Link to={teamUrl}>
            <div className="user-card__user-container__info__team">{blog?.user?.team?.title}</div>
          </Link>
        )}
        {blog?.blog?.profile?.sign && (
          <div className="user-card__user-container__info__team">{blog?.blog?.profile?.sign}</div>
        )}
      </div>
    </div>
  );
};

export default UserCardInfo;
