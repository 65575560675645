import React from 'react';
import addDays from 'date-fns/addDays';
import getHours from 'date-fns/getHours';
import isEqual from 'date-fns/isEqual';
import { format } from 'date-fns';

import CustomDatePicker from '../../../../../../components/datePicker';
import CustomInput from '../../../../../../components/input';

import './styles.scss';
import { getMinTime } from './helpers';

const DateSection = ({ dates, onChange, errors }) => {
  const maxTime = new Date().setHours(23, 59, 0);

  return (
    <div className="date-section date-section__container">
      <div className="date-section__top">
        <CustomDatePicker
          onChange={(d) => onChange(d, 'eventDateStart')}
          selected={dates.eventDateStart || null}
          name="eventDateStart"
          placeholderText="Укажите дату"
          minDate={addDays(new Date(), 1)}
          customInput={
            <CustomInput
              error={errors?.dateStart}
              required
              label="Дата начало игры *"
              labelPosition="top"
              name="eventDateStart"
            />
          }
        />
        <CustomDatePicker
          minDate={dates.eventDateStart || null}
          placeholderText="09:00"
          onChange={(d) => onChange(d, 'timeStart')}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          //minTime={minTime}
          //maxTime={maxTime}
          selected={dates.timeStart || null}
          customInput={<CustomInput label="Начало *" labelPosition="top" name="timeStart" />}
        />
        <CustomDatePicker
          disabled={!dates.eventDateStart}
          placeholderText="Укажите дату"
          onChange={(d) => onChange(d, 'eventDateEnd')}
          selected={dates.eventDateEnd || null}
          name="eventDateEnd"
          minDate={dates.eventDateStart || null}
          customInput={
            <CustomInput
              error={errors?.dateEnd}
              required
              label="Дата окончание игры *"
              labelPosition="top"
              name="eventDateEnd"
            />
          }
        />

        <CustomDatePicker
          disabled={!dates.eventDateStart}
          placeholderText="18:00"
          customInput={<CustomInput label="Конец *" labelPosition="top" />}
          onChange={(d) => onChange(d, 'timeEnd')}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          minTime={getMinTime(dates.eventDateStart, dates.eventDateEnd)}
          maxTime={maxTime}
          selected={dates.timeEnd || null}
        />
      </div>
      <div className="date-section__middle">
        <CustomDatePicker
          placeholderText="Укажите дату"
          onChange={(d) => onChange(d, 'arrivalDate')}
          selected={dates.arrivalDate || null}
          minDate={addDays(new Date(), 1)}
          maxDate={new Date(dates.eventDateStart)}
          disabled={!dates.eventDateStart}
          customInput={<CustomInput label="Дата заезда" labelPosition="top" name="arrivalDate" />}
        />
        <CustomDatePicker
          placeholderText="09:00"
          customInput={<CustomInput label="Время" labelPosition="top" />}
          onChange={(d) => onChange(d, 'arrivalTime')}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          //minTime={minTime}
          //maxTime={maxTime}
          selected={dates.arrivalTime || null}
        />
        <CustomDatePicker
          placeholderText="Укажите дату"
          customInput={
            <CustomInput label="Дата отъезда" labelPosition="top" name="departureDate" />
          }
          disabled={!dates.arrivalDate}
          onChange={(d) => onChange(d, 'departureDate')}
          selected={dates.departureDate || null}
          minDate={new Date(dates.arrivalDate)}
        />

        <CustomDatePicker
          placeholderText="18:00"
          customInput={<CustomInput label="Время" labelPosition="top" />}
          onChange={(d) => onChange(d, 'departureTime')}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          disabled={!dates.arrivalDate}
          minTime={getMinTime(dates.arrivalDate, dates.departureDate)}
          maxTime={maxTime}
          selected={dates.departureTime || null}
        />
      </div>
      <div className="date-section__bottom">
        <CustomDatePicker
          placeholderText="Укажите дату"
          customInput={
            <CustomInput label="Дата начала регистрации" labelPosition="top" name="regDate" />
          }
          onChange={(d) => onChange(d, 'regDateStart')}
          selected={dates.regDateStart || null}
        />
        <CustomDatePicker
          placeholderText="09:00"
          customInput={<CustomInput label="Время" labelPosition="top" />}
          onChange={(d) => onChange(d, 'regTimeStart')}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          //minTime={minTime}
          //maxTime={maxTime}
          selected={dates.regTimeStart || null}
        />
        <CustomDatePicker
          disabled={!dates.regDateStart}
          placeholderText="Укажите дату"
          customInput={
            <CustomInput label="Дата окончания регистрации" labelPosition="top" name="regDate" />
          }
          onChange={(d) => onChange(d, 'regDateEnd')}
          selected={dates.regDateEnd || null}
          minDate={new Date(dates.regTimeStart)}
        />
        <CustomDatePicker
          disabled={!dates.regDateStart}
          placeholderText="09:00"
          customInput={<CustomInput label="Время" labelPosition="top" />}
          onChange={(d) => onChange(d, 'regTimeEnd')}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="HH:mm"
          minTime={getMinTime(dates.regDateStart, dates.regDateEnd)}
          maxTime={maxTime}
          selected={dates.regTimeEnd || null}
        />
      </div>
    </div>
  );
};

export default DateSection;
