import { catchWrapper } from '../../../helpers/utility';
import { setGroundMainImage } from '../../../api/v2';

export default (data) => async (dispatch, getState) => {
  dispatch({ type: 'GROUND/SET_MAIN_IMAGE/REQUESTED', payload: data.id });

  const result = await catchWrapper(setGroundMainImage(data));

  if (result && result.result) {
    const { ground } = getState().grounds;

    ground.images = ground.images.map((image) => {
      const newImage = image;
      if (image.is_main) newImage.is_main = false;
      if (image.id === data.id) newImage.is_main = true;
      return newImage;
    });

    dispatch({ type: 'GROUND/SET_MAIN_IMAGE/SUCCEEDED', payload: ground });
    dispatch({ type: 'GROUND/SET_SNAPSHOT', payload: ground });
  } else {
    dispatch({
      type: 'GROUND/SET_MAIN_IMAGE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
