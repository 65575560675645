import React, { useEffect, useRef, useState } from 'react';

import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import { useActions, useSelector } from '../../../../hooks/store';
import { teamsActions } from '../../../../store/teams';
import { useResponsive } from '../../../../hooks/useResponsive';
import { cancelTokens, globalCancelFetch } from '../../../../api/v2';
import useDebounce from '../../../../hooks/useDebounce';

import TeamsList from '../../components/teamsList';
import MobileControls from '../../../components/mobile-controls';
import useOwnerData from '../../../../hooks/useOwnerData';

import './styles.scss';
import Trans from '../../../../components/trans';

const initGeo = {
  country: {},
  regionArr: [],
  selectedRegions: [],
  selectedCities: [],
  init: false,
};
const initCount = { min: 1, max: 1000 };
const initRecruitment = false;
const rangeName = Trans('teams.filter.members');

const pageMainUrl = '/teams/list';

const AllTeams = ({ navigationMap, search, onChangeSearch }) => {
  const { isMobile } = useResponsive();
  const [isFilterOpened, toggleFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [geoLocal, setGeoLocal] = useState(initGeo);
  const [count, setCount] = useState(initCount);
  const [recruitment, setRecruitment] = useState(initRecruitment);
  const containerEl = useRef(null);

  const debouncedSearchTerm = useDebounce(search, 500);
  const { getAllTeams, onClearGeneralPages } = useActions(teamsActions);
  const { teams, pagination, loading } = useSelector((state) => state.teams);
  const { userId } = useSelector((state) => state.auth);
  const { team, isLoading } = useOwnerData();

  const getList = (p) => {
    getAllTeams({
      page: p,
      members: count,
      geoLocal,
      recruitment,
      query: debouncedSearchTerm || null,
    });
  };

  const onChangeComplete = () => {
    setPage(1);
    onClearGeneralPages();
    if (cancelTokens[pageMainUrl]) {
      cancelTokens[pageMainUrl]();
    }
    getList(1);
  };

  useEffect(() => {
    onChangeComplete();
  }, [geoLocal, recruitment, debouncedSearchTerm, count]);

  useEffect(
    () => () => {
      onClearGeneralPages();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const handleVisibleFilter = (value) => {
    toggleFilter(value);
  };
  const handleChangeCount = (value) => setCount(value);

  const handleChangeCountry = (value) => {
    setGeoLocal({ ...initGeo, country: value, init: true });
  };

  const handleChangeGeo = (value) => {
    const selectedRegions = value
      ? value.filter((item) => item.isRegion).map((item) => item.region_id)
      : [];
    const selectedCities = value ? value.filter((item) => !item.isRegion) : [];
    setGeoLocal({
      ...geoLocal,
      regionArr: value
        ? value.filter((item) => item.isRegion || selectedRegions.indexOf(item.region_id) === -1)
        : value,
      selectedRegions,
      selectedCities,
      init: true,
    });
  };

  const handleClearFilter = () => {
    setRecruitment(initRecruitment);
    setCount(initCount);
    setGeoLocal({
      ...geoLocal,
      country: {},
      regionArr: [],
      selectedRegions: [],
      selectedCities: [],
      init: false,
    });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setCount({ ...count, [name]: value });
  };

  const handleChangeRecruitment = () => {
    setRecruitment(!recruitment);
  };

  const fetchPaginated = () => {
    const newPage = page + 1;
    setPage(newPage);
    getList(newPage);
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  useEffect(() => {
    if (teams?.length && hasMore && document.body.offsetHeight > containerEl.current.offsetHeight) {
      fetchPaginated();
    }
  }, [teams]);

  const goToCreateTeam = () => {
    history.push({
      pathname: Pages.team.create.main,
    });
  };

  return (
    <div ref={containerEl} className="all-team-page">
      {isMobile && (
        <MobileControls
          placeholder={Trans('teams.filter.findPlaceholder')}
          options={navigationMap}
          needSettings
          handleVisibleFilter={handleVisibleFilter}
          isFilterOpened={isFilterOpened}
          isAddButton={!team && !isLoading}
          addClick={goToCreateTeam}
          addButtonIcon="add"
          search={search}
          onChangeSearch={onChangeSearch}
        />
      )}
      <TeamsList
        isAddButton={!team && !isLoading}
        onClickAdd={goToCreateTeam}
        userId={userId}
        teams={teams}
        fetchPaginated={fetchPaginated}
        hasMore={hasMore}
        loading={loading}
        handleChangeAge={handleChangeCount}
        age={count}
        handleChangeInput={handleChangeInput}
        handleChangeGeo={handleChangeGeo}
        handleChangeCountry={handleChangeCountry}
        geoLocal={geoLocal}
        rangeName={rangeName}
        recruitment={recruitment}
        isFilterOpened={isFilterOpened}
        onChangeComplete={onChangeComplete}
        handleClearFilter={handleClearFilter}
        handleChangeRecruitment={handleChangeRecruitment}
        handleVisibleFilter={handleVisibleFilter}
      />
    </div>
  );
};

export default AllTeams;
