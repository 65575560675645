import React from 'react';
import { Switch } from 'react-router-dom';

import { localeContent } from '../../../locale/ru';
import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderRight from '../../../components/headerConstructor/components/right';

import CategoryPage from './main';

import './styles.scss';

const PagesSectionDesktop = () => {
  return (
    <>
      <HeaderConstructor className="pages">
        <HeaderLeft title={localeContent.Pages.category.pageTitle} />
        <HeaderRight>
          <Controls isShowLang />
          <Menu />
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute exact path={Pages.pages.category} component={CategoryPage} />
        </Switch>
      </div>
    </>
  );
};

export default PagesSectionDesktop;
