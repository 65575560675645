import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../../routes/privateRoute';

import { editorActions } from '../../../store/editor';
import { useActions, useSelector } from '../../../hooks/store';
import { eventsActions } from '../../../store/events';
import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';
import { getEventSettingPath } from '../helpers/navigations';
import { getIdFromUrl } from '../../../helpers/utility';

import useOwnerData from '../../../hooks/useOwnerData';
import UpcomingPage from './upcoming';
import PastPage from './past';
import AllPage from './all';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderRight from '../../../components/headerConstructor/components/right';
import InputSearch from '../../../components/inputSearch';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';
import Button from '../../../components/button';
import PricesPage from './prices';

import Icon from '../../../components/icon';
import CreateEventPage from './create';

import EventContainer from '../containers/eventContainer';

import './styles.scss';
import ChangeEventView from '../components/changeEventView';

const EventsSettingWrapper = React.lazy(() => import('./settingWrapper'));

const getTitle = (location, event) => {
  if (location.pathname.indexOf(Pages.event.eventBase.replace(':id', location.state)) !== -1) {
    return event?.profile?.title || 'Игра';
  }
  switch (location.pathname) {
    case Pages.events.create:
      return 'Новая игра';
    default:
      return 'Игры';
  }
};
const getActions = ({ location, eventId, actions, eventSettings, deleteTextEditorImages }) => {
  const {
    onCreateEvent,
    onSaveEventSettingsScenario,
    onGetEventSettingsScenario,
    onSaveEventSettingsPrices,
    onGetEventSettingsPrices,
    onSaveEventSettingsAccess,
    onGetEventSettingsAccess,
    onSaveEventSettingsRulesAdd,
    onGetEventSettingsInfoRulesAdd,
    onGetEventSettingsInfoAgreeAdd,
    onSaveEventSettingsAgreeAdd,
    onGetEventSettingsPolygon,
    onSaveEventSettingsPolygon,
    onSaveEventSettingsResults,
    onGetEventSettingsResults,
  } = actions;

  const path = location?.hash ? `${location?.pathname}${location?.hash}` : location?.pathname;
  switch (path) {
    case Pages.event.settings.create:
      return onCreateEvent;
    case Pages.event.settings.edit.replace(':id', eventId):
      return onCreateEvent;
    case Pages.event.settings.scenario.replace(':id', eventId):
      return async () => {
        const data = {
          images: [],
          page_id: 2,
          parent_id: Number(eventId),
          type: 'event',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveEventSettingsScenario(
          '2',
          eventId,
          'event',
          '1',
          eventSettings?.scenario?.text,
        );
        await onGetEventSettingsScenario('2', eventId, 'event', '1');
      };
    case Pages.event.settings.price.replace(':id', eventId):
      return async () => {
        const data = {
          images: [],
          page_id: 5,
          parent_id: Number(eventId),
          type: 'event',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveEventSettingsPrices('5', eventId, 'event', '1', eventSettings?.prices?.text);
        await onGetEventSettingsPrices('5', eventId, 'event', '1');
      };
    case Pages.event.settings.allow.replace(':id', eventId):
      return async () => {
        const data = {
          images: [],
          page_id: 3,
          parent_id: Number(eventId),
          type: 'event',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveEventSettingsAccess('3', eventId, 'event', '1', eventSettings?.access?.text);
        await onGetEventSettingsAccess('3', eventId, 'event', '1');
      };
    case `${Pages.event.settings.info.replace(':id', eventId)}#rulesAdditional`:
      return async () => {
        const data = {
          images: [],
          page_id: 1,
          parent_id: Number(eventId),
          type: 'event',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveEventSettingsRulesAdd(
          '1',
          eventId,
          'event',
          '1',
          eventSettings?.additionalRules?.text,
        );
        await onGetEventSettingsInfoRulesAdd('1', eventId, 'event', '1');
      };
    case `${Pages.event.settings.info.replace(':id', eventId)}#agreementsAdditional`:
      return async () => {
        const data = {
          images: [],
          page_id: 11,
          parent_id: Number(eventId),
          type: 'event',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveEventSettingsAgreeAdd(
          '11',
          eventId,
          'event',
          '1',
          eventSettings?.additionalAgreement?.text,
        );
        await onGetEventSettingsInfoAgreeAdd('11', eventId, 'event', '1');
      };
    case Pages.event.settings.location.replace(':id', eventId):
      return async () => {
        const data = {
          images: [],
          page_id: 4,
          parent_id: Number(eventId),
          type: 'event',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveEventSettingsPolygon('4', eventId, 'event', '1', eventSettings?.polygon?.text);
        await onGetEventSettingsPolygon('4', eventId, 'event', '1');
      };
    case Pages.event.settings.results.replace(':id', eventId):
      return async () => {
        const data = {
          images: [],
          page_id: 8,
          parent_id: Number(eventId),
          type: 'event',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveEventSettingsResults('8', eventId, 'event', '1', eventSettings?.results?.text);
        await onGetEventSettingsResults('8', eventId, 'event', '1');
      };
    default:
      return null;
  }
};

const EventsSectionDesktop = (rest) => {
  const {
    search,
    onChangeSearch,
    navigationMap,
    isEventMainPage,
    isCreateEventPage,
    isEventEditPage,
  } = rest;

  const { location, goBack } = history;
  const { event } = useSelector((s) => s.events);
  const { sponsorId, sponsorRole } = useOwnerData();

  const eventActions = useActions(eventsActions);
  const { deleteTextEditorImages } = useActions(editorActions);
  const { loading, eventSettings } = useSelector((s) => s.events);
  const isShowSearch = !(isEventMainPage || isCreateEventPage);
  const isShowNavigation = !isCreateEventPage;

  const isParticipantsPage = !!useRouteMatch(
    Pages.event.participants.replace(':id', getIdFromUrl(location.pathname)),
  );

  const isAgreementPage = !!useRouteMatch(
    Pages.event.agreement.replace(':id', getIdFromUrl(location.pathname)),
  );

  const isInfoRulesPage =
    !!useRouteMatch(Pages.event.info.replace(':id', getIdFromUrl(location.pathname))) &&
    location.hash === '#rules';

  const isOfferPage = !!useRouteMatch(
    Pages.event.offer.replace(':id', getIdFromUrl(location.pathname)),
  );

  const isShowEditBtn =
    sponsorId === event?.sponsor?.id &&
    sponsorRole &&
    !isEventEditPage &&
    !isParticipantsPage &&
    !isAgreementPage &&
    !isInfoRulesPage &&
    !isOfferPage;

  const handleGoToMainPage = () => goBack();

  const handleGoToEditEvent = () => getEventSettingPath(location, getIdFromUrl(location.pathname));

  const buttonActions = getActions({
    location,
    eventId: getIdFromUrl(location.pathname),
    actions: eventActions,
    eventSettings,
    deleteTextEditorImages,
  });

  const handleSubmit = async () => {
    await buttonActions();
    await handleGoToMainPage();
  };

  return (
    <>
      <HeaderConstructor className="events">
        <HeaderLeft
          title={getTitle(location, event)}
          search={
            isShowSearch ? (
              <>
                <InputSearch value={search} onChange={onChangeSearch} />
                <ChangeEventView />
              </>
            ) : null
          }
          navigation={isShowNavigation ? <TabletNavigation naviMap={navigationMap} /> : null}
        />
        <HeaderRight>
          {isShowEditBtn ? (
            <div className="edit-event__wrap">
              <Button onClick={handleGoToEditEvent} variant="second" className="edit-event">
                Редактировать
              </Button>
            </div>
          ) : null}
          {isCreateEventPage || isEventEditPage ? (
            <>
              <Button
                loading={loading}
                onClick={isCreateEventPage ? buttonActions : handleSubmit}
                className="create-event"
                variant="main"
              >
                {isCreateEventPage ? 'Создать игру' : 'Сохранить'}
              </Button>
              <Button onClick={handleGoToMainPage} className="go-back" variant="icon">
                <Icon type="add" />
              </Button>
            </>
          ) : (
            <>
              <Controls isShowLang />
              <Menu />
            </>
          )}
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute exact path={Pages.events.upcoming} component={UpcomingPage} />
          <PrivateRoute exact path={Pages.events.past} component={PastPage} />
          <PrivateRoute
            exact
            path={Pages.events.all}
            component={AllPage}
            extraProps={{ ...rest }}
          />

          <PrivateRoute path={Pages.event.base} component={EventContainer} />
          <PrivateRoute exact path={Pages.event.settings.create} component={CreateEventPage} />
          <PrivateRoute
            withAccess={event?.sponsor?.id && sponsorId}
            hasAccess={event?.sponsor?.id === sponsorId}
            redirectPath={{
              pathname: Pages.event.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.event.settings.base}
            component={EventsSettingWrapper}
          />

          <Redirect to={navigationMap[0].path} />
        </Switch>
      </div>
    </>
  );
};

export default EventsSectionDesktop;
