import { catchWrapper } from '../../../helpers/utility';
import { postSecurity } from '../../../api';

export default () => async (dispatch) => {
  dispatch({ type: 'AUTH/RESENT_CODE/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'submitEmailCode');

  const result = await catchWrapper(postSecurity(formData));

  if (result?.result) {
    dispatch({ type: 'AUTH/RESENT_CODE/SUCCEEDED', payload: result.message[0] });
  } else {
    dispatch({ type: 'AUTH/RESENT_CODE/FAILED', payload: result.message[0] });
  }
};
