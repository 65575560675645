import React from 'react';
import CustomInput from '../../../components/input';

const StepEmail = ({ error, onChange, email }) => {
  return (
    <div className="change-psw__wrap step-email">
      <CustomInput
        onChange={onChange}
        value={email}
        placeholder="Введите свой E-mail"
        labelPosition="top"
        name="email"
        type="email"
      />
      <div className="error__wrap">
        <span className="change-psw__error">{error}</span>
      </div>
    </div>
  );
};

export default StepEmail;
