import { catchWrapper } from '../../helpers/utility';
import { postCamouflages } from '../../api';
import { goToNotFound } from '../../routes/actions';

const initState = {
  loading: 0,
  camouflage: [],
  error: '',
};

export const camouflageReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CAMOUFLAGE/LOAD/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case 'CAMOUFLAGE/LOAD/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        camouflage: action.payload,
      };
    }
    case 'CAMOUFLAGE/LOAD/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: action.payload,
      };
    }
    case 'CAMOUFLAGE/UNMOUNT': {
      return initState;
    }
    default:
      return state;
  }
};

const getCamouflageData = (id) => async (dispatch) => {
  dispatch({ type: 'CAMOUFLAGE/LOAD/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getCamouflage');
  formData.append('id', id.toString());
  const result = await catchWrapper(postCamouflages(formData));
  if (result && result.result) {
    dispatch({ type: 'CAMOUFLAGE/LOAD/SUCCEEDED', payload: result && result.camouflage });
  } else {
    goToNotFound();
    dispatch({
      type: 'CAMOUFLAGE/LOAD/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
const unmountCamouflageData = () => async (dispatch) => {
  dispatch({ type: 'CAMOUFLAGE/UNMOUNT' });
};
export const camouflageActions = {
  getCamouflageData,
  unmountCamouflageData,
};
