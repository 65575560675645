import React from 'react';
import ExpandCollapse from 'react-expand-collapse';
import { ReactPhotoCollage } from 'react-photo-collage';

import { useResponsive } from '../../../hooks/useResponsive';
import './styles.scss';
import Icon from '../../../components/icon';
import Button from '../../../components/button';
import { classNames as cx } from '../../../helpers/utility';
import CommentsWithAttach from '../commentsWithAttach';
import Comments from '../comments';

const text =
  'Разнообразный и богатый опыт рамки и место обучения кадров позволяет выполнять важные задания по разработке модели развития. Разнообразный и богатый опыт консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании систем массового участия. Равным образом начало повседневной работы. Разнообразный и богатый опыт консультация с широким активом обеспечивает.';

const NewsCard = ({ userData }) => {
  const { isTablet, isMobile, isDesktop } = useResponsive();
  const setting = {
    width: '100%',
    height: isMobile ? ['150px', '80px'] : ['200px', '200px'],
    layout: isMobile ? [2, 3] : [2, 3],
    photos: [
      {
        src:
          'https://images.unsplash.com/photo-1526656892012-7b336603ed46?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=31c8e58b58c25dfcc18452ed29b52951&auto=format&fit=crop&w=334&q=80',
      },
      {
        src:
          'https://images.unsplash.com/photo-1521024221340-efe7d7fa239b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=9ad8a99d809d3fa3a9e8dff3ecc81878&auto=format&fit=crop&w=750&q=80',
      },
      {
        src:
          'https://images.unsplash.com/photo-1523038793606-2fd28f837a85?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=919b76f4229e41416653aeb10e84e94a&auto=format&fit=crop&w=334&q=80',
      },
      {
        src:
          'https://images.unsplash.com/photo-1516832970803-325be7a92aa5?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=93d7ac9abad6167aecb49ebd67fd5b6d&auto=format&fit=crop&w=751&q=80',
      },
      {
        src:
          'https://images.unsplash.com/photo-1526938972776-11558ad4de30?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=973795a277e861265b0fabbf4a96afe2&auto=format&fit=crop&w=750&q=80',
      },
      {
        src:
          'https://images.unsplash.com/photo-1464550838636-1a3496df938b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f22dbf6c13ea7c21e803aa721437b691&auto=format&fit=crop&w=888&q=80',
      },
    ],
    showNumOfRemainingPhotos: true,
  };
  const options = {
    previewHeight: '140px',
    expandText: 'Показать все',
    collapseText: 'Скрыть',
    collapse: true,
  };
  const classNames = cx('news-card__image', {
    'news-card__image_dummy': !userData.avatar_preview,
  });
  const acaUrl = userData && `${userData.avatar_url}${userData.avatar_preview}`;
  const dummy = isDesktop ? <Icon type="profile" /> : <Icon type="profileMobile" />;
  return (
    <div className="card news-card">
      <div className="news-card__header">
        <div className="news-card__user-info">
          <div>
            <Button onClick={() => {}} variant="submenu">
              <div className="news-card__content">
                <div className={classNames}>{acaUrl ? <img src={acaUrl} alt="" /> : dummy}</div>
              </div>
            </Button>
          </div>
          <div className="news-card__info-text">
            <div className="news-card__nickname">
              <span>{userData?.nickname}</span>
            </div>
            <div className="news-card__timestamp">
              <span>Сегодня в 12:45</span>
            </div>
          </div>
        </div>
        <div className="news-card__dots">
          <Button onClick={() => {}} variant="submenu">
            <Icon type="tripleDots" />
          </Button>
        </div>
      </div>
      <div className="news-card__news-container">
        <div className="news-card__media">
          <ReactPhotoCollage {...setting} />
        </div>
        <div className="news-card__text">
          {isMobile && (
            <ExpandCollapse {...options} className="news-card__text">
              {text}
            </ExpandCollapse>
          )}
          {!isMobile && <div className="news-card__text">{text}</div>}
        </div>
      </div>
      <div className="news-card__news-actions">
        <div className="news-card__news-actions-item">
          <div className="news-card__news-actions-item__comment-icon">
            <Icon type="comment" />
          </div>
          <div className="news-card__news-actions-item__text">10</div>
        </div>
        <div className="news-card__news-actions-item news-card__news-actions-item__like">
          <div className="news-card__news-actions-item__like-icon">
            <Icon type="like" />
          </div>
          <div className="news-card__news-actions-item__text">63</div>
        </div>
        <div className="news-card__news-actions-item">
          <div className="news-card__news-actions-item__repost-icon">
            <Icon type="repost" />
          </div>
          <div className="news-card__news-actions-item__text">9</div>
        </div>
      </div>
      {!isMobile && <div className="news-card__divider" />}
      {!isMobile && <CommentsWithAttach userData={userData} />}
      {!isMobile && <Comments userData={userData} />}
    </div>
  );
};

export default NewsCard;
