import React from 'react';

import './styles.scss';

const SocialButtons = ({ social, services }) => {
  return (
    <div className="social-buttons">
      {services &&
        social &&
        services.map((item) => {
          return social[item.key].value ? (
            <a key={item.id} href={social[item.key].value} target="_blank" rel="noreferrer">
              <img src={item.icon} alt={item.title} />
            </a>
          ) : null;
        })}
    </div>
  );
};

export default SocialButtons;
