import { catchWrapper } from '../../../helpers/utility';
import { deleteTextArticleImages } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

const getItemsToDelete = (state, allMatches, type) => {
  if (type === 'save') {
    const allNecessaryMatches = [...state?.editorChangesData, ...allMatches];
    return allNecessaryMatches.filter((item) => !state?.editorChangesData.includes(item));
  }
  return allMatches.filter((item) => !state?.editorSnapshotData.includes(item));
};

export default ({ data }) => async (dispatch, getState, type) => {
  const editorState = getState().editor;
  const allIdsArr = editorState?.matches;
  const unique = [...new Set(allIdsArr)];
  const delArr = getItemsToDelete(editorState, unique, type);
  if (delArr?.length) {
    dispatch({ type: 'TEXT/DELETE_IMAGES/REQUESTED' });
    const result = await catchWrapper(deleteTextArticleImages(data));
    if (result && result.result) {
      dispatch({ type: 'TEXT/DELETE_IMAGES/SUCCEEDED' });
      dispatch({ type: 'EDITOR/UNMOUNT' });
    } else {
      goToNotFound();
      dispatch({
        type: 'TEXT/DELETE_IMAGES/FAILED',
        payload: result && result.message && result.message[0],
      });
    }
  } else {
    dispatch({ type: 'EDITOR/UNMOUNT' });
  }
};
