import getLastList from './impl/getLastList';
import getAchievementList from './impl/getAchievementList';
import clearAchievements from './impl/clearAchievements';

const initState = {
  loading: 0,
  achievements: [],
  awards: [],
  titles: [],
  list: [],
  pagination: null,
};

export const achievementsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'ACHIEVEMENTS/GET_LAST_LIST/REQUESTED':
    case 'ACHIEVEMENTS/GET_LIST/REQUESTED':
      return {
        ...state,
        loading: state.loading + 1,
      };
    case 'ACHIEVEMENTS/GET_LAST_LIST/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        achievements: action.payload.achievements,
        awards: action.payload.awards,
        titles: action.payload.titles,
        qtyOfAchievements: action.payload.qty_of_achievements,
        qtyOfAwards: action.payload.qty_of_awards,
        qtyOfTitles: action.payload.qty_of_titles,
      };
    case 'ACHIEVEMENTS/GET_LIST/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        list: [...state.list, ...action.payload.items],
        pagination: action.payload.pagination,
      };

    case 'ACHIEVEMENTS/GET_LAST_LIST/FAILED':
      return {
        ...state,
        loading: state.loading - 1,
      };
    case 'ACHIEVEMENTS/CLEAR_ACHIEVEMENTS':
      return {
        ...state,
        ...initState,
      };
    default:
      return state;
  }
};

export const achievementsActions = {
  getLastList,
  getAchievementList,
  clearAchievements,
};
