import React, { ReactElement } from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.88872 19.4765C14.7978 19.4765 18.7774 15.4969 18.7774 10.5879C18.7774 8.70044 18.1891 6.9504 17.1859 5.51112C19.4916 7.11696 21.0005 9.78737 21.0005 12.8102C21.0005 17.7193 17.021 21.6988 12.1119 21.6988C9.09028 21.6988 6.42081 20.1911 4.81476 17.887C6.25351 18.889 8.0025 19.4765 9.88872 19.4765Z"
      fill="#BFCEE0"
    />
    <path
      d="M12 13.1994V9.44936C12 9.03514 12.3358 8.69936 12.75 8.69936C13.1642 8.69936 13.5 9.03514 13.5 9.44936V13.9494C13.5 14.3636 13.1642 14.6994 12.75 14.6994H9.25C8.83579 14.6994 8.5 14.3636 8.5 13.9494C8.5 13.5351 8.83579 13.1994 9.25 13.1994H12Z"
      fill="#285A95"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22854 5.07977C9.36498 4.51618 10.6455 4.19936 12 4.19936C16.6944 4.19936 20.5 8.00494 20.5 12.6994C20.5 17.3938 16.6944 21.1994 12 21.1994C7.30558 21.1994 3.5 17.3938 3.5 12.6994C3.5 11.6433 3.69259 10.6322 4.04455 9.69936H2.4578C2.16035 10.6464 2 11.6542 2 12.6994C2 18.2222 6.47715 22.6994 12 22.6994C17.5228 22.6994 22 18.2222 22 12.6994C22 7.17651 17.5228 2.69936 12 2.69936C10.3696 2.69936 8.83035 3.08953 7.4705 3.7816L7.86953 2.23864C7.97325 1.83762 7.73223 1.42846 7.33121 1.32475C6.93019 1.22103 6.52102 1.46205 6.41731 1.86307L5.54098 5.25159C5.46823 5.53291 5.56513 5.81825 5.7679 5.99932C5.87669 6.12463 6.02757 6.21482 6.20373 6.24588L9.65055 6.85365C10.0585 6.92558 10.4475 6.6532 10.5194 6.24528C10.5913 5.83736 10.3189 5.44836 9.91103 5.37644L8.22854 5.07977Z"
      fill="#285A95"
    />
  </svg>
);
