import React, { useEffect } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { profileActions } from '../../../../store/profile';

import { classNames as cx } from '../../../../helpers/utility';
// import SelectedLanguage from '../../components/selectedLang';
// import PortalLanguage from '../../components/portalLang';
import ImagePicker from '../../../../components/imagePicker';
import CustomInput from '../../../../components/input';

import dummy from '../../../../assets/image/user.svg';
import './profile.scss';

const SettingsProfileMobile = () => {
  const { onUserClone, onChangeField, saveAvatar, onChangeError } = useActions(profileActions);
  const { user, error } = useSelector((state) => state.profile);
  const { image_avatar: imageAvatar } = user;

  useEffect(() => {
    onUserClone();
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    onChangeField(name, value);
    onChangeError({
      ...error,
      [name]: null,
    });
  };

  const urlPreview =
    imageAvatar && imageAvatar.preview ? imageAvatar && imageAvatar.preview : dummy;

  const classNames = cx('card__photo', {
    card__photo_dummy: !(imageAvatar && imageAvatar.preview),
  });

  return (
    <div className="page-profile-mobile">
      <div className="card-photo__wrap">
        <img className={classNames} src={urlPreview} alt="" />
        <div className="card__photo-button-wrap">
          <ImagePicker
            aspectRatio={1}
            minHeight={360}
            minWidth={360}
            onSubmit={saveAvatar}
            className="add-photo"
          />
        </div>
      </div>
      <CustomInput
        name="nickname"
        label="Позывной"
        labelPosition="top"
        placeholder="placeholder"
        value={user?.nickname}
        onChange={handleChangeInput}
        maxLength="16"
        error={error?.nickname}
        required
      />
      <CustomInput
        value={user.about}
        name="about"
        placeholder="Напишите что-нибудь о себе"
        type="textarea"
        onChange={handleChangeInput}
        label="О себе"
        labelPosition="top"
      />
      {/*<SelectedLanguage />*/}
      {/*<PortalLanguage />*/}
    </div>
  );
};

export default SettingsProfileMobile;
