import { catchWrapper } from '../../../helpers/utility';
import { getBlogAlbumImages } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ page, parentId, parentType, albumId }) => async (dispatch) => {
  const payloadRequest = {
    page,
    objects_per_page: 20,
    parent_id: Number(parentId),
    type: parentType,
    album_id: Number(albumId),
  };
  dispatch({ type: 'BLOG/GET_BLOG_ALBUM_IMAGES/REQUESTED' });
  const result = await catchWrapper(getBlogAlbumImages(payloadRequest));
  if (result?.result) {
    dispatch({
      type: 'BLOG/GET_BLOG_ALBUM_IMAGES/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'BLOG/GET_BLOG_ALBUM_IMAGES/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
