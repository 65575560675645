import React from 'react';

export default () => (
  <svg
    width="242"
    height="242"
    viewBox="0 0 242 242"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="170.88"
      height="170.88"
      rx="24"
      transform="matrix(0.707327 0.706886 -0.707327 0.706886 121 0)"
      fill="url(#paint0_linear)"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M110.088 153.12L109.005 152.813C107.884 152.495 106.702 152.325 105.485 152.325C98.4463 152.325 92.7004 157.984 92.7004 165.014C92.7004 167.954 93.7085 170.656 95.3931 172.803C94.9508 173.082 94.5203 173.383 94.1043 173.707L94.1041 173.707C90.7319 176.332 88.5 180.323 88.5 185.39C88.5 187.682 90.3685 189.499 92.6239 189.499H100.74H103.187L103.225 187.73C104.021 188.805 105.3 189.499 106.734 189.499H136.486H138.836H138.986H141.285H150.376C152.632 189.499 154.5 187.681 154.5 185.39C154.5 180.323 152.268 176.332 148.896 173.707L148.896 173.707C148.48 173.383 148.049 173.082 147.607 172.803C149.292 170.656 150.3 167.954 150.3 165.014C150.3 157.984 144.554 152.325 137.515 152.325C136.31 152.325 135.14 152.491 134.028 152.803L132.846 153.136C130.237 149.709 126.104 147.499 121.461 147.499C116.825 147.499 112.698 149.703 110.088 153.12Z"
        fill="white"
        stroke="#4D63FF"
        strokeWidth="5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="36.7054"
        y1="-11.4762"
        x2="179.107"
        y2="162.214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6E6E6" />
        <stop offset="0.630108" stopColor="#E6E6E6" />
        <stop offset="0.630208" stopColor="#3F52DC" stopOpacity="0" />
        <stop offset="0.630308" stopColor="#4C62FF" />
        <stop offset="1" stopColor="#4C62FF" />
      </linearGradient>
      <clipPath id="clip0">
        <rect
          width="65.6639"
          height="65.6229"
          fill="white"
          transform="translate(87.8101 135.522)"
        />
      </clipPath>
    </defs>
  </svg>
);
