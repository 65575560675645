import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

const filtersMap = [
  {
    id: '1',
    label: 'Новости',
    route: '/feed',
    children: [
      {
        id: '1',
        label: 'Записи',
        route: '/feed',
      },
      {
        id: '2',
        label: 'Фото',
        route: '/feed',
      },
      {
        id: '3',
        label: 'Видео',
        route: '/feed',
      },
    ],
  },
  {
    id: '2',
    label: 'Команда',
    route: '/feed',
  },
  {
    id: '3',
    label: 'Блок',
    route: '/feed',
  },
  {
    id: '4',
    label: 'Орггруппа',
    route: '/feed',
  },
  {
    id: '5',
    label: 'Друзья',
    route: '/feed',
  },
];
const FeedFilter = () => {
  return (
    <div className="feed-filter">
      <div className="feed-filter__drop">
        <ul>
          {filtersMap.map((item) => {
            return (
              <li key={item.id}>
                <NavLink
                  className="feed-filter__link"
                  activeClassName="feed-filter__link_active"
                  to={item.route}
                  onClick={() => {}}
                  key={item.id}
                >
                  <div className="feed-filter__link__row">
                    <span className="feed-filter__link__text">{item.label}</span>
                  </div>
                  {item &&
                    item.children &&
                    item.children.map((child) => {
                      return (
                        <li key={child.id}>
                          <NavLink
                            className="feed-filter__link"
                            activeClassName="feed-filter__link_active"
                            to={child.route}
                            onClick={() => {}}
                            key={child.id}
                          >
                            <div className="feed-filter__link__row__child">
                              <span className="feed-filter__link__text__child">{child.label}</span>
                            </div>
                          </NavLink>
                        </li>
                      );
                    })}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FeedFilter;
