import React from 'react';

import './styles.scss';
import CustomInput from '../../../../components/input';
import { classNames as cx } from '../../../../helpers/utility';
import Trans from '../../../../components/trans';

const MottoBlock = ({ motto, isEditable, handleChangeInput }) => {
  const classNames = cx('motto-block', {
    'motto-block__edit': isEditable,
  });

  return (
    <div className={classNames}>
      <CustomInput
        name="motto"
        disabled={!isEditable}
        label="teams.moto"
        labelPosition="top"
        placeholder="teams.motoPlaceholder"
        value={motto}
        onChange={handleChangeInput}
        type="textarea"
        textRows={3}
      />
    </div>
  );
};

export default MottoBlock;
