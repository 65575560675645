import React from 'react';

import CustomInput from '../../../../components/input';

const SkInfo = ({ user, onChange }) => (
  <>
    <div className="sk-info card">
      <h3 className="settings-passport__right__title">Синхронизация с базой СК</h3>
      <CustomInput
        label="Номер аусвайса"
        value={user.sk_number}
        name="sk_number"
        placeholder="Введите номер вашего аусвайса"
        onChange={onChange}
      />
    </div>
    <div className="settings-passport__description-text">
      <span className="settings-passport__description-text__text">
        <p>Подключая синхронизацию вы даёте согласие на получение данных хранящихся в базе СК</p>
      </span>
    </div>
  </>
);
export default SkInfo;
