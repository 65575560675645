import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 -8 18 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.47059 0C1.10612 0 0 1.20883 0 2.7V15.7781C0 17.0052 0.910253 18 2.03311 18C2.3191 18 2.60189 17.934 2.86297 17.8065L6.61135 15.9749C6.82273 15.8716 7.06413 15.8703 7.2764 15.9714L11.1479 17.8146C11.4047 17.9369 11.6817 18 11.9619 18C13.0875 18 14 17.0028 14 15.7726V2.7C14 1.20883 12.8939 0 11.5294 0H2.47059Z"
      fill="#C0B4AF"
    />
  </svg>
);
