import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '../../../components/skeleton';
import SkeletonFriends from './skeletonFriends';
import ButtonsBlock from './buttonsBlock';
import dummy from '../../../assets/image/user.svg';
import { classNames as cx, getUrl } from '../../../helpers/utility';
import './styles.scss';
import { useResponsive } from '../../../hooks/useResponsive';
import history from '../../../routes/history';
import SubscribersBlock from '../blogSubscribersBlock';

import Icon from '../../../components/icon';
import { Pages } from '../../../routes/pages';

const BlogOwner = ({
  blog,
  loading,
  subscribers,
  totalSubscribers,
  subscribeBlog,
  isRequestWasSent,
  unSubscribeBlog,
  getBlogSilent,
  loadingSilent,
  cancelFriendRequest,
  isAmSub,
  selfId,
  blogHolder,
}) => {
  const { isMobile } = useResponsive();
  const { location } = history;
  const imageAvatar = blog?.image;
  const urlPreview = imageAvatar && imageAvatar.preview ? `${imageAvatar.preview}` : dummy;
  const qrLink = getUrl(location?.state || blog.id, Pages.blog.qr);
  const classNames = cx('owner__photo', {
    owner__photo_dummy: !(imageAvatar && imageAvatar.preview),
    owner__photo_round: isMobile,
  });
  const classNamesImageWrap = cx('owner-photo__wrap', {
    'owner-photo__wrap_round': isMobile,
  });
  return (
    <div className="card owner-block">
      <div className="owner-block__top">
        <div className={classNamesImageWrap}>
          {loading ? (
            <Skeleton className="profile-avatar" />
          ) : (
            <img className={classNames} src={urlPreview} alt="" />
          )}
        </div>

        {loading ? (
          <>
            <Skeleton className="sk-owner-title" />
            <Skeleton className="sk-owner-info" />
          </>
        ) : (
          <div className="owner-block__user-info-wrap">
            <h3>{blog?.profile?.title}</h3>
            <span>{blog?.profile?.sign}</span>
          </div>
        )}

        <ButtonsBlock
          isRequestWasSent={isRequestWasSent}
          loading={loading}
          blogHolder={blogHolder}
          subscribeBlog={subscribeBlog}
          unSubscribeBlog={unSubscribeBlog}
          getBlogSilent={getBlogSilent}
          loadingSilent={loadingSilent}
          cancelFriendRequest={cancelFriendRequest}
          isSubscriber={isAmSub}
          selfId={selfId}
          blog={blog}
        />
      </div>

      <div className="owner-block__bottom">
        {loading ? (
          <SkeletonFriends />
        ) : (
          totalSubscribers && (
            <SubscribersBlock
              subscribers={subscribers}
              totalSubscribers={totalSubscribers}
              friends={blog?.friends_qty}
            />
          )
        )}
      </div>
      {isMobile && (
        <Link className="qr-code__wrap" to={qrLink}>
          <div className="qr-code__elem">
            <Icon type="qr" />
          </div>
        </Link>
      )}
    </div>
  );
};

export default BlogOwner;
