import React from 'react';

export default () => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4973 18.9524H11.8643"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.59336 18.9524H7.50293"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4973 13.4998H11.8643"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.59336 13.4998H7.50293"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4973 8.04766H11.8643"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.59336 8.04766H7.50293"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2388 1C19.2388 1 7.72217 1.00543 7.70417 1.00543C3.56376 1.02852 1 3.49457 1 7.25611V19.7439C1 23.5244 3.58326 26 7.75968 26C7.75968 26 19.2748 25.9959 19.2943 25.9959C23.4347 25.9728 26 23.5054 26 19.7439V7.25611C26 3.47556 23.4152 1 19.2388 1Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
