import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../hooks/store';
import { teamsActions } from '../../../../../../store/teams';
import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { globalCancelFetch } from '../../../../../../api/v2';

const Demands = ({ teamId }) => {
  const { onGetTeamSettingsDemand } = useActions(teamsActions);
  const { teamsSettings } = useSelector((state) => state?.teams);
  const demandText = teamsSettings?.demands?.text;

  useEffect(() => {
    if (teamId) {
      onGetTeamSettingsDemand('4', teamId, 'team', '1');
    }
    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [teamId]);

  return (
    <div>
      {demandText && (
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(demandText)} />
      )}
    </div>
  );
};

export default Demands;
