import React from 'react';

import { useResponsive } from '../../hooks/useResponsive';

import BlogsSectionMobile from './mobile';
import BlogsSectionDesktop from './desktop';
import { Pages } from '../../routes/pages';

const navigationMap = () => {
  return [
    {
      id: '1',
      path: Pages.blog.all,
      state: null,
      label: 'Блоги',
    },
    {
      id: '2',
      path: Pages.blog.subscribed,
      state: null,
      label: 'Подписан',
    },
  ];
};
const BlogsPage = () => {
  const { isMobile } = useResponsive();
  return isMobile ? (
    <BlogsSectionMobile />
  ) : (
    <BlogsSectionDesktop navigationMap={navigationMap()} />
  );
};

export default BlogsPage;
