import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';
import { useResponsive } from '../../../hooks/useResponsive';
import { getIdFromUrl } from '../../../helpers/utility';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';

import ProfilePage from './main';
import history from '../../../routes/history';
import NetworkState from '../../../components/headerConstructor/components/networkState';
import AwardsPage from './awards';

import './styles.scss';

const ProfileSectionDesktop = () => {
  const { isDesktop } = useResponsive();
  const { location } = history;
  const { user, anotherUser } = useSelector((state) => state.profile);

  const userId = getIdFromUrl(location.pathname) ? getIdFromUrl(location.pathname) : user?.id;
  const netState = getIdFromUrl(location.pathname) ? anotherUser?.state_online : user?.state_online;

  return (
    <>
      <HeaderConstructor className="profile">
        <HeaderLeft>
          {!isDesktop ? <SidebarButton /> : null}
          <HeaderTitle title={`id ${userId || ''}`} />
          <NetworkState status={netState} />
        </HeaderLeft>
        <HeaderRight>
          <Controls isShowLang />
          <Menu />
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute exact path={Pages.profile.main} component={ProfilePage} />
          <PrivateRoute path={Pages.profile.awards} component={AwardsPage} />
          <PrivateRoute path={Pages.profile.achievements} component={AwardsPage} />
          <PrivateRoute path={Pages.profile.titles} component={AwardsPage} />
          <Redirect to={Pages.profile.main} />
        </Switch>
      </div>
    </>
  );
};

export default ProfileSectionDesktop;
