import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.506 13.0666C16.506 14.8193 15.5951 16.3628 14.2093 17.2731C14.9581 17.5313 15.665 17.8954 16.2853 18.3709C17.6934 19.4511 18.6266 21.0788 18.6266 23.1997C18.6266 23.3288 18.5959 23.4497 18.54 23.5571C18.4227 23.784 18.202 23.9511 17.9393 24H4.82144C4.36881 24 4 23.6413 4 23.1997C4 21.0788 4.93599 19.4511 6.34417 18.3709C6.96444 17.8954 7.66853 17.5313 8.42012 17.2731C7.03429 16.3628 6.12065 14.8193 6.12065 13.0666C6.12065 10.269 8.44526 8 11.3147 8C14.1814 8 16.506 10.269 16.506 13.0666ZM17.5761 14.985C17.3051 15.7581 16.8832 16.4565 16.3439 17.0449C16.7518 17.2731 17.1402 17.538 17.5034 17.8424C19.0485 19.1345 20.0712 21.0842 20.0712 23.6223C20.0712 23.7568 20.046 23.8845 19.9985 24H25.3714C25.729 23.9347 26 23.6332 26 23.269C26 21.3329 25.1478 19.8465 23.8626 18.86C23.2954 18.4266 22.65 18.0938 21.9683 17.8587C22.7199 17.3641 23.3178 16.6657 23.6782 15.8451C23.9269 15.2854 24.0638 14.6671 24.0638 14.0177C24.0638 11.462 21.9431 9.3913 19.3251 9.3913C18.5121 9.3913 17.7493 9.58968 17.0816 9.94158C17.6012 10.8193 17.903 11.8465 17.9113 12.947C17.9169 13.6617 17.7996 14.3478 17.5761 14.985Z"
      fill="currentColor"
    />
  </svg>
);
