import React from 'react';

import './styles.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true, error };
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return (
        <div className="error-boundary-container">
          <h1>Что-то пошло не так.</h1>
          <span className="support">Обратитесь в службу поддержки</span>
          <span>Ошибка:</span>
          <span>{state.error.message}</span>
        </div>
      );
    }

    return props.children;
  }
}

export default ErrorBoundary;
