import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useActions, useSelector } from '../../../../hooks/store';
import { geoActions } from '../../../../store/geo';
import { sponsorAction } from '../../../../store/sponsor';
import useOwnerData from '../../../../hooks/useOwnerData';
import { goToProfile } from '../../../../routes/actions';
import { globalCancelFetch } from '../../../../api/v2';
import SponsorBannerBlock from '../../components/sponsorBannerBlock';
import BlockSettingMain from '../../components/blockSettingMain';
import ControlsSettingMain from '../../components/controlsSettingMain';
import JoditEditor from '../../../../components/textEditor2';
import { getIdFromUrl } from '../../../../helpers/utility';
import history from '../../../../routes/history';
import './styles.scss';

const initImages = {
  image: null,
  flag: null,
};

const SettingSponsorPage = () => {
  const editor = useRef(null);
  const { location } = history;
  const sponsorId = getIdFromUrl(location.pathname);
  const [images, setImages] = useState(initImages);

  const { isSponsor } = useOwnerData();
  const { id } = useParams();
  const { sponsor, sponsorSnapshot, errors, loading } = useSelector((state) => state.sponsor);
  const geo = useSelector((state) => state.geo);

  const { getCity, getRegion, getCountry } = useActions(geoActions);
  const {
    getSponsor,
    onChangeProfile,
    onChangeSponsorField,
    onChangeLocation,
    onChangeDate,
    onChangeField,
    onChangeAbout,
  } = useActions(sponsorAction);

  useEffect(() => {
    if (id) {
      getSponsor(id);
    }
    if (!isSponsor && !id) {
      goToProfile();
    }

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  useEffect(() => {
    onChangeField('sponsorImageTemp', images);
  }, [images]);

  const handleChangeImages = (name, image) => {
    if (errors && errors[name]) {
      const temp = {
        ...errors,
        [name]: null,
      };
      onChangeField('errors', temp);
    }
    setImages({
      ...images,
      [name]: image,
    });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (errors && errors[name]) {
      const temp = {
        ...errors,
        [name]: null,
      };
      onChangeField('errors', temp);
    }
    onChangeProfile(name, value);
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    onChangeSponsorField(name, checked);
  };

  const handleChangeEditor = (value) => {
    onChangeAbout(value);
  };

  const handleChangeSelect = (value, name) => {
    if (errors && errors[name]) {
      const temp = {
        ...errors,
        [name]: null,
      };
      onChangeField('errors', temp);
    }

    if (name === 'country') {
      onChangeLocation('country', value);
      onChangeLocation('region', {});
      onChangeLocation('city', {});
    }
    if (name === 'region') {
      onChangeLocation('region', value);
      onChangeLocation('city', {});
    }
    if (name === 'city') {
      onChangeLocation('city', value);
    }
  };

  const handleChangeDate = (date) => {
    const t = date ? moment(date).format('YYYY-MM-DD') : null;
    onChangeDate(t);
    if (errors && t) {
      const temp = {
        ...errors,
        date: null,
      };
      onChangeField('errors', temp);
    }
  };

  const onMenuOpenCountry = () => getCountry();
  const onMenuOpenRegion = () => getRegion(sponsor?.location?.country);
  const onMenuOpenCity = () => getCity(sponsor?.location?.country, sponsor?.location?.region);

  return (
    <div className="sponsor-page--settings">
      <BlockSettingMain
        sponsor={sponsor}
        isEditable
        handleChangeImages={handleChangeImages}
        handleChangeInput={handleChangeInput}
        handleChangeCheckbox={handleChangeCheckbox}
        imagesState={images}
        errors={errors}
      />
      <SponsorBannerBlock
        sponsor={sponsor}
        handleChangeImages={handleChangeImages}
        imagesState={images}
        isEditable
        errors={errors}
        loading={loading}
      />
      <ControlsSettingMain
        onMenuOpenCountry={onMenuOpenCountry}
        onMenuOpenRegion={onMenuOpenRegion}
        onMenuOpenCity={onMenuOpenCity}
        handleChangeInput={handleChangeInput}
        handleChangeSelect={handleChangeSelect}
        handleChangeDate={handleChangeDate}
        handleChangeEditor={handleChangeEditor}
        geo={geo}
        sponsor={sponsor}
        about={sponsor?.profile?.about}
        errors={errors}
      />
      <div className="editor__wrapper">
        <JoditEditor
          value={sponsor?.about}
          snapshotValue={sponsorSnapshot?.about}
          ref={editor}
          onChange={handleChangeEditor}
          onBlur={handleChangeEditor}
          pageId={1}
          parentId={Number(sponsorId)}
          type="sponsor"
          languageId={1}
        />
      </div>
    </div>
  );
};

export default SettingSponsorPage;
