import React, { memo } from 'react';
import isAfter from 'date-fns/isAfter';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import history from '../../../routes/history';
import { useResponsive } from '../../../hooks/useResponsive';
import { Pages } from '../../../routes/pages';
import { classNames as cx } from '../../../helpers/utility';

import Place from './components/place';
import ViewParticipants from './components/viewParticipants';
import MiddleBlock from './components/middleBlock';
import EventStage from './components/eventStage';

import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';

const EventCard = ({ event, id, anonymous }) => {
  const { isMobile } = useResponsive();
  const {
    profile,
    location,
    type,
    size,
    date_start: dateStart,
    date_end: dateEnd,
    prices,
    sponsor,
    qty_of_users: qtyOfUsers,
    qty_of_friends: qtyFriends,
  } = event;

  const toDay = Date.now();
  const isFinishedEvent = isAfter(new Date(toDay), new Date(dateEnd));
  const howManyDays = differenceInCalendarDays(new Date(dateStart), new Date(toDay));

  const fakePercentage = (howManyDays * 100) / 130;

  const price = prices && prices[0].price;
  const defaultPrice = prices && prices[0].default_price;

  const handleClickEvent = () => {
    history.push({
      pathname: Pages.event.main.replace(':id', event?.id),
      state: event && event.id,
    });
  };

  const frontImage = event?.images?.preview?.preview || null;

  const classNamesFront = cx('event-card__overlay event-card__front', {
    'event-card__front--flip': frontImage,
  });

  const classNamesBack = cx('event-card__back', {
    'event-card__back--flip': frontImage,
  });

  const style = React.useMemo(
    () => ({
      background: `url(${frontImage}) no-repeat`,
      backgroundSize: isMobile ? 'contain' : 'cover',
    }),
    [frontImage],
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="event-card-wrap">
      <div onClick={handleClickEvent} id={id || event.id} className="card event-card">
        {frontImage ? (
          <div className={classNamesFront} style={style}>
            <span className="event-card__overlay-title">{profile.title}</span>
          </div>
        ) : null}

        <div className={classNamesBack}>
          <EventStage
            percentage={100 - fakePercentage}
            isFinishedEvent={isFinishedEvent}
            howManyDays={howManyDays}
            sign={event?.sign}
          />

          <MiddleBlock
            price={price}
            defaultPrice={defaultPrice}
            sponsor={sponsor?.title}
            eventTitle={profile.title}
            type={type}
            size={size}
          />
          <div className="event-card__bottom">
            <div className="event-card__bottom-left">
              <Place
                isShort
                event={event}
                location={location}
                place={profile && profile.location_description}
                dateStart={dateStart}
                dateEnd={dateEnd}
              />
            </div>
            {qtyOfUsers ? (
              <div className="event-card__bottom-right">
                {isMobile || anonymous ? (
                  <ViewParticipants isShort qtyOfUsers={qtyOfUsers} qtyFriends={qtyFriends} />
                ) : (
                  <ViewParticipants
                    users={event?.users}
                    qtyOfUsers={qtyOfUsers}
                    qtyFriends={qtyFriends}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(EventCard);
