import { catchWrapper } from '../../../helpers/utility';
import { subscribeBlog } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default (id) => async (dispatch) => {
  dispatch({ type: 'BLOG/SUBSCRIBE_BLOG/REQUESTED' });

  const payloadRequest = { blog_id: Number(id) };

  const result = await catchWrapper(subscribeBlog(payloadRequest));
  if (result && result.result) {
    dispatch({ type: 'BLOG/SUBSCRIBE_BLOG/SUCCEEDED', payload: result.blog });
  } else {
    goToNotFound();
    dispatch({
      type: 'BLOG/SUBSCRIBE_BLOG/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
