import React, { memo } from 'react';
import Button from '../../../button';

import './styles.scss';
import { classNames as cx } from '../../../../helpers/utility';

function FilterButton({ setFilter, label, selectedFilter, type }) {
  const onClick = () => setFilter(type);

  const classNames = cx('panel-filter-button', {
    'panel-filter-button--active': selectedFilter === type,
  });

  return (
    <Button className={classNames} onClick={onClick}>
      {label}
    </Button>
  );
}

export default memo(FilterButton);
