import React from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { geoActions } from '../../../../store/geo';

import CustomInputRange from '../../../settings/components/inputRange';
import CustomCitySelect from '../../../../components/selectRegionCity';
import CustomSelect from '../../../../components/select';
import Icon from '../../../../components/icon';
import Button from '../../../../components/button';
import TypesSwitch from '../typesSwitch';
import Trans from '../../../../components/trans';

const Filters = ({
  handleChangeType,
  type,
  handleChangeSize,
  size,
  handleChangeInput,
  handleChangeGeo,
  handleChangeCountry,
  geoLocal,
  rangeName,
  isRecruitment,
  minRangeValue,
  maxRangeValue,
  onChangeComplete,
  handleVisibleFilter,
  isMobile,
  recruitment,
  handleClearFilter,
  handleChangeRecruitment,
}) => {
  const { getCountry, getOpenCountry } = useActions(geoActions);
  const { countries, openCountries } = useSelector((state) => state.geo);

  const onMenuOpenCountry = () => getCountry();
  const onMenuOpenRegion = () => getOpenCountry(geoLocal.country);
  const textSize = {
    from: Trans('organizers.filter.from'),
    to: Trans('organizers.filter.to'),
  };

  return (
    <div className="filtersWrap">
      <div
        className="filtersWrap__filtersOverlay"
        onClick={() => {
          handleVisibleFilter();
        }}
      />
      <div className="filters">
        {isMobile && (
          <div className="filters__header">
            <div className="filters__header__SwipeRect" />
            <span className="filters__header__title">{Trans('organizers.filter.filters')}</span>
            <span onClick={() => handleClearFilter()} className="filters__header__clear">
              Очистить
            </span>
          </div>
        )}
        <div className="filters__type">
          <span className="filters__label">{Trans('organizers.filter.type')}</span>
          <TypesSwitch type={type} onChange={handleChangeType} />
        </div>
        <div className="filters__age">
          <span className="filters__label">{rangeName}</span>
          <CustomInputRange
            value={size}
            onChange={handleChangeSize}
            minValue={minRangeValue || 0}
            maxValue={maxRangeValue || 1000}
            text={textSize}
            onChangeInput={handleChangeInput}
            onChangeComplete={onChangeComplete}
          />
        </div>
        <div className="filters__country">
          <span className="filters__label">{Trans('common.country')}</span>
          <CustomSelect
            placeholder={Trans('common.countryPlaceholder')}
            name="country"
            asyncSelect
            isOpen
            options={countries && countries.options}
            handleChange={handleChangeCountry}
            onMenuOpen={onMenuOpenCountry}
            value={geoLocal.init ? geoLocal.country : null}
          />
        </div>
        <div className="filters__city">
          <span className="filters__label">{Trans('common.city')}</span>
          <CustomCitySelect
            placeholder={Trans('common.cityPlaceholder')}
            name="region"
            asyncSelect
            options={openCountries && openCountries.options}
            handleChange={handleChangeGeo}
            isDisabled={!geoLocal.country.id || false}
            onMenuOpen={onMenuOpenRegion}
            geoLocal={geoLocal}
          />
        </div>
        {isRecruitment && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div className="filters__recruitment" onClick={handleChangeRecruitment}>
            {recruitment ? <Icon type="check" /> : <Icon type="uncheck" />}
            <span className="filters__label">Идёт набор</span>
          </div>
        )}
        {isMobile && (
          <Button
            className="filters__result-button button-w100"
            onClick={() => handleVisibleFilter()}
          >
            Посмотреть результаты
          </Button>
        )}
      </div>
    </div>
  );
};

export default Filters;
