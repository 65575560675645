import { catchWrapper } from '../../../helpers/utility';
import { getEventYears } from '../../../api/v2';

export default () => async (dispatch) => {
  dispatch({ type: 'EVENTS/GET_EVENT_YEARS/REQUESTED' });

  const result = await catchWrapper(getEventYears());

  if (result?.result) {
    dispatch({ type: 'EVENTS/GET_EVENT_YEARS/SUCCEEDED', payload: result?.years });
  } else {
    dispatch({
      type: 'EVENTS/GET_EVENT_YEARS/FAILED',
      payload: result?.message && result.message[0],
    });
  }
};
