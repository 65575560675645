import React, { useState } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../../routes/privateRoute';

import { Pages } from '../../../routes/pages';
import { useResponsive } from '../../../hooks/useResponsive';
import history from '../../../routes/history';
import modalService from '../../../services/modalService';
import { getIdFromUrl } from '../../../helpers/utility';
import useOwnerData from '../../../hooks/useOwnerData';

//Pages
import AllTeams from './all';
import RecruitingTeams from './recruiting';
import MainTeamPage from './main';
import PersonnelTeamPage from './personnel';
import IncomingRequest from './incomingRequest';

import AboutTeamPage from './about';

//Components
import Button from '../../../components/button';
import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import InputSearch from '../../../components/inputSearch';
import HeaderRight from '../../../components/headerConstructor/components/right';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import Icon from '../../../components/icon';
import RecruitingControlButton from '../components/createRequestButton';
import CreateTeamButton from '../components/createTeamButton';

import './styles.scss';
import Trans from '../../../components/trans';

const TeamSettingWrapper = React.lazy(() => import('./settingWrapper'));
const TeamCreateWrapper = React.lazy(() => import('./createWrapper'));

const getTitle = (cb) => {
  if (cb(Pages.team.create.base)) return Trans('teams.teamCreation');
  if (cb(Pages.teams.recruiting.all)) return Trans('teams.navigation.recruting');
  if (cb(Pages.teams.all)) return Trans('teams.navigation.teams');
  if (cb(Pages.team.settings.base)) return Trans('teams.teamSettings');
  return Trans('teams.team');
};

const getButton = ({ isCreatePages, isSettingPages, isSettingLinksPages }) => {
  if (isCreatePages) return Trans('actions.create');
  if (isSettingPages && !isSettingLinksPages) return Trans('actions.save');
  return null;
};

const TeamsSectionDesktop = ({
  onSubmit,
  isTeamsPages,
  isSettingPages,
  isCreatePages,
  navigationMap,
  isDisabled,
  isSettingLinksPages,
  isRecruitingPage,
  actions,
  user,
  userRecruiting,
}) => {
  const [search, setSearch] = useState('');
  const [isRefreshPage, setRefreshPage] = useState(false);
  const { location } = history;
  const { isDesktop } = useResponsive();

  const { team, teamRole, userTeamId, isLoading, isProfileLoaded } = useOwnerData();

  const handleGoTo = () =>
    history.push({
      pathname: Pages.team.main.replace(':id', getIdFromUrl(location.pathname)),
      state: getIdFromUrl(location.pathname),
    });

  const handleChangeSearch = (v) => setSearch(v);

  const handleToggleRefreshPage = () => setRefreshPage(!isRefreshPage);

  const isRecruitingRequest = !!userRecruiting;

  const openRecruitingModal = () => {
    modalService.openCreateRequestTeamModal({
      user,
      userRecruiting,
      action: actions.onCreateRequestToTeam,
      refreshPage: handleToggleRefreshPage,
    });
  };

  const disabled = user?.team?.id;

  const mainAction = getButton({
    isSettingPages,
    isCreatePages,
    isSettingLinksPages,
  });

  const isShowMenu = !(isSettingPages || isCreatePages);

  return (
    <>
      <HeaderConstructor className="teams">
        <HeaderLeft
          title={getTitle(useRouteMatch)}
          search={
            isTeamsPages ? <InputSearch value={search} onChange={handleChangeSearch} /> : null
          }
          navigation={<TabletNavigation naviMap={navigationMap} />}
        />
        <HeaderRight>
          {isRecruitingPage && isDesktop ? (
            <RecruitingControlButton
              isRecruitingRequest={isRecruitingRequest}
              openRecruitingModal={openRecruitingModal}
              disabled={disabled}
            />
          ) : null}
          {isTeamsPages && !isRecruitingPage && !team && !isLoading && isDesktop ? (
            <CreateTeamButton />
          ) : null}

          {isShowMenu ? (
            <>
              <Controls isShowLang />
              <Menu />
            </>
          ) : null}

          {mainAction ? (
            <Button disabled={isDisabled} onClick={onSubmit} variant="save">
              {mainAction}
            </Button>
          ) : null}

          {isSettingPages ? (
            <>
              {/*<ThemeSwitch />*/}
              <Button onClick={handleGoTo} className="go-back" variant="icon">
                <Icon type="add" />
              </Button>
            </>
          ) : null}
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.teams.all}
            component={AllTeams}
            extraProps={{ search, navigationMap }}
          />
          <PrivateRoute
            exact
            path={Pages.teams.recruiting.all}
            component={RecruitingTeams}
            extraProps={{
              search,
              isRefreshPage,
              handleToggleRefreshPage,
              openRecruitingModal,
              isRecruitingRequest,
              navigationMap,
              onChangeSearch: handleChangeSearch,
            }}
          />
          <PrivateRoute exact path={Pages.team.main} component={MainTeamPage} />
          <PrivateRoute exact path={Pages.team.personnel} component={PersonnelTeamPage} />
          <PrivateRoute
            exact
            path={Pages.team.request}
            component={IncomingRequest}
            withAccess={isProfileLoaded}
            hasAccess={getIdFromUrl(location.pathname) === userTeamId && teamRole}
            redirectPath={{
              pathname: Pages.team.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
          />
          <PrivateRoute exact path={Pages.team.about} component={AboutTeamPage} />
          <PrivateRoute
            withAccess={isProfileLoaded}
            hasAccess={getIdFromUrl(location.pathname) === userTeamId && teamRole}
            redirectPath={{
              pathname: Pages.team.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.team.settings.base}
            component={TeamSettingWrapper}
          />
          <PrivateRoute path={Pages.team.create.base} component={TeamCreateWrapper} />

          <Redirect to={Pages.teams.all} />
        </Switch>
      </div>
    </>
  );
};

export default TeamsSectionDesktop;
