import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from '../filter-button';
import { MSG_FILTER } from '../../../../store/msg/constants';

function PanelFilter({ setFilter, selectedFilter }) {
  const { t } = useTranslation();
  return (
    <>
      <FilterButton
        label={t('pages.msg.filter.all')}
        setFilter={setFilter}
        type={MSG_FILTER.ALL}
        selectedFilter={selectedFilter}
      />
      <FilterButton
        label={t('pages.msg.filter.unread')}
        setFilter={setFilter}
        type={MSG_FILTER.UNREAD}
        selectedFilter={selectedFilter}
      />
    </>
  );
}

export default memo(PanelFilter);
