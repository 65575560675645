import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../../routes/privateRoute';

import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';
import { useSelector } from '../../../hooks/store';
import { classNames as cx, getIdFromUrl } from '../../../helpers/utility';

import UpcomingPage from '../desktop/upcoming';
import PastPage from '../desktop/past';
import AllPage from '../desktop/all';
import HeaderConstructor from '../../../components/headerConstructor';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import MobileNavigation from '../../../components/headerConstructor/components/mobileNavigation';
import CreateEventPage from '../desktop/create';
import useOwnerData from '../../../hooks/useOwnerData';
import EventContainer from '../containers/eventContainer';

const EventsSettingWrapper = React.lazy(() => import('../desktop/settingWrapper'));

const EventsSectionMobile = (rest) => {
  const { search, onChangeSearch, navigationMap, isEventMainPage, isEventPages } = rest;
  const { location } = history;
  const { event } = useSelector((s) => s.events);
  const { sponsorId } = useOwnerData();

  const classNames = cx('page-container page-container--mobile', {
    'page-container--mobile_event': isEventMainPage,
  });

  return (
    <>
      <HeaderConstructor className="events mobile">
        <HeaderLeft>
          {isEventMainPage ? <GoBackButton /> : <SidebarButton />}
          {isEventMainPage ? <HeaderTitle title="Игра" /> : null}
        </HeaderLeft>
        {isEventMainPage ? null : (
          <HeaderRight>
            <>
              <Controls />
              <Menu />
            </>
          </HeaderRight>
        )}
      </HeaderConstructor>
      <div className={classNames}>
        {isEventPages ? (
          <div className="mobile-controls__wrap">
            <MobileNavigation navigationMap={navigationMap} />
          </div>
        ) : null}
        <Switch>
          <PrivateRoute exact path={Pages.events.upcoming} component={UpcomingPage} />
          <PrivateRoute exact path={Pages.events.past} component={PastPage} />
          <PrivateRoute
            exact
            path={Pages.events.all}
            component={AllPage}
            extraProps={{ search, onChangeSearch, ...rest }}
          />

          <PrivateRoute path={Pages.event.base} component={EventContainer} />
          <PrivateRoute exact path={Pages.event.settings.create} component={CreateEventPage} />
          <PrivateRoute
            withAccess={event?.sponsor?.id === sponsorId}
            hasAccess={event?.sponsor?.id === sponsorId}
            redirectPath={{
              pathname: Pages.event.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.event.settings.base}
            component={EventsSettingWrapper}
          />
          <Redirect to={navigationMap[0].path} />
        </Switch>
      </div>
    </>
  );
};

export default EventsSectionMobile;
