import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="6" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 20.8293V25.9381C13.0537 25.446 10 22.0796 10 18C10 16.8434 10.2455 15.744 10.6871 14.7513L15.0739 17.3351C15.0255 17.549 15 17.7715 15 18C15 19.3062 15.8348 20.4175 17 20.8293ZM19 25.9381C22.9463 25.446 26 22.0796 26 18C26 16.8451 25.7553 15.7474 25.3149 14.7559L20.9272 17.3402C20.9749 17.5525 21 17.7733 21 18C21 19.3062 20.1652 20.4175 19 20.8293V25.9381ZM19.8603 15.6463L24.2798 13.0432C22.8146 11.1895 20.5462 10 18 10C15.4536 10 13.1851 11.1897 11.7199 13.0436L16.1393 15.6466C16.6507 15.2417 17.2971 15 18 15C18.7027 15 19.349 15.2416 19.8603 15.6463ZM18 29C24.0751 29 29 24.0751 29 18C29 11.9249 24.0751 7 18 7C11.9249 7 7 11.9249 7 18C7 24.0751 11.9249 29 18 29Z"
      fill="white"
    />
  </svg>
);
