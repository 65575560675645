import React from 'react';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useResponsive } from '../../../../hooks/useResponsive';

import Filters from '../filters';
import UserCard from '../userCard';
import SkeletonFriends from '../skeletonFriends';

import './styles.scss';
import ToggleFilterButton from '../../../components/toggleFilterButton';

const UsersList = ({
  containerRef,
  users,
  fetchPaginated,
  hasMore,
  loading,
  addFriend,
  removeFriend,
  cancelRequest,
  sentRequest,
  page,
  handleChangeGender,
  gender,
  handleChangeAge,
  age,
  handleChangeInput,
  handleChangeGeo,
  handleChangeCountry,
  geoLocal,
  disableFilters,
  rangeName,
  rangeDescription,
  genderFilter,
  onChangeComplete,
  isFilterOpened,
  handleVisibleFilter,
  handleClearFilter,
}) => {
  const { isMobile, isDesktop, isTablet } = useResponsive();
  const handleToggleFilter = () => handleVisibleFilter(!isFilterOpened);

  return (
    <>
      {!disableFilters && isTablet && <ToggleFilterButton onClick={handleToggleFilter} />}
      <div className="friends__filters">
        {!disableFilters && (isDesktop || ((isMobile || isTablet) && isFilterOpened)) && (
          <Filters
            handleChangeGender={handleChangeGender}
            gender={gender}
            handleChangeAge={handleChangeAge}
            age={age}
            handleChangeInput={handleChangeInput}
            handleChangeGeo={handleChangeGeo}
            handleChangeCountry={handleChangeCountry}
            geoLocal={geoLocal}
            rangeName={rangeName}
            rangeDescription={rangeDescription}
            genderFilter={genderFilter}
            onChangeComplete={onChangeComplete}
            handleVisibleFilter={handleVisibleFilter}
            isMobile={isMobile || isTablet}
            handleClearFilter={handleClearFilter}
          />
        )}
      </div>
      <div ref={containerRef}>
        {loading && !hasMore ? (
          <SkeletonFriends />
        ) : (
          !isEmpty(users) && (
            <InfiniteScroll
              dataLength={users?.length}
              next={fetchPaginated}
              hasMore={hasMore}
              loader={<></>}
              className="grid__users"
            >
              {users?.map((user) => (
                <UserCard
                  user={user}
                  extendedFriendCard={!isMobile}
                  teamBlock={!isMobile}
                  key={user.id}
                  addFriend={addFriend}
                  removeFriend={removeFriend}
                  sentRequest={sentRequest}
                  cancelRequest={cancelRequest}
                  page={page}
                />
              ))}
            </InfiniteScroll>
          )
        )}
      </div>
    </>
  );
};

export default UsersList;
