import React, { memo, useRef, useState } from 'react';

import { classNames as cx } from '../../helpers/utility';
import Button from '../button';

import './styles.scss';

const ExpandCollapse = ({ children, className, viewLine }) => {
  const [open, setOpen] = useState(false);
  const elemRef = useRef(null);

  const classNames = cx('expand-collapse', className, {
    'expand-collapse--opened': open,
  });

  const defaultStyle = React.useMemo(
    () => ({
      '-webkit-line-clamp': viewLine || '12',
    }),
    [viewLine],
  );

  const openedStyle = React.useMemo(
    () => ({
      '-webkit-line-clamp': 'inherit',
    }),
    [open],
  );

  const handleToggleCollapse = () => setOpen(!open);

  return (
    <div className={classNames}>
      <div
        ref={elemRef}
        style={open ? openedStyle : defaultStyle}
        className="expand-collapse__content"
      >
        {children}
      </div>
      <Button onClick={handleToggleCollapse} variant="text">
        {open ? 'Свернуть' : 'Читать полностью'}
      </Button>
    </div>
  );
};

export default memo(ExpandCollapse);
