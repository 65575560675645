import { catchWrapper } from '../../../helpers/utility';
import { postSaveProfile } from '../../../api';
import getUser from './getUser';

export default (avatar, preview, pass) => async (dispatch) => {
  dispatch({ type: 'PROFILE/SAVE_AVATAR/REQUESTED' });

  const action = pass ? 'updatePassportAvatar' : 'updateAvatar';

  const formData = new FormData();
  formData.append('action', action);
  formData.append('image', avatar);
  formData.append('preview', preview);
  const result = await catchWrapper(postSaveProfile(formData));

  if (result.result) {
    dispatch({ type: 'PROFILE/SAVE_AVATAR/SUCCEEDED' });
    dispatch(getUser());
  } else {
    dispatch({ type: 'PROFILE/SAVE_AVATAR/FAILED' });
  }
};
