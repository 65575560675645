import { catchWrapper } from '../../../helpers/utility';
import { postTeams } from '../../../api';

export default (data) => async (dispatch) => {
  dispatch({ type: 'TEAMS/USER_REQUEST_MANAGEMENT/REQUESTED' });

  const formData = new FormData();

  formData.append('action', data.action);
  formData.append('user_id', data.user_id);

  const result = await catchWrapper(postTeams('team_users', formData));

  if (result && result.result) {
    dispatch({ type: 'TEAMS/USER_REQUEST_MANAGEMENT/SUCCEEDED', payload: data.user_id });
  } else {
    dispatch({
      type: 'TEAMS/USER_REQUEST_MANAGEMENT/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
