import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2404 8.31132L6.31459 13.1855C5.89514 13.6006 5.89514 14.2736 6.31459 14.6887C6.73405 15.1038 7.41421 15.1038 7.83369 14.6887L12 10.5661L16.1663 14.6887C16.5858 15.1038 17.2659 15.1038 17.6854 14.6887C18.1049 14.2736 18.1049 13.6006 17.6854 13.1855L12.7595 8.31132C12.3401 7.89623 11.6599 7.89623 11.2404 8.31132Z"
      fill="currentColor"
    />
  </svg>
);
