import React, { useEffect } from 'react';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

import { useActions, useSelector } from '../../../../hooks/store';
import { authActions } from '../../../../store/auth';
import { settingsActions } from '../../../../store/settings';
import modalService from '../../../../services/modalService';

import AccountVerification from '../../components/account';
import ChangeSecurityData from '../../components/changeSecurityData';
import FooterActions from '../../components/footerAction';
import SocialDesktop from '../../components/socialDesktop';

import './styles.scss';

import history from '../../../../routes/history';

const SettingsSecurity = () => {
  const { user } = useSelector((state) => state.profile);
  const { social, providers } = useSelector((state) => state.auth);

  const { onLogOut, getSocial, getProviders, onUnlinkSocial, onBindingSocial } = useActions(
    authActions,
  );
  const { onChangeStep, onChangeField, removeProfile } = useActions(settingsActions);

  const { location } = history;

  useEffect(() => {
    getSocial();
    getProviders();
  }, []);

  useEffect(() => {
    const queryObj = queryString.parse(location.search);

    if (location.hash) {
      queryObj.code = `${queryObj.code}${location.hash}`;
    }

    if (!isEmpty(queryObj) && queryObj.code) {
      onBindingSocial(queryObj);
    }
  }, [location.search]);

  const handleOpenPhoneModal = () => {
    onChangeStep('phone');
    onChangeField('error', null);
    modalService.openSecurityModal();
  };
  const handleOpenEmailModal = () => {
    onChangeStep('email');
    onChangeField('error', null);
    modalService.openSecurityModal();
  };
  const handleOpenPasswordModal = () => {
    onChangeStep('password');
    onChangeField('error', null);
    modalService.openSecurityModal();
  };

  const handleUnLink = (keySocial) => onUnlinkSocial(keySocial);

  const handleRemoveProfile = () => {
    modalService.openRemoveProfile({
      onSubmit: removeProfile,
      id: user.id,
      nickname: user?.nickname,
    });
  };

  return (
    <div className="settings-security">
      <AccountVerification user={user} />
      <ChangeSecurityData onClick={handleOpenPhoneModal} label="Телефон" value={user.phone} />
      <ChangeSecurityData onClick={handleOpenEmailModal} label="Почта" value={user.email} />
      <ChangeSecurityData onClick={handleOpenPasswordModal} label="Пароль" value="•••••••••" />
      <SocialDesktop providers={providers} social={social} handleUnLink={handleUnLink} />
      <FooterActions onLogOut={onLogOut} remove={handleRemoveProfile} />
    </div>
  );
};

export default SettingsSecurity;
