import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { eventsActions } from '../../../../../store/events';
import { useActions, useSelector } from '../../../../../hooks/store';
import { getDangerouslyHtml } from '../../../../../helpers/utility';
import { sponsorAction } from '../../../../../store/sponsor';
import { Pages } from '../../../../../routes/pages';
import EntryClubText from './section/club';
import SubNavigation from '../../../../components/subNavi';
import { useResponsive } from '../../../../../hooks/useResponsive';
import history from '../../../../../routes/history';
import PersonalDataText from './section/personal';
import ResponsibilityText from './section/responsibility';

/*const naviMap = (location, id) => {
  return [
    {
      id: '1',
      label: 'Вступление в клуб',
      route: {
        pathname: Pages.event.agreement.replace(':id', id),
        hash: '#entry',
        state: location.state,
      },
    },
    {
      id: '2',
      label: 'Персональные данные',
      route: {
        pathname: Pages.event.agreement.replace(':id', id),
        hash: '#personalData',
        state: location.state,
      },
    },
    {
      id: '3',
      label: 'Ответственность на мероприятии',
      route: {
        pathname: Pages.event.agreement.replace(':id', id),
        hash: '#responsibility',
        state: location.state,
      },
    },
  ];
};*/

/*const renderSections = (hash) => {
  switch (hash) {
    case '#entry':
      return <EntryClubText />;
    case '#personalData':
      return <PersonalDataText />;
    case '#responsibility':
      return <ResponsibilityText />;
    default:
      return <EntryClubText />;
  }
};*/

const AgreementsEvents = () => {
  const { id } = useParams();
  const { location } = history;

  return (
    <div className="org-page--agreements">
      {/*{!isMobile && <SubNavigation naviMap={naviMap(location, id)} />}*/}
      <div className="org-page--agreements__editor">
        321
      </div>
    </div>
  );
};

export default AgreementsEvents;
