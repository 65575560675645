import React, { useRef } from 'react';
import Button from '../../components/button';
import Icon from '../../components/icon';
import './styles.scss';

const ModalHeaderBlog = ({
  title,
  close,
  loading,
  onSubmit,
  buttonTitle,
  disabledButton,
  imagePicker,
  onUploadPhoto,
  albumId,
  parentId,
  parentType,
  getBlogAlbumList,
  clearBlogAlbums,
  count,
  countAll,
  isSelected,
  removeImages,
}) => {
  const inputFile = useRef(null);
  const handleClose = async () => {
    close();
    if (imagePicker) {
      await clearBlogAlbums();
      await getBlogAlbumList({ page: 1, parentId: Number(parentId), parentType });
    }
  };
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const onChangeFile = async (e) => {
    const data = { album_id: albumId, type: 'blog', parent_id: parentId };
    const { files } = e.target;
    // eslint-disable-next-line no-param-reassign
    count.current = 0;
    // eslint-disable-next-line no-param-reassign
    countAll.current = files?.length;
    await onUploadPhoto({ data, files, count, parentId });
    //await clearBlogAlbumImages();
    // await getAlbumImages({ page: 1, blogId, albumId });
    // await clearBlogAlbumImages();
    // await getAlbumImages({ page: 1, blogId, albumId });
    document.getElementById('file-blog').value = '';
  };

  return (
    <div className="modal-header">
      <div>
        <span className="modal-header__title">{title}</span>
      </div>
      <div className="modal-header__actions">
        {buttonTitle ? (
          <div className="modal-header__actions__button">
            <Button
              disabled={loading || disabledButton}
              tabIndex="0"
              variant="main"
              onClick={onSubmit}
            >
              {buttonTitle}
            </Button>
          </div>
        ) : null}
        {imagePicker ? (
          <div>
            <div className="modal-header__actions__button">
              {isSelected ? (
                <Button
                  disabled={loading}
                  tabIndex="0"
                  variant="main"
                  onClick={removeImages}
                  className="remove"
                >
                  Удалить
                </Button>
              ) : null}

              <Button
                disabled={loading || disabledButton}
                tabIndex="0"
                variant="main"
                onClick={onButtonClick}
                className="add"
              >
                Добавить фото
              </Button>
            </div>
            <input
              accept="image/x-png,image/gif,image/jpeg, image/jpg"
              onChange={onChangeFile}
              id="file-blog"
              ref={inputFile}
              style={{ display: 'none' }}
              multiple
              type="file"
            />
          </div>
        ) : null}
        {close ? (
          <div>
            <Button onClick={handleClose} variant="icon">
              <Icon type="cross" />
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModalHeaderBlog;
