import { catchWrapper } from '../../helpers/utility';
import { postGetUserData } from '../../api';
import { goToLogin } from '../../routes/actions';
import { getFormData } from './userInit';

import savePassport from './impl/savePassport';
import saveProfile from './impl/saveProfile';
import getUserData from './impl/getUser';
import saveAvatar from './impl/saveAvatar';
import getUserSilent from './impl/getUserSilent';

export const profileReducer = (state = null, action) => {
  switch (action.type) {
    case 'PROFILE/LOAD/REQUESTED':
    case 'PROFILE/SAVE/REQUESTED':
    case 'PROFILE/SAVE_AVATAR/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case 'PROFILE/SET_USER': {
      return {
        ...state,
        user: action.payload,
        userSnapshot: action.payload,
      };
    }
    case 'PROFILE/SET_AUTH_DATA': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'PROFILE/CLONE': {
      return {
        ...state,
        user: state.userSnapshot,
        error: null,
      };
    }

    case 'PROFILE/LOAD/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        user: action.payload,
        userSnapshot: action.payload,
      };
    }

    case 'PROFILE/LOAD_BY_ID/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        anotherUser: action.payload,
      };
    }

    case 'PROFILE/OWNER_ID': {
      return {
        ...state,
        ownerId: action.payload,
      };
    }

    case 'PROFILE/SAVE/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        userSnapshot: state.user,
      };
    }

    case 'PROFILE/SAVE_AVATAR/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case 'PROFILE/LOAD/FAILED':
    case 'PROFILE/SAVE/FAILED':
    case 'PROFILE/SAVE_AVATAR/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case 'PROFILE/CHANGE_FIELD_USER': {
      const { field, value } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          [field]: value,
        },
      };
    }

    case 'PROFILE/CHANGE_PROFILE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    case 'PROFILE/CHANGE_FIELD_USER_AND_SNAPSHOT': {
      const { field, value } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          [field]: value,
        },
        userSnapshot: {
          ...state.userSnapshot,
          [field]: value,
        },
      };
    }
    case 'PROFILE/CLEAR_ANOTHER_USER': {
      return {
        ...state,
        anotherUser: {},
      };
    }
    case 'PROFILE/LOAD_SILENT/REQUESTED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent + 1,
      };
    }
    case 'PROFILE/LOAD_SILENT/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        anotherUser: action.payload,
      };
    }
    case 'PROFILE/LOAD_SILENT/FAILED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };
    }
    case 'PROFILE/CHANGE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export const onChangeProfileField = (field, value) => (dispatch) => {
  dispatch({
    type: 'PROFILE/CHANGE_PROFILE_FIELD',
    payload: { field, value },
  });
};

export const onChangeField = (field, value) => (dispatch) => {
  dispatch({
    type: 'PROFILE/CHANGE_FIELD_USER',
    payload: { field, value },
  });
};

export const onChangeError = (value) => (dispatch) => {
  dispatch({
    type: 'PROFILE/CHANGE_ERROR',
    payload: value,
  });
};

const onUserClone = () => (dispatch) => {
  dispatch({
    type: 'PROFILE/CLONE',
  });
};

const onClearAnotherUser = () => (dispatch) => {
  dispatch({
    type: 'PROFILE/CLEAR_ANOTHER_USER',
  });
};

const getInitUserData = (skip) => async (dispatch) => {
  dispatch({ type: 'PROFILE/LOAD/REQUESTED' });
  const formData = getFormData();
  const result = await catchWrapper(postGetUserData(formData));
  if (result && result.result) {
    dispatch({ type: 'PROFILE/LOAD/SUCCEEDED', payload: result.user });
    dispatch({ type: 'PROFILE/OWNER_ID', payload: result.user.id });
  } else {
    dispatch({ type: 'PROFILE/LOAD/FAILED' });
    if (!skip) await goToLogin();
  }
};

export const profileActions = {
  getUserData,
  getInitUserData,
  onChangeField,
  saveProfile,
  savePassport,
  saveAvatar,
  onUserClone,
  onClearAnotherUser,
  getUserSilent,
  onChangeError,
  onChangeProfileField,
};
