import React from 'react';

import './style.scss';
import Trans from '../../../../components/trans';

const TeamLinksBlock = ({ social, services }) => {
  const filteredServices = services.filter((item) => social[item.key].value);

  return (
    <>
      {filteredServices.length > 0 && (
        <div className="social-block">
          <span className="social-block__text">{Trans('common.links')}</span>
          <div className="social-block__row">
            {filteredServices.map((item) => (
              <div className="social-block__item" key={item.id}>
                <a href={social[item.key].value} target="_blank" rel="noreferrer">
                  <img src={item.icon} alt={item.title} />
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TeamLinksBlock;
