import React from 'react';

export default () => (
  <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.606 10.2696C24.606 13.821 22.8118 16.9494 20.0867 18.7951C20.5859 18.9716 21.076 19.1729 21.5527 19.3991C22.4813 19.8424 23.3626 20.3809 24.1698 21.0187C24.8982 21.5932 25.5615 22.2447 26.1461 22.9731C26.3327 23.2061 26.5103 23.4481 26.6811 23.6969C26.3372 24.0317 25.8897 24.2534 25.4019 24.3213C25.2985 24.3371 25.1928 24.3439 25.0848 24.3439H21.7168C20.9322 24.3439 20.3094 24.6991 19.9091 25.217C19.1717 26.1693 19.192 27.6781 20.3634 28.5331L23.0883 30.526C23.7044 30.9761 24.0461 31.691 24.0372 32.4284H1.61207C0.72172 32.4284 0 31.7022 0 30.8065C0 26.5087 1.8369 23.2084 4.60912 21.0187C5.82997 20.0551 7.21945 19.3154 8.69212 18.7951C5.96712 16.9494 4.17294 13.821 4.17294 10.2696C4.17294 4.59869 8.74609 0 14.3894 0C20.0328 0 24.606 4.59869 24.606 10.2696ZM30.8339 21.8647C30.7304 21.5435 30.2808 21.5435 30.1773 21.8647L28.644 26.6082C28.5991 26.7529 28.4664 26.8479 28.3158 26.8479H23.3581C23.0231 26.8479 22.8837 27.28 23.1535 27.4768L27.1668 30.4106C27.2883 30.4989 27.3376 30.655 27.2927 30.7974L25.7594 35.5432C25.6559 35.8644 26.0201 36.129 26.29 35.9322L30.3032 32.9984C30.4247 32.9101 30.5865 32.9101 30.708 32.9984L34.7213 35.9322C34.8562 36.0317 35.0159 36.0137 35.1282 35.9299C35.2384 35.8485 35.3036 35.7037 35.2518 35.5432L33.7185 30.7974C33.6736 30.655 33.723 30.4989 33.8444 30.4106L37.8577 27.4768C38.1275 27.28 37.9881 26.8479 37.6531 26.8479H32.6955C32.5449 26.8479 32.4122 26.7529 32.3673 26.6082L30.8339 21.8647Z"
      fill="currentColor"
    />
  </svg>
);
