import React, { Suspense } from 'react';

import Navigations from '../../components/navigations';
import { UnauthRoutes } from '../../routes';
import { classNames as cx } from '../../helpers/utility';
import { Pages } from '../../routes/pages';

const Unauthorised = ({ location }) => {
  const className = cx('main-container', {
    'main-container__auth': location.pathname === Pages.auth.auth,
  });

  return (
    <div className={className}>
      <Navigations />
      <div className="main-layout">
        <Suspense fallback={null}>
          <UnauthRoutes />
        </Suspense>
      </div>
    </div>
  );
};

export default Unauthorised;
