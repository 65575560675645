import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { saveTeamLink } from '../../../api/v2';
import onCheckAuth from '../../auth/impl/checkAuth';

export default (data) => async (dispatch, getState) => {
  dispatch({ type: 'TEAMS/SAVE_TEAM_LINK/REQUESTED' });

  const result = await catchWrapper(saveTeamLink({ ...data }));

  if (result && result.result) {
    const { team } = getState().teams;
    const newServices = { ...team.social_service };
    newServices[data.service].value = data.value;

    batch(() => {
      dispatch({ type: 'TEAMS/SAVE_TEAM_LINK/SUCCEEDED' });
      dispatch({
        type: 'TEAM/CHANGE_FIELD_TEAM',
        payload: {
          field: 'social_service',
          value: newServices,
        },
      });
      dispatch(onCheckAuth());
    });
  } else {
    dispatch({
      type: 'TEAMS/SAVE_TEAM_LINK/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
