import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useActions, useSelector } from '../../../../hooks/store';
import { sponsorAction } from '../../../../store/sponsor';
import { sponsorTypeEnum } from '../../../../helpers/enums';
import { getSchema } from './actionsMap';
import { globalCancelFetch } from '../../../../api/v2';

import useOwnerData from '../../../../hooks/useOwnerData';
import SkeletonParticipants from '../../components/skeletonParticipants';
import TeamCard from '../../../teams/components/teamCard';

import './styles.scss';
import Trans from '../../../../components/trans';

const ParticipantsSponsorPage = () => {
  const { sponsorParticipants, loading } = useSelector((state) => state.sponsor);
  const { getSponsorParticipants, onClearSponsorParticipants, onChangeParticipants } = useActions(
    sponsorAction,
  );
  const { userId } = useSelector((state) => state.auth);
  const { id } = useParams();

  const { sponsorRole, sponsorId } = useOwnerData();

  useEffect(() => {
    getSponsorParticipants(id);
    return () => {
      onClearSponsorParticipants();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  return (
    <div className="participants-sponsor-page">
      {loading ? (
        <SkeletonParticipants />
      ) : (
        <>
          {(sponsorParticipants.gold ||
            sponsorParticipants.silver ||
            sponsorParticipants.bronze) && (
            <div className="participants-sponsor-page__wrap">
              <div className="participants-sponsor-page__teams">
                {!isEmpty(sponsorParticipants.gold) &&
                  sponsorParticipants.gold.map((team) => (
                    <TeamCard
                      key={team.id}
                      team={team}
                      userId={userId}
                      isGold
                      type={sponsorTypeEnum.gold}
                      isCamouflage
                      isAction={!!sponsorRole && sponsorId === id}
                      action={onChangeParticipants}
                      sponsorId={id}
                      getSchema={getSchema}
                    />
                  ))}
                {!isEmpty(sponsorParticipants.silver) &&
                  sponsorParticipants.silver.map((team) => (
                    <TeamCard
                      key={team.id}
                      team={team}
                      userId={userId}
                      isSilver
                      type={sponsorTypeEnum.silver}
                      isCamouflage
                      isAction={!!sponsorRole && sponsorId === id}
                      action={onChangeParticipants}
                      sponsorId={id}
                      getSchema={getSchema}
                    />
                  ))}
                {!isEmpty(sponsorParticipants.bronze) &&
                  sponsorParticipants.bronze.map((team) => (
                    <TeamCard
                      key={team.id}
                      team={team}
                      userId={userId}
                      isBronze
                      type={sponsorTypeEnum.bronze}
                      isCamouflage
                      isAction={!!sponsorRole && sponsorId === id}
                      action={onChangeParticipants}
                      sponsorId={id}
                      getSchema={getSchema}
                    />
                  ))}
              </div>
            </div>
          )}
          {!isEmpty(sponsorParticipants.participant) && (
            <div className="participants-sponsor-page__wrap">
              <h3 className="participants-sponsor-page__title">
                {Trans('organizers.members.members')}
              </h3>
              <div className="participants-sponsor-page__teams">
                {sponsorParticipants.participant.map((team) => (
                  <TeamCard
                    key={team.id}
                    team={team}
                    userId={userId}
                    type={sponsorTypeEnum.participant}
                    isCamouflage
                    isAction={!!sponsorRole && sponsorId === id}
                    action={onChangeParticipants}
                    sponsorId={id}
                    getSchema={getSchema}
                  />
                ))}
              </div>
            </div>
          )}
          {!isEmpty(sponsorParticipants.guest) && (
            <div className="participants-sponsor-page__wrap">
              <h3 className="participants-sponsor-page__title">
                {Trans('organizers.members.guests')}
              </h3>
              <div className="participants-sponsor-page__teams">
                {sponsorParticipants.guest.map((team) => (
                  <TeamCard
                    key={team.id}
                    team={team}
                    userId={userId}
                    type={sponsorTypeEnum.guest}
                    isCamouflage
                    isAction={!!sponsorRole && sponsorId === id}
                    action={onChangeParticipants}
                    sponsorId={id}
                    getSchema={getSchema}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ParticipantsSponsorPage;
