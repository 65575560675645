import { isEmpty } from 'lodash';
import { isEmail, validateEmail } from '../../helpers/utility';

export const validationGround = ({ ground }) => {
  const error = {};

  if (!ground?.profile?.title) {
    error.title = 'Поле обязательно для заполнения!';
  }
  if (isEmpty(ground?.images)) {
    error.images = 'Добавьте изображения!';
  }
  if (!ground?.location?.country?.id) {
    error.country = 'Поле обязательно для заполнения!';
  }
  if (!ground?.location?.region?.id) {
    error.region = 'Поле обязательно для заполнения!';
  }
  if (!ground?.location?.city?.id) {
    error.city = 'Поле обязательно для заполнения!';
  }
  if (!ground?.phone) {
    error.phone = 'Поле обязательно для заполнения!';
  }
  if (!ground?.email) {
    error.email = 'Поле обязательно для заполнения!';
  } else if (!isEmail(ground.email)) {
    error.email = 'Введите корректный E-mail адрес!';
  }
  if (!ground?.gps) {
    error.gps = 'Поле обязательно для заполнения!';
  }

  return isEmpty(error) ? null : error;
};

export const validationGroundRequest = (fields) => {
  const error = {};

  if (!fields?.title) {
    error.title = 'Поле обязательно для заполнения!';
  }
  if (!fields?.org) {
    error.org = 'Поле обязательно для заполнения!';
  }
  if (!fields?.fio) {
    error.fio = 'Поле обязательно для заполнения!';
  }
  /*  if (!fields?.email) {
    error.email = 'Поле обязательно для заполнения!';
  }*/
  if (!fields?.phone) {
    error.phone = 'Поле обязательно для заполнения!';
  }
  /*
  if (!validateEmail(fields.email)) {
    error.email = 'Пожалуйста, введите корректный E-mail адрес.';
  }*/

  if (!fields?.description) {
    error.description = 'Поле обязательно для заполнения!';
  }

  return isEmpty(error) ? null : error;
};
