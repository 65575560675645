import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../hooks/store';
import { sponsorAction } from '../../../../../../store/sponsor';
import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { globalCancelFetch } from '../../../../../../api/v2';

const PersonalData = ({ sponsorId }) => {
  const { isMobile } = useResponsive();
  const { onGetSponsorSettingsPersonalData } = useActions(sponsorAction);
  const { sponsorSettings } = useSelector((state) => state.sponsor);
  const dataText = sponsorSettings?.data?.text;

  useEffect(() => {
    if (sponsorId) {
      onGetSponsorSettingsPersonalData('8', sponsorId, 'sponsor', '1');
    }

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [sponsorId]);

  return (
    <div>
      <div>
        {!isMobile && <div className="info-caption">Персональные данные</div>}
        {dataText && (
          <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(dataText)} />
        )}
      </div>
    </div>
  );
};

export default PersonalData;
