import React from 'react';
import uuid from 'react-uuid';

import './styles.scss';
import Trans from '../trans';

const MultiSwitch = ({ onChange, currentValue, values, single, name = uuid() }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    if (!single) {
      const index = currentValue.indexOf(value);
      if (index > -1) {
        onChange([...currentValue].filter((el) => el !== value));
      } else {
        onChange([...currentValue, value]);
      }
    } else {
      onChange(currentValue === value ? null : value);
    }
  };

  return (
    <div className="multi-switch">
      {values?.map((item) => (
        <div className="multi-switch_radio_btn" key={uuid()}>
          <input
            onChange={handleChange}
            checked={
              (!single && currentValue.indexOf(item.id) > -1) ||
              parseInt(currentValue, 10) === item.id
            }
            id={`radio-${name}-${item.id}`}
            type="checkbox"
            name="radio"
            value={item.id}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={`radio-${name}-${item.id}`}>
            <span>{Trans(item.title)}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default MultiSwitch;
