import React from 'react';
import uuid from 'react-uuid';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useSelector } from '../../../hooks/store';
import { classNames as cx, getUrlTwoPath } from '../../../helpers/utility';
import { Pages } from '../../../routes/pages';

import './styles.scss';

import dummy from '../../../assets/image/user.svg';

const AvatarsItem = ({ url, id, isDummy }) => {
  const { userId } = useSelector((state) => state.auth);
  const classNames = cx('avatars-block__photo-wrap', {
    'avatars-block__photo-wrap_dummy': !isDummy,
  });

  const newPath = userId === id ? '/profile' : `/profile/${id}`;

  return (
    <div className={classNames}>
      <Link to={{ pathname: newPath, state: id }}>
        <img className="avatars-block__photo" src={url} alt="avatar" />
      </Link>
    </div>
  );
};

const AvatarsMore = ({ num }) => {
  const { id } = useParams();

  const url = getUrlTwoPath(id, Pages.users.userFriends, Pages.users.main);

  return (
    <div className="avatars-block__photo-wrap avatars-block__photo-num">
      <Link to={url}>
        <span>{`+${num > 99 ? '99' : num}`}</span>
      </Link>
    </div>
  );
};

const renderItem = (index, id, url, len, isDummy) => {
  if (len === 5) {
    return <AvatarsItem key={uuid()} id={id} url={url} isDummy={isDummy} />;
  }
  if (index < 4) {
    return <AvatarsItem key={uuid()} id={id} url={url} isDummy={isDummy} />;
  }
  return null;
};

const Avatars = ({ friends, totalFriends }) => {
  const classNames = cx('avatars-block', {
    [`avatars-block__length_${friends && friends.length}`]: friends && friends.length <= 5,
  });

  return (
    <div className={classNames}>
      {!isEmpty(friends) &&
        friends.map((friend, index) => {
          const { image_avatar: imageAvatar } = friend;

          const url = imageAvatar && imageAvatar.preview ? `${imageAvatar.preview}` : dummy;
          const isDummy = imageAvatar && imageAvatar.preview;

          return renderItem(index, friend.id, url, totalFriends, isDummy);
        })}
      {!isEmpty(friends) && totalFriends > 4 && (
        <AvatarsMore key="more_friends" num={Number(totalFriends) - 4} />
      )}
    </div>
  );
};

export default Avatars;
