import React, { useState } from 'react';

import { useActions, useSelector } from '../../hooks/store';
import { pagesActions } from '../../store/pages';

import ModalHeader from '../components/modalHeaderBlog';
import CustomInput from '../../components/input';
import Button from '../../components/button';

import './styles.scss';

const init = {
  fio: null,
  email: null,
  description: null,
};

const BidSponsorModal = () => {
  const [state, setState] = useState(init);

  const { errors, loading } = useSelector((s) => s.pages);
  const { sendRequestSponsor, onChangeField } = useActions(pagesActions);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if (errors && errors[name]) {
      const temp = {
        ...errors,
        [name]: null,
      };
      onChangeField('errors', temp);
    }

    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = () => sendRequestSponsor(state);

  return (
    <div className="modal-wrapper">
      <ModalHeader title="Заявка на вступление в организаторы" />
      <div className="modal-content">
        <CustomInput
          onChange={handleChangeInput}
          value={state.fio}
          label="ФИО"
          labelPosition="top"
          placeholder="ФИО"
          name="fio"
          required
          error={errors?.fio}
        />
        <CustomInput
          onChange={handleChangeInput}
          value={state.email}
          label="E-mail"
          labelPosition="top"
          placeholder="E-mail"
          name="email"
          required
          error={errors?.email}
        />
        <CustomInput
          onChange={handleChangeInput}
          value={state.description}
          type="textarea"
          label="Описание"
          labelPosition="top"
          placeholder="Описание"
          name="description"
          required
          error={errors?.description}
        />
      </div>
      <div className="modal-footer">
        <Button disabled={loading} onClick={onSubmit} tabIndex="0" variant="main">
          Отправить
        </Button>
      </div>
    </div>
  );
};

export default BidSponsorModal;
