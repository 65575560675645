import React, { useCallback } from 'react';
import he from 'he';
import modalService from '../../../../../services/modalService';
import UserCardActions from '../../../../friends/components/userCardActions';
import './styles.scss';

const getSchema = (editAction, removeAction) => {
  return [
    {
      id: '1',
      label: 'Изменить',
      action: () => editAction(),
    },
    {
      id: '2',
      label: 'Удалить',
      action: () => removeAction(),
    },
  ];
};

const VideoCard = ({
  video,
  parentId,
  openAddBlogVideoModal,
  blogHolder,
  deleteVideo,
  userPage,
  parentType,
}) => {
  const handleOpenBlogVideoModal = useCallback(() => {
    modalService.openVideo({ video, parentId, userPage, parentType });
  }, [parentId, video]);

  const handleOnEditAction = useCallback(() => {
    openAddBlogVideoModal({ parentId, video, isEdit: true });
  }, [parentId, video]);

  const handleOnRemoveAction = useCallback(() => {
    deleteVideo(Number(video?.id));
  }, [video?.id]);

  return (
    <div className="video-card album-card__container">
      <div className="video-card__header">
        <span className="video-card__title" onClick={handleOpenBlogVideoModal}>
          {he.decode(video?.profile?.title || '')}
        </span>
        {blogHolder && (
          <UserCardActions
            schema={getSchema(handleOnEditAction, handleOnRemoveAction)}
            id={parentId}
          />
        )}
      </div>
      <div className="video-card__content">
        <img src={video?.image?.preview} alt="" onClick={handleOpenBlogVideoModal} />
      </div>
    </div>
  );
};

export default VideoCard;
