import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3ZM15.87 9.849L11.757 15.249C11.6732 15.3579 11.5655 15.4462 11.4422 15.507C11.319 15.5679 11.1834 15.5997 11.046 15.6C10.9093 15.6007 10.7742 15.5703 10.651 15.5111C10.5278 15.4518 10.4198 15.3653 10.335 15.258L8.139 12.459C7.99221 12.2704 7.92633 12.0313 7.95587 11.7941C7.9854 11.557 8.10793 11.3413 8.2965 11.1945C8.48507 11.0477 8.72423 10.9818 8.96137 11.0114C9.19851 11.0409 9.41421 11.1634 9.561 11.352L11.028 13.224L14.43 8.724C14.5021 8.62945 14.5921 8.55002 14.6949 8.49026C14.7977 8.43049 14.9113 8.39156 15.0291 8.37568C15.1469 8.3598 15.2667 8.36729 15.3817 8.39771C15.4966 8.42814 15.6045 8.4809 15.699 8.553C15.7936 8.62509 15.873 8.71511 15.9327 8.8179C15.9925 8.92069 16.0314 9.03425 16.0473 9.15208C16.0632 9.26992 16.0557 9.38973 16.0253 9.50468C15.9949 9.61962 15.9421 9.72745 15.87 9.822V9.849Z"
      fill="currentColor"
    />
  </svg>
);
