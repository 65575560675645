import React, { useEffect } from 'react';
import CustomInput from '../../../components/input';
import { useResponsive } from '../../../hooks/useResponsive';

const StepEmail = ({ onChange, credentials, error }) => {
  const { isMobile } = useResponsive();
  useEffect(() => {
    const target = document.querySelector('.input');
    if (target) {
      target.focus();
    }
  }, []);

  return (
    <>
      <span className="modal-content__label">Новый адрес электронной</span>
      <CustomInput
        tabIndex="0"
        value={credentials.email}
        onChange={onChange}
        placeholder="Email"
        name="email"
      />
      <span className="modal-content__label">Пароль</span>
      <CustomInput
        tabIndex="0"
        className="confirm-email-pass"
        value={credentials.password}
        onChange={onChange}
        placeholder="Password"
        name="password"
        type="password"
      />
      <span className="modal-content__text-error">{error}</span>
    </>
  );
};

export default StepEmail;
