import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

const Recaptcha = ({ getRecaptcha, recaptchaRef }) => {
  const { theme, loading } = useSelector((state) => state.theme);

  return loading ? null : (
    <ReCAPTCHA
      sitekey="6Le6GcoZAAAAACKd8Oi02i5WIvft58h-cBgt5bdh"
      onChange={getRecaptcha}
      className="recaptcha"
      ref={recaptchaRef}
      theme={theme === 1 ? 'dark' : 'light'}
    />
  );
};

export default React.memo(Recaptcha);
