export const localeContent = {
  Pages: {
    category: {
      pageTitle: 'Страницы',
      cards: {
        team: {
          title: 'Команда',
          content: 'Создание команды.',
          buttonText: 'Создать команду',
          text: 'Вы уже находитесь в команде',
        },
        org: {
          title: 'Орггруппа',
          content: 'Отправить запрос на создание страницы организатора.',
          buttonTextCreate: 'Создать орггруппу',
          buttonTextRequest: 'Отправить заявку',
          text: 'Вы уже состоите в орггруппе',
        },
        blog: {
          title: 'Блог',
          content: 'Создать страницу блога',
          buttonText: 'Создать блог',
          text: 'У Вас уже есть блог',
          // text: '(Скоро)',
        },
      },
    },
  },
};
