import React from 'react';
import { isEmpty } from 'lodash';
import PriceCard from '../priceCard';
import { getFormattedDate } from '../../../../helpers/utility';

import './styles.scss';

const PriceCardWrapper = ({ title, list }) => {
  return !isEmpty(list) ? (
    <div className="price-card-wrapper">
      <span className="price-car__title">{title}</span>
      <div className="price-car__grid">
        {list?.map((l) => {
          if (l.default_price) {
            return (
              <React.Fragment key={l.id}>
                <PriceCard value={l.price} label="Базовая" />
                <PriceCard value={l.default_price} label="На полигоне" />
              </React.Fragment>
            );
          }
          if (l.date) {
            const d = getFormattedDate(l.date, 'dd-MM');
            return (
              <React.Fragment key={l.id}>
                <PriceCard value={l.price} label={d} />
              </React.Fragment>
            );
          }
          return <PriceCard key={l.id} value={l.price} label="Базовая" />;
        })}
      </div>
    </div>
  ) : null;
};

export default PriceCardWrapper;
