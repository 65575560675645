import { catchWrapper } from '../../../helpers/utility';
import { postSaveProfile } from '../../../api';
import { validationProfile } from '../../settings/validation';
import { onChangeError } from '../index';

export default () => async (dispatch, getState) => {
  const { user } = getState().profile;
  const error = validationProfile({ user });

  if (error) {
    dispatch(onChangeError(error));
  } else {
    dispatch({ type: 'PROFILE/SAVE/REQUESTED' });

    const formData = new FormData();
    formData.append('action', 'saveProfile');

    formData.append('nickname', user?.nickname || null);

    formData.append('about', user?.about || null);

    // Object.keys(user).forEach((i) => {
    //   if (user[i] === null) {
    //     formData.append(i, '');
    //   } else if (i === 'birth_date') {
    //     const t = Moment(user[i]).format();
    //     formData.append(i, t);
    //   } else {
    //     formData.append(i, user[i]);
    //   }
    // });
    const result = await catchWrapper(postSaveProfile(formData));

    if (result.result) {
      dispatch({ type: 'PROFILE/SAVE/SUCCEEDED' });
    } else {
      dispatch({ type: 'PROFILE/SAVE/FAILED' });
    }
  }
};
