import getPrivateSettings from './impl/getPrivateSetting';
import getPrivateData from './impl/getPrivateData';
// eslint-disable-next-line import/no-cycle
import submitPassword from './impl/submitPassword';
import savePrivateData from './impl/savePrivateData';
import submitPhone from './impl/submitPhone';
// eslint-disable-next-line import/no-cycle
import submitCode from './impl/submitCode';
import submitEmail from './impl/submitEmail';
import removeProfile from './impl/removeProfile';

export const settingsReducer = (state = null, action) => {
  switch (action.type) {
    case 'SETTINGS/LOAD_PRIVATE/REQUESTED':
    case 'SETTINGS/SAVE_PRIVATE/REQUESTED':
    case 'SETTINGS/SUBMIT_PHONE/REQUESTED':
    case 'SETTINGS/SUBMIT_PHONE_CODE/REQUESTED':
    case 'SETTINGS/SUBMIT_EMAIL/REQUESTED':
    case 'SETTINGS/SUBMIT_PASSWORD/REQUESTED':
    case 'SETTINGS/GET_PRIVATE_SETTINGS/REQUESTED':
    case 'SETTINGS/REMOVE_PROFILE/REQUESTED':
      return {
        ...state,
        loading: state.loading + 1,
      };
    case 'SETTINGS/LOAD_PRIVATE/SUCCESSFUL':
      return {
        ...state,
        loading: state.loading - 1,
        privacy: action.payload,
        privacySnapshot: action.payload,
      };
    case 'SETTINGS/SAVE_PRIVATE/SUCCESSFUL': {
      return {
        ...state,
        loading: state.loading - 1,
        privacySnapshot: state.privacy,
      };
    }
    case 'SETTINGS/SUBMIT_PHONE_CODE/SUCCESSFUL':
      return {
        ...state,
        loading: state.loading - 1,
        step: '',
      };
    case 'SETTINGS/CHANGE_FIELD/PRIVATE': {
      const { field, value, groupKey } = action.payload;

      if (groupKey) {
        return {
          ...state,
          groupKey,
          privacy: {
            ...state.privacy,
            [groupKey]: {
              ...state.privacy[groupKey],
              [field]: value,
            },
          },
        };
      }
      return {
        ...state,
        privacy: {
          ...state.privacy,
          [field]: value,
        },
      };
    }
    case 'SETTINGS/CHANGE_STEP/SECURITY':
      return {
        ...state,
        step: action.payload,
      };
    case 'SETTINGS/SUBMIT_PHONE/SUCCESSFUL':
      return {
        ...state,
        loading: state.loading - 1,
        step: 'codePhone',
      };
    case 'SETTINGS/SUBMIT_EMAIL/SUCCESSFUL':
      return {
        ...state,
        loading: state.loading - 1,
        step: 'codeEmail',
      };
    case 'SETTINGS/SUBMIT_PASSWORD/SUCCESSFUL':
      return {
        ...state,
        loading: state.loading - 1,
        step: '',
      };
    case 'SECURITY/CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    case 'SETTINGS/GET_PRIVATE_SETTINGS/SUCCESSFUL': {
      return {
        ...state,
        loading: state.loading - 1,
        privacyConfig: action.payload.settings,
        privacyConfigValues: action.payload.value_settings,
      };
    }

    case 'SETTINGS/REMOVE_PROFILE/SUCCESSFUL':
    case 'SETTINGS/REMOVE_PROFILE/FAILED':
    case 'SETTINGS/SUBMIT_PHONE/FAILED':
    case 'SETTINGS/SUBMIT_PHONE_CODE/FAILED':
    case 'SETTINGS/SUBMIT_PASSWORD/FAILED':
    case 'SETTINGS/LOAD_PRIVATE/FAILED':
    case 'SETTINGS/SAVE_PRIVATE/FAILED':
    case 'SETTINGS/SUBMIT_EMAIL/FAILED':
    case 'SETTINGS/GET_PRIVATE_SETTINGS/':
      return {
        ...state,
        loading: state.loading - 1,
        error: action.payload,
      };

    default:
      return state;
  }
};

const onChangeField = (field, value) => (dispatch) => {
  dispatch({
    type: 'SECURITY/CHANGE_FIELD',
    payload: { field, value },
  });
};

const onChangePrivateField = (field, value, groupKey) => (dispatch) => {
  dispatch({
    type: 'SETTINGS/CHANGE_FIELD/PRIVATE',
    payload: { field, value, groupKey },
  });
};

const onChangeStep = (step) => (dispatch) => {
  dispatch({
    type: 'SETTINGS/CHANGE_STEP/SECURITY',
    payload: step,
  });
};

export const settingsActions = {
  getPrivateData,
  savePrivateData,
  onChangePrivateField,
  submitPhone,
  onChangeStep,
  submitCode,
  submitEmail,
  onChangeField,
  submitPassword,
  getPrivateSettings,
  removeProfile,
};
