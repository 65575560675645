import React from 'react';

export default () => (
  <svg
    width="244"
    height="242"
    viewBox="0 0 244 242"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="170.881"
      height="170.881"
      rx="24"
      transform="matrix(0.70816 0.706052 -0.70816 0.706052 122 0)"
      fill="url(#paint0_linear)"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M130.534 168.856C133.011 166.068 134.524 162.417 134.524 158.407C134.524 149.57 127.214 142.499 118.312 142.499C109.41 142.499 102.1 149.57 102.1 158.407C102.1 162.417 103.613 166.068 106.09 168.856C105.248 169.331 104.435 169.864 103.663 170.457L103.662 170.458C99.3583 173.765 96.4998 178.791 96.4998 185.219C96.4998 187.819 98.6387 189.836 101.163 189.836H131.26H131.458L131.199 190.615L131.198 190.617C130.236 193.521 133.565 195.486 135.73 193.95L135.733 193.948L139.942 190.955L144.142 193.942C144.144 193.943 144.145 193.944 144.147 193.945C145.305 194.773 146.695 194.593 147.606 193.936C148.515 193.283 149.144 192.003 148.684 190.615L148.684 190.615L147.105 185.862L151.255 182.911L130.534 168.856ZM130.534 168.856C131.375 169.33 132.188 169.863 132.962 170.458C134.082 171.317 135.109 172.298 136.019 173.401L136.021 173.403C136.239 173.668 136.447 173.938 136.646 174.209L137.128 172.758C137.128 172.758 137.128 172.757 137.128 172.756C138.025 170.055 141.858 170.055 142.755 172.756C142.755 172.757 142.755 172.758 142.755 172.758L144.348 177.55H149.534C152.274 177.55 153.721 181.158 151.258 182.909L130.534 168.856ZM129.998 184.836H131.26V187.262C131.26 187.287 131.26 187.311 131.26 187.336L132.778 185.862L130.447 184.205L129.998 184.836ZM129.998 184.836L130.447 184.204L128.628 182.911L126.33 182.251L129.964 184.836H129.998ZM131.435 182.815C131.435 182.815 131.436 182.815 131.436 182.815L131.435 182.815ZM131.435 182.815L131.064 182.55H132.435L135.46 184.702C135.623 184.817 135.69 185.021 135.629 185.207L131.435 182.815ZM131.696 182.025C131.481 182.22 131.225 182.372 130.938 182.461L130.349 182.042L129.615 181.52L130.074 180.872L131.696 182.025ZM134.017 186.743C134.015 186.742 134.014 186.741 134.012 186.739L134.017 186.743ZM145.866 186.743L145.871 186.739C145.869 186.741 145.868 186.742 145.866 186.743Z"
        fill="white"
        stroke="#50A46E"
        strokeWidth="5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="24.5146"
        y1="-41.3352"
        x2="223.02"
        y2="78.3273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6E6E6" />
        <stop offset="0.635217" stopColor="#E6E6E6" />
        <stop offset="0.635317" stopColor="#50A46E" stopOpacity="0" />
        <stop offset="0.635417" stopColor="#50A46E" />
        <stop offset="1" stopColor="#50A46E" />
      </linearGradient>
      <clipPath id="clip0">
        <rect
          width="65.6639"
          height="65.6229"
          fill="white"
          transform="translate(88.1233 135.522)"
        />
      </clipPath>
    </defs>
  </svg>
);
