import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../hooks/store';
import ControlsSettingMain from '../../components/controlsSettingMain';
import BlockSettingMain from '../../components/blockSettingMain';
import { blogActions } from '../../../../store/blog';
import CustomInput from '../../../../components/input';
import './styles.scss';

const CreateBlogPage = () => {
  const { tags, types, blog, blogImageTemp, newBlog } = useSelector((state) => state?.blog);
  const { getBlogTypes, getBlogTags, onChangeField } = useActions(blogActions);

  useEffect(() => {
    getBlogTypes();
    getBlogTags();
  }, [getBlogTags, getBlogTypes]);

  const handleChangeImages = (name, image) => {
    onChangeField('blogImageTemp', image);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    onChangeField('newBlog', {
      ...newBlog,
      profile: { ...newBlog.profile, [name]: value },
    });
  };

  const onChangeTypeSelect = (val) => {
    onChangeField('newBlog', {
      ...newBlog,
      type: val,
    });
  };

  const handleChangeTags = (val) => {
    onChangeField('newBlog', {
      ...newBlog,
      tags: val,
    });
  };

  return (
    <div className="blog-page--create">
      <BlockSettingMain
        isEditable
        handleChangeImages={handleChangeImages}
        handleChangeInput={handleChangeInput}
        onChangeTypeSelect={onChangeTypeSelect}
        blogImageTemp={blogImageTemp}
        types={types}
        blog={blog}
        newBlog={newBlog}
      />
      <ControlsSettingMain
        handleChangeTags={handleChangeTags}
        tags={tags && tags}
        handleChangeInput={handleChangeInput}
        newBlog={newBlog}
      />
      <CustomInput
        onChange={handleChangeInput}
        value={blog?.profile?.about || newBlog?.profile?.about}
        textRows={6}
        type="textarea"
        className="about"
        label="О блоге"
        labelPosition="top"
        name="about"
        placeholder="Напишите что-нибудь о блоге"
      />
    </div>
  );
};

export default CreateBlogPage;
