import React from 'react';
import { isEmpty } from 'lodash';
import NetworkState from '../../../../components/headerConstructor/components/networkState';

import { useSelector } from '../../../../hooks/store';

import './styles.scss';
import { t } from 'i18next';

function MessagesHeader() {
  const { receiver } = useSelector((state) => state.msg);
  console.log(receiver);
  return (
    <div className="msg-header">
      <span className="msg-header__title">{receiver?.name}</span>
      {isEmpty(receiver) ? (
        <span className="msg-header__text">{t('pages.msg.conversationNotSelected')}</span>
      ) : (
        <NetworkState className="msg-header__network" status={receiver?.status === 'online'} />
      )}
    </div>
  );
}

export default MessagesHeader;
