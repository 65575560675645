import { createAction } from '@reduxjs/toolkit';
import { CometChat } from '@cometchat-pro/chat';
import { MSG_TYPES } from '../constants';
import * as enums from '../enums';
import { CometChatEvent } from '../listeners/chatEvent';

export const setScrollValueAction = createAction(MSG_TYPES.SET_SCROLL_VALUE);
export const setMessageHistoryAction = createAction(MSG_TYPES.SET_MESSAGE_HISTORY);
export const setMessage = createAction(MSG_TYPES.SET_MESSAGE);
export const cleanMessagesAction = createAction(MSG_TYPES.CLEAN_MESSAGES);
export const updateMessagesAction = createAction(MSG_TYPES.UPDATE_MESSAGES);
export const setEditModeAction = createAction(MSG_TYPES.SET_EDIT_MODE);

export const setMessageHistory = (messageList, actionType) => (dispatch) => {
  if (actionType === enums.ACTIONS.MESSAGES_INITIAL_FETCH) {
    dispatch(setScrollValueAction(true));
  }
  if (actionType === enums.ACTIONS.MESSAGES_FETCHED) {
    dispatch(setScrollValueAction(false));
  }
  dispatch(setMessageHistoryAction(messageList));
};

export const sendMessage = (messageText, actionType) => async (dispatch, getState) => {
  const { receiver } = getState().msg;
  const receiverType = CometChat.RECEIVER_TYPE.USER;

  const textMessage = messageText?.trim();

  const data = new CometChat.TextMessage(receiver.uid, textMessage, receiverType);

  CometChat.sendMessage(data).then(
    (message) => {
      if (actionType === enums.ACTIONS.MESSAGE_SENT) {
        dispatch(setScrollValueAction(true));
      }
      dispatch(setMessage(message));
      CometChatEvent.triggerHandler(enums.EVENTS.UPDATE_LAST_MESSAGE, { ...message });
    },
    (error) => {
      console.log('Message sending failed with error:', error);
    },
  );
};
