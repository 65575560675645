import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { Link } from 'react-router-dom';
import { catchWrapper, classNames as cx } from '../../helpers/utility';
import { postTeams } from '../../api';

import ModalHeader from '../components/modalHeaderBlog';
import EntityPreview from '../../pages/components/entityPreview';
import RadioGroup from '../../components/radioGroup';
import Button from '../../components/button';
import CustomCheckbox from '../../components/customCheckbox';
import TeamUsers from '../../components/teamUsers';

import './styles.scss';
import { Pages } from '../../routes/pages';
import { useActions, useSelector } from '../../hooks/store';
import { eventsActions } from '../../store/events';

const convertSides = (arr) => {
  return arr.map((e) => {
    return {
      ...e,
      value: e.id,
      label: e.title,
    };
  });
};

const initState = {
  side: null,
  isChooseSide: true,
  agreement: false,
};

const getTeamUserList = async (teamId, cb) => {
  const formData = new FormData();
  formData.append('action', 'getTeamMembersList');
  formData.append('team_id', Number(teamId));

  const result = await catchWrapper(postTeams('team_users', formData));

  if (result?.result) {
    cb(result?.users);
  }
};

const EventRegistrationModal = ({
  onCancel,
  team,
  teamRole,
  event,
  actions,
  userBlog,
  isEdit,
  single,
  singleInTeam,
}) => {
  const [state, setState] = useState(initState);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const { onGetEventSettingsInfoRulesAdd } = useActions(eventsActions);
  const { eventSettings } = useSelector((state) => state.events);
  const additionalRulesText = eventSettings?.additionalRules?.text;
  const { id } = event;

  useEffect(() => {
    if (id) {
      onGetEventSettingsInfoRulesAdd('1', Number(id), 'event', '1');
    }
  }, [id]);

  useEffect(() => {
    if (team && teamRole) {
      getTeamUserList(team.id, setUsers);
    }
  }, [team, teamRole]);

  useEffect(() => {
    if (event && event?.request_team?.side) {
      setState({
        ...state,
        side: event?.request_team?.side?.id,
      });
    } else if (event?.choose_side && event?.sides?.length) {
      const side = event?.sides && convertSides(event?.sides)[0]?.id;
      setState({
        ...state,
        side,
      });
    }

    if (!isEmpty(event?.request_team?.users)) {
      const tmp = event?.request_team?.users.map((u) => Number(u?.user?.id));
      setSelected(tmp);
    }
  }, []);

  const handleChangeSide = useCallback(
    (e) => {
      const { value } = e.target;
      setState({
        ...state,
        side: value,
      });
    },
    [state],
  );

  const handleChangeCheckbox = useCallback(
    (e) => {
      const { checked } = e.target;

      const side = event?.sides && convertSides(event?.sides)[0]?.id;

      if (checked) {
        setState({
          ...state,
          isChooseSide: checked,
          side,
        });
      } else {
        setState({
          ...state,
          isChooseSide: checked,
          side: null,
        });
      }
    },
    [state],
  );

  const handleChangeAgreement = useCallback(
    (e) => {
      const { checked } = e.target;

      if (checked) {
        setState({
          ...state,
          agreement: checked,
        });
      } else {
        setState({
          ...state,
          agreement: checked,
        });
      }
    },
    [state],
  );

  const onSubmit = () => {
    const data = {
      event_id: event.id,
    };

    if (teamRole && !singleInTeam) {
      data.users = Array.from(selected);
    }

    if (teamRole && state?.isChooseSide && !singleInTeam) {
      data.side_id = Number(state?.side);
    }

    actions.onManageEventRequest({ data, url: 'manage', isEdit });
  };

  const handleClickAll = useCallback(
    (e) => {
      const { checked } = e.target;

      if (checked) {
        const tmp = users.map((u) => u.id);
        setSelected(tmp);
      } else {
        setSelected([]);
      }
    },
    [users],
  );

  const onBlogSubmit = () => {
    const data = {
      event_id: event.id,
    };
    const url = single ? 'manage_single' : 'manage_blog';
    actions.onManageEventRequest({ data, url });
  };

  const handleToggleUser = (e, userId) => {
    const { checked } = e.target;

    if (checked) {
      const tmp = [...selected, userId];

      setSelected(tmp);
    } else {
      const tmp = selected.filter((s) => s !== userId);
      setSelected(tmp);
    }
  };

  const teamUsersWrap = cx('team-users-wrap', {
    'team-users-wrap--short': event.request_team && teamRole,
  });

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title="Регистрация на игру" />
      <div className="modal-content">
        {!single ? (
          <EntityPreview item={team || userBlog?.user} label={userBlog ? 'Блог' : 'Команда'} />
        ) : null}

        {!userBlog && teamRole && !singleInTeam ? (
          <>
            {event?.choose_side && event?.sides?.length && teamRole && !userBlog ? (
              <div className="choose-side">
                <div className="choose-side__header">
                  <CustomCheckbox
                    label="Выберите сторону"
                    onChange={handleChangeCheckbox}
                    name="isChooseSide"
                    checked={state?.isChooseSide}
                  />
                </div>

                {state?.isChooseSide && teamRole ? (
                  <div className="choose-side__list">
                    <RadioGroup
                      name="side"
                      onChange={handleChangeSide}
                      value={state?.side}
                      options={convertSides(event?.sides)}
                      variant="sides"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className={teamUsersWrap}>
              <TeamUsers
                users={users}
                selected={selected}
                toggleAll={handleClickAll}
                toggleUser={handleToggleUser}
              />
            </div>
          </>
        ) : null}
      </div>
      <div className="modal-footer">
        <div className="modal-footer__agreement">
          <CustomCheckbox
            label={
              <>
                Подавая заявку я принимаю{' '}
                <Link to={Pages.event.offer.replace(':id', event.id)} target="_blank">
                  договор оферты
                </Link>
                ,{' '}
                <Link to={`${Pages.event.info.replace(':id', event.id)}#rules`} target="_blank">
                  правила организатора
                </Link>
                {!additionalRulesText ? null : (
                  <>
                    {' '}
                    и{' '}
                    <Link
                      to={`${Pages.event.info.replace(':id', event.id)}#rulesAdditional`}
                      target="_blank"
                    >
                      правила мероприятия
                    </Link>
                  </>
                )}
                .
              </>
            }
            onChange={handleChangeAgreement}
            name="agreement"
            checked={state?.agreement}
          />
        </div>

        {event.request_team && teamRole ? (
          <Button onClick={actions.onCancelTeamRequest} tabIndex="0" variant="second">
            Отменить заявку
          </Button>
        ) : null}

        {userBlog || single ? (
          <Button disabled={!state.agreement} onClick={onBlogSubmit} tabIndex="0" variant="main">
            Зарегистрироваться
          </Button>
        ) : (
          <Button
            disabled={(isEmpty(selected) && teamRole && !singleInTeam) || !state.agreement}
            onClick={onSubmit}
            tabIndex="0"
            variant="main"
          >
            {event.request_team && teamRole ? 'Сохранить' : 'Зарегистрироваться'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventRegistrationModal;
