import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames as cx } from '../../../../helpers/utility';

import './styles.scss';

const NetworkState = ({ status, className }) => {
  const { t } = useTranslation();
  const classNames = cx('network-status', className, {
    'network-status--online': status,
    'network-status--offline': !status,
  });

  const statusValue = status ? 'pages.msg.online' : 'pages.msg.offline';

  return <span className={classNames}>{t(statusValue)}</span>;
};

export default memo(NetworkState);
