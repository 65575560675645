import React from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import './styles.scss';

const CustomInputRange = ({
  value,
  minValue,
  maxValue,
  onChange,
  onChangeInput,
  text,
  onChangeComplete,
}) => {
  return (
    <div className="input-range">
      <InputRange
        value={value}
        onChangeComplete={onChangeComplete}
        maxValue={maxValue}
        minValue={minValue}
        onChange={onChange}
      />
      <div className="input-range__view">
        <span className="input-range__text">{text.from}</span>
        <div className="input-range__value">
          <input
            onChange={onChangeInput}
            name="min"
            className="input-range__input"
            value={value && value.min}
          />
        </div>
        <span className="input-range__text">{text.to}</span>
        <div className="input-range__value">
          <input
            onChange={onChangeInput}
            name="max"
            className="input-range__input"
            value={value && value.max}
          />
        </div>

        <span className="input-range__text">{text.value}</span>
      </div>
    </div>
  );
};

export default CustomInputRange;
