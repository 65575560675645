import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="6" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7143 11.8333H22.5714V11.2667C22.5714 10.1981 22.5714 9.66389 22.2367 9.33194C21.902 9 21.3632 9 20.2857 9H15.7143C14.6368 9 14.098 9 13.7633 9.33194C13.4286 9.66389 13.4286 10.1981 13.4286 11.2667V11.8333H10.2857C9.20822 11.8333 8.66947 11.8333 8.33473 12.1653C8 12.4972 8 13.0315 8 14.1V23.7333C8 24.8018 8 25.3361 8.33473 25.668C8.66947 26 9.20822 26 10.2857 26H25.7143C26.7918 26 27.3305 26 27.6653 25.668C28 25.3361 28 24.8018 28 23.7333V14.1C28 13.0315 28 12.4972 27.6653 12.1653C27.3305 11.8333 26.7918 11.8333 25.7143 11.8333ZM21.1429 18.6333C21.1429 20.3546 19.7357 21.75 18 21.75C16.2643 21.75 14.8571 20.3546 14.8571 18.6333C14.8571 16.9121 16.2643 15.5167 18 15.5167C19.7357 15.5167 21.1429 16.9121 21.1429 18.6333ZM22.8571 18.6333C22.8571 21.2935 20.6825 23.45 18 23.45C15.3175 23.45 13.1429 21.2935 13.1429 18.6333C13.1429 15.9732 15.3175 13.8167 18 13.8167C20.6825 13.8167 22.8571 15.9732 22.8571 18.6333Z"
      fill="white"
    />
  </svg>
);
