import { sponsorTypeEnum } from '../../../helpers/enums';
import { catchWrapper } from '../../../helpers/utility';
import { postChangeParticipant } from '../../../api/v2';

export default ({ sponsorId, teamId, type, vipType }) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/ON_CHANGE_PARTICIPANTS_REQUEST/REQUESTED' });

  const payload = {
    id: sponsorId,
    team_id: teamId,
    type,
  };

  if (type === sponsorTypeEnum.vip) {
    payload.vip = vipType;
  }

  const result = await catchWrapper(postChangeParticipant(payload));

  if (result && result.result) {
    dispatch({
      type: 'SPONSOR/ON_CHANGE_PARTICIPANTS_REQUEST/SUCCEEDED',
      payload: teamId,
    });
  } else {
    dispatch({
      type: 'SPONSOR/ON_CHANGE_PARTICIPANTS_REQUEST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
