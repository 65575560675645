import React from 'react';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputSearch from '../../../components/inputSearch';

import SkeletonGrid from '../../../components/skeletonsGrid';

const CamouflageDropdown = ({
  camouflages,
  fetchPaginated,
  hasMore,
  loading,
  handleClickItem,
  onChangeSearch,
  searchValue,
}) => {
  return (
    <div className="card camouflage-dropdown__container">
      <InputSearch onChange={onChangeSearch} value={searchValue} />
      <div className="camouflage-dropdown__wrap">
        <>
          {loading && !hasMore ? (
            <SkeletonGrid
              classNameElement="sk-camouflage-select--element"
              classNameGrid="sk-camouflage-select--grid"
            />
          ) : (
            !isEmpty(camouflages) && (
              <InfiniteScroll
                height={237}
                dataLength={camouflages && camouflages.length}
                next={fetchPaginated}
                hasMore={hasMore}
                loader={<></>}
                className="camouflage-dropdown__grid"
              >
                {camouflages &&
                  camouflages.map((camouflage) => (
                    // eslint-disable-next-line react/no-array-index-key,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      onClick={() => handleClickItem(camouflage)}
                      key={camouflage.id}
                      className="camouflage-dropdown__item"
                    >
                      <div className="camouflage-dropdown__item-image__wrap">
                        <img src={camouflage && camouflage.image.image} alt="camouflage" />
                      </div>
                      <div className="camouflage-dropdown__item-title__wrap">
                        <span className="camouflage-dropdown__item-title">
                          {camouflage && camouflage.title}
                        </span>
                      </div>
                    </div>
                  ))}
              </InfiniteScroll>
            )
          )}
        </>
      </div>
    </div>
  );
};

export default CamouflageDropdown;
