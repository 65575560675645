import React from 'react';

export default () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 26.9229C21.1366 26.9229 26.9233 21.1375 26.9233 13.9998C26.9233 6.86344 21.1366 1.0767 14.0002 1.0767C6.86389 1.07669 1.07715 6.86344 1.07715 13.9998C1.07715 21.1375 6.86389 26.9229 14.0002 26.9229Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9404 18.9619L16.9234 14.0004L11.9404 9.03882"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
