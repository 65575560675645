import React, { useEffect } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { authActions } from '../../../../store/auth';

import AuthHeader from '../component/header';
import StatisticsLogin from '../../../components/statisticsLogin';
import LeftBlock from '../component/left';
import RightBlock from '../component/right';

import './styles.scss';

const LoginDesktop = () => {
  const actions = useActions(authActions);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    actions.getProviders();
    actions.getEntityCounts();
  }, []);

  return (
    <div className="auth__wrap">
      <AuthHeader />
      <div className="auth-fields">
        <LeftBlock actions={actions} auth={auth} />
        <RightBlock actions={actions} auth={auth} />
      </div>
      <div className="auth-statistics">
        <StatisticsLogin auth={auth} />
      </div>
    </div>
  );
};

export default LoginDesktop;
