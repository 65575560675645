import React, { useEffect, useState } from 'react';
import Sidebar from 'react-sidebar';

import { sidebarActions } from '../../store/sideber';
import { useStore } from '../../hooks/store';

import NavigationRoutes from './routes';
import NavigationsNotification from './notifications';
import NavigationFooter from './footer';
import NavigationsHeader from './header';

import { useResponsive } from '../../hooks/useResponsive';

import './styles.scss';

const Navigations = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenSidebar, actions] = useStore((state) => state.sidebar.isOpenSidebar, sidebarActions);

  const { isDesktop } = useResponsive();

  const handleToggleSidebar = (bool) => actions.onToggle(bool);

  const handleCloseSidebar = (bool) => actions.onToggle(false);

  const handleOpenRoutes = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpenSidebar) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpenSidebar]);

  // eslint-disable-next-line no-nested-ternary
  return isDesktop ? (
    <div className="navigations" id="navigations">
      <NavigationsHeader closeMenu={handleCloseSidebar} />
      {/*<span className="navigations-nearest-game">Ближайшая игра</span>*/}
      {/*<NavigationsNotification />*/}
      <NavigationRoutes setOpen={handleOpenRoutes} open={isOpen} />
      <NavigationFooter />
    </div>
  ) : isOpenSidebar ? (
    <Sidebar
      touch={false}
      onSetOpen={handleToggleSidebar}
      open={isOpenSidebar}
      sidebarClassName="sidebar"
      rootClassName="sidebar--root"
      overlayClassName="sidebar--overlay"
      sidebar={
        <>
          <div className="navigations" id="navigations">
            <NavigationsHeader closeMenu={handleCloseSidebar} />
            {/*<span className="navigations-nearest-game">Ближайшая игра</span>*/}
            {/*<NavigationsNotification />*/}
            <NavigationRoutes
              setOpen={handleOpenRoutes}
              open={isOpen}
              closeMenu={handleCloseSidebar}
            />
            <NavigationFooter />
          </div>
        </>
      }
    >
      {null}
    </Sidebar>
  ) : null;
};

export default Navigations;
