import React from 'react';
import addDays from 'date-fns/addDays';
import { last } from 'lodash';

import { getFormattedDate } from '../../../../../../helpers/utility';

import CustomCheckbox from '../../../../../../components/customCheckbox';
import CurrencyInput from '../../../../../../components/currencyInput';
import CustomDatePicker from '../../../../../../components/datePicker';
import Button from '../../../../../../components/button';

const PaymentSection = ({
  payments,
  onChange,
  onChangeCheckbox,
  onChangeDate,
  section,
  mainLabel,
}) => {
  const selectedDate = React.useMemo(
    () => (payments?.dateInst ? new Date(payments.dateInst) : new Date()),
    [payments?.dateInst],
  );
  const lastDateExtend = React.useMemo(() => {
    const lastElement = last(payments.payments);
    return lastElement.date || null;
  }, [payments]);

  return (
    <div className="payments-event__section">
      <div className="payments-event__section-top">
        <CustomCheckbox
          onChange={onChangeCheckbox}
          label={mainLabel}
          checked={payments?.isVisible}
          name={section}
        />
      </div>
      {payments.isVisible ? (
        <div className="payments-event__section-main">
          {payments?.payments.map((p) => {
            return (
              <React.Fragment key={p.id}>
                <CurrencyInput
                  value={p.price}
                  suffix=" ₽"
                  label={p.date ? 'При оплате до' : 'Базовая стоимость'}
                  labelPosition="top"
                  placeholder="0 ₽"
                  name="cost"
                  extendedLabel={p.date ? getFormattedDate(p.date, 'dd-LL') : null}
                  onValueChange={(values) =>
                    onChange({ values, field: 'price', section, itemId: p.id })
                  }
                />
                {p.isDefaultPrice ? (
                  <CurrencyInput
                    value={p.default_price}
                    suffix=" ₽"
                    label="На полигоне"
                    labelPosition="top"
                    placeholder="0 ₽"
                    name="default_price"
                    onValueChange={(values) =>
                      onChange({ values, field: 'default_price', section, itemId: p.id })
                    }
                  />
                ) : null}
              </React.Fragment>
            );
          })}
          <CustomDatePicker
            selected={selectedDate}
            onChange={(d) => onChangeDate(d, section)}
            customInput={<Button variant="second">Добавить дату</Button>}
            minDate={lastDateExtend ? addDays(new Date(lastDateExtend), 1) : addDays(new Date(), 1)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PaymentSection;
