import React from 'react';
import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const HeaderConstructor = ({ children, className }) => {
  const classNames = cx('header', {
    [`header--${className}`]: className,
  });

  return (
    <header id="header" className={classNames}>
      {children}
    </header>
  );
};

export default HeaderConstructor;
