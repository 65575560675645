import { catchWrapper } from '../../../helpers/utility';
import { getBlogArticle } from '../../../api/v2';

export default (article) => async (dispatch) => {
  dispatch({ type: 'BLOG/GET_BLOG_ARTICLE/REQUESTED' });

  const payloadRequest = {
    id: Number(article),
  };

  const result = await catchWrapper(getBlogArticle(payloadRequest));

  if (result && result.result) {
    const data = {
      ...result?.article,
      text: result?.article?.profile?.text,
      title: result?.article?.profile?.title,
    };
    dispatch({ type: 'BLOG/GET_BLOG_ARTICLE/SUCCEEDED', payload: data });
  } else {
    dispatch({
      type: 'BLOG/GET_BLOG_ARTICLE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
