import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 20C24.1193 20 23 18.8807 23 17.5C23 16.1193 24.1193 15 25.5 15C26.8807 15 28 16.1193 28 17.5C28 18.8807 26.8807 20 25.5 20ZM18 20C16.6193 20 15.5 18.8807 15.5 17.5C15.5 16.1193 16.6193 15 18 15C19.3808 15 20.5 16.1193 20.5 17.5C20.5 18.8807 19.3808 20 18 20ZM8 17.5C8 18.8807 9.11925 20 10.5 20C11.8808 20 13 18.8807 13 17.5C13 16.1193 11.8808 15 10.5 15C9.11925 15 8 16.1193 8 17.5Z"
      fill="#C0B4AF"
    />
  </svg>
);
