import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { classNames as cx } from '../../../../helpers/utility';
import { getLocations } from '../../../../store/selectors';
import { useSelector } from '../../../../hooks/store';

import './styles.scss';
import Trans from '../../../../components/trans';

// without this line it didn't work
moment.locale('ru');

const SponsorDataRow = ({ label, value, sponsor }) => {
  let image;
  let link;

  const { userId } = useSelector((state) => state.auth);
  if (sponsor && !isEmpty(sponsor.commander)) {
    link = {
      pathname: userId === sponsor.commander.id ? '/profile' : `/profile/${sponsor.commander.id}`,
      state: sponsor?.commander?.id,
    };
    image = sponsor?.commander?.image_avatar?.preview;
  }

  const classNames = cx('sponsor-data__value', {
    'sponsor-data__value-m': image,
  });

  return (
    <div className="sponsor-data__row">
      <span className="sponsor-data__label">{label}</span>
      {sponsor && !isEmpty(sponsor.commander) ? (
        <Link to={link}>
          <div className="sponsor-data__image">
            <img src={image} alt="" />
          </div>
          <span className={classNames}>{sponsor?.commander?.nickname}</span>
        </Link>
      ) : (
        <span className={classNames}>{value}</span>
      )}
    </div>
  );
};

const SponsorDataBlock = ({ sponsor }) => {
  const { city, country } = getLocations(sponsor?.location);

  return (
    <div className="card sponsor-data__container">
      {country && <SponsorDataRow label={Trans('common.country')} value={country} />}
      {city && <SponsorDataRow label={Trans('common.city')} value={city} />}
      <SponsorDataRow label={Trans('organizers.personnel.leader')} sponsor={sponsor} />
      {sponsor?.qty_of_games ? (
        <SponsorDataRow label={Trans('organizers.main.games')} value={sponsor.qty_of_games} />
      ) : null}
      {sponsor?.date && (
        <SponsorDataRow
          label={Trans('organizers.main.date')}
          value={moment(sponsor?.date).format('DD MMMM YYYY')}
        />
      )}
    </div>
  );
};

export default SponsorDataBlock;
