import React, { memo } from 'react';
import { Trans } from 'react-i18next';
import { getMessageDate } from '../../../../helpers/common';

import './styles.scss';

function MessageDateSeparator({ date, language }) {
  return (
    <div className="msg-date-separator">
      <div className="msg-date-separator__divider">
        <span className="msg-date-separator__date">
          <Trans>{getMessageDate(date, language)}</Trans>
        </span>
      </div>
    </div>
  );
}

export default memo(MessageDateSeparator);
