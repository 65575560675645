import { getFormDataToAnotherUser } from '../userInit';
import { catchWrapper } from '../../../helpers/utility';
import { postGetUserData } from '../../../api';

export default (id) => async (dispatch) => {
  dispatch({ type: 'PROFILE/LOAD_SILENT/REQUESTED' });

  const formData = getFormDataToAnotherUser();
  formData.append('user_id', id);

  const result = await catchWrapper(postGetUserData(formData));

  if (result && result.result) {
    dispatch({ type: 'PROFILE/LOAD_SILENT/SUCCEEDED', payload: result.user });
  } else {
    dispatch({ type: 'PROFILE/LOAD_SILENT/FAILED' });
  }
};
