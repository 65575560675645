import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../../../hooks/store';
import { camouflageActions } from '../../../../store/camouflage';
import { useResponsive } from '../../../../hooks/useResponsive';
import { globalCancelFetch } from '../../../../api/v2';

import CamouflageInfoCard from '../components/camouflageCard';
import TeamCard from '../../../teams/components/teamCard';
import SkeletonGrid from '../../../../components/skeletonsGrid';

import './styles.scss';

const CamouflageMainPage = () => {
  const { id } = useParams();
  const { getCamouflageData, unmountCamouflageData } = useActions(camouflageActions);
  const { token } = useSelector((state) => state.auth);
  const { camouflage, loading } = useSelector((state) => state.camouflage);
  const teams = camouflage && camouflage.teams;

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (token) {
      if (id) {
        getCamouflageData(id);
      }
    }
    return () => {
      unmountCamouflageData();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [id, token]);

  return (
    <div className="camouflage-desktop-info">
      <div className="camouflage-desktop-info-card">
        <CamouflageInfoCard camouflage={camouflage} loading={loading} />
      </div>
      {loading && !isMobile ? (
        <SkeletonGrid
          classNameElement="sk-camouflage-team--element"
          classNameGrid="sk-camouflage-team--grid"
        />
      ) : (
        <div>
          <div className="camouflage-desktop-info__team-cards">
            {teams?.map((team) => (
              <TeamCard team={team} key={team.id} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CamouflageMainPage;
