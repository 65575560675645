import React from 'react';
import ModalHeader from '../components/modalHeaderBlog';

import Button from '../../components/button';

import './styles.scss';
import { t } from 'i18next';

const ConfirmModal = ({ title, content, onCancel, submit }) => {
  const handleSubmit = () => {
    submit();
    onCancel();
  };

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title={title} />
      <div className="modal-content">{content}</div>
      <div className="modal-footer">
        <Button onClick={handleSubmit} variant="main">
          {t('common.confirm')}
        </Button>
        <Button onClick={onCancel} variant="second">
          {t('common.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmModal;
