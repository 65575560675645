import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import { Pages } from '../../../routes/pages';

import EventMainPage from '../desktop/main';

import HeaderConstructor from '../../../components/headerConstructor';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';

import MobileNavigation from '../../../components/headerConstructor/components/mobileNavigation';
import { classNames as cx } from '../../../helpers/utility';
import PricesPage from '../../events/desktop/prices';
import Scenario from '../../events/desktop/eventsPages/scenario';
import Access from '../../events/desktop/eventsPages/access';
import AgreementsEvents from '../../events/desktop/eventsPages/agreement';
import InfoEventPage from '../../events/desktop/eventsPages/info';
import Polygon from '../../events/desktop/eventsPages/polygon';
import Results from '../../events/desktop/eventsPages/results';
import Offer from '../../events/desktop/eventsPages/offer';
import AllPage from '../../events/desktop/all';

const UnAuthEventsSectionMobile = (rest) => {
  const { navigationMap, isEventMainPage, isEventPages } = rest;

  const classNames = cx('page-container page-container--mobile', {
    'page-container--mobile_event': isEventMainPage,
  });

  return (
    <>
      <HeaderConstructor className="events mobile">
        <HeaderLeft>
          {isEventMainPage ? <GoBackButton /> : <SidebarButton />}
          {isEventMainPage ? <HeaderTitle title="Игра" /> : null}
        </HeaderLeft>
        {isEventMainPage ? null : (
          <HeaderRight>
            <>
              <Controls />
            </>
          </HeaderRight>
        )}
      </HeaderConstructor>
      <div className={classNames}>
        {isEventPages ? (
          <div className="mobile-controls__wrap">
            <MobileNavigation navigationMap={navigationMap} />
          </div>
        ) : null}
        <Switch>
          <Route
            exact
            path={Pages.events.all}
            render={(props) => <AllPage {...props} {...rest} />}
          />
          <Route exact path={Pages.event.main} component={EventMainPage} />
          <Route exact path={Pages.event.price} component={PricesPage} />
          <Route exact path={Pages.event.scenario} component={Scenario} />
          <Route exact path={Pages.event.allow} component={Access} />
          <Route exact path={Pages.event.agreement} component={AgreementsEvents} />
          <Route exact path={Pages.event.info} component={InfoEventPage} />
          <Route exact path={Pages.event.location} component={Polygon} />
          <Route exact path={Pages.event.results} component={Results} />
          <Route exact path={Pages.event.offer} component={Offer} />
          <Redirect to={navigationMap[0].path} />
        </Switch>
      </div>
    </>
  );
};

export default UnAuthEventsSectionMobile;
