import { isEmpty } from 'lodash';

export const validationCamouflageForm = (fields) => {
  const error = {};

  if (!fields?.title) {
    error.title = 'Поле обязательно для заполнения!';
  }
  if (!fields?.link) {
    error.link = 'Поле обязательно для заполнения!';
  }
  if (!fields?.description) {
    error.description = 'Поле обязательно для заполнения!';
  }

  return isEmpty(error) ? null : error;
};
