import { catchWrapper } from '../../../helpers/utility';
import { getAchievements } from '../../../api/v2';

export default (userId) => async (dispatch) => {
  dispatch({ type: 'ACHIEVEMENTS/GET_LAST_LIST/REQUESTED' });

  const result = await catchWrapper(getAchievements('last_list', { user_id: userId }));

  if (result && result.result) {
    dispatch({ type: 'ACHIEVEMENTS/GET_LAST_LIST/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'ACHIEVEMENTS/GET_LAST_LIST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
