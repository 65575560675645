import { isEmpty } from 'lodash';
import formatISO from 'date-fns/formatISO';

export const getPayments = (p) => {
  const arr = [];

  const keys = Object.keys(p);
  keys.forEach((k) => {
    if (p[k].isVisible) {
      p[k].payments.forEach((payment) => {
        if (payment.price || payment.default) {
          arr.push(payment);
        }
      });
    }
  });

  const payments = arr.map((elem) => {
    return {
      type: elem.type,
      price: elem.price || null,
      default_price: elem.default_price || null,
      date: elem.date ? formatISO(new Date(elem.date)) : null,
    };
  });
  return isEmpty(payments) ? null : payments;
};
