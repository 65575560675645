import React from 'react';
import uuid from 'react-uuid';

import Skeleton from '../../../../components/skeleton';

import './styles.scss';

const array = Array.from({ length: 40 }, () => Math.floor(Math.random() * 32));

const SkeletonFriends = () => {
  return (
    <div className="sk-container-friends">
      {array.map(() => (
        <Skeleton key={uuid()} className="sk-friends" />
      ))}
    </div>
  );
};

export default SkeletonFriends;
