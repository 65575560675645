import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';
import { getIdFromUrl } from '../../../helpers/utility';
import history from '../../../routes/history';
import useOwnerData from '../../../hooks/useOwnerData';

import AllOrganizers from '../desktop/all';
import MainOrgPage from '../desktop/main';
import PersonnelSponsorPage from '../desktop/personnel';
import ParticipantsSponsorPage from '../desktop/participants';
import EventsOrganizers from '../desktop/events';
import SettingSponsorPage from '../desktop/settingMain';
import AboutOrgPage from '../desktop/about';
import AgreementsOrgPage from '../desktop/agreements';
import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import MobileNavigation from '../../../components/headerConstructor/components/mobileNavigation';
import Button from '../../../components/button';

import './styles.scss';
import IncomingRequest from '../desktop/incomingRequest';

const SponsorSettingWrapper = React.lazy(() => import('../desktop/settingWrapper'));

const OrgPageMobile = ({
  navigationMap,
  onSubmit,
  isDisabled,
  isSettingPages,
  isSponsors,
  isSettingLinksPages,
  search,
  onChangeSearch,
  isCreatePage,
  ...params
}) => {
  const { location } = history;
  const isShowSidebarButton = location.pathname === Pages.organizers.all;
  const isShowTitle = location.pathname !== Pages.organizers.all;

  const { sponsorId, sponsorRole, isProfileLoaded, userSponsors } = useOwnerData();

  const id = getIdFromUrl(location.pathname);
  const userSponsor = id && userSponsors ? userSponsors.find((item) => item.id === id) : null;

  const title = isSettingPages ? 'Настройки' : 'Организатор';

  return (
    <>
      <HeaderConstructor className="organizers mobile">
        <HeaderLeft>
          {isShowSidebarButton ? <SidebarButton /> : <GoBackButton />}
          {isShowTitle ? <HeaderTitle title={title} /> : null}
        </HeaderLeft>
        {!isSponsors ? (
          <HeaderRight>
            {isSettingPages && !isSettingLinksPages && !isCreatePage ? (
              <Button disabled={isDisabled} onClick={onSubmit} secondVariant="text" variant="done">
                Готово
              </Button>
            ) : null}
            {isCreatePage ? (
              <Button onClick={onSubmit} secondVariant="text" variant="done">
                Создать
              </Button>
            ) : null}
          </HeaderRight>
        ) : (
          <HeaderRight>
            <>
              <Controls />
              <Menu />
            </>
          </HeaderRight>
        )}
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        {!isSponsors ? (
          <div className="mobile-controls__wrap">
            <MobileNavigation navigationMap={navigationMap} />
          </div>
        ) : null}
        <Switch>
          <PrivateRoute
            exact
            path={Pages.organizers.all}
            component={AllOrganizers}
            extraProps={{ navigationMap, search, onChangeSearch, isShowSidebarButton, ...params }}
          />
          <PrivateRoute exact path={Pages.organizers.main} component={MainOrgPage} />
          <PrivateRoute exact path={Pages.organizers.personnel} component={PersonnelSponsorPage} />
          <PrivateRoute
            exact
            path={Pages.organizers.participants}
            component={ParticipantsSponsorPage}
          />
          <PrivateRoute exact path={Pages.organizers.events} component={EventsOrganizers} />
          <PrivateRoute
            exact
            path={Pages.organizers.settings.create}
            component={SettingSponsorPage}
          />
          <PrivateRoute exact path={Pages.organizers.about} component={AboutOrgPage} />
          <PrivateRoute exact path={Pages.organizers.agreements} component={AgreementsOrgPage} />
          <PrivateRoute
            withAccess={isProfileLoaded}
            hasAccess={getIdFromUrl(location.pathname) === sponsorId && sponsorRole}
            redirectPath={{
              pathname: Pages.organizers.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.organizers.request}
            component={IncomingRequest}
          />
          <PrivateRoute
            withAccess={isProfileLoaded}
            hasAccess={userSponsor && userSponsor.user_role}
            redirectPath={{
              pathname: Pages.organizers.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.organizers.settings.base}
            component={SponsorSettingWrapper}
          />
          <Redirect to={Pages.organizers.all} />
        </Switch>
      </div>
    </>
  );
};

export default OrgPageMobile;
