import React, { useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import Gallery from 'react-grid-gallery';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/store';
import { blogActions } from '../../store/blog';
import LightBoxCounter from './components/lightBoxCounter';
import ModalHeaderBlog from '../components/modalHeaderBlog';
import EntitiesActions from '../../pages/components/entitiesActions';
import { socialActions } from '../../store/social';
import './styles.scss';

const albumImagesRemapped = ({
  albumImages,
  handleLike,
  handleBookmark,
  getFullImageInfo,
  imageInfo,
  videoInfo,
  getComments,
  parentType,
  comments,
  clearComments,
  sendComment,
  text,
  handleChangeText,
  clearFullImageInfo,
}) =>
  albumImages?.map((img) => ({
    src: img?.image?.image,
    thumbnail: img?.image?.preview,
    // thumbnailWidth: 120,
    // thumbnailHeight: 120,
    isSelected: false,
    id: img.id,
    albumId: img.album_id,
    // Нижняя часть кастомная!!!
    caption: (
      <EntitiesActions
        handleLike={handleLike}
        handleBookmark={handleBookmark}
        id={img.id}
        getFullInfo={getFullImageInfo}
        imageInfo={imageInfo}
        videoInfo={videoInfo}
        getComments={getComments}
        parentType={parentType}
        comments={comments}
        clearComments={clearComments}
        contentType="image"
        sendComment={sendComment}
        text={text}
        handleChangeText={handleChangeText}
        clearFullInfo={clearFullImageInfo}
      />
    ),
  }));

const getIsSelected = (images) => {
  const t = images.filter((i) => i.isSelected);
  return !isEmpty(t) ? t : null;
};

const BlogAlbumModal = ({ title, onCancel, albumId, blogHolder, parentId, parentType, blogId }) => {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [page, setPage] = useState(1);
  const [text, setText] = useState('');
  const { albumImages, paginationAlbumImages } = useSelector((s) => s?.blog);
  const { videoInfo, imageInfo, comments } = useSelector((s) => s?.social);
  const {
    getBlogAlbumImages,
    onUploadBlogImage,
    clearBlogAlbumImages,
    onRemoveImagesFromAlbum,
    clearBlogAlbums,
    getBlogAlbumList,
  } = useActions(blogActions);
  const {
    getComments,
    writeComments,
    likeRequest,
    bookmark,
    getFullImageInfo,
    clearComments,
    clearFullImageInfo,
  } = useActions(socialActions);
  const count = useRef(0);
  const countAll = useRef(0);

  useEffect(() => {
    getBlogAlbumImages({ page, parentId, parentType, albumId });

    return () => {
      clearBlogAlbumImages();
    };
  }, []);

  useEffect(() => {
    if (paginationAlbumImages?.hasMore && images?.length > 0) {
      if (currentImage === images?.length) {
        setPage(page + 1);
        getBlogAlbumImages({ page: page + 1, parentId, parentType, albumId });
      }
    }
  }, [currentImage]);

  const handleLike = async (picId) => {
    await likeRequest({ id: picId, type: 'image' });
    await getFullImageInfo({ id: picId });
  };
  const handleBookmark = async (picId) => {
    await bookmark({ id: picId, type: 'image' });
    await getFullImageInfo({ id: picId });
  };

  const sendComment = async () => {
    await writeComments({ text, id: imageInfo?.id, contentType: 'image' });
    await getFullImageInfo({ id: imageInfo?.id });
    // await clearComments();
    await setText('');
    await getComments({ id: imageInfo?.id, contentType: 'image' });
  };

  const handleChangeText = (e) => {
    const { value } = e.target;
    setText(value);
  };

  useEffect(() => {
    if (albumImages) {
      setImages(
        albumImagesRemapped({
          albumImages,
          handleLike,
          handleBookmark,
          getFullImageInfo,
          imageInfo,
          videoInfo,
          getComments,
          parentType,
          comments,
          clearComments,
          sendComment,
          text,
          handleChangeText,
          clearFullImageInfo,
        }),
      );
    }
  }, [albumImages, imageInfo, videoInfo, text, comments]);

  const handleRemoveImages = () => onRemoveImagesFromAlbum({ images, parentId });

  const currentImageWillChange = (index) => {
    setCurrentImage(index + 1);
  };

  const handleSelectImage = (index, image) => {
    const tmp = images.map((i) => {
      if (i.id === image.id) {
        if (i.isSelected) {
          return {
            ...i,
            isSelected: false,
          };
        }
        return {
          ...i,
          isSelected: true,
        };
      }
      return i;
    });
    setImages(tmp);
  };

  return (
    <div className="modal-wrapper">
      <ModalHeaderBlog
        title={title}
        onCancel={onCancel}
        close={onCancel}
        imagePicker={blogHolder}
        onUploadPhoto={onUploadBlogImage}
        parentId={parentId}
        parentType={parentType}
        albumId={albumId}
        getAlbumImages={getBlogAlbumImages}
        clearBlogAlbumImages={clearBlogAlbumImages}
        count={count}
        countAll={countAll}
        isSelected={getIsSelected(images)}
        removeImages={handleRemoveImages}
        clearBlogAlbums={clearBlogAlbums}
        getBlogAlbumList={getBlogAlbumList}
      />
      <div className="modal-content">
        {count.current === countAll.current ? null : (
          <div className="upload-counter">{`Загружено ${count.current} из ${countAll.current}`}</div>
        )}
        <Gallery
          images={images}
          currentImageWillChange={currentImageWillChange}
          showImageCount={false}
          onSelectImage={handleSelectImage}
          enableImageSelection={blogHolder}
          customControls={[
            <LightBoxCounter
              key="1"
              current={currentImage}
              total={paginationAlbumImages?.total_items}
            />,
          ]}
        />
        <div className="modal-content__images">
          {/*{albumImages?.length*/}
          {/*  ? albumImages?.map((image) => (*/}
          {/*      <div className="modal-content__images-wrap" key={image?.id}>*/}
          {/*        <img src={image?.image?.image} alt="" />*/}
          {/*      </div>*/}
          {/*    ))*/}
          {/*  : 'В альбоме нет фото'}*/}
        </div>
      </div>
    </div>
  );
};

export default BlogAlbumModal;
