import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { saveBlogLink } from '../../../api/v2';

export default (data) => async (dispatch, getState) => {
  const { blog } = getState().blog;

  dispatch({ type: 'BLOG/SAVE_BLOG_LINK/REQUESTED' });

  const result = await catchWrapper(saveBlogLink({ id: blog.id, ...data }));

  if (result && result.result) {
    const newServices = { ...blog?.social_service };
    newServices[data.service].value = data.value;

    batch(() => {
      dispatch({
        type: 'BLOG/SAVE_BLOG_LINK/SUCCEEDED',
        payload: newServices,
      });
    });
  } else {
    dispatch({
      type: 'BLOG/SAVE_BLOG_LINK/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
