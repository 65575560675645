import React from 'react';
import uuid from 'react-uuid';
import Skeleton from '../../../../components/skeleton';

import './styles.scss';

const array = Array.from({ length: 40 }, () => Math.floor(Math.random() * 32));

const SkeletonParticipants = () => {
  return (
    <div className="sk-container">
      {array.map(() => (
        <Skeleton key={uuid()} className="sk-participants" />
      ))}
    </div>
  );
};

export default SkeletonParticipants;
