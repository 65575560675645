import React from 'react';
import Button from '../../components/button';

import './styles.scss';

const WarningModal = ({ content, onCancel }) => {
  return (
    <div className="modal__wrapper">
      <div className="modal__content">{content}</div>
      <div className="modal__footer">
        <Button onClick={onCancel} variant="main">
          Закрыть
        </Button>
      </div>
    </div>
  );
};

export default WarningModal;
