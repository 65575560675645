import { batch } from 'react-redux';
import { CometChat } from '@cometchat-pro/chat';
import { catchWrapper } from '../../../helpers/utility';
import { postAuth } from '../../../api';
import { goToLogin } from '../../../routes/actions';
import { localStorageService } from '../../../services/localStorageService';
import { MSG_TYPES } from '../../msg/constants';

export default () => async (dispatch) => {
  dispatch({ type: 'AUTH/LOGOUT/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'logOut');

  const result = await catchWrapper(postAuth(formData));

  if (result && !result.auth) {
    goToLogin();
    batch(() => {
      dispatch({ type: 'AUTH/LOGOUT/SUCCEEDED' });
      dispatch({ type: 'PROFILE/SET_USER', payload: {} });
      dispatch({ type: MSG_TYPES.LOGOUT });
    });
    localStorageService.clearAuthData();
    CometChat.logout();
  } else {
    dispatch({ type: 'AUTH/LOGOUT/FAILED' });
  }
};
