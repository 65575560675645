import React from 'react';
import { isEmpty } from 'lodash';

import './styles.scss';

const AuthSocialButtons = ({ providers, text }) => {
  return (
    <div className="auth-social-container">
      <div className="auth-social__title">{text}</div>
      <div className="auth-social__wrap">
        {!isEmpty(providers) &&
          providers.map((item) => {
            return (
              <a key={item.id} href={item.url}>
                <img src={item.icon} className="auth-social__icon" alt={item.title} />
              </a>
            );
          })}
      </div>
    </div>
  );
};

export default AuthSocialButtons;
