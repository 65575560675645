import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { Pages } from '../../../../../routes/pages';

const BlogCard = ({ blog }) => {
  const pic = blog?.image?.preview;
  const tags = blog?.tags;
  const title = blog?.profile?.title;
  const sign = blog?.profile?.sign;
  const blogUrl = Pages.blog.main.replace(':id', blog?.id);
  return (
    <div className="card blog-card">
      <div className="blog-card__left">
        <Link to={blogUrl}>
          <div className="blog-card__image_wrap">{pic ? <img src={pic} alt="" /> : null}</div>
        </Link>
      </div>
      <div className="blog-card__right">
        <Link to={blogUrl}>
          <span className="blog-card__title">{title}</span>
        </Link>
        <span className="blog-card__text">{sign}</span>
        <span className="blog-card__text">{blog?.qty_of_subs}</span>
        <div className="blog-card__roles">
          {tags &&
            tags.map((tag, i) => (
              <span key={tag?.id}>
                {tag?.title}
                {i < tags?.length - 1 && ', '}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
