import React, { useEffect, useState } from 'react';
import Icon from '../../../components/icon';
import Button from '../../../components/button';
import './styles.scss';
import Comment from '../comment';
import { classNames as cx } from '../../../helpers/utility';
import CommentsWithAttach from '../commentsWithAttach';

const EntitiesActions = ({
  handleChangeText,
  text,
  sendComment,
  handleBookmark,
  handleLike,
  id,
  getFullInfo,
  videoInfo,
  imageInfo,
  getComments,
  contentType,
  comments,
  clearComments,
  clearFullInfo,
}) => {
  const [state, setState] = useState(false);
  const handleOpenComments = () => setState(!state);
  useEffect(() => {
    if (id) {
      getFullInfo({ id });
      getComments({ page: 1, id, contentType });
    }
    return () => {
      clearComments();
      clearFullInfo();
    };
  }, [id]);
  const likeClassNames = cx('entities_actions__actions-item', {
    liked: videoInfo?.like?.is_liked || imageInfo?.like?.is_liked,
  });
  const bookmarkClassNames = cx('entities_actions__actions-item', {
    bookmarked: videoInfo?.linked?.is_linked || imageInfo?.linked?.is_linked,
  });

  return (
    <div className="entities_actions">
      <div className="entities_actions__actions-items">
        <div className="entities_actions__actions-item">
          <Button onClick={handleOpenComments} variant="icon">
            <Icon type="comment" />
          </Button>
          <div className="entities_actions__actions-item__text">
            {videoInfo?.comments?.count || imageInfo?.comments?.count || 0}
          </div>
        </div>
        <div className={likeClassNames}>
          <Button onClick={id ? () => handleLike(id) : handleLike} variant="icon">
            <Icon type="like" />
          </Button>
          <div className="entities_actions__actions-item__text">
            {videoInfo?.like?.count || imageInfo?.like?.count || 0}
          </div>
        </div>
        <div className={bookmarkClassNames}>
          <Button onClick={id ? () => handleBookmark(id) : handleBookmark} variant="icon">
            <Icon type="bookmark" />
          </Button>
          <div className="entities_actions__actions-item__text" />
        </div>
      </div>

      {state && (
        <div className="entities_actions__comments">
          {comments && comments?.map((comment) => <Comment comment={comment} key={comment?.id} />)}
          <CommentsWithAttach
            sendComment={sendComment}
            handleChangeText={handleChangeText}
            text={text}
          />
        </div>
      )}
    </div>
  );
};

export default EntitiesActions;
