import { isEmpty } from 'lodash';
import getUsers from './impl/getUsers';
import getFriends from './impl/getFriends';
import getMutualFriends from './impl/getMutualFriends';
import sentFriendRequest from './impl/sentFriendsRequest';
import getIncomingFriends from './impl/getIncomingFriends';
import removeFriend from './impl/removeFriend';
import cancelFriendRequest from './impl/cancelFriendRequest';
import getOutgoingFriends from './impl/getOutgoingFriends';
import clearUsers from './impl/clearUsers';

import cancelIncoming from './impl/cancelIncoming';
import acceptIncoming from './impl/acceptIncoming';

import cancelOutgoing from './impl/cancelOutgoing';

export const usersReducer = (state = null, action) => {
  switch (action.type) {
    case 'USERS/GET_INCOMING/REQUESTED':
    case 'USERS/GET_OUTGOING/REQUESTED':
    case 'USERS/LOAD_USERS/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    //Эти запросы не показывают скелетоны
    case 'USERS/REMOVE_FRIEND/REQUESTED':
    case 'USERS/CANCEL_FRIEND_REQUEST/REQUESTED':
    case 'USERS/CANCEL_INCOMING/REQUESTED':
    case 'USERS/SENT_FRIEND_REQUEST/REQUESTED':
    case 'USERS/ACCEPT_INCOMING/REQUESTED':
    case 'USERS/CANCEL_OUTGOING_FRIEND/REQUESTED': {
      return {
        ...state,
        loadingAction: state.loadingAction + 1,
      };
    }

    //Удаление из друзей
    case 'USERS/REMOVE_FRIEND/SUCCEEDED': {
      const isFriends = !isEmpty(state.friends);

      const newState = isFriends
        ? {
            ...state,
            loadingAction: state.loadingAction - 1,
            friends: state.friends.filter((f) => f.id !== action.payload),
          }
        : {
            ...state,
            loadingAction: state.loadingAction - 1,
            users: action.extra
              ? state.users.filter((f) => f.id !== action.payload)
              : state.users.map((u) => {
                  if (u.id === action.payload) {
                    return {
                      ...u,
                      is_friend: false,
                      is_request_received: true,
                      is_request_was_sent: false,
                    };
                  }
                  return u;
                }),
          };

      return {
        ...newState,
      };
    }

    //case 'USERS/CANCEL_INCOMING/SUCCEEDED':

    //Принять заявку в друзья
    case 'USERS/ACCEPT_INCOMING/SUCCEEDED': {
      const isIncoming = !isEmpty(state.incoming);

      const newState = isIncoming
        ? {
            ...state,
            loadingAction: state.loadingAction - 1,
            incoming: state.incoming.filter((f) => f.id !== action.payload),
          }
        : {
            ...state,
            loadingAction: state.loadingAction - 1,
            users: state.users.map((u) => {
              if (u.id === action.payload) {
                return {
                  ...u,
                  is_friend: true,
                  is_request_received: false,
                  is_request_was_sent: false,
                };
              }
              return u;
            }),
          };

      return {
        ...newState,
      };
    }

    //Отменить запрос на добавление в друзья
    case 'USERS/CANCEL_OUTGOING_FRIEND/SUCCEEDED': {
      return {
        ...state,
        loadingAction: state.loadingAction - 1,
        outgoing: state.outgoing.filter((f) => f.id !== action.payload),
      };
    }

    //Загрузка Входщих завок
    case 'USERS/GET_INCOMING/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        incoming: [...state.incoming, ...action.payload.users],
        incomingPagination: action.payload.pagination,
      };
    }

    //Загрузка Исходящих заявок
    case 'USERS/GET_OUTGOING/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        outgoing: [...state.outgoing, ...action.payload.users],
        outgoingPagination: action.payload.pagination,
      };
    }

    //Загрузка Users
    case 'USERS/LOAD_USERS/SUCCEEDED': {
      const newState = {
        ...state,
        loading: state.loading - 1,
        users: [...state.users, ...action.payload.users],
        pagination: action.payload.pagination,
      };

      return {
        ...newState,
      };
    }

    //Загрузка Users по фильтру
    case 'USERS/LOAD_USERS_FILTER/SUCCEEDED': {
      const newState = {
        ...state,
        loading: state.loading - 1,
        users: [...action.payload.users],
        pagination: action.payload.pagination,
      };

      return {
        ...newState,
      };
    }

    //Отправленна заявка на добавление в друзья
    case 'USERS/SENT_FRIEND_REQUEST/SUCCEEDED': {
      return {
        ...state,
        loadingAction: state.loadingAction - 1,
        users: state.users.map((u) => {
          if (u.id === action.payload) {
            return {
              ...u,
              is_friend: false,
              is_request_received: false,
              is_request_was_sent: true,
            };
          }
          return u;
        }),
      };
    }

    //Отменена заявка на добавление в друзья
    case 'USERS/CANCEL_FRIEND_REQUEST/SUCCEEDED': {
      return {
        ...state,
        loadingAction: state.loadingAction - 1,
        users: state.users.map((u) => {
          if (u.id === action.payload) {
            return {
              ...u,
              is_friend: false,
              is_request_received: false,
              is_request_was_sent: false,
            };
          }
          return u;
        }),
      };
    }

    case 'USERS/SENT_FRIEND_REQUEST/FAILED':
    case 'USERS/CANCEL_FRIEND_REQUEST/FAILED':
    case 'USERS/REMOVE_FRIEND/FAILED':
    case 'USERS/CANCEL_INCOMING/FAILED':
    case 'USERS/ACCEPT_INCOMING/FAILED': {
      return {
        ...state,
        loadingAction: state.loadingAction - 1,
      };
    }

    case 'USERS/LOAD_USERS/FAILED':
    case 'USERS/GET_INCOMING/FAILED':
    case 'USERS/GET_OUTGOING/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: action.payload,
      };
    }

    case 'USERS/CLEAR_USERS': {
      return {
        ...state,
        users: [],
        pagination: {},
        friends: [],
        friendsPagination: {},
        incoming: [],
        incomingPagination: {},
        outgoing: [],
        outgoingPagination: {},
      };
    }
    default:
      return state;
  }
};

export const usersActions = {
  getUsers,
  getFriends,
  getMutualFriends,
  clearUsers,
  sentFriendRequest,
  getIncomingFriends,
  removeFriend,
  cancelFriendRequest,
  getOutgoingFriends,
  cancelIncoming,
  acceptIncoming,
  cancelOutgoing,
};
