import React from 'react';
import Trans from '../../../../components/trans';

const SwitchSex = ({ onChange, gender, isFriends }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className="switch-type">
      {isFriends ? (
        <div className="switch-type_radio_btn">
          <input
            onChange={handleChange}
            checked={gender === 'any'}
            id="radio-3"
            type="radio"
            name="radio"
            value="any"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="radio-3">
            <span>{Trans('friends.filter.any')}</span>
          </label>
        </div>
      ) : null}

      <div className="switch-type_radio_btn">
        <input
          onChange={handleChange}
          checked={gender === 'male'}
          id="radio-1"
          type="radio"
          name="radio"
          value="male"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="radio-1">
          <span>{Trans('friends.filter.male')}</span>
        </label>
      </div>

      <div className="switch-type_radio_btn">
        <input
          onChange={handleChange}
          checked={gender === 'female'}
          id="radio-2"
          type="radio"
          name="radio"
          value="female"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="radio-2">
          <span>{Trans('friends.filter.female')}</span>
        </label>
      </div>
    </div>
  );
};

export default SwitchSex;
