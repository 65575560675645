import React, { useEffect, useRef } from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import Button from '../../../../components/button';
import { useActions, useSelector } from '../../../../hooks/store';
import CustomInput from '../../../../components/input';
// eslint-disable-next-line import/no-named-as-default-member
import TextEditor from '../../../../components/textEditor';
import { blogActions } from '../../../../store/blog';
import UserCardInfo from '../../components/userCardInfo';
import SponsorBannerBlock from '../../../sponsors/components/sponsorBannerBlock';
import JoditEditor from '../../../../components/textEditor2';
import Icon from '../../../../components/icon';
import history from '../../../../routes/history';
import { useResponsive } from '../../../../hooks/useResponsive';
import Trans from '../../../../components/trans';

const EditArticlePage = () => {
  const { id } = useParams();
  const editor = useRef(null);
  const { goBack } = history;
  const { isMobile } = useResponsive();
  const { blog, loading, article, articleSnapshot } = useSelector((s) => s?.blog);
  const { token } = useSelector((s) => s?.auth);
  const {
    getBlogArticleTypes,
    getArticle,
    onChangeArticleField,
    onEditBlogArticle,
    onChangeArticleText,
    onChangeArticleTitle,
  } = useActions(blogActions);
  useEffect(() => {
    if (token) {
      if (id) {
        getArticle(id);
        getBlogArticleTypes();
      }
    }
  }, [id, token]);

  const getIsDisabledMainPage = () => {
    const image = !!blog?.articleImageTemp?.flag?.blob;
    let isDisabled;
    if (image && isEqual(article, articleSnapshot)) isDisabled = false;
    if (!image && isEqual(article, articleSnapshot)) isDisabled = true;
    if (image && !isEqual(article, articleSnapshot)) isDisabled = false;
    return isDisabled;
  };

  const handleChangeInput = (e) => {
    const { value } = e.target;
    onChangeArticleTitle(value);
  };

  const handleChangeText = (value) => {
    onChangeArticleText(value);
  };
  const onCreateArticle = async () => {
    const payload = {
      profile: { title: article?.title, text: article?.text },
      type: 1,
      hash: article?.hash,
      id: Number(id),
      blog_id: Number(article?.blog?.id),
    };
    await onEditBlogArticle(payload, blog?.articleImageTemp?.flag);
  };

  const handleChangeImages = (name, image) => {
    onChangeArticleField('blog', {
      ...blog,
      articleImageTemp: { [name]: image },
    });
  };
  const handleGoTo = () => {
    goBack();
  };
  const element = { hash: article?.hash, article_id: Number(id) || null };

  return (
    <div className="blog-page__edit-article">
      <div className="blog-page__edit-article__info">
        <div className="blog-page__edit-article__info__card">
          <UserCardInfo blog={article} ownerId={blog?.id} />
        </div>
        <div className="action-buttons">
          <div className="action-buttons__save">
            <Button
              disabled={loading || getIsDisabledMainPage()}
              onClick={onCreateArticle}
              tabIndex="0"
              variant="main"
            >
              {Trans('actions.save')}
            </Button>
          </div>
          {!isMobile && (
            <div className="action-buttons__close">
              <Button onClick={handleGoTo} className="go-back" variant="icon">
                <Icon type="add" />
              </Button>
            </div>
          )}
        </div>
      </div>
      <SponsorBannerBlock
        handleChangeImages={handleChangeImages}
        sponsor={articleSnapshot}
        imagesState={blog?.articleImageTemp}
        isEditable
      />
      <div className="blog-page__edit-article__title">
        <CustomInput
          labelPosition="top"
          onChange={handleChangeInput}
          value={article?.title}
          name="title"
          placeholder="Укажите заголовок статьи"
        />
      </div>
      <div className="blog-page__edit-article__editor">
        <JoditEditor
          value={article?.text}
          snapshotValue={articleSnapshot?.text}
          ref={editor}
          onChange={handleChangeText}
          onBlur={handleChangeText}
          name="text"
          element={article?.hash && element}
        />
      </div>
    </div>
  );
};

export default EditArticlePage;
