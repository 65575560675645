import React, { useState } from 'react';

import modalService from '../../services/modalService';

import Button from '../../components/button';
import CustomInput from '../../components/input';
import ModalHeader from '../components/modalHeaderBlog';

import './styles.scss';
import Trans from '../../components/trans';

const ChangeLinkModal = ({ onCancel, service, value, onSave }) => {
  const [state, setState] = useState(value || '');

  const handleChangeInput = (e) => setState(e.target.value);

  const onSubmit = () => {
    const payload = {
      service,
      value: state,
    };

    onSave(payload);
    modalService.closeAll();
  };

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title="Изменить ссылку" />
      <div className="modal-content">
        <CustomInput
          value={state}
          onChange={handleChangeInput}
          label="Ссылка"
          labelPosition="top"
          placeholder="Вставьте ссылку"
        />
      </div>
      <div className="modal-footer">
        <Button onClick={onSubmit} variant="main">
          {Trans('actions.save')}
        </Button>
      </div>
    </div>
  );
};

export default ChangeLinkModal;
