import Vk from './vk';
import Facebook from './facebook';
import Ok from './ok';
import Instagram from './instagram';
import Youtube from './youtube';
import Tiktok from './tiktok';

export default {
  vk: Vk,
  facebook: Facebook,
  ok: Ok,
  instagram: Instagram,
  youtube: Youtube,
  tiktok: Tiktok,
};
