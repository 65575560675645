export const myProfileMap = [
  {
    id: '1',
    label: 'Кто видит мои ФИО',
    name: 'fio',
  },
  {
    id: '2',
    label: 'Кто видит мой адрес',
    name: 'address',
  },
  {
    id: '3',
    label: 'Кто видит мой номер телефона',
    name: 'phone',
  },
  {
    id: '4',
    label: 'Кто видит мою почту',
    name: 'email',
  },
];
export const connectMap = [
  {
    id: '1',
    label: 'Кто может писать мне личные сообщения',
    name: 'messages',
  },
  {
    id: '2',
    label: 'Кто может отправлять мне заявки в друзья',
    name: 'friendship',
  },
  {
    id: '3',
    label: 'Кто может приглашать меня на мероприятия',
    name: 'events',
  },
];
export const dropdownMap = [
  {
    id: '1',
    label: 'Никто',
    value: 'no_one',
  },
  {
    id: '2',
    label: 'Только команда',
    value: 'team',
  },
  {
    id: '3',
    label: 'Только друзья',
    value: 'friends',
  },
  {
    id: '4',
    label: 'Друзья и команда',
    value: 'friends_and_team',
  },
  {
    id: '5',
    label: 'Все пользователи',
    value: 'all',
  },
];

export const dropdownMapSecond = [
  {
    id: '1',
    label: 'Никто',
    value: 'no_one',
  },
  {
    id: '2',
    label: 'Только команда',
    value: 'team',
  },
  {
    id: '3',
    label: 'Все пользователи',
    value: 'all',
  },
];
