import React, { useState } from 'react';
import { isEqual } from 'lodash';

import { useResponsive } from '../../hooks/useResponsive';
import SettingsPageDesktop from './desktop';
import SettingsPageMobile from './mobile';
import { Pages } from '../../routes/pages';
import { useActions, useSelector } from '../../hooks/store';
import { profileActions } from '../../store/profile';
import { settingsActions } from '../../store/settings';
import history from '../../routes/history';

const getActions = (url, saveProfile, savePassport, savePrivate) => {
  switch (url) {
    case Pages.settings.profile:
      return saveProfile;
    case Pages.settings.passport:
      return savePassport;
    case Pages.settings.private:
      return savePrivate;
    default:
      return console.log('action');
  }
};

const getDisabled = ({ path, user, userSnapshot, privacy, privacySnapshot }) => {
  switch (path) {
    case Pages.settings.profile:
      return isEqual(user, userSnapshot);
    case Pages.settings.passport:
      return isEqual(user, userSnapshot);
    case Pages.settings.private:
      return isEqual(privacy, privacySnapshot);
    default:
      return true;
  }
};

const navigationSettingsMap = [
  {
    id: '1',
    path: Pages.settings.profile,
    label: 'Профиль',
  },
  {
    id: '2',
    path: Pages.settings.passport,
    label: 'Игровой паспорт',
  },
  {
    id: '3',
    path: Pages.settings.links,
    label: 'Ссылки',
  },
  {
    id: '4',
    path: Pages.settings.private,
    label: 'Приватность',
  },
  {
    id: '5',
    path: Pages.settings.security,
    label: 'Безопасность',
  },
];

const SettingsPage = () => {
  const { user, userSnapshot, loading } = useSelector((state) => state.profile);
  const { privacy, privacySnapshot } = useSelector((state) => state.settings);

  const { isMobile } = useResponsive();
  const { location } = history;

  const { saveProfile, savePassport, onUserClone } = useActions(profileActions);
  const { savePrivateData } = useActions(settingsActions);

  const isShowButton =
    location.pathname === Pages.settings.links || location.pathname === Pages.settings.security;

  const isDisabled = getDisabled({
    path: location.pathname,
    user,
    userSnapshot,
    privacy,
    privacySnapshot,
  });

  return isMobile ? (
    <SettingsPageMobile
      navigationMap={navigationSettingsMap}
      onSubmit={getActions(location.pathname, saveProfile, savePassport, savePrivateData)}
      disabled={isDisabled}
      isShowButton={!isShowButton}
      onUserClone={onUserClone}
    />
  ) : (
    <SettingsPageDesktop
      navigationMap={navigationSettingsMap}
      onSubmit={getActions(location.pathname, saveProfile, savePassport, savePrivateData)}
      disabled={isDisabled}
      loading={loading}
      isShowButton={!isShowButton}
      onUserClone={onUserClone}
    />
  );
};

export default SettingsPage;
