import React from 'react';

import PaymentSection from '../payment';

import './styles.scss';

const Payments = ({ payments, onChange, onChangeCheckbox, onChangeDate }) => {
  return (
    <div className="payments-event payments-event__container">
      <PaymentSection
        onChange={onChange}
        onChangeCheckbox={onChangeCheckbox}
        onChangeDate={onChangeDate}
        payments={payments.base}
        section="base"
        mainLabel={payments.base.isVisible ? 'Стоимость участия' : 'Стоимость участия (бесплатно)'}
      />
      <PaymentSection
        onChange={onChange}
        onChangeCheckbox={onChangeCheckbox}
        onChangeDate={onChangeDate}
        payments={payments.regions}
        section="regions"
        mainLabel="Для регионов"
      />
      <PaymentSection
        onChange={onChange}
        onChangeCheckbox={onChangeCheckbox}
        onChangeDate={onChangeDate}
        payments={payments.foreigners}
        section="foreigners"
        mainLabel="Для иностранцев"
      />
      <PaymentSection
        onChange={onChange}
        onChangeCheckbox={onChangeCheckbox}
        onChangeDate={onChangeDate}
        payments={payments.gold}
        section="gold"
        mainLabel="Золотой статус"
      />
      <PaymentSection
        onChange={onChange}
        onChangeCheckbox={onChangeCheckbox}
        onChangeDate={onChangeDate}
        payments={payments.silver}
        section="silver"
        mainLabel="Серебряный статус"
      />
      <PaymentSection
        onChange={onChange}
        onChangeCheckbox={onChangeCheckbox}
        onChangeDate={onChangeDate}
        payments={payments.bronze}
        section="bronze"
        mainLabel="Бронзовый статус"
      />
      <PaymentSection
        onChange={onChange}
        onChangeCheckbox={onChangeCheckbox}
        onChangeDate={onChangeDate}
        payments={payments.driver}
        section="driver"
        mainLabel="Водитель"
      />
    </div>
  );
};

export default Payments;
