import { catchWrapper } from '../../../helpers/utility';
import { postPrivate } from '../../../api';

export default () => async (dispatch) => {
  dispatch({ type: 'SETTINGS/LOAD_PRIVATE/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getPrivate');

  const result = await catchWrapper(postPrivate(formData));
  if (result && result.result) {
    dispatch({ type: 'SETTINGS/LOAD_PRIVATE/SUCCESSFUL', payload: result.private });
  } else {
    dispatch({ type: 'SETTINGS/LOAD_PRIVATE/FAILED', payload: result.message[0] });
  }
};
