import { catchWrapper } from '../../../helpers/utility';
import { postSponsorRequest } from '../../../api/v2';

export default (id, teamId) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/REQUEST_JOIN_SPONSOR/REQUESTED' });
  const result = await catchWrapper(
    postSponsorRequest({ id: Number(id), team_id: Number(teamId) }),
  );

  if (result && result.result) {
    dispatch({ type: 'SPONSOR/REQUEST_JOIN_SPONSOR/SUCCEEDED' });
  } else {
    dispatch({
      type: 'SPONSOR/REQUEST_JOIN_SPONSOR/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
