import React from 'react';
import Button from '../../../../../components/button';

import './styles.scss';
import Icon from '../../../../../components/icon';

const CategoryCard = ({ icon, title, content, btnText, text, action, isShowButton }) => {
  return (
    <div className="card category-card category-card__container">
      <div className="category-card__top">
        <Icon type={icon} />
      </div>
      <div className="category-card__bottom">
        <span className="category-card__title">{title}</span>
        <span className="category-card__text">{content}</span>
        {isShowButton ? (
          <Button onClick={action} variant="main">
            {btnText}
          </Button>
        ) : (
          <span className="category-card__text-small">{text}</span>
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
