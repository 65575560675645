import React from 'react';
import Button from '../../../../components/button';
import Trans from '../../../../components/trans';

const RecruitingControlButton = ({ openRecruitingModal, isRecruitingRequest, disabled }) => {
  return (
    <div className="create-request__wrap">
      <Button
        disabled={disabled}
        onClick={openRecruitingModal}
        className="create-request"
        variant="main"
      >
        {isRecruitingRequest ? Trans('actions.edit') : Trans('actions.apply')}
      </Button>
    </div>
  );
};

export default RecruitingControlButton;
