import React, { useState } from 'react';
import ImagePicker from '../../../../../../components/imagePicker';
import Button from '../../../../../../components/button';

const SelectImage = ({
  selectedImage,
  onChange,
  removeTempImage,
  fieldName,
  aspectRatio,
  minWidth,
  minHeight,
  className,
  showRemoveBtn,
  error,
}) => {
  const [state, setState] = useState(false);
  const handleOnMouseEnter = () => {
    setState(true);
  };
  const handleOnMouseLeave = () => {
    setState(false);
  };
  return (
    <div
      className={className}
      onMouseEnter={selectedImage ? handleOnMouseEnter : null}
      onMouseLeave={selectedImage ? handleOnMouseLeave : null}
    >
      {selectedImage ? <img src={selectedImage} alt="pic" /> : null}

      {selectedImage && state ? (
        <div className="create-event-banner__overlay">
          <span className="banner-overlay__title">Замените баннер игры</span>
          <span className="banner-overlay__sub-title">
            {`Оптимальные размеры изображения ${minWidth} x ${minHeight}`}
          </span>
          <div className="create-event-banner__buttons">
            <ImagePicker
              aspectRatio={aspectRatio}
              minWidth={minWidth}
              minHeight={minHeight}
              onChange={onChange}
              className="add-banner"
              fieldName={fieldName}
              viewRender={
                <span className="create-event-banner__upload-view">Заменить изображение</span>
              }
            />
            {showRemoveBtn ? (
              <Button onClick={() => removeTempImage(fieldName)} variant="text">
                Удалить
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}

      {!selectedImage ? (
        <div className="create-event-banner__buttons_empty">
          <ImagePicker
            aspectRatio={aspectRatio}
            minWidth={minWidth}
            minHeight={minHeight}
            onChange={onChange}
            //onSubmit={saveAvatar}
            className="add-banner"
            fieldName={fieldName}
            viewRender={
              <span className="create-event-banner__upload-view">Загрузить изображение</span>
            }
          />
          <span className="banner-overlay__sub-title">
            {`Оптимальные размеры изображения ${minWidth} x ${minHeight}`}
          </span>
          <span className="banner-overlay__error">{error}</span>
        </div>
      ) : null}
    </div>
  );
};

export default SelectImage;
