import { catchWrapper } from '../../../helpers/utility';
import { getUserPhotosList } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ page, parentId }) => async (dispatch) => {
  const payloadRequest = {
    page,
    objects_per_page: 20,
    user_id: Number(parentId),
  };
  dispatch({ type: 'SOCIAL/GET_PHOTOS_LIST/REQUESTED' });
  const result = await catchWrapper(getUserPhotosList(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'SOCIAL/GET_PHOTOS_LIST/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'SOCIAL/GET_PHOTOS_LIST/FAILED',
      payload: result && result?.message && result?.message[0],
    });
  }
};
