import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2628 8.96727C15.3351 8.41392 15.8066 8 16.3647 8H19.636C20.194 8 20.6656 8.41392 20.7378 8.96727L21.013 11.0747C21.6395 11.3248 22.2247 11.6506 22.7564 12.0401L24.8238 11.205C25.3468 10.9937 25.9578 11.1963 26.2478 11.6771L27.8432 14.3228C28.1331 14.8037 28.0109 15.4116 27.5583 15.7396L25.7697 17.0358C25.8121 17.3515 25.8339 17.6733 25.8339 18C25.8339 18.3267 25.8121 18.6484 25.7697 18.9641L27.5584 20.2603C28.011 20.5883 28.1332 21.1963 27.8432 21.6771L26.2478 24.3228C25.9579 24.8037 25.3468 25.0062 24.8239 24.795L22.7564 23.9599C22.2247 24.3494 21.6395 24.6752 21.013 24.9253L20.7378 27.0328C20.6656 27.5861 20.194 28 19.636 28H16.3647C15.8066 28 15.3351 27.5861 15.2628 27.0328L14.9877 24.9253C14.3611 24.6752 13.7757 24.3493 13.2439 23.9598L11.1761 24.795C10.6532 25.0062 10.0421 24.8037 9.75218 24.3228L8.15674 21.6771C7.86678 21.1963 7.98902 20.5883 8.44161 20.2603L10.2307 18.9638C10.1884 18.6482 10.1666 18.3265 10.1666 18C10.1666 17.6734 10.1884 17.3517 10.2307 17.0362L8.44168 15.7396C7.98909 15.4116 7.86685 14.8037 8.15681 14.3228L9.75225 11.6771C10.0422 11.1963 10.6532 10.9937 11.1762 11.205L13.244 12.0402C13.7757 11.6506 14.3611 11.3248 14.9877 11.0747L15.2628 8.96727ZM18.0003 21.75C20.1635 21.75 21.9172 20.0711 21.9172 18C21.9172 15.9289 20.1635 14.25 18.0003 14.25C15.8371 14.25 14.0835 15.9289 14.0835 18C14.0835 20.0711 15.8371 21.75 18.0003 21.75Z"
      fill="currentColor"
    />
  </svg>
);
