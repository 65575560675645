import React from 'react';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import useVisible from '../../../../hooks/useVisible';
import DropdownWithSchema from '../../../components/dropdown';

const UserCardActions = ({ schema, id }) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const handleOpenMenu = () => setIsVisible(!isVisible);

  return (
    <div className="user-card__user-container__action-button">
      <Button onClick={handleOpenMenu} variant="submenu">
        <Icon type="tripleDots" />
      </Button>
      {isVisible && (
        <DropdownWithSchema dropRef={ref} schema={schema} id={id} setIsVisible={setIsVisible} />
      )}
    </div>
  );
};

export default UserCardActions;
