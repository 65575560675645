import { catchWrapper } from '../../../helpers/utility';
import { postPrivate } from '../../../api';

export default () => async (dispatch) => {
  dispatch({ type: 'SETTINGS/GET_PRIVATE_SETTINGS/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'getPrivateSettings');

  const result = await catchWrapper(postPrivate(formData));

  if (result && result.result) {
    dispatch({ type: 'SETTINGS/GET_PRIVATE_SETTINGS/SUCCESSFUL', payload: result });
  } else {
    dispatch({ type: 'SETTINGS/GET_PRIVATE_SETTINGS/FAILED' });
  }
};
