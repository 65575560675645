import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../../../hooks/store';
import './styles.scss';
import ControlsSettingMain from '../../components/controlsSettingMain';
import BlockSettingMain from '../../components/blockSettingMain';
import { blogActions } from '../../../../store/blog';
import CustomInput from '../../../../components/input';

const SettingBlogPage = () => {
  const { id } = useParams();
  const { tags, blog, blogImageTemp, newBlog } = useSelector((state) => state.blog);
  //
  const { getBlogTypes, getBlogTags, getBlog, onChangeField } = useActions(blogActions);
  //
  useEffect(() => {
    getBlog(id);
    getBlogTypes();
    getBlogTags();
  }, []);

  useEffect(() => {
    // getBlogTypes();
    getBlogTags();
  }, []);

  const handleChangeImages = (name, image) => {
    onChangeField('blogImageTemp', image);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    onChangeField('blog', {
      ...blog,
      profile: { ...blog.profile, [name]: value },
    });
  };

  const handleChangeTags = (val) => {
    onChangeField('blog', {
      ...blog,
      tags: val,
    });
  };

  return (
    <div className="blog-page--settings">
      <BlockSettingMain
        blog={blog}
        blogImageTemp={blogImageTemp}
        isEditable
        handleChangeImages={handleChangeImages}
        handleChangeInput={handleChangeInput}
      />
      <ControlsSettingMain
        blog={blog}
        tags={tags}
        handleChangeTags={handleChangeTags}
        handleChangeInput={handleChangeInput}
      />
      <CustomInput
        onChange={handleChangeInput}
        value={blog?.profile?.about || newBlog?.profile?.about}
        textRows={6}
        type="textarea"
        className="about"
        label="О блоге"
        labelPosition="top"
        name="about"
        placeholder="Напишите что-нибудь о блоге"
      />
    </div>
  );
};

export default SettingBlogPage;
