import { Pages } from '../../routes/pages';

export const routesMap = (isMobile) => [
  {
    id: '1',
    icon: 'calendar',
    label: 'navigation.events',
    route: Pages.events.all,
    unAuth: true,
    auth: true,
  },
  {
    id: '2',
    icon: 'organizers',
    label: 'navigation.organizers',
    route: Pages.organizers.all,
    unAuth: false,
    auth: true,
  },
  {
    id: '3',
    icon: 'blogs',
    label: 'navigation.blogs',
    route: Pages.blog.all,
    unAuth: false,
    auth: true,
  },
  {
    id: '4',
    icon: 'teams',
    label: 'navigation.teams',
    route: Pages.teams.all,
    unAuth: false,
    auth: true,
  },
  {
    id: '5',
    icon: 'camouflage',
    label: 'navigation.camo',
    route: Pages.camouflages.all,
    unAuth: false,
    auth: true,
  },
  {
    id: '6',
    icon: 'friends',
    label: 'navigation.friends',
    route: Pages.users.main,
    unAuth: false,
    auth: true,
  },
  {
    id: '7',
    icon: 'bid',
    label: 'navigation.pages',
    route: Pages.pages.category,
    unAuth: false,
    auth: true,
  },
  {
    id: '8',
    icon: 'location',
    label: 'navigation.fields',
    route: Pages.grounds.base,
    unAuth: false,
    auth: true,
  },
  {
    id: '9',
    icon: 'location',
    label: 'navigation.msg',
    route: isMobile ? Pages.msg.conversations : Pages.msg.base,
    unAuth: false,
    auth: true,
    notDesktop: true,
  },
  {
    id: '10',
    icon: 'faq',
    label: 'navigation.faq',
    route: Pages.faq.base,
    unAuth: false,
    auth: false,
  },
  {
    id: '11',
    icon: 'friends',
    label: 'navigation.loginReg',
    route: Pages.auth.auth,
    unAuth: true,
    auth: false,
  },
];
