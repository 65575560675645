import React from 'react';
import ModalHeader from '../components/modalHeaderBlog';
import Button from '../../components/button';

import './styles.scss';

const RemoveProfileConfirmModal = ({ onCancel, onSubmit, id, nickname }) => {
  return (
    <div className="modal-wrapper">
      <ModalHeader title="Удаление аккаунта" close={onCancel} />
      <div className="modal-content">
        <span className="modal-content__text">Вы действительно хотите удалить аккаунт?</span>
        <div className="modal-content__row">
          <span className="modal-content__label">ID:</span>
          <span className="modal-content__value">{id}</span>
        </div>
        <div className="modal-content__row">
          <span className="modal-content__label">Позывной:</span>
          <span className="modal-content__value">{nickname}</span>
        </div>
        <span className="modal-content__text">
          Аккаунт будет скрыт для других пользователей, восстановить профиль можно в течении 6
          месяцев, обратившись в службу поддержки.
        </span>
      </div>
      <div className="modal-footer">
        <Button tabIndex="0" variant="main" onClick={onSubmit}>
          Подтвердить
        </Button>
      </div>
    </div>
  );
};

export default RemoveProfileConfirmModal;
