import { catchWrapper } from '../../../helpers/utility';
import { removeGroundImage } from '../../../api/v2';

export default (data) => async (dispatch, getState) => {
  dispatch({ type: 'GROUND/REMOVE_IMAGE/REQUESTED', payload: data.id });

  const result = await catchWrapper(removeGroundImage(data));

  if (result && result.result) {
    const { ground } = getState().grounds;

    ground.images = ground.images.filter((image) => image.id !== data.id);
    ground.images = ground.images.map((image) => {
      const newImage = image;
      if (image.id === result.main_image) newImage.is_main = true;

      return newImage;
    });

    dispatch({ type: 'GROUND/REMOVE_IMAGE/SUCCEEDED', payload: ground });
    dispatch({ type: 'GROUND/SET_SNAPSHOT', payload: ground });
  } else {
    dispatch({
      type: 'GROUND/REMOVE_IMAGE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
