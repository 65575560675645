import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';
import { useResponsive } from '../../../hooks/useResponsive';
import history from '../../../routes/history';
import useOwnerData from '../../../hooks/useOwnerData';
import { getIdFromUrl } from '../../../helpers/utility';

import AllOrganizers from './all';
import MainOrgPage from './main';
import PersonnelSponsorPage from './personnel';
import ParticipantsSponsorPage from './participants';
import EventsOrganizers from './events';
import SettingSponsorPage from './settingMain';
import AboutOrgPage from './about';
import AgreementsOrgPage from './agreements';
import IncomingRequest from './incomingRequest';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import InputSearch from '../../../components/inputSearch';
import Menu from '../../../components/headerConstructor/components/menu';
import Controls from '../../../components/headerConstructor/components/controls';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';
import Button from '../../../components/button';
import Icon from '../../../components/icon';

import './styles.scss';
import Trans from '../../../components/trans';

const SponsorSettingWrapper = React.lazy(() => import('./settingWrapper'));

const OrgPageDesktop = ({
  onSubmit,
  isSettingPages,
  navigationMap,
  isDisabled,
  isSettingLinksPages,
  isSponsorEventPage,
  isShowEventButton,
  search,
  onChangeSearch,
  isCreatePage,
  ...params
}) => {
  const { location } = history;
  const { isDesktop } = useResponsive();
  const isShowNavigation = location.pathname !== Pages.organizers.all;
  const isShowSearch = location.pathname === Pages.organizers.all;

  const { sponsorId, sponsorRole, isProfileLoaded, userSponsors } = useOwnerData();

  const id = getIdFromUrl(location.pathname);
  const userSponsor = id && userSponsors ? userSponsors.find((item) => item.id === id) : null;

  const handleGoToMainPage = () =>
    history.push({
      pathname: Pages.organizers.main.replace(':id', location.state),
      state: location.state,
    });

  const handleGoToCreateEvent = () => {
    history.push({
      pathname: Pages.event.settings.create,
    });
  };

  return (
    <>
      <HeaderConstructor className="organizers">
        <HeaderLeft
          title={Trans('navigation.organizers')}
          search={isShowSearch ? <InputSearch value={search} onChange={onChangeSearch} /> : null}
          navigation={isShowNavigation ? <TabletNavigation naviMap={navigationMap} /> : null}
        />
        <HeaderRight>
          {isCreatePage ? (
            <Button onClick={onSubmit} variant="save">
              Создать
            </Button>
          ) : null}
          {!isSettingPages ? (
            <>
              <Controls isShowLang />
              <Menu />
            </>
          ) : null}
          {isSettingPages && !isCreatePage ? (
            <>
              {/*<ThemeSwitch />*/}
              {isSettingLinksPages ? null : (
                <Button disabled={isDisabled} onClick={onSubmit} variant="save">
                  {Trans('actions.save')}
                </Button>
              )}

              <Button onClick={handleGoToMainPage} className="go-back" variant="icon">
                <Icon type="add" />
              </Button>
            </>
          ) : null}
        </HeaderRight>
        {isSponsorEventPage && isDesktop && isShowEventButton ? (
          <div className="create-event__wrap">
            <Button onClick={handleGoToCreateEvent} className="create-event" variant="main">
              Создать игру
            </Button>
          </div>
        ) : null}
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.organizers.all}
            component={AllOrganizers}
            extraProps={{ search, ...params }}
          />
          <PrivateRoute exact path={Pages.organizers.main} component={MainOrgPage} />
          <PrivateRoute exact path={Pages.organizers.about} component={AboutOrgPage} />
          <PrivateRoute exact path={Pages.organizers.agreements} component={AgreementsOrgPage} />
          <PrivateRoute exact path={Pages.organizers.personnel} component={PersonnelSponsorPage} />
          <PrivateRoute
            exact
            path={Pages.organizers.participants}
            component={ParticipantsSponsorPage}
          />
          <PrivateRoute
            exact
            path={Pages.organizers.events}
            component={EventsOrganizers}
            extraProps={{ handleGoToCreateEvent, isShowEventButton }}
          />
          <PrivateRoute
            exact
            path={Pages.organizers.settings.create}
            component={SettingSponsorPage}
          />
          <PrivateRoute
            withAccess={isProfileLoaded}
            hasAccess={getIdFromUrl(location.pathname) === sponsorId && sponsorRole}
            redirectPath={{
              pathname: Pages.organizers.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.organizers.request}
            component={IncomingRequest}
          />
          <PrivateRoute
            withAccess={isProfileLoaded}
            hasAccess={userSponsor && userSponsor.user_role}
            redirectPath={{
              pathname: Pages.organizers.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.organizers.settings.base}
            component={SponsorSettingWrapper}
          />
          <Redirect to={Pages.organizers.all} />
        </Switch>
      </div>
    </>
  );
};

export default OrgPageDesktop;
