import React, { memo } from 'react';
import { classNames as cx } from '../../../../helpers/utility';

import './styles.scss';

function MessageInput({ className, value, onChange, onPressEnter, placeholder, ...props }) {
  const classNames = cx('input msg-input', className, {});

  return (
    <input
      className={classNames}
      value={value}
      onChange={onChange}
      onKeyDown={onPressEnter}
      placeholder={placeholder}
      {...props}
    />
  );
}

export default memo(MessageInput);
