import React, { memo } from 'react';
import PanelDialog from '../panel-dialog';

import './styles.scss';
import { classNames as cx } from '../../../../helpers/utility';

function PanelList({ open, list, setReceiverInfo, onClosePanel, language, deleteConversation }) {
  const classNames = cx('panel-list', {
    'panel-list--open': open,
  });

  if (!list.length || !list) {
    return null;
  }

  return (
    <div className={classNames}>
      {list.map((conversation) => (
        <PanelDialog
          setReceiverInfo={setReceiverInfo}
          key={conversation.conversationId}
          conversation={conversation}
          open={open}
          onClosePanel={onClosePanel}
          language={language}
          deleteConversation={deleteConversation}
        />
      ))}
    </div>
  );
}

export default memo(PanelList);
