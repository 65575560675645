import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useActions, useSelector } from '../../../../hooks/store';
import { authActions } from '../../../../store/auth';
import SponsorLinksBlock from '../../../components/linksBlock';
import SponsorButtonsControl from '../sponsorButtonsControl';

import './styles.scss';

const BlockMain = ({
  sponsor,
  role,
  loading,
  loadingSilent,
  userSponsors,
  cancelRequestJoinSponsor,
  requestJoinSponsor,
  userTeam,
  getSponsor,
}) => {
  const { getServices } = useActions(authActions);
  const { services } = useSelector((state) => state.auth);
  const pic = sponsor && sponsor.images && sponsor.images.image && sponsor.images.image.preview;

  useEffect(() => {
    if (isEmpty(services)) getServices();
  }, []);

  return (
    <div className="card sponsor-main-block">
      <div className="image-container">
        <div className="image-main__wrap">{pic ? <img src={pic} alt="" /> : null}</div>
      </div>
      <div className="sponsor-main__info">
        <h3 className="sponsor-main__title">{(sponsor && sponsor.title) || ''}</h3>
      </div>
      <SponsorButtonsControl
        sponsor={sponsor}
        role={role}
        loading={loading}
        loadingSilent={loadingSilent}
        userSponsors={userSponsors}
        cancelRequestJoinSponsor={cancelRequestJoinSponsor}
        requestJoinSponsor={requestJoinSponsor}
        userTeam={userTeam}
        getSponsor={getSponsor}
      />
      {sponsor?.social_service && (
        <SponsorLinksBlock services={services} social={sponsor?.social_service} />
      )}
    </div>
  );
};

export default BlockMain;
