import React from 'react';

import { getImageFromFile } from '../../../../../../helpers/utility';

import SelectImage from './selectImage';

import './styles.scss';

const EventBannerSelection = ({
  imagesState,
  eventImage,
  onChange,
  removeTempImage,
  isPreviewRemove,
  errors,
}) => {
  const preview = getImageFromFile(imagesState?.preview?.blob);
  const banner = getImageFromFile(imagesState?.banner?.blob);

  const eventPreview = isPreviewRemove ? null : eventImage?.preview?.preview;
  const eventBanner = eventImage?.banner?.preview;

  return (
    <div className="create-event-banner create-event-banner__container">
      <span className="create-event-banner__title">Установите заставку игры</span>
      <div className="create-event-banner__images-wrapper">
        <SelectImage
          selectedImage={banner || eventBanner}
          onChange={onChange}
          className="create-event-banner__banner-inner"
          removeTempImage={removeTempImage}
          fieldName="banner"
          aspectRatio={1.73}
          minWidth={600}
          minHeight={346}
          error={errors?.banner}
        />

        <SelectImage
          selectedImage={preview || eventPreview}
          onChange={onChange}
          className="create-event-banner__image-inner"
          removeTempImage={removeTempImage}
          fieldName="preview"
          aspectRatio={1.44}
          minWidth={462}
          minHeight={320}
          showRemoveBtn
        />
      </div>
    </div>
  );
};

export default EventBannerSelection;
