import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import HeaderConstructor from '../../../../components/headerConstructor';
import HeaderLeft from '../../../../components/headerConstructor/components/left';
import SidebarButton from '../../../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../../../components/headerConstructor/components/title';
import HeaderRight from '../../../../components/headerConstructor/components/right';
import Controls from '../../../../components/headerConstructor/components/controls';
import Menu from '../../../../components/headerConstructor/components/menu';
import { getDangerouslyHtml } from '../../../../helpers/utility';
import { useResponsive } from '../../../../hooks/useResponsive';
import { Pages } from '../../../../routes/pages';
import history from '../../../../routes/history';
import SubNavigation from '../../../components/subNavi';

const TextPageDesktop = ({ title, text, menu }) => {
  const { user } = useSelector((state) => state.profile);
  const { isDesktop } = useResponsive();

  const { location } = history;
  console.log(location);
  const naviMap = () =>
    menu?.map((item, ind) => ({
      id: ind,
      label: item.title,
      route: {
        path: item.path,
        pathname: item.path,
        state: location.state,
      },
    }));

  return (
    <>
      <HeaderConstructor className="grounds">
        <HeaderLeft>
          {!isDesktop ? <SidebarButton /> : null}
          <HeaderTitle title={title} />
        </HeaderLeft>
        <HeaderRight>
          <>
            <Controls isShowLang />
            {!isEmpty(user) && <Menu />}
          </>
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <div className="text-block">
          <SubNavigation naviMap={naviMap()} />
          <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(text || '')} />
        </div>
      </div>
    </>
  );
};

export default TextPageDesktop;
