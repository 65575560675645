import React, { useRef } from 'react';
import { HashLoader } from 'react-spinners';
import Button from '../../../../../../components/button';
import { useActions, useSelector } from '../../../../../../hooks/store';
import { groundsActions } from '../../../../../../store/grounds';
import ImageSetting from '../imageSettings';

import './styles.scss';
import Trans from '../../../../../../components/trans';

const ImageSettings = ({ id, hash, error }) => {
  const { ground, imageLoading, imageActionId } = useSelector((state) => state.grounds);
  const { onUploadPhoto, removeImage, setMainImage } = useActions(groundsActions);

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const onChangeFile = async (e) => {
    const data = {
      id,
      hash,
    };
    const { files } = e.target;
    await onUploadPhoto({ data, files });
    document.getElementById('file-blog').value = '';
  };

  return (
    <div className="grounds-settings__images">
      <div className="grounds-settings__images-button">
        <Button
          disabled={imageLoading || imageActionId}
          tabIndex="0"
          variant="main"
          onClick={onButtonClick}
          className="add"
        >
          {Trans('fields.settings.addImage')}
        </Button>
        {!!error && (
          <span className="input__label input__label--error input__label--required">{error}</span>
        )}
      </div>
      <div className="grounds-settings__images-wrap">
        {!!ground &&
          ground?.images &&
          ground.images.map((item) => {
            if (imageActionId === item.id) {
              return (
                <div className="image loading">
                  <div className="image-loader">
                    <HashLoader size={40} color="#e26e43" />
                  </div>
                </div>
              );
            }
            return (
              <ImageSetting
                key={item.id}
                item={item}
                removeImage={removeImage}
                setMainImage={setMainImage}
                hash={hash}
                disabled={imageLoading || imageActionId}
              />
            );
          })}
        {imageLoading > 0 && (
          <div className="image loading">
            <div className="image-loader">
              <HashLoader size={40} color="#e26e43" />
            </div>
          </div>
        )}
      </div>
      <input
        accept="image/x-png,image/gif,image/jpeg, image/jpg"
        onChange={onChangeFile}
        id="file-blog"
        ref={inputFile}
        style={{ display: 'none' }}
        multiple
        type="file"
      />
    </div>
  );
};

export default ImageSettings;
