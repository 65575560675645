import moment from 'moment';
import { catchWrapper } from '../../../helpers/utility';
import { createSponsor } from '../../../api/v2';
import { gaToSponsorMain, gaToSponsorSetting } from '../../../routes/actions';
import { validationCreateSponsor } from '../validation';
import onChangeField from './onChangeField';
import { onChangeProfileField } from '../../profile';

export default () => async (dispatch, getState) => {
  const { sponsor, sponsorImageTemp } = getState().sponsor;

  const formData = new FormData();

  const error = validationCreateSponsor({ sponsor, sponsorImageTemp });

  formData.append('element', JSON.stringify(sponsor));

  if (sponsorImageTemp.image) {
    const { blob, file } = sponsorImageTemp.image;
    formData.append('imageBlob', blob);
    formData.append('imageFile', file);
  }
  if (sponsorImageTemp.flag) {
    const { blob, file } = sponsorImageTemp.flag;
    formData.append('flagBlob', blob);
    formData.append('flagFile', file);
  }

  if (error) {
    dispatch(onChangeField('errors', error));
  } else {
    dispatch({ type: 'SPONSOR/CREATE_SPONSOR/REQUESTED' });

    const result = await catchWrapper(createSponsor(formData));

    if (result && result.result) {
      dispatch({ type: 'SPONSOR/CREATE_SPONSOR/SUCCEEDED' });
      result.sponsor.date = result.sponsor.date
        ? moment(result.sponsor.date).format('YYYY-MM-DD')
        : null;
      dispatch(onChangeProfileField('userSponsors', [result.sponsor]));
      gaToSponsorMain(result.sponsor.id);
    } else {
      dispatch({
        type: 'SPONSOR/CREATE_SPONSOR/FAILED',
        payload: result && result.message && result.message[0],
      });
    }
  }
};
