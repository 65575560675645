import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1591 21.0667C12.8837 21.0667 18.3182 12.0624 18.3182 8.83807C18.3182 5.61379 15.5607 3 12.1591 3C8.75752 3 6 5.61379 6 8.83807C6 12.0624 11.4345 21.0667 12.1591 21.0667ZM12.1579 11.242C13.2917 11.242 14.2109 10.3195 14.2109 9.18152C14.2109 8.04354 13.2917 7.12102 12.1579 7.12102C11.024 7.12102 10.1048 8.04354 10.1048 9.18152C10.1048 10.3195 11.024 11.242 12.1579 11.242Z"
      fill="#C0B4AF"
    />
  </svg>
);
