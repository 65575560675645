import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import { useResponsive } from '../../hooks/useResponsive';

import BlogSectionMobile from './mobile';
import BlogSectionDesktop from './desktop';
import { Pages } from '../../routes/pages';

import history from '../../routes/history';
import { useActions, useSelector } from '../../hooks/store';
import { blogActions } from '../../store/blog';

const navigationBlogSettingsMap = (location) => [
  {
    id: '1',
    path: Pages.blog.settings.main.replace(':id', location.state),
    state: location.state,
    label: 'Блог',
  },
  {
    id: '2',
    path: Pages.blog.settings.links.replace(':id', location.state),
    state: location.state,
    label: 'Ссылки',
  },
];
const getMaps = (isOrgPages, isSettingOrgPages, location, isMobile) => {
  if (isSettingOrgPages && isEmpty(isSettingOrgPages.params))
    return isMobile && navigationBlogSettingsMap(location);
  // if (isOrgPages) return isMobile ? navigationOrgMobileMap(location) : navigationOrgMap(location);
};
const BlogSection = () => {
  const { location } = history;
  const { isMobile } = useResponsive();
  const { onSaveBlogProfile, onCreateBlogProfile } = useActions(blogActions);
  const { newBlog, blog, blogSnapshot, blogImageTemp } = useSelector((state) => state.blog);
  const isSettingBlogPages = !!useRouteMatch('/blog-settings/');
  const isBlogPages = useRouteMatch(Pages.blog.base);
  const isBlogs = useRouteMatch(Pages.blog.all);
  const isSettingPages = !!useRouteMatch(Pages.blog.settings.base);
  const isCreatingBlogPage = location.pathname === Pages.blog.createBlog;
  const isSettingLinksPages = !!useRouteMatch(
    Pages.blog.settings.links.replace(':id', location.state),
  );
  const onSubmit = async () => {
    if (isCreatingBlogPage) {
      const payload = {
        profile: newBlog.profile,
        tags: newBlog.tags,
        type: newBlog.tags[0],
      };
      await onCreateBlogProfile(payload);
    } else {
      const newTags = blog?.tags?.map((tag) => {
        if (tag?.id) {
          return tag.id;
        }
        return tag;
      });

      const payload = {
        profile: blog.profile,
        tags: newTags,
        type: Number(blog.type.id),
        id: Number(blog?.id),
      };
      onSaveBlogProfile(payload);
      history.push({
        pathname: Pages.blog.main.replace(':id', blog.id),
        state: blog.id,
      });
    }
  };
  const getIsDisabledMainPage = () => {
    const image = !!blogImageTemp?.blob;
    let isDisabled;
    if (image && isEqual(blog, blogSnapshot)) isDisabled = false;
    if (!image && isEqual(blog, blogSnapshot)) isDisabled = true;
    if (image && !isEqual(blog, blogSnapshot)) isDisabled = false;
    return isDisabled;
  };

  const isDisabled = isSettingBlogPages
    ? getIsDisabledMainPage({ blog, blogSnapshot, blogImageTemp })
    : null;
  return isMobile ? (
    <BlogSectionMobile
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      navigationMap={getMaps(isBlogPages, isSettingBlogPages, location, isMobile)}
      isSettingPages={isSettingPages}
      isBlogPages={isBlogPages}
      isSponsors={isBlogs}
      isSettingLinksPages={isSettingLinksPages}
      isCreatingBlogPage={isCreatingBlogPage}
    />
  ) : (
    <BlogSectionDesktop
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      isSettingPages={isSettingPages}
      isCreatingBlogPage={isCreatingBlogPage}
      isBlogPages={isBlogPages}
      isSettingLinksPages={isSettingLinksPages}
    />
  );
};

export default BlogSection;
