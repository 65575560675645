import React from 'react';

export default () => (
  <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.91593 6.598C3.91593 6.11267 4.02326 5.71133 4.23793 5.394C4.46193 5.07667 4.78393 4.72667 5.20393 4.344C5.51193 4.064 5.73593 3.83067 5.87593 3.644C6.02526 3.448 6.09993 3.22867 6.09993 2.986C6.09993 2.64067 5.95993 2.37 5.67993 2.174C5.40926 1.96867 5.04526 1.866 4.58793 1.866C4.14926 1.866 3.75726 1.95933 3.41193 2.146C3.07593 2.32333 2.79126 2.57533 2.55793 2.902L0.86393 1.908C1.25593 1.31067 1.78326 0.848666 2.44593 0.521999C3.11793 0.195333 3.91126 0.0319998 4.82593 0.0319998C5.89926 0.0319998 6.75793 0.260666 7.40193 0.717999C8.05526 1.17533 8.38193 1.81 8.38193 2.622C8.38193 3.00467 8.3166 3.34067 8.18593 3.63C8.0646 3.91933 7.9106 4.16667 7.72393 4.372C7.5466 4.568 7.31326 4.79667 7.02393 5.058C6.6786 5.366 6.4266 5.62733 6.26793 5.842C6.10926 6.04733 6.02993 6.29933 6.02993 6.598H3.91593ZM4.97993 10.112C4.59726 10.112 4.27526 9.99067 4.01393 9.748C3.76193 9.496 3.63593 9.19267 3.63593 8.838C3.63593 8.48333 3.76193 8.18933 4.01393 7.956C4.26593 7.71333 4.58793 7.592 4.97993 7.592C5.37193 7.592 5.69393 7.71333 5.94593 7.956C6.19793 8.18933 6.32393 8.48333 6.32393 8.838C6.32393 9.19267 6.19326 9.496 5.93193 9.748C5.67993 9.99067 5.3626 10.112 4.97993 10.112Z"
      fill="currentColor"
    />
  </svg>
);
