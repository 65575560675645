import React from 'react';
import uuid from 'react-uuid';

import Icon from '../../components/icon';
import Button from '../../components/button';

import './styles.scss';
import { classNames as cx } from '../../helpers/utility';

const NotificationModal = ({ type, content, onCancel, className }) => {
  const handleClose = () => onCancel();
  const classNamesContent = cx('notification-content', className);

  return (
    <div className="notification-wrapper">
      <div className={classNamesContent}>
        {type && <Icon className="icon--status" type={type} />}
        {Array.isArray(content) ? (
          content.map((i) => <div key={uuid()}>{i}</div>)
        ) : (
          <div>{content}</div>
        )}
      </div>
      <Button onClick={handleClose} variant="icon">
        <Icon type="cross" />
      </Button>
    </div>
  );
};

export default NotificationModal;
