import React from 'react';
import { Link } from 'react-router-dom';
import he from 'he';

import { useTranslation } from 'react-i18next';
import { getLocations } from '../../../../store/selectors';
import { classNames as cx } from '../../../../helpers/utility';
import { sponsorTypeEnum } from '../../../../helpers/enums';
import { Pages } from '../../../../routes/pages';

import SelectionTag from '../selectionTag';
import UserCardActions from '../../../friends/components/userCardActions';

import './styles.scss';

const TeamCard = ({ team, userId, sponsorId, type, isAction, isCamouflage, action, getSchema }) => {
  const commanderId = team?.commander?.id;

  const teamUrl = {
    pathname: Pages.team.main.replace(':id', team?.id),
    state: team?.id,
  };
  const commanderLink = {
    pathname:
      userId === commanderId ? Pages.profile.base : Pages.profile.main.replace(':id', commanderId),
    state: commanderId,
  };
  const personnelLink = {
    pathname: Pages.team.personnel.replace(':id', team?.id),
    state: team?.id,
  };

  const pic = team?.images?.image?.preview;
  const title = he.decode(team?.title);
  const { city } = getLocations(team?.location);
  const { t } = useTranslation();

  const classNames = cx('team-card', {
    'team-card__gold': type === sponsorTypeEnum.gold,
    'team-card__silver': type === sponsorTypeEnum.silver,
    'team-card__bronze': type === sponsorTypeEnum.bronze,
  });

  return (
    <div className={classNames}>
      <div className="team-card__inner">
        <div className="team-card__left">
          <Link to={teamUrl}>
            <div className="team-card__main-image-wrap">
              {pic ? <img src={pic} alt="" /> : null}
            </div>
          </Link>
          {isCamouflage && (
            <div className="team-card__camouflage">
              {team &&
                team.camouflages.map((c) => (
                  <Link key={c.id} to={{ pathname: `/camouflage/${c.id}`, state: c.id }}>
                    <div className="team-card__camouflage__item">
                      <img src={c.image.preview} alt="pic" />
                    </div>
                  </Link>
                ))}
            </div>
          )}
        </div>
        <div className="team-card__right">
          <Link to={teamUrl}>
            <span className="team-card__title">{title}</span>
          </Link>
          <Link to={commanderLink}>
            <span className="team-card__text">{`${t('teams.personnel.commander')}: ${
              team?.commander?.nickname || ''
            }`}</span>
          </Link>

          {/*<span className="team-card__text">{team.block.title || ''}</span>*/}

          <Link to={personnelLink}>
            <span className="team-card__text">{`${t('teams.personnel.members')}:
            ${team.qty_of_users || ''}`}</span>
          </Link>

          <span className="team-card__text">{city}</span>
        </div>
        {team.is_selection ? <SelectionTag /> : null}
        {isAction ? (
          <UserCardActions
            schema={getSchema({ type, action, sponsorId, teamId: team?.id })}
            //id={user?.id}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TeamCard;
