import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isEmpty } from 'lodash';

import { useActions, useSelector } from '../../../../hooks/store';
import { eventsActions } from '../../../../store/events';
import useDebounce from '../../../../hooks/useDebounce';
import { globalCancelFetch } from '../../../../api/v2';

import EventCard from '../../../components/eventCard';
import SkeletonEvents from '../../components/skeleton';

import './styles.scss';

const PastPage = ({ search }) => {
  const [page, setPage] = useState(1);
  const { pagination, loading, eventsList } = useSelector((state) => state.events);
  const { userId } = useSelector((state) => state.auth);
  const { getEventsList, clearEvents } = useActions(eventsActions);

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    clearEvents();
    getEventsList({ url: 'past', page, query: debouncedSearchTerm || null });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    return () => {
      clearEvents();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const fetchPaginated = () => {
    setPage(page + 1);
    getEventsList({ url: 'past', page: page + 1, query: debouncedSearchTerm || null });
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  return (
    <div className="events-section past-page">
      <>
        {loading && !hasMore ? (
          <SkeletonEvents />
        ) : (
          !isEmpty([1]) && (
            <InfiniteScroll
              dataLength={3}
              next={fetchPaginated}
              hasMore={hasMore}
              loader={<></>}
              className="events-section--grid"
            >
              {eventsList &&
                eventsList.map((event) => {
                  return <EventCard key={event.id} event={event} userId={userId} />;
                })}
            </InfiniteScroll>
          )
        )}
      </>
    </div>
  );
};

export default PastPage;
