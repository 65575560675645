import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

import Button from '../../../components/button';
import Icon from '../../../components/icon';
import Avatars from '../avatars';
import AccountHolder from '../../../helpers/accountHolder';

import './styles.scss';

const FriendsBlock = ({
  friends,
  totalFriends,
  totalFriendsNumber,
  qtyMutualFriends,
  userId,
  selfId,
}) => {
  const goToAllUsers = () => history.push(Pages.users.all);

  const friendLink = {
    pathname: !userId ? Pages.users.main : Pages.users.userFriends.replace(':id', userId),
    state: userId,
  };

  const mutualLink = {
    pathname: Pages.users.common.replace(':id', userId),
    state: userId,
  };

  return !isEmpty(friends) ? (
    <div className="friends-block">
      <div className="friends-block__top">
        {friends?.length ? (
          <span className="friends-block__text">
            {qtyMutualFriends && userId ? (
              <>
                <Link to={friendLink}>
                  <span className="total-friends">{totalFriends}</span>
                </Link>

                <div className="friends-separator" />
                <Link to={mutualLink}>
                  <span>{qtyMutualFriends}</span>
                </Link>
              </>
            ) : (
              <Link to={friendLink}>
                <span>{totalFriends}</span>
              </Link>
            )}
          </span>
        ) : null}

        <AccountHolder>
          <Button onClick={goToAllUsers} className="add-friends" type="icon">
            <Icon type="plus" />
          </Button>
        </AccountHolder>
      </div>
      <Avatars friends={friends} totalFriends={totalFriendsNumber} />
    </div>
  ) : (
    <div className="friends-block__add">
      <span className="friends-block__text">Друзья</span>
      <AccountHolder>
        <Button onClick={goToAllUsers} className="add-friends" variant="icon">
          <Icon type="plus" />
        </Button>
      </AccountHolder>
    </div>
  );
};

export default FriendsBlock;
