import { isEmpty } from 'lodash';

export const validationProfile = ({ user }) => {
  const error = {};
  if (!user?.nickname) {
    error.nickname = 'Поле обязательно для заполнения!';
  }
  return isEmpty(error) ? null : error;
};
export const validationPassport = ({ user }) => {
  const error = {};

  if (!user?.birth_date) {
    error.birth_date = 'Поле обязательно для заполнения!';
  }
  if (!user?.name) {
    error.name = 'Поле обязательно для заполнения!';
  }
  if (!user?.last_name) {
    error.last_name = 'Поле обязательно для заполнения!';
  }
  if (!user?.patronymic) {
    error.patronymic = 'Поле обязательно для заполнения!';
  }

  if (!user?.location?.country?.id) {
    error.country = 'Поле обязательно для заполнения!';
  }
  if (!user?.location?.region?.id) {
    error.region = 'Поле обязательно для заполнения!';
  }
  if (!user?.location?.city?.id) {
    error.city = 'Поле обязательно для заполнения!';
  }

  return isEmpty(error) ? null : error;
};
