import React from 'react';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import './skeleton.scss';
import Icon from '../../../../components/icon';
import SkeletonGrid from '../../../../components/skeletonsGrid';
import ArticleInfoCard from '../articleCard';

const ArticlesList = ({
  isMobile,
  articles,
  fetchPaginated,
  hasMore,
  loading,
  openAddCamouflageModal,
}) => {
  return (
    <>
      {loading && !hasMore ? (
        <SkeletonGrid
        //classNameElement="sk-camouflage--element"
        //classNameGrid="sk-camouflage--grid"
        />
      ) : (
        !isEmpty(articles) && (
          <InfiniteScroll
            dataLength={articles && articles.length}
            next={fetchPaginated}
            hasMore={hasMore}
            loader={<></>}
            className=""
          >
            {/*{isMobile ? null : (*/}
            {/*  <div onClick={openAddCamouflageModal} role="button" className="add-camouflage">*/}
            {/*    <Icon type="plusLarge" />*/}
            {/*    <span className="add-camouflage__text">Добавить новый камуфляж</span>*/}
            {/*  </div>*/}
            {/*)}*/}

            {articles &&
              articles.map((article) => (
                // eslint-disable-next-line react/no-array-index-key
                <ArticleInfoCard key={article.id} article={article} />
              ))}
          </InfiniteScroll>
        )
      )}
    </>
  );
};

export default ArticlesList;
