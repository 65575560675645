import { addRecruit } from '../../../api/v2';
import { catchWrapper } from '../../../helpers/utility';
import modalService from '../../../services/modalService';
import onCheckAuth from '../../auth/impl/checkAuth';

export default (data, isEdit, isCancel) => async (dispatch) => {
  dispatch({ type: 'TEAMS/CREATE_REQUEST_TO_TEAM/REQUESTED' });

  const getUrl = () => {
    if (isEdit) return 'edit';
    if (isCancel) return 'delete';
    return 'add';
  };

  const result = await catchWrapper(addRecruit(getUrl(), data), true);

  if (result && result.result) {
    dispatch({ type: 'TEAMS/CREATE_REQUEST_TO_TEAM/SUCCEEDED' });

    if (!isEdit && !isCancel) {
      modalService.openNotificationsModal({
        type: 'success',
        content: 'Заявка опубликована',
      });
    }

    if (isCancel) {
      modalService.openNotificationsModal({
        type: 'success',
        content: 'Заявка отменена',
      });
    }
    dispatch(onCheckAuth());
  } else {
    dispatch({
      type: 'TEAMS/CREATE_REQUEST_TO_TEAM/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
