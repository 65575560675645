import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from '../../../hooks/store';
import { getLocations } from '../../../store/selectors';

import './styles.scss';
import { Pages } from '../../../routes/pages';
import Skeleton from '../../../components/skeleton';

const Teams = ({ team, isOwner, loading }) => {
  const { userId } = useSelector((state) => state.auth);

  const pic = team?.images?.image?.preview;
  const commanderId = team?.commander?.id;

  const commanderLink = userId === commanderId ? '/profile' : `/profile/${commanderId}`;
  const teamLink = `/team/${team?.id}/main`;

  const { city } = getLocations(team && team.location);

  return (
    <div className="card teams-block">
      <div className="teams-block__photo-wrap">
        {team ? (
          <Link to={{ pathname: teamLink, state: team?.id }}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img className="teams-block__photo" src={pic} alt="image" />
          </Link>
        ) : null}
      </div>
      <div className="teams-block__team-camouflage">
        {team?.camouflages?.map((c) => (
          <Link key={c?.id} to={{ pathname: `/camouflage/${c.id}`, state: c.id }}>
            <div className="teams-block__team-camouflage__item">
              <img src={c?.image?.preview} alt="pic" />
            </div>
          </Link>
        ))}
      </div>
      <div className="teams-block__about">
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {team ? (
              <Link to={{ pathname: teamLink, state: team?.id }}>
                <h3>{team?.title}</h3>
              </Link>
            ) : null}
            <div className="teams-block__data">
              {team ? (
                <>
                  <Link to={{ pathname: commanderLink, state: commanderId }}>
                    <span>{`Командир ${team?.commander?.nickname || ''}`}</span>
                  </Link>
                  {/*<span>{team.block.title || ''}</span>*/}
                  <span>
                    {team.qty_of_users || ''}
                    {team.qty_of_groups ? ', ' : ''}
                    {team.qty_of_groups || ''}
                  </span>
                  <span>{city}</span>
                </>
              ) : null}

              {!team && isOwner ? (
                <>
                  <span>Здесь будут отображаться данные вашей команды.</span>
                  <span>Вы пока не состоите в команде.</span>
                  <div className="teams-block__links-wrap">
                    <Link to={Pages.teams.all}>Вступите</Link>
                    <span>в нее или</span>
                    <Link to={Pages.team.create.main}>создайте свою.</Link>
                  </div>
                </>
              ) : null}

              {!team && !isOwner ? <span>Пользователь не состоит в команде.</span> : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Teams);
