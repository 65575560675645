import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

import HeaderLeft from '../../../components/headerConstructor/components/left';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderConstructor from '../../../components/headerConstructor';
import { PrivateRoute } from '../../../routes/privateRoute';
import GroundsList from '../components/groundsList';
import GroundsSettings from '../components/groundsSettings';
import GroundsMainPage from '../components/groundsMainPage';
import { useActions, useSelector } from '../../../hooks/store';
import { groundsActions } from '../../../store/grounds';
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import Trans from '../../../components/trans';

const GroundsSectionMobile = ({ search, onChangeSearch }) => {
  const { location } = history;
  const isGroundPage = useRouteMatch(Pages.grounds.main.replace(':id', location.state));
  const { ground, imageLoading, imageActionId } = useSelector((state) => state.grounds);
  const { saveGround } = useActions(groundsActions);

  const pageTitle = isGroundPage ? ground?.profile?.title : Trans('fields.pageTitle');
  const isSettingPage = !!useRouteMatch(Pages.grounds.settings);
  const isCreatePage = !!useRouteMatch(Pages.grounds.create);

  const handleGoToMainPage = () =>
    history.push({
      pathname: isGroundPage
        ? Pages.grounds.main.replace(':id', location.state)
        : Pages.grounds.base,
      state: location.state,
    });

  const onSubmit = () => {
    saveGround();
  };

  const isDisabled = () => {
    if (
      imageLoading ||
      imageActionId ||
      !ground?.images?.length ||
      !ground?.profile?.title ||
      !ground?.phone ||
      !ground?.email ||
      !ground?.gps ||
      !ground?.location?.country?.id ||
      !ground?.location?.region?.id ||
      !ground?.location?.city?.id
    )
      return true;
    return false;
  };
  return (
    <>
      <HeaderConstructor className="grounds mobile">
        <HeaderLeft>
          {isGroundPage ? <GoBackButton /> : <SidebarButton />}
          {isGroundPage ? <HeaderTitle title={pageTitle} /> : null}
        </HeaderLeft>
        <HeaderRight>
          {!isSettingPage && !isCreatePage ? (
            <>
              <Controls />
              <Menu />
            </>
          ) : null}
          {isSettingPage || isCreatePage ? (
            <>
              {/*<ThemeSwitch />*/}
              <Button disabled={isDisabled()} onClick={onSubmit} variant="save">
                {isCreatePage ? Trans('fields.buttonCreate') : Trans('fields.buttonSave')}
              </Button>
            </>
          ) : null}
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.grounds.base}
            component={GroundsList}
            extraProps={{ search, onChangeSearch }}
          />
          <PrivateRoute exact path={Pages.grounds.create} component={GroundsSettings} />
          <PrivateRoute exact path={Pages.grounds.main} component={GroundsMainPage} />
          <PrivateRoute exact path={Pages.grounds.settings} component={GroundsSettings} />
        </Switch>
      </div>
    </>
  );
};

export default GroundsSectionMobile;
