import React, { useEffect, useState } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { authActions } from '../../../../store/auth';

import AuthHeader from '../component/header';
import AuthFooter from '../component/footer';
import LeftBlock from '../component/left';
import RightBlock from '../component/right';

import './styles.scss';

const LoginMobile = () => {
  const [step, setStep] = useState('login');
  const actions = useActions(authActions);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    actions.getProviders();
    actions.getEntityCounts();
  }, []);

  return (
    <>
      <AuthHeader />
      <div className="auth-fields">
        {step === 'login' ? (
          <LeftBlock actions={actions} auth={auth} />
        ) : (
          <RightBlock actions={actions} auth={auth} />
        )}
      </div>
      <AuthFooter changeStep={setStep} step={step} />
    </>
  );
};

export default LoginMobile;
