import React from 'react';

import Button from '../../components/button';

import './styles.scss';

const ReadAboutModal = ({ content, onCancel }) => {
  return (
    <div className="modal-wrapper">
      <div className="modal-content">{content}</div>
      <div className="modal-footer">
        <Button onClick={onCancel} variant="main">
          Закрыть
        </Button>
      </div>
    </div>
  );
};

export default ReadAboutModal;
