import React from 'react';

import CustomInput from '../../../../components/input';
import DynamicLabelRow from '../../../components/dynamicLabelRow';

const TransportInfo = ({ user, onChange }) => {
  return (
    <div className="transport-info card">
      <h3 className="settings-passport__right__title">Данные о транспорте</h3>
      <CustomInput
        label="Марка"
        value={user.vehicle_brand}
        name="vehicle_brand"
        placeholder="Марка авто"
        onChange={onChange}
      />
      <CustomInput
        label="Номер"
        value={user.vehicle_number}
        name="vehicle_number"
        placeholder="Номер авто"
        onChange={onChange}
      />
    </div>
  );
};
export default TransportInfo;
