import moment from 'moment';

import { catchWrapper } from '../../../helpers/utility';
import { postTeams } from '../../../api';
import { goToNotFound } from '../../../routes/actions';

export default (id) => async (dispatch) => {
  dispatch({ type: 'TEAMS/LOAD_TEAMS/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getTeam');
  formData.append('id', id);

  const result = await catchWrapper(postTeams('team', formData));

  if (result && result.result) {
    result.team.date = result.team.date ? moment(result.team.date).format('YYYY-MM-DD') : null;

    dispatch({ type: 'TEAMS/LOAD_TEAMS/SUCCEEDED', payload: result.team });
  } else {
    goToNotFound();
    dispatch({
      type: 'TEAMS/LOAD_TEAMS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
