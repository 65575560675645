import React from 'react';

export default () => (
  <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 6C19.3431 6 18 4.65686 18 3C18 1.34314 19.3432 -2.03557e-07 21 -1.31134e-07C22.6568 -5.8711e-08 24 1.34314 24 3C24 4.65686 22.6568 6 21 6ZM12 6C10.3431 6 9 4.65686 9 3C9 1.34314 10.3431 -5.96962e-07 12 -5.24537e-07C13.6569 -4.52111e-07 15 1.34314 15 3C15 4.65686 13.6569 6 12 6ZM-1.31134e-07 3C-2.03558e-07 4.65686 1.3431 6 3 6C4.6569 6 6 4.65686 6 3C6 1.34314 4.6569 -8.45514e-07 3 -9.17939e-07C1.3431 -9.90365e-07 -5.87105e-08 1.34314 -1.31134e-07 3Z"
      fill="currentColor"
    />
  </svg>
);
