import { catchWrapper } from '../../../helpers/utility';
import { getEventParticipants, getEventParticipantsBySide } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ id, page, sideId }) => async (dispatch) => {
  dispatch({ type: 'EVENTS/GET_PARTICIPANTS/REQUESTED' });

  const payloadRequest = {
    id,
    page,
    objects_per_page: 12,
    side_id: sideId,
  };

  const result = await catchWrapper(
    sideId ? getEventParticipantsBySide(payloadRequest) : getEventParticipants(payloadRequest),
  );

  if (result && result.result) {
    const payload = {
      teams: result?.teams,
      single: result?.single,
    };

    dispatch({ type: 'EVENTS/GET_PARTICIPANTS/SUCCEEDED', payload });
  } else {
    goToNotFound();
    dispatch({
      type: 'EVENTS/GET_PARTICIPANTS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
