import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.506 15.0666C19.506 16.8193 18.5951 18.3628 17.2093 19.2731C17.9581 19.5313 18.665 19.8954 19.2853 20.3709C20.6934 21.4511 21.6266 23.0788 21.6266 25.1997C21.6266 25.3288 21.5959 25.4497 21.54 25.5571C21.4227 25.784 21.202 25.9511 20.9393 26H7.82144C7.36881 26 7 25.6413 7 25.1997C7 23.0788 7.93599 21.4511 9.34417 20.3709C9.96444 19.8954 10.6685 19.5313 11.4201 19.2731C10.0343 18.3628 9.12065 16.8193 9.12065 15.0666C9.12065 12.269 11.4453 10 14.3147 10C17.1814 10 19.506 12.269 19.506 15.0666ZM20.5761 16.985C20.3051 17.7581 19.8832 18.4565 19.3439 19.0449C19.7518 19.2731 20.1402 19.538 20.5034 19.8424C22.0485 21.1345 23.0712 23.0842 23.0712 25.6223C23.0712 25.7568 23.046 25.8845 22.9985 26H28.3714C28.729 25.9347 29 25.6332 29 25.269C29 23.3329 28.1478 21.8465 26.8626 20.86C26.2954 20.4266 25.65 20.0938 24.9683 19.8587C25.7199 19.3641 26.3178 18.6657 26.6782 17.8451C26.9269 17.2854 27.0638 16.6671 27.0638 16.0177C27.0638 13.462 24.9431 11.3913 22.3251 11.3913C21.5121 11.3913 20.7493 11.5897 20.0816 11.9416C20.6012 12.8193 20.903 13.8465 20.9113 14.947C20.9169 15.6617 20.7996 16.3478 20.5761 16.985Z"
      fill="currentColor"
    />
  </svg>
);
