import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Pages } from '../../routes/pages';
// eslint-disable-next-line import/no-cycle
import ModalHeader from '../components/modalHeaderBlog';
// eslint-disable-next-line import/no-cycle
import CustomInput from '../../components/input';
// eslint-disable-next-line import/no-cycle
import CamouflageSelect from '../../pages/components/camouflageSelect';
// eslint-disable-next-line import/no-cycle
import Button from '../../components/button';

import './styles.scss';

const CreateRequestTeamModal = ({ user, action, refreshPage, userRecruiting, onCancel }) => {
  const [camouflages, setCamouflages] = useState(userRecruiting?.camouflages || []);
  const [about, setAbout] = useState(userRecruiting?.text || '');

  const handleAddCamouflage = (value) => setCamouflages(value);
  const handleRemoveCamouflage = (id) => {
    const temp = camouflages.filter((c) => c.id !== id);
    setCamouflages(temp);
  };

  const handleChangeAbout = (e) => {
    setAbout(e?.target?.value);
  };

  const onSubmit = async () => {
    await action(
      {
        text: about,
        location: user?.location,
        camouflages,
        user,
      },
      !!userRecruiting,
    );
    await refreshPage();
    await onCancel();
  };

  const onCancelRequest = async () => {
    await action(userRecruiting, false, true);
    await refreshPage();
    await onCancel();
  };

  const errorText = 'Необходимо заполнить';

  const isShowError = React.useMemo(() => {
    return !user?.location?.city?.title || !user?.birth_date;
  }, [user?.location?.city?.title, user?.birth_date]);

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title="Заявка на вступление в команду" />
      <div className="modal-content">
        <div className="content__user-name">
          <span className="content__label">Позывной</span>
          <span className="content__value">{user?.nickname}</span>
        </div>
        <CustomInput
          className="city"
          label="Город"
          placeholder="Город"
          labelPosition="top"
          value={user?.location?.city?.title}
          error={isShowError ? errorText : null}
          required
          disabled
        />
        <CustomInput
          className="bd"
          label="Дата рождения"
          placeholder="Дата рождения"
          labelPosition="top"
          value={user?.birth_date}
          error={isShowError ? errorText : null}
          required
          disabled
        />

        {isShowError ? (
          <Link className="link-setting" to={Pages.settings.profile} onClick={onCancel}>
            Перейти на страницу настроек
          </Link>
        ) : null}
        <CamouflageSelect
          selectedCamouflages={camouflages}
          onChange={handleAddCamouflage}
          onRemoveCamouflage={handleRemoveCamouflage}
        />
        <CustomInput
          onChange={handleChangeAbout}
          value={about}
          textRows={6}
          type="textarea"
          className="about"
          label="О себе"
          labelPosition="top"
          name="about"
          placeholder="Расскажите немного о себе"
        />
      </div>
      {userRecruiting ? (
        <Button onClick={onCancelRequest} variant="text" className="cancel-request">
          Отменить заявку
        </Button>
      ) : null}
      <div className="modal-footer">
        <Button disabled={isShowError} onClick={onSubmit} variant="main">
          {userRecruiting ? 'Сохранить заявку' : 'Опубликовать заявку'}
        </Button>
      </div>
    </div>
  );
};

export default CreateRequestTeamModal;
