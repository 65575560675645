import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { postAuth, postSecurity } from '../../../api';
import { localStorageService } from '../../../services/localStorageService';
import { goToEvents } from '../../../routes/actions';

export default (data) => async (dispatch) => {
  dispatch({ type: 'AUTH/LOGIN/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'auth');

  Object.keys(data).forEach((i) => {
    formData.append(i, data[i]);
  });

  const result = await catchWrapper(postAuth(formData));

  if (result && result.auth && result.result) {
    const authInfo = {
      token: result.user_salt,
      userId: result.current_user.id,
    };

    localStorageService.setAuthData(authInfo);

    const authData = {
      userSponsors: result?.user_sponsors,
      userRecruiting: result?.user_recruiting,
      userBlog: result?.user_blog,
      currentEvent: result?.current_event,
    };

    batch(() => {
      dispatch({ type: 'AUTH/LOGIN/SUCCEEDED', payload: authInfo });
      dispatch({ type: 'PROFILE/SET_USER', payload: result.current_user });
      dispatch({ type: 'PROFILE/SET_AUTH_DATA', payload: authData });

      dispatch({
        type: 'THEME/TOGGLE',
        payload: {
          theme: result?.theme ?? 0,
          eventView: result?.event_view ?? 1,
        },
      });
    });
    goToEvents();

    if (!result.current_user.is_email_confirmed) {
      const formDataCode = new FormData();
      formData.append('action', 'submitEmailCode');

      await catchWrapper(postSecurity(formDataCode));
    }
  } else {
    dispatch({
      type: 'AUTH/LOGIN/FAILED',
      payload: { message: result.message && result.message[0], show_captcha: result.show_captcha },
    });
  }
};
