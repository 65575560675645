import React from 'react';

export default () => (
  <svg
    width="242"
    height="242"
    viewBox="0 0 242 242"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="170.88"
      height="170.88"
      rx="24"
      transform="matrix(0.707327 0.706886 -0.707327 0.706886 121 0)"
      fill="url(#paint0_linear)"
    />
    <path
      d="M109.76 171.254C108.922 171.75 108.116 172.309 107.351 172.932L107.35 172.933C103.213 176.305 100.5 181.387 100.5 187.848C100.5 190.334 102.473 192.499 105.077 192.499H138.261H138.505L138.745 192.452C140.222 192.16 141.407 191.176 142.027 189.906L142.028 189.904C142.334 189.277 142.5 188.579 142.5 187.848C142.5 181.389 139.796 176.307 135.657 172.933L135.656 172.932C134.891 172.309 134.084 171.75 133.246 171.255C135.673 168.409 137.135 164.682 137.135 160.616C137.135 151.803 130.22 144.499 121.504 144.499C112.779 144.499 105.865 151.802 105.865 160.616C105.865 164.683 107.332 168.41 109.76 171.254Z"
      fill="white"
      stroke="#EC9431"
      strokeWidth="5"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="108.338"
        y1="-13.3287"
        x2="197.002"
        y2="100.544"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6E6E6" />
        <stop offset="0.676883" stopColor="#E6E6E6" />
        <stop offset="0.676983" stopColor="#E67C00" stopOpacity="0" />
        <stop offset="0.677083" stopColor="#EB9330" />
        <stop offset="1" stopColor="#EB9330" />
      </linearGradient>
    </defs>
  </svg>
);
