import React from 'react';

export default () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2913 7.62563C10.2913 6.35946 9.26537 5.3335 8.00014 5.3335C6.73397 5.3335 5.70801 6.35946 5.70801 7.62563C5.70801 8.89086 6.73397 9.91683 8.00014 9.91683C9.26537 9.91683 10.2913 8.89086 10.2913 7.62563Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99955 17.25C6.90095 17.25 1.125 12.5735 1.125 7.68302C1.125 3.85442 4.20234 0.75 7.99955 0.75C11.7968 0.75 14.875 3.85442 14.875 7.68302C14.875 12.5735 9.09816 17.25 7.99955 17.25Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
