import { isEmpty } from 'lodash';

export const validationCreateSponsor = ({ sponsor, sponsorImageTemp }) => {
  const error = {};

  if (!sponsor?.profile?.title) {
    error.title = 'Поле обязательно для заполнения!';
  }
  if (!sponsor?.date) {
    error.date = 'Поле обязательно для заполнения!';
  }
  if (!sponsor?.location?.country?.id) {
    error.country = 'Поле обязательно для заполнения!';
  }
  if (!sponsor?.location?.region?.id) {
    error.region = 'Поле обязательно для заполнения!';
  }
  if (!sponsor?.location?.city?.id) {
    error.city = 'Поле обязательно для заполнения!';
  }
  if (!sponsorImageTemp?.image) {
    error.image = 'Необходимо загрузить изображение!';
  }
  if (!sponsorImageTemp?.flag) {
    error.flag = 'Необходимо загрузить изображение!';
  }

  return isEmpty(error) ? null : error;
};
