import React from 'react';

import { classNames as cx } from '../../../helpers/utility';

import './styles.scss';

const DynamicLabelRow = ({ children, label, page }) => {
  const classNames = cx('dynamic-label-row', {
    [`dynamic-label-row--${page}`]: page,
  });

  return (
    <div className={classNames}>
      <span className="dynamic-label-row__text">{label}</span>
      <div className="dynamic-label-row__item">{children}</div>
    </div>
  );
};

export default DynamicLabelRow;
