import { CometChat } from '@cometchat-pro/chat';
import * as enums from '../enums';
//import * as enums from '../enums';

export class MessageListManager {
  // item = {};
  // type = "";
  // parentMessageId = null;
  // messageRequest = null;
  // limit = 30;

  // groupListenerId = "group_" + new Date().getTime();
  // callListenerId = "call_" + new Date().getTime();

  constructor(item, type, parentMessageId) {
    this.msgListenerId = `message_${new Date().getTime()}`;
    this.item = item || {};
    this.type = type || '';
    this.parentMessageId = parentMessageId || null;
    //this.context = context;
    this.limit = 30;
    this.messageRequest = null;
  }

  initializeMessageRequest() {
    const initBuilder = new Promise((resolve) => {
      //const categories = {};
      //const types = CometChat.MESSAGE_TYPE.TEXT;

      if (this.type === CometChat.ACTION_TYPE.TYPE_USER) {
        if (this.parentMessageId) {
          this.messageRequest = new CometChat.MessagesRequestBuilder()
            .setUID(this.item.uid)
            //.setParentMessageId(this.parentMessageId)
            //.setCategories(categories)
            //.setTypes(types)
            .setLimit(this.limit)
            .build();
        } else {
          this.messageRequest = new CometChat.MessagesRequestBuilder()
            .setUID(this.item.uid)
            //.setCategories(categories)
            //.setTypes(types)
            //.hideReplies(true)
            .setLimit(this.limit)
            .build();
        }
        resolve(this.messageRequest);
      }

      // const messageFilterManager = new MessageFilter(this.context);
      //
      // messageFilterManager
      //   .getCategories()
      //   .then((categoryList) => (categories = Object.keys(categoryList)))
      //   .then(() => messageFilterManager.getTypes())
      //   .then((typeList) => (types = Object.keys(typeList)))
      //   .then(() => this.context.FeatureRestriction.isHideDeletedMessagesEnabled())
      //   .then((hideDeletedMessages) => {
      //     if (this.type === CometChat.ACTION_TYPE.TYPE_USER) {
      //       if (this.parentMessageId) {
      //         this.messageRequest = new CometChat.MessagesRequestBuilder()
      //           .setUID(this.item.uid)
      //           .setParentMessageId(this.parentMessageId)
      //           .setCategories(categories)
      //           .setTypes(types)
      //           .hideDeletedMessages(hideDeletedMessages)
      //           .setLimit(this.limit)
      //           .build();
      //       } else {
      //         this.messageRequest = new CometChat.MessagesRequestBuilder()
      //           .setUID(this.item.uid)
      //           .setCategories(categories)
      //           .setTypes(types)
      //           .hideReplies(true)
      //           .hideDeletedMessages(hideDeletedMessages)
      //           .setLimit(this.limit)
      //           .build();
      //       }
      //       resolve(this.messageRequest);
      //     } else if (this.type === CometChat.ACTION_TYPE.TYPE_GROUP) {
      //       if (this.parentMessageId) {
      //         this.messageRequest = new CometChat.MessagesRequestBuilder()
      //           .setGUID(this.item.guid)
      //           .setParentMessageId(this.parentMessageId)
      //           .setCategories(categories)
      //           .setTypes(types)
      //           .hideDeletedMessages(hideDeletedMessages)
      //           .setLimit(this.limit)
      //           .build();
      //       } else {
      //         this.messageRequest = new CometChat.MessagesRequestBuilder()
      //           .setGUID(this.item.guid)
      //           .setCategories(categories)
      //           .setTypes(types)
      //           .hideReplies(true)
      //           .hideDeletedMessages(hideDeletedMessages)
      //           .setLimit(this.limit)
      //           .build();
      //       }
      //       resolve(this.messageRequest);
      //     }
      //   });
    });
    return Promise.resolve(initBuilder);
  }

  fetchPreviousMessages() {
    return this.messageRequest.fetchPrevious();
  }

  attachListeners(callback) {
    CometChat.addMessageListener(
      this.msgListenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          callback(enums.TEXT_MESSAGE_RECEIVED, textMessage);
        },
        // onMediaMessageReceived: (mediaMessage) => {
        //   callback(enums.MEDIA_MESSAGE_RECEIVED, mediaMessage);
        // },
        // onCustomMessageReceived: (customMessage) => {
        //   callback(enums.CUSTOM_MESSAGE_RECEIVED, customMessage);
        // },
        // onMessagesDelivered: (messageReceipt) => {
        //   callback(enums.MESSAGE_DELIVERED, messageReceipt);
        // },
        onMessagesRead: (messageReceipt) => {
          callback(enums.MESSAGE_READ, messageReceipt);
        },
        onMessageDeleted: (deletedMessage) => {
          callback(enums.MESSAGE_DELETED, deletedMessage);
        },
        onMessageEdited: (editedMessage) => {
          callback(enums.MESSAGE_EDITED, editedMessage);
        },
        // onTransientMessageReceived: (transientMessage) => {
        //   callback(enums.TRANSIENT_MESSAGE_RECEIVED, transientMessage);
        // },
      }),
    );
  }

  removeListeners() {
    CometChat.removeMessageListener(this.msgListenerId);
    // CometChat.removeGroupListener(this.groupListenerId);
    // CometChat.removeCallListener(this.callListenerId);
  }
}
