import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02942 21 3 16.9706 3 12C3 7.02943 7.02942 3 12 3C16.9706 3 21 7.02943 21 12ZM12.5022 7.95683C12.8246 7.63441 13.3473 7.63441 13.6697 7.95683L17.0782 11.3654C17.3994 11.6865 17.4008 12.2063 17.0823 12.529C17.0736 12.5392 17.0647 12.5492 17.0554 12.5591C17.0488 12.5662 17.042 12.5732 17.0352 12.5801L13.4453 16.1699C13.1185 16.4968 12.5885 16.4968 12.2617 16.1699C11.9349 15.8431 11.9349 15.3132 12.2617 14.9863L15.3129 11.9351L12.5022 9.1244C12.1797 8.80199 12.1797 8.27925 12.5022 7.95683ZM7.90063 7.95683C8.22302 7.63441 8.74573 7.63441 9.06812 7.95683L12.4778 11.3665C12.8003 11.6889 12.8003 12.2117 12.4778 12.5341C12.4742 12.5376 12.4707 12.541 12.4672 12.5445C12.4563 12.5566 12.4452 12.5684 12.4336 12.5801L8.8457 16.168C8.51892 16.4948 7.98889 16.4948 7.66211 16.168C7.33533 15.8411 7.33533 15.3112 7.66211 14.9844L10.7113 11.9351L7.90063 9.1244C7.57812 8.80199 7.57812 8.27925 7.90063 7.95683Z"
      fill="currentColor"
    />
  </svg>
);
