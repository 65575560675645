import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CometChat } from '@cometchat-pro/chat';
import { useResponsive } from '../../../hooks/useResponsive';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';

import MessagesHeader from '../components/header';
import MessagesFooter from '../components/footer';
import MessagesLayout from '../components/layout';
import MessagesContainer from '../components/msg-container';
import { useActions, useSelector } from '../../../hooks/store';
import { msgActions } from '../../../store/msg';
import ChatPanel from '../../../components/chat';
import { classNames as cx } from '../../../helpers/utility';

function ChatDesktop() {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useResponsive();
  const { user: userMsg } = useSelector((state) => state.msg);
  const { user } = useSelector((state) => state.profile);
  const { loginUserMsg } = useActions(msgActions);

  useEffect(() => {
    if (user?.chat?.uid) {
      if (CometChat.isInitialized()) {
        loginUserMsg();
      }
    }
  }, [user?.chat?.uid, loginUserMsg]);

  const hasPanel = useMemo(
    () => !isDesktop && !isMobile && user.user_type === 'developer' && userMsg?.uid,
    [isDesktop, isMobile, user.user_type, userMsg?.uid],
  );

  const classNames = cx('page-container', {
    'page-container--msg': hasPanel,
  });

  return (
    <>
      <HeaderConstructor className="chat">
        <HeaderLeft title={t('pages.msg.title')} />
        <HeaderRight>
          <Controls isShowLang />
          <Menu />
        </HeaderRight>
      </HeaderConstructor>
      <div className={classNames}>
        <MessagesLayout>
          <MessagesHeader />
          <MessagesContainer />
        </MessagesLayout>
        {hasPanel ? <ChatPanel platform="tablet" /> : null}
      </div>
    </>
  );
}

export default memo(ChatDesktop);
