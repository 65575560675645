import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from '../../routes/history';

import { useResponsive } from '../../hooks/useResponsive';
import { textPageActions } from '../../store/textPage';
import { useActions } from '../../hooks/store';

import TextPageMobile from './components/textPageMobile';
import TextPageDesktop from './components/textPageDesktop';
import Loader from '../../components/loader';

const TextPage = () => {
  const { isMobile } = useResponsive();
  const { location } = history;

  const { loading, title, text, menu } = useSelector((state) => state.textPage);
  const { getTextPage, onTextPageClear } = useActions(textPageActions);

  useEffect(() => {
    getTextPage({
      path: location.pathname,
    });
    return () => {
      onTextPageClear();
    };
  }, [location.pathname]);

  useEffect(() => () => {}, []);

  // eslint-disable-next-line no-nested-ternary
  return loading ? (
    <Loader />
  ) : isMobile ? (
    <TextPageMobile title={title} text={text} menu={menu} />
  ) : (
    <TextPageDesktop title={title} text={text} menu={menu} />
  );
};

export default TextPage;
