import { catchWrapper } from '../../../helpers/utility';
import { saveGround } from '../../../api/v2';
import { goToGroundMain } from '../../../routes/actions';
import { validationGround } from '../validation';
import onChangeError from './onChangeError';

export default () => async (dispatch, getState) => {
  const { ground } = getState().grounds;
  const error = validationGround({ ground });

  if (error) {
    dispatch(onChangeError(error));
  } else {
    dispatch({ type: 'GROUND/SAVE/REQUESTED' });
    const result = await catchWrapper(saveGround(ground));

    if (result && result.result) {
      dispatch({ type: 'GROUND/SAVE/SUCCEEDED' });

      goToGroundMain(result?.ground?.id);
    } else {
      dispatch({
        type: 'GROUND/SAVE/FAILED',
        payload: result && result.message && result.message[0],
      });
    }
  }
};
