import React from 'react';

export default () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.20215 11.2136L7.39666 7.39666L11.2136 6.20215L10.0191 10.0191L6.20215 11.2136Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.70824 15.9165C12.6892 15.9165 15.9165 12.6892 15.9165 8.70824C15.9165 4.72724 12.6892 1.5 8.70824 1.5C4.72724 1.5 1.5 4.72724 1.5 8.70824C1.5 12.6892 4.72724 15.9165 8.70824 15.9165Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
