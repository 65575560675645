import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import he from 'he';
import ModalHeader from '../components/modalHeaderBlog';
import { useResponsive } from '../../hooks/useResponsive';
import EntitiesActions from '../../pages/components/entitiesActions';
import './styles.scss';
import { useActions, useSelector } from '../../hooks/store';
import { socialActions } from '../../store/social';

const BlogVideoModal = ({ video, onCancel, parentId, userPage }) => {
  const { isMobile, isTablet } = useResponsive();
  const {
    getComments,
    writeComments,
    likeRequest,
    bookmark,
    getFullVideoInfo,
    clearFullVideoInfo,
    getUserVideoList,
    clearComments,
  } = useActions(socialActions);

  const { videoInfo, comments } = useSelector((state) => state?.social);
  const [text, setText] = useState('');
  useEffect(() => {
    return () => {
      // getFullVideoInfo({ id: video?.id });
      clearFullVideoInfo();
      if (userPage) {
        getUserVideoList({ page: 1, id: parentId, parentType: 'video' });
      }
    };
  }, [video?.id]);

  const getVideoWidth = () => {
    if (isMobile) {
      return { width: '300px', height: '166px' };
    }
    if (isTablet) {
      return { width: '600px', height: '333px' };
    }
    return { width: '1370px', height: '764px' };
  };
  const sendComment = async () => {
    await writeComments({ text, id: video?.id, contentType: 'video' });
    await getFullVideoInfo({ id: video?.id });
    // await clearComments();
    await setText('');
    await getComments({ id: video?.id, contentType: 'video' });
  };
  const handleLike = async () => {
    await likeRequest({ id: video?.id, type: 'video' });
    await getFullVideoInfo({ id: video?.id });
  };
  const handleBookmark = async () => {
    await bookmark({ id: video?.id, type: 'video' });
    await getFullVideoInfo({ id: video?.id });
  };
  const handleChangeText = (e) => {
    const { value } = e.target;
    setText(value);
  };
  const sizes = getVideoWidth();
  return (
    <div className="modal-wrapper">
      <ModalHeader title={he.decode(video?.profile?.title)} onCancel={onCancel} close={onCancel} />
      <div className="modal-content">
        <ReactPlayer
          url={video?.link}
          controls
          width={sizes?.width}
          height={sizes?.height}
          style={{ marginBottom: '5px' }}
        />
        <EntitiesActions
          sendComment={sendComment}
          text={text}
          handleChangeText={handleChangeText}
          handleLike={handleLike}
          handleBookmark={handleBookmark}
          id={video?.id}
          videoInfo={videoInfo}
          getComments={getComments}
          getFullInfo={getFullVideoInfo}
          comments={comments}
          clearComments={clearComments}
          contentType="video"
          clearFullInfo={clearFullVideoInfo}
        />
      </div>
    </div>
  );
};

export default BlogVideoModal;
