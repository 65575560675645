import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { localStorageService } from '../services/localStorageService';

export const PrivateRoute = ({
  component: Component,
  withAccess,
  hasAccess,
  redirectPath,
  extraProps,
  ...rest
}) => {
  const auth = !!localStorageService.getAuthData();
  return (
    <>
      {withAccess ? (
        <Route
          {...rest}
          render={(props) =>
            auth && hasAccess ? (
              <Component {...props} {...extraProps} />
            ) : (
              <Redirect to={redirectPath || '/auth'} />
            )
          }
        />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            auth ? <Component {...props} {...extraProps} /> : <Redirect to="/auth" />
          }
        />
      )}
    </>
  );
};
