import { catchWrapper } from '../../../helpers/utility';
import { postSecurity } from '../../../api';

export default (phone) => async (dispatch) => {
  dispatch({ type: 'SETTINGS/SUBMIT_PHONE/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'submitPhoneCode');
  formData.append('phone', phone);
  const result = await catchWrapper(postSecurity(formData));
  if (result && result.result) {
    dispatch({ type: 'SETTINGS/SUBMIT_PHONE/SUCCESSFUL' });
  } else {
    dispatch({ type: 'SETTINGS/SUBMIT_PHONE/FAILED', payload: result.message[0] });
  }
};
