import React, { useState } from 'react';

import { Pages } from '../../routes/pages';
import history from '../../routes/history';
import { useResponsive } from '../../hooks/useResponsive';

import FriendsPageDesktop from './desktop';
import FriendsPageMobile from './mobile';
import Trans from '../../components/trans';

export const navigationFriendsMap = () => [
  {
    id: '1',
    label: Trans('friends.menu.players'),
    path: Pages.users.all,
  },
  {
    id: '2',
    label: Trans('friends.menu.myFriends'),
    path: Pages.users.main,
  },
  {
    id: '3',
    label: Trans('friends.menu.incoming'),
    path: Pages.users.requests,
  },
  {
    id: '4',
    label: Trans('friends.menu.outgoing'),
    path: Pages.users.outRequests,
  },
];

export const navigationUsersMap = (location) => [
  {
    id: '1',
    label: Trans('friends.menu.commonFriends'),
    path: Pages.users.common.replace(':id', location.state),
    state: location.state,
  },
  {
    id: '2',
    label: Trans('friends.menu.friends'),
    path: Pages.users.userFriends.replace(':id', location.state),
    state: location.state,
  },
];

export const initGeo = {
  country: {},
  regionArr: [],
  selectedRegions: [],
  selectedCities: [],
  init: false,
};
const initAge = {
  min: 18,
  max: 100,
};
const initGender = 'any';
const rangeName = Trans('friends.filter.age');

const FriendsPage = () => {
  const [isFilterOpened, toggleFilter] = useState(false);
  const [geoLocal, setGeoLocal] = useState(initGeo);
  const [gender, setGender] = useState(initGender);
  const [age, setAge] = useState(initAge);
  const [search, setSearch] = useState('');

  const handleChangeSearch = (v) => setSearch(v);

  const { location } = history;

  const handleVisibleFilter = (value) => {
    toggleFilter(value);
  };
  const handleChangeGender = (value) => setGender(value);
  const handleChangeAge = (value) => setAge(value);
  const handleChangeCountry = (value) => {
    setGeoLocal({ ...initGeo, country: value, init: true });
  };
  const handleChangeGeo = (value) => {
    const selectedRegions = value
      ? value.filter((item) => item.isRegion).map((item) => item.region_id)
      : [];
    const selectedCities = value ? value.filter((item) => !item.isRegion) : [];
    setGeoLocal({
      ...geoLocal,
      regionArr: value
        ? value.filter((item) => item.isRegion || selectedRegions.indexOf(item.region_id) === -1)
        : value,
      selectedRegions,
      selectedCities,
      init: true,
    });
  };
  const handleClearFilter = () => {
    setGender(initGender);
    setAge(initAge);
    setGeoLocal({
      ...geoLocal,
      country: {},
      regionArr: [],
      selectedRegions: [],
      selectedCities: [],
      init: false,
    });
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setAge({ ...age, [name]: value });
  };

  const params = {
    rangeName,
    isFilterOpened,
    geoLocal,
    gender,
    age,
    handleVisibleFilter,
    handleChangeGender,
    handleChangeAge,
    handleChangeCountry,
    handleChangeGeo,
    handleClearFilter,
    handleChangeInput,
    navigationMap: location.state ? navigationUsersMap(location) : navigationFriendsMap(location),
    search,
    onChangeSearch: handleChangeSearch,
  };

  const { isMobile } = useResponsive();
  return isMobile ? <FriendsPageMobile {...params} /> : <FriendsPageDesktop {...params} />;
};

export default FriendsPage;
