import React, { memo } from 'react';

import Button from '../../../components/button';
import Icon from '../../../components/icon';

import './styles.scss';

const BlockHeader = ({ title, count, isAddButton, addButtonAction }) => (
  <div className="block__header">
    {title && <span className="block__header-title">{title}</span>}
    <span className="block__header-count">{count}</span>
    {isAddButton && (
      <Button onClick={addButtonAction} variant="icon">
        <Icon type="add" />
      </Button>
    )}
  </div>
);

export default memo(BlockHeader);
