import React from 'react';
import { Link } from 'react-router-dom';
import AccountHolder from '../../../helpers/accountHolder';
import { Pages } from '../../../routes/pages';

const RenderProfileName = ({ user, isShowFio, selfId }) => {
  //const name
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {user.id === selfId ? (
        user?.name && user.last_name ? (
          <span className="user-info__left__name">{`${user.name} ${user.last_name}`}</span>
        ) : (
          <AccountHolder>
            <Link className="user-info__user-no-data" to={Pages.settings.passport}>
              Укажите имя
            </Link>
          </AccountHolder>
        )
      ) : null}

      {/* eslint-disable-next-line no-nested-ternary */}
      {user.id !== selfId ? (
        // eslint-disable-next-line no-nested-ternary
        user?.name && user.last_name ? (
          isShowFio ? (
            <span className="user-info__left__name">{`${user.name} ${user.last_name}`}</span>
          ) : (
            <span className="user-info__left__name">{`${user.name}`}</span>
          )
        ) : (
          <AccountHolder>
            <Link className="user-info__user-no-data" to={Pages.settings.passport}>
              Укажите имя
            </Link>
          </AccountHolder>
        )
      ) : null}
    </>
  );
};

export default RenderProfileName;
