import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { ru, enUS } from 'date-fns/locale';
import DOMPurify from 'dompurify';
import modalService from '../services/modalService';
import Trans from '../components/trans';

export function isEmail(email) {
  const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

export function classNames(...args) {
  let classes = [];
  args.forEach((item) => {
    if (typeof item === 'string' && item) {
      classes.push(item);
    }
    if (typeof item === 'object' && item) {
      if (!Array.isArray(item)) {
        Object.keys(item).forEach((key) => {
          if (item[key]) {
            classes.push(key);
          }
        });
      } else if (Array.isArray(item) && item.length) {
        classes.push(classNames.apply(null, item));
      }
    }
  });
  classes = classes.filter((item, index) => classes.indexOf(item) === index).join(' ');
  return classes;
}

export const getImageFromFile = (file) => {
  const reader = new FileReader();
  return file && window.URL.createObjectURL(file);
};

export const catchWrapper = async (request, showNotification) => {
  try {
    const response = await request;

    if (showNotification) {
      if (!response?.data?.result && !isEmpty(response?.data?.message)) {
        modalService.openNotificationsModal({
          content: response?.data?.message,
        });
      }

      if (!response?.data?.result && isEmpty(response?.data?.message)) {
        modalService.openNotificationsModal({
          type: 'reject',
          content: 'Упс, что-то пошло не так. Попробуйте еще раз.',
        });
      }
    }

    return response?.data;
  } catch (error) {
    return error;
  }
};

const getLocale = (language) => {
  switch (language) {
    case 'ru-RU':
      return ru;
    case 'en-EN':
      return enUS;
    default:
      return enUS;
  }
};

export const getFormattedDateNumber = (date, f = 'MMMM yyyy', language = 'ru') => {
  const timestamp = date?.toString().length === 10 ? date * 1000 : date;
  const r = date && new Date(timestamp);
  return r && format(r, f, { locale: getLocale(language) });
};

export const getFormattedDate = (dateStr, f = 'MMMM yyyy', language = 'ru') => {
  const date = dateStr && new Date(dateStr);
  return date && format(date, f, { locale: getLocale(language) });
};

export const rankENUM = {
  COMMANDER: 'COMMANDER',
  SUB_COMMANDER: 'SUB_COMMANDER',
  PLAT_COMMANDER: 'PLAT_COMMANDER',
  ORGANIZER: 'ORGANIZER',
  SUB_ORGANIZER: 'SUB_ORGANIZER',
};

export const rankToName = {
  COMMANDER: Trans('teams.personnel.commander'),
  SUB_COMMANDER: Trans('teams.personnel.deputy'),
  PLAT_COMMANDER: Trans('teams.personnel.squadLeader'),
  ORGANIZER: Trans('organizers.personnel.leader'),
  SUB_ORGANIZER: Trans('organizers.personnel.deputy'),
};

export const getUrlToNavi = (item) => {
  return item.state
    ? {
        pathname: item.path,
        state: item.state,
        hash: item.hash,
      }
    : {
        pathname: item.path,
        hash: item.hash,
      };
};

export const getUrl = (id, path) => {
  let url;
  if (id) {
    url = {
      pathname: path.replace(':id?', id).replace(':id', id),
      state: id,
    };
  } else {
    url = {
      pathname: path,
    };
  }
  return url;
};

export const getUrlTwoPath = (id, path, secondPath) => {
  let url;
  if (id) {
    url = {
      pathname: path.replace(':id', id),
      state: id,
    };
  } else {
    url = {
      pathname: secondPath,
    };
  }
  return url;
};

export const getDangerouslyHtml = (html) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return { __html: `${cleanHtml}` };
};

export const numberWithCommas = (x) => {
  return Number(x)
    .toFixed(2)
    .toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const validateEmail = (email) => {
  const EMAIL_REGEXP = /^(([^+<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEXP.test(email);
};

export const validateUrl = (url) => {
  const URL_REGEXP = /^(ftp|http|https):\/\/[^ "]+$/;
  return URL_REGEXP.test(url);
};

export const getIdFromUrl = (url) => {
  return url?.replace(/\D+/g, '');
};

export const concatenationDateAndTime = (dateStr, timeStr) => {
  const date = dateStr && formatISO(new Date(dateStr)).slice(0, 10);
  const time = timeStr && formatISO(new Date(timeStr)).slice(11);

  return date && time ? `${date}${time}` : null;
};

export const getArticleTitle = (count) => {
  if (count === 0) {
    return 'Статей';
  }
  if (count === 1) {
    return 'Статья';
  }
  if (count >= 1 && count <= 4) {
    return 'Статьи';
  }
  if (count > 4) {
    return 'Статей';
  }
};

export const getDiffInArr = (arr1, arr2) => {
  // if (arr1?.length > arr2?.length) {
  return arr1.filter((id1) => !arr2.some((id2) => id2 === id1));
  // }
  // return arr2.filter((id1) => !arr1.some((id2) => id2 === id1));
};

export const regexDelImg = /serverid\s*=\s*"?(.+?)["|\s]/g;

export const hasKeyValue = (data, key) => {
  return !(data.hasOwnProperty(key) === false || data[key] === null || data[key] === undefined);
};
