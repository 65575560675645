import { isEmpty } from 'lodash';
import { validateEmail } from '../../helpers/utility';

export const validationSponsorForm = (fields) => {
  const error = {};

  if (!fields?.fio) {
    error.fio = 'Поле обязательно для заполнения!';
  }
  if (!fields?.email) {
    error.email = 'Поле обязательно для заполнения!';
  }

  if (!validateEmail(fields.email)) {
    error.email = 'Пожалуйста, введите корректный E-mail адрес.';
  }

  if (!fields?.description) {
    error.description = 'Поле обязательно для заполнения!';
  }

  return isEmpty(error) ? null : error;
};
