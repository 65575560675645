import React, { useEffect, useState, useRef } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { camouflageActions } from '../../../../store/camouflages';
import { useResponsive } from '../../../../hooks/useResponsive';
import useDebounce from '../../../../hooks/useDebounce';
import { globalCancelFetch } from '../../../../api/v2';

import modalService from '../../../../services/modalService';

import HeaderTitle from '../../../../components/headerConstructor/components/title';
import Icon from '../../../../components/icon';
import Button from '../../../../components/button';
import useVisible from '../../../../hooks/useVisible';
import MobileSearchRow from '../../../components/mobile-controls/searchRow';
import CamouflagesList from '../components/camouflagesList';

import './styles.scss';
import Trans from '../../../../components/trans';

const CamouflagesAllPage = ({ search, onChangeSearch }) => {
  const [page, setPage] = useState(1);
  const containerEl = useRef(null);
  const { getCamouflages, clearCamouflages } = useActions(camouflageActions);
  const { camouflages, pagination, loading } = useSelector((state) => state.camouflages);
  const { isMobile } = useResponsive();
  const { isVisible, setIsVisible } = useVisible(false);

  const debouncedSearchTerm = useDebounce(search, 500);

  const handleShowSearch = () => setIsVisible(true);
  const handleCloseSearch = () => setIsVisible(false);

  useEffect(
    () => () => {
      clearCamouflages();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  useEffect(() => {
    clearCamouflages();
    getCamouflages({ page: 1, query: debouncedSearchTerm || null });
  }, [debouncedSearchTerm]);

  const fetchPaginated = () => {
    setPage(page + 1);
    getCamouflages({ page: page + 1, query: debouncedSearchTerm || null });
  };
  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  useEffect(() => {
    if (
      camouflages.length &&
      hasMore &&
      document.body.offsetHeight > containerEl.current.offsetHeight
    ) {
      fetchPaginated();
    }
  }, [camouflages]);

  const handleOpenAddCamouflageModal = () => {
    modalService.openAddCamouflage({});
  };

  return (
    <div ref={containerEl} className="camouflages">
      {isMobile ? (
        <div className="camouflages--mobile-control">
          <HeaderTitle title={Trans('camouflage.title')} />
          <Button className="search" onClick={handleShowSearch} variant="icon">
            <Icon type="search" />
          </Button>
          <Button onClick={handleOpenAddCamouflageModal} variant="icon">
            <Icon type="add" />
          </Button>
          {isVisible ? (
            <MobileSearchRow
              placeholder={Trans('camouflage.findPlaceholder')}
              onClose={handleCloseSearch}
              value={search}
              onChange={onChangeSearch}
            />
          ) : null}
        </div>
      ) : null}
      <CamouflagesList
        isMobile={isMobile}
        openAddCamouflageModal={handleOpenAddCamouflageModal}
        camouflages={camouflages}
        fetchPaginated={fetchPaginated}
        hasMore={hasMore}
        loading={loading}
      />
    </div>
  );
};

export default CamouflagesAllPage;
