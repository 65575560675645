import { catchWrapper } from '../../../helpers/utility';
import { postSecurity } from '../../../api';
import modalService from '../../../services/modalService';
import checkAuth from './checkAuth';

export default ({ code }) => async (dispatch) => {
  dispatch({ type: 'AUTH/SEND_CODE/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'saveEmail');

  if (code) {
    formData.append('code', code);
  }

  const result = await catchWrapper(postSecurity(formData));

  if (result?.result) {
    dispatch({ type: 'AUTH/SEND_CODE/SUCCEEDED' });
    dispatch({
      type: 'PROFILE/CHANGE_FIELD_USER_AND_SNAPSHOT',
      payload: { field: 'is_email_confirmed', value: true },
    });
    dispatch({
      type: 'PROFILE/CHANGE_FIELD_USER_AND_SNAPSHOT',
      payload: { field: 'need_confirmation', value: false },
    });
    modalService.closeAll();
  } else {
    dispatch({ type: 'AUTH/SEND_CODE/FAILED', payload: result.message[0] });
  }
};
