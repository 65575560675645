import React from 'react';
import { isEmpty } from 'lodash';
import ReactHtmlParser from 'react-html-parser';

import ExpandCollapse from '../../../../../components/expandCollapse';

import './styles.scss';
import Skeleton from '../../../../../components/skeleton';

const CamouflageInfoCard = ({ camouflage, loading }) => (
  <div className="block camouflage-info-card">
    <div className="camouflage-info-card__image-wrap">
      {loading ? <Skeleton /> : null}
      {camouflage?.image?.image ? <img src={camouflage?.image?.image} alt="" /> : null}
    </div>
    {!isEmpty(camouflage) && (
      <div className="camouflage-info-card__name-block">
        <div className="camouflage-info-card__title">{camouflage.title}</div>
        <ExpandCollapse className="camouflage-info-card__about">
          {ReactHtmlParser(camouflage?.text)}
        </ExpandCollapse>
      </div>
    )}
  </div>
);

export default CamouflageInfoCard;
