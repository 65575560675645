import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';

export default () => async (dispatch) => {
  dispatch({ type: 'AUTH/SERVICES/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'getServices');

  const result = await catchWrapper(postSocial('social_get_services', formData));

  if (result) {
    dispatch({
      type: 'AUTH/SERVICES/SUCCEEDED',
      payload: result.services,
    });
  } else {
    dispatch({ type: 'AUTH/SERVICES/FAILED' });
  }
};
