import React from 'react';
import './styles.scss';
import { getDangerouslyHtml } from '../../../../helpers/utility';

const SponsorAboutBlock = ({ sponsor }) => {
  return (
    <div className="sponsor-about-block">
      <div className="card sponsor-about-block__content">
        {sponsor?.about ? (
          <div dangerouslySetInnerHTML={getDangerouslyHtml(sponsor.about)} />
        ) : (
          '...'
        )}
      </div>
    </div>
  );
};

export default SponsorAboutBlock;
