import uuid from 'react-uuid';

import getAllTeams from './impl/getAllTeams';
import getAllTeamsFiltered from './impl/getAllTeamsFiltered';
import getTeam from './impl/getTeam';
import getTeamMembers from './impl/getTeamMembers';
import sendRequestJoinTeam from './impl/sendRequestJoinTeam';
import cancelRequestJoinTeam from './impl/cancelRequestJoinTeam';
import onChangeRole from './impl/onChangeRole';
import onChangeMembers from './impl/onChangeMembers';
import onSaveTeamMembers from './impl/onSaveTeamMembers';
import getTeamIncomingRequests from './impl/getTeamIncomingRequests';
import getAllRecruiting from './impl/getAllRecruiting';

import onCreateTeam from './impl/onCreateTeam';
import onCreateRequestToTeam from './impl/onCreateRequestToTeam';
import onSaveTeamProfile from './impl/onSaveTeamProfile';
import onSaveTeamService from './impl/onSaveTeamService';
import onSaveTeamSettingsCharter from './impl/onSaveTeamSettingsCharter';
import onGetTeamSettingsCharter from './impl/onGetTeamSettingsCharter';
import onGetTeamSettingsEquipment from './impl/onGetTeamSettingsEquipment';
import onSaveTeamSettingsEquipment from './impl/onSaveTeamSettingsEquipment';
import onGetTeamSettingsHistory from './impl/onGetTeamSettingsHistory';
import onSaveTeamSettingsHistory from './impl/onSaveTeamSettingsHistory';
import onGetTeamSettingsDemand from './impl/onGetTeamSettingsDemand';
import onSaveTeamSettingsDemand from './impl/onSaveTeamSettingsDemand';
import changeTeamText from './impl/changeTeamText';
import onUserRequestManagement from './impl/onUserRequestManagement';

import onChangeField from './impl/onChangeField';

export const teamsReducer = (state = null, action) => {
  switch (action.type) {
    case 'TEAMS/LOAD_ALL_TEAMS/REQUESTED':
    case 'TEAMS/LOAD_ALL_RECRUIT/REQUESTED':
    case 'TEAMS/LOAD_TEAMS/REQUESTED':
    case 'TEAMS/GET_TEXT_DEMAND/REQUESTED':
    case 'TEAMS/GET_TEXT_CHARTER/REQUESTED':
    case 'TEAMS/SAVE_TEXT_CHARTER/REQUESTED':
    case 'TEAMS/SAVE_TEXT_DEMAND/REQUESTED':
    case 'TEAMS/GET_TEXT_EQUIPMENT/REQUESTED':
    case 'TEAMS/SAVE_TEXT_EQUIPMENT/REQUESTED':
    case 'TEAMS/GET_TEXT_HISTORY/REQUESTED':
    case 'TEAMS/SAVE_TEXT_HISTORY/REQUESTED':
    case 'TEAMS/LOAD_ALL_TEAMS_FILTERED/REQUESTED':
    case 'TEAMS/LOAD_TEAM_MEMBERS/REQUESTED':
    case 'TEAMS/LOAD_TEAM_REQUESTS/REQUESTED':
      return {
        ...state,
        loading: state.loading + 1,
      };

    case 'TEAMS/USER_REQUEST_MANAGEMENT/REQUESTED':
    case 'TEAMS/CREATE_REQUEST_TO_TEAM/REQUESTED':
    case 'TEAMS/REQUEST_JOIN_TEAM/REQUESTED':
    case 'TEAMS/CANCEL_REQUEST_JOIN_TEAM/REQUESTED':
    case 'TEAMS/SAVE_TEAM_MEMBERS/REQUESTED':
    case 'TEAMS/SAVE_TEAM_PROFILE/REQUESTED':
    case 'TEAMS/SAVE_TEAM_LINK/REQUESTED':
    case 'TEAMS/CREATE_TEAM/REQUESTED':
      return {
        ...state,
        loadingSilent: state.loadingSilent + 1,
      };

    case 'TEAMS/LOAD_ALL_TEAMS/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        teams: [...state.teams, ...action.payload.teams],
        pagination: action.payload.pagination,
      };

    case 'TEAMS/LOAD_TEAMS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading > 0 ? state.loading - 1 : 0,
        team: action.payload,
        teamSnapshot: action.payload,
      };
    }

    case 'TEAMS/LOAD_TEAM_MEMBERS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        teamMembers: action.payload,
        teamMembersSnapshot: action.payload,
      };
    }

    case 'TEAMS/SAVE_TEAM_MEMBERS/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        teamMembersSnapshot: state.teamMembers,
      };
    }

    case 'TEAMS/SAVE_TEAM_PROFILE/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        teamSnapshot: state.team,
        teamImageTemp: {
          image: null,
          photo: null,
          chevron: null,
        },
      };
    }

    case 'TEAMS/LOAD_TEAM_REQUESTS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        teamRequests: action.payload,
      };
    }

    case 'TEAMS/USER_REQUEST_MANAGEMENT/SUCCEEDED': {
      return {
        ...state,
        loading: state.loadingSilent - 1,
        teamRequests: state.teamRequests.filter((item) => item.id !== action.payload),
      };
    }

    case 'TEAMS/CLEAR_TEAM_MEMBERS': {
      return {
        ...state,
        teamMembers: action.payload,
        teamMembersSnapshot: action.payload,
      };
    }

    case 'TEAMS/CHANGE_TEAM_MEMBER': {
      return {
        ...state,
        teamMembers: action.payload,
      };
    }

    case 'TEAMS/CHANGE_USER_ROLE': {
      return {
        ...state,
        teamMembers: action.payload,
      };
    }

    case 'TEAMS/REQUEST_JOIN_TEAM/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        team: {
          ...state.team,
          isUserRequest: true,
        },
      };
    }

    case 'TEAMS/CANCEL_REQUEST_JOIN_TEAM/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        team: {
          ...state.team,
          isUserRequest: false,
        },
      };
    }

    case 'TEAMS/LOAD_ALL_TEAMS_FILTERED/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        teams: [...action.payload.teams],
        pagination: action.payload.pagination,
      };
    }

    case 'TEAMS/LOAD_ALL_RECRUIT/SUCCEEDED': {
      const { items, pagination } = action.payload;

      const newState = {
        ...state,
        loading: state.loading - 1,
        recruits: [...state.recruits, ...items],
        pagination,
      };
      return {
        ...newState,
      };
    }

    case 'TEAM/CLEAR_GENERAL_PAGE': {
      return {
        ...state,
        teams: [],
        recruits: [],
        teamRequests: [],
        pagination: {},
      };
    }

    case 'TEAMS/CLEAR_TEAM': {
      return {
        ...state,
        team: {},
        teamSnapshot: {},
      };
    }

    case 'TEAMS/GET_TEXT_CHARTER/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        teamsSettings: {
          ...state.teamsSettings,
          charter: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'TEAMS/GET_TEXT_EQUIPMENT/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        teamsSettings: {
          ...state.teamsSettings,
          equipment: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'TEAMS/GET_TEXT_HISTORY/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        teamsSettings: {
          ...state.teamsSettings,
          history: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'TEAMS/GET_TEXT_DEMAND/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        teamsSettings: {
          ...state.teamsSettings,
          demands: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'TEAMS/SAVE_TEXT_CHARTER/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case 'TEAMS/CREATE_TEAM/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };
    }

    case 'TEAMS/LOAD_ALL_TEAMS/FAILED':
    case 'TEAMS/LOAD_TEAMS/FAILED':
    case 'TEAMS/GET_TEXT_CHARTER/FAILED':
    case 'TEAMS/GET_TEXT_DEMAND/FAILED':
    case 'TEAMS/SAVE_TEXT_CHARTER/FAILED':
    case 'TEAMS/SAVE_TEXT_DEMAND/FAILED':
    case 'TEAMS/GET_TEXT_EQUIPMENT/FAILED':
    case 'TEAMS/SAVE_TEXT_EQUIPMENT/FAILED':
    case 'TEAMS/GET_TEXT_HISTORY/FAILED':
    case 'TEAMS/SAVE_TEXT_HISTORY/FAILED':
    case 'TEAMS/LOAD_TEAM_MEMBERS/FAILED':
    case 'TEAMS/LOAD_TEAM_REQUESTS/FAILED':
    case 'TEAMS/LOAD_ALL_RECRUIT/FAILED':
      return {
        ...state,
        loading: state.loading - 1,
      };

    case 'TEAMS/USER_REQUEST_MANAGEMENT/FAILED':
    case 'TEAMS/CREATE_REQUEST_TO_TEAM/FAILED':
    case 'TEAMS/CREATE_REQUEST_TO_TEAM/SUCCEEDED':
    case 'TEAMS/REQUEST_JOIN_TEAM/FAILED':
    case 'TEAMS/LOAD_ALL_TEAMS_FILTERED/FAILED':
    case 'TEAMS/CANCEL_REQUEST_JOIN_TEAM/FAILED':
    case 'TEAMS/SAVE_TEAM_MEMBERS/FAILED':
    case 'TEAMS/SAVE_TEAM_PROFILE/FAILED':
    case 'TEAMS/SAVE_TEAM_LINK/SUCCEEDED':
    case 'TEAMS/SAVE_TEAM_LINK/FAILED':
    case 'TEAMS/CREATE_TEAM/FAILED':
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };

    case 'TEAMS/ADD_GROUP': {
      const { title } = action.payload;
      const newItem = {
        id: uuid(),
        title,
        remove: false,
        commander: null,
        users: [],
      };
      const groups = [...state.teamMembers.groups, newItem];

      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          groups,
        },
      };
    }

    case 'TEAMS/CHANGE_TITLE': {
      const { groupIndex, title } = action.payload;

      const groups = [...state.teamMembers.groups];
      const newGroups = groups.map((g, ind) => {
        if (groupIndex === ind) {
          return {
            ...g,
            title,
          };
        }
        return {
          ...g,
        };
      });

      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          groups: newGroups,
        },
      };
    }

    case 'TEAMS/REMOVE_GROUP': {
      const { groupId } = action.payload;
      const newGroups = state.teamMembers.groups.map((g) => {
        if (g.id === groupId) {
          return {
            ...g,
            remove: true,
          };
        }
        return g;
      });

      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          groups: newGroups,
        },
      };
    }

    case 'TEAM/CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    case 'TEAM/TEAM_CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        team: {
          ...state.team,
          [field]: value,
        },
      };
    }

    case 'TEAM/CHANGE_LOCATION': {
      const { field, value } = action.payload;
      return {
        ...state,
        team: {
          ...state.team,
          location: {
            ...state.team.location,
            [field]: value,
          },
        },
      };
    }

    case 'TEAM/CHANGE_PROFILE_FIELD': {
      const { field, value } = action.payload;

      return {
        ...state,
        team: {
          ...state.team,
          profile: {
            ...state.team.profile,
            [field]: value === '' ? null : value,
          },
        },
      };
    }

    case 'TEAM/CHANGE_CAMOUFLAGES': {
      return {
        ...state,
        team: {
          ...state.team,
          camouflages: [...action.payload],
        },
        errors: {
          ...state.errors,
          camouflage: null,
        },
      };
    }

    case 'TEAM/REMOVE_CAMOUFLAGE': {
      return {
        ...state,
        team: {
          ...state.team,
          camouflages: state.team.camouflages.filter((c) => c.id !== action.payload),
        },
      };
    }

    case 'TEAM/CHANGE_ABOUT_FIELD': {
      return {
        ...state,
        team: {
          ...state.team,
          about: action.payload,
        },
      };
    }

    case 'TEAM/CHANGE_TEXT': {
      return {
        ...state,
        teamsSettings: {
          ...state.teamsSettings,
          [action.payload.field]: {
            ...state.teamsSettings[action.payload.field],
            text: action.payload.text,
          },
        },
      };
    }
    default:
      return state;
  }
};

const onChangeCamouflages = (arr) => (dispatch) => {
  dispatch({
    type: 'TEAM/CHANGE_CAMOUFLAGES',
    payload: arr,
  });
};

const onRemoveCamouflage = (id) => (dispatch) => {
  dispatch({
    type: 'TEAM/REMOVE_CAMOUFLAGE',
    payload: id,
  });
};

const onClearTeamMembers = () => (dispatch) => {
  dispatch({
    type: 'TEAMS/CLEAR_TEAM_MEMBERS',
    payload: {
      commander: null,
      deputy: null,
      users: null,
      requests: null,
      groups: null,
    },
  });
};

const onClearTeam = () => (dispatch) => {
  dispatch({
    type: 'TEAMS/CLEAR_TEAM',
  });
};

const onChangeTitle = (groupIndex, title) => (dispatch) => {
  dispatch({
    type: 'TEAMS/CHANGE_TITLE',
    payload: {
      groupIndex,
      title,
    },
  });
};

const onRemoveGroup = (groupId) => (dispatch) => {
  dispatch({
    type: 'TEAMS/REMOVE_GROUP',
    payload: {
      groupId,
    },
  });
};

const onAddGroup = (title) => (dispatch) => {
  dispatch({
    type: 'TEAMS/ADD_GROUP',
    payload: {
      title,
    },
  });
};

const onChangeTeamField = (field, value) => (dispatch) => {
  dispatch({
    type: 'TEAM/TEAM_CHANGE_FIELD',
    payload: { field, value },
  });
};

const onChangeLocation = (field, value) => (dispatch) => {
  dispatch({
    type: 'TEAM/CHANGE_LOCATION',
    payload: { field, value },
  });
};

const onChangeProfile = (field, value) => (dispatch) => {
  dispatch({
    type: 'TEAM/CHANGE_PROFILE_FIELD',
    payload: { field, value },
  });
};

const onChangeAbout = (value) => (dispatch) => {
  dispatch({
    type: 'TEAM/CHANGE_ABOUT_FIELD',
    payload: value,
  });
};

const onClearGeneralPages = () => (dispatch) => {
  dispatch({
    type: 'TEAM/CLEAR_GENERAL_PAGE',
  });
};

export const teamsActions = {
  getAllTeams,
  getTeam,
  getTeamMembers,
  sendRequestJoinTeam,
  cancelRequestJoinTeam,
  getAllTeamsFiltered,
  getAllRecruiting,
  onChangeMembers,
  onChangeTeamField,
  onRemoveCamouflage,
  onSaveTeamMembers,
  onChangeLocation,
  onClearTeamMembers,
  onRemoveGroup,
  onAddGroup,
  onChangeField,
  onChangeTitle,
  onChangeRole,
  onChangeCamouflages,
  getTeamIncomingRequests,
  onSaveTeamService,
  onChangeProfile,
  onSaveTeamProfile,
  onSaveTeamSettingsCharter,
  onGetTeamSettingsCharter,
  onSaveTeamSettingsEquipment,
  onGetTeamSettingsEquipment,
  onGetTeamSettingsHistory,
  onSaveTeamSettingsHistory,
  onSaveTeamSettingsDemand,
  onGetTeamSettingsDemand,
  changeTeamText,
  onChangeAbout,
  onCreateRequestToTeam,
  onClearGeneralPages,
  onClearTeam,
  onUserRequestManagement,
  onCreateTeam,
};
