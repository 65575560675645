import React, { useEffect, useRef, useState } from 'react';

import CustomInput from '../../../../../components/input';
import Button from '../../../../../components/button';
import Spinner from '../../../../../components/spinner';
import Recaptcha from '../../../../components/recaptcha';
import AuthSocialButtons from '../../../../components/authSocialButtons';
import modalService from '../../../../../services/modalService';
import Trans from '../../../../../components/trans';

const LeftBlock = ({ auth, actions }) => {
  const recaptchaRef = useRef(null);
  const btnLoginRef = useRef(null);
  const [state, setState] = useState({ email: '', password: '' });
  const { error, loading, providers } = auth;
  const buttonLeft = Trans('login.buttonLeft');

  useEffect(() => {
    if (btnLoginRef) {
      btnLoginRef.current.focus();
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  const getRecaptcha = (value) => {
    setState({ ...state, recaptcha: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...state,
      'g-recaptcha-response': state.recaptcha,
    };

    actions.onLogin(state.recaptcha ? data : state);
  };

  const handleChangePasswordClick = () => modalService.openChangePasswordModal({});

  return (
    <div className="auth-fields__left">
      <h3 className="auth-fields__title">{Trans('login.signIn')}</h3>
      <div className="auth-controls-container">
        {error.login && <span className="auth-fields__error">{error.login}</span>}
        <form autoComplete="on" className="form-login">
          <CustomInput
            tabIndex="0"
            placeholder="profile.email"
            name="email"
            type="email"
            autoComplete="on"
            value={state.email}
            onChange={handleInputChange}
          />
          <CustomInput
            placeholder="login.password"
            type="password"
            name="password"
            autoComplete="on"
            value={state.password}
            onChange={handleInputChange}
            tabIndex="0"
          />
        </form>
        <Button onClick={handleChangePasswordClick} className="forgot-password" variant="text">
          {Trans('login.passwordForgot')}
        </Button>
        <Recaptcha getRecaptcha={getRecaptcha} recaptchaRef={recaptchaRef} />
        <Button
          refElem={btnLoginRef}
          tabIndex="0"
          onClick={(e) => onSubmit(e)}
          variant="icon"
          secondVariant="auth"
          className="button-login"
          type="submit"
        >
          {loading ? <Spinner size={30} color="#ffffff" /> : buttonLeft}
        </Button>
        <div className="hr" />
        <AuthSocialButtons providers={providers} text={Trans('login.social')} />
      </div>
    </div>
  );
};

export default LeftBlock;
