import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-cycle
import modalService from '../../services/modalService';

import Button from '../../components/button';

import './styles.scss';

const BottomMenuModal = ({
  onCancel,
  type,
  action,
  social,
  service,
  value,
  url,
  unlink,
  onSave,
  remove,
}) => {
  useEffect(() => {
    const target = document.querySelector('.modal-overlay');
    if (target) target.style.alignItems = 'flex-end';
    return () => {
      target.style.alignItems = 'center';
    };
  }, []);

  const handleLink = () => {
    window.open(url);
  };

  const handleUnlink = () => {
    unlink(social);
    onCancel();
  };

  const handleRemoveLink = () => {
    remove(service);
    onCancel();
  };

  const handleOpenChangeLinkModal = () =>
    modalService.openChangeLinkModal({
      service,
      value,
      onSave,
    });

  return (
    <div className="modal-wrapper menu-bottom">
      <div className="bottom-menu">
        {type === 'social' && action === 'link' ? (
          <Button onClick={handleLink} className="bottom-menu__button" variant="text">
            Привязать к аккаунту
          </Button>
        ) : null}

        {/* eslint-disable-next-line no-nested-ternary */}
        {type === 'service' ? (
          value ? (
            <Button onClick={handleRemoveLink} className="bottom-menu__button" variant="text">
              Удалить ссылку
            </Button>
          ) : (
            <Button
              onClick={handleOpenChangeLinkModal}
              className="bottom-menu__button"
              variant="text"
            >
              Добавить ссылку
            </Button>
          )
        ) : null}

        {type === 'social' && action === 'unlink' ? (
          <Button
            onClick={handleUnlink}
            className="bottom-menu__button bottom-menu__remove"
            variant="text"
          >
            Отвязать
          </Button>
        ) : null}
      </div>
      <Button onClick={onCancel} className="bottom-menu__button bottom-menu__close" variant="text">
        Отмена
      </Button>
    </div>
  );
};

export default BottomMenuModal;
