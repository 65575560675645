import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions, useSelector } from '../../../../hooks/store';
import { teamsActions } from '../../../../store/teams';
import { globalCancelFetch } from '../../../../api/v2';

import BlockMain from '../../components/blockMain';
import ChevronAndPhoto from '../../components/chevronAndPhoto';
import MottoBlock from '../../components/motto';
import TeamDataBlock from '../../components/teamData';
import SelectionTag from '../../components/selectionTag';
import TeamAboutBlock from '../../components/teamAboutBlock';

import './styles.scss';
import GalleryComponent from '../../../components/gallery';

const MainTeamPage = () => {
  const { getTeam, onClearTeam, sendRequestJoinTeam, cancelRequestJoinTeam } = useActions(
    teamsActions,
  );
  const { team, loading: teamsLoading, loadingSilent } = useSelector((state) => state.teams);
  const { user, loading: profileLoading } = useSelector((state) => state.profile);
  const { id } = useParams();

  useEffect(() => {
    getTeam(id);
  }, [id]);

  useEffect(() => {
    return () => {
      onClearTeam();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const motto = team?.profile?.motto;

  const userTeam = user?.team;
  const role = user?.team_role;
  const loading = teamsLoading || profileLoading;

  return (
    <div className="team-page--main">
      <BlockMain
        userId={user.id}
        cancelRequestJoinTeam={cancelRequestJoinTeam}
        sendRequestJoinTeam={sendRequestJoinTeam}
        team={team}
        userTeam={userTeam}
        role={role}
        loading={loading}
        loadingSilent={loadingSilent}
      />
      <ChevronAndPhoto team={team} loading={loading} />
      <MottoBlock motto={motto} />
      <TeamAboutBlock team={team} />
      <TeamDataBlock team={team} />
      {team?.is_selection ? <SelectionTag /> : null}
      {/*<Gallery isDevMod />*/}
    </div>
  );
};

export default MainTeamPage;
