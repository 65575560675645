import { catchWrapper } from '../../../helpers/utility';
import { postSecurity } from '../../../api';
import { profileActions } from '../../profile';
import modalService from '../../../services/modalService';

export default (code, type) => async (dispatch) => {
  const typeAction = type === 'phone' ? 'savePhone' : 'saveEmail';
  dispatch({ type: 'SETTINGS/SUBMIT_PHONE_CODE/REQUESTED' });

  const formData = new FormData();
  formData.append('action', typeAction);
  formData.append('code', code);

  const result = await catchWrapper(postSecurity(formData));
  if (result?.result) {
    dispatch({ type: 'SETTINGS/SUBMIT_PHONE_CODE/SUCCESSFUL' });
    dispatch(profileActions.getInitUserData());
    modalService.closeAll();
  } else {
    dispatch({ type: 'SETTINGS/SUBMIT_PHONE_CODE/FAILED', payload: result.message[0] });
  }
};
