import { MSG_TYPES } from './constants';
import { setReceiverInfoAction, setUserList } from './actions/users';
import { setConversationList } from './actions/conversations';
import { loginUserMsg } from './actions/authentication';
import {
  cleanMessagesAction,
  sendMessage,
  setEditModeAction,
  setMessage,
  setMessageHistory,
} from './actions/messages';

const initState = {
  user: {},
  receiver: {},
  messageList: [],
  userList: [],
  conversationList: [],
  editMode: false,
};

// eslint-disable-next-line default-param-last
export function msgReducer(state = null, action) {
  switch (action.type) {
    case MSG_TYPES.AUTH_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case MSG_TYPES.AUTH_FAIL: {
      return {
        ...state,
        user: {},
      };
    }
    case MSG_TYPES.SET_USER_LIST: {
      return {
        ...state,
        userList: action.payload,
      };
    }
    case MSG_TYPES.SET_RECEIVER_INFO: {
      return {
        ...state,
        receiver: action.payload,
      };
    }
    case MSG_TYPES.SET_SCROLL_VALUE: {
      return {
        ...state,
        scrollToBottom: action.payload,
      };
    }
    case MSG_TYPES.SET_MESSAGE: {
      return {
        ...state,
        messageList: [...state.messageList, action.payload],
      };
    }
    case MSG_TYPES.SET_MESSAGE_HISTORY: {
      return {
        ...state,
        messageList: [...action.payload, ...state.messageList],
      };
    }
    case MSG_TYPES.SET_CONVERSATION_LIST: {
      return {
        ...state,
        conversationList: action.payload,
      };
    }
    case MSG_TYPES.UPDATE_MESSAGES: {
      return {
        ...state,
        messageList: action.payload,
      };
    }
    case MSG_TYPES.CLEAN_MESSAGES: {
      return {
        ...state,
        messageList: [],
      };
    }
    case MSG_TYPES.SET_FILTER_VALUE: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case MSG_TYPES.SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload,
      };
    }
    case MSG_TYPES.LOGOUT: {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
}

export const msgActions = {
  setMessage,
  setReceiverInfo: setReceiverInfoAction,
  loginUserMsg,
  sendMessage,
  setMessageHistory,
  setUserList,
  setConversationList,
  cleanMessages: cleanMessagesAction,
  setEditMode: setEditModeAction,
};
