import { catchWrapper } from '../../../helpers/utility';
import { getSponsorMembers } from '../../../api/v2';

export default (id) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/LOAD_SPONSOR_MEMBERS/REQUESTED' });

  const payloadRequest = { id };

  const result = await catchWrapper(getSponsorMembers(payloadRequest));

  const sponsorMembers = {
    commander: result.commander,
    deputy: result.deputy,
    users: result.users,
    groups: result.groups,
    remove: result.remove,
  };

  if (result && result.result) {
    dispatch({ type: 'SPONSOR/LOAD_SPONSOR_MEMBERS/SUCCEEDED', payload: { ...sponsorMembers } });
  } else {
    dispatch({
      type: 'SPONSOR/LOAD_SPONSOR_MEMBERS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
