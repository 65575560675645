import { isEqual } from 'lodash';
import { Pages } from '../../../routes/pages';

export const getActions = ({ location, teamId, actions, teamState, deleteTextEditorImages }) => {
  const {
    onCreateTeam,
    onSaveTeamProfile,
    onSaveTeamMembers,
    onSaveTeamSettingsCharter,
    onGetTeamSettingsCharter,
    onSaveTeamSettingsEquipment,
    onGetTeamSettingsEquipment,
    onGetTeamSettingsHistory,
    onSaveTeamSettingsHistory,
    onSaveTeamSettingsDemand,
    onGetTeamSettingsDemand,
  } = actions;

  const path = location?.hash ? `${location?.pathname}${location?.hash}` : location?.pathname;

  switch (path) {
    case Pages.team.create.main:
      return onCreateTeam;
    case Pages.team.settings.personnel.replace(':id', teamId):
      return onSaveTeamMembers;
    case Pages.team.settings.main.replace(':id', teamId):
      return async () => {
        const data = {
          images: [],
          page_id: 1,
          parent_id: Number(teamId),
          type: 'team',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveTeamProfile();
      };
    case `${Pages.team.settings.about.replace(':id', teamId)}#history`:
      return async () => {
        const data = {
          images: [],
          page_id: 5,
          parent_id: Number(teamId),
          type: 'team',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveTeamSettingsHistory(
          '5',
          teamId,
          'team',
          '1',
          teamState?.teamsSettings?.history?.text,
        );
        await onGetTeamSettingsHistory('5', teamId, 'team', '1');
      };
    case `${Pages.team.settings.about.replace(':id', teamId)}#charter`:
      return async () => {
        const data = {
          images: [],
          page_id: 1,
          parent_id: Number(teamId),
          type: 'team',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveTeamSettingsCharter(
          '1',
          teamId,
          'team',
          '1',
          teamState?.teamsSettings?.charter?.text,
        );
        await onGetTeamSettingsCharter('1', teamId, 'team', '1');
      };
    case `${Pages.team.settings.about.replace(':id', teamId)}#equipment`:
      return async () => {
        const data = {
          images: [],
          page_id: 2,
          parent_id: Number(teamId),
          type: 'team',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveTeamSettingsEquipment(
          '2',
          teamId,
          'team',
          '1',
          teamState?.teamsSettings?.equipment?.text,
        );
        await onGetTeamSettingsEquipment('2', teamId, 'team', '1');
      };
    case `${Pages.team.settings.about.replace(':id', teamId)}#demands`:
      return async () => {
        const data = {
          images: [],
          page_id: 4,
          parent_id: Number(teamId),
          type: 'team',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveTeamSettingsDemand(
          '4',
          teamId,
          'team',
          '1',
          teamState?.teamsSettings?.demands?.text,
        );
        await onGetTeamSettingsDemand('4', teamId, 'team', '1');
      };
    default:
      return console.log('action');
  }
};

export const getIsDisabled = ({ location, teamId, teamState }) => {
  const {
    teamsSettings,
    teamSnapshot,
    teamImageTemp,
    teamMembers,
    teamMembersSnapshot,
    team,
  } = teamState;
  const image = !!teamImageTemp.image || !!teamImageTemp.photo || !!teamImageTemp.chevron;

  let isDisabled;

  if (Pages.team.settings.main.replace(':id', teamId) === location?.pathname) {
    if (image && isEqual(team, teamSnapshot)) isDisabled = false;
    if (!image && isEqual(team, teamSnapshot)) isDisabled = true;
    if (image && !isEqual(team, teamSnapshot)) isDisabled = false;
  }

  if (Pages.team.settings.personnel.replace(':id', teamId) === location?.pathname) {
    isDisabled = isEqual(teamMembers, teamMembersSnapshot);
  }

  if (
    Pages.team.settings.about.replace(':id', teamId) === location.pathname &&
    location.hash === '#history'
  ) {
    isDisabled = isEqual(teamsSettings?.history?.text, teamsSettings?.history?.snapshot);
  }
  if (
    Pages.team.settings.about.replace(':id', teamId) === location.pathname &&
    location.hash === '#charter'
  ) {
    isDisabled = isEqual(teamsSettings?.charter?.text, teamsSettings?.charter?.snapshot);
  }
  if (
    Pages.team.settings.about.replace(':id', teamId) === location.pathname &&
    location.hash === '#equipment'
  ) {
    isDisabled = isEqual(teamsSettings?.equipment?.text, teamsSettings?.equipment?.snapshot);
  }
  if (
    Pages.team.settings.about.replace(':id', teamId) === location.pathname &&
    location.hash === '#demands'
  ) {
    isDisabled = isEqual(teamsSettings?.demands?.text, teamsSettings?.demands?.snapshot);
  }
  return isDisabled;
};
