import React from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';
import { localeContent } from '../../../locale/ru';

//Pages
import CategoryPage from '../desktop/main';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderRight from '../../../components/headerConstructor/components/right';

const PagesSectionMobile = () => {
  return (
    <>
      <HeaderConstructor className="pages mobile">
        <HeaderLeft>
          <SidebarButton />
          <HeaderTitle title={localeContent.Pages.category.pageTitle} />
        </HeaderLeft>
        <HeaderRight>
          <>
            <Controls />
            <Menu />
          </>
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        <Switch>
          <PrivateRoute exact path={Pages.pages.category} component={CategoryPage} />
        </Switch>
      </div>
    </>
  );
};

export default PagesSectionMobile;
