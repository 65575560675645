import onChangeError from './onChangeError';
import { catchWrapper } from '../../../helpers/utility';
import { postGroundRequest } from '../../../api/v2';
import modalService from '../../../services/modalService';
import { validationGroundRequest } from '../validation';

export default (params, ground) => async (dispatch) => {
  const error = validationGroundRequest(params);

  if (ground?.id) {
    if (error) {
      dispatch(onChangeError(error));
    } else {
      dispatch({ type: 'GROUND/SEND_REQUEST/REQUESTED' });

      const result = await catchWrapper(
        postGroundRequest({
          ...params,
          ground,
        }),
      );

      if (result?.result) {
        dispatch({ type: 'GROUND/SEND_REQUEST/SUCCEEDED' });
        modalService.closeAll();
        modalService.openNotificationsModal({
          type: 'success',
          content: result?.message && result.message[0],
        });
      } else {
        dispatch({ type: 'GROUND/SEND_REQUEST/FAILED' });
      }
    }
  }
};
