import React, { useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

import HeaderConstructor from '../../../components/headerConstructor';
import InputSearch from '../../../components/inputSearch';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderRight from '../../../components/headerConstructor/components/right';

import BlogsAllPage from './all';

import './styles.scss';
import BlogSectionDesktop from '../../blog/desktop';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';
import BlogsSubscribedPage from './subscribedBlogs';

const BlogsSectionDesktop = ({ navigationMap }) => {
  const [search, setSearch] = useState('');
  // const { location } = history;
  // const isBlogsMainPage = useRouteMatch(Pages.blog.main.replace(':id', location.state));
  const isBlogsMainPage = useRouteMatch(Pages.blog.main);

  const pageTitle = isBlogsMainPage ? 'Блог' : 'Блоги';

  const isShowSearch = !isBlogsMainPage;

  const handleChangeSearch = (v) => setSearch(v);
  return (
    <>
      <HeaderConstructor className="blogs">
        <HeaderLeft
          title={pageTitle}
          search={
            isShowSearch ? <InputSearch value={search} onChange={handleChangeSearch} /> : null
          }
          navigation={<TabletNavigation naviMap={navigationMap} />}
        />
        <HeaderRight>
          <Controls isShowLang />
          <Menu />
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.blog.all}
            component={BlogsAllPage}
            extraProps={{ search }}
          />
          <PrivateRoute
            exact
            path={Pages.blog.subscribed}
            component={BlogsSubscribedPage}
            extraProps={{ search }}
          />
          <PrivateRoute exact path={Pages.blog.all} component={BlogSectionDesktop} />
        </Switch>
      </div>
    </>
  );
};

export default BlogsSectionDesktop;
