import React from 'react';
import { NavLink } from 'react-router-dom';

import { getUrlToNavi } from '../../../helpers/utility';

import './styles.scss';

const SubNavigation = ({ naviMap }) => (
  <div className="rules-filter">
    <div className="rules-filter__drop">
      <ul>
        {naviMap &&
          naviMap.map((item) => (
            <li key={item.id}>
              <NavLink
                isActive={(match, location) =>
                  (item.route?.hash && location.hash === item.route.hash) ||
                  (item.route?.path && item.route.path === location.pathname)
                }
                className="rules-filter__link"
                activeClassName="rules-filter__link_active"
                to={getUrlToNavi(item.route)}
                strict
                key={item.id}
              >
                <div className="rules-filter__link__row">
                  <span className="rules-filter__link__text">{item.label}</span>
                </div>
              </NavLink>
            </li>
          ))}
      </ul>
    </div>
  </div>
);

export default SubNavigation;
