import { getFormattedDateNumber } from './utility';

const milliseconds = 1000;
const seconds = 1 * milliseconds;
const minute = 60 * seconds;
const hour = 60 * minute;
const day = 24 * hour;

const currentTimestamp = new Date(Date.now());

const dateDiffInDays = (a, b) => {
  const milliSecondsPerDay = day;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / milliSecondsPerDay);
};

export const getLastMessageDate = (timestamp, language) => {
  const timeStampInMilliSeconds = timestamp * 1000;
  const messageTimestamp = new Date(timeStampInMilliSeconds);
  const dateDifferenceInDays = dateDiffInDays(messageTimestamp, currentTimestamp);

  if (dateDifferenceInDays < 1) {
    timestamp = getFormattedDateNumber(timeStampInMilliSeconds, 'H:mm', language);
  } else if (dateDifferenceInDays < 2) {
    timestamp = 'pages.msg.yesterday';
  } else if (dateDifferenceInDays < 7) {
    timestamp = getFormattedDateNumber(messageTimestamp, 'EEEE', language);
  } else {
    timestamp = getFormattedDateNumber(timeStampInMilliSeconds, 'dd/LL/yyyy', language);
  }
  return timestamp;
};

export const getMessageDate = (timestamp, language) => {
  const timeStampInMilliSeconds = timestamp * 1000;
  const messageTimestamp = new Date(timeStampInMilliSeconds);
  const dateDifferenceInDays = dateDiffInDays(messageTimestamp, currentTimestamp);

  if (dateDifferenceInDays < 1) {
    timestamp = 'pages.msg.today';
  } else if (dateDifferenceInDays < 2) {
    timestamp = 'pages.msg.yesterday';
  } else if (dateDifferenceInDays < 7) {
    timestamp = getFormattedDateNumber(messageTimestamp, 'EEEE', language);
  } else {
    timestamp = getFormattedDateNumber(timeStampInMilliSeconds, 'dd/LL/yyyy', language);
  }
  return timestamp;
};
