import getComments from './impl/getComments';
import writeComments from './impl/writeComments';
import likeRequest from './impl/likeRequest';
import bookmark from './impl/bookmark';
import getFullVideoInfo from './impl/getFullVideoInfo';
import getUserVideoList from './impl/getUserVideoList';
import clearFullVideoInfo from './impl/clearFullVideoInfo';
import getAlbumList from './impl/getUserImagesList';
import getUserImagesList from './impl/getUserImagesList';
import getFullImageInfo from './impl/getFullImageInfo';
import clearFullImageInfo from './impl/clearFullImageInfo';
import clearComments from './impl/clearComments';

const initState = {
  loading: 0,
  comments: [],
  likes: 0,
  bookmarks: 0,
  pagination: null,
  videoInfo: {},
  imageInfo: {},
  userVideoList: [],
  albumList: [],
  userPhotos: [],
};

export const socialReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SOCIAL/GET_COMMENTS_LIST/REQUESTED':
    case 'SOCIAL/GET_FULL_VIDEO_INFO/REQUESTED':
    case 'SOCIAL/GET_PHOTOS_LIST/REQUESTED':
    case 'SOCIAL/GET_FULL_IMAGE_INFO/REQUESTED':
    case 'SOCIAL/GET_ALBUM_LIST/REQUESTED':
      return {
        ...state,
        loading: state.loading + 1,
      };
    case 'SOCIAL/GET_COMMENTS_LIST/SUCCEEDED': {
      const allComments = action.payload.items.filter(
        (obj) => state?.comments?.indexOf(obj) === -1,
      );
      return {
        ...state,
        loading: state.loading - 1,
        comments: allComments,
        pagination: action.payload.pagination,
      };
    }

    case 'SOCIAL/GET_FULL_VIDEO_INFO/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        videoInfo: action.payload?.video,
      };

    case 'SOCIAL/GET_PHOTOS_LIST/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        userPhotos: action.payload?.items,
      };

    case 'SOCIAL/CLEAR_FULL_VIDEO_INFO':
      return {
        ...state,
        videoInfo: initState?.videoInfo,
      };

    case 'SOCIAL/CLEAR_COMMENTS':
      return {
        ...state,
        comments: initState?.comments,
      };

    case 'SOCIAL/CLEAR_FULL_IMAGE_INFO':
      return {
        ...state,
        videoInfo: initState?.videoInfo,
      };

    case 'SOCIAL/GET_FULL_IMAGE_INFO/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        imageInfo: action.payload?.image,
      };

    case 'SOCIAL/GET_USER_VIDEO_LIST/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        userVideoList: action.payload?.items,
      };

    case 'SOCIAL/GET_ALBUM_LIST/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        albumList: action.payload?.items,
      };

    case 'SOCIAL/GET_COMMENTS_LIST/FAILED':
    case 'SOCIAL/GET_FULL_VIDEO_INFO/FAILED':
    case 'SOCIAL/GET_PHOTOS_LIST/FAILED':
    case 'SOCIAL/GET_FULL_IMAGE_INFO/FAILED':
    case 'SOCIAL/GET_ALBUM_LIST/FAILED':
      return {
        ...state,
        loading: state.loading - 1,
      };
    case 'SOCIAL/CLEAR_SOCIAL':
      return {
        ...state,
        ...initState,
      };

    case 'SOCIAL/CLEAR_ALBUMS':
      return {
        ...state,
        albumList: [],
      };
    default:
      return state;
  }
};

export const socialActions = {
  getComments,
  writeComments,
  likeRequest,
  bookmark,
  getFullVideoInfo,
  getUserVideoList,
  clearFullVideoInfo,
  getAlbumList,
  getUserImagesList,
  getFullImageInfo,
  clearFullImageInfo,
  clearComments,
};
