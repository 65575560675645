import React from 'react';
import Trans from '../../../../components/trans';

const EventSize = ({ size }) => {
  let sizeType = 6;
  console.log(size);
  if (size <= 50) sizeType = 1;
  else if (size <= 100) sizeType = 2;
  else if (size <= 300) sizeType = 3;
  else if (size <= 500) sizeType = 4;
  else if (size <= 1000) sizeType = 5;
  const title = Trans(`events.size${sizeType}`);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{title}</>;
};

export default EventSize;
