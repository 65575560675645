import Telegram from './telegram';
import Delete from './delete';
import EditMode from './edit';
import MessageRead from './messageRead';

export default {
  telegram: Telegram,
  delete: Delete,
  editMode: EditMode,
  msgRead: MessageRead,
};
