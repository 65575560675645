import { catchWrapper } from '../../../helpers/utility';
import { postTeams } from '../../../api';

export default (id) => async (dispatch) => {
  dispatch({ type: 'TEAMS/CANCEL_REQUEST_JOIN_TEAM/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'removeRequest');
  formData.append('team_id', Number(id));

  const result = await catchWrapper(postTeams('team_users', formData));

  if (result?.result) {
    dispatch({ type: 'TEAMS/CANCEL_REQUEST_JOIN_TEAM/SUCCEEDED' });
    dispatch({
      type: 'PROFILE/CHANGE_FIELD_USER_AND_SNAPSHOT',
      payload: {
        field: 'team',
        value: null,
      },
    });
  } else {
    dispatch({
      type: 'TEAMS/CANCEL_REQUEST_JOIN_TEAM/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
