import React, { memo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import VideoCard from './videoCard';

const VideoCardWrap = ({
  videoList,
  fetchPaginated,
  hasMore,
  parentId,
  isBlogHolder,
  openModal,
  onDeleteVideo,
  userPage,
  parentType,
}) => {
  return (
    <InfiniteScroll
      dataLength={videoList?.length || 0}
      next={fetchPaginated}
      hasMore={hasMore}
      loader={<></>}
      className=""
      scrollableTarget="scrollable-gallery"
    >
      {videoList?.map((video) => (
        <VideoCard
          profile={video?.profile}
          key={video?.id}
          video={video}
          parentId={parentId}
          openAddBlogVideoModal={openModal}
          blogHolder={isBlogHolder}
          deleteVideo={onDeleteVideo}
          userPage={userPage}
          parentType={parentType}
        />
      ))}
    </InfiniteScroll>
  );
};

export default memo(VideoCardWrap);
