import { applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default composeWithDevTools(applyMiddleware(thunk, promise, routerMiddleware(history)));
