import React from 'react';
import { classNames as cx } from '../../../helpers/utility';
import dummy from '../../../assets/image/user.svg';
import { Pages } from '../../../routes/pages';
import './styles.scss';

const Comment = ({ comment }) => {
  const classNames = cx('user-card__user-container__image', {
    'user-card__user-container__image_dummy': !comment?.user?.image_avatar?.preview,
  });
  const urlPreview = comment?.user?.image_avatar?.preview
    ? comment?.user?.image_avatar?.preview
    : dummy;
  // const acaUrl = comment && `${comment?.user?.image_avatar?.preview}`;
  const url = Pages.profile.main.replace(':id?', comment?.user?.id);
  return (
    <div className="comment">
      <div>
        <a href={url}>
          <div className={classNames}>
            <img src={urlPreview} alt="avatar" />
          </div>
        </a>
      </div>
      <div className="comment__header">
        <div className="comment__user-info">
          <div className="comment__info-text">
            <div className="comment__nickname">
              <span>{comment?.user?.nickname}</span>
            </div>
            <div className="comment__timestamp">
              <span>{comment?.date}</span>
            </div>
          </div>
        </div>
        <div className="comment__comment-text">{comment?.text}</div>
      </div>
    </div>
  );
};

export default Comment;
