import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.576 22.5553L25.5543 15.5921C26.1486 14.9991 26.1486 14.0377 25.5543 13.4447C24.9601 12.8518 23.9965 12.8518 23.4023 13.4447L17.5 19.3342L11.5977 13.4447C11.0035 12.8518 10.04 12.8518 9.4457 13.4447C8.85143 14.0377 8.85143 14.9991 9.4457 15.5921L16.424 22.5553C17.0183 23.1482 17.9818 23.1482 18.576 22.5553Z"
      fill="currentColor"
    />
  </svg>
);
