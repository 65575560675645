import React from 'react';

export default () => (
  <svg width="60" height="43" viewBox="0 0 60 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 43C12.1136 43 23.9766 31.1931 30.264 20.21C36.4178 9.45999 48.6399 0.43 59.9999 0L60 43H0Z"
      fill="#FAFAFA"
    />
  </svg>
);
