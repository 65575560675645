import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1388 3.00186C14.1065 3.00371 13.271 3.85375 13.271 4.90423L13.2729 15.3998C13.2729 16.4503 14.1101 17.3004 15.1407 17.3004L30.1341 17.2985C31.1646 17.2985 32 16.4465 32 15.3979L31.9982 4.90052C31.9982 3.85004 31.1628 3 30.1304 3L15.1388 3.00186ZM18.8762 6.39644C18.3601 6.39644 17.9423 6.82146 17.9423 7.3467C17.9423 7.87194 18.3601 8.29697 18.8762 8.29697H26.3474C26.8636 8.29697 27.2813 7.87194 27.2813 7.3467C27.2813 6.82146 26.8636 6.39644 26.3474 6.39644H18.8762ZM18.3947 12.2335C18.5352 12.1481 18.6993 12.098 18.8762 12.098H26.3474C26.8636 12.098 27.2813 12.523 27.2813 13.0482C27.2813 13.5735 26.8636 13.9985 26.3474 13.9985H18.8762C18.3601 13.9985 17.9423 13.5735 17.9423 13.0482C17.9423 12.7031 18.123 12.4005 18.3947 12.2335ZM5.65942 18.7145C5.65942 15.1436 8.44104 12.2335 11.9213 12.0999V16.8456C11.9213 17.484 12.2295 18.0483 12.7037 18.3916C13.0102 18.6144 13.3841 18.7461 13.789 18.7461H18.6847C18.6738 21.0234 17.5338 23.0279 15.8046 24.212C16.744 24.5479 17.6286 25.0249 18.4056 25.6467C20.1731 27.0572 21.3441 29.1841 21.3441 31.9551C21.3441 32.4767 20.9702 32.9073 20.4814 33H4.02874C3.45965 33 3 32.5324 3 31.9551C3 29.1841 4.17102 27.0572 5.93849 25.6467C6.71552 25.0249 7.60199 24.5479 8.54136 24.212C6.80308 23.0223 5.65942 21.0049 5.65942 18.7145Z"
      fill="currentColor"
    />
  </svg>
);
