import Search from './search';
import TripleDots from './tripleDots';
import Comment from './comment';
import Like from './like';
import Repost from './repost';
import FilterSettings from './filterSettings';
import Bookmark from './bookmark';

export default {
  search: Search,
  tripleDots: TripleDots,
  comment: Comment,
  like: Like,
  repost: Repost,
  filterSettings: FilterSettings,
  bookmark: Bookmark,
};
