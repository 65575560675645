import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './styles.scss';

const ArticleInfoCard = ({ article, id }) => {
  const date = moment(article?.created).format('DD MMMM YYYY, HH:MM');
  moment.lang('ru');
  return (
    <div id={id} className="card article-info-card">
      <Link
        className="article-info-card__link"
        to={{ pathname: `/blog/article/${article?.id}/`, state: article?.id }}
      >
        <div className="article-info-card__image-wrap">
          {article?.image?.image ? <img src={article?.image?.image} alt="" /> : null}
        </div>
        <div className="article-info-card__name-block">
          <div className="article-info-card__title">{article?.profile?.title}</div>
          <div className="article-info-card__info">{date}</div>
        </div>
      </Link>
    </div>
  );
};

export default memo(ArticleInfoCard);
