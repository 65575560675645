import React from 'react';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const mockOptions = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const CustomSelect = ({
  className,
  name,
  label,
  labelPosition,
  isDisabled,
  asyncSelect,
  options,
  isLoading,
  loadOptions,
  valueKey,
  labelKey,
  handleChange,
  onMenuOpen,
  value,
  defaultValue,
  placeholder,
  isClearable,
  error,
  required,
  handleClear,
  ...props
}) => {
  const classNamesWrapper = cx('select__wrapper', className, {
    [`select__wrapper__label_${labelPosition}`]: labelPosition,
    [`select__${name}`]: name,
    'select__wrapper--error': error,
  });

  const classNamesLabel = cx('select__label', className, {
    [`select__label_${labelPosition}`]: labelPosition,
    'select__label--error': error,
  });
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
    }),
  };

  const customLabel = required ? `${label} *` : label;

  return asyncSelect ? (
    <div className={classNamesWrapper}>
      {(label || (error && required)) && (
        <span className={classNamesLabel}>{error || customLabel}</span>
      )}
      <Select
        {...props}
        className="select__container"
        label={label}
        name={name}
        classNamePrefix="select"
        isDisabled={isDisabled}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        onChange={handleChange}
        isSearchable
        value={isEmpty(value) ? null : value}
        noOptionsMessage={() => null}
        styles={style}
        components={{
          IndicatorSeparator: () => null,
        }}
        options={options}
        onMenuOpen={onMenuOpen}
        isLoading={isLoading}
        placeholder={placeholder}
        clearValue={handleClear || (() => {})}
        isClearable={!!handleClear}
      />
    </div>
  ) : (
    <div className={classNamesWrapper}>
      {(label || error) && <span className={classNamesLabel}>{error || label}</span>}
      <Select
        {...props}
        styles={style}
        className="select__container"
        label={label}
        classNamePrefix="select"
        options={options || mockOptions}
        isDisabled={isDisabled}
        value={isEmpty(value) ? null : value}
        name={name}
        onChange={handleChange}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        components={{
          IndicatorSeparator: null,
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomSelect;
