import React from 'react';

import { classNames as cx } from '../../../../../helpers/utility';

import Avatars from '../../../avatars';

import './styles.scss';
import Trans from '../../../../../components/trans';

const ViewParticipants = ({ isShort, users, qtyOfUsers = 0, qtyFriends = 0 }) => {
  const classNames = cx('view-participants__wrap', {
    'view-participants__wrap--short': isShort,
  });

  return (
    <div className={classNames}>
      {isShort ? (
        <span className="view-participants--short">
          <span className="view-participants__text">Посетят</span>
          <span className="view-participants__bold">{qtyOfUsers}</span>
          <span className="view-participants__text">бойцов</span>
        </span>
      ) : (
        <span className="view-participants--full">
          <span className="view-participants__text">
            {Trans('common.players')} {qtyOfUsers}
          </span>
          <Avatars friends={users} totalFriends={qtyOfUsers} />
          {qtyFriends ? (
            <span className="view-participants__friends">
              {Trans('common.friends')} {qtyFriends}
            </span>
          ) : null}
        </span>
      )}
    </div>
  );
};

export default ViewParticipants;
