import { paymentsInit, sidesInit } from '../pages/events/desktop/create/helpers';
import { MSG_FILTER } from './msg/constants';

export default {
  profile: {
    loading: 0,
    loadingSilent: 0,
    error: null,
    user: {},
    userSnapshot: {},
    ownerId: null,
    anotherUser: {},
    userSponsors: [],
  },
  auth: {
    loading: false,
    error: {
      login: null,
      register: null,
      emailConfirm: null,
    },
    isEmailConfirmed: false,
    showCaptcha: false,
    providers: [],
    services: [],
    token: '',
    userId: '',
    social: [],
    authSocial: false,
    counts: null,
  },
  settings: {
    error: null,
    loading: 0,
    privacy: {},
    privacySnapshot: {},
    privacyConfig: [],
    privacyConfigValues: {},
    step: '',
  },
  users: {
    loading: 0,
    loadingAction: 0,
    users: [],
    pagination: {},
    friends: [],
    friendsPagination: {},
    incoming: [],
    incomingPagination: {},
    outgoing: [],
    outgoingPagination: {},
    error: null,
  },
  camouflages: {
    loading: 0,
    camouflages: [],
    errors: null,
  },
  camouflage: {
    loading: 0,
    camouflages: [],
    error: null,
    camouflage: {},
  },
  geo: {
    countries: { options: [], loading: 0 },
    regions: { options: [], loading: 0 },
    cities: { options: [], loading: 0 },
    openCountries: { options: [], loading: 0 },
  },
  teams: {
    loading: 0,
    loadingSilent: 0,
    teams: [],
    pagination: {},
    recruits: [],
    teamsSettings: {
      charter: { snapshot: '', text: '' },
      equipment: { snapshot: '', text: '' },
      demands: { snapshot: '', text: '' },
      history: { snapshot: '', text: '' },
    },
    teamSnapshot: {},
    teamImageTemp: {
      image: null,
      photo: null,
      chevron: null,
    },
    teamCamouflagesTemp: [],
    teamMembers: {
      commander: null,
      deputy: null,
      users: null,
      // requests: null,
      groups: null,
      remove: [],
    },
    teamMembersSnapshot: {
      commander: null,
      deputy: null,
      users: null,
      // requests: null,
      groups: null,
      remove: [],
    },
    teamRequests: [],
    errors: null,
  },
  sponsor: {
    loading: 0,
    loadingSilent: 0,
    sponsorList: [],
    pagination: {},
    sponsor: {
      closed: false,
      single: false,
    },
    sponsorSettings: {
      historyText: { snapshot: '', text: '' },
      rules: { snapshot: '', text: '' },
      demands: { snapshot: '', text: '' },
      entry: { snapshot: '', text: '' },
      data: { snapshot: '', text: '' },
      responsibility: { snapshot: '', text: '' },
    },
    sponsorSnapshot: {},
    sponsorImageTemp: {
      image: null,
      flag: null,
    },
    sponsorMembers: {
      commander: null,
      deputy: null,
      users: null,
      groups: null,
      remove: [],
    },
    sponsorMembersSnapshot: {
      commander: null,
      deputy: null,
      users: null,
      groups: null,
      remove: [],
    },
    sponsorParticipants: {
      gold: null,
      silver: null,
      bronze: null,
      members: null,
      guests: null,
    },
    sponsorParticipantsSnapshot: {
      gold: null,
      silver: null,
      bronze: null,
      members: null,
      guests: null,
    },
    sponsorRequests: [],
    errors: null,
  },
  sidebar: {
    isOpenSidebar: false,
  },
  events: {
    loading: 0,
    loadingSilent: 0,
    eventsList: [],
    eventsUserList: [],
    event: {},
    eventParticipants: {
      teams: {
        items: [],
        pagination: {},
      },
      single: {
        items: [],
        pagination: {},
      },
    },
    pagination: {},
    types: [],
    years: [],
    yearsLoading: 0,
    create: {
      sponsor: {},
      sponsors: [],
      sides: sidesInit,
      profile: {},
      type: {},
      location: {
        country: {},
        regionArr: [],
        selectedRegions: [],
        selectedCities: [],
        init: false,
      },
      dates: {},
      size: null,
      close_reg: false,
      choose_side: false,
      payments: paymentsInit(),
      allow: {
        is_club: true,
        is_guest: false,
        is_single: false,
        is_rules: true,
        is_spec_rules: false,
        is_agreement: true,
        is_spec_agreement: false,
      },
    },
    errors: null,
    eventTempImage: {},
    eventSettings: {
      scenario: { snapshot: '', text: '' },
      prices: { snapshot: '', text: '' },
      access: { snapshot: '', text: '' },
      agreement: { snapshot: '', text: '' },
      rules: { snapshot: '', text: '' },
      additionalRules: { snapshot: '', text: '' },
      additionalAgreement: { snapshot: '', text: '' },
      polygon: { snapshot: '', text: '' },
      results: { snapshot: '', text: '' },
    },
  },
  achievements: {
    loading: 0,
    achievements: [],
    awards: [],
    titles: [],
    list: [],
    pagination: null,
  },
  imagePicker: {},
  blogs: {
    loading: 0,
    error: null,
    pagination: {},
    blogs: [],
  },
  blog: {
    loading: 0,
    loadingSilent: 0,
    loadingSilentGallery: 0,
    uploadCount: 0,
    error: null,
    pagination: {},
    paginationAlbums: {},
    paginationVideo: {},
    albumsList: [],
    albumImages: [],
    album: {},
    blog: {},
    tags: [],
    types: [],
    articlesTypes: [],
    blogSnapshot: {},
    blogImageTemp: {},
    articleImageTemp: {},
    newBlog: {},
    newArticle: {},
    articlesList: [],
    videoList: [],
    article: {},
    articleSnapshot: {},
  },
  pages: {
    errors: null,
    loading: 0,
  },
  editor: {
    editorSnapshotData: [],
    editorChangesData: [],
    loading: 0,
    matches: [],
  },
  grounds: {
    loading: 0,
    grounds: [],
    error: null,
    pagination: {},
    ground: {},
    groundSnapshot: {},
    imageLoading: 0,
    imageActionId: 0,
    saveRequest: 0,
    sendingRequest: 0,
  },
  textPage: {
    loading: 0,
    error: null,
    title: null,
    text: null,
    menu: null,
  },
  theme: {
    loading: 0,
    theme: 0,
    eventView: 1,
  },
  msg: {
    receiver: {},
    user: {},
    messageList: [],
    userList: [],
    conversationList: [],
    scrollToBottom: true,
    editMode: false,
    filter: MSG_FILTER.ALL,
  },
  social: {
    loading: 0,
    comments: [],
    likes: 0,
    bookmarks: 0,
    pagination: null,
    videoInfo: {},
    imageInfo: {},
    userVideoList: [],
    albumList: [],
    userPhotos: [],
  },
};
