import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useActions, useSelector } from '../../../../hooks/store';
import { authActions } from '../../../../store/auth';

import Icon from '../../../../components/icon';
import Button from '../../../../components/button';

import modalService from '../../../../services/modalService';

import './styles.scss';
import MobileLinksContent from '../../../components/mobileLinksContent';

const SettingsLinksMobile = () => {
  const { onSaveService, getServices } = useActions(authActions);
  const { providers, services } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  useEffect(() => {
    getServices();
  }, []);

  const handleRemoveLink = (service) => {
    const payload = {
      service,
      value: '',
    };
    onSaveService(payload);
  };

  const handleOpenModal = (type, service, value) =>
    modalService.openBottomMenu({
      type,
      service,
      value,
      onSave: onSaveService,
      remove: handleRemoveLink,
      providers,
    });

  return (
    <div className="settings-links-mobile">
      {user?.social_service && services ? (
        <MobileLinksContent
          socialService={user?.social_service}
          services={services}
          openModal={handleOpenModal}
        />
      ) : null}
    </div>
  );
};

export default SettingsLinksMobile;
