import { catchWrapper } from '../../../helpers/utility';
import { postSecurity } from '../../../api';
import modalService from '../../../services/modalService';

export default (data) => async (dispatch) => {
  dispatch({ type: 'SETTINGS/SUBMIT_PASSWORD/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'changePassword');
  formData.append('password', data.password);
  formData.append('new_password', data.new_password);
  formData.append('re_new_password', data.re_new_password);

  const result = await catchWrapper(postSecurity(formData));

  if (result && result.result) {
    modalService.closeAll();
    modalService.openNotificationsModal({
      type: 'success',
      content: 'Пароль успешно изменен',
    });
    dispatch({ type: 'SETTINGS/SUBMIT_PASSWORD/SUCCESSFUL' });
  } else {
    // modalService.closeAll();
    // modalService.openNotificationsModal({
    //   type: 'reject',
    //   content: 'Не удалось изменить пароль',
    // });
    dispatch({ type: 'SETTINGS/SUBMIT_PASSWORD/FAILED', payload: result.message[0] });
  }
};
