import React from 'react';

import { classNames as cx } from '../../helpers/utility';

import menu from './icons/menu';
import playingRole from './icons/playingRole';
import social from './icons/social';
import login from './icons/login';
import navMobile from './icons/mobNav';
import feed from './icons/feed';
import noBox from './icons/noBox';
import notifications from './icons/notifications';
import other from './icons/other';
import events from './icons/events';
import place from './icons/place';
import msg from './icons/msg';

export const icons = {
  ...menu,
  ...playingRole,
  ...social,
  ...login,
  ...navMobile,
  ...feed,
  ...noBox,
  ...notifications,
  ...events,
  ...place,
  ...msg,
  ...other,
};

const Icon = (props) => {
  let { children } = props;
  const { type, className, variant, style, onClick } = props;
  if (!type) return null;

  const classNames = cx('icon-wrapper', className, {
    [`icon--${type}`]: type,
    [`icon--${variant}`]: variant,
  });

  const IconComponent = icons[type] || icons.default;

  children = children || <IconComponent />;
  if (!children) return null;

  return (
    <span className={classNames.toString()} onClick={onClick ? onClick : () => true} style={style}>
      {children}
    </span>
  );
};

export default Icon;
