import { isEmpty } from 'lodash';

export const validationCreateTeam = ({ team, teamImageTemp }) => {
  const error = {};

  if (!team?.profile?.title) {
    error.title = 'Поле обязательно для заполнения!';
  }
  if (!team?.date) {
    error.date = 'Поле обязательно для заполнения!';
  }
  if (isEmpty(team.camouflages)) {
    error.camouflage = 'Выберите камуфляж!';
  }
  if (!team?.location?.country?.id) {
    error.country = 'Поле обязательно для заполнения!';
  }
  if (!team?.location?.region?.id) {
    error.region = 'Поле обязательно для заполнения!';
  }
  if (!team?.location?.city?.id) {
    error.city = 'Поле обязательно для заполнения!';
  }

  if (teamImageTemp) {
    if (!teamImageTemp?.image) {
      error.image = 'Необходимо загрузить изображение!';
    }
    if (!teamImageTemp?.chevron) {
      error.chevron = 'Необходимо загрузить изображение!';
    }
    if (!teamImageTemp?.photo) {
      error.photo = 'Необходимо загрузить изображение!';
    }
  }

  return isEmpty(error) ? null : error;
};
