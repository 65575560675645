import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { useActions, useSelector } from '../../../../hooks/store';
import useVisible from '../../../../hooks/useVisible';
import { useResponsive } from '../../../../hooks/useResponsive';
import { authActions } from '../../../../store/auth';

import { classNames as cx } from '../../../../helpers/utility';

import Button from '../../../button';
import Icon from '../../../icon';
import { Pages } from '../../../../routes/pages';

import './styles.scss';

import dummy from '../../../../assets/image/user.svg';
import Trans from '../../../trans';

const getRoutesMap = ({ teamId, sponsorId, blogId }) => [
  {
    id: '1',
    icon: 'profile',
    label: Trans('navigation.userMenu.profile'),
    route: Pages.profile.base,
    isShow: true,
  },
  {
    id: '2',
    icon: 'teams',
    label: Trans('navigation.userMenu.myTeam'),
    route: {
      pathname: Pages.team.main.replace(':id', teamId),
      state: teamId,
    },
    isShow: teamId,
  },
  {
    id: '3',
    icon: 'organizers',
    label: Trans('navigation.userMenu.myOrg'),
    route: Pages.organizers.main.replace(':id', sponsorId),
    isShow: sponsorId,
  },
  {
    id: '4',
    icon: 'blogs',
    label: Trans('navigation.userMenu.myBlog'),
    route: Pages.blog.main.replace(':id', blogId),
    isShow: blogId,
  },
  {
    id: '5',
    icon: 'passport',
    label: Trans('navigation.userMenu.myPass'),
    route: Pages.settings.passport,
    isShow: true,
  },
  {
    id: '6',
    icon: 'settings',
    label: Trans('actions.settings'),
    route: Pages.settings.profile,
    isShow: true,
  },
  {
    id: '7',
    icon: 'friends',
    label: Trans('navigation.userMenu.exit'),
    isShow: true,
  },
];

const Menu = () => {
  const { userSnapshot, userSponsors, userBlog } = useSelector((state) => state.profile);
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const { isMobile } = useResponsive();
  const teamId = userSnapshot?.team?.id;
  const blogId = userBlog?.id;
  const sponsorId = userSponsors?.[0]?.id;
  const { onLogOut } = useActions(authActions);

  const urlPreview = userSnapshot.image_avatar && userSnapshot.image_avatar.preview;

  const classNamesParent = cx('menu', {
    menu__open: isVisible,
  });

  useEffect(() => {
    const messenger = document.body.querySelector('#js--messenger');
    const filter = document.body.querySelector('#js--toggle-filter');

    if (isVisible) {
      // eslint-disable-next-line no-unused-expressions
      messenger && messenger.classList.add('messenger-container--position-down');
      // eslint-disable-next-line no-unused-expressions
      filter && filter.classList.add('toggle-filter--position-down');
    } else {
      // eslint-disable-next-line no-unused-expressions
      messenger && messenger.classList.remove('messenger-container--position-down');
      // eslint-disable-next-line no-unused-expressions
      filter && filter.classList.remove('toggle-filter--position-down');
    }
  }, [isVisible]);

  const handleToggle = () => setIsVisible(!isVisible);

  const handleCloseMenu = () => setIsVisible(false);

  return (
    <div ref={ref} className={classNamesParent}>
      <Button onClick={handleToggle} variant="submenu">
        <div className="menu__content">
          <div className="menu__image">
            {urlPreview ? (
              <img className="menu__image-avatar" src={urlPreview} alt="avatar" />
            ) : (
              <img className="menu__image-dummy" src={dummy} alt="avatar" />
            )}
          </div>
          {!isMobile ? <Icon type="chevron" /> : null}
        </div>
      </Button>
      {isVisible ? (
        <div className="menu__drop">
          <ul>
            {getRoutesMap({ teamId, sponsorId, blogId }).map((item) =>
              item.isShow ? (
                <li key={item.id}>
                  {item.route ? (
                    <NavLink
                      className="menu__link"
                      activeClassName="menu__link_active"
                      to={item.route}
                      onClick={handleCloseMenu}
                    >
                      <div className="menu__link__row">
                        <Icon type={item.icon} />
                        <span className="menu__link__text">{item.label}</span>
                      </div>
                    </NavLink>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div onClick={onLogOut} className="menu__link__row">
                      <Icon type={item.icon} />
                      <span className="menu__link__text">{item.label}</span>
                    </div>
                  )}
                </li>
              ) : null,
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default Menu;
