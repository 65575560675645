import React from 'react';
import Button from '../../../components/button';
import Icon from '../../../components/icon';

import './styles.scss';

const ToggleFilterButton = ({ onClick, isAddButton, onClickAdd, clickAddDisabled }) => {
  return (
    <div id="js--toggle-filter" className="toggle-filter__wrap">
      {onClick ? (
        <>
          <Button onClick={onClick} variant="icon">
            <Icon type="filterSettings" />
          </Button>
          <span onClick={onClick} className="toggle-filter__label">
            Фильтры
          </span>
        </>
      ) : null}

      {isAddButton ? (
        <Button
          onClick={onClickAdd}
          disabled={clickAddDisabled}
          className="create-request create-request--mobile"
          variant="icon"
        >
          <Icon type="add" />
        </Button>
      ) : null}
    </div>
  );
};

export default ToggleFilterButton;
