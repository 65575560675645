import getEventsList from './impl/getEventsList';
import getSponsorEvents from './impl/getSponsorEvents';
import getUserEvents from './impl/getUserEvents';
import getEvent from './impl/getEvent';
import clearEvents from './impl/clearEvents';
import getEventTypes from './impl/getEventTypes';
import getEventYears from './impl/getEventYears';
import onCreateEvent from './impl/onCreateEvent';
import onChangeField from './impl/onChangeField';
import onChangeCreateField from './impl/onChangeCreateField';
import onManageEventRequest from './impl/onManageEventRequest';
import onGetEventSettingsScenario from './impl/onGetEventSettingsScenario';
import changeEventEditorText from './impl/changeEventEditorText';
import onSaveEventSettingsScenario from './impl/onSaveEventSettingsScenario';
import onGetEventSettingsPrices from './impl/onGetEventSettingsPrices';
import onSaveEventSettingsPrices from './impl/onSaveEventSettingsPrices';
import onSaveEventSettingsAccess from './impl/onSaveEventSettingsAccess';
import onGetEventSettingsAccess from './impl/onGetEventSettingsAccess';
import getEventParticipants from './impl/getEventParticipants';
import onSaveEventSettingsRulesAdd from './impl/onSaveEventSettingsRulesAdd';
import onGetEventSettingsInfoRulesAdd from './impl/onGetEventSettingsInfoRulesAdd';
import onGetEventSettingsInfoAgreeAdd from './impl/onGetEventSettingsInfoAgreeAdd';
import onSaveEventSettingsAgreeAdd from './impl/onSaveEventSettingsAgreeAdd';
import onSaveEventSettingsPolygon from './impl/onSaveEventSettingsPolygon';
import onGetEventSettingsPolygon from './impl/onGetEventSettingsPolygon';
import onSaveEventSettingsResults from './impl/onSaveEventSettingsResults';
import onGetEventSettingsResults from './impl/onGetEventSettingsResults';
import onGetEventOffer from './impl/onGetEventOffer';
import { paymentsInit, sidesInit } from '../../pages/events/desktop/create/helpers';

export const eventsReducer = (state = null, action) => {
  switch (action.type) {
    case 'EVENTS/GET_EVENT_YEARS/REQUESTED':
      return {
        ...state,
        yearsLoading: 1,
      };
    case 'EVENTS/GET_EVENTS_LIST/REQUESTED':
    case 'EVENTS/GET_EVENT/REQUESTED':
    case 'EVENTS/GET_PARTICIPANTS/REQUESTED':
    case 'EVENTS/GET_SPONSOR_EVENTS_LIST/REQUESTED':
    case 'EVENTS/GET_USER_EVENTS_LIST/REQUESTED':
    case 'EVENTS/GET_EVENT_TYPES/REQUESTED':
    case 'EVENTS/SAVE_TEXT_SCENARIO/REQUESTED':
    case 'EVENTS/GET_TEXT_PRICES/REQUESTED':
    case 'EVENTS/GET_TEXT_ACCESS/REQUESTED':
    case 'EVENTS/SAVE_TEXT_RULES_ADD/REQUESTED':
    case 'EVENTS/GET_TEXT_POLYGON/REQUESTED':
    case 'EVENTS/SAVE_TEXT_POLYGON/REQUESTED':
    case 'EVENTS/GET_TEXT_RULES_ADD/REQUESTED':
    case 'EVENTS/GET_TEXT_AGREE_ADD/REQUESTED':
    case 'EVENTS/SAVE_TEXT_AGREE_ADD/REQUESTED':
    case 'EVENTS/SAVE_TEXT_RESULTS/REQUESTED':
    case 'EVENTS/GET_TEXT_RESULTS/REQUESTED':
    case 'EVENTS/SAVE_TEXT_ACCESS/REQUESTED':
    case 'EVENTS/GET_TEXT_SCENARIO/REQUESTED':
    case 'EVENTS/SAVE_TEXT_PRICES/REQUESTED':
    case 'EVENTS/CREATE_EVENT/REQUESTED':
    case 'EVENTS/GET_OFFER/REQUESTED':
      return {
        ...state,
        loading: state.loading + 1,
      };

    case 'EVENTS/MANAGE_EVENT_REQUEST/REQUESTED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent + 1,
      };
    }

    case 'EVENTS/MANAGE_EVENT_REQUEST/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        event: {
          ...state.event,
          ...action.payload,
        },
      };
    }

    case 'EVENTS/GET_EVENT/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        event: action.payload.event,
      };
    case 'EVENTS/GET_EVENTS_LIST/SUCCEEDED':
    case 'EVENTS/GET_SPONSOR_EVENTS_LIST/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        eventsList: action.payload.events
          ? [...state.eventsList, ...action.payload.events]
          : [...state.eventsList],
        pagination: action.payload.pagination,
      };

    case 'EVENTS/GET_USER_EVENTS_LIST/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        eventsUserList: [...state.eventsUserList, ...action.payload.events],
        pagination: action.payload.pagination,
      };

    case 'EVENTS/GET_EVENT_TYPES/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        types: action.payload,
      };
    case 'EVENTS/GET_EVENT_YEARS/SUCCEEDED':
      return {
        ...state,
        yearsLoading: 0,
        years: action.payload,
      };
    case 'EVENTS/GET_PARTICIPANTS/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        eventParticipants: {
          teams: {
            items: [...state.eventParticipants.teams.items, ...action.payload.teams.items],
            pagination: action.payload.teams.pagination,
          },
          single: {
            items: [...state.eventParticipants.single.items, ...action.payload.single.items],
            pagination: action.payload.single.pagination,
          },
        },
      };
    case 'EVENTS/GET_EVENT_YEARS/FAILED':
      return {
        ...state,
        yearsLoading: 0,
      };
    case 'EVENTS/GET_EVENTS_LIST/FAILED':
    case 'EVENTS/GET_EVENT/FAILED':
    case 'EVENTS/GET_PARTICIPANTS/FAILED':
    case 'EVENTS/GET_SPONSOR_EVENTS_LIST/FAILED':
    case 'EVENTS/GET_USER_EVENTS_LIST/FAILED':
    case 'EVENTS/GET_EVENT_TYPES/FAILED':
    case 'EVENTS/CREATE_EVENT/SUCCEEDED':
    case 'EVENTS/CREATE_EVENT/FAILED':
    case 'EVENTS/SAVE_TEXT_SCENARIO/SUCCEEDED':
    case 'EVENTS/SAVE_TEXT_PRICES/SUCCEEDED':
    case 'EVENTS/SAVE_TEXT_ACCESS/SUCCEEDED':
    case 'EVENTS/SAVE_TEXT_RULES_ADD/SUCCEEDED':
    case 'EVENTS/SAVE_TEXT_POLYGON/SUCCEEDED':
    case 'EVENTS/SAVE_TEXT_AGREE_ADD/SUCCEEDED':
    case 'EVENTS/SAVE_TEXT_RESULTS/SUCCEEDED':
    case 'EVENTS/SAVE_TEXT_ACCESS/FAILED':
    case 'EVENTS/SAVE_TEXT_POLYGON/FAILED':
    case 'EVENTS/SAVE_TEXT_AGREE_ADD/FAILED':
    case 'EVENTS/SAVE_TEXT_SCENARIO/FAILED':
    case 'EVENTS/GET_TEXT_RULES_ADD/FAILED':
    case 'EVENTS/SAVE_TEXT_PRICES/FAILED':
    case 'EVENTS/SAVE_TEXT_RULES_ADD/FAILED':
    case 'EVENTS/SAVE_TEXT_RESULTS/FAILED':
    case 'EVENTS/GET_TEXT_PRICES/FAILED':
    case 'EVENTS/GET_TEXT_RESULTS/FAILED':
    case 'EVENTS/GET_TEXT_POLYGON/FAILED':
    case 'EVENTS/GET_TEXT_ACCESS/FAILED':
    case 'EVENTS/GET_TEXT_SCENARIO/FAILED':
    case 'EVENTS/GET_OFFER/FAILED':
      return {
        ...state,
        loading: state.loading - 1,
      };

    case 'EVENTS/MANAGE_EVENT_REQUEST/FAILED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };
    }

    case 'EVENTS/CHANGE_FIELD': {
      const { value, field } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    case 'EVENTS/GET_TEXT_SCENARIO/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          scenario: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'EVENTS/GET_TEXT_PRICES/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          prices: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'EVENTS/GET_TEXT_RULES_ADD/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          additionalRules: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'EVENTS/GET_TEXT_AGREE_ADD/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          additionalAgreement: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'EVENTS/GET_TEXT_POLYGON/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          polygon: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'EVENTS/GET_OFFER/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          offer: { title: action.payload?.title, text: action.payload?.text },
        },
      };
    }

    case 'EVENTS/GET_TEXT_RESULTS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          results: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'EVENTS/GET_TEXT_ACCESS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventSettings: {
          ...state.eventSettings,
          access: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'EVENTS/CHANGE_TEXT': {
      return {
        ...state,
        eventSettings: {
          ...state.eventSettings,
          [action.payload.field]: {
            ...state.eventSettings[action.payload.field],
            text: action.payload.text,
          },
        },
      };
    }
    case 'EVENTS/CHANGE_CREATE_FIELD': {
      const { value, field } = action.payload;
      return {
        ...state,
        create: {
          ...state.create,
          [field]: value,
        },
      };
    }
    case 'EVENTS/CLEAR_EVENT_PARTICIPANTS':
      return {
        ...state,
        eventParticipants: {
          teams: {
            items: [],
            pagination: {},
          },
          single: {
            items: [],
            pagination: {},
          },
        },
      };
    case 'EVENTS/CLEAR_EVENTS':
      return {
        ...state,
        eventsList: [],
        eventsUserList: [],
        event: {},
        eventParticipants: {
          teams: {
            items: [],
            pagination: {},
          },
          single: {
            items: [],
            pagination: {},
          },
        },
        pagination: {},
        create: {
          sponsor: {},
          sponsors: [],
          sides: sidesInit,
          profile: {},
          type: {},
          location: {
            country: {},
            regionArr: [],
            selectedRegions: [],
            selectedCities: [],
            init: false,
          },
          dates: {},
          size: null,
          close_reg: false,
          choose_side: false,
          payments: paymentsInit(),
          allow: {
            is_club: true,
            is_guest: false,
            is_single: false,
            is_rules: true,
            is_spec_rules: false,
            is_agreement: true,
            is_spec_agreement: false,
          },
        },
        errors: null,
      };
    default:
      return state;
  }
};

const clearEventParticipants = () => (dispatch) => {
  dispatch({
    type: 'EVENTS/CLEAR_EVENT_PARTICIPANTS',
  });
};

export const eventsActions = {
  getEventsList,
  getSponsorEvents,
  getUserEvents,
  clearEvents,
  clearEventParticipants,
  getEvent,
  getEventTypes,
  getEventYears,
  onCreateEvent,
  onChangeField,
  onChangeCreateField,
  onManageEventRequest,
  onGetEventSettingsScenario,
  changeEventEditorText,
  onSaveEventSettingsScenario,
  onSaveEventSettingsPrices,
  onGetEventSettingsPrices,
  onGetEventSettingsAccess,
  onSaveEventSettingsAccess,
  getEventParticipants,
  onGetEventSettingsInfoRulesAdd,
  onSaveEventSettingsRulesAdd,
  onGetEventSettingsInfoAgreeAdd,
  onSaveEventSettingsAgreeAdd,
  onGetEventSettingsPolygon,
  onSaveEventSettingsPolygon,
  onGetEventSettingsResults,
  onSaveEventSettingsResults,
  onGetEventOffer,
};
