import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.769 26.528L27.5054 19.1393C27.9004 18.7619 28.0588 18.2442 27.9806 17.7544C27.928 17.4273 27.7696 17.1126 27.5054 16.8607L19.769 9.47193C19.1098 8.84269 18.0416 8.84269 17.3829 9.47193C16.7238 10.1012 16.7238 11.1215 17.3829 11.7505L22.1593 16.3125H9.66664C8.74588 16.3125 8 17.0681 8 18C8 18.9319 8.74588 19.6875 9.66664 19.6875H22.1593L17.3829 24.2495C16.7238 24.8788 16.7238 25.8988 17.3829 26.528C18.0416 27.1573 19.1098 27.1573 19.769 26.528Z"
      fill="currentColor"
    />
  </svg>
);
