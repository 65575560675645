import { catchWrapper } from '../../../helpers/utility';
import { getEventOffer } from '../../../api/v2';

export default (event, languageId) => async (dispatch) => {
  dispatch({ type: 'EVENTS/GET_OFFER/REQUESTED' });

  const result = await catchWrapper(
    getEventOffer({
      event,
      language_id: languageId,
    }),
  );
  if (result && result.result) {
    dispatch({ type: 'EVENTS/GET_OFFER/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'EVENTS/GET_OFFER/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
