import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../hooks/store';
import { sponsorAction } from '../../../../../../store/sponsor';
import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { globalCancelFetch } from '../../../../../../api/v2';

const Responsibility = ({ sponsorId }) => {
  const { isMobile } = useResponsive();
  const { onGetSponsorSettingsResponsibility } = useActions(sponsorAction);
  const { sponsorSettings } = useSelector((state) => state.sponsor);
  const responsibilityText = sponsorSettings?.responsibility?.text;

  useEffect(() => {
    if (sponsorId) {
      onGetSponsorSettingsResponsibility('9', sponsorId, 'sponsor', '1');
    }

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [sponsorId]);

  return (
    <div>
      {!isMobile && <div className="info-caption">Ответственность на мероприятии</div>}
      {responsibilityText && (
        <div
          className="info-text"
          dangerouslySetInnerHTML={getDangerouslyHtml(responsibilityText)}
        />
      )}
    </div>
  );
};

export default Responsibility;
