import React from 'react';
import { Link } from 'react-router-dom';

import image from '../../../assets/image/user.svg';
import { classNames as cx } from '../../../helpers/utility';

import './styles.scss';

const EntityPreview = ({ item, label, toLink }) => {
  const url = item?.images?.image?.preview;
  const blogUrl = item?.image_avatar?.preview;

  const classNames = cx('entity-preview__image--wrap', {
    'entity-preview__image--empty': !url && !blogUrl,
  });

  return (
    <>
      {!toLink ? (
        <div className="entity-preview">
          <div className={classNames}>
            <img src={blogUrl || url || image} alt="avatar" />
          </div>
          <div className="entity-preview__info">
            <span className="entity-preview__text">{label}</span>
            <span className="entity-preview__value">{item?.nickname || item?.title || ''}</span>
          </div>
        </div>
      ) : (
        <Link to={toLink?.replace(':id', item?.id)} className="entity-preview">
          <div className={classNames}>
            <img src={blogUrl || url || image} alt="avatar" />
          </div>
          <div className="entity-preview__info">
            <span className="entity-preview__text">{label}</span>
            <span className="entity-preview__value">{item?.nickname || item?.title || ''}</span>
          </div>
        </Link>
      )}
    </>
  );
};

export default EntityPreview;
