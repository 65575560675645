import React from 'react';
import Button from '../../../components/button';

const ButtonTags = ({ tags }) => {
  return (
    <div className="blog-tags">
      {tags &&
        tags.map((tag) => (
          <Button key={tag.id} onClick={() => {}} className="filter" variant="filter">
            {tag.title}
          </Button>
        ))}
    </div>
  );
};

export default ButtonTags;
