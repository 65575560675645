import React from 'react';

import './styles.scss';

const HintImage = ({ label, width, height, error }) => {
  return (
    <div className="hint-image">
      {label ? <span className="hint-image__label">{label}</span> : null}
      {width && height ? (
        <span className="hint-image__size">{`${width}px на ${height}px`}</span>
      ) : null}
      {error ? <span className="hint-image__error">{error}</span> : null}
    </div>
  );
};

export default HintImage;
