import React, { useEffect, useState } from 'react';

import Button from '../../components/button';

import './styles.scss';
import ModalHeader from '../components/modalHeaderBlog';
import { getDangerouslyHtml } from '../../helpers/utility';

const TermOfUseModal = ({ onCancel, page }) => {
  const [state, setState] = useState('');
  const title = page === 'terms' ? 'Конфиденциальность' : 'Правила пользования';

  useEffect(() => {
    const id = page === 'terms' ? '1' : '2';

    fetch(`https://api.airsoft.net/v2/page?id=${id}`)
      .then((response) => response.json())
      .then((response) => {
        setState({
          title: response.title,
          text: response.text,
        });
      });
  }, []);

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title={title} />
      <div className="modal-content">
        <div className="info-title" dangerouslySetInnerHTML={getDangerouslyHtml(state?.title)} />
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(state?.text)} />
      </div>
      <div className="modal-footer">
        <Button onClick={onCancel} variant="main">
          Закрыть
        </Button>
      </div>
    </div>
  );
};

export default TermOfUseModal;
