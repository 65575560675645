import moment from 'moment';
import { catchWrapper } from '../../../helpers/utility';
import { getSponsor } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default (id) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/LOAD_SPONSOR/REQUESTED' });

  const payloadRequest = { id };

  const result = await catchWrapper(getSponsor(payloadRequest));

  if (result && result.result) {
    result.sponsor.date = result.sponsor.date
      ? moment(result.sponsor.date).format('YYYY-MM-DD')
      : null;
    dispatch({ type: 'SPONSOR/LOAD_SPONSOR/SUCCEEDED', payload: result.sponsor });
  } else {
    goToNotFound();
    dispatch({
      type: 'SPONSOR/LOAD_SPONSOR/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
