import React from 'react';
import './styles.scss';
import { getDangerouslyHtml } from '../../../../helpers/utility';

const TeamAboutBlock = ({ team }) => {
  return (
    <div className="team-about-block">
      <div className="card team-about-block__content">
        {team?.about ? <div dangerouslySetInnerHTML={getDangerouslyHtml(team.about)} /> : '...'}
      </div>
    </div>
  );
};

export default TeamAboutBlock;
