import React from 'react';
import ImageSettingsActions from '../imageSettingsActions';
import Icon from '../../../../../../components/icon';

const ImageSetting = ({ item, removeImage, setMainImage, hash, disabled }) => {
  const styles = {
    background: `url(${item.image.preview}) center no-repeat`,
    backgroundSize: 'cover',
  };

  const handleRemoveImage = (data) => {
    removeImage({
      id: data,
      ground: item.ground_id,
      hash,
    });
  };
  const handleSetMainImage = (data) => {
    setMainImage({
      id: data,
      ground: item.ground_id,
      hash,
    });
  };

  return (
    <div className="image" style={styles}>
      {!!item.is_main && <Icon type="check" />}
      <ImageSettingsActions
        removeImage={handleRemoveImage}
        setMainImage={handleSetMainImage}
        id={item.id}
        isMain={item.is_main}
        disabled={disabled}
      />
    </div>
  );
};

export default ImageSetting;
