import { sponsorTypeEnum } from '../../../../helpers/enums';

export const getSchema = ({ type, action, sponsorId, teamId }) => {
  if (type === sponsorTypeEnum.gold) {
    return [
      {
        id: '1',
        label: 'Вип 2',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.silver,
          }),
      },
      {
        id: '2',
        label: 'Вип 3',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.bronze,
          }),
      },
      {
        id: '3',
        label: 'Участник',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.participant,
          }),
      },
      {
        id: '4',
        label: 'Гость клуба',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.guest,
          }),
      },
      {
        id: '5',
        label: 'Исключить',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.remove,
          }),
      },
    ];
  }
  if (type === sponsorTypeEnum.silver) {
    return [
      {
        id: '1',
        label: 'Вип 1',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.gold,
          }),
      },
      {
        id: '2',
        label: 'Вип 3',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.bronze,
          }),
      },
      {
        id: '3',
        label: 'Участник',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.participant,
          }),
      },
      {
        id: '4',
        label: 'Гость клуба',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.guest,
          }),
      },
      {
        id: '5',
        label: 'Исключить',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.remove,
          }),
      },
    ];
  }
  if (type === sponsorTypeEnum.bronze) {
    return [
      {
        id: '1',
        label: 'Вип 1',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.gold,
          }),
      },
      {
        id: '2',
        label: 'Вип 2',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.silver,
          }),
      },
      {
        id: '3',
        label: 'Участник',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.participant,
          }),
      },
      {
        id: '4',
        label: 'Гость клуба',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.guest,
          }),
      },
      {
        id: '5',
        label: 'Исключить',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.remove,
          }),
      },
    ];
  }
  if (type === sponsorTypeEnum.participant) {
    return [
      {
        id: '1',
        label: 'Вип 1',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.gold,
          }),
      },
      {
        id: '2',
        label: 'Вип 2',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.silver,
          }),
      },
      {
        id: '3',
        label: 'Вип 3',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.vip,
            vipType: sponsorTypeEnum.bronze,
          }),
      },
      {
        id: '4',
        label: 'Гость клуба',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.guest,
          }),
      },
      {
        id: '5',
        label: 'Исключить',
        action: () =>
          action({
            sponsorId,
            teamId,
            currentType: type,
            type: sponsorTypeEnum.remove,
          }),
      },
    ];
  }
  return [
    {
      id: '1',
      label: 'Вип 1',
      action: () =>
        action({
          sponsorId,
          teamId,
          currentType: type,
          type: sponsorTypeEnum.vip,
          vipType: sponsorTypeEnum.gold,
        }),
    },
    {
      id: '2',
      label: 'Вип 2',
      action: () =>
        action({
          sponsorId,
          teamId,
          currentType: type,
          type: sponsorTypeEnum.vip,
          vipType: sponsorTypeEnum.silver,
        }),
    },
    {
      id: '3',
      label: 'Вип 3',
      action: () =>
        action({
          sponsorId,
          teamId,
          currentType: type,
          type: sponsorTypeEnum.vip,
          vipType: sponsorTypeEnum.bronze,
        }),
    },
    {
      id: '4',
      label: 'Участник',
      action: () =>
        action({
          sponsorId,
          teamId,
          currentType: type,
          type: sponsorTypeEnum.participant,
        }),
    },
    {
      id: '5',
      label: 'Исключить',
      action: () =>
        action({
          sponsorId,
          teamId,
          currentType: type,
          type: sponsorTypeEnum.remove,
        }),
    },
  ];
};
