import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useActions, useSelector } from '../../../../hooks/store';
import { usersActions } from '../../../../store/users';
import { useResponsive } from '../../../../hooks/useResponsive';
import { Pages } from '../../../../routes/pages';
import useDebounce from '../../../../hooks/useDebounce';
import { cancelTokens, globalCancelFetch } from '../../../../api/v2';

import UsersList from '../../components/usersList';
import MobileControls from '../../../components/mobile-controls';
import Trans from '../../../../components/trans';

const pageMainUrl = '/users/mutual_friends';

const CommonFriends = ({ search, onChangeSearch, ...params }) => {
  const {
    rangeName,
    rangeDescription,
    isFilterOpened,
    handleVisibleFilter,
    geoLocal,
    gender,
    age,
    handleChangeGender,
    handleChangeAge,
    handleChangeCountry,
    handleChangeGeo,
    handleClearFilter,
    handleChangeInput,
    navigationMap,
  } = params;
  const { removeFriend, getMutualFriends, clearUsers } = useActions(usersActions);
  const [page, setPage] = useState(1);
  const { users, pagination, loading } = useSelector((state) => state.users);
  const { id: userId } = useParams();
  const { isMobile } = useResponsive();
  const containerEl = useRef(null);
  const debouncedSearchTerm = useDebounce(search, 500);

  const getList = (p) =>
    getMutualFriends({
      id: userId,
      page: p,
      age,
      gender,
      geoLocal,
      query: debouncedSearchTerm || null,
    });

  const onChangeComplete = () => {
    setPage(1);
    clearUsers();
    if (cancelTokens[pageMainUrl]) {
      cancelTokens[pageMainUrl]();
    }
    getList(1);
  };

  useEffect(() => {
    onChangeComplete();
  }, [gender, geoLocal, userId, debouncedSearchTerm, age]);

  useEffect(
    () => () => {
      clearUsers();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const fetchPaginated = () => {
    const newPage = page + 1;
    setPage(newPage);
    getList(newPage);
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  useEffect(() => {
    if (
      users.length &&
      hasMore &&
      document.body.offsetHeight - 220 > containerEl.current.offsetHeight
    ) {
      fetchPaginated();
    }
  }, [users]);

  const handleRemove = (id) => {
    removeFriend(id, true);
  };

  return (
    <>
      {isMobile && (
        <MobileControls
          placeholder={Trans('friends.filter.findPlayersPlaceholder')}
          options={navigationMap}
          needSettings
          handleVisibleFilter={handleVisibleFilter}
          isFilterOpened={isFilterOpened}
          search={search}
          onChangeSearch={onChangeSearch}
        />
      )}
      <UsersList
        containerRef={containerEl}
        users={users}
        fetchPaginated={fetchPaginated}
        hasMore={hasMore}
        loading={loading}
        removeFriend={handleRemove}
        handleChangeGender={handleChangeGender}
        gender={gender}
        handleChangeAge={handleChangeAge}
        age={age}
        handleChangeInput={handleChangeInput}
        handleChangeGeo={handleChangeGeo}
        handleChangeCountry={handleChangeCountry}
        onChangeComplete={onChangeComplete}
        geoLocal={geoLocal}
        rangeName={rangeName}
        rangeDescription={rangeDescription}
        genderFilter
        page={Pages.users.common}
        isFilterOpened={isFilterOpened}
        handleVisibleFilter={handleVisibleFilter}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};
export default CommonFriends;
