import React from 'react';
import { Link } from 'react-router-dom';
import { Pages } from '../../../../routes/pages';
import './styles.scss';
import { getArticleTitle } from '../../../../helpers/utility';

const navMap = ({ id, articlesCount = 0 }) => {
  return [
    {
      id: '1',
      path: Pages.blog.articles.replace(':id', id),
      label: getArticleTitle(articlesCount),
      count: articlesCount,
    },
    // {
    //   id: '2',
    //   path: getUrl(id, Pages.profile.activity),
    //   count: eventsCount,
    //   label: 'Обзоры',
    // },
    // {
    //   id: '3',
    //   path: '/settings/links',
    //   count: '6',
    //   label: 'Отзывы',
    // },
  ];
};

const MobileNav = ({ id, articlesCount }) => {
  return (
    <div className="card mobile-nav">
      {navMap({ id, articlesCount }).map((item) => {
        return (
          <Link key={item.id} className="mobile-nav__link" to={item.path}>
            <span className="mobile-nav__count">{item.count}</span>
            <span className="mobile-nav__label">{item.label}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default MobileNav;
