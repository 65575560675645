export const getAchievementTitle = (location) => {
  const pathStr = location.pathname.split('/').pop();

  switch (pathStr) {
    case 'achievements':
      return 'Достижения';
    case 'awards':
      return 'Награды';
    case 'titles':
      return 'Звания';
    default:
      return 'Достижения';
  }
};
