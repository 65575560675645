import React, { useEffect, useState } from 'react';

import { useActions, useSelector } from '../../hooks/store';
import { authActions } from '../../store/auth';

import ModalHeader from '../components/modalHeaderBlog';
import StepCode from '../securityModal/stepCode';
import Button from '../../components/button';

import './styles.scss';

const AuthEmailConfirmModal = ({ onCancel, user }) => {
  const [code, setCode] = useState('');

  const { onChangeField, submitCode, onResentCode, onLogOut } = useActions(authActions);
  const { error, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'Escape' || e.key === 'Escape') {
        return;
      }
    });
  }, []);

  const handleChangeCode = (value) => {
    if (error) {
      onChangeField(
        {
          login: null,
          register: null,
          emailConfirm: null,
        },
        'error',
      );
    }
    setCode(value);
  };

  const handleSubmit = () => {
    submitCode({ code });
  };

  const handleResentCode = () => {
    onResentCode();
  };

  const handleClickLogout = () => {
    onCancel();
    onLogOut();
  };

  const disabled = !(code?.length === 6) || loading;

  return (
    <div className="modal-wrapper">
      <ModalHeader title="Подтверждения E-mail адреса" />
      <div className="modal-content">
        <span className="modal-content__auth-text">
          Для входа на портал Вам необходимо подтвердить E-mail.
        </span>

        <span className="modal-content__email-text">
          {`На Ваш E-mail адрес ${user.email || ''} был выслан код подтверждения.`}
        </span>
        <StepCode
          onChange={handleChangeCode}
          code={code}
          error={error.emailConfirm}
          action={handleResentCode}
          clearError={handleChangeCode}
          numFields={6}
        />
        <Button onClick={handleClickLogout} className="modal-content__logout" variant="text">
          Выйти из аккаунта
        </Button>
      </div>
      <div className="modal-footer">
        <Button tabIndex="0" variant="main" onClick={handleSubmit} disabled={disabled}>
          Подтвердить
        </Button>
      </div>
    </div>
  );
};

export default AuthEmailConfirmModal;
