import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9312 10C14.0695 10 13.2659 10.2463 12.5698 10.668C11.0354 11.5547 10 13.2347 10 15.1613C10 16.1913 10.2959 17.1508 10.8058 17.9562C10.9456 18.2432 11.1 18.5268 11.2644 18.805C11.9677 19.9951 12.9074 21.1729 13.8731 22.2089C14.84 23.2463 15.8518 24.1618 16.7141 24.824C17.1441 25.1544 17.5497 25.4316 17.9032 25.6304C18.0798 25.7297 18.2557 25.8165 18.424 25.8803C18.5814 25.9399 18.7845 26 19 26C19.2155 26 19.4186 25.9399 19.576 25.8803C19.7443 25.8165 19.9202 25.7297 20.0967 25.6304C20.4503 25.4316 20.856 25.1544 21.2859 24.824C22.1482 24.1618 23.16 23.2463 24.1268 22.2089C25.0926 21.1729 26.0323 19.9951 26.7356 18.805C26.9 18.5268 27.0544 18.2432 27.1942 17.9563C27.7041 17.1508 28 16.1913 28 15.1613C28 13.2347 26.9646 11.5547 25.4302 10.668C24.7341 10.2463 23.9306 10 23.0687 10V10.0017C23.0252 10.0006 22.9814 10 22.9375 10C21.3472 10 19.9281 10.7476 19 11.9169C18.0719 10.7476 16.6528 10 15.0625 10C15.0186 10 14.9749 10.0006 14.9312 10.0017V10Z"
      fill="currentColor"
    />
  </svg>
);
