import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 24C15.7059 24 21 15.5273 21 12.4934C21 9.45947 18.3137 7 15 7C11.6863 7 9 9.45947 9 12.4934C9 15.5273 14.2941 24 15 24ZM14.9988 14.7554C16.1034 14.7554 16.9988 13.8873 16.9988 12.8165C16.9988 11.7458 16.1034 10.8777 14.9988 10.8777C13.8942 10.8777 12.9988 11.7458 12.9988 12.8165C12.9988 13.8873 13.8942 14.7554 14.9988 14.7554Z"
      fill="currentColor"
    />
  </svg>
);
