import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authReducer as auth } from './auth';
import { profileReducer as profile } from './profile';
import { editorReducer as editor } from './editor';

// eslint-disable-next-line import/no-cycle
import { settingsReducer as settings } from './settings';
import { usersReducer as users } from './users';
import { camouflagesReducer as camouflages } from './camouflages';
import { camouflageReducer as camouflage } from './camouflage';
import { geoReducer as geo } from './geo';
import { teamsReducer as teams } from './teams';
import { blogsReducer as blogs } from './blogs';
import { blogReducer as blog } from './blog';
import { sponsorReducer as sponsor } from './sponsor';
import { sidebarReducer as sidebar } from './sideber';
import { eventsReducer as events } from './events';
import { achievementsReducer as achievements } from './achievements';
import { imagePickerReducer as imagePicker } from './imagePicker';
import { pagesReducer as pages } from './pages';
import { groundsReducer as grounds } from './grounds';
import { textPageReducer as textPage } from './textPage';
import { themeReducer as theme } from './theme';
import { socialReducer as social } from './social';
import { msgReducer as msg } from './msg';

const history = createBrowserHistory();

const persistConfig = {
  msg: {
    key: 'msg',
    storage,
    whitelist: ['receiver'],
  },
};

export default combineReducers({
  auth,
  profile,
  settings,
  users,
  camouflages,
  camouflage,
  geo,
  teams,
  sponsor,
  sidebar,
  events,
  achievements,
  imagePicker,
  blogs,
  blog,
  pages,
  editor,
  grounds,
  textPage,
  theme,
  social,
  msg: persistReducer(persistConfig.msg, msg),
  router: connectRouter(history),
});
