import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../hooks/store';
import { sponsorAction } from '../../../../../../store/sponsor';
import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { globalCancelFetch } from '../../../../../../api/v2';

const Entry = ({ sponsorId }) => {
  const { onGetSponsorSettingsEntry } = useActions(sponsorAction);
  const { sponsorSettings } = useSelector((state) => state.sponsor);
  const entryText = sponsorSettings?.entry?.text;
  useEffect(() => {
    if (sponsorId) {
      onGetSponsorSettingsEntry('7', sponsorId, 'sponsor', '1');
    }

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [sponsorId]);

  return (
    <div>
      <div>
        <div className="info-caption">Вступление в клуб</div>
        {entryText && (
          <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(entryText)} />
        )}
      </div>
    </div>
  );
};

export default Entry;
