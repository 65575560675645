import React from 'react';

export default () => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88614 7.64421L13.633 2.07369C14.1223 1.5993 14.1223 0.830174 13.633 0.355789C13.1436 -0.118595 12.3501 -0.118596 11.8607 0.355789L7.00001 5.06736L2.13931 0.355788C1.64992 -0.118596 0.856443 -0.118596 0.367048 0.355788C-0.122349 0.830173 -0.12235 1.5993 0.367048 2.07369L6.11388 7.64421C6.60327 8.1186 7.39674 8.1186 7.88614 7.64421Z"
      fill="currentColor"
    />
  </svg>
);
