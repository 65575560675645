import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import useVisible from '../../../../hooks/useVisible';
import Trans from '../../../trans';
import Button from '../../../button';
import Icon from '../../../icon';

import { classNames as cx, getUrlToNavi } from '../../../../helpers/utility';
import history from '../../../../routes/history';

import './styles.scss';

const TabletNavigation = ({ naviMap }) => {
  const { ref, isVisible, setIsVisible, buttonRef } = useVisible(false, null);
  const { location } = history;
  const [showQty, setShowQty] = useState(3);

  const onResize = () => {
    const width = document.getElementById('header')?.offsetWidth;
    const rightWidth = document.getElementById('header-right')?.offsetWidth;
    const menuWidth = width - rightWidth - 100;
    const qty = Math.floor(menuWidth / (width < 1024 ? 120 : 100));
    setShowQty(qty > 3 ? qty : 3);
  };

  useEffect(() => {
    //TODO переделать
    setTimeout(() => onResize(), 100);

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    setIsVisible(false);
  }, [location.pathname, setIsVisible]);

  const classNames = cx('tablet-navigation tablet-navigation__dropdown', {
    'tablet-navigation__dropdown_open': isVisible,
  });

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const naviMapLength = naviMap && naviMap.length;

  return (
    <div className="tablet-navigation_wrap">
      <div className={classNames}>
        {naviMap.map((item, index) => {
          // eslint-disable-next-line no-shadow
          const { isVisible } = item;
          return !item.hiddenRequest || isVisible
            ? index < showQty && (
                <NavLink
                  className="tablet-navigation__link"
                  activeClassName="tablet-navigation__link_active"
                  key={item.id}
                  exact
                  to={getUrlToNavi(item)}
                >
                  <span>{Trans(item.label)}</span>
                </NavLink>
              )
            : null;
        })}
        {naviMapLength > showQty ? (
          <Button
            refElem={buttonRef}
            onClick={handleToggle}
            className="tablet-navigation--else"
            variant="text"
          >
            <span className="tablet-navigation--else__label">{Trans('navigation.more')}</span>
            <Icon type="chevronSmall" />
          </Button>
        ) : null}

        {isVisible ? (
          <div ref={ref} className="tablet-navigation__dropdown-list">
            {naviMap.map((item, index) => {
              // eslint-disable-next-line no-shadow
              const { isVisible } = item;
              return !item.hiddenRequest || isVisible ? (
                index > showQty - 1 && (
                  <NavLink
                    className="tablet-navigation__link"
                    activeClassName="tablet-navigation__link_active"
                    key={item.id}
                    exact
                    to={getUrlToNavi(item)}
                  >
                    <span>{Trans(item.label)}</span>
                  </NavLink>
                )
              ) : (
                <></>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TabletNavigation;
