import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Pages } from '../../../../routes/pages';
import history from '../../../../routes/history';

import Button from '../../../../components/button';
import Avatar from '../../../components/avatar';

import './styles.scss';
import Trans from '../../../../components/trans';

const ViewRequestPage = () => {
  const { location } = history;

  const { ownerId, item } = location?.state || {};

  useEffect(() => {
    if (!location.state) {
      history.push({
        pathname: Pages.teams.recruiting.all,
      });
    }
  }, []);

  const url =
    item?.user?.id === ownerId
      ? Pages.profile.base
      : {
          pathname: Pages.profile.main.replace(':id?', item?.user?.id),
          state: item?.user?.id,
        };

  return (
    <div className="view-request-page">
      <div className="content">
        <div className="content__header">
          <div className="content__avatar">
            <Avatar
              image={item?.user?.image_avatar?.preview}
              withStatus
              status={item?.user?.state_online}
            />
          </div>
          <div className="content__user-info">
            <Link to={url}>
              <span data-user-name={item?.user?.nickname} className="user-info__name">
                {item?.user?.nickname}
              </span>
            </Link>
            <span className="user-info__value">{`${item?.location?.city?.title || ''}, ${
              item?.user?.age || ''
            } `}</span>
          </div>
          <div className="content__camouflages">
            {item?.camouflages?.map((c) => (
              <div key={c.id} className="content__cam-wrap">
                <img src={c?.image?.preview} alt="c" />
              </div>
            ))}
          </div>
        </div>
        <div className="content__content">
          <span className="content__text">{item?.text}</span>
        </div>
        <div className="footer">
          <Button onClick={() => console.log()} variant="main">
            {Trans('actions.inviteTeam')}
          </Button>
          <Button onClick={() => console.log()} variant="second">
            {Trans('actions.message')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewRequestPage;
