import React, { useLayoutEffect } from 'react';
import { Switch, useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { PrivateRoute } from './privateRoute';
import { Page, Pages } from './pages';

import SettingsPage from '../pages/settings';
import GamesPage from '../pages/events';
import UnAuthGamesPage from '../pages/unAuthEvents';
import ProfileSection from '../pages/profile';
import FriendsPage from '../pages/friends';
import FeedPage from '../pages/feed';
import CamouflagesPage from '../pages/camouflages';
import TeamsPage from '../pages/teams';
import OrgPage from '../pages/sponsors';
import UiKit from '../pages/dev/index';
import BlogSection from '../pages/blog';
import BlogsPage from '../pages/blogs';
import PagesSection from '../pages/pages';
import ErrorPage from '../pages/404';
import GroundsPage from '../pages/grounds';
import AuthPage from '../pages/auth';
import TextPage from '../pages/textPage';
import Chat from '../pages/chat';

export const Routes = () => {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Switch>
      {/*<Route path={Pages.auth.auth} component={Unauthorised} />*/}
      <PrivateRoute exact path={Pages.dev.ui} component={UiKit} />
      <PrivateRoute exact path={Page.privacy} component={TextPage} />
      <PrivateRoute exact path={Page.terms} component={TextPage} />
      <PrivateRoute exact path={Page.cookies} component={TextPage} />
      <PrivateRoute exact path={Page.forUser} component={TextPage} />
      <PrivateRoute exact path={Page.forOrg} component={TextPage} />
      <PrivateRoute exact path={Page.forLandfill} component={TextPage} />

      <PrivateRoute path={Pages.profile.main} component={ProfileSection} />
      <PrivateRoute path={Page.settings} component={SettingsPage} />
      <PrivateRoute path={Pages.users.base} component={FriendsPage} />
      <PrivateRoute path={Pages.teams.base} component={TeamsPage} />
      <PrivateRoute path={Pages.team.base} component={TeamsPage} />
      <PrivateRoute path={Pages.team.settings.base} component={TeamsPage} />
      <PrivateRoute path={Pages.team.create.base} component={TeamsPage} />
      <PrivateRoute path={Pages.organizers.settings.base} component={OrgPage} />
      <PrivateRoute path={Page.organizers} component={OrgPage} />
      <PrivateRoute path={Pages.events.base} component={GamesPage} />
      <PrivateRoute path={Pages.event.base} component={GamesPage} />
      <PrivateRoute path={Pages.event.settings.base} component={GamesPage} />
      <PrivateRoute exact path={Page.feed} component={FeedPage} />
      <PrivateRoute path={Pages.camouflages.base} component={CamouflagesPage} />
      <PrivateRoute path={Pages.camouflage.base} component={CamouflagesPage} />
      <PrivateRoute path={Pages.grounds.base} component={GroundsPage} />

      <PrivateRoute path={Pages.blog.settings.base} component={BlogSection} />
      <PrivateRoute path={Pages.blog.subscribed} component={BlogsPage} />
      <PrivateRoute path={Pages.blog.all} component={BlogsPage} />
      <PrivateRoute path={Pages.blog.base} component={BlogSection} />

      <PrivateRoute path={Pages.pages.base} component={PagesSection} />

      <PrivateRoute path={Pages.msg.base} component={Chat} />
      {/*<PrivateRoute path={Pages.msg.conversations} component={Chat} />*/}

      <PrivateRoute path={Pages.error.notFound} component={ErrorPage} />
    </Switch>
  );
};

export const UnauthRoutes = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Switch>
      <Route path={Pages.auth.auth} component={AuthPage} />
      <Route path={Pages.faq.base} component={AuthPage} />
      <Route path={Pages.events.base} component={UnAuthGamesPage} />
      <Route path={Pages.event.base} component={UnAuthGamesPage} />
      <Route exact path={Page.privacy} component={TextPage} />
      <Route exact path={Page.terms} component={TextPage} />

      <Route exact path={Page.cookies} component={TextPage} />
      <Route exact path={Page.forUser} component={TextPage} />
      <Route exact path={Page.forOrg} component={TextPage} />
      <Route exact path={Page.forLandfill} component={TextPage} />
    </Switch>
  );
};
