import React from 'react';

export default () => (
  <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 1.76471C26 0.790153 25.2724 0 24.375 0H1.625C0.727619 0 0 0.790153 0 1.76471C0 2.73926 0.727619 3.52941 1.625 3.52941H24.375C25.2724 3.52941 26 2.73926 26 1.76471ZM17.3333 10C17.3333 9.02547 16.6057 8.23529 15.7083 8.23529H1.625C0.727619 8.23529 0 9.02547 0 10C0 10.9745 0.727619 11.7647 1.625 11.7647H15.7083C16.6057 11.7647 17.3333 10.9745 17.3333 10ZM20.0417 16.4706C20.9391 16.4706 21.6667 17.2608 21.6667 18.2353C21.6667 19.2098 20.9391 20 20.0417 20H1.625C0.727619 20 0 19.2098 0 18.2353C0 17.2608 0.727619 16.4706 1.625 16.4706H20.0417Z"
      fill="currentColor"
    />
  </svg>
);
