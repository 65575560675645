import React from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../../routes/pages';
//import modalService from '../../services/modalService';
import { useActions } from '../../hooks/store';
import { sidebarActions } from '../../store/sideber';
import Trans from '../trans';

const NavigationFooter = () => {
  const { onToggle } = useActions(sidebarActions);
  const handleToggleSidebar = (bool) => onToggle(bool);

  /*  const openTermsModal = (page) => {
    modalService.openTermOfUse({
      page,
    });
  };*/

  return (
    <div className="navigations-footer" id="navigationFooter">
      <div className="navigations-footer__list">
        <Link
          // onClick={() => openTermsModal('terms')}
          onClick={() => handleToggleSidebar(false)}
          className="navigations-footer__list-link"
          to={Page.privacy}
        >
          {Trans('navigation.privacy')}
        </Link>
        <Link
          // onClick={() => openTermsModal('rules')}
          onClick={() => handleToggleSidebar(false)}
          className="navigations-footer__list-link"
          to={Page.terms}
        >
          {Trans('navigation.agreement')}
        </Link>
        <Link
          // onClick={() => openTermsModal('rules')}
          onClick={() => handleToggleSidebar(false)}
          className="navigations-footer__list-link"
          to={Page.cookies}
        >
          {Trans('navigation.cookies')}
        </Link>
        <Link
          // onClick={() => openTermsModal('rules')}
          onClick={() => handleToggleSidebar(false)}
          className="navigations-footer__list-link"
          to={Page.forUser}
        >
          {Trans('navigation.about')}
        </Link>

        {/*<ul>*/}
        {/*  <li>*/}
        {/*    <Link*/}
        {/*      // onClick={() => openTermsModal('terms')}*/}
        {/*      onClick={() => handleToggleSidebar(false)}*/}
        {/*      className="navigations-footer__list-link small-link"*/}
        {/*      to={Page.forUser}*/}
        {/*    >*/}
        {/*      {Trans('navigation.forPlayer')}*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    <Link*/}
        {/*      // onClick={() => openTermsModal('terms')}*/}
        {/*      onClick={() => handleToggleSidebar(false)}*/}
        {/*      className="navigations-footer__list-link small-link"*/}
        {/*      to={Page.forOrg}*/}
        {/*    >*/}
        {/*      {Trans('navigation.forOrg')}*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    <Link*/}
        {/*      // onClick={() => openTermsModal('terms')}*/}
        {/*      onClick={() => handleToggleSidebar(false)}*/}
        {/*      className="navigations-footer__list-link small-link"*/}
        {/*      to={Page.forLandfill}*/}
        {/*    >*/}
        {/*      {Trans('navigation.forField')}*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*</ul>*/}
      </div>
    </div>
  );
};

export default NavigationFooter;
