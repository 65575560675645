import React, { memo, useMemo } from 'react';
import { t } from 'i18next';
import { classNames as cx } from '../../../../helpers/utility';

function MsgBlockDeleted({ own, className, time }) {
  const text = useMemo(
    () => (own ? `${t('pages.msg.youDeletedMsg')}` : `${t('pages.msg.thisDeletedMsg')}`),
    [own],
  );

  const classNames = cx('msg-block msg-block--deleted', className, {
    'msg-block--own': own,
    'msg-block--deleted-own': own,
  });

  return (
    <div className={classNames}>
      <div className="msg-block__right">
        <div className="msg-block__text-wrap">
          <span className="msg-block__text">{text}</span>
        </div>
        <div className="msg-block__time-wrap">
          <span className="msg-block__time">{time}</span>
        </div>
      </div>
    </div>
  );
}

export default memo(MsgBlockDeleted);
