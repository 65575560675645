import { catchWrapper } from '../../../helpers/utility';
import { postTeams } from '../../../api';

export default (id) => async (dispatch) => {
  dispatch({ type: 'TEAMS/LOAD_TEAM_MEMBERS/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getTeamMembers');
  formData.append('team_id', Number(id));

  const result = await catchWrapper(postTeams('team_users', formData));

  const teamMembers = {
    commander: result.commander,
    deputy: result.deputy,
    users: result.users,
    requests: result.requests,
    groups: result.groups,
    remove: result.remove,
  };

  if (result && result.result) {
    dispatch({ type: 'TEAMS/LOAD_TEAM_MEMBERS/SUCCEEDED', payload: { ...teamMembers } });
  } else {
    dispatch({
      type: 'TEAMS/LOAD_TEAM_MEMBERS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
