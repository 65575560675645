import { catchWrapper } from '../../../helpers/utility';
import { changeTheme } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default (data) => async (dispatch) => {
  dispatch({ type: 'THEME/CHANGE/REQUESTED' });

  const result = await catchWrapper(changeTheme(data));

  if (result && result.result) {
    dispatch({ type: 'THEME/CHANGE/SUCCEEDED', payload: result?.theme });
  } else {
    dispatch({
      type: 'THEME/CHANGE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
