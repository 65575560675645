import React from 'react';

import CustomInput from '../../../../components/input';
import CustomSelect from '../../../../components/select';
import { useActions, useSelector } from '../../../../hooks/store';
import { geoActions } from '../../../../store/geo';

const ContactInfo = ({
  user,
  error,
  onChange,
  geoLocal,
  handleChangeCountrySelect,
  handleChangeRegionSelect,
  handleChangeCitySelect,
}) => {
  const { getCity, getRegion, getCountry } = useActions(geoActions);
  const { countries, regions, cities } = useSelector((state) => state.geo);

  const onMenuOpenCountry = () => getCountry();
  const onMenuOpenRegion = () => getRegion(geoLocal.country);
  const onMenuOpenCity = () => getCity(geoLocal.country, geoLocal.region);

  return (
    <div className="contact-info card">
      <h3 className="settings-passport__right__title">Регион проживания</h3>
      <CustomSelect
        label="Страна"
        placeholder={user?.location?.country?.title || 'Начните вводить'}
        name="country"
        asyncSelect
        value={geoLocal?.country || null}
        options={countries?.options}
        handleChange={handleChangeCountrySelect}
        onMenuOpen={onMenuOpenCountry}
        defaultValue="123"
        required
        labelPosition="top"
        error={error?.country}
      />
      <CustomSelect
        label="Регион"
        placeholder={user?.location?.region?.title || 'Начните вводить'}
        name="region"
        asyncSelect
        value={geoLocal?.region || null}
        options={regions?.options}
        handleChange={handleChangeRegionSelect}
        isDisabled={!geoLocal?.country?.id}
        onMenuOpen={onMenuOpenRegion}
        required
        labelPosition="top"
        error={error?.region}
      />
      <CustomSelect
        label="Город"
        placeholder={user?.location?.city?.title || 'Начните вводить'}
        name="city"
        asyncSelect
        options={cities?.options}
        value={geoLocal?.city || null}
        handleChange={handleChangeCitySelect}
        isDisabled={!geoLocal?.region?.id}
        onMenuOpen={onMenuOpenCity}
        required
        labelPosition="top"
        error={error?.city}
      />
    </div>
  );
};
export default ContactInfo;
