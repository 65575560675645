import React from 'react';
import { Link } from 'react-router-dom';

import gc from '../../../../assets/icon/gameClass.png';
import { rankToName } from '../../../../helpers/utility';

import Avatar from '../../../components/avatar';

import './styles.scss';

const SponsorUserCard = ({ user, rank, isLink, userId: currentUserId }) => {
  const userId = user && user.id;
  const userLink = {
    pathname: currentUserId === userId ? '/profile' : `/profile/${userId}`,
    state: userId,
  };

  const image = user?.image_avatar?.preview;
  const status = user?.state_online;

  return isLink ? (
    <Link to={userLink}>
      <div className="card sponsor-user-card">
        <Avatar image={image} status={status} withStatus />
        <div className="sponsor-user-card__info">
          <span className="sponsor-user-card__info-nickname">{user?.nickname || 'Позывной'}</span>
          <span className="sponsor-user-card__info-rank">{rankToName[rank] || ''}</span>
        </div>
        <div className="sponsor-user-card__class">
          <img src={gc} alt="" />
        </div>
      </div>
    </Link>
  ) : (
    <div className="card sponsor-user-card">
      <Avatar image={image} status={status} withStatus />
      <div className="sponsor-user-card__info">
        <span className="sponsor-user-card__info-nickname">{user?.nickname || 'Позывной'}</span>
        <span className="sponsor-user-card__info-rank">{rankToName[rank] || ''}</span>
      </div>
      <div className="sponsor-user-card__class">
        <img src={gc} alt="" />
      </div>
    </div>
  );
};

export default SponsorUserCard;
