import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';
import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

import { PrivateRoute } from '../../../routes/privateRoute';
import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import InputSearch from '../../../components/inputSearch';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import GroundsList from '../components/groundsList';
import GroundsMainPage from '../components/groundsMainPage';
import GroundsSettings from '../components/groundsSettings';
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import { useActions, useSelector } from '../../../hooks/store';
import { groundsActions } from '../../../store/grounds';
import { editorActions } from '../../../store/editor';
import { getIdFromUrl } from '../../../helpers/utility';
import './styles.scss';
import Trans from '../../../components/trans';

const GroundsSectionDesktop = ({ search, onChangeSearch }) => {
  const { location } = history;
  const groundId = getIdFromUrl(location.pathname);
  const isGroundPage = useRouteMatch(Pages.grounds.main.replace(':id', location.state));
  const { ground, imageLoading, imageActionId } = useSelector((state) => state.grounds);
  const { saveGround } = useActions(groundsActions);
  const { deleteTextEditorImages } = useActions(editorActions);
  const pageTitle = isGroundPage ? ground?.profile?.title : Trans('fields.pageTitle');
  const isShowSearch = !isGroundPage;

  const isSettingPage = !!useRouteMatch(Pages.grounds.settings);
  const isCreatePage = !!useRouteMatch(Pages.grounds.create);

  const handleGoToMainPage = () =>
    history.push({
      pathname: isGroundPage
        ? Pages.grounds.main.replace(':id', location.state)
        : Pages.grounds.base,
      state: location.state,
    });

  const onSubmit = async () => {
    const data = {
      images: [],
      page_id: 1,
      parent_id: Number(groundId),
      type: 'ground',
      language_id: 1,
    };
    await deleteTextEditorImages({ data, type: 'save' });
    await saveGround();
  };

  const isDisabled = () => {
    if (
      imageLoading ||
      imageActionId ||
      !ground?.images?.length ||
      !ground?.profile?.title ||
      !ground?.phone ||
      !ground?.email ||
      !ground?.gps ||
      !ground?.location?.country?.id ||
      !ground?.location?.region?.id ||
      !ground?.location?.city?.id
    )
      return true;
    return false;
  };

  return (
    <>
      <HeaderConstructor className="grounds">
        <HeaderLeft
          title={pageTitle}
          search={isShowSearch ? <InputSearch value={search} onChange={onChangeSearch} /> : null}
        />
        <HeaderRight>
          {!isSettingPage && !isCreatePage ? (
            <>
              <Controls isShowLang />
              <Menu />
            </>
          ) : null}
          {isSettingPage || isCreatePage ? (
            <>
              <Button disabled={isDisabled()} onClick={onSubmit} variant="save">
                {isCreatePage ? Trans('fields.buttonCreate') : Trans('fields.buttonSave')}
              </Button>
              <Button onClick={handleGoToMainPage} className="go-back" variant="icon">
                <Icon type="add" />
              </Button>
            </>
          ) : null}
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.grounds.base}
            component={GroundsList}
            extraProps={{ search, onChangeSearch }}
          />
          <PrivateRoute exact path={Pages.grounds.create} component={GroundsSettings} />
          <PrivateRoute exact path={Pages.grounds.main} component={GroundsMainPage} />
          <PrivateRoute exact path={Pages.grounds.settings} component={GroundsSettings} />
        </Switch>
      </div>
    </>
  );
};

export default GroundsSectionDesktop;
