import React from 'react';
import NumberFormat from 'react-number-format';

import './styles.scss';
import { classNames as cx } from '../../helpers/utility';

const CurrencyInput = ({
  value,
  label,
  extendedLabel,
  labelPosition,
  name,
  placeholder,
  disabled,
  prefix,
  suffix,
  className,
  ...props
}) => {
  const classNames = cx('input__wrap', className, {
    'input-prefix__wrap': prefix,
  });

  const classNamesContainer = cx('input__container', {
    [`input__container__label_${labelPosition}`]: labelPosition,
    [`input__${name}`]: name,
  });

  const classNamesLabel = cx('input__label', {
    [`input__label_${labelPosition}`]: labelPosition,
  });

  return (
    <div className={classNamesContainer}>
      {label && <span className={classNamesLabel}>{label}</span>}
      {extendedLabel && <span className="extended-label">{extendedLabel}</span>}
      <div className={classNames}>
        <NumberFormat
          className="input"
          name={name}
          prefix={prefix}
          suffix={suffix}
          value={value}
          placeholder={placeholder}
          displayType="input"
          thousandSeparator={' '}
          decimalScale={2}
          fixedDecimalScale
          defaultValue={null}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
};

export default CurrencyInput;
