import { catchWrapper } from '../../../helpers/utility';
import { getBlogAlbum } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ albumId }) => async (dispatch) => {
  const payloadRequest = {
    album_id: Number(albumId),
  };
  dispatch({ type: 'BLOG/GET_BLOG_ALBUM/REQUESTED' });
  const result = await catchWrapper(getBlogAlbum(payloadRequest));
  if (result && result.result) {
    dispatch({
      type: 'BLOG/GET_BLOG_ALBUM/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'BLOG/GET_BLOG_ALBUM/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
