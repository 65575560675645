import { catchWrapper } from '../../../helpers/utility';
import { getFullImageInfo } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ id }) => async (dispatch) => {
  const payloadRequest = { id: Number(id) };
  dispatch({ type: 'SOCIAL/GET_FULL_IMAGE_INFO/REQUESTED' });
  const result = await catchWrapper(getFullImageInfo(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'SOCIAL/GET_FULL_IMAGE_INFO/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'SOCIAL/GET_FULL_IMAGE_INFO/FAILED',
      payload: result && result?.message && result.message[0],
    });
  }
};
