import React, { memo, useState } from 'react';
import { Trans } from 'react-i18next';
import Avatar from '../../../../pages/components/avatar';
import { getLastMessageDate } from '../../../../helpers/common';

import './styles.scss';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import ButtonIcon from '../../../../pages/chat/components/buttons/button-icon';
import modalService from '../../../../services/modalService';
import { t } from 'i18next';

function ConversationsItemMobile({ language, setReceiverInfo, deleteConversation, conversation }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { conversationWith, lastMessage, unreadMessageCount } = conversation;
  const handleClick = (e) => {
    console.log(e);
    setReceiverInfo(conversationWith);
    history.push({
      pathname: Pages.msg.base,
    });
  };
  const handleKeyDown = () => null;

  const toggleMenu = () => setIsOpenMenu(!isOpenMenu);

  const onDeleteConversation = () => {
    deleteConversation(conversation);
  };

  const onClickDelete = () => {
    modalService.openConfirmModal({
      title: t('pages.msg.doYouWantDelete'),
      submit: onDeleteConversation,
    });
    setIsOpenMenu(false);
  };

  return (
    <div className="card convo-item-mobile">
      <div
        role="link"
        onKeyDown={handleKeyDown}
        tabIndex={0}
        onClick={handleClick}
        className="convo-item-mobile__wrapper"
      >
        <div className="convo-item-mobile__avatar">
          <Avatar
            image={conversationWith?.avatar}
            status={conversationWith?.status === 'online'}
            withStatus
            hasCount
            count={unreadMessageCount}
          />
        </div>
        <div className="convo-item-mobile__info">
          <span className="convo-item-mobile__title">{conversationWith?.name}</span>
          <span className="convo-item-mobile__text">{lastMessage?.data?.text}</span>
        </div>
      </div>
      <div className="convo-item-mobile__time">
        <span className="convo-item-mobile__text convo-item-mobile__text-time">
          <Trans>{getLastMessageDate(lastMessage.sentAt, language)}</Trans>
        </span>
        <ButtonIcon onClick={toggleMenu} iconName="moreMobile" />
        {isOpenMenu ? (
          <div className="convo-item-mobile__menu card">
            <ButtonIcon onClick={onClickDelete} iconName="delete" />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default memo(ConversationsItemMobile);
