import getCamouflages from './impl/getCamouflages';
import clearCamouflages from './impl/clearCamouflages';
import onChangeField from './impl/onChangeField';
import sendAddCamouflage from './impl/sendAddCamouflage';

const initState = {
  loading: 0,
  camouflages: [],
  error: null,
  errors: null,
  camouflage: {},
};

export const camouflagesReducer = (state = null, action) => {
  switch (action.type) {
    case 'CAMOUFLAGES/LOAD/REQUESTED':
    case 'CAMOUFLAGES/SEND_ADD_CAMOUFLAGE/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case 'CAMOUFLAGES/LOAD/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        camouflages: [...state.camouflages, ...action.payload.camouflages],
        pagination: action.payload.pagination,
      };
    }
    case 'CAMOUFLAGES/LOAD/FAILED':
    case 'CAMOUFLAGES/SEND_ADD_CAMOUFLAGE/SUCCEEDED':
    case 'CAMOUFLAGES/SEND_ADD_CAMOUFLAGE/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: action.payload,
      };
    }
    case 'CAMOUFLAGES/CLEAR': {
      return initState;
    }

    case 'CAMOUFLAGES/CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    default:
      return state;
  }
};

export const camouflageActions = {
  getCamouflages,
  clearCamouflages,
  onChangeField,
  sendAddCamouflage,
};
