import React from 'react';

export default () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0844 5.41642C15.8466 4.38407 14.1022 5.38936 14.1022 7.13509V8.08093C10.688 8.08093 8.3495 9.7199 6.90964 12.3782C5.5073 14.9674 5 18.4401 5 22.0866C5 22.5911 5.35956 23 5.80314 23C6.24668 23 6.60629 22.5911 6.60629 22.0866C6.60629 20.404 7.58908 18.502 9.12671 16.9696C10.6568 15.4447 12.551 14.4747 14.1022 14.4747V15.7251C14.1022 17.4709 15.8466 18.4761 17.0844 17.4438L22.2341 13.1488C23.2553 12.2972 23.2553 10.563 22.2341 9.71139L17.0844 5.41642Z"
      fill="#EB9330"
    />
  </svg>
);
