import React, { useEffect } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { profileActions } from '../../../../store/profile';
import CustomInput from '../../../../components/input';
// import SelectedLanguage from '../../components/selectedLang';
import PortalLanguage from '../../components/portalLang';
import ImagePicker from '../../../../components/imagePicker';
import { classNames as cx } from '../../../../helpers/utility';
import dummy from '../../../../assets/image/user.svg';
import ThemeSwitch from '../../../../components/themeSwitch';

import './styles.scss';

const SettingsProfile = () => {
  const { onUserClone, onChangeField, saveAvatar, onChangeError } = useActions(profileActions);
  const { user, error } = useSelector((state) => state.profile);
  const { image_avatar: imageAvatar } = user;

  useEffect(() => {
    onUserClone();
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    onChangeField(name, value);
    onChangeError({
      ...error,
      [name]: null,
    });
  };

  const urlPreview =
    imageAvatar && imageAvatar.preview ? imageAvatar && imageAvatar.preview : dummy;

  const classNames = cx('card__photo', {
    card__photo_dummy: !(imageAvatar && imageAvatar.preview),
  });

  return (
    <div className="settings-profile settings-profile__wrap">
      <div className="settings-profile__left">
        <div className="settings-profile__card card">
          <div className="card-photo__wrap">
            <img className={classNames} src={urlPreview} alt="" />
            <div className="card__photo-button-wrap">
              <ImagePicker
                aspectRatio={1}
                minHeight={360}
                minWidth={360}
                onSubmit={saveAvatar}
                className="add-photo"
              />
            </div>
          </div>
          <CustomInput
            name="nickname"
            label="Позывной"
            labelPosition="top"
            placeholder="Позывной"
            value={user?.nickname}
            onChange={handleChangeInput}
            maxLength="16"
            error={error?.nickname}
            required
          />
        </div>
        {/*<SelectedLanguage />*/}
        <ThemeSwitch />
        <PortalLanguage />
      </div>
      <div className="settings-profile__right">
        <div className="card">
          <CustomInput
            label="О себе"
            value={user.about}
            name="about"
            placeholder="Напишите что-нибудь о себе"
            type="textarea"
            onChange={handleChangeInput}
          />
        </div>
      </div>
    </div>
  );
};
export default SettingsProfile;
