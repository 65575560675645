import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../hooks/store';

import { sponsorAction } from '../../../../../../store/sponsor';
import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { globalCancelFetch } from '../../../../../../api/v2';

const Rules = ({ sponsorId }) => {
  const { isMobile } = useResponsive();
  const { onGetSponsorSettingsRules } = useActions(sponsorAction);
  const { sponsorSettings } = useSelector((state) => state.sponsor);
  const ruleText = sponsorSettings?.rules?.text;

  useEffect(() => {
    if (sponsorId) {
      onGetSponsorSettingsRules('2', sponsorId, 'sponsor', '1');
    }

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [sponsorId]);

  return (
    <div>
      {ruleText && (
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(ruleText)} />
      )}
    </div>
  );
};

export default Rules;
