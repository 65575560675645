import React from 'react';
import HeaderTitle from '../title';
import { useResponsive } from '../../../../hooks/useResponsive';
import SidebarButton from '../sidebarButton';

const HeaderLeft = ({ children, title, navigation, search }) => {
  const { isDesktop } = useResponsive();

  if (children) {
    return <div className="header__left">{children}</div>;
  }
  return (
    <div className="header__left">
      {!isDesktop ? <SidebarButton /> : null}
      <div className="header__left__leftwrap">
        <div className="header__left__searchwrap">
          <HeaderTitle title={title} />
          {search}
        </div>
        {navigation}
      </div>
    </div>
  );
};

export default HeaderLeft;
