import React from 'react';

export default () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C5.3367 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5367 4.8 0 5.3373 0 6C0 6.6626 0.5367 7.2 1.2 7.2H4.8V10.8C4.8 11.1416 4.943 11.4498 5.1703 11.6683C5.3859 11.8738 5.6789 12 6 12C6.3774 12 6.7126 11.8262 6.9328 11.5547C7.0992 11.3484 7.2 11.086 7.2 10.8V7.2H10.8C11.0929 7.2 11.3626 7.0945 11.5711 6.9193C11.8336 6.699 12 6.3689 12 6C12 5.3373 11.4632 4.8 10.8 4.8H7.2V1.2C7.2 0.5373 6.6632 0 6 0Z"
      fill="currentColor"
    />
  </svg>
);
