import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';
import { countLoginPage } from '../../../api/v2';

export default () => async (dispatch) => {
  dispatch({ type: 'AUTH/GET_COUNT/REQUESTED' });

  const result = await catchWrapper(countLoginPage());

  if (result) {
    const counts = {
      gamers: result.gamers,
      games: result.games,
      sponsors: result.sponsors,
      teams: result.teams,
    };
    dispatch({
      type: 'AUTH/GET_COUNT/SUCCEEDED',
      payload: counts,
    });
  } else {
    dispatch({ type: 'AUTH/GET_COUNT/FAILED' });
  }
};
