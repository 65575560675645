import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useResponsive } from '../../../hooks/useResponsive';
import { Page } from '../../../routes/pages';

import SocialButtons from '../social';
import './styles.scss';
import Skeleton from '../../../components/skeleton';
import AccountHolder from '../../../helpers/accountHolder';
import UserCardInfo from '../../blog/components/userCardInfo';

const BlogInfo = ({ blog, services, loading }) => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (!isEmpty(blog?.social_service) && !isEmpty(services)) {
      let count = 0;
      services.forEach((item) => {
        if (blog?.social_service[item.key]?.value) {
          count += 1;
        }
      });
      setCounter(count);
    }
  }, [services, blog?.social_service]);

  const { isTabletLarge, isDesktop } = useResponsive();
  return (
    <div className="card blog-info">
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div className="blog-info__left">
            <div className="blog-info__left__row">
              <span className="blog-info__left__name">Автор блога</span>
            </div>
            <div className="blog-info__left__row">
              <UserCardInfo blog={blog} ownerId={blog?.id} />
            </div>
          </div>
          {isTabletLarge || isDesktop ? (
            <div className="blog-info__right">
              <div className="blog-info__right__wrap">
                {counter ? (
                  <>
                    <span className="blog-info__right__title">
                      {blog?.social_service && 'Ссылки'}
                    </span>
                    <SocialButtons social={blog?.social_service} services={services} />
                  </>
                ) : (
                  <AccountHolder>
                    <Link className="blog-info__user-no-data" to={Page.settingsLinks}>
                      Добавить ссылки на соц сети
                    </Link>
                  </AccountHolder>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default BlogInfo;
