import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1002 13.9667C25.1002 16.3758 23.8538 18.4994 21.9594 19.7505C22.9835 20.1047 23.9483 20.6059 24.7968 21.26C26.7227 22.7445 28 24.9841 28 27.8999C28 28.4476 27.5922 28.9017 27.0583 28.9859V29H9.1211C8.5019 29 8 28.5075 8 27.9C8 24.9841 9.2773 22.7446 11.2032 21.26C12.0517 20.6059 13.0165 20.1047 14.0407 19.7505C12.1462 18.4994 10.8999 16.3758 10.8999 13.9667C10.8999 10.1191 14.0787 7 18 7C21.9213 7 25.1002 10.1191 25.1002 13.9667Z"
      fill="#C0B4AF"
    />
  </svg>
);
