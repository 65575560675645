import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.77778 7C3.79594 7 3 7.80118 3 8.78947V22.2105C3 23.1989 3.79594 24 4.77778 24H25.2222C26.2041 24 27 23.1989 27 22.2105V8.78947C27 7.80118 26.2041 7 25.2222 7H4.77778ZM18.1111 12.3684C17.6202 12.3684 17.2222 12.769 17.2222 13.2632C17.2222 13.7573 17.6202 14.1579 18.1111 14.1579H22.5556C23.0465 14.1579 23.4444 13.7573 23.4444 13.2632C23.4444 12.769 23.0465 12.3684 22.5556 12.3684H18.1111ZM17.2222 17.7368C17.2222 17.2427 17.6202 16.8421 18.1111 16.8421H22.5556C23.0465 16.8421 23.4444 17.2427 23.4444 17.7368C23.4444 18.231 23.0465 18.6316 22.5556 18.6316H18.1111C17.6202 18.6316 17.2222 18.231 17.2222 17.7368ZM13.3955 14.307C13.3955 15.287 12.8974 16.1502 12.1395 16.6593C12.5491 16.8036 12.9357 17.0073 13.275 17.2731C14.0452 17.8773 14.5556 18.7874 14.5556 19.9736C14.5556 20.0458 14.5386 20.1134 14.5082 20.1735C14.4437 20.3003 14.3233 20.3938 14.1796 20.4211H7.00465C6.7574 20.4211 6.55556 20.2204 6.55556 19.9736C6.55556 18.7874 7.06746 17.8773 7.83767 17.2731C8.17697 17.0073 8.56208 16.8036 8.97325 16.6593C8.21528 16.1502 7.71563 15.287 7.71563 14.307C7.71563 12.7425 8.98668 11.4737 10.5563 11.4737C12.124 11.4737 13.3955 12.7425 13.3955 14.307Z"
      fill="currentColor"
    />
  </svg>
);
