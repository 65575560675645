import history from './history';
import { Pages } from './pages';

export const goToProfile = () => history.push(Pages.profile.base);
export const goToEvents = () => history.push(Pages.events.all);
export const goToGrounds = () => history.push(Pages.grounds.base);
export const goToLogin = () => history.push(Pages.auth.auth);
export const goToNotFound = () => history.replace(Pages.error.notFound);
export const goToTeamSetting = (teamID) =>
  history.replace(Pages.team.settings.main.replace(':id', teamID));
export const gaToSponsorMain = (sponsorID) =>
  history.replace(Pages.organizers.main.replace(':id', sponsorID));
export const goToEvent = (eventId) => history.replace(Pages.event.main.replace(':id', eventId));
export const goToGroundMain = (groundID) =>
  history.push({
    pathname: Pages.grounds.main.replace(':id', groundID),
    state: groundID,
  });
