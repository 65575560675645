import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';
// eslint-disable-next-line import/no-cycle
import { authActions } from '../index';

export default (data) => async (dispatch) => {
  dispatch({ type: 'AUTH/SOCIAL_BINDING/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'processSocial');
  Object.keys(data).forEach((i) => {
    formData.append(i, data[i]);
  });

  const result = await catchWrapper(postSocial('social', formData));

  if (result && result.auth && result.result) {
    batch(() => {
      dispatch({ type: 'AUTH/SOCIAL_BINDING/SUCCEEDED' });
      dispatch(authActions.getSocial());
    });
  } else {
    dispatch({
      type: 'AUTH/SOCIAL_BINDING/FAILED',
    });
  }
};
