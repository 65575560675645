import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.44C6 9.64468 6.65119 9 7.45455 9H28.5455C29.3487 9 30 9.64468 30 10.44V25.56C30 26.3552 29.3487 27 28.5455 27H7.45455C6.65119 27 6 26.3552 6 25.56V10.44ZM18.0822 17.4522L9.54395 10.4081C9.08114 10.0262 8.3932 10.0882 8.00755 10.5463C7.6218 11.0046 7.68431 11.6856 8.1472 12.0675L17.3565 19.6651C17.7531 20.0155 18.3596 20.0349 18.7803 19.6879L28.0132 12.0707C28.4761 11.6889 28.5386 11.0079 28.153 10.5497C27.7672 10.0914 27.0794 10.0295 26.6165 10.4114L18.0822 17.4522Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.95"
        y1="36.2143"
        x2="48.8808"
        y2="31.2247"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E26E43" />
        <stop offset="1" stopColor="#F8CE0E" />
      </linearGradient>
    </defs>
  </svg>
);
