import React from 'react';

import './styles.scss';

const LinksBlock = ({ social, services }) => {
  return (
    <div className="card links-block">
      <span className="links-block__title">Ссылки</span>
      <div className="links-block__row">
        {services &&
          services.map((item) => {
            return social && social[item?.key]?.value ? (
              <a
                key={item.id}
                href={`${social[item?.key]?.value}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={item.icon} alt={`${item?.titlev}`} />
              </a>
            ) : null;
          })}
      </div>
    </div>
  );
};

export default LinksBlock;
