import { catchWrapper } from '../../../helpers/utility';
import { getFullVideoInfo } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ id }) => async (dispatch) => {
  const payloadRequest = { video_id: Number(id) };
  dispatch({ type: 'SOCIAL/GET_FULL_VIDEO_INFO/REQUESTED' });
  const result = await catchWrapper(getFullVideoInfo(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'SOCIAL/GET_FULL_VIDEO_INFO/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'SOCIAL/GET_FULL_VIDEO_INFO/FAILED',
      payload: result && result?.message && result.message[0],
    });
  }
};
