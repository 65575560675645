import { catchWrapper } from '../../../helpers/utility';
import { postSaveTeamProfile } from '../../../api/v2';
import { validationCreateTeam } from '../validation';
import onChangeField from './onChangeField';
import onCheckAuth from '../../auth/impl/checkAuth';

export default () => async (dispatch, getState) => {
  const { team, teamImageTemp } = getState().teams;

  const formData = new FormData();

  formData.append('element', JSON.stringify(team));

  const error = validationCreateTeam({ team });

  if (teamImageTemp.image) {
    const { blob, file } = teamImageTemp.image;
    formData.append('imageBlob', blob);
    formData.append('imageFile', file);
  }
  if (teamImageTemp.photo) {
    const { blob, file } = teamImageTemp.photo;
    formData.append('photoBlob', blob);
    formData.append('photoFile', file);
  }
  if (teamImageTemp.chevron) {
    const { blob, file } = teamImageTemp.chevron;
    formData.append('chevronBlob', blob);
    formData.append('chevronFile', file);
  }

  if (error) {
    dispatch(onChangeField('errors', error));
  } else {
    dispatch({ type: 'TEAMS/SAVE_TEAM_PROFILE/REQUESTED' });

    const result = await catchWrapper(postSaveTeamProfile(formData));

    if (result?.result) {
      dispatch({ type: 'TEAMS/SAVE_TEAM_PROFILE/SUCCEEDED' });
      dispatch(onCheckAuth());
    } else {
      dispatch({
        type: 'TEAMS/SAVE_TEAM_PROFILE/FAILED',
        payload: result?.message && result.message[0],
      });
    }
  }
};
