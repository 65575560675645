import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../../../hooks/store';
import { sponsorAction } from '../../../../store/sponsor';
import { globalCancelFetch } from '../../../../api/v2';

import BlockMain from '../../components/blockMain';
import SponsorDataBlock from '../../components/sponsorData';
import SponsorBannerBlock from '../../components/sponsorBannerBlock';
import SponsorAboutBlock from '../../components/sponsorAboutBlock';

import './styles.scss';
import GalleryComponent from '../../../components/gallery';

const MainOrgPage = () => {
  const { getSponsor, onClearSponsor, cancelRequestJoinSponsor, requestJoinSponsor } = useActions(
    sponsorAction,
  );
  const { id } = useParams();
  const { sponsor, loading, loadingSilent } = useSelector((state) => state.sponsor);
  const { userSponsors } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.profile);
  const userTeam = user.team;
  useEffect(() => {
    getSponsor(id);
  }, [id]);

  useEffect(
    () => () => {
      onClearSponsor();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );
  const sponsorId = userSponsors?.[0]?.id;
  const userSponsorId = userSponsors?.[0]?.user_role;
  return (
    <div className="org-page--main">
      <BlockMain
        sponsor={sponsor}
        loading={loading}
        loadingSilent={loadingSilent}
        role={user?.team_role}
        userSponsors={userSponsors}
        requestJoinSponsor={requestJoinSponsor}
        cancelRequestJoinSponsor={cancelRequestJoinSponsor}
        userTeam={userTeam}
        getSponsor={getSponsor}
      />
      <SponsorBannerBlock sponsor={sponsor} />
      <SponsorAboutBlock sponsor={sponsor} />
      <SponsorDataBlock sponsor={sponsor} />
      <GalleryComponent
        parentId={id}
        parentType="org"
        isOwner={userSponsorId && sponsorId === sponsor?.id}
      />
    </div>
  );
};

export default MainOrgPage;
