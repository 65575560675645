import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';

export default (data) => async (dispatch, getState) => {
  dispatch({ type: 'AUTH/SERVICE_SAVE/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'saveService');
  Object.keys(data).forEach((i) => {
    formData.append(i, data[i]);
  });

  const result = await catchWrapper(postSocial('social_service', formData));

  if (result && result.auth && result.result) {
    const { user } = getState().profile;
    const { services } = getState().auth;
    const newServices = { ...user.social_service };
    const currentService = services.find((s) => s.key === data.service);

    const v = data.value.replace(currentService.base_url, '');
    const newValue = `${currentService.base_url}${v}`;

    newServices[data.service].value = data.value ? newValue : data.value;

    batch(() => {
      dispatch({ type: 'AUTH/SERVICE_SAVE/SUCCEEDED' });
      dispatch({
        type: 'PROFILE/CHANGE_FIELD_USER_AND_SNAPSHOT',
        payload: {
          field: 'social_service',
          value: newServices,
        },
      });
    });
  } else {
    dispatch({
      type: 'AUTH/SERVICE_SAVE/FAILED',
    });
  }
};
