import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b)">
      <circle cx="10" cy="10" r="10" fill="black" fillOpacity="0.5" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6909 13.1829C15.103 13.5943 15.103 14.2613 14.6909 14.6726C14.2787 15.084 13.6105 15.084 13.1983 14.6726L10.0094 11.4897L6.80168 14.6914C6.38947 15.1029 5.72126 15.1029 5.30911 14.6914C4.89696 14.2801 4.89696 13.6131 5.30911 13.2017L8.51685 9.99999L5.30911 6.79828C4.89696 6.3869 4.89696 5.71992 5.30911 5.30854C5.72126 4.89715 6.38947 4.89715 6.80168 5.30854L10.0094 8.51024L13.1983 5.32733C13.6105 4.91595 14.2787 4.91595 14.6909 5.32733C15.103 5.73871 15.103 6.40569 14.6909 6.81708L11.502 9.99999L14.6909 13.1829Z"
      fill="#E57C00"
    />
    <defs>
      <filter
        id="filter0_b"
        x="-2"
        y="-2"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="1" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
      </filter>
    </defs>
  </svg>
);
