import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useVisible from '../../../../hooks/useVisible';
import { classNames as cx } from '../../../../helpers/utility';
import { useResponsive } from '../../../../hooks/useResponsive';

import Button from '../../../../components/button';
import LanguageButton from '../../../components/languageButton';

import './styles.scss';
import Trans from '../../../../components/trans';

const langMap = {
  ru: 'RU',
  en: 'EN',
};

const PortalLanguage = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState('');
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const { isMobile } = useResponsive();

  console.log(i18n);

  useEffect(() => {
    setLang(i18n.language);
  }, []);

  useEffect(() => {
    if (isVisible && isMobile) {
      const target = document.querySelector('.page-body');
      target.scrollTop = target.scrollHeight;
    }
  }, [isVisible]);

  const handleToggle = () => setIsVisible(!isVisible);

  const classNames = cx('portal-lang', {
    'portal-lang--open': isVisible,
  });

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  return (
    <div className={classNames}>
      {/*<span className="portal-lang__text">{Trans("common.lang")}</span>*/}
      <Button onClick={handleToggle} className="portal-lang__action" variant="text">
        {langMap[lang]}
      </Button>
      {isVisible && (
        <div ref={ref} className="portal-lang__dropdown">
          {/*<div className="triangle" />*/}
          <LanguageButton onClick={handleChangeLanguage} lang="ru" label="RU" />
          <LanguageButton onClick={handleChangeLanguage} lang="en" label="EN" />
        </div>
      )}
    </div>
  );
};

export default PortalLanguage;
