import React from 'react';
import Button from '../../../../components/button';

import './styles.scss';

const ChangeSecurityData = ({ label, value, onClick }) => {
  return (
    <div className="change-security-data__wrap">
      <span className="change-security-data__label">{label}</span>
      <span className="change-security-data__value">{value}</span>
      <Button onClick={onClick} className="change-security-data__action" variant="text">
        Изменить
      </Button>
    </div>
  );
};

export default ChangeSecurityData;
