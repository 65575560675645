import React from 'react';
import CustomInput from '../../../components/input';
import Button from '../../../components/button';

import './styles.scss';

const MobileSearchRow = ({ onClose, value, onChange, placeholder = 'Поиск команд' }) => {
  const handleChangeInput = (e) => {
    onChange(e.target.value);
  };

  const handleClose = () => {
    onClose();
    onChange('');
  };

  return (
    <div className="mobile-search">
      <CustomInput
        onChange={handleChangeInput}
        value={value}
        placeholder={placeholder}
        className="mobile-search__input"
      />
      <Button
        onClick={handleClose}
        secondVariant="text"
        variant="done"
        className="mobile-search__cancel"
      >
        Отменить
      </Button>
    </div>
  );
};

export default MobileSearchRow;
