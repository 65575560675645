import React from 'react';

import { Link } from 'react-router-dom';
import { Pages } from '../../../routes/pages';

import { useResponsive } from '../../../hooks/useResponsive';
import modalService from '../../../services/modalService';

import AccountHolder from '../../../helpers/accountHolder';
import Button from '../../../components/button';

import './styles.scss';
import SkeletonAbout from './skeleton';

const BlogAbout = ({ about, isLoading, tags }) => {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  // eslint-disable-next-line consistent-return
  const sliceText = (aboutText) => {
    if (aboutText) {
      if (isDesktop) {
        return aboutText.length >= 281 ? `${aboutText.slice(0, 280)}...` : aboutText;
      }
      if (isTablet) {
        return aboutText.length >= 281 ? `${aboutText.slice(0, 280)}...` : aboutText;
      }
      if (isMobile) {
        return aboutText.length >= 130 ? `${aboutText.slice(0, 130)}...` : aboutText;
      }
    }
  };

  const handleOpen = () =>
    modalService.openReadMore({
      content: about,
    });

  return (
    <div className="blog-about-wrapper">
      <div className="card blog-about-block">
        {isLoading ? (
          <SkeletonAbout />
        ) : (
          <>
            <div className="blog-about-block__top">
              <span className="blog-about-block__title">О блоге</span>
            </div>
            <div className="blog-about-block__bottom">
              {/* eslint-disable-next-line no-nested-ternary */}
              {about ? (
                <>
                  <span className="blog-about-block__text">{sliceText(about)}</span>

                  {about?.length >= 281 ? (
                    <Button
                      onClick={handleOpen}
                      className="blog-about-block__text__more"
                      type="text"
                    >
                      Читать полностью
                    </Button>
                  ) : null}
                </>
              ) : (
                <AccountHolder>
                  <Link className="blog-about-block__user-no-data" to={Pages.blog.settings}>
                    Добавить описание блога
                  </Link>
                </AccountHolder>
              )}
            </div>
          </>
        )}
      </div>
      <div className="blog-filter">
        {!isMobile &&
          tags &&
          tags.map((tag) => (
            <Button key={tag.id} onClick={() => {}} className="filter" variant="filter">
              {tag.title}
            </Button>
          ))}
      </div>
    </div>
  );
};

export default BlogAbout;
