import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../../hooks/store';
import { blogActions } from '../../../store/blog';
import GalleryHeader from './components/galleryHeader';
import modalService from '../../../services/modalService';
import VideoCardWrap from './components/videoCardWrap';
import AlbumCardWrap from './components/albumCardWrap';
import { socialActions } from '../../../store/social';
import './styles.scss';
import { globalCancelFetch } from '../../../api/v2';

const initTypeState = 'video';
const initPage = 1;

const GalleryComponent = ({ parentId, parentType, userPage, videos, isOwner }) => {
  const [type, setType] = useState(initTypeState);
  const [page, setPage] = useState(initPage);
  const { id } = useParams();
  const { blog, paginationAlbums, paginationVideo, albumsList, videoList } = useSelector(
    (s) => s.blog,
  );
  const { userId } = useSelector((s) => s?.auth);
  const { userVideoList, albumList, userPhotos } = useSelector((s) => s?.social);

  const {
    onCreateBlogAlbum,
    getBlogAlbumList,
    clearBlogAlbums,
    getBlogVideoList,
    onCreateBlogVideo,
    clearBlogVideos,
    onEditBlogVideo,
    onEditBlogAlbum,
    onDeleteBlogVideo,
    onDeleteBlogAlbum,
  } = useActions(blogActions);

  const { getUserVideoList, getUserImagesList, getFullImageInfo, getComments } = useActions(
    socialActions,
  );

  useEffect(() => {
    if (type === 'video') {
      clearBlogAlbums();
      if (userPage) {
        if (parentId) {
          getUserVideoList({ page: 1, id: parentId, parentType: type });
        }
      } else if (parentId) {
        getBlogVideoList({ page: 1, parentId, parentType });
      }
    } else {
      clearBlogVideos();
      if (userPage) {
        getUserImagesList({ page: 1, parentId });
      } else {
        getBlogAlbumList({ page: 1, parentId, parentType });
      }
    }
    setPage(initPage);
  }, [type, parentId]);

  const handleToggleGalleryType = useCallback(
    (e) => {
      const { value } = e.target;
      setType(value);
    },
    [setType],
  );

  useEffect(
    () => () => {
      clearBlogVideos();
      clearBlogAlbums();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const fetchPaginated = useCallback(() => {
    if (type === 'video') {
      setPage(page + 1);
      getBlogVideoList({ page: page + 1, parentId, parentType });
    } else {
      setPage(page + 1);
      getBlogAlbumList({ page: page + 1, parentId, parentType });
    }
  }, [getBlogVideoList, getBlogAlbumList, page]);

  const hasMoreVideo =
    (paginationVideo && Number(paginationVideo.current_page)) <
    (paginationVideo && Number(paginationVideo.total_pages));

  const hasMoreAlbums =
    (paginationAlbums && Number(paginationAlbums.current_page)) <
    (paginationAlbums && Number(paginationAlbums.total_pages));

  const handleOpenAddVideoModal = ({ blogId, video, isEdit }) => {
    modalService.openAddBlogVideo({
      onCreateBlogVideo,
      getBlogVideoList,
      blogId: id || blogId,
      clearBlogVideos,
      onEditBlogVideo,
      isEdit,
      video,
      parentType,
      parentId,
    });
  };

  const handleOpenAddAlbumModal = ({ blogId, album, isEdit }) => {
    modalService.openAddBlogAlbum({
      onCreateBlogAlbum,
      getBlogAlbumList,
      blogId: id || blogId,
      clearBlogAlbums,
      onEditBlogAlbum,
      album,
      isEdit,
      parentType,
    });
  };

  const handleDeleteVideo = async (albumId) => {
    await clearBlogVideos();
    await onDeleteBlogVideo({ id: albumId, parent_id: parentId, type: 'blog' });
    if (userPage) {
      if (parentId) {
        await getUserVideoList({ page: 1, id: parentId, parentType: type });
      }
    } else if (parentId) {
      await getBlogVideoList({ page: 1, parentId, parentType });
    }
  };

  const handleDeleteAlbum = async (albumId) => {
    await clearBlogAlbums();
    await onDeleteBlogAlbum({ id: albumId, parent_id: parentId, type: 'blog' });
    await getBlogAlbumList({ page: 1, parentId: id, parentType: 'blog' });
  };

  const handleOpenModal = useMemo(
    () => (type === 'video' ? handleOpenAddVideoModal : handleOpenAddAlbumModal),
    [type],
  );

  return (
    <div className="card gallery gallery__container">
      <GalleryHeader
        isBlogHolder={isOwner}
        toggleType={handleToggleGalleryType}
        type={type}
        onClick={handleOpenModal}
      />
      {userPage ? (
        type === 'video' ? (
          <div className="gallery__content-wrap">
            <VideoCardWrap
              videoList={videos?.length ? videos : videoList || userVideoList}
              fetchPaginated={fetchPaginated}
              hasMore={hasMoreVideo}
              parentId={parentId}
              parentType={parentType}
              isBlogHolder={isOwner}
              openModal={handleOpenAddVideoModal}
              onDeleteVideo={handleDeleteVideo}
              userPage={userPage}
            />
          </div>
        ) : (
          <AlbumCardWrap
            albumsList={userPhotos}
            userPhotos={userPhotos && userPhotos}
            fetchPaginated={fetchPaginated}
            hasMore={hasMoreAlbums}
            parentId={parentId}
            parentType={parentType}
            isBlogHolder={isOwner}
            openModal={handleOpenAddAlbumModal}
            onDeleteAlbum={handleDeleteAlbum}
            userPage={userPage}
            getFullInfo={getFullImageInfo}
          />
        )
      ) : (
        <div className="gallery__content">
          <div className="gallery__content-wrap">
            {type === 'video' ? (
              <VideoCardWrap
                videoList={videos?.length ? videos : videoList || userVideoList}
                fetchPaginated={fetchPaginated}
                hasMore={hasMoreVideo}
                parentId={parentId}
                parentType={parentType}
                isBlogHolder={isOwner}
                openModal={handleOpenAddVideoModal}
                onDeleteVideo={handleDeleteVideo}
                userPage={userPage}
              />
            ) : (
              <AlbumCardWrap
                albumsList={albumsList}
                fetchPaginated={fetchPaginated}
                hasMore={hasMoreAlbums}
                parentId={parentId}
                parentType={parentType}
                isBlogHolder={isOwner}
                openModal={handleOpenAddAlbumModal}
                onDeleteAlbum={handleDeleteAlbum}
                userPage={userPage}
                getFullInfo={getFullImageInfo}
                getComments={getComments}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryComponent;
