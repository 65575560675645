import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2087 4.00136C12.4256 4.00272 11.7918 4.62609 11.7918 5.39644L11.7932 13.0932C11.7932 13.8635 12.4283 14.4869 13.2101 14.4869L24.5845 14.4855C25.3663 14.4855 26 13.8608 26 13.0918L25.9987 5.39372C25.9987 4.62336 25.3649 4 24.5817 4L13.2087 4.00136ZM16.044 6.49073C15.6525 6.49073 15.3356 6.8024 15.3356 7.18758C15.3356 7.57276 15.6525 7.88444 16.044 7.88444H21.7118C22.1034 7.88444 22.4203 7.57276 22.4203 7.18758C22.4203 6.8024 22.1034 6.49073 21.7118 6.49073H16.044ZM15.6788 10.7712C15.7853 10.7086 15.9098 10.6719 16.044 10.6719H21.7118C22.1034 10.6719 22.4203 10.9835 22.4203 11.3687C22.4203 11.7539 22.1034 12.0656 21.7118 12.0656H16.044C15.6525 12.0656 15.3356 11.7539 15.3356 11.3687C15.3356 11.1156 15.4726 10.8937 15.6788 10.7712ZM6.01749 15.524C6.01749 12.9053 8.12768 10.7712 10.7679 10.6732V14.1534C10.7679 14.6216 11.0017 15.0354 11.3615 15.2872C11.594 15.4505 11.8776 15.5471 12.1848 15.5471H15.8987C15.8904 17.2171 15.0256 18.6871 13.7138 19.5555C14.4265 19.8018 15.0976 20.1516 15.687 20.6076C17.0279 21.6419 17.9163 23.2017 17.9163 25.2337C17.9163 25.6162 17.6326 25.932 17.2618 26H4.78043C4.3487 26 4 25.6571 4 25.2337C4 23.2017 4.88836 21.6419 6.2292 20.6076C6.81867 20.1516 7.49116 19.8018 8.20379 19.5555C6.88509 18.683 6.01749 17.2036 6.01749 15.524Z"
      fill="currentColor"
    />
  </svg>
);
