import React, { useState } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { useResponsive } from '../../hooks/useResponsive';
import { getIdFromUrl } from '../../helpers/utility';
import { getEventMaps } from './helpers/navigations';

import EventsSectionDesktop from './desktop';
import EventsSectionMobile from './mobile';

import { Pages } from '../../routes/pages';
import history from '../../routes/history';
import useOwnerData from '../../hooks/useOwnerData';
import { useSelector } from '../../hooks/store';

export const initGeo = {
  country: {},
  regionArr: [],
  selectedRegions: [],
  selectedCities: [],
  init: false,
};
const initSize = null;
const initType = [];
const sizes = [
  { id: 1, title: 'events.size1' },
  { id: 2, title: 'events.size2' },
  { id: 3, title: 'events.size3' },
  { id: 4, title: 'events.size4' },
  { id: 5, title: 'events.size5' },
  { id: 6, title: 'events.size6' },
];
const durations = [
  { id: 1, title: 'events.duration1' },
  { id: 2, title: 'events.duration2' },
  { id: 3, title: 'events.duration3' },
  { id: 4, title: 'events.duration4' },
  { id: 5, title: 'events.duration5' },
];

const EventsSection = () => {
  const { event } = useSelector((state) => state.events);
  const [search, setSearch] = useState('');
  const [duration, setDuration] = useState(initSize);
  const [size, setSize] = useState(initSize);
  const [type, setType] = useState(initType);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(`0${new Date().getMonth() + 1}`.slice(-2));
  const [isFilterOpened, toggleFilter] = useState(false);
  const [geoLocal, setGeoLocal] = useState(initGeo);
  const { isMobile } = useResponsive();
  const { location } = history;
  const isEventMainPage = !!getIdFromUrl(location.pathname);
  //   !!useRouteMatch(
  //   Pages.event.main.replace(':id', getIdFromUrl(location.pathname)),
  // );
  const isEventEditPage = !!useRouteMatch(Pages.event.settings.base);
  const isAllEventsPage = !!useRouteMatch(Pages.events.all);
  const isCreateEventPage = !!useRouteMatch(Pages.event.settings.create);
  const isEventPages = !!useRouteMatch(Pages.event.base);
  const isEventSettingPages = !!useRouteMatch(Pages.event.settings.base);

  const handleChangeSearch = (v) => setSearch(v);

  const handleVisibleFilter = (value) => {
    toggleFilter(value);
  };
  const handleChangeCountry = (value) => {
    setGeoLocal({ ...initGeo, country: value, init: true });
  };
  const handleClearCountry = () => {
    setGeoLocal({ ...initGeo });
  };

  const handleChangeGeo = (value) => {
    const selectedRegions = value
      ? value.filter((item) => item.isRegion).map((item) => item.region_id)
      : [];
    const selectedCities = value ? value.filter((item) => !item.isRegion) : [];
    setGeoLocal({
      ...geoLocal,
      regionArr: value
        ? value.filter((item) => item.isRegion || selectedRegions.indexOf(item.region_id) === -1)
        : value,
      selectedRegions,
      selectedCities,
      init: true,
    });
  };
  const handleClearFilter = () => {
    setType(initType);
    setSize(initSize);
    setGeoLocal({
      ...geoLocal,
      country: {},
      regionArr: [],
      selectedRegions: [],
      selectedCities: [],
      init: false,
    });
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setSize({ ...size, [name]: value });
  };
  const handleChangeSize = (value) => setSize(value);
  const handleChangeType = (value) => setType(value);
  const handleChangeDuration = (value) => setDuration(value);

  const { userType } = useOwnerData();

  const handleChangeYear = (value) => {
    setYear(value);
  };

  const handleChangeMonth = (value) => {
    setMonth(value);
  };

  const params = {
    isFilterOpened,
    geoLocal,
    size,
    type,
    handleVisibleFilter,
    handleChangeType,
    handleChangeSize,
    handleChangeCountry,
    handleChangeGeo,
    handleClearFilter,
    handleChangeInput,
    navigationMap: getEventMaps({
      isAllEventsPage,
      isEventSettingPages,
      location,
      userType,
      event,
      isMobile,
    }),
    isEventMainPage,
    isCreateEventPage,
    isEventEditPage,
    isEventPages,
    search,
    onChangeSearch: handleChangeSearch,
    sizes,
    handleChangeDuration,
    duration,
    durations,
    handleClearCountry,
    handleChangeYear,
    handleChangeMonth,
    year,
    month,
  };

  return isMobile ? <EventsSectionMobile {...params} /> : <EventsSectionDesktop {...params} />;
};

export default EventsSection;
