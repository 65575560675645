import { catchWrapper } from '../../../helpers/utility';
import { getMutualFriendsList } from '../../../api/v2';

export default ({ id, page, age, gender, geoLocal, useFilter, query }) => async (dispatch) => {
  dispatch({ type: 'USERS/LOAD_USERS/REQUESTED' });

  const formData = new FormData();
  formData.append('objects_per_page', '20');

  if (id) {
    formData.append('user_id', id);
  }
  if (query) {
    formData.append('filter[query]', query);
  }
  if (page) {
    formData.append('page', page.toString());
  }
  if (gender && gender !== 'any') {
    formData.append('filter[gender]', gender);
  }
  if (age && age.min) {
    formData.append('filter[age_from]', age.min);
  }
  if (age && age.max) {
    formData.append('filter[age_to]', age.max);
  }

  if (
    geoLocal &&
    geoLocal.country &&
    !(geoLocal && geoLocal.regionArr && geoLocal.regionArr.length)
  ) {
    formData.append('filter[regions][0][country]', geoLocal.country.id || null);
    formData.append('filter[regions][0][region]', null);
    formData.append('filter[regions][0][city]', null);
  }
  if (geoLocal && geoLocal.regionArr && geoLocal.regionArr.length) {
    geoLocal.regionArr.forEach((regOpt, index) => {
      formData.append(`filter[regions][${index}][country]`, geoLocal.country.id);
      formData.append(`filter[regions][${index}][region]`, regOpt.region_id);
      formData.append(`filter[regions][${index}][city]`, !regOpt.isRegion ? regOpt.id : null);
    });
  }
  const result = await catchWrapper(getMutualFriendsList(formData));

  if (result && result.result) {
    if (useFilter) {
      dispatch({ type: 'USERS/LOAD_USERS_FILTER/SUCCEEDED', payload: result });
    } else {
      dispatch({ type: 'USERS/LOAD_USERS/SUCCEEDED', payload: result });
    }
  } else {
    dispatch({
      type: 'USERS/LOAD_USERS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
