import React from 'react';

import { useActions } from '../../hooks/store';
import { imagePickerActions } from '../../store/imagePicker';
import { classNames as cx } from '../../helpers/utility';

import Icon from '../icon';

import './styles.scss';

const ImagePicker = ({
  onChange,
  fieldName,
  className,
  onSubmit,
  aspectRatio,
  minWidth,
  minHeight,
  pass,
  viewRender,
}) => {
  const { onCheckImage } = useActions(imagePickerActions);

  const handleSubmit = (avatar, preview) => {
    onSubmit(avatar, preview, pass);
  };

  const classNames = cx('image-picker', className);
  const classNamesLabel = cx('file-upload', {
    'file-upload--custom': !viewRender,
  });

  const handleChangeFile = (e) => {
    const params = {
      isCropper: true,
      aspectRatio,
      minWidth,
      minHeight,
      onChange,
      fieldName,
      submit: handleSubmit,
    };
    const { files } = e.target;
    onCheckImage({ file: files[0], minWidth, minHeight, params });
    document.getElementById(`file-upload--${fieldName}`).value = '';
  };

  return (
    <div className={classNames}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={`file-upload--${fieldName}`} className={classNamesLabel}>
        {viewRender || <Icon type="photo" />}
      </label>
      <input
        accept="image/x-png,image/gif,image/jpeg, image/jpg"
        onChange={handleChangeFile}
        id={`file-upload--${fieldName}`}
        type="file"
      />
    </div>
  );
};

export default ImagePicker;
