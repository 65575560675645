import React from 'react';

import { classNames as cx } from '../../../helpers/utility';

import './styles.scss';

const DropdownWithSchema = ({ dropRef, schema, id, setIsVisible }) => {
  return (
    <div ref={dropRef} className="drop-container">
      {schema &&
        schema.map((s) => {
          const classNames = cx('drop-btn', {
            'drop-btn_disabled': !s.action,
          });

          const handleClickWithId = () => {
            s.action(id);
            setIsVisible(false);
          };

          const handleClickWithOutId = () => {
            s.action();
            setIsVisible(false);
          };

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <span
              key={s.id}
              onClick={id ? handleClickWithId : handleClickWithOutId}
              className={classNames}
            >
              {s.label}
            </span>
          );
        })}
    </div>
  );
};

export default DropdownWithSchema;
