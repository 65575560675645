import React, { useCallback, useEffect, useState } from 'react';

import CustomCheckbox from '../customCheckbox';
import Avatar from '../../pages/components/avatar';

import './styles.scss';
import CustomInput from '../input';
import InputSearch from '../inputSearch';

const TeamUsers = ({ users, selected, toggleUser, toggleAll }) => {
  const [search, setSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  const filterList = useCallback(
    (e) => {
      const filteredList = users?.filter((u) => {
        return u?.nickname?.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
      });
      setSearch(e.target.value);
      setFiltered(filteredList);
    },
    [search, filtered],
  );

  useEffect(() => {
    setFiltered(users);
  }, [users]);

  return (
    <div className="users-list">
      <div className="users-list__header">
        <InputSearch value={search} onChange={filterList} />
        {search ? null : (
          <div className="selected-all">
            <span>Все</span>
            <CustomCheckbox
              name="all"
              onChange={toggleAll}
              checked={selected?.length === users?.length || false}
            />
          </div>
        )}
      </div>

      <div className="users-list__list-wrap">
        <div className="users-list__list">
          {filtered.map((item) => {
            const checked = selected.find((s) => s === item.id);

            return (
              <div key={item.id} className="users-list__item">
                <div className="users-list__item-left">
                  <Avatar
                    status={item?.state_online}
                    withStatus
                    image={item?.image_avatar?.image}
                  />
                  <span className="users-list__item-name">{item?.nickname}</span>
                </div>
                <div className="users-list__item-right">
                  <CustomCheckbox
                    name={item.id}
                    onChange={(e) => toggleUser(e, item.id)}
                    checked={checked || false}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamUsers;
