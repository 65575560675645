import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 25C20.5228 25 25 20.5229 25 15C25 9.47714 20.5228 5 15 5C9.47717 5 5 9.47714 5 15C5 20.5229 9.47717 25 15 25ZM19.2054 10.7782C18.8351 10.4073 18.2349 10.4073 17.8646 10.7782L15 13.6482L12.1354 10.7782C11.7651 10.4073 11.1649 10.4073 10.7946 10.7782C10.4244 11.1491 10.4244 11.7506 10.7946 12.1215L13.6592 14.9915L10.7777 17.8785C10.4074 18.2495 10.4074 18.8508 10.7777 19.2218C11.148 19.5927 11.7482 19.5927 12.1185 19.2218L15 16.3348L17.8815 19.2218C18.2518 19.5927 18.8521 19.5927 19.2223 19.2218C19.5926 18.8508 19.5926 18.2495 19.2223 17.8785L16.3408 14.9915L19.2054 12.1215C19.5756 11.7506 19.5756 11.1491 19.2054 10.7782Z"
      fill="#FF603E"
    />
  </svg>
);
