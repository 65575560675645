import Lenta from './lenta';
import LentaSmall from './lentaSmall';
import Lenta24 from './lenta24';
import Calendar from './calendar';
import CalendarNg from './calendar-ng';
import CalendarNgSmall from './calendar-ng-small';
import CalendarSmall from './calendar-small';
import Calendar24 from './calendar24';
import Location from './location';
import LocationSmall from './locationSmall';
import Location24 from './location24';

export default {
  calendar: Calendar,
  calendarNg: CalendarNg,
  calendarNgSmall: CalendarNgSmall,
  calendarSmall: CalendarSmall,
  calendar24: Calendar24,
  location: Location,
  locationSmall: LocationSmall,
  location24: Location24,
  lenta: Lenta,
  lentaSmall: LentaSmall,
  lenta24: Lenta24,
};
