import { sponsorTypeEnum } from '../../../../helpers/enums';

export const getSchema = ({ type, action, sponsorId, teamId }) => {
  return [
    {
      id: '1',
      label: 'Вип 1',
      action: () =>
        action({
          sponsorId,
          teamId,
          type: sponsorTypeEnum.vip,
          vipType: sponsorTypeEnum.gold,
        }),
    },
    {
      id: '2',
      label: 'Вип 2',
      action: () =>
        action({
          sponsorId,
          teamId,
          type: sponsorTypeEnum.vip,
          vipType: sponsorTypeEnum.silver,
        }),
    },
    {
      id: '3',
      label: 'Вип 3',
      action: () =>
        action({
          sponsorId,
          teamId,
          type: sponsorTypeEnum.vip,
          vipType: sponsorTypeEnum.bronze,
        }),
    },
    {
      id: '4',
      label: 'Участник',
      action: () =>
        action({
          sponsorId,
          teamId,
          currentType: type,
          type: sponsorTypeEnum.participant,
        }),
    },
    {
      id: '5',
      label: 'Гость клуба',
      action: () =>
        action({
          sponsorId,
          teamId,
          type: sponsorTypeEnum.guest,
        }),
    },
    {
      id: '6',
      label: 'Отменить',
      action: () =>
        action({
          sponsorId,
          teamId,
          type: sponsorTypeEnum.remove,
        }),
    },
  ];
};
