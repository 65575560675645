import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useActions, useSelector } from '../../../../hooks/store';
import { authActions } from '../../../../store/auth';

import Button from '../../../../components/button';
import modalService from '../../../../services/modalService';

import './styles.scss';

const SettingsLinks = () => {
  const { onSaveService, getServices } = useActions(authActions);
  const { services } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  useEffect(() => {
    getServices();
  }, []);

  const handleOpenModal = (key) =>
    modalService.openChangeLinkModal({
      service: key,
      onSave: onSaveService,
    });

  const handleRemoveLink = (service) => {
    const payload = {
      service,
      value: '',
    };
    onSaveService(payload);
  };

  return (
    <div className="settings-links">
      {user.social_service &&
        !isEmpty(services) &&
        services.map((item) => {
          return (
            <div key={item.id} className="settings-links__row settings-links__row_vk">
              <img src={item.icon} className="settings-links__icon" alt={item.title} />
              <span className="settings-links__name">{item.title}</span>

              {user.social_service[item.key].value ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="settings-links__link"
                  href={user.social_service[item.key].value}
                >{`${user.social_service[item.key].value}`}</a>
              ) : null}

              {user.social_service[item.key].value ? (
                <Button
                  onClick={() => handleRemoveLink(item.key)}
                  variant="text"
                  className="settings-links__link settings-links__link_remove"
                >
                  Удалить
                </Button>
              ) : (
                <Button
                  onClick={() => handleOpenModal(item.key)}
                  variant="text"
                  className="settings-links__link settings-links__link_add"
                >
                  Добавить ссылку
                </Button>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default SettingsLinks;
