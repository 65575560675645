import React from 'react';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';

import './styles.scss';

const ChangeSecurityDataMobile = ({ label, value, onClick }) => {
  return (
    <div className="change-security-data-mobile__wrap">
      <span className="change-security-data-mobile__label">{label}</span>
      <div className="block__wrapper">
        <span className="change-security-data-mobile__value">{value}</span>
        <Button onClick={onClick} className="change-security-data-mobile__action" variant="icon">
          <Icon type="edit" />
        </Button>
      </div>
    </div>
  );
};

export default ChangeSecurityDataMobile;
