import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.913 12.94C13.913 13.0679 13.918 13.1946 13.9279 13.32H11.5652C10.7008 13.32 10 14.0005 10 14.84V25.48C10 26.3195 10.7008 27 11.5652 27H26.4348C27.2993 27 28 26.3195 28 25.48V14.84C28 14.0005 27.2993 13.32 26.4348 13.32H24.0722C24.0819 13.1946 24.087 13.0679 24.087 12.94C24.087 10.2117 21.8095 8 19 8C16.1906 8 13.913 10.2117 13.913 12.94ZM15.8696 12.94C15.8696 13.0687 15.8778 13.1955 15.8938 13.32H22.1063C22.1222 13.1955 22.1304 13.0687 22.1304 12.94C22.1304 11.2611 20.7289 9.9 19 9.9C17.2711 9.9 15.8696 11.2611 15.8696 12.94ZM20.5661 18.26C20.5661 18.8673 20.1993 19.3915 19.6691 19.6349C19.6997 19.6853 19.7254 19.741 19.7451 19.8019L20.7114 22.7817C20.8703 23.2714 20.4937 23.77 19.965 23.77H18.0323C17.5036 23.77 17.1271 23.2714 17.2859 22.7817L18.2522 19.8019C18.2722 19.7403 18.2982 19.6842 18.3291 19.6333C17.8008 19.3892 17.4357 18.866 17.4357 18.26C17.4357 17.4205 18.1364 16.74 19.0009 16.74C19.8653 16.74 20.5661 17.4205 20.5661 18.26Z"
      fill="currentColor"
    />
  </svg>
);
