import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { getSchema } from './actionsMap';
import { useActions, useSelector } from '../../../../hooks/store';
import { globalCancelFetch } from '../../../../api/v2';
import { sponsorAction } from '../../../../store/sponsor';
import TeamCard from '../../../teams/components/teamCard';
import useOwnerData from '../../../../hooks/useOwnerData';

import './styles.scss';

const IncomingRequest = () => {
  const { sponsorRequests, loading } = useSelector((state) => state.sponsor);
  const { getSponsorIncomingRequest, onChangeParticipantsRequest } = useActions(sponsorAction);
  const { id } = useParams();
  const { sponsorRole } = useOwnerData();

  useEffect(() => {
    getSponsorIncomingRequest(id);

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  return (
    <div className="sponsor-request-page">
      <div className="sponsor-request-page__incoming">
        {!isEmpty(sponsorRequests) &&
          sponsorRequests.map((r) => {
            return (
              <TeamCard
                key={r.id}
                team={r}
                //isGold
                //type={sponsorTypeEnum.gold}
                //isCamouflage
                isAction={!!sponsorRole}
                action={onChangeParticipantsRequest}
                sponsorId={id}
                getSchema={getSchema}
              />
            );
          })}
      </div>
    </div>
  );
};

export default IncomingRequest;
