import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { getTextPage } from '../../../api/v2';

export default (data) => async (dispatch) => {
  dispatch({ type: 'TEXT/REQUESTED' });
  const result = await catchWrapper(getTextPage(data));

  if (result && result.result) {
    batch(() => {
      dispatch({
        type: 'TEXT/SUCCEEDED',
        payload: {
          title: result.title,
          text: result.text,
          menu: result?.menu,
        },
      });
    });
  } else {
    dispatch({ type: 'TEXT/FAILED' });
  }
};
