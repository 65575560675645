import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 5C13.0222 5 11.0888 5.58649 9.4443 6.6853C7.79981 7.78412 6.51809 9.3459 5.76121 11.1732C5.00433 13.0004 4.8063 15.0111 5.19215 16.9509C5.578 18.8907 6.53041 20.6725 7.92894 22.0711C9.32746 23.4696 11.1093 24.422 13.0491 24.8078C14.9889 25.1937 16.9996 24.9957 18.8268 24.2388C20.6541 23.4819 22.2159 22.2002 23.3147 20.5557C24.4135 18.9112 25 16.9778 25 15C25 13.6868 24.7413 12.3864 24.2388 11.1732C23.7362 9.95991 22.9997 8.85752 22.0711 7.92893C21.1425 7.00035 20.0401 6.26375 18.8268 5.7612C17.6136 5.25866 16.3132 5 15 5ZM19.3 12.61L14.73 18.61C14.6368 18.731 14.5172 18.8291 14.3803 18.8967C14.2433 18.9643 14.0927 18.9996 13.94 19C13.7881 19.0008 13.638 18.967 13.5011 18.9012C13.3643 18.8353 13.2442 18.7392 13.15 18.62L10.71 15.51C10.5469 15.3005 10.4737 15.0347 10.5065 14.7713C10.5393 14.5078 10.6755 14.2681 10.885 14.105C11.0945 13.9419 11.3603 13.8687 11.6237 13.9015C11.8872 13.9343 12.1269 14.0705 12.29 14.28L13.92 16.36L17.7 11.36C17.7801 11.2549 17.8801 11.1667 17.9943 11.1003C18.1085 11.0339 18.2347 10.9906 18.3657 10.973C18.4966 10.9553 18.6297 10.9637 18.7574 10.9975C18.8851 11.0313 19.0049 11.0899 19.11 11.17C19.2151 11.2501 19.3033 11.3501 19.3697 11.4643C19.4361 11.5785 19.4794 11.7047 19.497 11.8356C19.5147 11.9666 19.5063 12.0997 19.4725 12.2274C19.4387 12.3551 19.3801 12.4749 19.3 12.58V12.61Z"
      fill="#469060"
    />
  </svg>
);
