import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { eventsActions } from '../../../../../store/events';
import { useActions, useSelector } from '../../../../../hooks/store';
import { getDangerouslyHtml } from '../../../../../helpers/utility';
import { useResponsive } from '../../../../../hooks/useResponsive';

const Access = () => {
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { onGetEventSettingsAccess } = useActions(eventsActions);
  const { eventSettings } = useSelector((state) => state.events);
  const accessText = eventSettings?.access?.text;

  useEffect(() => {
    if (id) {
      onGetEventSettingsAccess('3', Number(id), 'event', '1');
    }
  }, [id]);

  return (
    <div className="events-text">
      {!isMobile && <div className="info-caption">Допуск</div>}
      {accessText && (
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(accessText)} />
      )}
    </div>
  );
};

export default Access;
