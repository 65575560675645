import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="6" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 18.2858L10.0659 21.2947L10.7174 20.6553L7.6515 17.6464L7 18.2858ZM19.0719 10.9517L17.1174 12.8698L16.9066 14.5811L11.3689 20.0159L8.303 17.007L13.8408 11.5723L15.5845 11.3654L17.539 9.44725L19.0719 10.9517ZM18.842 8.16848C20.145 6.88971 21.563 7.00254 21.563 7.00254C21.6779 8.39414 20.3749 9.67291 20.3749 9.67291L19.7234 10.3123L18.1905 8.80786L18.842 8.16848ZM10.216 25.2389L14.0484 29L14.8915 28.1725L11.0591 24.4114L10.216 25.2389ZM25.7371 15.6481L23.2077 18.1304L22.9011 20.3118L15.7346 27.3451L11.9022 23.584L19.0688 16.5507L21.2915 16.2499L23.8209 13.7675L25.7371 15.6481ZM25.5071 12.1126C27.1934 10.4578 28.9946 10.5706 28.9946 10.5706C29.1095 12.3383 27.4233 13.9932 27.4233 13.9932L26.5802 14.8206L24.664 12.9401L25.5071 12.1126Z"
      fill="white"
    />
  </svg>
);
