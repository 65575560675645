import React from 'react';
import { isEmpty } from 'lodash';

import Avatars from '../avatars';

import './styles.scss';

const SubscribersBlock = ({ subscribers, totalSubscribers, friends }) => {
  return !isEmpty(subscribers) ? (
    <div className="subscribers-block">
      <div className="subscribers-block__top">
        {totalSubscribers && totalSubscribers ? (
          <span className="subscribers-block__text">
            {totalSubscribers}, {friends}
          </span>
        ) : null}
      </div>
      <Avatars friends={subscribers} totalFriends={subscribers.length} />
    </div>
  ) : (
    <div className="subscribers-block__add">
      <span className="subscribers-block__text">{totalSubscribers}</span>
    </div>
  );
};

export default SubscribersBlock;
