import React, { useEffect, useRef, useState } from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { usersActions } from '../../../../store/users';
import { Pages } from '../../../../routes/pages';
import useDebounce from '../../../../hooks/useDebounce';
import { globalCancelFetch } from '../../../../api/v2';

import UsersList from '../../components/usersList';

const IncomingFriends = ({ search, ...params }) => {
  const { isFilterOpened, handleVisibleFilter, handleClearFilter } = params;
  const { getIncomingFriends, acceptIncoming, cancelIncomingFriends, clearUsers } = useActions(
    usersActions,
  );
  const [page, setPage] = useState(1);
  const { incoming, incomingPagination, loading } = useSelector((state) => state.users);

  const debouncedSearchTerm = useDebounce(search, 500);
  const containerEl = useRef(null);

  useEffect(() => {
    getIncomingFriends({ page, query: debouncedSearchTerm || null });
    setPage(1);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    return () => {
      clearUsers();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const fetchPaginated = () => {
    setPage(page + 1);
    getIncomingFriends({ page: page + 1, query: debouncedSearchTerm || null });
  };

  const hasMore =
    (incomingPagination && Number(incomingPagination.current_page)) <
    (incomingPagination && Number(incomingPagination.total_pages));

  const handleAddFriend = async (id) => {
    await acceptIncoming(id);
  };

  const handleCancelRequest = async (id) => {
    await cancelIncomingFriends(id);
  };

  useEffect(() => {
    if (
      incoming.length &&
      hasMore &&
      document.body.offsetHeight - 220 > containerEl.current.offsetHeight
    ) {
      fetchPaginated();
    }
  }, [incoming]);

  return (
    <UsersList
      containerRef={containerEl}
      users={incoming}
      fetchPaginated={fetchPaginated}
      hasMore={hasMore}
      loading={loading}
      addFriend={handleAddFriend}
      removeFriend={handleCancelRequest}
      disableFilters
      page={Pages.users.requests}
      isFilterOpened={isFilterOpened}
      handleVisibleFilter={handleVisibleFilter}
      handleClearFilter={handleClearFilter}
    />
  );
};
export default IncomingFriends;
