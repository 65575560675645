import { useState, useEffect, useRef } from 'react';

export default function useVisible(initialIsVisible, callback) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      ((buttonRef.current && !buttonRef.current.contains(event.target)) || !buttonRef.current)
    ) {
      if (callback) {
        callback();
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isVisible, setIsVisible, buttonRef };
}
