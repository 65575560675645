import onChangeField from './onChangeField';
import { catchWrapper } from '../../../helpers/utility';
import { postSupport } from '../../../api/v2';
import modalService from '../../../services/modalService';
import { validationSponsorForm } from '../validation';

export default (params) => async (dispatch) => {
  const error = validationSponsorForm(params);

  if (error) {
    dispatch(onChangeField('errors', error));
  } else {
    dispatch({ type: 'PAGES/SEND_REQUEST_SPONSOR/REQUESTED' });

    const result = await catchWrapper(postSupport('add_sponsor', params));

    if (result?.result) {
      dispatch({ type: 'PAGES/SEND_REQUEST_SPONSOR/SUCCEEDED' });
      modalService.closeAll();
      modalService.openNotificationsModal({
        type: 'success',
        content: result?.message && result.message[0],
      });
    } else {
      dispatch({ type: 'PAGES/SEND_REQUEST_SPONSOR/FAILED' });
    }
  }
};
