import React from 'react';
import './styles.scss';
import FeedFilterCollapsed from '../../components/mobile-controls';
import NewsCard from '../../components/newsCard';

const feedFilterOptions = [
  {
    id: '1',
    label: 'Новости',
    path: '/feed',
  },
  {
    id: '2',
    label: 'Команда',
    path: '/feed',
  },
  {
    id: '3',
    label: 'Блок',
    path: '/feed',
  },
  {
    id: '4',
    label: 'Орггруппа',
    path: '/feed',
  },
  {
    id: '5',
    label: 'Друзья',
    path: '/feed',
  },
];
const MobileFeedPage = ({ userData }) => {
  return (
    <div className="page-feed-mobile">
      <FeedFilterCollapsed options={feedFilterOptions} />
      <NewsCard userData={userData} />
      <NewsCard userData={userData} />
    </div>
  );
};

export default MobileFeedPage;
