import React, { memo } from 'react';

import { Link } from 'react-router-dom';
import { Pages } from '../../../routes/pages';

import modalService from '../../../services/modalService';

import AccountHolder from '../../../helpers/accountHolder';
import SkeletonAbout from './skeleton';
import ClampLines from '../../../components/clampLines';

import './styles.scss';

const About = ({ about, isLoading }) => {
  const handleOpen = () =>
    modalService.openReadMore({
      content: about,
    });

  return (
    <div className="card about-block">
      {isLoading ? (
        <SkeletonAbout />
      ) : (
        <>
          <div className="about-block__top">
            <span className="about-block__title">О себе</span>
          </div>
          <div className="about-block__bottom">
            {/* eslint-disable-next-line no-nested-ternary */}
            {about ? (
              <>
                <ClampLines
                  className="about-content"
                  text={about}
                  lines={4}
                  moreText="Читать полностью"
                  id="about"
                  action={handleOpen}
                />
              </>
            ) : (
              <AccountHolder>
                <Link className="about-block__user-no-data" to={Pages.settings.profile}>
                  Расскажите немного о себе
                </Link>
              </AccountHolder>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(About);
