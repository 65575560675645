import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2481 12.5701C22.2481 14.6902 21.1513 16.5589 19.4842 17.6599C20.3855 17.9716 21.2345 18.4126 21.9811 18.9883C23.6759 20.2946 24.8 22.2654 24.8 24.8314C24.8 25.3133 24.4411 25.713 23.9713 25.7871V25.7995H8.18652C7.64163 25.7995 7.19995 25.3661 7.19995 24.8315C7.19995 22.2655 8.32397 20.2947 10.0187 18.9883C10.7655 18.4126 11.6145 17.9716 12.5158 17.6599C10.8486 16.5589 9.75182 14.6902 9.75182 12.5701C9.75182 9.18426 12.5492 6.43945 16 6.43945C19.4507 6.43945 22.2481 9.18426 22.2481 12.5701Z"
      fill="#C0B4AF"
    />
  </svg>
);
