import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill="#3F65A6"
    />
    <path
      d="M16.9609 13.4216C16.9609 13.9998 16.9609 16.5779 16.9609 16.5779H14.6484V20.4373H16.9609V31.0154H21.7109V20.4294H24.8984C24.8984 20.4294 25.1953 18.5779 25.3437 16.5544C24.9297 16.5544 21.7344 16.5544 21.7344 16.5544C21.7344 16.5544 21.7344 14.3123 21.7344 13.9138C21.7344 13.5232 22.25 12.9919 22.7656 12.9919C23.2734 12.9919 24.3516 12.9919 25.3516 12.9919C25.3516 12.4685 25.3516 10.6482 25.3516 8.97632C24.0156 8.97632 22.5 8.97632 21.8359 8.97632C16.8437 8.98413 16.9609 12.8435 16.9609 13.4216Z"
      fill="white"
    />
  </svg>
);
