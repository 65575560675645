import { catchWrapper } from '../../../helpers/utility';
import { postUserAction } from '../../../api';

export default (id, extra) => async (dispatch) => {
  dispatch({ type: 'USERS/REMOVE_FRIEND/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'removeFriend');
  formData.append('user_id', id);

  const result = await catchWrapper(postUserAction('friends', formData));

  if (result && result.result) {
    dispatch({ type: 'USERS/REMOVE_FRIEND/SUCCEEDED', payload: id, extra });
  } else {
    dispatch({
      type: 'USERS/REMOVE_FRIEND/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
