import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { classNames as cx } from '../helpers/utility';

import './generalModalStyles.scss';

const DumbModal = ({
  show,
  className,
  children,
  onCancel,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
}) => {
  const [cropper, setCropper] = useState(false);
  const classNames = cx('modal-container', className);
  const handleCloseModal = () => onCancel();

  useEffect(() => {
    const { isCropper } = children && children.props;
    setCropper(isCropper);
  }, []);

  const handleControl = cropper ? () => null : handleCloseModal;

  return (
    <Modal
      onRequestClose={handleControl}
      overlayClassName="modal-overlay"
      className={classNames}
      isOpen={show}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      {children}
    </Modal>
  );
};

export default DumbModal;
