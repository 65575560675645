import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';

import { classNames as cx } from '../../../../helpers/utility';
import { getLocations } from '../../../../store/selectors';
import { Pages } from '../../../../routes/pages';

import './styles.scss';
import Trans from '../../../../components/trans';

// without this line it didn't work
// moment.locale('ru');

const TeamDataRow = ({ label, value, image }) => {
  const classNames = cx('team-data__value', {
    'team-data__value-m': image,
  });
  return (
    <div className="team-data__row">
      <span className="team-data__label">{label}</span>
      {/*{image ? (*/}
      {/*  <div className="team-data__image">*/}
      {/*    <img src={image} alt="" />*/}
      {/*  </div>*/}
      {/*) : null}*/}
      <span className={classNames}>{value}</span>
    </div>
  );
};

const TeamDataRowWithLink = ({ label, value, image, userId }) => {
  const classNames = cx('team-data__value', {
    'team-data__value-m': image,
  });

  const linkPayload = {
    pathname: Pages.profile.main.replace(':id?', userId),
    state: userId,
  };

  return (
    <div className="team-data__row">
      <span className="team-data__label">{label}</span>

      <Link to={linkPayload}>
        {image ? (
          <div className="team-data__image">
            <img src={image} alt="" />
          </div>
        ) : null}
        <span className={classNames}>{value}</span>
      </Link>
    </div>
  );
};

const TeamDataBlock = ({ team }) => {
  const { city, country } = getLocations(team?.location);

  return (
    <div className="card team-data__container">
      {city && country && (
        <>
          <TeamDataRow label={Trans('common.country')} value={country} />
          <TeamDataRow label={Trans('common.city')} value={city} />
        </>
      )}
      <TeamDataRowWithLink
        label={Trans('teams.personnel.commander')}
        image={team?.commander?.image_avatar?.preview}
        value={team?.commander?.nickname}
        userId={team?.commander?.id}
      />
      <TeamDataRow label={Trans('teams.main.games')} value={team?.qty_of_games} />
      <TeamDataRow
        label={Trans('teams.main.date')}
        value={moment(team?.date).locale('ru').format('DD MMMM YYYY')}
      />
    </div>
  );
};

export default TeamDataBlock;
