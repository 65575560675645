import React from 'react';
import Trans from '../../../../components/trans';

import './styles.scss';

const MonthBreak = ({ month, year }) => {
  return (
    <div className="month-break">
      <span>
        {Trans(`months.month${month}`)} {year}
      </span>
    </div>
  );
};

export default MonthBreak;
