import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';
import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

import EventMainPage from './main';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import Controls from '../../../components/headerConstructor/components/controls';
import HeaderRight from '../../../components/headerConstructor/components/right';
import InputSearch from '../../../components/inputSearch';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';

import AllPage from '../../events/desktop/all';
import PricesPage from '../../events/desktop/prices';
import Scenario from '../../events/desktop/eventsPages/scenario';
import Access from '../../events/desktop/eventsPages/access';
import AgreementsEvents from '../../events/desktop/eventsPages/agreement';
import InfoEventPage from '../../events/desktop/eventsPages/info';
import Polygon from '../../events/desktop/eventsPages/polygon';
import Results from '../../events/desktop/eventsPages/results';
import Offer from '../../events/desktop/eventsPages/offer';

import './styles.scss';
import ChangeEventView from '../../events/components/changeEventView';


const getTitle = (location, event) => {
  if (location.pathname.indexOf(Pages.event.eventBase.replace(':id', location.state)) !== -1) {
    return event?.profile?.title || 'Игра';
  }
  switch (location.pathname) {
    case Pages.events.create:
      return 'Новая игра';
    default:
      return 'Игры';
  }
};

const UnAuthEventsSectionDesktop = (rest) => {
  const { search, onChangeSearch, navigationMap, isEventMainPage, isCreateEventPage } = rest;
  const { event } = useSelector((s) => s.events);

  const { location } = history;

  const isShowSearch = !(isEventMainPage || isCreateEventPage);
  const isShowNavigation = !isCreateEventPage;

  return (
    <>
      <HeaderConstructor className="events">
        <HeaderLeft
          title={getTitle(location, event)}
          search={
            isShowSearch ? (
              <>
                <InputSearch value={search} onChange={onChangeSearch} />
                <ChangeEventView />
              </>
            ) : null
          }
          navigation={isShowNavigation ? <TabletNavigation naviMap={navigationMap} /> : null}
        />
        <HeaderRight>
          <>
            <Controls isShowLang />
          </>
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <Route
            exact
            path={Pages.events.all}
            render={(props) => <AllPage {...props} {...rest} />}
          />

          <Route
            exact
            path={Pages.event.price}
            component={PricesPage}
            //extraProps={{ search, ...rest }}
          />

          <Route exact path={Pages.event.main} component={EventMainPage} />
          <Route exact path={Pages.event.scenario} component={Scenario} />
          <Route exact path={Pages.event.allow} component={Access} />
          <Route exact path={Pages.event.agreement} component={AgreementsEvents} />
          <Route exact path={Pages.event.info} component={InfoEventPage} />
          <Route exact path={Pages.event.location} component={Polygon} />
          <Route exact path={Pages.event.results} component={Results} />
          <Route exact path={Pages.event.offer} component={Offer} />

          <Redirect to={navigationMap[0].path} />
        </Switch>
      </div>
    </>
  );
};

export default UnAuthEventsSectionDesktop;
