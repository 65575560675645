import { catchWrapper } from '../../../helpers/utility';
import { getEventsList } from '../../../api/v2';

export default ({ size, type, duration, geoLocal, url, page, query, year, month }) => async (
  dispatch,
) => {
  dispatch({ type: 'EVENTS/GET_EVENTS_LIST/REQUESTED' });

  const payloadRequest = {
    page,
    objects_per_page: 12,
    filter: {
      query,
      year,
      month,
      event_type: type || null,
      size,
      duration,
      regions:
        geoLocal?.country && !geoLocal?.regionArr?.length
          ? [
              {
                country: geoLocal.country.id || null,
                region: null,
                city: null,
              },
            ]
          : geoLocal?.regionArr && geoLocal?.regionArr?.length
          ? geoLocal.regionArr.map((item) => {
              return {
                country: geoLocal.country.id,
                region: item.region_id,
                city: item.isRegion !== true ? item.id : null,
              };
            })
          : null,
    },
  };

  const result = await catchWrapper(getEventsList(url, payloadRequest));

  if (result && result.result) {
    dispatch({ type: 'EVENTS/GET_EVENTS_LIST/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'EVENTS/GET_EVENTS_LIST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
