import uuid from 'react-uuid';
import { isEmpty } from 'lodash';

export const setPaymentsValue = (arr, field, value, id) => {
  return arr.map((t) => {
    if (t.id === id) {
      return {
        ...t,
        [field]: value,
      };
    }
    return t;
  });
};

export const typeNames = {
  base: '1',
  regions: '2',
  foreigners: '3',
  gold: '4',
  silver: '5',
  bronze: '6',
  driver: '7',
};

export const typesToName = {
  '1': 'Стоимость участия',
  '2': 'Для регионов',
  '3': 'Для иностранцев',
  '4': 'Золотой статус',
  '5': 'Серебряный статус',
  '6': 'Бронзовый статус',
  '7': 'Водитель',
};

export const sidesInit = [
  {
    id: uuid(),
    title: null,
    qty: null,
    excess: null,
  },
  {
    id: uuid(),
    title: null,
    qty: null,
    excess: null,
  },
];
export const paymentsInit = () => {
  return {
    base: {
      isVisible: true,
      payments: [
        {
          id: uuid(),
          type: 1,
          price: null,
          default_price: null,
          date: null,
          isDefaultPrice: true,
        },
      ],
    },
    regions: {
      isVisible: false,
      payments: [
        {
          id: uuid(),
          type: 2,
          price: null,
          default_price: null,
          date: null,
          isDefaultPrice: true,
        },
      ],
    },
    foreigners: {
      isVisible: false,
      payments: [
        {
          id: uuid(),
          type: 3,
          price: null,
          default_price: null,
          date: null,
          isDefaultPrice: true,
        },
      ],
    },
    gold: {
      isVisible: false,
      payments: [
        {
          id: uuid(),
          type: 4,
          price: null,
          default_price: null,
          date: null,
          isDefaultPrice: true,
        },
      ],
    },
    silver: {
      isVisible: false,
      payments: [
        {
          id: uuid(),
          type: 5,
          price: null,
          default_price: null,
          date: null,
          isDefaultPrice: true,
        },
      ],
    },
    bronze: {
      isVisible: false,
      payments: [
        {
          id: uuid(),
          type: 6,
          price: null,
          default_price: null,
          date: null,
          isDefaultPrice: true,
        },
      ],
    },
    driver: {
      isVisible: false,
      payments: [
        {
          id: uuid(),
          type: 7,
          price: null,
          default_price: null,
          date: null,
          isDefaultPrice: true,
        },
      ],
    },
  };
};

export const datesInit = {
  eventDateStart: null,
  eventDateEnd: null,
  eventDateStartInst: null,
  eventDateEndInst: null,
  timeStart: null,
  timeStartInst: null,
  timeEnd: null,
  timeEndInst: null,
  arrivalDate: null,
  arrivalDateInst: null,
  departureDate: null,
  departureDateInst: null,
  arrivalTime: null,
  arrivalTimeInst: null,
  departureTime: null,
  departureTimeInst: null,
  regDate: null,
  regDateInst: null,
};

export const allowMap = [
  {
    id: '1',
    label: 'Клуб(ы)',
    name: 'is_club',
  },
  {
    id: '2',
    label: 'Гости',
    name: 'is_guest',
  },
  {
    id: '3',
    label: 'Одиночки',
    name: 'is_single',
  },
];
export const rulesMap = [
  {
    id: '1',
    label: 'Правила клуба',
    name: 'is_rules',
  },
  {
    id: '2',
    label: 'Дополнительные правила',
    name: 'is_spec_rules',
  },
];
export const agreementMap = [
  {
    id: '1',
    label: 'Базовое соглашение',
    name: 'is_agreement',
  },
  {
    id: '2',
    label: 'Специальное соглашение',
    name: 'is_spec_agreement',
  },
];

export const getDataToEdit = (event, cb) => {
  cb(event?.sponsors, 'sponsors');

  const profile = {
    title: event?.profile?.title,
    location_description: event?.profile?.location_description,
  };

  cb(profile, 'profile');

  const location = {
    ...event.location,
    init: true,
  };

  cb(location, 'location');
  cb(event?.type, 'type');

  const dates = {
    eventDateStart: event.date_start ? new Date(event.date_start) : null,
    timeStart: event.date_start ? new Date(event.date_start) : null,
    eventDateEnd: event.date_end ? new Date(event.date_end) : null,
    timeEnd: event.date_end ? new Date(event.date_end) : null,

    regDateStart: event.date_reg_start ? new Date(event.date_reg_start) : null,
    regTimeStart: event.date_reg_start ? new Date(event.date_reg_start) : null,
    regDateEnd: event.date_reg_end ? new Date(event.date_reg_end) : null,
    regTimeEnd: event.date_reg_end ? new Date(event.date_reg_end) : null,
  };

  if (event.date_arrival) {
    dates.arrivalDate = new Date(event.date_arrival);
    dates.arrivalTime = new Date(event.date_arrival);
  }

  if (event.date_departure) {
    dates.departureDate = new Date(event.date_departure);
    dates.departureTime = new Date(event.date_departure);
  }

  cb(dates, 'dates');

  cb(event.size, 'size');
  cb(event.close_reg, 'close_reg');
  cb(event.choose_side, 'choose_side');

  const prices = paymentsInit();

  const getPrices = () => {
    const base = [];
    const regions = [];
    const foreigners = [];
    const gold = [];
    const silver = [];
    const bronze = [];
    const driver = [];

    // eslint-disable-next-line no-unused-expressions
    event?.prices.forEach((p) => {
      if (p.type === 1) {
        base.push(p);
      }
      if (p.type === 2) {
        regions.push(p);
      }
      if (p.type === 3) {
        foreigners.push(p);
      }
      if (p.type === 4) {
        gold.push(p);
      }
      if (p.type === 5) {
        silver.push(p);
      }
      if (p.type === 6) {
        bronze.push(p);
      }
      if (p.type === 7) {
        driver.push(p);
      }
    });

    if (!isEmpty(base)) {
      const t = base.map((b, index) => {
        if (index === 0) {
          return {
            ...b,
            isDefaultPrice: true,
          };
        }
        return b;
      });
      prices.base.payments = t;
    }
    if (!isEmpty(regions)) {
      const t = regions.map((b, index) => {
        if (index === 0) {
          return {
            ...b,
            isDefaultPrice: true,
          };
        }
        return b;
      });
      prices.regions.payments = t;
      prices.regions.isVisible = true;
    }
    if (!isEmpty(foreigners)) {
      const t = foreigners.map((b, index) => {
        if (index === 0) {
          return {
            ...b,
            isDefaultPrice: true,
          };
        }
        return b;
      });
      prices.foreigners.payments = t;
      prices.foreigners.isVisible = true;
    }
    if (!isEmpty(gold)) {
      const t = gold.map((b, index) => {
        if (index === 0) {
          return {
            ...b,
            isDefaultPrice: true,
          };
        }
        return b;
      });
      prices.gold.payments = t;
      prices.gold.isVisible = true;
    }
    if (!isEmpty(silver)) {
      const t = silver.map((b, index) => {
        if (index === 0) {
          return {
            ...b,
            isDefaultPrice: true,
          };
        }
        return b;
      });
      prices.silver.payments = t;
      prices.silver.isVisible = true;
    }
    if (!isEmpty(bronze)) {
      const t = bronze.map((b, index) => {
        if (index === 0) {
          return {
            ...b,
            isDefaultPrice: true,
          };
        }
        return b;
      });
      prices.bronze.payments = t;
      prices.bronze.isVisible = true;
    }
    if (!isEmpty(driver)) {
      const t = driver.map((b, index) => {
        if (index === 0) {
          return {
            ...b,
            isDefaultPrice: true,
          };
        }
        return b;
      });
      prices.driver.payments = t;
      prices.driver.isVisible = true;
    }
  };

  getPrices();

  const allow = {
    is_club: event.is_club,
    is_guest: event.is_guest,
    is_single: event.is_single,
    is_rules: event.is_rules,
    is_spec_rules: event.is_spec_rules,
    is_agreement: event.is_agreement,
    is_spec_agreement: event.is_spec_agreement,
  };

  cb(prices, 'payments');
  cb(event.sides, 'sides');
  cb(allow, 'allow');
  cb(event?.id, 'id');
};
