import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { localeContent } from '../../../../locale/ru';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import modalService from '../../../../services/modalService';

import CategoryCard from '../components/categoryCard';
import useOwnerData from '../../../../hooks/useOwnerData';

import './styles.scss';
import OrgCard from '../../../sponsors/components/orgCard';

const getCards = ({ user, team, sponsorId, userSponsors, sponsorAction, isSponsor, blogId }) => {
  const content = localeContent.Pages.category.cards;

  return [
    {
      id: '1',
      icon: 'teamLarge',
      title: content.team.title,
      content: content.team.content,
      btnText: content.team.buttonText,
      text: content.team.text,
      isShowButton: !team && !isEmpty(user),
      action: () => history.push(Pages.team.create.main),
    },
    {
      id: '2',
      icon: 'sponsor',
      title: content.org.title,
      content: content.org.content,
      btnText: isSponsor ? content.org.buttonTextCreate : content.org.buttonTextRequest,
      text: isSponsor ? content.org.text : '',
      isShowButton: isSponsor && !sponsorId && isEmpty(userSponsors),
      isShowModalButton: !isSponsor && !sponsorId && isEmpty(userSponsors),
      action: isSponsor
        ? () => history.push(Pages.organizers.settings.create)
        : () => sponsorAction(),
    },
    {
      id: '3',
      icon: 'blog',
      title: content.blog.title,
      content: content.blog.content,
      btnText: content.blog.buttonText,
      text: content.blog.text,
      isShowButton: !blogId && !isEmpty(user),
      action: () => history.push(Pages.blog.createBlog),
    },
  ];
};

const CategoryPage = () => {
  const { team, user, isSponsor, userSponsors, sponsorId, loading, blogId } = useOwnerData();

  const handleOpenSponsorModal = () => {
    modalService.openBidSponsor({});
  };

  const cards = useMemo(
    () =>
      getCards({
        user,
        team,
        sponsorId,
        userSponsors,
        sponsorAction: handleOpenSponsorModal,
        isSponsor,
        blogId,
      }),
    [user, team, sponsorId, userSponsors, isSponsor, handleOpenSponsorModal],
  );

  return (
    <div>
      <div className="pages-category pages-category__grid">
        {cards.map((card) => (
          <CategoryCard
            loading={loading}
            icon={card.icon}
            key={card.id}
            title={card.title}
            content={card.content}
            btnText={card.btnText}
            text={card.text}
            isShowButton={card.isShowButton || card.isShowModalButton}
            action={card.action}
          />
        ))}
      </div>
      {userSponsors && (
        <>
          <h4>Мои страницы</h4>
          <h5>Организаторы:</h5>
          <div className="org-all-page">
            <div className="org__cards">
              {userSponsors.map((item) => (
                <OrgCard key={item.id} sponsor={item} userId={user.id} />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryPage;
