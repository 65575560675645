import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../../routes/privateRoute';

import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';
import { useResponsive } from '../../../hooks/useResponsive';

import SettingsProfile from './profile';
import SettingsPassport from './passport';
import SettingsLinks from './links';
import SettingPrivate from './private';
import SettingsSecurity from './security';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import HeaderConstructor from '../../../components/headerConstructor';

import Button from '../../../components/button';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';
import Icon from '../../../components/icon';

import './styles.scss';
import Trans from '../../../components/trans';

const SettingsPageDesktop = ({ navigationMap, onSubmit, disabled, onUserClone, isShowButton }) => {
  const handleGoTo = () => {
    onUserClone();
    history.push(Pages.profile.base);
  };

  return (
    <>
      <HeaderConstructor className="settings">
        <HeaderLeft title="Настройки" navigation={<TabletNavigation naviMap={navigationMap} />} />
        <HeaderRight>
          <>
            {/*<ThemeSwitch />*/}
            {isShowButton ? (
              <Button disabled={disabled} onClick={onSubmit} variant="save">
                {Trans('actions.save')}
              </Button>
            ) : null}
            <Button onClick={handleGoTo} className="go-back" variant="icon">
              <Icon type="add" />
            </Button>
          </>
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute exact path={Pages.settings.profile} component={SettingsProfile} />
          <PrivateRoute exact path={Pages.settings.links} component={SettingsLinks} />
          <PrivateRoute exact path={Pages.settings.private} component={SettingPrivate} />
          <PrivateRoute exact path={Pages.settings.security} component={SettingsSecurity} />
          <PrivateRoute exact path={Pages.settings.passport} component={SettingsPassport} />

          <Redirect to={navigationMap[0].path} />
        </Switch>
      </div>
    </>
  );
};

export default SettingsPageDesktop;
