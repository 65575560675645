import React from 'react';
import { Link } from 'react-router-dom';

import { Pages } from '../../routes/pages';

import ModalHeader from '../components/modalHeaderBlog';
import Button from '../../components/button';
import Avatar from '../../pages/components/avatar';

import './styles.scss';

const RecruitingRequestViewModal = ({ onCancel, item, ownerId }) => {
  const url =
    item?.user?.id === ownerId
      ? Pages.profile.base
      : {
          pathname: Pages.profile.main.replace(':id?', item?.user?.id),
          state: item?.user?.id,
        };

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title="Заявка" />
      <div className="modal-content">
        <div className="modal-content__header">
          <div className="modal-content__avatar">
            <Avatar
              image={item?.user?.image_avatar?.preview}
              withStatus
              status={item?.user?.state_online}
            />
          </div>
          <div className="modal-content__user-info">
            {/*<Link to={url}>*/}
            <span data-user-name={item?.user?.nickname} className="user-info__name">
              {item?.user?.nickname}
            </span>
            {/*</Link>*/}
            <span className="user-info__value">{`${item?.location?.city?.title || ''}, ${
              item?.user?.age || ''
            } `}</span>
          </div>
          <div className="modal-content__camouflages">
            {item?.camouflages?.map((c) => {
              return (
                <div key={c.id} className="modal-content__cam-wrap">
                  <img src={c?.image?.preview} alt="c" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="modal-content__content">
          <span className="modal-content__text">{item?.text}</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button onClick={() => console.log()} variant="main">
          Пригласить в команду
        </Button>
        <Button onClick={() => console.log()} variant="second">
          Написать
        </Button>
      </div>
    </div>
  );
};

export default RecruitingRequestViewModal;
