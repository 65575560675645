import React from 'react';
import HeaderConstructor from '../../components/headerConstructor';
import HeaderLeft from '../../components/headerConstructor/components/left';
import SidebarButton from '../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../components/headerConstructor/components/title';
import HeaderRight from '../../components/headerConstructor/components/right';
import Controls from '../../components/headerConstructor/components/controls';
import Menu from '../../components/headerConstructor/components/menu';
import { useResponsive } from '../../hooks/useResponsive';

import './styles.scss';

import move from './404.webm';
import Trans from '../../components/trans';

const ErrorPage = () => {
  const { isDesktop, isMobile } = useResponsive();
  return (
    <>
      <HeaderConstructor className="teams">
        <HeaderLeft>
          {!isDesktop ? <SidebarButton /> : null}
          {!isMobile ? <HeaderTitle title={Trans('404.notFound')} /> : null}
        </HeaderLeft>
        <HeaderRight>
          <Controls isShowLang />
          <Menu />
        </HeaderRight>
      </HeaderConstructor>
      <div className="error-page">
        {/*<span className="error-page__code--404">404</span>*/}
        {/*<h2 className="error-page__title">Страница не найдена</h2>*/}
        <div className="video-wrap">
          <video autoPlay muted>
            <source src={move} type="video/webm" />
          </video>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
