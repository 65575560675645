import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5572 3.7919H13.4936C13.7767 3.7919 14.0088 3.56566 14.0088 3.28957V2.50234C14.0088 2.22624 13.7767 2 13.4936 2H10.5572C10.274 2 10.042 2.22624 10.042 2.50234V3.28957C10.0416 3.56566 10.2736 3.7919 10.5572 3.7919Z"
      fill="currentColor"
    />
    <path
      d="M12 4.44727C9.50917 4.44727 7.25251 5.43979 5.62103 7.0398L7.57777 8.41735C8.75723 7.37874 10.3139 6.74025 12.0249 6.74025C15.6913 6.74025 18.6742 9.64868 18.6742 13.2237C18.6742 16.7987 15.6918 19.7071 12.0249 19.7071C8.35806 19.7071 5.37568 16.7991 5.37568 13.2237C5.37568 11.6421 5.9609 10.1925 6.92982 9.06591L5.46033 7.21284C3.9397 8.7852 3 10.8972 3 13.2237C3 18.0631 7.03724 22.0001 12 22.0001C16.9628 22.0001 21 18.0631 21 13.2237C21 8.38425 16.9628 4.44727 12 4.44727Z"
      fill="currentColor"
    />
    <path
      d="M12.0671 14.664C11.6117 14.664 11.2103 14.4511 10.9491 14.1264L10.9465 14.1298L7.69431 10.0286C7.00296 10.9176 6.58789 12.0211 6.58789 13.2202C6.58789 16.1429 9.0263 18.5201 12.0237 18.5201C15.0211 18.5201 17.4591 16.1425 17.4591 13.2202C17.4591 10.298 15.0207 7.91992 12.0237 7.91992C10.7188 7.91992 9.52 8.37114 8.58202 9.12151L12.8006 12.0911L12.798 12.0945C13.2109 12.3366 13.4894 12.7736 13.4894 13.2776C13.4898 14.0431 12.8534 14.664 12.0671 14.664Z"
      fill="currentColor"
    />
  </svg>
);
