import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../../hooks/store';

import { sponsorAction } from '../../../../../../../store/sponsor';
import { getDangerouslyHtml } from '../../../../../../../helpers/utility';
import { useResponsive } from '../../../../../../../hooks/useResponsive';

const PolygonEdit = () => {
  const { isMobile } = useResponsive();
  const { onGetSponsorSettingsRules } = useActions(sponsorAction);
  const { sponsorSettings } = useSelector((state) => state.sponsor);
  const { event } = useSelector((s) => s.events);
  const sponsorId = event?.sponsor?.id;
  const ruleText = sponsorSettings?.rules?.text;

  useEffect(() => {
    if (sponsorId) {
      onGetSponsorSettingsRules('2', sponsorId, 'sponsor', '1');
    }
  }, [sponsorId]);

  return (
    <div>
      {!isMobile && <div className="info-caption">Правила</div>}
      {ruleText && (
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(ruleText)} />
      )}
    </div>
  );
};

export default PolygonEdit;
