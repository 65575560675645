import React from 'react';
import { useSelector } from '../../hooks/store';
import Light from './light';
import Dark from './dark';

// const Light = React.lazy(() => import('./light'));
// const Dark = React.lazy(() => import('./dark'));

const ThemeSelector = ({ children }) => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <>
      {theme === 1 ? <Light /> : null}
      {theme === 0 ? <Dark /> : null}

      {children}
    </>
  );
};

export default ThemeSelector;
