import React from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useSelector } from '../../hooks/store';
import { useResponsive } from '../../hooks/useResponsive';

import Logo from '../logo';
import Button from '../button';
import Icon from '../icon';

import { Page, Pages } from '../../routes/pages';

const NavigationsHeader = ({ closeMenu }) => {
  const { isMobile, isTablet } = useResponsive();
  const { user } = useSelector((state) => state.profile);

  return (
    <div className="navigations-header" id="navigationHeader">
      <Link to={isEmpty(user) ? Pages.auth.auth : Page.profile}>
        <Logo />
      </Link>
      {isMobile || isTablet ? (
        <Button onClick={closeMenu} variant="icon" className="navigations-header__close">
          <Icon type="cross" />
        </Button>
      ) : null}
    </div>
  );
};

export default NavigationsHeader;
