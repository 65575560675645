import React, { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';

import uuid from 'react-uuid';

import { useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../../../hooks/store';
import { geoActions } from '../../../../store/geo';
import { goToProfile } from '../../../../routes/actions';
import { eventsActions } from '../../../../store/events';
import {
  agreementMap,
  allowMap,
  getDataToEdit,
  rulesMap,
  setPaymentsValue,
  typeNames,
} from './helpers';

import EventInfo from './components/eventInfo';
import EventBannerSelection from './components/eventBannerSelection';
import loadOptions from './loadOptions';
import Payments from './components/payments';
import DateSection from './components/dateSection';
import CountUsers from './components/countUsers';
import SideSelect from './components/sideSelect';
import CheckBoxesSection from './components/checkBoxesSection';
import useOwnerData from '../../../../hooks/useOwnerData';

import './styles.scss';

const CreateEventPage = () => {
  const { id } = useParams();

  const { userSponsors, user } = useSelector((s) => s.profile);
  const { types, eventTempImage, create, errors, event } = useSelector((s) => s.events);
  const { countries, regions, cities } = useSelector((s) => s.geo);

  const { sponsorId, sponsorRole, isLoading } = useOwnerData();

  const { getCountry, getRegion, getCity } = useActions(geoActions);
  const { getEventTypes, getEvent, clearEvents, onChangeField, onChangeCreateField } = useActions(
    eventsActions,
  );

  const isAccess = sponsorId && sponsorRole;

  useEffect(() => {
    if (id) {
      getEvent(id);
    }

    return () => {
      clearEvents();
    };
  }, []);

  useEffect(() => {
    if (event?.id) {
      getDataToEdit(event, onChangeCreateField);
    }
  }, [event]);

  useEffect(() => {
    if (!isEmpty(userSponsors)) {
      onChangeCreateField(userSponsors && userSponsors[0], 'sponsor');
    }

    if (!isAccess && user.id && !isLoading) {
      goToProfile();
    }
  }, [userSponsors]);

  useEffect(() => {
    getEventTypes();
  }, []);

  const handleRemoveTempImage = (name) => {
    const tmp = {
      ...eventTempImage,
      [name]: null,
    };
    onChangeCreateField(true, 'previewRemove');
    onChangeField(tmp, 'eventTempImage');
  };

  const handleChangeImage = (name, image) => {
    const tmp = {
      ...eventTempImage,
      [name]: image,
      previewRemove: false,
    };

    onChangeCreateField(false, 'previewRemove');
    onChangeField(tmp, 'eventTempImage');
  };

  const handleChangeProfile = useCallback((e) => {
    const { name, value } = e.target;

    const tmp = { ...create.profile, [name]: value };
    onChangeCreateField(tmp, 'profile');
  });

  const handleChangeInputSides = useCallback((e) => {
    const { value, name, dataset } = e.target;

    const sides = create?.sides.map((s) => {
      if (s.id === dataset.field) {
        return {
          ...s,
          [name]: value,
        };
      }
      return s;
    });

    onChangeCreateField(sides, 'sides');
  });

  const handleChangePaymentsInput = ({ values, field, section, itemId }) => {
    const tmp = create.payments[section].payments;

    const p = {
      ...create.payments,
      [section]: {
        ...create.payments[section],
        payments: setPaymentsValue(tmp, field, values.value, itemId),
      },
    };

    onChangeCreateField(p, 'payments');
  };

  const handleChangePaymentsCheckbox = useCallback((e) => {
    const { name, checked } = e.target;

    const p = {
      ...create.payments,
      [name]: {
        ...create.payments[name],
        isVisible: checked,
      },
    };

    onChangeCreateField(p, 'payments');
  });

  const handleChangeDatePayments = (date, section) => {
    const item = {
      id: uuid(),
      type: typeNames[section],
      price: null,
      date,
    };

    const tmp = [...create.payments[section].payments, item];

    const p = {
      ...create.payments,
      [section]: {
        ...create.payments[section],
        payments: tmp,
      },
    };
    onChangeCreateField(p, 'payments');
  };

  const handleChangeInput = useCallback((e) => {
    const { name, value } = e.target;

    const tmp = { ...create, [name]: value };

    onChangeField(tmp, 'create');
  });

  const handleChangeCheckbox = useCallback((e) => {
    const { name, checked } = e.target;

    const tmp = { ...create, [name]: checked };

    onChangeField(tmp, 'create');
  });

  const handleChangeAllowCheckbox = useCallback((e) => {
    const { name, checked } = e.target;

    const tmp = { ...create.allow, [name]: checked };

    onChangeCreateField(tmp, 'allow');
  });

  const handleChangeDate = (date, name) => {
    let tmp;
    if (name === 'eventDateStart') {
      tmp = {
        ...create.dates,
        [name]: date,
        eventDateEnd: null,
        timeEnd: null,
      };
    } else if (name === 'arrivalDate') {
      tmp = {
        ...create.dates,
        [name]: date,
        departureDate: null,
        departureTime: null,
      };
    } else if (name === 'regDateStart') {
      tmp = {
        ...create.dates,
        [name]: date,
        regDateEnd: null,
        regTimeEnd: null,
      };
    } else {
      tmp = {
        ...create.dates,
        [name]: date,
      };
    }
    onChangeCreateField(tmp, 'dates');
  };

  const handleChangeGeo = (value, name) => {
    if (name === 'country') {
      const tmp = {
        ...create.location,
        country: value,
        region: {},
        city: {},
        init: true,
      };
      onChangeCreateField(tmp, 'location');
    }
    if (name === 'region') {
      const tmp = {
        ...create.location,
        region: value,
        city: {},
      };
      onChangeCreateField(tmp, 'location');
    }
    if (name === 'city') {
      const tmp = {
        ...create.location,
        city: value,
      };
      onChangeCreateField(tmp, 'location');
    }
  };

  const handleSetSponsor = (value) => {
    const tmp = [...create.sponsors, value];
    onChangeCreateField(tmp, 'sponsors');
  };

  const handleRemoveSponsor = (sponsorId) => {
    const selectedSponsors = create.sponsors.filter((s) => s.id !== sponsorId);
    onChangeCreateField(selectedSponsors, 'sponsors');
  };

  const handleSetType = (value) => {
    onChangeCreateField(value, 'type');
  };

  const handleAddSide = useCallback(() => {
    const item = {
      id: uuid(),
      value: null,
      qty: null,
      excess: null,
    };
    const sides = [...create?.sides, item];

    onChangeCreateField(sides, 'sides');
  });

  const handleRemoveSide = (sideId) => {
    const sides = create?.sides?.filter((s) => s.id !== sideId);
    onChangeCreateField(sides, 'sides');
  };

  const onMenuOpenCountry = () => getCountry();
  const onMenuOpenRegion = () => getRegion(create?.location?.country);
  const onMenuOpenCity = () => getCity(create?.location?.country, create?.location?.region);

  return (
    <div className="create-event-page">
      <EventBannerSelection
        errors={errors}
        imagesState={eventTempImage}
        eventImage={event?.images}
        onChange={handleChangeImage}
        removeTempImage={handleRemoveTempImage}
        isPreviewRemove={create.previewRemove}
      />
      <EventInfo
        errors={errors}
        sponsor={create?.sponsor}
        sponsors={create?.sponsors}
        onChangeProfile={handleChangeProfile}
        profile={create?.profile}
        location={create?.location}
        onMenuOpenCountry={onMenuOpenCountry}
        onMenuOpenRegion={onMenuOpenRegion}
        onMenuOpenCity={onMenuOpenCity}
        countries={countries}
        regions={regions}
        cities={cities}
        onChangeInput={handleChangeInput}
        onChangeGeo={handleChangeGeo}
        loadOptions={loadOptions}
        onSetSponsor={handleSetSponsor}
        onRemoveSponsor={handleRemoveSponsor}
        onChangeType={handleSetType}
        types={types}
        selectedType={create.type}
      />
      <DateSection errors={errors} dates={create?.dates} onChange={handleChangeDate} />
      <CountUsers
        errors={errors}
        size={create?.size}
        isCloseRegistration={create.close_reg}
        onChangeInput={handleChangeInput}
        onChangeCheckbox={handleChangeCheckbox}
      />
      <Payments
        payments={create?.payments}
        onChange={handleChangePaymentsInput}
        onChangeCheckbox={handleChangePaymentsCheckbox}
        onChangeDate={handleChangeDatePayments}
      />
      <SideSelect
        errors={errors}
        sides={create?.sides}
        allowSideSelection={create?.choose_side}
        onChangeInput={handleChangeInputSides}
        onChangeCheckbox={handleChangeCheckbox}
        onAddSide={handleAddSide}
        onRemoveSide={handleRemoveSide}
      />
      <CheckBoxesSection
        error={errors?.allow}
        state={create.allow}
        title="Допуск"
        list={allowMap}
        onChangeCheckbox={handleChangeAllowCheckbox}
      />
      <CheckBoxesSection
        error={errors?.rules}
        state={create.allow}
        title="Правила"
        list={rulesMap}
        onChangeCheckbox={handleChangeAllowCheckbox}
      />
      <CheckBoxesSection
        error={errors?.agreement}
        state={create.allow}
        title="Соглашение"
        list={agreementMap}
        onChangeCheckbox={handleChangeAllowCheckbox}
      />
    </div>
  );
};

export default CreateEventPage;
