import React, { memo } from 'react';
import { classNames as cx } from '../../../../helpers/utility';
import Icon from '../../../../components/icon';

import './styles.scss';

function ButtonIcon({ className, variant, iconName, onClick, ...props }) {
  const classNames = cx('msg-button msg-button-icon', className, {
    [`msg-button-icon--${iconName}`]: iconName,
    [`msg-button-icon--${variant}`]: variant,
  });

  return (
    <button type="button" onClick={onClick} className={classNames} {...props}>
      <Icon type={iconName} />
    </button>
  );
}

export default memo(ButtonIcon);
