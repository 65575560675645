import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/bootstrap.css';

import { useResponsive } from '../../hooks/useResponsive';
import { useActions, useSelector } from '../../hooks/store';
import { settingsActions } from '../../store/settings';

import ModalHeader from '../components/modalHeaderBlog';
import Button from '../../components/button';
import StepPhone from './stepPhone';
import StepCode from './stepCode';
import StepEmail from './stepEmail';
import StepPassword from './stepPassword';

import './styles.scss';

const renderTitle = (step) => {
  switch (step) {
    case 'phone':
      return 'Смена номера телефона';
    case 'email':
      return 'Смена адреса электронной почты';
    case 'password':
      return 'Смена пароля';
    default:
      return 'Смена номера телефона';
  }
};
const renderButtonLabel = (step, isMobile) => {
  switch (step) {
    case 'phone':
      return 'Смена номера телефона';
    case 'email':
      return isMobile ? 'Изменить адрес эл. почты' : 'Изменить адрес электронной почты';
    case 'password':
      return 'Изменить пароль на новый';
    case 'codePhone':
    case 'codeEmail':
      return 'Подтвердить';
    default:
      return 'Смена номера телефона';
  }
};

const initCredential = {
  email: '',
  password: '',
};

const initPasswords = {
  password: '',
  new_password: '',
  re_new_password: '',
};

const SecurityModal = ({ onCancel }) => {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [credential, setCredential] = useState(initCredential);
  const [passwords, setPasswords] = useState(initPasswords);
  const { loading, step, error } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.profile);
  const { submitPhone, submitCode, submitEmail, submitPassword, onChangeField } = useActions(
    settingsActions,
  );

  const { isMobile } = useResponsive();

  const handleClearError = () => onChangeField('error', '');

  const handleChangePhone = (value) => setPhone(value);

  const handleChangeCode = (value) => {
    if (error) {
      handleClearError();
    }
    setCode(value);
  };

  const handleChangeCredentials = (e) => {
    const { name, value } = e.target;
    setCredential({ ...credential, [name]: value });
  };

  const handleChangePasswords = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  useEffect(() => {
    if (user.temp_phone) setPhone(user.temp_phone);
    if (user.temp_email)
      setCredential({
        email: user.temp_email,
        password: '',
      });
  }, []);

  const onSubmit = () => {
    if (step === 'phone') {
      submitPhone(phone);
    }
    if (step === 'email') {
      submitEmail(credential);
    }
    if (step === 'password') {
      submitPassword(passwords);
    }
    if (step === 'codePhone') {
      submitCode(code, 'phone');
    }
    if (step === 'codeEmail') {
      submitCode(code, 'email');
    }
  };

  const onResentCode = () => {
    if (step === 'codePhone') {
      submitPhone(phone);
    }
    if (step === 'codeEmail') {
      submitEmail(credential);
    }
  };

  const renderContent = () => {
    switch (step) {
      case 'phone':
        return <StepPhone error={error} phone={phone} onChange={handleChangePhone} />;
      case 'codePhone':
      case 'codeEmail':
        return (
          <StepCode
            clearError={handleClearError}
            error={error}
            code={code}
            onChange={handleChangeCode}
            action={onResentCode}
            numFields={6}
          />
        );
      case 'email':
        return (
          <StepEmail credentials={credential} onChange={handleChangeCredentials} error={error} />
        );
      case 'password':
        return (
          <StepPassword passwords={passwords} onChange={handleChangePasswords} error={error} />
        );
      default:
        return <StepPhone phone={phone} changePhone={handleChangePhone} />;
    }
  };

  const disabledSubmit = () => {
    if (step === 'phone') {
      return !phone.length || loading;
    }
    if (step === 'email') {
      return !(credential.email.length && credential.password.length) || loading;
    }
    if (step === 'password') {
      return (
        !(
          passwords.password.length &&
          passwords.new_password.length &&
          passwords.re_new_password.length
        ) || loading
      );
    }
    if (step === 'codePhone' || step === 'codeEmail') {
      return !(code.length === 6) || loading;
    }
    return null;
  };

  return (
    <div className="modal-wrapper">
      <ModalHeader close={onCancel} title={renderTitle(step)} />
      <div className="modal-content">{renderContent()}</div>
      <div className="modal-footer">
        <Button tabIndex="0" disabled={disabledSubmit()} onClick={onSubmit} variant="main">
          {renderButtonLabel(step, isMobile)}
        </Button>
      </div>
    </div>
  );
};

export default SecurityModal;
