import { catchWrapper } from '../../../helpers/utility';
import { postUserAction } from '../../../api';

export default ({ page, query }) => async (dispatch) => {
  dispatch({ type: 'USERS/GET_OUTGOING/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'getOutgoingRequests');
  formData.append('objects_per_page', '20');

  if (page) {
    formData.append('page', page.toString());
  }

  if (query) {
    formData.append('filter[query]', query);
  }

  const result = await catchWrapper(postUserAction('friends', formData));

  if (result && result.result) {
    dispatch({ type: 'USERS/GET_OUTGOING/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'USERS/GET_OUTGOING/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
