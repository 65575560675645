import React, { useState, useRef, useEffect } from 'react';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import Button from '../../components/button';
import ModalHeader from '../components/modalHeaderBlog';
import '../generalModalStyles.scss';

import './styles.scss';
import Trans from '../../components/trans';

const CropperModal = ({
  onCancel,
  onChange,
  fieldName,
  file,
  submit,
  aspectRatio,
  minWidth,
  minHeight,
  id,
}) => {
  const [cropper, setCropper] = useState();
  const refWrap = useRef(null);
  const refEl = useRef(null);
  const cropperRef = useRef(null);

  const dynamicHeight = refWrap?.current?.clientHeight;

  const handleClickOutside = (event) => {
    if (refEl.current && !refEl.current.contains(event.target)) {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  });

  const onSubmit = async () => {
    cropper.getCroppedCanvas().toBlob((blob) => {
      if (onChange) {
        onChange(fieldName, {
          blob,
          file,
        });
      } else {
        submit(file, blob);
      }
    });
    onCancel();
  };
  let lastValidCrop = null;
  const onCrop = (e) => {
    if (e?.detail?.width >= minWidth && e?.detail?.height >= minHeight) {
      lastValidCrop = e.target.cropper.getData();
    } else {
      e.target.cropper.setData(lastValidCrop);
    }
  };

  return (
    <div ref={refEl} className="modal-wrapper">
      <ModalHeader close={onCancel} title="Изменить фотограию" />
      <div ref={refWrap} className="modal-content">
        <Cropper
          src={file && window.URL.createObjectURL(file)}
          style={{ height: dynamicHeight, width: '100%' }}
          guides={false}
          background={false}
          responsive
          viewMode={2}
          dragMode="move"
          aspectRatio={aspectRatio}
          autoCropArea={1}
          checkOrientation={false}
          crop={onCrop}
          ref={cropperRef}
          zoomable={false}
          rotatable={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      </div>
      <div className="modal-footer">
        <Button onClick={onSubmit} variant="main">
          {Trans('actions.save')}
        </Button>
      </div>
    </div>
  );
};

export default CropperModal;
