import React, { useEffect, useState } from 'react';

import he from 'he';
import { isEmpty } from 'lodash';
import isAfter from 'date-fns/isAfter';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { useActions, useSelector } from '../../../../hooks/store';
import { eventsActions } from '../../../../store/events';
import { classNames as cx } from '../../../../helpers/utility';

import { Pages } from '../../../../routes/pages';
import useOwnerData from '../../../../hooks/useOwnerData';

import Place from '../../../components/eventCard/components/place';
import EventInfo from '../../../components/eventCard/components/info';
import EntityPreview from '../../../components/entityPreview';
import RequestControls from '../../components/requestControls';
import Skeleton from '../../../../components/skeleton';

import './styles.scss';

const EventMainPage = () => {
  const { team, teamRole, userBlog } = useOwnerData();
  const [error, setError] = useState(null);
  const { event, loading } = useSelector((state) => state.events);

  const { onManageEventRequest } = useActions(eventsActions);
  const {
    profile,
    images,
    location,
    type,
    size,
    date_start: dateStart,
    date_end: dateEnd,
    prices,
    sponsor,
    sponsors,
    qty_of_users: qtyOfUsers,
    users,
  } = event;

  const toDay = Date.now();
  const pic = images?.banner?.preview;
  const title = he.decode((profile && profile.title) || '');
  const howManyDays = differenceInCalendarDays(new Date(dateStart), new Date(toDay));
  const isFinishedEvent = isAfter(new Date(toDay), new Date(dateEnd));

  useEffect(() => {
    if (!isEmpty(event)) {
      if (!event.allow_me_as_club && !event.allow_me_as_guest && !event.allow_me_as_single) {
        setError('Для участия в играх, вступите в клуб организатора.');
      }
      if (event?.closed) {
        setError('Регистрация закончена.');
      }
      if (isFinishedEvent) {
        setError(null);
      }
    }
  }, [event.id]);

  const classNamesSponsor = cx('event-page__sponsors', {
    'event-page__sponsors--loading': loading,
  });

  return (
    <div className="event-page__wrap">
      <div className="event-page__block-info__wrap">
        <div className={pic ? 'event-page__image-wrap' : 'event-page__image-wrap--empty'}>
          {pic ? <img src={pic} alt="pic" /> : null}
        </div>
        <div className="event-page__block-info">
          <EventInfo
            status={isFinishedEvent ? 'Завершено' : 'Заявка подана'}
            howManyDays={howManyDays}
            isFinishedEvent={isFinishedEvent}
            type={type}
            size={size}
            title={title}
            prices={prices}
            loading={loading}
            sign={event.sign}
          />

          <div className={classNamesSponsor}>
            {loading ? (
              <>
                <Skeleton className="event-info-sk__sponsor-1" />
                <Skeleton className="event-info-sk__sponsor-2" />
                <Skeleton className="event-info-sk__sponsor-3" />
              </>
            ) : (
              <div className="event-page__sponsors-list">
                <EntityPreview toLink={Pages.organizers.main} item={sponsor} label="Организатор" />
                {!isEmpty(sponsors) &&
                  sponsors.map((s) => (
                    <EntityPreview
                      toLink={Pages.organizers.main}
                      key={s.id}
                      item={s}
                      label="Организатор"
                    />
                  ))}
              </div>
            )}
          </div>

          <Place
            className="event-place__main-page"
            event={event}
            location={location}
            place={profile && profile.location_description}
            dateStart={dateStart}
            dateEnd={dateEnd}
            loading={loading}
          />

          {error ? <span className="event-warning">{error}</span> : null}

          {error || isFinishedEvent ? null : (
            <RequestControls
              team={team}
              teamRole={teamRole}
              userBlog={userBlog}
              event={event}
              onManageEventRequest={onManageEventRequest}
            />
          )}
        </div>
      </div>
      <div className="event-page__comments" />
    </div>
  );
};

export default EventMainPage;
