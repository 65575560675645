import React from 'react';
import { useParams } from 'react-router-dom';

const AccountHolder = ({ children }) => {
  const { id } = useParams();

  return !id ? children : null;
};

export default AccountHolder;
