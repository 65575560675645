import React from 'react';
import { useResponsive } from '../../hooks/useResponsive';
import ChatMobile from './mobile';
import ChatDesktop from './desktop';

function Chat() {
  const { isMobile } = useResponsive();
  return isMobile ? <ChatMobile /> : <ChatDesktop />;
}

export default Chat;
