export const MSG_TYPES = {
  AUTH_SUCCESS: 'MSG/AUTH_SUCCESS',
  AUTH_FAIL: 'MSG/AUTH_FAIL',
  LOGOUT: 'MSG/LOGOUT',

  SET_RECEIVER_INFO: 'MSG/SET_RECEIVER_INFO',
  SET_USER_LIST: 'MSG/SET_USER_LIST',

  SET_SCROLL_VALUE: 'MSG/SET_SCROLL_VALUE',
  CLEAN_MESSAGES: 'MSG/CLEAN_MESSAGES',
  UPDATE_MESSAGES: 'MSG/UPDATE_MESSAGES',
  SEND_MESSAGE: 'MSG/SEND_MESSAGE',
  SET_MESSAGE_HISTORY: 'MSG/SET_MESSAGE_HISTORY',
  SET_MESSAGE: 'MSG/SET_MESSAGE',
  SET_CONVERSATION_LIST: 'MSG/SET_CONVERSATION_LIST',
  SET_FILTER_VALUE: 'MSG/SET_FILTER_VALUE',
  SET_EDIT_MODE: 'MSG/SET_EDIT_MODE',
};

export const RECEIVER_TYPE = {
  USER: 'USER',
  GROUP: 'GROUP',
};

export const MSG_FILTER = {
  ALL: 'ALL',
  UNREAD: 'UNREAD',
};
