import { catchWrapper } from '../../../helpers/utility';
import { getEvent } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default (id) => async (dispatch) => {
  dispatch({ type: 'EVENTS/GET_EVENT/REQUESTED' });

  const result = await catchWrapper(getEvent({ id }));

  if (result && result.result) {
    dispatch({ type: 'EVENTS/GET_EVENT/SUCCEEDED', payload: result });
  } else {
    goToNotFound();
    dispatch({
      type: 'EVENTS/GET_EVENT/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
