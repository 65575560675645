import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useActions, useSelector } from '../../../../hooks/store';
import { eventsActions } from '../../../../store/events';
import { globalCancelFetch } from '../../../../api/v2';

import EventCard from '../../../components/eventCard';
import SkeletonEvents from '../../../events/components/skeleton';

import './styles.scss';

const MobilePageActivity = () => {
  const { id } = useParams();
  const [page, setPage] = useState(1);

  const { pagination, loading, eventsList } = useSelector((state) => state.events);
  const { userId } = useSelector((state) => state.auth);

  const { getUserEvents, clearEvents } = useActions(eventsActions);

  useEffect(() => {
    getUserEvents(id || userId, 1);

    return () => {
      clearEvents();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const fetchPaginated = () => {
    setPage(page + 1);
    getUserEvents(id || userId, page + 1);
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  return (
    <div className="mobile-page-activity">
      <>
        {loading && !hasMore ? (
          <SkeletonEvents />
        ) : (
          !isEmpty([1]) && (
            <InfiniteScroll
              dataLength={3}
              next={fetchPaginated}
              hasMore={hasMore}
              loader={<></>}
              className="events-section--grid"
            >
              {eventsList &&
                eventsList.map((event) => {
                  return <EventCard key={event.id} event={event} userId={userId} />;
                })}
            </InfiniteScroll>
          )
        )}
      </>
    </div>
  );
};

export default MobilePageActivity;
