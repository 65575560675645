import getBlog from './impl/getBlog';
import onSaveBlogService from './impl/onSaveBlogService';
import getBlogTypes from './impl/getBlogTypes';
import getBlogTags from './impl/getBlogTags';
import onChangeField from './impl/onChangeField';
import onSaveBlogProfile from './impl/onSaveBlogProfile';
import onCreateBlogProfile from './impl/onCreateBlogProfile';
import subscribeBlog from './impl/subscribeBlog';
import unSubscribeBlog from './impl/unSubscribeBlog';
import getBlogArticleTypes from './impl/getBlogArticleTypes';
import onCreateBlogArticle from './impl/onCreateBlogArticle';
import getArticlesList from './impl/getArticlesList';
import clearBlog from './impl/clearBlog';
import getArticle from './impl/getArticle';
import onEditBlogArticle from './impl/onEditBlogArticle';
import onChangeArticleField from './impl/onChangeArticleField';
import onCreateBlogAlbum from './impl/onCreateBlogAlbum';
import getBlogAlbumList from './impl/getBlogAlbumList';
import getBlogAlbum from './impl/getBlogAlbum';
import clearBlogAlbum from './impl/clearBlogAlbum';
import clearBlogAlbums from './impl/clearBlogAlbums';
import getBlogAlbumImages from './impl/getBlogAlbumImages';
import clearBlogAlbumImages from './impl/clearBlogAlbumImages';
import onUploadBlogImage from './impl/onUploadBlogImage';
import getBlogVideoList from './impl/getBlogVideoList';
import onCreateBlogVideo from './impl/onCreateBlogVideo';
import clearBlogVideos from './impl/clearBlogVideos';
import onEditBlogVideo from './impl/onEditBlogVideo';
import onEditBlogAlbum from './impl/onEditBlogAlbum';
import onRemoveImagesFromAlbum from './impl/onRemoveImagesFromAlbum';
import onDeleteBlogVideo from './impl/onDeleteBlogVideo';
import onDeleteBlogAlbum from './impl/onDeleteBlogAlbum';
import onChangeArticleTitle from './impl/onChangeArticleTitle';
import onChangeArticleText from './impl/onChangeArticleText';

const initState = {
  loading: 0,
  blog: {},
  error: null,
};

export const blogReducer = (state = null, action) => {
  switch (action.type) {
    case 'BLOG/LOAD_BLOG/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case 'BLOG/SUBSCRIBE_BLOG/REQUESTED':
    case 'BLOG/GET_BLOG_ARTICLES/REQUESTED':
    case 'BLOG/GET_BLOG_ARTICLE/REQUESTED':
    case 'BLOG/CREATE_BLOG_ALBUM/REQUESTED':
    case 'BLOG/SUCCEEDED/REQUESTED':
    case 'BLOG/GET_BLOG_ALBUM/REQUESTED':
    case 'BLOG/UNSUBSCRIBE_BLOG/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case 'BLOG/LOAD_BLOG_TYPES/REQUESTED':
    case 'BLOG/SAVE_BLOG_PROFILE/REQUESTED':
    case 'BLOG/CREATE_BLOG_PROFILE/REQUESTED':
    case 'BLOG/CREATE_BLOG_ARTICLE/REQUESTED':
    case 'BLOG/LOAD_BLOG_ARTICLES_TYPES/REQUESTED':
    case 'BLOG/CREATE_BLOG_VIDEO/REQUESTED':
    case 'BLOG/LOAD_BLOG_TAGS/REQUESTED':
    case 'BLOG/SAVE_BLOG_LINK/REQUESTED':
      return {
        ...state,
        loadingSilent: state.loadingSilent + 1,
      };
    case 'BLOG/REMOVE_IMAGES_ALBUM/REQUESTED':
    case 'BLOG/GET_BLOG_ALBUM_LIST/REQUESTED':
    case 'BLOG/GET_BLOG_VIDEO_LIST/REQUESTED':
    case 'BLOG/EDIT_BLOG_ALBUM/REQUESTED':
    case 'BLOG/EDIT_BLOG_VIDEO/REQUESTED':
    case 'BLOG/DELETE_BLOG_VIDEO/REQUESTED':
    case 'BLOG/DELETE_BLOG_ALBUM/REQUESTED':
    case 'BLOG/GET_BLOG_ALBUM_IMAGES/REQUESTED':
      return {
        ...state,
        loadingSilentGallery: state.loadingSilentGallery + 1,
      };

    case 'BLOG/REMOVE_IMAGES_ALBUM/SUCCEEDED': {
      return {
        ...state,
        albumImages: state.albumImages.filter((a) => !action.payload.some((t) => a.id === t.id)),
        loadingSilentGallery: state.loadingSilentGallery - 1,
      };
    }
    case 'BLOG/LOAD_BLOG/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        blog: action.payload,
        blogSnapshot: action.payload,
      };
    }
    case 'BLOG/SUBSCRIBE_BLOG/SUCCEEDED':
    case 'BLOG/UNSUBSCRIBE_BLOG/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }
    case 'BLOG/LOAD_BLOG_TYPES/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        types: action.payload,
      };
    }
    case 'BLOG/LOAD_BLOG_TAGS/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        tags: action.payload,
      };
    }
    case 'BLOG/LOAD_BLOG_ARTICLES_TYPES/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        articlesTypes: action.payload,
      };
    }
    case 'BLOG/LOAD_BLOG/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: action.payload,
      };
    }

    case 'BLOG/CLEAR': {
      return initState;
    }
    case 'BLOG/SAVE_BLOG_LINK/SUCCEEDED':
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        blog: {
          ...state.blog,
          social_service: action.payload,
        },
      };
    case 'BLOG/CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }
    case 'BLOG/CHANGE_ARTICLE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }
    case 'BLOG/CHANGE_ARTICLE_TEXT': {
      return {
        ...state,
        article: { ...state.article, text: action.payload },
      };
    }
    case 'BLOG/CHANGE_ARTICLE_TITLE': {
      return {
        ...state,
        article: { ...state.article, title: action.payload },
      };
    }
    case 'BLOG/SAVE_BLOG_PROFILE/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        blog: action.payload,
        blogSnapshot: action.payload,
      };
    }
    case 'BLOG/GET_BLOG_ARTICLES/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        articlesList: [...state.articlesList, ...action.payload.items],
        pagination: action.payload.pagination,
      };
    case 'BLOG/GET_BLOG_VIDEO_LIST/SUCCEEDED':
      return {
        ...state,
        loadingSilentGallery: state.loadingSilentGallery - 1,
        videoList: [...state.videoList, ...action.payload.items],
        paginationVideo: action.payload.pagination,
      };
    case 'BLOG/GET_BLOG_ALBUM_LIST/SUCCEEDED':
      return {
        ...state,
        loadingSilentGallery: state.loadingSilentGallery - 1,
        albumsList: [...state.albumsList, ...action.payload.items],
        paginationAlbums: action.payload.pagination,
      };
    case 'BLOG/GET_BLOG_ALBUM_IMAGES/SUCCEEDED': {
      const { items, pagination } = action.payload;
      const hasMore = Number(pagination.current_page) < Number(pagination.total_pages);
      return {
        ...state,
        loadingSilentGallery: state.loadingSilentGallery - 1,
        albumImages: [...state.albumImages, ...items],
        paginationAlbumImages: {
          ...pagination,
          hasMore,
        },
      };
    }

    case 'BLOG/GET_BLOG_ALBUM/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        album: action.payload.album,
      };
    case 'BLOG/GET_BLOG_ARTICLE/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        article: action.payload,
        articleSnapshot: action.payload,
      };

    case 'BLOG/CREATE_BLOG_PROFILE/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        blogSnapshot: state.blog,
      };
    }

    case 'BLOG/UPLOAD_BLOG_IMAGE/REQUESTED':
      return {
        ...state,
        uploadCount: state.uploadCount + 1,
      };

    case 'BLOG/UPLOAD_BLOG_IMAGE/SUCCEEDED':
      return {
        ...state,
        albumImages: [...state.albumImages, action.payload],
        uploadCount: state.uploadCount - 1,
      };

    case 'BLOG/UPLOAD_BLOG_IMAGE/FAILED':
      return {
        ...state,
        uploadCount: state.uploadCount - 1,
      };

    case 'BLOG/SAVE_BLOG_LINK/FAILED':
    case 'BLOG/LOAD_BLOG_TAGS/FAILED':
    case 'BLOG/CREATE_BLOG_PROFILE/FAILED':
    case 'BLOG/SAVE_BLOG_PROFILE/FAILED':
    case 'BLOG/LOAD_BLOG_ARTICLES_TYPES/FAILED':
    case 'BLOG/CREATE_BLOG_VIDEO/SUCCEEDED':
    case 'BLOG/CREATE_BLOG_VIDEO/FAILED':
    case 'BLOG/LOAD_BLOG_TYPES/FAILED':
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };

    case 'BLOG/REMOVE_IMAGES_ALBUM/FAILED':
    case 'BLOG/DELETE_BLOG_VIDEO/SUCCEEDED':
    case 'BLOG/DELETE_BLOG_ALBUM/SUCCEEDED':
    case 'BLOG/EDIT_BLOG_VIDEO/SUCCEEDED':
    case 'BLOG/EDIT_BLOG_ALBUM/SUCCEEDED':
    case 'BLOG/GET_BLOG_VIDEO_LIST/FAILED':
    case 'BLOG/DELETE_BLOG_VIDEO/FAILED':
    case 'BLOG/DELETE_BLOG_ALBUM/FAILED':
    case 'BLOG/EDIT_BLOG_VIDEO/FAILED':
    case 'BLOG/EDIT_BLOG_ALBUM/FAILED':
    case 'BLOG/GET_BLOG_ALBUM_LIST/FAILED':
    case 'BLOG/GET_BLOG_ALBUM_IMAGES/FAILED':
      return {
        ...state,
        loadingSilentGallery: state.loadingSilentGallery - 1,
      };
    case 'BLOG/SUBSCRIBE_BLOG/FAILED':
    case 'BLOG/GET_BLOG_ARTICLES/FAILED':
    case 'BLOG/GET_BLOG_ARTICLE/FAILED':
    case 'BLOG/CREATE_BLOG_ALBUM/SUCCEEDED':
    case 'BLOG/CREATE_BLOG_ALBUM/FAILED':
    case 'BLOG/GET_BLOG_ALBUM/FAILED':
    case 'BLOG/UNSUBSCRIBE_BLOG/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }
    case 'BLOG/CLEAR_BLOG':
      return {
        ...state,
        articlesList: [],
        blog: {},
        pagination: {},
        article: {},
        articleSnapshot: {},
      };

    case 'BLOG/ALBUMS/CLEAR':
      return {
        ...state,
        albumsList: [],
      };

    case 'BLOG/VIDEOS/CLEAR':
      return {
        ...state,
        videoList: [],
        paginationVideo: {},
      };

    case 'BLOG/ALBUMS_IMAGES/CLEAR':
      return {
        ...state,
        albumImages: [],
      };

    case 'BLOG/ALBUM/CLEAR':
      return {
        ...state,
        album: {},
      };
    default:
      return state;
  }
};

export const blogActions = {
  getBlog,
  onSaveBlogService,
  getBlogTypes,
  getBlogTags,
  onChangeField,
  onSaveBlogProfile,
  onCreateBlogProfile,
  subscribeBlog,
  unSubscribeBlog,
  getBlogArticleTypes,
  onCreateBlogArticle,
  getArticlesList,
  clearBlog,
  getArticle,
  onEditBlogArticle,
  onChangeArticleField,
  onCreateBlogAlbum,
  getBlogAlbumList,
  getBlogAlbum,
  clearBlogAlbum,
  clearBlogAlbums,
  getBlogAlbumImages,
  clearBlogAlbumImages,
  onUploadBlogImage,
  getBlogVideoList,
  onCreateBlogVideo,
  clearBlogVideos,
  onEditBlogVideo,
  onEditBlogAlbum,
  onRemoveImagesFromAlbum,
  onDeleteBlogVideo,
  onDeleteBlogAlbum,
  onChangeArticleText,
  onChangeArticleTitle,
};
