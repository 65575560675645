import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { Pages } from '../../../routes/pages';
import { PrivateRoute } from '../../../routes/privateRoute';
import AllUsers from '../desktop/all';
import MyFriends from '../desktop/myFriends';
import IncomingFriends from '../desktop/incomingFriends';
import OutgoingFriends from '../desktop/outgoingFriends';

import UserFriends from '../desktop/userFriends';
import CommonFriends from '../desktop/common';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderRight from '../../../components/headerConstructor/components/right';

import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderConstructor from '../../../components/headerConstructor';
import MobileNavigation from '../../../components/headerConstructor/components/mobileNavigation';

const FriendsPageMobile = (rest) => {
  const { navigationMap } = rest;
  const isIncoming = useRouteMatch(Pages.users.requests);
  const isOutgoing = useRouteMatch(Pages.users.outRequests);

  return (
    <>
      <HeaderConstructor className="team mobile">
        <HeaderLeft>
          <SidebarButton />
        </HeaderLeft>
        <HeaderRight>
          <>
            <Controls />
            <Menu />
          </>
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        {isOutgoing || isIncoming ? (
          <div className="mobile-controls__wrap">
            <MobileNavigation navigationMap={navigationMap} />
          </div>
        ) : null}

        <Switch>
          <PrivateRoute
            exact
            path={Pages.users.all}
            component={AllUsers}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.main}
            component={MyFriends}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.requests}
            component={IncomingFriends}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.outRequests}
            component={OutgoingFriends}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.userFriends}
            component={UserFriends}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.common}
            component={CommonFriends}
            extraProps={{ ...rest }}
          />
        </Switch>
      </div>
    </>
  );
};

export default FriendsPageMobile;
