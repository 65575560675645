import React from 'react';
import uuid from 'react-uuid';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import Filters from '../../../friends/components/filters';
import { useResponsive } from '../../../../hooks/useResponsive';
import TeamCard from '../teamCard';
import SkeletonFriends from '../../../friends/components/skeletonFriends';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import ToggleFilterButton from '../../../components/toggleFilterButton';
import SkeletonGrid from '../../../../components/skeletonsGrid';

const TeamsList = ({
  userId,
  teams,
  fetchPaginated,
  hasMore,
  loading,
  handleChangeAge,
  age,
  handleChangeInput,
  handleChangeGeo,
  geoLocal,
  handleChangeCountry,
  rangeName,
  rangeDescription,
  onChangeComplete,
  recruitment,
  handleClearFilter,
  handleChangeRecruitment,
  handleVisibleFilter,
  isFilterOpened,
  isAddButton,
  onClickAdd,
}) => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const handleToggleFilter = () => handleVisibleFilter(!isFilterOpened);
  return (
    <>
      {isTablet && (
        <ToggleFilterButton
          onClick={handleToggleFilter}
          isAddButton={isAddButton}
          onClickAdd={onClickAdd}
        />
      )}
      <div className="friends__filters">
        {(isDesktop || ((isMobile || isTablet) && isFilterOpened)) && (
          <Filters
            handleChangeAge={handleChangeAge}
            age={age}
            handleChangeInput={handleChangeInput}
            handleChangeGeo={handleChangeGeo}
            handleChangeCountry={handleChangeCountry}
            geoLocal={geoLocal}
            rangeName={rangeName}
            rangeDescription={rangeDescription}
            minRangeValue={1}
            maxRangeValue={1000}
            isRecruitment
            recruitment={recruitment}
            onChangeComplete={onChangeComplete}
            handleClearFilter={handleClearFilter}
            handleChangeRecruitment={handleChangeRecruitment}
            handleVisibleFilter={handleVisibleFilter}
            isMobile={isMobile || isTablet}
          />
        )}
      </div>
      <>
        {loading && !hasMore ? (
          <SkeletonGrid
            classNameElement="sk-camouflage--element"
            classNameGrid="sk-camouflage--grid"
          />
        ) : (
          !isEmpty(teams) && (
            <InfiniteScroll
              dataLength={teams?.length}
              next={fetchPaginated}
              hasMore={hasMore}
              loader={<></>}
              className="teams__cards"
            >
              {teams?.map((team) => (
                <TeamCard userId={userId} team={team} key={uuid()} />
              ))}
            </InfiniteScroll>
          )
        )}
      </>
    </>
  );
};

export default TeamsList;
