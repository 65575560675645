import React from 'react';
import { Link } from 'react-router-dom';

import { Pages } from '../../../../routes/pages';
import { classNames as cx } from '../../../../helpers/utility';

import Avatar from '../../../components/avatar';

import './styles.scss';

const RecruitingCard = ({ ownerId, item, onClick, userTeamRole }) => {
  const url =
    item?.user?.id === ownerId
      ? Pages.profile.base
      : {
          pathname: Pages.profile.main.replace(':id?', item?.user?.id),
          state: item?.user?.id,
        };

  const classNames = cx('card recruiting-card', {
    'recruiting-card--control': userTeamRole,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={(e) => onClick(e, item)} className={classNames}>
      <div className="recruiting-card__header">
        <div className="recruiting-card__avatar">
          <Avatar
            image={item?.user?.image_avatar?.preview}
            withStatus
            status={item?.user?.state_online}
          />
        </div>
        <div className="recruiting-card__user-info">
          <Link to={url}>
            <span data-user-name={item?.user?.nickname} className="user-info__name">
              {item?.user?.nickname}
            </span>
          </Link>
          <span className="user-info__value">{`${item?.location?.city?.title || ''}, ${
            item?.user?.age || ''
          } `}</span>
        </div>
        <div className="recruiting-card__camouflages">
          {item?.camouflages?.map((c) => {
            return (
              <div key={c.id} className="recruiting-card__cam-wrap">
                <img src={c?.image?.preview} alt="c" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="recruiting-card__content">
        <span className="recruiting-card__text">{item?.text}</span>
      </div>
    </div>
  );
};
export default RecruitingCard;
