import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getImageFromFile } from '../../../../helpers/utility';
import { Pages } from '../../../../routes/pages';
import ImagePicker from '../../../../components/imagePicker';
import HintImage from '../../../components/hintImage';
import './styles.scss';

const SponsorBannerBlock = ({
  isEditable,
  handleChangeImages,
  imagesState,
  sponsor,
  loading,
  errors,
}) => {
  const isSettingPages = !!useRouteMatch(Pages.organizers.settings.base);
  const isCreatePage = !!useRouteMatch(Pages.organizers.settings.create);
  const flag = imagesState?.flag && getImageFromFile(imagesState?.flag?.blob);
  const banner = sponsor?.images?.flag?.preview;
  const img = sponsor?.image?.image;
  const isHint = (isSettingPages || isCreatePage) && !loading && !(flag || banner);

  return (
    <div className="sponsor-banner-block">
      <div className="sponsor-banner__wrap">
        {isHint ? <HintImage label="Шеврон" width={250} height={665} error={errors?.flag} /> : null}
        {flag || banner || img ? <img src={flag || banner || img} alt="" /> : null}
        {isEditable ? (
          <ImagePicker
            onChange={handleChangeImages}
            aspectRatio={2.66}
            minHeight={250}
            minWidth={665}
            fieldName="flag"
            className="add-photo"
          />
        ) : null}
      </div>
    </div>
  );
};

export default SponsorBannerBlock;
