import { Pages } from '../../../routes/pages';
import { getIdFromUrl } from '../../../helpers/utility';

const navigationMap = () => {
  return [
    {
      id: '1',
      path: Pages.events.all,
      state: null,
      label: 'Игры',
    },
  ];
};

const navigationEvent = ({ eventId }) => {
  // TODO : Добавить event.isScenario // event.isAloow и.т.п.
  return [
    {
      id: '1',
      path: Pages.event.main.replace(':id', eventId),
      state: eventId,
      label: 'Главная',
    },
    {
      id: '2',
      path: Pages.event.scenario.replace(':id', eventId),
      state: eventId,
      label: 'Сценарий',
      isVisible: true,
      hiddenRequest: true,
    },
    {
      id: '3',
      path: Pages.event.price.replace(':id', eventId),
      state: eventId,
      label: 'Цены',
      isVisible: true,
      hiddenRequest: true,
    },
    {
      id: '4',
      path: Pages.event.allow.replace(':id', eventId),
      state: eventId,
      label: 'Допуск',
      isVisible: true,
      hiddenRequest: true,
    },
    {
      id: '5',
      path: Pages.event.info.replace(':id', eventId),
      state: eventId,
      label: 'Инфо',
      isVisible: true,
      hiddenRequest: true,
      hash: '#rules',
    },
    {
      id: '6',
      path: Pages.event.location.replace(':id', eventId),
      state: eventId,
      label: 'Полигон',
      isVisible: true,
      hiddenRequest: true,
    },
  ];
};
export const getEventMaps = ({ location, isAllEventsPage, userType, event }) => {
  if (isAllEventsPage) {
    return navigationMap();
  }

  if (!!event)
    return navigationEvent({ eventId: getIdFromUrl(location.pathname), userType, event });
};
