import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useActions, useSelector } from '../../../../hooks/store';
import { groundsActions } from '../../../../store/grounds';

import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import modalService from '../../../../services/modalService';
import GroundPage from './components/groundPage';
import { globalCancelFetch } from '../../../../api/v2';

const GroundsMainPage = () => {
  const { id } = useParams();
  const { ground, loading } = useSelector((state) => state.grounds);
  const { userSponsors } = useSelector((state) => state.profile);
  const { clearGrounds, getGround } = useActions(groundsActions);
  let canUserEdit = false;
  if (
    userSponsors &&
    userSponsors.length &&
    userSponsors[0].is_ground &&
    (userSponsors[0].user_role === 'commander' || userSponsors[0].user_role === 'deputy') &&
    userSponsors[0].id === ground?.owner?.id
  ) {
    canUserEdit = true;
  }

  const handleOpenModal = () => {
    modalService.openGroundRequest({ ground, closeModal: () => modalService.closeAll() });
  };

  const goToSettings = () =>
    history.push({
      pathname: Pages.grounds.settings.replace(':id', id),
      state: id,
    });

  useEffect(() => {
    getGround({ id });
  }, [id]);

  useEffect(() => {
    return () => {
      clearGrounds();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  return (
    <GroundPage
      ground={ground}
      loading={loading}
      canUserEdit={canUserEdit}
      goToSettings={goToSettings}
      handleOpenModal={handleOpenModal}
    />
  );
};

export default GroundsMainPage;
