import React from 'react';

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7292 0.556412C15.4698 -0.185471 16.6703 -0.185471 17.4108 0.556412C18.1512 1.2983 18.1512 2.50113 17.4108 3.24302L11.6815 8.98306L17.4446 14.757C18.1851 15.4989 18.1851 16.7017 17.4446 17.4436C16.7041 18.1855 15.5036 18.1855 14.7631 17.4436L9 11.6697L3.23691 17.4436C2.49642 18.1855 1.29585 18.1855 0.555366 17.4436C-0.185123 16.7017 -0.185122 15.4989 0.555366 14.757L6.31845 8.98306L0.589195 3.24302C-0.151293 2.50113 -0.151293 1.2983 0.589195 0.556412C1.32968 -0.185471 2.53025 -0.185471 3.27074 0.556412L9 6.29645L14.7292 0.556412Z"
      fill="currentColor"
    />
  </svg>
);
