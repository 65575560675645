import React from 'react';
import './styles.scss';
import NewsCard from '../../components/newsCard';
import FeedFilter from '../../components/feedFilter';
import DayPickerCalendar from '../../components/dayPickerCalendar';
import { useResponsive } from '../../../hooks/useResponsive';
import NewOrgGroupCard from '../../components/OrgGroupCard';

const FeedGeneral = ({ userData }) => {
  const { isDesktop } = useResponsive();
  return (
    <div className="page-feed-general">
      <div className="page-feed-general__container">
        <NewsCard userData={userData} />
        <NewsCard userData={userData} />
        <NewsCard userData={userData} />
      </div>
      <div className="page-feed-general__filter">
        <FeedFilter />
      </div>
      {isDesktop && (
        <div className="page-feed-general__calendar">
          <DayPickerCalendar />
          <NewOrgGroupCard userData={userData} />
        </div>
      )}
    </div>
  );
};

export default FeedGeneral;
