import React, { useEffect } from 'react';
import CustomInput from '../../../components/input';
import InputPhone from '../../../components/inputPhone';

const StepPhone = ({ phone, onChange, classNames, error }) => {
  useEffect(() => {
    const target = document.querySelector('.phone-input');
    if (target) {
      target.focus();
    }
  }, []);

  return (
    <>
      <span className="modal-content__label">Новый номер телефона</span>
      <InputPhone
        tabIndex="0"
        disableDropdown
        containerClass={classNames}
        inputClass="phone-input"
        inputComponent={<CustomInput />}
        placeholder="Введите номер телефона"
        value={phone}
        onChange={onChange}
      />
      <span className="modal-content__text-error">{error}</span>
    </>
  );
};

export default StepPhone;
