import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.424 13.4447L9.44567 20.4079C8.85144 21.0009 8.85144 21.9623 9.44567 22.5553C10.0399 23.1482 11.0035 23.1482 11.5977 22.5553L17.5 16.6658L23.4023 22.5553C23.9965 23.1482 24.96 23.1482 25.5543 22.5553C26.1486 21.9623 26.1486 21.0009 25.5543 20.4079L18.576 13.4447C17.9817 12.8518 17.0182 12.8518 16.424 13.4447Z"
      fill="currentColor"
    />
  </svg>
);
