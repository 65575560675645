import React from 'react';
import { NavLink } from 'react-router-dom';

import { Pages } from '../../../../routes/pages';

import ThemeSwitch from '../../../../components/themeSwitch';
import Icon from '../../../../components/icon';
import history from '../../../../routes/history';
import './styles.scss';

const getNavigationMap = (id) => {
  return [
    {
      id: '1',
      icon: 'profile-30',
      label: 'Блог',
      path: Pages.blog.settings.main.replace(':id', id),
    },
    {
      id: '2',
      icon: 'links-30',
      label: 'Ссылки',
      path: Pages.blog.settings.links.replace(':id', id),
    },
  ];
};

const SettingsBlogNavigationsMobile = () => {
  const { location } = history;
  return (
    <div className="settings-nav">
      {/*<div className="settings-nav__row settings-nav__theme">*/}
      {/*  <ThemeSwitch />*/}
      {/*</div>*/}

      {getNavigationMap(location?.state).map((item) => {
        return (
          <NavLink key={item.id} className="settings-nav__link" to={item.path}>
            <div className="settings-nav__row settings-nav__link__row">
              <Icon type={item.icon} />
              <span className="settings-nav__row__text">{item.label}</span>
              <Icon type="chevron" />
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default SettingsBlogNavigationsMobile;
