export const Page = {
  main: '/',
  privacy: '/privacy',
  terms: '/terms',
  profile: '/profile',
  profileId: '/profile/:id?',
  settings: '/settings',
  settingsNav: '/settings/nav',
  settingsProfile: '/settings/profile',
  settingsPassport: '/settings/passport',
  settingsLinks: '/settings/links',
  settingsPrivate: '/settings/private',
  settingsSecurity: '/settings/security',
  mobileAwards: '/awards',
  mobileActivity: '/activity',
  friends: '/profileFriendsBlock',
  myFriends: '/profileFriendsBlock/myfriends',
  friendsIncoming: '/profileFriendsBlock/incoming',
  friendsOutgoing: '/profileFriendsBlock/outgoing',
  allUsers: '/profileFriendsBlock/all',
  games: '/games',
  teams: '/teams',
  allTeams: '/teams/all',
  recruitingTeams: '/teams/recruiting',
  login: '/login',
  register: '/register',
  feed: '/feed',
  user: '/user/:id',
  camouflage: '/camouflage',
  camouflages: '/camouflages',
  camouflageId: '/camouflage/:id',
  team: '/team',
  teamMain: '/team/:id/main',
  teamPersonnel: '/team/:id/personnel',
  teamRequest: '/team/:id/request',
  teamSettings: '/team/settings',
  teamSettingsPersonnel: '/team/settingsPersonnel',
  teamSettingsLinks: '/team/settingsLinks',
  teamSettingsCharter: '/team/settingsCharter',
  teamSettingsEquipment: '/team/settingsEquipment',
  organizers: '/organizers',
  orgAll: '/organizers/all',
  orgMain: '/organizers/:id/main',
  orgSettings: '/organizers/:id/settings',
  orgRules: '/organizers/:id/equipment',
  orgAgreements: '/organizers/:id/settingsEquipment',
  cookies: '/cookies',
  forUser: '/for_user',
  forOrg: '/for_organizer',
  forLandfill: '/for_landfill',
};

export const Pages = {
  error: {
    notFound: '/404',
  },
  faq: {
    base: '/faq',
  },
  auth: {
    auth: '/auth',
  },
  dev: {
    ui: '/dev/ui-kit',
  },
  camouflage: {
    base: '/camouflage',
    main: '/camouflage/:id',
  },
  camouflages: {
    base: '/camouflages',
    all: '/camouflages/all',
  },
  team: {
    base: '/team',
    main: '/team/:id/main',
    personnel: '/team/:id/personnel',
    request: '/team/:id/request',
    about: '/team/:id/about',
    equipment: '/team/:id/equipment',
    create: {
      base: '/team-create',
      main: '/team-create/main',
      links: '/team-create/links',
      about: '/team-create/about',
    },
    settings: {
      base: '/team-settings',
      main: '/team-settings/:id/main',
      personnel: '/team-settings/:id/personnel',
      links: '/team-settings/:id/links',
      about: '/team-settings/:id/about',
    },
  },
  teams: {
    base: '/teams',
    all: '/teams/all',
    recruiting: {
      all: '/teams/recruiting',
      add: '/teams/recruiting/add',
      view: '/teams/recruiting/view',
    },
  },
  blog: {
    base: '/blog',
    main: '/blog/:id/main',
    all: '/blog/all',
    subscribed: '/blog/subscribed',
    createArticle: '/blog/:id/createArticle-article',
    createReview: '/blog/createArticle-review',
    createBlog: '/blog/createArticle-blog',
    articles: '/blog/:id/articles',
    article: '/blog/article/:id/',
    articleEdit: '/blog/article/:id/edit',
    qr: '/blog/:id?/qr',
    navi: '/blog/navi',
    settings: {
      base: '/blog-settings',
      main: '/blog-settings/:id/main',
      links: '/blog-settings/:id/links',
    },
  },
  organizers: {
    base: '/organizers',
    all: '/organizers/all',
    main: '/organizers/:id/main',
    personnel: '/organizers/:id/personnel',
    request: '/organizers/:id/request',
    agreements: '/organizers/:id/agreements',
    requirements: '/organizers/:id/requirements',
    participants: '/organizers/:id/participants',
    events: '/organizers/:id/events',
    about: '/organizers/:id/about',
    settings: {
      base: '/organizers-settings',
      create: '/organizers-settings/create',
      main: '/organizers-settings/:id/main',
      personnel: '/organizers-settings/:id/personnel',
      links: '/organizers-settings/:id/links',
      about: '/organizers-settings/:id/about',
      agreements: '/organizers-settings/:id/agreements',
      legal: '/organizers-settings/:id/legal',
    },
  },
  event: {
    base: '/event/',
    eventBase: '/event/:id',
    main: '/event/:id/main',
    scenario: '/event/:id/scenario',
    participants: '/event/:id/participants',
    price: '/event/:id/price',
    allow: '/event/:id/allow',
    info: '/event/:id/info',
    rules: '/event/:id/rules',
    location: '/event/:id/location',
    agreement: '/event/:id/agreement',
    gallery: '/event/:id/social',
    results: '/event/:id/results',
    offer: '/event/:id/offer',

    settings: {
      base: '/event-settings',
      create: '/event-settings/create',
      edit: '/event-settings/:id/edit',
      scenario: '/event-settings/:id/scenario',
      participants: '/event-settings/:id/participants',
      price: '/event-settings/:id/price',
      allow: '/event-settings/:id/allow',
      rules: '/event-settings/:id/rules',
      location: '/event-settings/:id/location',
      info: '/event-settings/:id/info',
      gallery: '/event-settings/:id/social',
      results: '/event-settings/:id/results',
    },
  },
  events: {
    base: '/events',
    upcoming: '/events/upcoming',
    past: '/events/past',
    all: '/events/all',
    create: '/events/create',
  },
  profile: {
    base: '/profile',
    main: '/profile/:id?',
    activity: '/profile/:id/activity',
    achievements: '/profile/:id/achievements',
    awards: '/profile/:id/awards',
    titles: '/profile/:id/titles',
    qr: '/profile/:id/qr',
  },
  settings: {
    base: '/settings',
    profile: '/settings/profile',
    passport: '/settings/game-passport',
    links: '/settings/links',
    private: '/settings/private',
    security: '/settings/security',
    navi: '/settings/navi',
  },
  users: {
    base: '/users/:id?',
    main: '/users/friends',
    all: '/users/all',
    requests: '/users/requests',
    outRequests: '/users/out-requests',
    common: '/users/:id/common',
    userFriends: '/users/:id/all',
  },
  pages: {
    base: '/pages',
    category: '/pages/category',
  },
  grounds: {
    base: '/grounds',
    create: '/grounds/create',
    settings: '/grounds/:id/settings',
    main: '/grounds/:id',
  },
  msg: {
    base: '/msg',
    conversations: '/msg/conversations',
    dialog: '/msg/dialog',
  },
};
