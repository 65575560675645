export const sidebarReducer = (state = null, action) => {
  switch (action.type) {
    case 'SIDEBAR/TOGGLE': {
      return {
        ...state,
        isOpenSidebar: action.payload,
      };
    }
    default:
      return state;
  }
};

const onToggle = (bool) => (dispatch) => {
  dispatch({
    type: 'SIDEBAR/TOGGLE',
    payload: bool,
  });
};

export const sidebarActions = {
  onToggle,
};
