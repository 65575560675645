import { catchWrapper } from '../../../helpers/utility';
import { getSponsorParticipants } from '../../../api/v2';

export default (id) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/LOAD_SPONSOR_PARTICIPANTS/REQUESTED' });

  const payloadRequest = { id };

  const result = await catchWrapper(getSponsorParticipants(payloadRequest));

  const sponsorParticipants = {
    gold: result.gold,
    silver: result.silver,
    bronze: result.bronze,
    participant: result.members,
    guest: result.guests,
  };

  if (result && result.result) {
    dispatch({
      type: 'SPONSOR/LOAD_SPONSOR_PARTICIPANTS/SUCCEEDED',
      payload: { ...sponsorParticipants },
    });
  } else {
    dispatch({
      type: 'SPONSOR/LOAD_SPONSOR_PARTICIPANTS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
