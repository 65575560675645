import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { CometChat } from '@cometchat-pro/chat';
import history from './routes/history';
import { store, persistor } from './store';

import App from './layouts';

import './i18n';

import './styles/normalize.scss';
import './styles/main.scss';
import ThemeSelector from './providers/theme-selector/ThemeSelector';

const appID = process.env.REACT_APP_COMET_CHAT_APP_ID;

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion('eu')
  .build();

CometChat.init(appID, appSetting).then(
  () => {
    console.log('Initialization completed successfully');
  },
  (error) => {
    console.log('Initialization failed with error:', error);
  },
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Suspense fallback={null}>
          <ThemeSelector>
            <Route path="/" component={App} />
          </ThemeSelector>
        </Suspense>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
