import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { Pages } from '../../../routes/pages';
import { PrivateRoute } from '../../../routes/privateRoute';

import HeaderLeft from '../../../components/headerConstructor/components/left';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import HeaderConstructor from '../../../components/headerConstructor';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';

import BlogPage from '../desktop/main';
import history from '../../../routes/history';
import './styles.scss';
import MobileBlogPageQr from './qr';
import SettingsBlogNavigationsMobile from './navigation';
import SettingBlogPage from '../desktop/settingMain';
import BlogSettingLinks from '../desktop/settingLinks';
import Button from '../../../components/button';
import EditArticlePage from '../desktop/editArticle';
import NewArticlePage from '../desktop/newArticle';
import ArticlePage from '../desktop/article';
import CreateBlogPage from '../desktop/createBlog';
import ArticlesAllPage from './allArticles';

const getTitle = (path, id) => {
  switch (path) {
    case Pages.profile.awards:
      return 'Награды';
    case Pages.blog.articles.replace(':id', id):
      return 'Статьи';
    case Pages.blog.qr.replace(':id?', id):
      return 'QR-код';
    default:
      return 'Блог';
  }
};

const BlogSectionMobile = ({ onSubmit, isDisabled }) => {
  const { location } = history;
  const isQrCodePage = useRouteMatch(Pages.blog.qr);
  const isSettingsPage = useRouteMatch(Pages.blog.settings.main);
  const isSettingsBlogNaviPage = useRouteMatch(Pages.blog.navi);
  const isSettingsLinksPage = useRouteMatch(Pages.blog.settings.links);
  const isArticlesPage = useRouteMatch(Pages.blog.articles);
  const isArticlePage = useRouteMatch(Pages.blog.article);
  const isMainPage =
    !isQrCodePage &&
    !isSettingsPage &&
    !isSettingsLinksPage &&
    !isSettingsBlogNaviPage &&
    !isArticlePage &&
    !isArticlesPage;
  const title = getTitle(location.pathname, location.state);

  return (
    <>
      <HeaderConstructor className="profile mobile">
        <HeaderLeft>
          {isMainPage ? <SidebarButton /> : <GoBackButton />}
          {isMainPage ? null : <HeaderTitle title={title} />}
        </HeaderLeft>
        {!isMainPage ? (
          <HeaderRight>
            {isSettingsPage && !isSettingsLinksPage ? (
              <Button disabled={isDisabled} onClick={onSubmit} secondVariant="text" variant="done">
                Готово
              </Button>
            ) : null}
          </HeaderRight>
        ) : (
          <HeaderRight>
            <>
              <Controls />
              <Menu />
            </>
          </HeaderRight>
        )}
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.blog.navi}
            component={SettingsBlogNavigationsMobile}
            extraProps={location.state}
          />
          <PrivateRoute exact path={Pages.blog.articleEdit} component={EditArticlePage} />
          <PrivateRoute exact path={Pages.blog.createArticle} component={NewArticlePage} />
          <PrivateRoute exact path={Pages.blog.settings.main} component={SettingBlogPage} />
          <PrivateRoute path={Pages.blog.article} component={ArticlePage} />
          <PrivateRoute exact path={Pages.blog.settings.links} component={BlogSettingLinks} />
          <PrivateRoute exact path={Pages.blog.qr} component={MobileBlogPageQr} />
          <PrivateRoute exact path={Pages.blog.articles} component={ArticlesAllPage} />
          <PrivateRoute exact path={Pages.blog.createBlog} component={CreateBlogPage} />
          <PrivateRoute exact path={Pages.blog.main} component={BlogPage} />
        </Switch>
      </div>
    </>
  );
};

export default BlogSectionMobile;
