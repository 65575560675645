import { catchWrapper } from '../../../helpers/utility';
import { postTeams } from '../../../api';

export default ({ page, members, geoLocal, recruitment, query }) => async (dispatch) => {
  dispatch({ type: 'TEAMS/LOAD_ALL_TEAMS_FILTERED/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getTeamList');
  formData.append('objects_per_page', '12');

  if (recruitment) {
    formData.append('filter[is_selection]', recruitment);
  }

  if (query) {
    formData.append('filter[query]', query);
  }

  if (page) {
    formData.append('page', page.toString());
  }

  if (members && members.min) {
    formData.append('filter[members_from]', members.min);
  }
  if (members && members.max) {
    formData.append('filter[members_to]', members.max);
  }

  if (geoLocal?.country && !geoLocal?.regionArr?.length) {
    formData.append('filter[regions][0][country]', geoLocal.country.id || null);
    formData.append('filter[regions][0][region]', null);
    formData.append('filter[regions][0][city]', null);
  }
  if (geoLocal?.regionArr?.length) {
    geoLocal.regionArr.forEach((regOpt, index) => {
      formData.append(`filter[regions][${index}][country]`, geoLocal.country.id);
      formData.append(`filter[regions][${index}][region]`, regOpt.region_id);
      formData.append(`filter[regions][${index}][city]`, !regOpt.isRegion ? regOpt.id : null);
    });
  }
  const result = await catchWrapper(postTeams('team', formData));

  if (result && result.result) {
    dispatch({ type: 'TEAMS/LOAD_ALL_TEAMS_FILTERED/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'TEAMS/LOAD_ALL_TEAMS_FILTERED/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
