import React, { memo } from 'react';

import ConversationsItemMobile from '../conversations-item-mobile';

function ConversationsListMobile({
  setReceiverInfo,
  conversationList,
  language,
  deleteConversation,
}) {
  if (!conversationList.length || !conversationList) {
    return null;
  }

  return (
    <div>
      {conversationList.map((conversation) => (
        <ConversationsItemMobile
          setReceiverInfo={setReceiverInfo}
          key={conversation.conversationId}
          conversation={conversation}
          language={language}
          deleteConversation={deleteConversation}
        />
      ))}
    </div>
  );
}

export default memo(ConversationsListMobile);
