import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';

import MyFriends from './myFriends';
import IncomingFriends from './incomingFriends';
import OutgoingFriends from './outgoingFriends';
import AllUsers from './all';
import UserFriends from './userFriends';
import CommonFriends from './common';

import HeaderLeft from '../../../components/headerConstructor/components/left';
import InputSearch from '../../../components/inputSearch';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';
import HeaderConstructor from '../../../components/headerConstructor';

import './styles.scss';
import Trans from '../../../components/trans';

const FriendsPageDesktop = (rest) => {
  const { navigationMap, search, onChangeSearch } = rest;
  const isAllUsersPage = useRouteMatch(Pages.users.all);
  const pageTitle = isAllUsersPage ? Trans('friends.menu.players') : Trans('friends.menu.friends');

  return (
    <>
      <HeaderConstructor className="events">
        <HeaderLeft
          title={pageTitle}
          search={<InputSearch value={search} onChange={onChangeSearch} />}
          navigation={<TabletNavigation naviMap={navigationMap} />}
        />
        <HeaderRight>
          <Controls isShowLang />
          <Menu />
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container users">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.users.all}
            component={AllUsers}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.main}
            component={MyFriends}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.requests}
            component={IncomingFriends}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.outRequests}
            component={OutgoingFriends}
            extraProps={{ ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.userFriends}
            component={UserFriends}
            extraProps={{ search, ...rest }}
          />
          <PrivateRoute
            exact
            path={Pages.users.common}
            component={CommonFriends}
            extraProps={{ search, ...rest }}
          />
        </Switch>
      </div>
    </>
  );
};

export default FriendsPageDesktop;
