import React, { useState } from 'react';
import './styles.scss';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const FeedFilter = () => {
  const [selectedDay, setSelectedDay] = useState();
  const modifiers = {
    birthday: new Date(),
  };
  const handleDayClick = (day) => {
    setSelectedDay(day);
  };
  const modifiersStyles = {
    // birthday: {
    //   color: '#4C62FF',
    //   border: '2px solid #4C62FF',
    //   width: 34,
    //   height: 34,
    //   borderRadius: 6,
    //   boxSizing: 'border-box',
    // },
    // thursdays: {
    //   color: '#ffc107',
    //   backgroundColor: '#fffdee',
    // },
  };
  return (
    <DayPicker
      showOutsideDays
      className="calendar"
      modifiers={modifiers}
      modifiersStyles={modifiersStyles}
      onDayClick={handleDayClick}
      selectedDays={selectedDay}
    />
  );
};

export default FeedFilter;
