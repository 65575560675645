class CometChatEvent {
  static triggers = {};

  static on(event, callback) {
    if (!CometChatEvent.triggers[event]) CometChatEvent.triggers[event] = [];
    CometChatEvent.triggers[event].push(callback);
  }

  static triggerHandler(event, params) {
    if (CometChatEvent.triggers[event]) {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in CometChatEvent.triggers[event]) CometChatEvent.triggers[event][i](params);
    }
  }
}

export { CometChatEvent };
