import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 9V11.1237V21.858L13.0004 23.2867L15.0004 24.001V21.8773V11.143L11 9.71429L9 9ZM11 11.838V20.4485L13.0004 21.163V12.5524L11 11.838ZM21.0007 21.8583L17.0008 23.2868L15.0008 24.0011V21.8774V11.1431L19.0012 9.71442L20.9998 9.00064V9L21.0007 9.00032L21.0012 9.00013V9.0005L22.9998 9.71429L27.0002 11.143V21.8773V24.001L25.0002 23.2867L21.0007 21.8583ZM22.9998 11.838V20.4485L25.0002 21.163V12.5524L22.9998 11.838ZM17.0008 12.5526V21.1631L19.0012 20.4487V11.8381L17.0008 12.5526Z"
      fill="currentColor"
    />
  </svg>
);
