import Profile from './profile';
import Passport from './passport';
import Links from './links';
import Private from './private';
import Security from './security';
import GoBack from './goBack';

export default {
  'profile-30': Profile,
  'passport-30': Passport,
  'links-30': Links,
  'private-30': Private,
  'security-30': Security,
  goBack: GoBack,
};
