import React from 'react';

export default () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.96C0 0.429785 0.434126 0 0.969697 0H15.0303C15.5658 0 16 0.429785 16 0.96V11.04C16 11.5702 15.5658 12 15.0303 12H0.969697C0.434126 12 0 11.5702 0 11.04V0.96ZM8.05481 5.63479L2.36263 0.938731C2.05409 0.684142 1.59547 0.72545 1.33836 1.0309C1.0812 1.33641 1.12287 1.79039 1.43146 2.04498L7.57103 7.11005C7.83539 7.34366 8.23976 7.35662 8.5202 7.12529L14.6755 2.04715C14.9841 1.79262 15.0257 1.33857 14.7687 1.03313C14.5115 0.727618 14.0529 0.686366 13.7443 0.940956L8.05481 5.63479Z"
      fill="#C0B4AF"
    />
  </svg>
);
