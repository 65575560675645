import React from 'react';

import SideSwitch from '../sideSwitch';

import './styles.scss';

const EventParticipantsFilter = ({ handleChangeSide, side }) => {
  return (
    <div className="participants-filtersWrap">
      <div className="be-like-filters">
        <div className="be-like-filters__type">
          <span className="be-like-filters__label">Сторона</span>
          <SideSwitch side={side} onChange={handleChangeSide} />
        </div>
      </div>
    </div>
  );
};

export default EventParticipantsFilter;
