import React from 'react';

export default () => (
  <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 0C16.1286 0.130339 19.8438 4.15391 19.8438 9.1C19.8438 9.30991 19.8372 9.51808 19.8239 9.72296C19.8106 9.92775 19.7906 10.1309 19.7656 10.3323C19.7457 10.4829 19.7223 10.6302 19.6974 10.7774L19.6408 11.077C19.5227 11.6865 19.5526 12.4024 19.7024 13.1151C19.7457 13.3216 19.7989 13.5281 19.8605 13.7296C19.912 13.8988 19.9703 14.0664 20.0351 14.2306C20.1466 14.5149 20.2731 14.779 20.4078 15.0143C20.4528 15.0939 20.4994 15.17 20.5459 15.2428C20.6158 15.3494 20.6857 15.451 20.7572 15.5425C20.8154 15.6169 20.8721 15.6829 20.9269 15.7422C21.0051 15.8268 21.0783 15.8945 21.1449 15.9487C22.2463 16.4853 23 17.5839 23 18.85C23 20.6443 21.486 22.1 19.6176 22.1H16.1869H16.1079C16.1079 22.1001 16.1079 22.1003 16.1078 22.1004L6.89185 22.0988C6.89172 22.0981 6.89159 22.0973 6.89146 22.0966H6.81315L4.26092 22.0983L3.38245 22.0966C1.51403 22.0966 0 20.6426 0 18.8466C0 17.5805 0.75369 16.4819 1.8551 15.947C1.91833 15.8945 1.98654 15.8319 2.05975 15.754C2.11798 15.6914 2.17954 15.6203 2.24276 15.5391C2.50731 15.2006 2.7602 14.7502 2.96484 14.2272C3.3758 13.1845 3.54051 12.0047 3.35916 11.0754L3.29593 10.7436C3.27097 10.598 3.24935 10.4524 3.23105 10.3035L3.20443 10.0699C3.17282 9.75 3.15618 9.425 3.15618 9.09662C3.15618 4.15052 6.87139 0.126953 11.5 0ZM7.21114 23.155C7.93746 24.8305 9.58453 26 11.5 26C13.415 26 15.0615 24.8312 15.7882 23.1566L7.21114 23.155Z"
      fill="currentColor"
    />
  </svg>
);
