import React, { memo } from 'react';

import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const Button = ({
  children,
  label,
  variant,
  secondVariant,
  ripple,
  className,
  tabIndex,
  refElem,
  disabled,
  onClick,
  loading,
  ...props
}) => {
  const classNames = cx('button', className, {
    [`button--${variant}`]: variant,
    [`button--${secondVariant}`]: secondVariant,
    ripple,
  });

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={refElem}
      tabIndex={tabIndex}
      className={classNames}
      disabled={loading || disabled}
      onClick={onClick}
      {...props}
    >
      {children || null}
      {label || null}
    </button>
  );
};

export default memo(Button);
