import React from 'react';
import { isEmpty } from 'lodash';

import GroundImages from '../../../groundImages';
import Skeleton from '../../../../../../components/skeleton';
import EntityPreview from '../../../../../components/entityPreview';
import Icon from '../../../../../../components/icon';
import Button from '../../../../../../components/button';

import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { Pages } from '../../../../../../routes/pages';

import './styles.scss';
import Trans from '../../../../../../components/trans';

const GroundPage = ({
  ground,
  loading,
  handleOpenModal,
  canUserEdit,
  goToSettings,
  isRequest = true,
}) => (
  <div className="ground-page">
    {!isEmpty(ground?.images) && <GroundImages images={ground?.images} />}
    <div className="ground-page__content">
      <div className="inner">
        <div className="card ground-page__content__info">
          {loading ? (
            <Skeleton className="event-info-sk__sponsor-1" />
          ) : (
            <>
              <EntityPreview
                toLink={Pages.organizers.main}
                item={ground.owner}
                label={Trans('fields.cards.owner')}
              />
              {isRequest && (
                <>
                  <div className="ground-info-item">
                    <Icon type="phoneNoBox" />
                    <a target="_blank" href={`tel:+${ground.phone}`} rel="noreferrer">
                      {ground.phone}
                    </a>
                  </div>
                  <div className="ground-info-item">
                    <Icon type="emailNoBox" />
                    <a target="_blank" href={`mailto:${ground.email}`} rel="noreferrer">
                      {ground.email}
                    </a>
                  </div>
                </>
              )}
              <div className="ground-info-item">
                <b>
                  {Trans('fields.cards.locationGPS')}{' '}
                  <a
                    target="_blank"
                    href={`https://www.google.com/maps/dir//${ground.gps}/@${ground.gps}`}
                    rel="noreferrer"
                  >
                    {ground.gps}
                  </a>
                </b>
              </div>
              <div className="ground-info-item small">
                <span>
                  {ground?.location?.country?.title} / {ground?.location?.region?.title} /{' '}
                  {ground?.location?.city?.title}
                </span>
              </div>
              {isRequest && (
                <Button
                  onClick={() => handleOpenModal()}
                  variant="main"
                  className="add send-request"
                >
                  {Trans('fields.cards.request')}
                </Button>
              )}
              {canUserEdit && (
                <Button onClick={goToSettings} className="setting" variant="second">
                  {Trans('fields.cards.settings')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="inner">
        <div className="card ground-page__content__text">
          {ground?.profile?.description ? (
            <div dangerouslySetInnerHTML={getDangerouslyHtml(ground.profile.description)} />
          ) : (
            '...'
          )}
        </div>
      </div>
    </div>
  </div>
);

export default GroundPage;
