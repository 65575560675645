import React from 'react';
// import ThemeSwitch from '../../../themeSwitch';

// import LanguageButton from '../../../../pages/components/languageButton';
// import Button from '../../../button';
// import Icon from '../../../icon';
// import history from '../../../../routes/history';
// import { Page } from '../../../../routes/pages';

import './styles.scss';

const Controls = ({ isShowLang }) => (
  // const handleToFeed = () => history.push(Page.feed);
  <div className="controls__wrap">
    {/*<ThemeSwitch />*/}
    {/*{isShowLang ? <LanguageButton label="RU" /> : null}*/}
    {/*<Button variant="icon" className="feed" onClick={handleToFeed}>*/}
    {/*  <Icon type="news" />*/}
    {/*</Button>*/}
    {/*<Button variant="icon" className="notifications">*/}
    {/*  <Icon type="notifications" />*/}
    {/*</Button>*/}
  </div>
);
export default Controls;
