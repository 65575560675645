import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useResponsive } from '../../../../hooks/useResponsive';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import useVisible from '../../../../hooks/useVisible';

import Spinner from '../../../../components/spinner';
import Icon from '../../../../components/icon';
import DropdownWithSchema from '../../dropdown';
import Skeleton from '../../../../components/skeleton';
import Button from '../../../../components/button';

import { getUserByUid } from '../../../../store/msg/actions/conversations';

const OwnerButton = () => {
  const { isMobile } = useResponsive();
  const goToEdiPage = () => {
    if (isMobile) {
      history.push(Pages.settings.navi);
    } else {
      history.push(Pages.settings.profile);
    }
  };
  return (
    <Button onClick={goToEdiPage} className="owner-btn">
      Настройки
    </Button>
  );
};

const OtherUserButton = ({
  sentFriendRequest,
  isFriend,
  isRequestWasSent,
  removeFriend,
  getUserSilent,
  loadingSilent,
  cancelFriendRequest,
  setReceiverInfo,
  chatUid,
}) => {
  const { id } = useParams();
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const handleAddFriend = async () => {
    await sentFriendRequest(id);
    await getUserSilent(id);
  };

  const handleCancelRequest = async () => {
    await cancelFriendRequest(id);
    await getUserSilent(id);
  };

  // Один запрос для удаление из друзей и для отмены запроса
  const handleRemoveFriends = async () => {
    await removeFriend(id);
    await getUserSilent(id);
    setIsVisible(false);
  };

  const handleOpenMenu = () => setIsVisible(!isVisible);

  const inFriendsSchema = [
    {
      id: '1',
      label: 'Удалить из друзей',
      action: handleRemoveFriends,
    },
    {
      id: '2',
      label: 'Пожаловаться',
    },
    {
      id: '3',
      label: 'Добавить в черный список',
    },
  ];

  const handleSendMessage = useCallback(() => {
    getUserByUid(chatUid)
      .then((user) => {
        setReceiverInfo(user);
        history.push({
          pathname: Pages.msg.base,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-prototype-builtins
        const errorCode = error && error?.hasOwnProperty('code') ? error.code : 'uid not available';
        console.log(errorCode);
      });
  }, [chatUid, setReceiverInfo]);

  return (
    <>
      {!isFriend && !isRequestWasSent ? (
        <Button onClick={handleAddFriend} className="owner-btn owner-btn__friends">
          {loadingSilent ? <Spinner size={20} /> : 'Добавить в друзья'}
        </Button>
      ) : null}

      {!isFriend && isRequestWasSent ? (
        <Button onClick={handleCancelRequest} className="owner-btn owner-btn__was_sent">
          {loadingSilent ? <Spinner size={20} /> : 'Отменить заявку'}
        </Button>
      ) : null}

      {isFriend ? (
        <Button onClick={handleOpenMenu} className="owner-btn owner-btn__in_friends">
          {loadingSilent ? (
            <Spinner size={20} />
          ) : (
            <div className="owner-btn__in_friends__wrap">
              <span className="owner-btn__text">В друзьях</span>
              <Icon type="chevronSmall" />
            </div>
          )}
          {isVisible && <DropdownWithSchema dropRef={ref} schema={inFriendsSchema} />}
        </Button>
      ) : null}

      <Button
        onClick={handleSendMessage}
        disabled={!chatUid}
        className="owner-btn owner-btn__message"
      >
        Написать
      </Button>
    </>
  );
};

const ButtonsBlock = ({
  loading,
  accountHolder,
  sentFriendRequest,
  isFriend,
  isRequestWasSent,
  removeFriend,
  getUserSilent,
  loadingSilent,
  cancelFriendRequest,
  setReceiverInfo,
  chatUid,
}) =>
  // eslint-disable-next-line no-nested-ternary
  loading ? (
    <Skeleton className="sk-owner-btn" />
  ) : accountHolder ? (
    <OwnerButton />
  ) : (
    <OtherUserButton
      sentFriendRequest={sentFriendRequest}
      isFriend={isFriend}
      isRequestWasSent={isRequestWasSent}
      removeFriend={removeFriend}
      getUserSilent={getUserSilent}
      loadingSilent={loadingSilent}
      cancelFriendRequest={cancelFriendRequest}
      setReceiverInfo={setReceiverInfo}
      chatUid={chatUid}
    />
  );
export default ButtonsBlock;
