import React from 'react';
import Skeleton from '../../../components/skeleton';

const SkeletonAbout = () => {
  return (
    <>
      <Skeleton className="sk-about-title" />
      <Skeleton className="sk-about-text" />
      <Skeleton className="sk-about-text" />
      <Skeleton className="sk-about-text" />
      <Skeleton className="sk-about-text" />
      <Skeleton className="sk-about-text" />
    </>
  );
};

export default SkeletonAbout;
