import React from 'react';
import { Link } from 'react-router-dom';

import { classNames as cx } from '../../../../helpers/utility';
import { Pages } from '../../../../routes/pages';

import dummy from '../../../../assets/image/user.svg';

const UserCardInfo = ({ user, ownerId }) => {
  const { image_avatar: imageAvatar } = user;
  const classNames = cx('user-card__user-container__image', {
    'user-card__user-container__image_dummy': !(imageAvatar && imageAvatar?.preview),
  });
  const urlPreview = imageAvatar?.preview ? imageAvatar?.preview : dummy;
  const nicknamePlaceholder = 'Позывной';

  const url =
    user.id === ownerId
      ? Pages.profile.base
      : {
          pathname: Pages.profile.main.replace(':id?', user.id),
          state: user?.id,
        };

  const teamUrl = {
    pathname: Pages.team.main.replace(':id', user?.team?.id),
    state: user?.team?.id,
  };

  return (
    <div className="user-card__user-container">
      <Link to={url}>
        <div className={classNames}>
          <img src={urlPreview} alt="avatar" />
        </div>
        {user?.state_online && <div className="user-card__user-container__online" />}
      </Link>

      <div className="user-card__user-container__info">
        <Link to={url}>
          <div className="user-card__user-container__info__nickname">
            {user?.nickname || nicknamePlaceholder}
          </div>
        </Link>
        {user?.team && (
          <Link to={teamUrl}>
            <div className="user-card__user-container__info__team">{user?.team?.title}</div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default UserCardInfo;
