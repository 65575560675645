export default (data) => (dispatch, getState) => {
  const old = getState().sponsor.sponsorMembers;
  const { user, groupIn, groupOut } = data;

  const dataOut = old[groupOut];
  const dataIn = old[groupIn];
  let newDataOut;
  let newDataIn;
  let newTeamMembers;

  //remove item
  if (groupOut === 'deputy' || groupOut === 'users' || groupOut === 'remove') {
    newDataOut = dataOut.filter((u) => u.id !== user.id);
  }
  if (groupOut === 'groups' && groupOut !== groupIn) {
    newDataOut = dataOut.map((t) => {
      if (t.id === data.groupOutId) {
        if (data.groupOutType) {
          return {
            ...t,
            commander: null,
          };
        }
        const platUsers = Array.from(t.users);
        const platUsersFiltered = platUsers.filter((u) => u.id !== user.id);
        return {
          ...t,
          users: platUsersFiltered,
        };
      }
      return t;
    });
  }

  //add item
  if (
    (groupIn === 'deputy' || groupIn === 'users' || groupIn === 'remove') &&
    groupIn !== 'commander'
  ) {
    newDataIn = [...dataIn, user];
  }
  if (groupIn === 'groups' && groupOut !== groupIn && groupIn !== 'commander') {
    newDataIn = dataIn.map((t) => {
      if (t.id === data.groupId) {
        if (data.groupInType) {
          if (t.commander) {
            return {
              ...t,
              commander: user,
              users: [...t.users, t.commander],
            };
          }
          return {
            ...t,
            commander: user,
            users: [...t.users],
          };
        }

        const platUsers = Array.from(t.users);
        const platUsersAdd = [...platUsers, user];
        return {
          ...t,
          users: platUsersAdd,
        };
      }
      return t;
    });
  }

  newTeamMembers = {
    ...old,
    [groupOut]: newDataOut,
    [groupIn]: newDataIn,
  };

  //only groups change item
  let newGroups;
  if (groupOut === groupIn) {
    const tmp = Array.from(old.groups);
    newGroups = tmp.map((team) => {
      if (team.id === data.groupOutId) {
        if (data.groupOutType) {
          return {
            ...team,
            commander: null,
          };
        }
        const platUsers = Array.from(team.users);
        const platUsersFiltered = platUsers.filter((u) => u.id !== user.id);
        return {
          ...team,
          users: platUsersFiltered,
        };
      }
      return team;
    });

    newGroups = newGroups.map((team) => {
      if (team.id === data.groupId) {
        if (data.groupInType) {
          if (team.commander) {
            return {
              ...team,
              commander: user,
              users: [...team.users, team.commander],
            };
          }
          return {
            ...team,
            commander: user,
          };
        }
        const platUsers = Array.from(team.users);
        const platUsersAdd = [...platUsers, user];

        return {
          ...team,
          users: platUsersAdd,
        };
      }
      return team;
    });

    newTeamMembers = {
      ...old,
      groups: newGroups,
    };
  }

  //change commander team

  if (groupIn === 'commander') {
    if (groupOut === 'deputy') {
      const newDeputy = [...newDataOut, old.commander];
      newTeamMembers = {
        ...old,
        commander: user,
        deputy: newDeputy,
      };
    } else {
      const newDeputy = [...old.deputy, old.commander];
      newTeamMembers = {
        ...old,
        [groupOut]: newDataOut,
        commander: user,
        deputy: newDeputy,
      };
    }
  }

  dispatch({
    type: 'SPONSOR/CHANGE_SPONSOR_MEMBER',
    payload: newTeamMembers,
  });
};
