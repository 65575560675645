export const getAchievementType = (location) => {
  const pathStr = location.pathname.split('/').pop();

  switch (pathStr) {
    case 'achievements':
      return 1;
    case 'awards':
      return 2;
    case 'titles':
      return 3;
    default:
      return 1;
  }
};
