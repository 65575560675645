import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 7V9.12372V19.858L10.0004 21.2867L12.0004 22.001V19.8773V9.143L8 7.71429L6 7ZM8 9.83801V18.4485L10.0004 19.163V10.5524L8 9.83801ZM18.0007 19.8583L14.0008 21.2868L12.0008 22.0011V19.8774V9.14313L16.0012 7.71442L17.9998 7.00064V7L18.0007 7.00032L18.0012 7.00013V7.0005L19.9998 7.71429L24.0002 9.143V19.8773V22.001L22.0002 21.2867L18.0007 19.8583ZM19.9998 9.83801V18.4485L22.0002 19.163V10.5524L19.9998 9.83801ZM14.0008 10.5526V19.1631L16.0012 18.4487V9.83814L14.0008 10.5526Z"
      fill="currentColor"
    />
  </svg>
);
