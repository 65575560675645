import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import { Pages } from '../../../../routes/pages';

const UserCardExtended = ({ teamBlock, user, ownerId }) => {
  const commonUrl = {
    pathname: Pages.users.common.replace(':id', user?.id),
    state: user?.id,
  };

  const style = {
    color: `#${user?.sign?.color}`,
    borderColor: `#${user?.sign?.color}`,
  };

  return (
    <div className="user-card__extended">
      <div className="user-card__extended-container">
        {/*<div className="user-card__extended-container-message">*/}
        {/*  <Button onClick={() => {}} variant="submenu">*/}
        {/*    <Icon type="message" />*/}
        {/*  </Button>*/}
        {/*</div>*/}
        {ownerId === user.id ? null : (
          <Link to={commonUrl}>
            <div className="user-card__extended-container-friends">
              {user?.qty_of_mutual_friends ? `${user.qty_of_mutual_friends} общих друзей` : null}
            </div>
          </Link>
        )}
      </div>
      {teamBlock && user?.sign?.title && (
        <div style={style} className="user-card__extended-team">
          {user?.sign?.title}
        </div>
      )}
    </div>
  );
};

export default UserCardExtended;
