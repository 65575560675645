import { catchWrapper } from '../../../helpers/utility';
import { getAchievements } from '../../../api/v2';

export default ({ page, userId, type }) => async (dispatch) => {
  dispatch({ type: 'ACHIEVEMENTS/GET_LIST/REQUESTED' });

  const payloadRequest = {
    page,
    objects_per_page: 12,
    user_id: userId,
    type,
  };

  const result = await catchWrapper(getAchievements('list', payloadRequest));

  if (result && result.result) {
    dispatch({ type: 'ACHIEVEMENTS/GET_LIST/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'ACHIEVEMENTS/GET_LIST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
