import React from 'react';

export default () => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9.5C7 7.62485 7.02008 7 9.5 7C11.9799 7 12 7.62485 12 9.5C12 11.3752 12.0079 12 9.5 12C6.99209 12 7 11.3752 7 9.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9.5C15 7.62485 15.0201 7 17.5 7C19.9799 7 20 7.62485 20 9.5C20 11.3752 20.0079 12 17.5 12C14.9921 12 15 11.3752 15 9.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 17.5C7 15.6248 7.02008 15 9.5 15C11.9799 15 12 15.6248 12 17.5C12 19.3752 12.0079 20 9.5 20C6.99209 20 7 19.3752 7 17.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 17.5C15 15.6248 15.0201 15 17.5 15C19.9799 15 20 15.6248 20 17.5C20 19.3752 20.0079 20 17.5 20C14.9921 20 15 19.3752 15 17.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2388 1C19.2388 1 7.72217 1.00543 7.70417 1.00543C3.56376 1.02852 1 3.49457 1 7.25611V19.7439C1 23.5244 3.58326 26 7.75968 26C7.75968 26 19.2748 25.9959 19.2943 25.9959C23.4347 25.9728 26 23.5054 26 19.7439V7.25611C26 3.47556 23.4152 1 19.2388 1Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
