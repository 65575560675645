import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import history from '../../../../routes/history';
import './styles.scss';
import { useActions, useSelector } from '../../../../hooks/store';
import { blogActions } from '../../../../store/blog';
import UserCardInfo from '../../components/userCardInfo';
import { getDangerouslyHtml } from '../../../../helpers/utility';
import Button from '../../../../components/button';
import { Pages } from '../../../../routes/pages';

const ArticlePage = () => {
  const { id } = useParams();
  const { article } = useSelector((s) => s.blog);
  const { token, userId } = useSelector((s) => s.auth);
  const { getArticle } = useActions(blogActions);
  useEffect(() => {
    if (token) {
      if (id) {
        getArticle(id);
      }
    }
  }, [id, token]);
  const articleText = article?.profile?.text;
  const articleTitle = article?.profile?.title;
  const urlPreview = article?.image?.image;
  const articleHolder = article?.blog?.user?.id === userId;
  const goToArticleEdit = () => {
    history.push({
      pathname: Pages.blog.articleEdit.replace(':id', id),
      state: id,
    });
  };
  return (
    <div className="blog-page__article">
      {article && (
        <>
          <div className="blog-page__article__info">
            <div className="blog-page__article__info__card">
              <UserCardInfo blog={article} ownerId={article?.blog?.id} />
            </div>
            <div>
              {articleHolder && (
                <Button onClick={goToArticleEdit} tabIndex="0" variant="main">
                  Редактировать
                </Button>
              )}
            </div>
          </div>
          <div className="blog-page__article__image">
            <img src={urlPreview} alt="image" />
          </div>
          <h2>{articleTitle}</h2>
          <div>
            {articleText && (
              <div
                className="info-text"
                dangerouslySetInnerHTML={getDangerouslyHtml(articleText)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ArticlePage;
