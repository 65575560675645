import React, { memo } from 'react';
import Slider from 'react-slick';

import BlockHeader from '../blockHeader';
import EventCard from '../eventCard';
import ButtonArrow from '../../../components/button/buttonArrow';

import './styles.scss';

const Activity = ({ eventsList }) => {
  const setting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <ButtonArrow />,
    prevArrow: <ButtonArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="activity-block">
      <BlockHeader title="Активность" count={eventsList?.length} />
      <div className="activity-block__wrap">
        <Slider {...setting}>
          {eventsList?.map((item) => (
            <EventCard key={item.id} event={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default memo(Activity);
