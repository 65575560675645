import React from 'react';

import { useResponsive } from '../../hooks/useResponsive';

import ProfileSectionMobile from './mobile';
import ProfileSectionDesktop from './desktop';

const ProfileSection = () => {
  const { isMobile } = useResponsive();

  return isMobile ? <ProfileSectionMobile /> : <ProfileSectionDesktop />;
};

export default ProfileSection;
