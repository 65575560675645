import React, { useState, useEffect } from 'react';
import CodeInput from '../../../components/codeInput';
import Button from '../../../components/button';
import { useResponsive } from '../../../hooks/useResponsive';

const StepCode = ({ code, onChange, action, error, clearError, type, numFields }) => {
  const [countDown, setCountDown] = useState(30);

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (!countDown) return;

    const intervalId = setInterval(() => {
      setCountDown(countDown - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [countDown]);

  const handleOnAction = () => {
    clearError();
    action();
    setCountDown(30);
  };

  return (
    <>
      <div className="modal-content__code">
        <div className="modal-content__left">
          <span className="modal-content__label">Полученный код</span>
          <CodeInput fields={numFields || 6} onChange={onChange} value={code} type={type} />
          <span className="modal-content__text-error">{error}</span>
        </div>
        <div className="modal-content__right">
          <Button onClick={handleOnAction} disabled={countDown} variant="text">
            Отправить код повторно
          </Button>
          {countDown === 0 ? null : (
            <span className="modal-content__counter">{`Через ${countDown} секунд`}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default StepCode;
