import moment from 'moment';
import { catchWrapper } from '../../../helpers/utility';
import { createTeam } from '../../../api/v2';
import { goToTeamSetting } from '../../../routes/actions';
import { validationCreateTeam } from '../validation';
import onChangeField from './onChangeField';
import onCheckAuth from '../../auth/impl/checkAuth';

export default () => async (dispatch, getState) => {
  const { team, teamImageTemp } = getState().teams;

  dispatch(onChangeField('errors', null));

  const formData = new FormData();

  const error = validationCreateTeam({ team, teamImageTemp });

  if (teamImageTemp.image) {
    const { blob, file } = teamImageTemp.image;
    formData.append('imageBlob', blob);
    formData.append('imageFile', file);
  }
  if (teamImageTemp.photo) {
    const { blob, file } = teamImageTemp.photo;
    formData.append('photoBlob', blob);
    formData.append('photoFile', file);
  }
  if (teamImageTemp.chevron) {
    const { blob, file } = teamImageTemp.chevron;
    formData.append('chevronBlob', blob);
    formData.append('chevronFile', file);
  }

  formData.append('element', JSON.stringify(team));

  if (error) {
    dispatch(onChangeField('errors', error));
  } else {
    dispatch({ type: 'TEAMS/CREATE_TEAM/REQUESTED' });

    const result = await catchWrapper(createTeam(formData));

    if (result?.result) {
      dispatch({ type: 'TEAMS/CREATE_TEAM/SUCCEEDED' });
      result.team.date = result.team.date ? moment(result.team.date).format('YYYY-MM-DD') : null;
      dispatch({ type: 'TEAMS/LOAD_TEAMS/SUCCEEDED', payload: result.team });
      dispatch(onCheckAuth());
      goToTeamSetting(result.team.id);
    } else {
      dispatch({
        type: 'TEAMS/CREATE_TEAM/FAILED',
        payload: result?.message && result.message[0],
      });
    }
  }
};
