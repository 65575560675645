import React from 'react';
import { useActions, useSelector } from '../../hooks/store';
import { themeActions } from '../../store/theme';

import './styles.scss';
import Trans from '../trans';

const ThemeSwitch = () => {
  const { theme, loading } = useSelector((state) => state.theme);
  const actions = useActions(themeActions);
  const handleChangeTheme = () => {
    if (loading === 0) {
      actions.changeTheme({ theme });
    }
  };

  return (
    <div className="theme">
      <div className="theme-name">{Trans('navigation.darkTheme')}</div>
      <div className="theme-toggle">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="theme-toggle-switch">
          <input type="checkbox" checked={theme === 1} onChange={handleChangeTheme} />
          <span className="slider" />
        </label>
      </div>
    </div>
  );
};

export default ThemeSwitch;
