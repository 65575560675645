import React, { useEffect, useRef, useState } from 'react';

import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';

import { useResponsive } from '../../../../hooks/useResponsive';
import { useActions, useSelector } from '../../../../hooks/store';
import { teamsActions } from '../../../../store/teams';
import useDebounce from '../../../../hooks/useDebounce';
import { cancelTokens, globalCancelFetch } from '../../../../api/v2';

import MobileControls from '../../../components/mobile-controls';
import RecruitingList from '../../components/recruitingList';

import modalService from '../../../../services/modalService';

import './styles.scss';
import Trans from '../../../../components/trans';

const initGeo = {
  country: {},
  regionArr: [],
  selectedRegions: [],
  selectedCities: [],
  init: false,
};
const initCount = { min: 18, max: 100 };
const rangeName = Trans('teams.filter.age');

const pageMainUrl = '/recruiting/list';

const RecruitingTeams = ({
  search,
  onChangeSearch,
  isRefreshPage,
  handleToggleRefreshPage,
  openRecruitingModal,
  navigationMap,
  isRecruitingRequest,
}) => {
  const { isMobile } = useResponsive();
  const [isFilterOpened, toggleFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [geoLocal, setGeoLocal] = useState(initGeo);
  const [count, setCount] = useState(initCount);
  const containerEl = useRef(null);

  const { getAllRecruiting, onClearGeneralPages } = useActions(teamsActions);
  const { recruits, pagination, loading } = useSelector((state) => state.teams);
  const { userId } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  const debouncedSearchTerm = useDebounce(search, 500);

  const getList = (p) =>
    getAllRecruiting({
      page: p,
      age: count,
      geoLocal,
      query: debouncedSearchTerm || null,
    });

  const onChangeComplete = () => {
    setPage(1);
    onClearGeneralPages();
    if (cancelTokens[pageMainUrl]) {
      cancelTokens[pageMainUrl]();
    }
    getList(1);
  };

  useEffect(() => {
    onChangeComplete();
  }, [geoLocal, debouncedSearchTerm, count]);

  useEffect(() => {
    if (isRefreshPage) {
      onClearGeneralPages();
      getList(1);
      handleToggleRefreshPage();
    }
  }, [isRefreshPage]);

  useEffect(
    () => () => {
      onClearGeneralPages();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const handleVisibleFilter = (value) => {
    toggleFilter(value);
  };
  const handleChangeCount = (value) => setCount(value);

  const handleChangeCountry = (value) => {
    setGeoLocal({ ...geoLocal, country: value, init: true });
  };

  const handleChangeGeo = (value) => {
    const selectedRegions = value
      ? value.filter((item) => item.isRegion).map((item) => item.region_id)
      : [];
    const selectedCities = value ? value.filter((item) => !item.isRegion) : [];
    setGeoLocal({
      ...geoLocal,
      regionArr: value
        ? value.filter((item) => item.isRegion || selectedRegions.indexOf(item.region_id) === -1)
        : value,
      selectedRegions,
      selectedCities,
      init: true,
    });
  };

  const handleClearFilter = () => {
    setCount(initCount);
    setGeoLocal({
      ...geoLocal,
      country: {},
      regionArr: [],
      selectedRegions: [],
      selectedCities: [],
      init: false,
    });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setCount({ ...count, [name]: value });
  };

  const fetchPaginated = () => {
    const newPage = page + 1;
    setPage(newPage);
    getList(newPage);
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  useEffect(() => {
    if (
      recruits?.length &&
      hasMore &&
      document.body.offsetHeight > containerEl.current.offsetHeight
    ) {
      fetchPaginated();
    }
  }, [recruits]);

  const handleGoToAddRequestPage = () => history.push(Pages.teams.recruiting.add);
  const handleClickCard = (e, item) => {
    const { dataset } = e.target;

    if (!user?.team_role) {
      return;
    }
    if (dataset?.userName) {
      history.push({
        pathname: Pages.profile.main.replace(':id?', item?.user?.id),
        state: item?.user?.id,
      });
    }

    if (user?.team_role && !isMobile && !dataset?.userName) {
      modalService.openRecruitingRequestViewModal({
        item,
        ownerId: userId,
      });
    }

    if (user?.team_role && isMobile && !dataset?.userName) {
      history.push({
        pathname: Pages.teams.recruiting.view,
        state: {
          item,
          ownerId: userId,
          userTeamRole: user?.team_role,
        },
      });
    }
  };

  return (
    <div ref={containerEl} className="team-recruiting-page">
      {isMobile && (
        <MobileControls
          placeholder={Trans('friends.filter.findPlaceholder')}
          options={navigationMap}
          needSettings
          isAddButton
          addButtonIcon={isRecruitingRequest ? 'edit' : 'add'}
          addClick={handleGoToAddRequestPage}
          clickAddDisabled={user?.team?.id}
          handleVisibleFilter={handleVisibleFilter}
          isFilterOpened={isFilterOpened}
          onChangeSearch={onChangeSearch}
          search={search}
        />
      )}

      <RecruitingList
        userTeamRole={user?.team_role}
        onClickCard={handleClickCard}
        onClickAdd={openRecruitingModal}
        clickAddDisabled={user?.team?.id}
        userId={userId}
        recruits={recruits}
        fetchPaginated={fetchPaginated}
        hasMore={hasMore}
        loading={loading}
        handleChangeAge={handleChangeCount}
        age={count}
        handleChangeInput={handleChangeInput}
        handleChangeGeo={handleChangeGeo}
        handleChangeCountry={handleChangeCountry}
        geoLocal={geoLocal}
        rangeName={rangeName}
        isFilterOpened={isFilterOpened}
        onChangeComplete={onChangeComplete}
        handleClearFilter={handleClearFilter}
        handleVisibleFilter={handleVisibleFilter}
      />
    </div>
  );
};

export default RecruitingTeams;
