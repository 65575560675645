import React from 'react';

import Button from './index';
import Icon from '../icon';

const ButtonArrow = (props) => {
  const { className, onClick } = props;
  return (
    <Button onClick={onClick} className={className} secondVariant="icon" variant="carousel">
      <Icon type="chevron" />
    </Button>
  );
};

export default ButtonArrow;
