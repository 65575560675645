import React from 'react';
import { useParams } from 'react-router-dom';

import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import { teamRole } from '../../../../helpers/enums';

import Spinner from '../../../../components/spinner';
import Button from '../../../../components/button';
import Skeleton from '../../../../components/skeleton';
import modalService from '../../../../services/modalService';
import Trans from '../../../../components/trans';

const TeamButtonsControl = ({
  userId,
  team,
  userTeam,
  role,
  loading,
  loadingSilent,
  sendRequestJoinTeam,
  cancelRequestJoinTeam,
}) => {
  const { id } = useParams();
  const userTeamId = userTeam && userTeam.id;
  const isMyTeam = id === userTeamId;
  const isUserRequest = team?.isUserRequest;

  const goToSettings = () =>
    history.push({
      pathname: Pages.team.settings.main.replace(':id', id),
      state: id,
    });

  const handleRequestJoinTeam = () => {
    sendRequestJoinTeam(team?.id);
  };

  const handleCancelRequestJoinTeam = () => {
    cancelRequestJoinTeam(team?.id);
  };

  const handleConfirmLeaveTeam = () => {
    modalService.openConfirmModal({
      title: Trans('teams.personnel.leave'),
      content: Trans('teams.personnel.leaveWarn'),
      submit: handleCancelRequestJoinTeam,
    });
  };

  return loading ? (
    <Skeleton className="sk-owner-btn" />
  ) : (
    <div className="buttons__wrap">
      {role && isMyTeam ? (
        <Button onClick={goToSettings} className="setting" variant="second">
          {Trans('actions.settings')}
        </Button>
      ) : null}
      {isMyTeam && role !== teamRole.commander ? (
        <Button
          loading={loadingSilent}
          onClick={handleConfirmLeaveTeam}
          className="leave"
          variant="second"
        >
          {loadingSilent ? <Spinner size={20} /> : Trans('teams.personnel.leaveConfirm')}
        </Button>
      ) : null}
      {!isMyTeam && isUserRequest ? (
        <Button onClick={handleCancelRequestJoinTeam} className="leave" variant="second">
          {loadingSilent ? <Spinner size={20} /> : Trans('teams.personnel.leaveCancel')}
        </Button>
      ) : null}

      {/* eslint-disable-next-line no-nested-ternary */}
      {team?.is_selection ? (
        !userTeamId && !isUserRequest && userId ? (
          <Button onClick={handleRequestJoinTeam} className="enter" variant="save">
            {loadingSilent ? <Spinner size={20} /> : Trans('teams.personnel.join')}
          </Button>
        ) : null
      ) : null}
    </div>
  );
};

export default TeamButtonsControl;
