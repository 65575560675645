import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import CustomInput from '../../../../../components/input';
import Button from '../../../../../components/button';
import Spinner from '../../../../../components/spinner';
import Recaptcha from '../../../../components/recaptcha';
import history from '../../../../../routes/history';
import modalService from '../../../../../services/modalService';
import Trans from '../../../../../components/trans';

const RightBlock = ({ auth, actions }) => {
  const recaptchaRef = useRef(null);
  const [state, setState] = useState({
    email: '',
    password: '',
    nickname: '',
    re_password: '',
    recaptcha: '',
  });
  const buttonRight = Trans('login.buttonRight');

  const { error, loading } = auth;

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...state,
      'g-recaptcha-response': state.recaptcha,
    };

    await actions.onRegister(data);
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };
  const getRecaptcha = (value) => {
    setState({ ...state, recaptcha: value });
  };

  const { location } = history;

  const openTermsModal = (page) => {
    modalService.openTermOfUse({
      page,
    });
  };

  return (
    <div className="auth-fields__right">
      <h3 className="auth-fields__title">{Trans('login.register')}</h3>
      <div className="auth-controls-container">
        {error.register && <span className="auth-fields__error">{error.register}</span>}
        <form autoComplete="off" className="form-registration">
          <CustomInput
            placeholder="profile.nickname"
            name="nickname"
            value={state?.nickname}
            onChange={handleInputChange}
            maxLength="16"
          />
          <CustomInput
            tabIndex="0"
            placeholder="profile.email"
            name="email"
            type="text"
            autoComplete="off"
            value={state.email}
            onChange={handleInputChange}
          />
          <CustomInput
            placeholder="login.password"
            type="password"
            name="password"
            autoComplete="new-password"
            value={state.password}
            onChange={handleInputChange}
            tabIndex="0"
          />
          <CustomInput
            placeholder="login.passwordRepeat"
            type="password"
            name="re_password"
            autoComplete="new-password"
            value={state.re_password}
            onChange={handleInputChange}
          />
        </form>
        <Recaptcha getRecaptcha={getRecaptcha} recaptchaRef={recaptchaRef} />
        <Button
          tabIndex="0"
          onClick={(e) => onSubmit(e)}
          variant="icon"
          secondVariant="auth"
          className="button-registration"
          type="submit"
        >
          {loading ? <Spinner size={30} color="#ffffff" /> : buttonRight}
        </Button>
        <div className="rules">
          <span>{Trans('login.acceptance')}</span>
          <Link className="privacy" onClick={() => openTermsModal('terms')} to={location.pathname}>
            {Trans('login.policy')}
          </Link>
          {Trans('login.and')}
          <Link onClick={() => openTermsModal('rules')} className="term" to={location.pathname}>
            {Trans('login.agreement')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RightBlock;
