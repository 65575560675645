import { catchWrapper } from '../../../helpers/utility';
import { getGround } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default (data) => async (dispatch) => {
  dispatch({ type: 'GROUND/LOAD/REQUESTED' });

  const result = await catchWrapper(getGround(data));

  if (result && result.result) {
    dispatch({ type: 'GROUND/LOAD/SUCCEEDED', payload: result.ground });
    dispatch({ type: 'GROUND/SET_SNAPSHOT', payload: result.ground });
  } else {
    goToNotFound();
    dispatch({
      type: 'GROUND/LOAD/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
