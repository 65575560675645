import React from 'react';

import StatisticsImageBlock from '../statisticsImageBlock';

import games from '../../../assets/image/games.png';
import teams from '../../../assets/image/teams.png';
import org from '../../../assets/image/org.png';
import gamers from '../../../assets/image/gamers.png';

import './styles.scss';

const StatisticsLogin = ({ auth }) => {
  return (
    <div className="social-login-images">
      <div className="social-login-image__action-buttons">
        <StatisticsImageBlock
          value={auth?.counts?.games}
          src={games}
          className="login-count-row games-image"
        />
        <StatisticsImageBlock
          value={auth?.counts?.teams}
          src={teams}
          className="login-count-row teams-image"
        />
        <StatisticsImageBlock
          value={auth?.counts?.sponsors}
          src={org}
          className="login-count-row org-image"
        />
        <StatisticsImageBlock
          value={auth?.counts?.gamers}
          src={gamers}
          className="login-count-row gamers-image"
        />
      </div>
    </div>
  );
};

export default StatisticsLogin;
