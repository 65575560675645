import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 18C30 24.6275 24.6281 30 18 30C11.3719 30 6 24.6275 6 18C6 11.3725 11.3719 6 18 6C24.6281 6 30 11.3725 30 18ZM16.8 13.2C16.8 12.5373 17.3367 12 18 12C18.6632 12 19.2 12.5373 19.2 13.2V16.8H22.8C23.4632 16.8 24 17.3373 24 18C24 18.3689 23.8336 18.699 23.5711 18.9193C23.3626 19.0945 23.0929 19.2 22.8 19.2H19.2V22.8C19.2 23.086 19.0992 23.3484 18.9328 23.5547C18.7126 23.8262 18.3774 24 18 24C17.6789 24 17.3859 23.8738 17.1703 23.6683C16.943 23.4498 16.8 23.1416 16.8 22.8V19.2H13.2C12.5367 19.2 12 18.6626 12 18C12 17.3373 12.5367 16.8 13.2 16.8H16.8V13.2Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 12C17.3367 12 16.8 12.5373 16.8 13.2V16.8H13.2C12.5367 16.8 12 17.3373 12 18C12 18.6626 12.5367 19.2 13.2 19.2H16.8V22.8C16.8 23.1416 16.943 23.4498 17.1703 23.6683C17.3859 23.8738 17.6789 24 18 24C18.3774 24 18.7126 23.8262 18.9328 23.5547C19.0992 23.3484 19.2 23.086 19.2 22.8V19.2H22.8C23.0929 19.2 23.3626 19.0945 23.5711 18.9193C23.8336 18.699 24 18.3689 24 18C24 17.3373 23.4632 16.8 22.8 16.8H19.2V13.2C19.2 12.5373 18.6632 12 18 12Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-4"
        y="-2"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.921569 0 0 0 0 0.576471 0 0 0 0 0.188235 0 0 0 0.3 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="4.95"
        y1="42.2857"
        x2="49.1269"
        y2="38.5226"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E26E43" />
        <stop offset="1" stopColor="#F8CE0E" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
