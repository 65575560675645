import { createAction } from '@reduxjs/toolkit';
import { CometChat } from '@cometchat-pro/chat';
import { MSG_TYPES } from '../constants';

export const setConversationListAction = createAction(MSG_TYPES.SET_CONVERSATION_LIST);
export const setFilterConversationAction = createAction(MSG_TYPES.SET_FILTER_VALUE);

export const setConversationList = (conversationList) => (dispatch) => {
  dispatch(setConversationListAction(conversationList));
};

export const getUserByUid = (uid) =>
  new Promise((resolve, reject) => {
    if (!uid) {
      const error = { code: 'uid not available' };
      reject(error);
    }

    CometChat.getUser(uid)
      .then((user) => resolve(user))
      .catch((error) => reject(error));
  });

export const updateUserNetworkStatus = (user) => (dispatch, getState) => {
  const { conversationList } = getState().msg;

  const temp = [...conversationList];
  const conversationKey = conversationList.findIndex(
    (conversationObj) =>
      conversationObj.conversationType === 'user' &&
      conversationObj.conversationWith.uid === user.uid,
  );
  if (conversationKey > -1) {
    const conversationObj = { ...temp[conversationKey] };
    const conversationWithObj = { ...conversationObj.conversationWith, status: user.getStatus() };

    const newConversationObj = { ...conversationObj, conversationWith: conversationWithObj };
    temp.splice(conversationKey, 1, newConversationObj);
    dispatch(setConversationListAction(temp));
  }
};
