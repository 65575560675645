import React from 'react';

import { classNames as cx } from '../../helpers/utility';

import Icon from '../icon';

import './styles.scss';

const CustomCheckbox = ({ className, name, checked, onChange, label }) => {
  const classNames = cx('custom-checkbox custom-checkbox--wrap', className, {
    'custom-checkbox--checked': checked,
    [`custom-checkbox--${name}`]: name,
  });

  return (
    <div className={classNames}>
      <input
        name={name}
        onChange={onChange}
        className="checkbox"
        type="checkbox"
        id={`checkbox-${name}`}
        checked={checked}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="custom-checkbox__label--wrap" htmlFor={`checkbox-${name}`}>
        {checked ? <Icon type="check" /> : <Icon type="uncheck" />}
        <span className="custom-checkbox__label"> {label}</span>
      </label>
    </div>
  );
};
export default CustomCheckbox;
