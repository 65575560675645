import { useSelector } from './store';

const useOwnerData = () => {
  const { user, userSponsors, userBlog, loading } = useSelector((state) => state.profile);
  const { loading: loadingAuth } = useSelector((state) => state.auth);

  const userId = user?.id;
  const name = user?.name || null;
  const team = user?.team || null;
  const userTeamId = user?.team?.id || null;
  const teamRole = user?.team_role || null;
  const isSponsor = user?.is_sponsor;
  const sponsorId = (userSponsors && userSponsors[0]?.id) || null;
  const sponsorRole = (userSponsors && userSponsors[0]?.user_role) || null;
  const isLoading = loading || loadingAuth;
  const userType = user?.user_type;
  const isProfileLoaded = user?.id && !isLoading;
  const blogId = userBlog?.id && !isLoading;

  return {
    userId,
    user,
    userSponsors,
    name,
    userType,
    team,
    userTeamId,
    teamRole,
    isSponsor,
    sponsorId,
    sponsorRole,
    userBlog,
    isLoading,
    isProfileLoaded,
    blogId,
  };
};

export default useOwnerData;
