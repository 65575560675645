import React from 'react';
import uuid from 'react-uuid';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import Filters from '../../../friends/components/filters';
import { useResponsive } from '../../../../hooks/useResponsive';
import TeamCard from '../teamCard';
import SkeletonFriends from '../../../friends/components/skeletonFriends';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import ToggleFilterButton from '../../../components/toggleFilterButton';
import RecruitingCard from '../recruitingCard';

const RecruitingList = ({
  recruits,
  fetchPaginated,
  hasMore,
  loading,
  handleChangeAge,
  age,
  handleChangeInput,
  handleChangeGeo,
  geoLocal,
  handleChangeCountry,
  rangeName,
  rangeDescription,
  onChangeComplete,
  handleClearFilter,
  handleChangeRecruitment,
  handleVisibleFilter,
  isFilterOpened,
  onClickAdd,
  clickAddDisabled,
  onClickCard,
  userId,
  userTeamRole,
}) => {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const handleToggleFilter = () => handleVisibleFilter(!isFilterOpened);
  return (
    <>
      {isTablet && (
        <ToggleFilterButton
          onClick={handleToggleFilter}
          isAddButton
          onClickAdd={onClickAdd}
          clickAddDisabled={clickAddDisabled}
        />
      )}
      <div className="friends__filters">
        {(isDesktop || ((isMobile || isTablet) && isFilterOpened)) && (
          <Filters
            handleChangeAge={handleChangeAge}
            age={age}
            handleChangeInput={handleChangeInput}
            handleChangeGeo={handleChangeGeo}
            handleChangeCountry={handleChangeCountry}
            geoLocal={geoLocal}
            rangeName={rangeName}
            rangeDescription={rangeDescription}
            minRangeValue={18}
            maxRangeValue={100}
            onChangeComplete={onChangeComplete}
            handleClearFilter={handleClearFilter}
            handleChangeRecruitment={handleChangeRecruitment}
            handleVisibleFilter={handleVisibleFilter}
            isMobile={isMobile || isTablet}
          />
        )}
      </div>
      {loading && !hasMore ? (
        <SkeletonFriends />
      ) : (
        !isEmpty(recruits) && (
          <InfiniteScroll
            dataLength={recruits}
            next={fetchPaginated}
            hasMore={hasMore}
            loader={<></>}
            className="recruiting__cards"
          >
            {recruits?.map((r) => (
              <RecruitingCard
                userTeamRole={userTeamRole}
                ownerId={userId}
                onClick={onClickCard}
                key={r.id}
                item={r}
              />
            ))}
          </InfiniteScroll>
        )
      )}
    </>
  );
};

export default RecruitingList;
