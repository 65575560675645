import { useMediaQuery } from 'react-responsive';

export const useResponsive = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTabletSmall = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 });
  const isTabletLarge = useMediaQuery({ minWidth: 1024, maxWidth: 1279 });
  const isDesktop = useMediaQuery({ minWidth: 1280 });
  const isLD = useMediaQuery({ minWidth: 1366 });
  const isMD = useMediaQuery({ minWidth: 1600 });
  const isHD = useMediaQuery({ minWidth: 1920 });
  const is2K = useMediaQuery({ minWidth: 2560 });
  const is2KHeight = useMediaQuery({ minHeight: 1081 });

  return {
    isMobile,
    isTabletSmall,
    isTablet,
    isTabletLarge,
    isDesktop,
    isLD,
    isMD,
    isHD,
    is2K,
    is2KHeight,
  };
};
