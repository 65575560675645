import React from 'react';

import './styles.scss';

const AwardItem = ({ imageSrc }) => {
  return (
    <div className="award-item">
      <img src={imageSrc} alt="" />
    </div>
  );
};

export default AwardItem;
