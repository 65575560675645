import { catchWrapper } from '../../../helpers/utility';
import { createBlog } from '../../../api/v2';
import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

export default (data) => async (dispatch, getState) => {
  const { blogImageTemp } = getState().blog;

  const formData = new FormData();

  formData.append('element', JSON.stringify(data));

  if (blogImageTemp) {
    const { blob, file } = blogImageTemp;
    formData.append('imageBlob', blob);
    formData.append('imageFile', file);
  }

  dispatch({ type: 'BLOG/CREATE_BLOG_PROFILE/REQUESTED' });
  const result = await catchWrapper(createBlog(formData));
  if (result && result.result) {
    dispatch({ type: 'BLOG/CREATE_BLOG_PROFILE/SUCCEEDED', payload: result && result });
    history.push({
      pathname: Pages.blog.main.replace(':id', result?.blog?.id),
      state: result?.blog?.id,
    });
  } else {
    dispatch({
      type: 'BLOG/CREATE_BLOG_PROFILE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
