import React from 'react';
import uuid from 'react-uuid';

import useVisible from '../../../../hooks/useVisible';
import Icon from '../../../../components/icon';

import { classNames as cx } from '../../../../helpers/utility';

import './styles.scss';

const SelectWhoCanSee = ({
  data,
  onChange,
  groupKey,
  value,
  valuesName,
  name,
  label,
  isMobile,
  menuOrientation,
}) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const handleToggleMenu = () => setIsVisible(!isVisible);

  const handleChangeValue = (elem) => {
    setIsVisible(false);
    onChange(name, elem, groupKey);
  };

  const classNames = cx('who-can-see__wrapper', {
    'who-can-see__wrapper--mobile': isMobile,
    'who-can-see__wrapper--open': isMobile && isVisible,
    'who-can-see__wrapper--menu_top': menuOrientation,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div ref={ref} onClick={handleToggleMenu} className={classNames}>
      {label ? <span className="who-can-see__label">{label}</span> : null}
      <span className="who-can-see__value">{valuesName[value]}</span>
      {isMobile ? <Icon type="chevronSmall" /> : null}
      {isVisible ? (
        <div className="who-can-see__dropdown-list">
          {data.map((item) => {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                onClick={() => handleChangeValue(item)}
                key={uuid()}
                className="who-can-see__dropdown-list__row"
              >
                <span className="who-can-see__dropdown-list__value">{valuesName[item]}</span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SelectWhoCanSee;
