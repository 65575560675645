import { catchWrapper } from '../../../helpers/utility';
import { getEvent, onManageEventRequest } from '../../../api/v2';
import modalService from '../../../services/modalService';

export default ({ data, url, remove, isEdit }) => async (dispatch) => {
  dispatch({ type: 'EVENTS/MANAGE_EVENT_REQUEST/REQUESTED' });

  const result = await catchWrapper(onManageEventRequest(url, data));

  if (result?.result) {
    dispatch({
      type: 'EVENTS/MANAGE_EVENT_REQUEST/SUCCEEDED',
      payload: {
        request: result?.request,
        request_team: result?.request_team,
      },
    });
    modalService.closeAll();
    if (remove) {
      modalService.openNotificationsModal({
        type: 'success',
        content: 'Ваша заявка отменена',
      });
    } else {
      modalService.openNotificationsModal({
        type: 'success',
        content: isEdit ? 'Заявка отредактирована' : 'Заявка создана',
      });
    }
  } else {
    modalService.closeAll();
    dispatch({ type: 'EVENTS/MANAGE_EVENT_REQUEST/FAILED' });
    modalService.openNotificationsModal({
      type: 'reject',
      content: result?.message && result?.message[0],
    });
  }
};
