import axios from 'axios';
import fileDownload from 'js-file-download';

const { host } = window.location;
const baseURL =
  host === 'new.airsoft.net' || host === 'airsoft.net'
    ? 'https://api.airsoft.net/v2'
    : 'https://devapi.airsoft.net/v2';

const getUrl = (type) => {
  switch (type) {
    case 'user_passport.pdf':
      return '/user/profile.pdf';
    default:
      return '';
  }
};

export const downloadFile = (type) => {
  axios({
    url: baseURL + getUrl(type),
    method: 'GET',
    withCredentials: true,
    responseType: 'blob',
  }).then((response) => {
    fileDownload(response.data, type);
  });
};

export const openFilePdf = (type) => {
  axios({
    url: baseURL + getUrl(type),
    method: 'GET',
    withCredentials: true,
    responseType: 'blob',
  }).then((response) => {
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  });
};
