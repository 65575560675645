import React from 'react';

export default () => (
  <svg width="166" height="40" viewBox="0 0 166 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.5327 23.5208H51.9132L51.1064 26.1228H47.5049L52.6335 12.1943H55.8028L60.9698 26.1228H57.3491L56.5327 23.5208ZM52.7199 20.9283H55.726L54.2181 16.0973L52.7199 20.9283Z"
      fill="#FFBB00"
    />
    <path d="M65.5894 26.1228H62.2279V12.1943H65.5894V26.1228Z" fill="#FFBB00" />
    <path
      d="M73.1286 21.2057H71.3038V26.1228H67.9328V12.1943H73.4359C75.0942 12.1943 76.3908 12.561 77.3256 13.2944C78.2604 14.0278 78.7278 15.0642 78.7278 16.4034C78.7278 17.3728 78.5293 18.1764 78.1323 18.8142C77.7417 19.4519 77.1271 19.9685 76.2883 20.3639L79.208 25.9793V26.1228H75.5968L73.1286 21.2057ZM71.3038 18.6133H73.4359C74.0762 18.6133 74.5564 18.4474 74.8765 18.1158C75.2031 17.7778 75.3663 17.3091 75.3663 16.7096C75.3663 16.1101 75.2031 15.6413 74.8765 15.3033C74.55 14.9589 74.0698 14.7867 73.4359 14.7867H71.3038V18.6133Z"
      fill="#FFBB00"
    />
    <path
      d="M87.9765 22.4207C87.9765 21.9296 87.8004 21.5469 87.4483 21.2727C87.1025 20.9985 86.4911 20.7147 85.6139 20.4213C84.7367 20.1279 84.0196 19.8441 83.4626 19.5699C81.6506 18.6834 80.7446 17.4653 80.7446 15.9156C80.7446 15.1439 80.9687 14.4647 81.4169 13.8779C81.8715 13.2848 82.5118 12.8256 83.3377 12.5004C84.1637 12.1687 85.0921 12.0029 86.1229 12.0029C87.1281 12.0029 88.0277 12.1815 88.8216 12.5386C89.622 12.8958 90.243 13.406 90.6848 14.0693C91.1266 14.7261 91.3475 15.4787 91.3475 16.3269H87.9861C87.9861 15.7593 87.81 15.3193 87.4579 15.0068C87.1121 14.6943 86.6415 14.538 86.0461 14.538C85.4442 14.538 84.9672 14.6719 84.6151 14.9398C84.2693 15.2013 84.0964 15.5361 84.0964 15.9443C84.0964 16.3014 84.2885 16.6267 84.6727 16.92C85.0568 17.207 85.7323 17.5068 86.6991 17.8193C87.666 18.1254 88.4599 18.457 89.081 18.8142C90.592 19.6815 91.3475 20.8773 91.3475 22.4015C91.3475 23.6196 90.8865 24.5763 89.9645 25.2714C89.0425 25.9666 87.778 26.3142 86.1709 26.3142C85.0376 26.3142 84.01 26.1133 83.088 25.7115C82.1724 25.3033 81.4809 24.7485 81.0135 24.0469C80.5525 23.339 80.322 22.5259 80.322 21.6075H83.7027C83.7027 22.3537 83.8948 22.9054 84.2789 23.2625C84.6695 23.6133 85.3001 23.7886 86.1709 23.7886C86.728 23.7886 87.1665 23.6707 87.4867 23.4347C87.8132 23.1923 87.9765 22.8543 87.9765 22.4207Z"
      fill="#FFBB00"
    />
    <path
      d="M104.918 19.4455C104.918 20.804 104.665 22.0061 104.159 23.052C103.654 24.098 102.933 24.9047 101.998 25.4723C101.07 26.0335 100.01 26.3142 98.8195 26.3142C97.6286 26.3142 96.5721 26.0431 95.6501 25.501C94.7282 24.9525 94.0079 24.1713 93.4892 23.1573C92.977 22.1432 92.7081 20.9793 92.6825 19.6656V18.8811C92.6825 17.5163 92.9322 16.3142 93.4316 15.2746C93.9374 14.2287 94.6577 13.4219 95.5925 12.8543C96.5337 12.2867 97.603 12.0029 98.8003 12.0029C99.9848 12.0029 101.041 12.2835 101.97 12.8448C102.898 13.406 103.618 14.2064 104.131 15.2459C104.649 16.2791 104.912 17.4653 104.918 18.8046V19.4455ZM101.489 18.862C101.489 17.4781 101.256 16.429 100.788 15.7147C100.327 14.994 99.6647 14.6337 98.8003 14.6337C97.11 14.6337 96.2168 15.8964 96.1207 18.4219L96.1111 19.4455C96.1111 20.8103 96.3384 21.8594 96.793 22.5929C97.2476 23.3263 97.9231 23.693 98.8195 23.693C99.6711 23.693 100.327 23.3326 100.788 22.612C101.249 21.8913 101.483 20.855 101.489 19.5029V18.862Z"
      fill="#FFBB00"
    />
    <path
      d="M115.463 20.5744H110.027V26.1228H106.656V12.1943H116.011V14.7867H110.027V17.9915H115.463V20.5744Z"
      fill="#FFBB00"
    />
    <path
      d="M128.583 14.7867H124.386V26.1228H121.015V14.7867H116.894V12.1943H128.583V14.7867Z"
      fill="#FFBB00"
    />
    <path
      d="M141.692 26.1228H138.34L133.385 17.4557V26.1228H130.014V12.1943H133.385L138.331 20.8613V12.1943H141.692V26.1228Z"
      fill="#FFBB00"
    />
    <path
      d="M152.497 20.2204H147.195V23.5399H153.457V26.1228H143.824V12.1943H153.476V14.7867H147.195V17.7236H152.497V20.2204Z"
      fill="#FFBB00"
    />
    <path
      d="M166 14.7867H161.803V26.1228H158.432V14.7867H154.312V12.1943H166V14.7867Z"
      fill="#FFBB00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3981 40C13.3139 38.3757 10.4066 37.0752 7.26673 33.8308C4.12682 30.5869 -0.294407 24.9733 0.015437 7.67409C3.98858 6.81896 5.0268 4.02281 5.21366 1.23711C12.2242 -0.412371 20.5714 -0.412371 27.582 1.23711C27.7688 4.02329 28.8071 6.81896 32.7802 7.67409C33.09 24.9733 28.6688 30.5869 25.5289 33.8313C22.3895 37.0752 19.4822 38.3757 16.3981 40Z"
      fill="#858475"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.398 37.5515C13.6914 36.1257 11.1397 34.9847 8.38449 32.1373C5.62878 29.2903 1.74859 24.3637 2.02078 9.18172C5.50771 8.43104 6.57452 6.00857 6.7385 3.56331C12.8915 2.11562 19.9054 2.11562 26.058 3.56331C26.2219 6.00857 27.2888 8.43104 30.7752 9.18172C31.0474 24.3632 27.1672 29.2903 24.4115 32.1373C21.6558 34.9842 19.1046 36.1257 16.398 37.5515Z"
      fill="#1A1A1A"
    />
    <path
      d="M16.3979 38.2268L16.2282 38.1375C15.9184 37.9742 15.6062 37.8122 15.3035 37.6556C12.8452 36.3816 10.5228 35.178 8.0007 32.5723C6.51822 31.0405 4.82838 29.0012 3.538 25.4891C2.82774 23.5556 2.29052 21.3065 1.94111 18.8033C1.54213 15.944 1.37291 12.6493 1.43821 9.00989L1.44346 8.72358L1.72422 8.66328C4.55333 8.0541 6.02676 6.29589 6.22839 3.28845L6.24603 3.02208L6.50678 2.96083C12.7923 1.48181 20.0021 1.48181 26.2877 2.96083L26.5484 3.02208L26.566 3.28845C26.7677 6.29636 28.2411 8.05458 31.0702 8.66328L31.351 8.72358L31.3562 9.00989C31.4215 12.6493 31.2523 15.9445 30.8533 18.8038C30.5039 21.307 29.9667 23.5561 29.2564 25.4895C27.9661 29.0017 26.2767 31.041 24.7942 32.5727C22.2721 35.1785 19.9502 36.3821 17.4919 37.656C17.1897 37.8127 16.877 37.9746 16.5672 38.138L16.3979 38.2268ZM2.15943 9.3038C1.92633 25.2559 6.37759 29.8535 8.52314 32.0704C10.9633 34.5911 13.2342 35.7682 15.6386 37.0141C15.8869 37.1428 16.1424 37.2752 16.3979 37.4091C16.6534 37.2752 16.9089 37.1428 17.1573 37.0141C19.5617 35.7682 21.8326 34.5911 24.2723 32.0704C26.4178 29.8535 30.8691 25.2555 30.636 9.3038C29.0567 8.91066 27.8478 8.14289 27.0394 7.0195C26.3868 6.11214 25.9921 4.96405 25.8644 3.60372C19.832 2.22915 12.9639 2.22915 6.9315 3.60372C6.80375 4.96405 6.40906 6.11214 5.75648 7.0195C4.94802 8.14337 3.73868 8.91066 2.15943 9.3038Z"
      fill="#FFBB00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4064 14.5039C18.3999 14.5039 20.0163 16.1135 20.0163 18.0996C20.0163 20.0853 18.3999 21.6954 16.4064 21.6954C14.4129 21.6954 12.7965 20.0858 12.7965 18.0996C12.7965 16.114 14.4125 14.5039 16.4064 14.5039Z"
      fill="#FFBB00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3954 22.8875C16.8478 22.8875 17.3006 22.8875 17.7535 22.8875C17.7535 23.8025 17.7535 25.3983 17.7535 26.3133C19.0644 25.4401 20.3304 23.5162 20.7718 22.8301C21.8963 23.6401 22.1561 23.4844 23.0203 24.2374C21.5112 26.7012 17.6057 30.4018 16.3949 30.4398C15.0621 30.4066 11.2787 26.7012 9.76953 24.2374C10.6338 23.4839 10.894 23.6401 12.018 22.8301C12.4595 23.5167 13.7699 25.5802 15.0364 26.3133C15.0364 25.3988 15.0364 23.8025 15.0364 22.8875C15.4897 22.8875 15.9426 22.8875 16.3954 22.8875Z"
      fill="#FFBB00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1986 15.828C12.4251 15.4372 12.6515 15.0469 12.8774 14.6566C12.0823 14.1994 10.6947 13.4012 9.89911 12.944C11.3139 12.2498 13.6191 12.1192 14.4366 12.0817C14.2946 10.7067 14.5601 10.5604 14.3375 9.43799C11.4407 9.50731 6.27013 11.0267 5.63185 12.0518C4.99405 13.2179 6.32399 18.3345 7.71209 20.8681C8.7994 20.4991 8.79368 20.1967 10.0602 19.6321C9.68413 18.908 8.54486 16.7462 8.54104 15.2867C9.33615 15.7439 10.7238 16.5421 11.5194 16.9993C11.7458 16.609 11.9722 16.2183 12.1986 15.828Z"
      fill="#FFBB00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5954 15.8275C20.369 15.4367 20.1426 15.0464 19.9166 14.6561C20.7118 14.1989 22.0994 13.4007 22.895 12.9435C21.4802 12.2493 19.1749 12.1187 18.3574 12.0812C18.4995 10.7062 18.234 10.5599 18.4566 9.4375C21.3534 9.50682 26.5239 11.0262 27.1622 12.0513C27.7995 13.2175 26.4701 18.334 25.082 20.8676C23.9947 20.4986 24.0004 20.1962 22.7338 19.6316C23.1099 18.9076 24.2492 16.7458 24.253 15.2862C23.4579 15.7434 22.0703 16.5416 21.2747 16.9988C21.0483 16.6085 20.8219 16.2178 20.5954 15.8275Z"
      fill="#FFBB00"
    />
  </svg>
);
