import changeTheme from './impl/changeTheme';
import changeEventView from './impl/changeEventView';

export const themeReducer = (state = null, action) => {
  switch (action.type) {
    case 'EVENT_VIEW/CHANGE/REQUESTED':
    case 'THEME/CHANGE/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case 'EVENT_VIEW/CHANGE/FAILED':
    case 'THEME/CHANGE/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }
    case 'THEME/CHANGE/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        theme: action.payload * 1,
      };
    }
    case 'EVENT_VIEW/CHANGE/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        eventView: action.payload * 1,
      };
    }
    case 'THEME/TOGGLE': {
      return {
        ...state,
        theme: action.payload.theme * 1,
        eventView: action.payload.eventView * 1,
      };
    }
    default:
      return state;
  }
};

export const themeActions = {
  changeEventView,
  changeTheme,
};
