import { catchWrapper } from '../../../helpers/utility';
import { getSponsorRequest } from '../../../api/v2';

export default (id) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/LOAD_TEAM_REQUESTS/REQUESTED' });

  const result = await catchWrapper(getSponsorRequest({ id }));

  if (result && result.result) {
    dispatch({ type: 'SPONSOR/LOAD_TEAM_REQUESTS/SUCCEEDED', payload: [...result.teams] });
  } else {
    dispatch({
      type: 'SPONSOR/LOAD_TEAM_REQUESTS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
