import React from 'react';

import { getImageFromFile } from '../../../../helpers/utility';

import CustomInput from '../../../../components/input';
import CustomCheckbox from '../../../../components/customCheckbox';
import HintImage from '../../../components/hintImage';
import ImagePicker from '../../../../components/imagePicker';

import './styles.scss';

const BlockSettingMain = ({
  sponsor,
  imagesState,
  handleChangeInput,
  isEditable,
  handleChangeImages,
  handleChangeCheckbox,
  errors,
}) => {
  const pic = sponsor?.images?.image?.preview || '';
  const image = imagesState?.image && getImageFromFile(imagesState?.image?.blob);
  const isHint = !(pic || image);

  return (
    <div className="card sponsor-setting__card">
      <div className="image-container">
        <div className="image-main__wrap">
          {isHint ? (
            <HintImage label="Логотип" width={360} height={360} error={errors?.image} />
          ) : null}
          {pic || image ? <img src={image || pic} alt="pic" /> : null}
          {isEditable ? (
            <ImagePicker
              onChange={handleChangeImages}
              aspectRatio={1}
              minHeight={360}
              minWidth={360}
              fieldName="image"
              className="add-photo"
            />
          ) : null}
        </div>
      </div>
      <CustomInput
        required
        name="title"
        placeholder="Название организатора"
        label="Название"
        labelPosition="top"
        value={sponsor?.profile?.title || ''}
        onChange={handleChangeInput}
        error={errors?.title}
      />
      <CustomCheckbox
        checked={sponsor?.closed}
        className="sponsor-setting__checkbox"
        name="closed"
        label="Закрытый клуб"
        onChange={handleChangeCheckbox}
      />
      <CustomCheckbox
        checked={sponsor?.single}
        className="sponsor-setting__checkbox"
        name="single"
        label="Одиночки"
        onChange={handleChangeCheckbox}
      />
    </div>
  );
};
export default BlockSettingMain;
