import React, { memo } from 'react';

function WrapperOutBodyScroll({ children, className }) {
  const handleOnMouseEnter = () => {
    document.body.classList.add('no-scroll');
  };

  const handleOnMouseLeave = () => {
    document.body.classList.remove('no-scroll');
  };
  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className={className}>
      {children}
    </div>
  );
}

export default memo(WrapperOutBodyScroll);
