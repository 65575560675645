import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8148 1.8C18.8148 0.80589 18.0189 0 17.037 0C16.0552 0 15.2593 0.80589 15.2593 1.8V4.00418C15.7981 3.74501 16.4008 3.6 17.037 3.6C17.6732 3.6 18.276 3.74501 18.8148 4.00418V1.8ZM18.8148 11.5958C18.276 11.855 17.6732 12 17.037 12C16.4008 12 15.7981 11.855 15.2593 11.5958V22.2C15.2593 23.1941 16.0552 24 17.037 24C18.0189 24 18.8148 23.1941 18.8148 22.2V11.5958ZM17.037 10.8C18.6734 10.8 20 9.45684 20 7.8C20 6.14316 18.6734 4.8 17.037 4.8C15.4007 4.8 14.0741 6.14316 14.0741 7.8C14.0741 9.45684 15.4007 10.8 17.037 10.8ZM6.96296 19.2C8.59936 19.2 9.92593 17.8568 9.92593 16.2C9.92593 14.5432 8.59936 13.2 6.96296 13.2C5.32657 13.2 4 14.5432 4 16.2C4 17.8568 5.32657 19.2 6.96296 19.2ZM6.96296 0C7.94473 0 8.74074 0.805956 8.74074 1.8V12.4042C8.20197 12.145 7.59925 12 6.96296 12C6.32668 12 5.72396 12.145 5.18519 12.4042V1.8C5.18519 0.805956 5.98119 0 6.96296 0ZM6.96296 20.4C7.59925 20.4 8.20197 20.255 8.74074 19.9958V22.2C8.74074 23.194 7.94473 24 6.96296 24C5.98119 24 5.18519 23.194 5.18519 22.2V19.9958C5.72396 20.255 6.32668 20.4 6.96296 20.4Z"
      fill="#C0B4AF"
    />
  </svg>
);
