import React from 'react';

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.71429 1.22727C7.71429 0.549466 8.28992 0 9 0C9.71006 0 10.2857 0.549466 10.2857 1.22727V7.5519L12.1737 5.74975C12.6427 5.30206 13.4031 5.30206 13.8721 5.74975C14.341 6.19744 14.341 6.92323 13.8721 7.37093L9.91303 11.1499C9.65769 11.3938 9.31611 11.5048 8.98209 11.4831C8.68337 11.4836 8.38454 11.375 8.15661 11.1575L8.11032 11.1133C8.09721 11.1013 8.08437 11.0891 8.07181 11.0765L4.13149 7.3153C3.67671 6.88119 3.67671 6.17736 4.13149 5.74325C4.58626 5.30914 5.32361 5.30914 5.77839 5.74325L7.71429 7.59116V1.22727ZM1.28571 9.81818C0.575631 9.81818 0 10.3677 0 11.0455V16.7727C0 17.4505 0.575631 18 1.28571 18H16.7143C17.4243 18 18 17.4505 18 16.7727V11.0455C18 10.3677 17.4243 9.81818 16.7143 9.81818C16.0042 9.81818 15.4286 10.3677 15.4286 11.0455V15.5455H2.57143V11.0455C2.57143 10.3677 1.9958 9.81818 1.28571 9.81818Z"
      fill="#EB9330"
    />
  </svg>
);
