import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77778 10C6.79594 10 6 10.8012 6 11.7895V25.2105C6 26.1989 6.79594 27 7.77778 27H28.2222C29.2041 27 30 26.1989 30 25.2105V11.7895C30 10.8012 29.2041 10 28.2222 10H7.77778ZM21.1111 15.3684C20.6202 15.3684 20.2222 15.769 20.2222 16.2632C20.2222 16.7573 20.6202 17.1579 21.1111 17.1579H25.5556C26.0465 17.1579 26.4444 16.7573 26.4444 16.2632C26.4444 15.769 26.0465 15.3684 25.5556 15.3684H21.1111ZM20.2222 20.7368C20.2222 20.2427 20.6202 19.8421 21.1111 19.8421H25.5556C26.0465 19.8421 26.4444 20.2427 26.4444 20.7368C26.4444 21.231 26.0465 21.6316 25.5556 21.6316H21.1111C20.6202 21.6316 20.2222 21.231 20.2222 20.7368ZM16.3955 17.307C16.3955 18.287 15.8974 19.1502 15.1395 19.6593C15.5491 19.8036 15.9357 20.0073 16.275 20.2731C17.0452 20.8773 17.5556 21.7874 17.5556 22.9736C17.5556 23.0458 17.5386 23.1134 17.5082 23.1735C17.4437 23.3003 17.3233 23.3938 17.1796 23.4211H10.0046C9.7574 23.4211 9.55556 23.2204 9.55556 22.9736C9.55556 21.7874 10.0675 20.8773 10.8377 20.2731C11.177 20.0073 11.5621 19.8036 11.9733 19.6593C11.2153 19.1502 10.7156 18.287 10.7156 17.307C10.7156 15.7425 11.9867 14.4737 13.5563 14.4737C15.124 14.4737 16.3955 15.7425 16.3955 17.307Z"
      fill="currentColor"
    />
  </svg>
);
