import { catchWrapper } from '../../../helpers/utility';
import { saveText } from '../../../api/v2';

export default (pageId, parentId, type, languageId, text) => async (dispatch) => {
  dispatch({ type: 'EVENTS/SAVE_TEXT_SCENARIO/REQUESTED' });
  const formData = new FormData();
  formData.append('page_id', pageId);
  formData.append('parent_id', parentId);
  formData.append('type', type);
  formData.append('language_id', languageId);
  formData.append('text', text);

  const result = await catchWrapper(saveText(formData));

  if (result && result.result) {
    dispatch({ type: 'EVENTS/SAVE_TEXT_SCENARIO/SUCCEEDED' });
    dispatch({ type: 'EDITOR/UNMOUNT' });
  } else {
    dispatch({
      type: 'EVENTS/SAVE_TEXT_SCENARIO/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
