import { catchWrapper } from '../../../helpers/utility';
import { getBlog } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default (id) => async (dispatch) => {
  dispatch({ type: 'BLOG/LOAD_BLOG/REQUESTED' });
  const url = `?id=${Number(id)}`;
  const result = await catchWrapper(getBlog(url));
  if (result && result.result) {
    dispatch({ type: 'BLOG/LOAD_BLOG/SUCCEEDED', payload: result.blog });
  } else {
    goToNotFound();
    dispatch({
      type: 'BLOG/LOAD_BLOG/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
