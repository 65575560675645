import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isEmpty } from 'lodash';

import { useActions, useSelector } from '../../../../hooks/store';
import { eventsActions } from '../../../../store/events';
import { useResponsive } from '../../../../hooks/useResponsive';
import { globalCancelFetch, cancelTokens } from '../../../../api/v2';
import useDebounce from '../../../../hooks/useDebounce';
import { getFormattedDate } from '../../../../helpers/utility';

import MonthBreak from '../../components/monthBreak';
import EventList from '../../../components/eventList';
import EventCard from '../../../components/eventCard';
import SkeletonEvents from '../../components/skeleton';
import Filters from '../../components/filters';
import MobileControls from '../../../components/mobile-controls';
import ToggleFilterButton from '../../../components/toggleFilterButton';

import './styles.scss';

const pageMainUrl = '/event/list';

const AllPage = ({ search, onChangeSearch, ...params }) => {
  const {
    isFilterOpened,
    handleVisibleFilter,
    geoLocal,
    type,
    size,
    handleChangeType,
    handleChangeSize,
    handleChangeCountry,
    handleChangeGeo,
    handleClearFilter,
    navigationMap,
    sizes,
    duration,
    durations,
    handleChangeDuration,
    handleClearCountry,
    handleChangeYear,
    handleChangeMonth,
    year,
    month,
    anonymous,
  } = params;
  const { isMobile, isDesktop, isTablet } = useResponsive();
  const [page, setPage] = useState(1);
  const { pagination, loading, eventsList } = useSelector((state) => state.events);
  const { eventView } = useSelector((state) => state.theme);
  const { userId } = useSelector((state) => state.auth);
  const { getEventsList, clearEvents } = useActions(eventsActions);
  const debouncedSearchTerm = useDebounce(search, 500);
  let dateCheck = '';

  const getList = (p) =>
    getEventsList({
      size,
      type,
      duration,
      geoLocal,
      year,
      month,
      url: 'list',
      page: p,
      query: debouncedSearchTerm || null,
    });

  useEffect(() => {
    clearEvents();
    if (cancelTokens[pageMainUrl]) {
      cancelTokens[pageMainUrl]();
    }
    setPage(1);
    getList(1);
  }, [type, size, duration, geoLocal, debouncedSearchTerm, year, month]);

  useEffect(
    () => () => {
      clearEvents();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const fetchPaginated = () => {
    setPage(page + 1);
    getList(page + 1);
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  return (
    <div className="events-section all-page">
      {isMobile && (
        <MobileControls
          placeholder="Поиск мероприятий"
          options={navigationMap}
          needSettings
          handleVisibleFilter={handleVisibleFilter}
          isFilterOpened={isFilterOpened}
          search={search}
          onChangeSearch={onChangeSearch}
        />
      )}
      {isTablet && <ToggleFilterButton onClick={handleVisibleFilter} />}
      {(isDesktop || ((isMobile || isTablet) && isFilterOpened)) && (
        <Filters
          handleVisibleFilter={handleVisibleFilter}
          geoLocal={geoLocal}
          handleChangeCountry={handleChangeCountry}
          handleChangeGeo={handleChangeGeo}
          handleClearFilter={handleClearFilter}
          handleChangeSize={handleChangeSize}
          size={size}
          type={type}
          handleChangeType={handleChangeType}
          isMobile={isMobile || isTablet}
          sizes={sizes}
          duration={duration}
          durations={durations}
          handleChangeDuration={handleChangeDuration}
          handleClearCountry={handleClearCountry}
          handleChangeYear={handleChangeYear}
          handleChangeMonth={handleChangeMonth}
          year={year}
          month={month}
        />
      )}
      {loading && !hasMore ? (
        <SkeletonEvents />
      ) : (
        !isEmpty([1]) && (
          <InfiniteScroll
            dataLength={eventsList?.length}
            next={fetchPaginated}
            hasMore={hasMore}
            loader={<></>}
            className={`events-section--grid event-view${eventView}`}
          >
            {eventsList?.map((event) => {
              const month = getFormattedDate(event.date_start, 'MM');
              const year = getFormattedDate(event.date_start, 'yyyy');
              if (dateCheck !== `${month}-${year}`) {
                dateCheck = `${month}-${year}`;
                return (
                  <>
                    <MonthBreak month={month} year={year} />
                    {isMobile || eventView === 1 ? (
                      <EventCard key={event.id} event={event} userId={userId} anonymous={anonymous} />
                    ) : (
                      <EventList key={event.id} event={event} userId={userId} anonymous={anonymous} />
                    )}
                  </>
                );
              }

              return (
                <>
                  {isMobile || eventView === 1 ? (
                    <EventCard key={event.id} event={event} userId={userId} anonymous={anonymous} />
                  ) : (
                    <EventList key={event.id} event={event} userId={userId} anonymous={anonymous} />
                  )}
                </>
              );
            })}
          </InfiniteScroll>
        )
      )}
    </div>
  );
};

export default AllPage;
