import React, { memo } from 'react';
import Button from '../../../components/button';

import './styles.scss';

const LanguageButton = ({ label, lang, onClick }) => (
  <Button onClick={() => onClick(lang)} variant="lang">
    {label}
  </Button>
);

export default memo(LanguageButton);
