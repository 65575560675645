import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7.71429C4 6.76751 4.8565 6 5.91304 6H24.087C25.1435 6 26 6.76751 26 7.71429V22.2857C26 23.2325 25.1435 24 24.087 24H5.91304C4.8565 24 4 23.2325 4 22.2857V7.71429ZM7.82609 10.7143C7.82609 10.0042 8.46846 9.42857 9.26087 9.42857C10.0533 9.42857 10.6957 10.0042 10.6957 10.7143C10.6957 11.4244 10.0533 12 9.26087 12C8.46846 12 7.82609 11.4244 7.82609 10.7143ZM14.0435 9.42857C13.2511 9.42857 12.6087 10.0042 12.6087 10.7143C12.6087 11.4244 13.2511 12 14.0435 12H20.7391C21.5315 12 22.1739 11.4244 22.1739 10.7143C22.1739 10.0042 21.5315 9.42857 20.7391 9.42857H14.0435ZM7.82609 15C7.82609 14.2899 8.46846 13.7143 9.26087 13.7143C10.0533 13.7143 10.6957 14.2899 10.6957 15C10.6957 15.7101 10.0533 16.2857 9.26087 16.2857C8.46846 16.2857 7.82609 15.7101 7.82609 15ZM14.0435 13.7143C13.2511 13.7143 12.6087 14.2899 12.6087 15C12.6087 15.7101 13.2511 16.2857 14.0435 16.2857H20.7391C21.5315 16.2857 22.1739 15.7101 22.1739 15C22.1739 14.2899 21.5315 13.7143 20.7391 13.7143H14.0435ZM7.82609 19.2857C7.82609 18.5757 8.46846 18 9.26087 18C10.0533 18 10.6957 18.5757 10.6957 19.2857C10.6957 19.9958 10.0533 20.5714 9.26087 20.5714C8.46846 20.5714 7.82609 19.9958 7.82609 19.2857ZM14.0435 18C13.2511 18 12.6087 18.5757 12.6087 19.2857C12.6087 19.9958 13.2511 20.5714 14.0435 20.5714H20.7391C21.5315 20.5714 22.1739 19.9958 22.1739 19.2857C22.1739 18.5757 21.5315 18 20.7391 18H14.0435Z"
      fill="currentColor"
    />
  </svg>
);
