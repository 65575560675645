import React, { memo } from 'react';
import isAfter from 'date-fns/isAfter';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { CircularProgressbar } from 'react-circular-progressbar';
import history from '../../../routes/history';
import { Pages } from '../../../routes/pages';

import Icon from '../../../components/icon';
import Trans from '../../../components/trans';

import 'react-circular-progressbar/dist/styles.css';
import './styles.scss';
import EventSize from '../../events/components/eventSize';
import ViewParticipants from '../eventCard/components/viewParticipants';
import Place from '../eventCard/components/place';

const EventList = ({ event, id, anonymous }) => {
  const {
    profile,
    location,
    date_start: dateStart,
    date_end: dateEnd,
    prices,
    sponsor,
    qty_of_users: qtyOfUsers,
    qty_of_friends: qtyFriends,
  } = event;

  const toDay = Date.now();
  const isFinishedEvent = isAfter(new Date(toDay), new Date(dateEnd));
  const howManyDays = differenceInCalendarDays(new Date(dateStart), new Date(toDay));

  const fakePercentage = (howManyDays * 100) / 130;

  const price = prices && prices[0].price;
  const defaultPrice = prices && prices[0].default_price;

  const handleClickEvent = () => {
    history.push({
      pathname: Pages.event.main.replace(':id', event?.id),
      state: event && event.id,
    });
  };

  const signStyle = {
    color: `#${event?.sign?.color}`,
  };

  return (
    <div onClick={handleClickEvent} id={id || event.id} className="card event-list">
      <div className="event-stage">
        {isFinishedEvent ? (
          <Icon type="finished" />
        ) : (
          <CircularProgressbar
            className="event-stage__progress"
            value={100 - fakePercentage}
            text={`${howManyDays}д`}
          />
        )}
      </div>
      <div className="data">
        <div className="sponsor">{sponsor?.title}</div>
        <div className="title">{profile?.title}</div>
      </div>
      {(price || defaultPrice) && (
        <div className="price">
          {price && <div className="price">{`${price} ₽`}</div>}
          {defaultPrice ? (
            <div className="default-price">
              ( {Trans('events.onPolygon')} {defaultPrice}₽ )
            </div>
          ) : null}
        </div>
      )}
      <div className="params">
        <div className="sign" style={signStyle}>
          {event?.sign?.title || ''}
        </div>
        <div className="param">{event?.type?.title}</div>
        <div className="param">
          <EventSize size={event?.size} />
        </div>
      </div>
      <div className="players">
        {anonymous ? (
          <ViewParticipants isShort qtyOfUsers={qtyOfUsers} qtyFriends={qtyFriends} />
        ) : (
          <ViewParticipants users={event?.users} qtyOfUsers={qtyOfUsers} qtyFriends={qtyFriends} />
        )}
      </div>
      <div className="place">
        <Place
          isShort
          event={event}
          location={location}
          place={profile && profile.location_description}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
      </div>
    </div>
  );
};

export default memo(EventList);
