import { catchWrapper } from '../../../helpers/utility';
import { changeEventView } from '../../../api/v2';

export default (data) => async (dispatch) => {
  dispatch({ type: 'EVENT_VIEW/CHANGE/REQUESTED' });

  const result = await catchWrapper(changeEventView(data));

  if (result && result.result) {
    dispatch({ type: 'EVENT_VIEW/CHANGE/SUCCEEDED', payload: result?.event_view });
  } else {
    dispatch({
      type: 'EVENT_VIEW/CHANGE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
