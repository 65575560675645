import React, { memo } from 'react';
import ButtonIcon from '../buttons/button-icon';

import './styles.scss';

function MessageMenu({ onDeleteAction, onEditModeAction }) {
  return (
    <div className="message-menu">
      <div className="message-menu__list">
        <div className="message-menu__item">
          <ButtonIcon onClick={onDeleteAction} iconName="delete" />
        </div>
        <div className="message-menu__item">
          <ButtonIcon onClick={onEditModeAction} iconName="editMode" />
        </div>
      </div>
    </div>
  );
}

export default memo(MessageMenu);
