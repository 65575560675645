import React from 'react';
import Skeleton from '../../../../components/skeleton';

import './styles.scss';

const SkeletonPrivate = () => {
  return (
    <>
      <Skeleton className="private-title" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item private-item_last" />
      <Skeleton className="private-title" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item" />
      <Skeleton className="private-item" />
    </>
  );
};

export default SkeletonPrivate;
