import React from 'react';
import { classNames as cx } from '../../../helpers/utility';

import dummy from '../../../assets/image/user.svg';

import './styles.scss';

const Avatar = ({ image, status, withStatus, hasCount, count }) => {
  const classNamesImage = cx('avatar-image__wrap', {
    'avatar-image--empty': !image,
  });

  const classNamesStatus = cx('avatar__status-inner', {
    'avatar__status-inner_online': status,
    'avatar__status-inner_offline': !status,
  });

  return (
    <div className="avatar-container">
      {withStatus ? (
        <div className="avatar__status-external">
          <span className={classNamesStatus} />
        </div>
      ) : null}
      {hasCount && count ? (
        <div className="avatar__count">
          <div className="avatar__count-inner">
            <span className="avatar__count-value">{count}</span>
          </div>
        </div>
      ) : null}
      <div className={classNamesImage}>
        <img src={image || dummy} alt="" />
      </div>
    </div>
  );
};

export default Avatar;
