import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 12.12C10 11.5014 10.4884 11 11.0909 11H26.9091C27.5115 11 28 11.5014 28 12.12V23.88C28 24.4985 27.5115 25 26.9091 25H11.0909C10.4884 25 10 24.4985 10 23.88V12.12ZM19.0617 17.5739L12.658 12.0952C12.3109 11.7982 11.7949 11.8464 11.5057 12.2027C11.2164 12.5591 11.2632 13.0888 11.6104 13.3858L18.5174 19.2951C18.8148 19.5676 19.2697 19.5827 19.5852 19.3128L26.5099 13.3883C26.8571 13.0914 26.904 12.5617 26.6147 12.2053C26.3254 11.8489 25.8095 11.8008 25.4624 12.0978L19.0617 17.5739Z"
      fill="currentColor"
    />
  </svg>
);
