import React from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import { useResponsive } from '../../../../../hooks/useResponsive';

import Icon from '../../../../../components/icon';
import Skeleton from '../../../../../components/skeleton';

import './styles.scss';
import './sk.scss';

const EventInfo = ({
  loading,
  percentage = 1,
  isFinishedEvent,
  howManyDays,
  type,
  size,
  title,
  prices,
  sign
}) => {
  const { isMobile } = useResponsive();

  const price = prices && prices[0].price;
  const defaultPrice = prices && prices[0].default_price;
  const signStyle = {
    color: `#${sign?.color}`,
  };

  return (
    <div className="event-info">
      <div className="event-info__status-wrap">
        {loading ? (
          <Skeleton className="event-info-sk__status" />
        ) : (
          <div style={signStyle} className="event-info__status">
            {sign?.title || ''}
          </div>
        )}
      </div>
      <div className="event-info__type-wrap">
        {loading ? (
          <Skeleton className="event-info-sk__type" />
        ) : (
          <span className="event-info__type">{`${type?.title}${isMobile ? ',' : ''}` || ''}</span>
        )}

        {loading ? (
          <Skeleton className="event-info-sk__type" />
        ) : (
          <span className="event-info__size">{`${size} ${isMobile ? 'человек' : 'чел.'}`}</span>
        )}
      </div>

      {loading ? (
        <Skeleton className="event-info-sk__counter" />
      ) : (
        <div className="event-info__counter-wrap">
          {isFinishedEvent ? (
            <Icon type="finished" />
          ) : (
            <CircularProgressbar
              className="event-info__progress"
              value={percentage}
              text={`${howManyDays}д`}
            />
          )}
        </div>
      )}

      {loading ? (
        <Skeleton className="event-info-sk__price" />
      ) : (
        <>
          <span className="event-info__title">{title}</span>
          <div className="event-info__prices-wrap">
            {price ? (
              <span className="event-info__price_bold">{`${price} ₽`}</span>
            ) : (
              <span className="event-info__price_bold">Бесплатная игра</span>
            )}

            {defaultPrice ? (
              <span className="event-info__ ">{`(на полигоне ${defaultPrice} ₽)`}</span>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default EventInfo;
