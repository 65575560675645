import React, { memo } from 'react';
import GalleryToggle from '../toggle';
import Button from '../../../../../components/button';
import Icon from '../../../../../components/icon';
import { galleryOptions } from '../../constants';

const GalleryHeader = ({ isBlogHolder, toggleType, type, onClick }) => {
  return (
    <div className="gallery__header">
      <span className="gallery__title">Галерея</span>
      <GalleryToggle options={galleryOptions} toggleType={toggleType} type={type} />
      {isBlogHolder && (
        <Button variant="icon" onClick={onClick}>
          <Icon type="add" />
        </Button>
      )}
    </div>
  );
};

export default memo(GalleryHeader);
