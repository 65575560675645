import React from 'react';
import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const RadioGroup = ({ onChange, name, value, options, className, variant }) => {
  const classNames = cx('radio-group', className, {
    [`radio-group--${variant}`]: variant,
  });

  return (
    <div className={classNames}>
      {options?.map((o) => {
        return (
          <div key={o.id} className="radio-group__item">
            <input
              checked={value === o.value}
              onChange={onChange}
              name={name || 'radio'}
              type="radio"
              id={o.value}
              value={o.value}
            />
            <label htmlFor={o.value}>{o.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioGroup;
