import React, { useState } from 'react';

import { useActions, useSelector } from '../../hooks/store';
import { camouflageActions } from '../../store/camouflages';

import ModalHeader from '../components/modalHeaderBlog';
import CustomInput from '../../components/input';
import Button from '../../components/button';

import './styles.scss';

const init = {
  title: null,
  link: null,
  description: null,
};

const AddCamouflageModal = ({ onCancel }) => {
  const [state, setState] = useState(init);

  const { errors, loading } = useSelector((s) => s.camouflages);
  const { sendAddCamouflage, onChangeField } = useActions(camouflageActions);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if (errors && errors[name]) {
      const temp = {
        ...errors,
        [name]: null,
      };
      onChangeField('errors', temp);
    }

    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = () => sendAddCamouflage(state);

  return (
    <div className="modal-wrapper">
      <ModalHeader title="Добавление камуфляжа" close={onCancel} />
      <div className="modal-content">
        <CustomInput
          onChange={handleChangeInput}
          value={state.title}
          label="Наименование"
          labelPosition="top"
          placeholder="Укажите наименование камуфляжа"
          name="title"
          required
          error={errors?.title}
        />
        <CustomInput
          onChange={handleChangeInput}
          value={state.link}
          label="Ссылка"
          labelPosition="top"
          placeholder="Укажите ссылку на камуфляж"
          name="link"
          required
          error={errors?.link}
        />
        <CustomInput
          onChange={handleChangeInput}
          value={state.description}
          type="textarea"
          label="Описание"
          labelPosition="top"
          placeholder="Опишите камуфляж"
          name="description"
          required
          error={errors?.description}
        />
      </div>
      <div className="modal-footer">
        <Button disabled={loading} tabIndex="0" variant="main" onClick={onSubmit}>
          Добавить
        </Button>
      </div>
    </div>
  );
};

export default AddCamouflageModal;
