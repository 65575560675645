import React from 'react';

export default () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.128 7.54336L23.3766 7.45023L24.2399 7.12657L24.5243 7.02001C24.79 6.92032 25.0816 7.21196 24.982 7.47769L24.8754 7.76212L24.5517 8.6254L24.4586 8.87398L18.6048 24.4878L18.5365 24.6701L18.2278 25.4934L18.1065 25.8172C18.0102 26.0737 17.6391 26.0565 17.5044 25.7892L17.3346 25.4519L16.9024 24.5942L16.8067 24.4044L14.4405 19.7079L19.0417 12.8377L12.1198 17.4737L7.5976 15.1953L7.40772 15.0996L6.54999 14.6674L6.21275 14.4975C5.94544 14.3628 5.92823 13.9918 6.18477 13.8955L6.50858 13.7742L7.33183 13.4655L7.51413 13.3972L23.128 7.54336Z"
      fill="currentColor"
    />
  </svg>
);
