import { catchWrapper } from '../../../helpers/utility';
import { deleteBlogVideo } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default (data) => async (dispatch) => {
  dispatch({ type: 'BLOG/DELETE_BLOG_VIDEO/REQUESTED' });
  const result = await catchWrapper(deleteBlogVideo(data));
  if (result && result.result) {
    dispatch({ type: 'BLOG/DELETE_BLOG_VIDEO/SUCCEEDED' });
  } else {
    goToNotFound();
    dispatch({
      type: 'BLOG/DELETE_BLOG_VIDEO/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
