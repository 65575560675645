import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useActions, useSelector } from '../../../../hooks/store';
import { sponsorAction } from '../../../../store/sponsor';
import useDebounce from '../../../../hooks/useDebounce';
import { useResponsive } from '../../../../hooks/useResponsive';
import { cancelTokens, globalCancelFetch } from '../../../../api/v2';

import OrgCard from '../../components/orgCard';
import SkeletonGrid from '../../../../components/skeletonsGrid';
import Filters from '../../components/filters';
import ToggleFilterButton from '../../../components/toggleFilterButton';
import MobileControls from '../../../components/mobile-controls';

import './styles.scss';

const pageMainUrl = '/sponsor/list';

const AllOrganizers = ({
  navigationMap,
  search,
  onChangeSearch,
  isShowSidebarButton,
  ...params
}) => {
  const [page, setPage] = useState(1);
  const { pagination, loading, sponsorList } = useSelector((state) => state.sponsor);
  const { userId } = useSelector((state) => state.auth);
  const { getAllSponsors, onClearSponsorList } = useActions(sponsorAction);
  const containerEl = useRef(null);

  const { isMobile, isDesktop, isTablet } = useResponsive();
  const debouncedSearchTerm = useDebounce(search, 500);

  const {
    rangeName,
    rangeDescription,
    isFilterOpened,
    handleVisibleFilter,
    geoLocal,
    type,
    size,
    handleChangeType,
    handleChangeSize,
    handleChangeCountry,
    handleChangeGeo,
    handleClearFilter,
    handleChangeInput,
  } = params;

  const getList = (p) =>
    getAllSponsors({
      size,
      type,
      geoLocal,
      page: p,
      query: debouncedSearchTerm || null,
    });

  const onChangeComplete = () => {
    setPage(1);
    onClearSponsorList();
    if (cancelTokens[pageMainUrl]) {
      cancelTokens[pageMainUrl]();
    }
    getList(1);
  };

  useEffect(() => {
    onChangeComplete();
  }, [type, geoLocal, debouncedSearchTerm, size]);

  useEffect(
    () => () => {
      onClearSponsorList();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const fetchPaginated = () => {
    const newPage = page + 1;

    setPage(newPage);
    getList(newPage);
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  useEffect(() => {
    if (
      sponsorList.length &&
      hasMore &&
      document.body.offsetHeight > containerEl.current.offsetHeight
    ) {
      fetchPaginated();
    }
  }, [sponsorList]);

  return (
    <div ref={containerEl} className="org-all-page">
      {isMobile && (
        <MobileControls
          title="Организаторы"
          placeholder="Поиск организатора"
          needSettings
          handleVisibleFilter={handleVisibleFilter}
          isFilterOpened={isFilterOpened}
          search={search}
          onChangeSearch={onChangeSearch}
        />
      )}

      {isTablet && (
        <>
          <ToggleFilterButton onClick={handleVisibleFilter} />
          <br />
        </>
      )}
      {(isDesktop || ((isMobile || isTablet) && isFilterOpened)) && (
        <Filters
          isFilterOpened={isFilterOpened}
          handleVisibleFilter={handleVisibleFilter}
          geoLocal={geoLocal}
          handleChangeCountry={handleChangeCountry}
          handleChangeGeo={handleChangeGeo}
          handleClearFilter={handleClearFilter}
          handleChangeInput={handleChangeInput}
          handleChangeSize={handleChangeSize}
          size={size}
          rangeName={rangeName}
          rangeDescription={rangeDescription}
          type={type}
          handleChangeType={handleChangeType}
          onChangeComplete={onChangeComplete}
          isMobile={isMobile || isTablet}
        />
      )}
      {loading && page === 1 ? (
        <SkeletonGrid
          classNameElement="sk-camouflage--element"
          classNameGrid="sk-camouflage--grid"
        />
      ) : (
        !isEmpty(sponsorList) && (
          <InfiniteScroll
            dataLength={3}
            next={fetchPaginated}
            hasMore={hasMore}
            loader={<></>}
            className="org__cards"
          >
            {sponsorList?.map((s) => (
              <OrgCard key={s.id} sponsor={s} userId={userId} />
            ))}
          </InfiniteScroll>
        )
      )}
    </div>
  );
};

export default AllOrganizers;
