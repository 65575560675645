import React from 'react';
import Button from '../../components/button';
import Icon from '../../components/icon';

const ModalHeader = ({ title, close }) => {
  return (
    <div className="modal-header">
      <span className="modal-header__title">{title}</span>
      {close ? (
        <Button onClick={close} variant="icon">
          <Icon type="cross" />
        </Button>
      ) : null}
    </div>
  );
};

export default ModalHeader;
