import { format } from 'date-fns';
import getHours from 'date-fns/getHours';

export const getMinTime = (dateStart, dateEnd) => {
  const a = dateStart && format(new Date(dateStart), 'yyyy-MM-dd');
  const b = dateEnd && format(new Date(dateEnd), 'yyyy-MM-dd');

  const isEqualFirstDate = a === b;

  return isEqualFirstDate
    ? new Date().setHours(getHours(new Date(dateStart)), 0, 0)
    : new Date().setHours(0, 0, 0);
};
