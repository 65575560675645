import React, { useEffect, useState } from 'react';
import he from 'he';
import { useSelector } from 'react-redux';
import ModalHeader from '../components/modalHeaderBlog';
import CustomInput from '../../components/input';
import './styles.scss';
import loadOptionsBlogger from './loadOptionsBlogger';
import SelectAsyncPaginate from '../../components/selectAsyncPaginate';
import loadOptionsOrg from './loadOptionsOrg';

const init = {
  title: null,
  link: null,
  eventId: null,
};

const AddBlogVideoModal = ({
  onCreateBlogVideo,
  onCancel,
  getBlogVideoList,
  blogId,
  clearBlogVideos,
  onEditBlogVideo,
  isEdit,
  video,
  parentType,
  parentId,
}) => {
  const [state, setState] = useState(init);
  const selectedSponsors = [];
  const { errors } = useSelector((s) => s?.blog);

  useEffect(() => {
    setState({ ...state, title: video?.profile?.title, link: video?.link });
  }, [isEdit]);

  const filterOption = (option) => {
    const { value } = option;
    const t = selectedSponsors.find((s) => s.id === value.id);
    return t ? null : value;
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleOnChangeSelect = (value) => {
    setState({ ...state, eventId: Number(value?.id) });
  };
  const onSubmit = async () => {
    const data = {
      title: he.decode(state?.title || ''),
      link: state.link,
      id: isEdit ? Number(video?.id) : undefined,
      parent_id: Number(blogId),
      event_id: state.eventId,
      type: parentType,
    };
    if (isEdit) {
      await onEditBlogVideo(data);
    } else {
      await onCreateBlogVideo(data);
    }
    await clearBlogVideos();
    await getBlogVideoList({ page: 1, parentId, parentType });
    await onCancel();
  };

  return (
    <div className="modal-wrapper">
      <ModalHeader
        title={isEdit ? 'Изменить видео' : 'Добавить видео'}
        buttonTitle={isEdit ? 'Сохранить' : 'Загрузить'}
        onSubmit={onSubmit}
        onCancel={onCancel}
        close={onCancel}
        disabledButton={!state?.title || !state?.link || !state?.eventId}
      />
      <div className="modal-content">
        <CustomInput
          onChange={handleChangeInput}
          value={he.decode(state?.title || '')}
          label="Название видео"
          labelPosition="top"
          placeholder="Укажите название видео"
          name="title"
          required
          error={errors?.title}
        />
        <CustomInput
          onChange={handleChangeInput}
          value={state.link}
          label="Ссылка на видео youtube"
          labelPosition="top"
          placeholder="Укажите ссылку"
          name="link"
          required
          error={errors?.link}
        />
        <SelectAsyncPaginate
          name="event"
          loadOptions={parentType === 'org' ? loadOptionsOrg : loadOptionsBlogger}
          onChange={handleOnChangeSelect}
          filterOption={filterOption}
          label="Привязать к мероприятию"
          placeholder="Привязать к мероприятию"
          required
          error={errors?.event}
          labelPosition="top"
        />
      </div>
    </div>
  );
};

export default AddBlogVideoModal;
