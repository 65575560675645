import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useActions, useSelector } from '../../../../hooks/store';
import { eventsActions } from '../../../../store/events';

import EventParticipantsFilter from '../../components/eventParticipantsFilter';
import SkeletonFriends from '../../../friends/components/skeletonFriends';
import TeamCard from '../../../teams/components/teamCard';
import TeamUserCard from '../../../teams/components/teamUserCard';

import './styles.scss';
import Spinner from '../../../../components/spinner';

const EventParticipantsPage = () => {
  const { id } = useParams();
  const { clearEventParticipants, getEventParticipants } = useActions(eventsActions);
  const [page, setPage] = useState(1);
  const [sideId, setSideId] = useState(null);
  const { loading, eventParticipants } = useSelector((state) => state.events);
  const { teams, single } = eventParticipants;
  const { userId } = useSelector((state) => state.auth);

  const handleChangeSide = (value) => {
    setSideId(value);
  };

  useEffect(() => {
    const newPage = 1;
    setPage(newPage);
    clearEventParticipants();
    getEventParticipants({ id, page: newPage, sideId });
    // TODO : Убрать косяк с InfinityScroll
    getEventParticipants({ id, page: newPage + 1, sideId });
    getEventParticipants({ id, page: newPage + 2, sideId });
    setPage(newPage + 2);
  }, [sideId]);

  const fetchPaginated = () => {
    const newPage = page + 1;
    setPage(newPage);
    getEventParticipants({ id, page: newPage, sideId });
  };

  const hasMoreTeams =
    (teams?.pagination && Number(teams.pagination.current_page)) <
    (teams?.pagination && Number(teams.pagination.total_pages));

  const hasMoreSingle =
    (single?.pagination && Number(single.pagination.current_page)) <
    (single?.pagination && Number(single.pagination.total_pages));

  return (
    <>
      <EventParticipantsFilter handleChangeSide={handleChangeSide} side={sideId} />
      <h3 className="participants__title">Команды</h3>
      {loading && !hasMoreTeams && page === 1 ? (
        <SkeletonFriends />
      ) : (
        <>
          {teams?.pagination?.total_items ? (
            <>
              <InfiniteScroll
                dataLength={teams?.items?.length || 0}
                next={fetchPaginated}
                hasMore={hasMoreTeams}
                loader={<></>}
                className="events-section--grid"
              >
                {teams?.items?.map((team) => (
                  <TeamCard userId={userId} team={team.team} key={uuid()} />
                ))}
              </InfiniteScroll>
              {!!loading && (
                <center>
                  <br/>
                  <Spinner color="#eb9330" size="30" />
                </center>
              )}
            </>
          ) : (
            <p className="participants_text">Команды отсутствуют.</p>
          )}
        </>
      )}
      <h3 className="participants__title">Одиночки</h3>
      {loading && !hasMoreSingle && page === 1 ? (
        <SkeletonFriends />
      ) : (
        <>
          {single?.pagination?.total_items ? (
            <InfiniteScroll
              dataLength={single?.items?.length || 0}
              next={fetchPaginated}
              hasMore={hasMoreSingle}
              loader={<></>}
              className="grid__users"
            >
              {single?.items?.map((user) => (
                <TeamUserCard key={user.id} user={user.user} userId={userId} isLink />
              ))}
            </InfiniteScroll>
          ) : (
            <p className="participants_text">Одиночки отсутствуют.</p>
          )}
        </>
      )}
    </>
  );
};

export default EventParticipantsPage;
