import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useActions, useSelector } from '../../../../hooks/store';
import { useResponsive } from '../../../../hooks/useResponsive';
import LinksBlock from '../../../components/links';
import { classNames as cx } from '../../../../helpers/utility';
import BlogOwner from '../../../components/blogOwner';
import BlogInfo from '../../../components/blogInfo';
import BlogAbout from '../../../components/blogAbout';
import GalleryComponent from '../../../components/gallery';
import { blogActions } from '../../../../store/blog';
import ButtonTags from '../../../components/buttonTags';
import SponsorLinksBlock from '../../../components/linksBlock';
import Articles from '../../../components/acticles';
import MobileNav from '../../components/mobileNav';

import './styles.scss';

const BlogPage = () => {
  const { id } = useParams();
  const { blog, loading, loadingSilent, articlesList } = useSelector((s) => s.blog);
  const { userId, services, token, loading: authLoading } = useSelector((s) => s.auth);

  const { getBlog, subscribeBlog, unSubscribeBlog, getArticlesList, clearBlog } = useActions(
    blogActions,
  );

  const { isMobile, isTabletSmall } = useResponsive();
  // Позже добавят accountHolder в auth
  // const { accountHolder } = isAccountHolder();
  const blogHolder = blog?.user?.id === userId;

  //Если есть ID в урле, то загружаем блог
  useEffect(() => {
    if (token) {
      if (id) {
        getBlog(id);
        getArticlesList({ page: 1, blogId: id, blogType: 1 });
      }
    }
  }, [id, token]);

  useEffect(
    () => () => {
      clearBlog();
    },
    [],
  );

  const isLoading = loading || authLoading;

  const classNames = cx('page-blog', {
    'page-blog--about-empty': !blog?.dop_info,
  });
  const isBlogHolder = useMemo(() => blog?.user?.id === userId, [blog, userId]);
  return (
    <section className={classNames}>
      <BlogOwner
        subscribers={blog?.random_users}
        totalSubscribers={blog?.qty_of_subs}
        blog={blog}
        userId={id}
        selfId={userId}
        loading={isLoading}
        subscribeBlog={subscribeBlog}
        unSubscribeBlog={unSubscribeBlog}
        isFriend={blog?.is_friend}
        isRequestWasSent={blog?.is_request_was_sent}
        isAmSub={blog?.is_am_sub}
        loadingSilent={loadingSilent}
        blogHolder={blogHolder}
        getBlogSilent={getBlog}
      />
      {isMobile && !isEmpty(blog?.tags) ? <ButtonTags tags={blog?.tags} /> : null}
      {isMobile && <MobileNav id={id} articlesCount={articlesList?.length} />}
      <BlogInfo blog={blog} services={services} loading={isLoading} />
      {isTabletSmall && <LinksBlock services={services} social={blog?.social_service} />}
      {isMobile && blog?.id && (
        <div className="card">
          <SponsorLinksBlock services={services} social={blog?.social_service} />
        </div>
      )}
      <BlogAbout
        isLoading={isLoading}
        about={blog?.profile?.about}
        loading={isLoading}
        tags={blog?.tags}
      />
      {!isMobile ? <Articles articles={articlesList} blogHolder={blogHolder} /> : null}
      {/*{!isMobile && !isEmpty(reviewsList) ? <Reviews articles={reviewsList} /> : null}*/}
      <GalleryComponent parentId={id} parentType="blog" isOwner={isBlogHolder} />
    </section>
  );
};

export default BlogPage;
