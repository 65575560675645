import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';

export default () => async (dispatch) => {
  dispatch({ type: 'AUTH/GET_SOCIAL/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'getSocial');

  const result = await catchWrapper(postSocial('social', formData));

  if (result) {
    dispatch({
      type: 'AUTH/GET_SOCIAL/SUCCEEDED',
      payload: result.social,
    });
  } else {
    dispatch({ type: 'AUTH/GET_SOCIAL/FAILED' });
  }
};
