import { catchWrapper } from '../../../helpers/utility';

import { removeProfile } from '../../../api/v2';
import { goToLogin } from '../../../routes/actions';
import modalService from '../../../services/modalService';
import { localStorageService } from '../../../services/localStorageService';

export default () => async (dispatch) => {
  dispatch({ type: 'SETTINGS/REMOVE_PROFILE/REQUESTED' });

  const result = await catchWrapper(removeProfile());

  if (result?.result) {
    dispatch({ type: 'SETTINGS/REMOVE_PROFILE/SUCCESSFUL' });
    localStorageService.clearAuthData();
    modalService.closeAll();
    goToLogin();
  } else {
    dispatch({ type: 'SETTINGS/REMOVE_PROFILE/FAILED', payload: result.message[0] });
  }
};
