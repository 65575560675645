import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9505 11.4201C17.9505 13.2944 17.0062 14.9455 15.572 15.9197C15.8347 16.0128 16.0927 16.119 16.3435 16.2384C16.8322 16.4724 17.2961 16.7566 17.7209 17.0932C18.1043 17.3964 18.4534 17.7403 18.7611 18.1247C18.8593 18.2477 18.9528 18.3754 19.0427 18.5067C18.8617 18.6834 18.6262 18.8004 18.3694 18.8362C18.315 18.8446 18.2594 18.8481 18.2025 18.8481H16.4299C16.0169 18.8481 15.6891 19.0356 15.4785 19.309C15.0904 19.8116 15.101 20.6079 15.7176 21.0592L17.1517 22.1109C17.476 22.3485 17.6559 22.7258 17.6511 23.115H5.84846C5.37985 23.115 5 22.7317 5 22.259C5 19.9907 5.96679 18.2489 7.42585 17.0932C8.06841 16.5846 8.79971 16.1942 9.5748 15.9197C8.14059 14.9455 7.19628 13.2944 7.19628 11.4201C7.19628 8.42709 9.6032 6 12.5734 6C15.5436 6 17.9505 8.42709 17.9505 11.4201ZM21.2284 17.5397C21.1739 17.3702 20.9373 17.3702 20.8828 17.5397L20.0758 20.0432C20.0521 20.1196 19.9823 20.1697 19.903 20.1697H17.2937C17.1174 20.1697 17.0441 20.3978 17.1861 20.5016L19.2983 22.05C19.3622 22.0966 19.3882 22.179 19.3646 22.2542L18.5576 24.7589C18.5031 24.9284 18.6948 25.0681 18.8368 24.9642L20.9491 23.4158C21.013 23.3692 21.0982 23.3692 21.1621 23.4158L23.2744 24.9642C23.3454 25.0167 23.4294 25.0072 23.4885 24.963C23.5465 24.92 23.5809 24.8436 23.5536 24.7589L22.7466 22.2542C22.7229 22.179 22.7489 22.0966 22.8129 22.05L24.9251 20.5016C25.0671 20.3978 24.9938 20.1697 24.8174 20.1697H22.2081C22.1289 20.1697 22.059 20.1196 22.0354 20.0432L21.2284 17.5397Z"
      fill="currentColor"
    />
  </svg>
);
