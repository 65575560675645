import moment from 'moment';
import { getFormData, getFormDataToAnotherUser } from '../userInit';
import { catchWrapper } from '../../../helpers/utility';
import { postGetUserData } from '../../../api';
import { goToProfile } from '../../../routes/actions';

export default (id) => async (dispatch) => {
  dispatch({ type: 'PROFILE/LOAD/REQUESTED' });

  let formData;

  if (id) {
    formData = getFormDataToAnotherUser();
    formData.append('user_id', id);
    dispatch({ type: 'PROFILE/CLEAR_ANOTHER_USER' });
  } else {
    formData = getFormData();
  }

  const result = await catchWrapper(postGetUserData(formData));

  if (result && result.result) {
    if (id) {
      dispatch({ type: 'PROFILE/LOAD_BY_ID/SUCCEEDED', payload: result.user });
    } else {
      const user = result && result.user;

      if (user.birth_date === 'Invalid date') {
        user.birth_date = new Date();
      }
      if (user.birth_date) {
        user.birth_date = moment(user.birth_date).format('YYYY-MM-DD');
      }
      if (user.image_avatar) {
        user.image_avatar.preview = `${user.image_avatar.preview}?v=${new Date().getTime()}`;
      }

      dispatch({ type: 'PROFILE/LOAD/SUCCEEDED', payload: user });
    }
  } else {
    dispatch({ type: 'PROFILE/LOAD/FAILED' });
    goToProfile();
  }
};
