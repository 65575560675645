import React from 'react';

import CustomInput from '../../../../../../components/input';
import CustomCheckbox from '../../../../../../components/customCheckbox';

import './styles.scss';

const CountUsers = ({ size, isCloseRegistration, onChangeInput, onChangeCheckbox, errors }) => {
  return (
    <div className="count-users count-users__container">
      <CustomInput
        onChange={onChangeInput}
        value={size}
        placeholder="Укажите количество игроков"
        name="size"
        type="number"
        label="Количество игроков"
        labelPosition="top"
        required
        error={errors?.size}
      />
      <CustomCheckbox
        name="close_reg"
        label="Закрывать регистрацию при достижении этого количества"
        onChange={onChangeCheckbox}
        checked={isCloseRegistration}
      />
    </div>
  );
};

export default CountUsers;
