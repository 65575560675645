import { catchWrapper } from '../../../helpers/utility';
import { bookmark } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ id, type }) => async (dispatch) => {
  const payloadRequest = { id: Number(id), type };
  dispatch({ type: 'SOCIAL/BOOKMARK/REQUESTED' });
  const result = await catchWrapper(bookmark(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'SOCIAL/BOOKMARK/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'SOCIAL/BOOKMARK/FAILED',
      payload: result && result?.message && result.message[0],
    });
  }
};
