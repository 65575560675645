import React from 'react';
import PhoneInput from 'react-phone-input-2';
import CustomInput from '../input';
import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const InputPhone = ({ value, onChange }) => {
  const classNamesPhone = cx('phone-input-container', {
    'phone-input-container--hidden': (value && !value.length) || !value,
  });
  return (
    <PhoneInput
      tabIndex="0"
      disableDropdown
      containerClass={classNamesPhone}
      inputClass="phone-input"
      inputComponent={<CustomInput />}
      placeholder="Введите номер телефона"
      value={value}
      onChange={onChange}
    />
  );
};

export default InputPhone;
