import { isEmpty } from 'lodash';

export const validationCreateEvent = ({
  images,
  eventImages,
  profile,
  type,
  size,
  dateStart,
  dateEnd,
  sides,
  location,
  allow,
}) => {
  const error = {};

  if (!images?.banner && !eventImages?.banner?.preview) {
    error.banner = 'Необходимо загрузить изображение!';
  }

  if (!profile?.title) {
    error.title = 'Поле обязательно для заполнения!';
  }

  if (!profile?.location_description) {
    error.location_description = 'Поле обязательно для заполнения!';
  }

  if (!type?.id) {
    error.type = 'Выберите тип игры!';
  }
  if (!size) {
    error.size = 'Укажите количество бойцов!';
  }
  if (!dateStart) {
    error.dateStart = 'Укажите дату и время!';
  }
  if (!dateEnd) {
    error.dateEnd = 'Укажите дату и время!';
  }
  if (!location?.country?.id) {
    error.country = 'Укажите страну!';
  }
  if (!location?.region?.id) {
    error.region = 'Укажите регион!';
  }
  if (!location?.city?.id) {
    error.city = 'Укажите город!';
  }
  if (!sides[0]?.title) {
    error.sideF = 'Поле обязательно для заполнения!';
  }
  if (!sides[1]?.title) {
    error.sideS = 'Поле обязательно для заполнения!';
  }
  if (!allow?.is_club && !allow?.is_guest && !allow.is_single) {
    error.allow = 'Поле обязательно для заполнения!';
  }
  if (!allow?.is_rules && !allow?.is_spec_rules) {
    error.rules = 'Поле обязательно для заполнения!';
  }
  if (!allow?.is_agreement && !allow?.is_spec_agreement) {
    error.agreement = 'Поле обязательно для заполнения!';
  }

  return isEmpty(error) ? null : error;
};
