import { createSelector } from '@reduxjs/toolkit';
import { MSG_FILTER } from './constants';

const conversationsListState = (state) => state.msg.conversationList;
const selectedFilterState = (state) => state.msg.filter;

export const conversationListSelector = createSelector(
  conversationsListState,
  selectedFilterState,
  (conversations, selectedFilter) => {
    if (selectedFilter === MSG_FILTER.UNREAD) {
      return conversations.filter((c) => c.unreadMessageCount > 0);
    }
    return conversations;
  },
);
