import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { CometChat } from '@cometchat-pro/chat';
import { hasKeyValue } from '../../helpers/utility';
import * as enums from '../../store/msg/enums';
import history from '../../routes/history';

import { setReceiverInfoAction } from '../../store/msg/actions/users';
import { loginUserMsg as loginUserMsgAction } from '../../store/msg/actions/authentication';
import {
  chatListFilterOptions,
  ConversationListManager,
} from '../../store/msg/listeners/conversationListController';
import {
  setConversationList as setConversationListAction,
  setFilterConversationAction,
  updateUserNetworkStatus as updateUserNetworkStatusAction,
} from '../../store/msg/actions/conversations';

import { Pages } from '../../routes/pages';
import { CometChatEvent } from '../../store/msg/listeners/chatEvent';
import { conversationListSelector } from '../../store/msg/selectors';
import ConversationsListMobile from './components/mobile';
import ConversationsListDesktop from './components/desktop';

import './styles.scss';
import modalService from '../../services/modalService';

class ChatPanel extends React.Component {
  static incomingMessageAudio = null;

  incrementUnreadCount = false;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
    this.isComponentMounted = false;
  }

  componentDidMount() {
    this.isComponentMounted = true;
    const { location } = history;
    const { setConversationList, setReceiverInfo } = this.props;

    if (location.pathname !== Pages.msg.base) {
      setReceiverInfo({});
    }

    this.conversationListController = new ConversationListManager(chatListFilterOptions.USERS);

    this.conversationListController.fetchNextConversation(setConversationList);
    this.conversationListController.attachListeners(this.conversationCallback);

    //updating last message whenever a message is composed and sent
    CometChatEvent.on(enums.EVENTS.UPDATE_LAST_MESSAGE, (args) => this.updateLastMessage(args));
    //updating unreadcount whenever a new message is received and not read.
    CometChatEvent.on(enums.EVENTS.NEW_MESSAGES, (args) => this.updateUnreadCount(args));
    //clearing unreadcount whenever scrolled to the bottom.
    CometChatEvent.on(enums.EVENTS.CLEAR_UNREAD_MESSAGES, (args) => this.clearUnreadCount(args));
  }

  componentDidUpdate(prevProps) {
    const { receiver, setConversationList } = this.props;
    if (!isEmpty(receiver) && receiver?.uid !== prevProps?.receiver?.uid) {
      // eslint-disable-next-line react/destructuring-assignment
      const conversationList = [...this.props.conversationList];
      const conversationObj = this.filterConversation();

      if (
        conversationObj &&
        conversationObj.unreadMessageCount > 0 &&
        this.incrementUnreadCount === false
      ) {
        const conversationKey = conversationList.indexOf(conversationObj);
        const newConversationObj = { ...conversationObj, unreadMessageCount: 0 };

        conversationList.splice(conversationKey, 1, newConversationObj);
        setConversationList(conversationList);
      }
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.conversationListController.removeListeners();
    this.conversationListController = null;
  }

  handleToggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  onClosePanel = () => {
    this.setState({ open: false });
  };

  updateUnreadCount = () => {
    this.incrementUnreadCount = true;
    return false;
  };

  filterConversation = () => {
    const { receiver } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const conversationList = [...this.props.conversationList];
    return conversationList.find((c) => {
      if (
        c.conversationType === CometChat.RECEIVER_TYPE.USER &&
        c.conversationWith.uid === receiver?.uid
      ) {
        return c;
      }

      return false;
    });
  };

  clearUnreadCount = () => {
    const { setConversationList } = this.props;
    this.incrementUnreadCount = false;

    // eslint-disable-next-line react/destructuring-assignment
    const conversationList = [...this.props.conversationList];
    const conversationObj = this.filterConversation();

    if (conversationObj && conversationObj.unreadMessageCount > 0) {
      const conversationKey = conversationList.indexOf(conversationObj);
      const newConversationObj = { ...conversationObj, unreadMessageCount: 0 };

      conversationList.splice(conversationKey, 1, newConversationObj);
      setConversationList(conversationList);
    }
    return false;
  };

  markMessageAsDelivered = (message) => {
    //if chat window is not open, mark message as delivered
    if (!message.deliveredAt) {
      CometChat.markAsDelivered(message);
    }
  };

  updateLastMessage = (lastMessage) => {
    const { setConversationList, receiver } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const conversationList = [...this.props.conversationList];
    const conversationKey = conversationList.findIndex(
      (c) => c.conversationId === lastMessage.conversationId,
    );

    if (conversationKey > -1) {
      const conversationObj = conversationList[conversationKey];
      const newConversationObj = { ...conversationObj, lastMessage: { ...lastMessage } };

      if (conversationKey === 0) {
        conversationList.splice(conversationKey, 1, newConversationObj);
      } else {
        conversationList.splice(conversationKey, 1);
        conversationList.unshift(newConversationObj);
      }
      if (this.isComponentMounted) {
        setConversationList(conversationList);
      }
    } else {
      const newConversation = new CometChat.Conversation();
      newConversation.setConversationId(lastMessage.conversationId);
      newConversation.setConversationType(chatListFilterOptions.USERS);
      newConversation.setConversationWith(receiver);
      newConversation.setLastMessage(lastMessage);
      newConversation.setUnreadMessageCount(0);
      conversationList.unshift(newConversation);

      if (this.isComponentMounted) {
        setConversationList(conversationList);
      }
    }
  };

  makeConversation = (message) => {
    // eslint-disable-next-line react/destructuring-assignment
    const oldList = this.props.conversationList;
    const promise = new Promise((resolve) => {
      CometChat.CometChatHelper.getConversationFromMessage(message).then((conversation) => {
        const conversationList = [...oldList];
        const conversationKey = conversationList.findIndex(
          (c) => c.conversationId === conversation.conversationId,
        );

        let conversationObj = { ...conversation };
        if (conversationKey > -1) {
          conversationObj = { ...conversationList[conversationKey] };
        }

        resolve({
          conversationKey,
          conversationObj,
          conversationList,
        });
      });
    });
    return promise;
  };

  makeLastMessage = (message) => ({ ...message });

  playAudio = () => {
    const audioFile = process.env.production
      ? import('./audio/incomingmessage.wav')
      : import('./audio/message.mp3');
    audioFile.then((response) => {
      this.incomingMessageAudio = new Audio(response.default);
      this.incomingMessageAudio.currentTime = 0;
      this.incomingMessageAudio.play();
    });
  };

  makeUnreadMessageCount = (message, conversation = {}) => {
    const { user, receiver } = this.props;

    if (Object.keys(conversation).length === 0) {
      if (message.sender.uid === user?.uid) {
        return 0;
      }
      return 1;
    }

    let unreadMessageCount = parseInt(conversation.unreadMessageCount, 10);
    if (receiver?.uid === conversation?.conversationWith?.uid) {
      if (this.incrementUnreadCount) {
        unreadMessageCount += 1;
      } else {
        unreadMessageCount = 0;
      }
    } else {
      unreadMessageCount = this.shouldIncrementCount(message)
        ? unreadMessageCount + 1
        : unreadMessageCount;
    }
    return unreadMessageCount;
  };

  shouldIncrementCount = (incomingMessage) => {
    const { user } = this.props;
    let output = false;
    if (
      (incomingMessage.category === CometChat.CATEGORY_MESSAGE &&
        incomingMessage.sender.uid !== user?.uid) ||
      (hasKeyValue(incomingMessage, enums.KEYS.METADATA) &&
        hasKeyValue(incomingMessage[enums.KEYS.METADATA], enums.KEYS.INCREMENT_UNREAD_COUNT) &&
        incomingMessage[enums.KEYS.METADATA][enums.KEYS.INCREMENT_UNREAD_COUNT] === true &&
        incomingMessage.sender.uid !== user?.uid)
    ) {
      output = true;
    }

    return output;
  };

  onDeleteConfirm = (conversation) => {
    const conversationWith = conversation?.conversationWith?.uid;
    CometChat.deleteConversation(conversationWith, conversation.conversationType)
      .then((deletedConversation) => {
        this.conversationDeleted(conversation);
      })
      .catch((error) => {
        console.log(error, 'SOMETHING_WRONG');
      });
  };

  conversationDeleted = (conversation) => {
    const { conversationList, setConversationList, setReceiverInfo } = this.props;
    const conversationListUpdated = [...conversationList];
    const conversationKey = conversationListUpdated.findIndex(
      (c) => c.conversationId === conversation.conversationId,
    );

    if (conversationKey > -1) {
      conversationListUpdated.splice(conversationKey, 1);
      setConversationList(conversationListUpdated);
      setReceiverInfo({});
    }
  };

  conversationUpdated = (key, message, options) => {
    switch (key) {
      case enums.TEXT_MESSAGE_RECEIVED:
      case enums.MEDIA_MESSAGE_RECEIVED:
      case enums.CUSTOM_MESSAGE_RECEIVED:
        this.updateConversation(key, message);
        break;
      case enums.MESSAGE_EDITED:
      case enums.MESSAGE_DELETED:
        this.conversationEditedDeleted(message);
        break;
      case enums.GROUP_MEMBER_UNBANNED:
        this.updateGroupMemberChanged(message, options);
        break;
      case enums.MESSAGE_READ:
        this.onMessagesRead(message);
        break;
      default:
        break;
    }
  };

  updateConversation = (key, message) => {
    const { setConversationList } = this.props;
    this.makeConversation(message).then((response) => {
      const { conversationKey, conversationObj, conversationList } = response;

      if (conversationKey > -1) {
        const unreadMessageCount = this.makeUnreadMessageCount(message, conversationObj);
        const lastMessageObj = this.makeLastMessage(message, conversationObj);

        const newConversationObj = {
          ...conversationObj,
          lastMessage: lastMessageObj,
          unreadMessageCount,
        };

        conversationList.splice(conversationKey, 1);
        conversationList.unshift(newConversationObj);
        setConversationList(conversationList);
        this.playAudio();
      } else {
        const unreadMessageCount = this.makeUnreadMessageCount(message, {});
        const lastMessageObj = this.makeLastMessage(message);

        const newConversationObj = {
          ...conversationObj,
          lastMessage: lastMessageObj,
          unreadMessageCount,
        };
        conversationList.unshift(newConversationObj);
        setConversationList(conversationList);
      }
    });
  };

  conversationCallback = (key, item, message, options) => {
    const { updateUserNetworkStatus } = this.props;
    switch (key) {
      case enums.USER_ONLINE:
      case enums.USER_OFFLINE:
        updateUserNetworkStatus(item);
        break;
      case enums.TEXT_MESSAGE_RECEIVED:
      case enums.MEDIA_MESSAGE_RECEIVED:
      case enums.CUSTOM_MESSAGE_RECEIVED:
        this.markMessageAsDelivered(message);
        this.conversationUpdated(key, message, options);
        break;
      default:
        break;
    }
  };

  render() {
    const { open, isHovering } = this.state;
    const {
      setReceiverInfo,
      conversationList,
      setFilter,
      selectedFilter,
      i18n,
      platform,
      isMobile,
    } = this.props;

    const { host } = window.location;
    if (host === 'new.airsoft.net' || host === 'airsoft.net') {
      return null;
    }

    return isMobile ? (
      <ConversationsListMobile
        platform={platform}
        toggle={this.handleToggle}
        setFilter={setFilter}
        open={open}
        selectedFilter={selectedFilter}
        setReceiverInfo={setReceiverInfo}
        conversationList={conversationList}
        onClosePanel={this.onClosePanel}
        language={i18n?.language}
        deleteConversation={this.onDeleteConfirm}
      />
    ) : (
      <ConversationsListDesktop
        platform={platform}
        toggle={this.handleToggle}
        setFilter={setFilter}
        open={open}
        selectedFilter={selectedFilter}
        setReceiverInfo={setReceiverInfo}
        conversationList={conversationList}
        onClosePanel={this.onClosePanel}
        language={i18n?.language}
        deleteConversation={this.onDeleteConfirm}
      />
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      conversationList: conversationListSelector(state),
      chat: state.profile.user.chat,
      user: state.msg.user,
      receiver: state.msg.receiver,
      selectedFilter: state.msg.filter,
    }),
    {
      setConversationList: setConversationListAction,
      setReceiverInfo: setReceiverInfoAction,
      loginUserMsg: loginUserMsgAction,
      updateUserNetworkStatus: updateUserNetworkStatusAction,
      setFilter: setFilterConversationAction,
    },
  )(ChatPanel),
);
