export const eventsType = {
  1: 'events.types.classic',
  2: 'events.types.cqb',
  3: 'events.types.role',
  4: 'events.types.raid',
  5: 'events.types.sym',
  6: 'events.types.ipsc',
};

export const eventsSize = {
  1: 'до 50',
  2: 'до 100',
  3: 'до 200',
  4: 'до 300',
  5: 'до 500',
  6: 'до 750',
  7: 'больше 1000',
};

export const teamRole = {
  commander: 'commander',
  deputy: 'deputy',
};

export const userTypeEnum = {
  developer: 'developer',
  admin: 'admin',
};

export const sponsorTypeEnum = {
  gold: 'gold',
  silver: 'silver',
  bronze: 'bronze',
  vip: 'vip',
  participant: 'participant',
  guest: 'guest',
  remove: 'remove',
};
