import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useActions, useSelector } from '../../../../hooks/store';
import { authActions } from '../../../../store/auth';

import TeamButtonsControl from '../teamButtonsControl';
import TeamLinksBlock from '../teamLinksBlock';

import './styles.scss';

const BlockMain = ({
  userId,
  team,
  userTeam,
  role,
  loading,
  loadingSilent,
  sendRequestJoinTeam,
  cancelRequestJoinTeam,
}) => {
  const { getServices } = useActions(authActions);
  const { services } = useSelector((state) => state.auth);
  const pic = team && team.images && team.images.image && team.images.image.preview;

  useEffect(() => {
    if (isEmpty(services)) getServices();
  }, []);

  return (
    <div className="card team-main-block">
      <div className="image-container">
        <div className="image-main__wrap">{pic ? <img src={pic} /> : null}</div>
        <div className="image-secondary__container">
          {team?.camouflages?.map((c) => {
            return (
              <Link key={c.id} to={{ pathname: `/camouflage/${c.id}`, state: c.id }}>
                <div className="image-secondary__wrap">
                  <img src={c.image.preview} alt="" />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="team-main__info">
        <h3 className="team-main__title">{team?.title || ''}</h3>
        {/*<span className="team-main__text">{team?.block?.title || ''}</span>*/}
      </div>
      <TeamButtonsControl
        userId={userId}
        team={team}
        userTeam={userTeam}
        role={role}
        loading={loading}
        loadingSilent={loadingSilent}
        sendRequestJoinTeam={sendRequestJoinTeam}
        cancelRequestJoinTeam={cancelRequestJoinTeam}
      />
      {services && team?.social_service && (
        <TeamLinksBlock services={services} social={team.social_service} />
      )}
    </div>
  );
};

export default BlockMain;
