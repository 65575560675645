import { catchWrapper } from '../../../helpers/utility';
import { getEventTypes } from '../../../api/v2';

export default () => async (dispatch) => {
  dispatch({ type: 'EVENTS/GET_EVENT_TYPES/REQUESTED' });

  const result = await catchWrapper(getEventTypes());

  if (result?.result) {
    dispatch({ type: 'EVENTS/GET_EVENT_TYPES/SUCCEEDED', payload: result?.types });
  } else {
    dispatch({
      type: 'EVENTS/GET_EVENT_TYPES/FAILED',
      payload: result?.message && result.message[0],
    });
  }
};
