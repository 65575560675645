import React from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import SubNavigation from '../../../components/subNavi';
import { Pages } from '../../../../routes/pages';
import history from '../../../../routes/history';
import Entry from './sections/entry';
import Responsibility from './sections/responsibility';
import PersonalData from './sections/data';
import { useResponsive } from '../../../../hooks/useResponsive';

const naviMap = (location, id) => {
  return [
    {
      id: '1',
      label: 'Вступление в клуб',
      route: {
        pathname: Pages.organizers.agreements.replace(':id', id),
        hash: '#entry',
        state: location.state,
      },
    },
    {
      id: '2',
      label: 'Персональные данные',
      route: {
        pathname: Pages.organizers.agreements.replace(':id', id),
        hash: '#data',
        state: location.state,
      },
    },
    {
      id: '3',
      label: 'Ответственность на мероприятии',
      route: {
        pathname: Pages.organizers.agreements.replace(':id', id),
        hash: '#responsibility',
        state: location.state,
      },
    },
  ];
};

const renderSections = (hash, sponsorId) => {
  switch (hash) {
    case '#entry':
      return <Entry sponsorId={sponsorId} />;
    case '#data':
      return <PersonalData sponsorId={sponsorId} />;
    case '#responsibility':
      return <Responsibility sponsorId={sponsorId} />;
    default:
      return <Entry sponsorId={sponsorId} />;
  }
};

const AgreementsOrgPage = () => {
  const { location } = history;
  const { id } = useParams();
  const { isMobile } = useResponsive();
  return (
    <div className="org-page--agreements">
      {!isMobile && <SubNavigation naviMap={naviMap(location, id)} />}
      <div className="org-page--agreements__editor">{renderSections(location.hash, id)}</div>
    </div>
  );
};
export default AgreementsOrgPage;
