import React, { memo } from 'react';

import { classNames as cx } from '../../../../helpers/utility';

import WrapperOutBodyScroll from '../wrapper-out-body-scroll';
import PanelControls from '../panel-controls';
import PanelList from '../panel-list';

function ConversationsListDesktop({
  platform,
  toggle,
  setFilter,
  open,
  selectedFilter,
  setReceiverInfo,
  conversationList,
  onClosePanel,
  language,
  deleteConversation,
}) {
  const classNamesContainer = cx('messenger-container', {
    [`messenger-container--${platform}`]: platform,
  });

  const classNames = cx('messenger-panel', {
    'messenger-panel--open': open,
  });
  return (
    <WrapperOutBodyScroll className={classNamesContainer}>
      <div className={classNames}>
        <PanelControls
          onClick={toggle}
          setFilter={setFilter}
          open={open}
          selectedFilter={selectedFilter}
        />
        <PanelList
          setReceiverInfo={setReceiverInfo}
          open={open}
          list={conversationList}
          onClosePanel={onClosePanel}
          language={language}
          deleteConversation={deleteConversation}
        />
      </div>
    </WrapperOutBodyScroll>
  );
}

export default memo(ConversationsListDesktop);
