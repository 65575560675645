import React from 'react';
import Button from '../../../../components/button';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import Trans from '../../../../components/trans';

const CreateTeamButton = () => {
  const goToCreateTeam = () => {
    history.push({
      pathname: Pages.team.create.main,
    });
  };
  return (
    <div className="create-request__wrap">
      <Button onClick={goToCreateTeam} className="create-request" variant="main">
        {Trans('actions.createTeam')}
      </Button>
    </div>
  );
};

export default CreateTeamButton;
