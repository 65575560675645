import React from 'react';
import Skeleton from '../skeleton';
import { classNames as cx } from '../../helpers/utility';

const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

const SkeletonGrid = ({ classNameGrid, classNameElement }) => {
  const classNamesGrid = cx('sk-grid-container', classNameGrid);
  const classNamesElement = cx('sk-grid-element', classNameElement);

  return (
    <div className={classNamesGrid}>
      {array.map((i) => (
        <Skeleton key={i.toString()} className={classNamesElement} />
      ))}
    </div>
  );
};

export default SkeletonGrid;
