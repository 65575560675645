import React from 'react';
import { useParams } from 'react-router-dom';

import { useResponsive } from '../../../../hooks/useResponsive';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import Spinner from '../../../../components/spinner';
import Skeleton from '../../../../components/skeleton';
import Button from '../../../../components/button';

const OwnerButton = () => {
  const { isMobile } = useResponsive();
  const { id } = useParams();
  const goToEdiPage = () => {
    if (isMobile) {
      // history.push(Pages.blog.navi);
      history.push({
        pathname: Pages.blog.navi,
        state: id,
      });
    } else {
      history.push({
        pathname: Pages.blog.settings.main.replace(':id', id),
        state: id,
      });
    }
  };
  return (
    <Button onClick={goToEdiPage} className="owner-btn">
      Настройки
    </Button>
  );
};

const OtherUserButton = ({
  subscribeBlog,
  isSubscriber,
  isRequestWasSent,
  getBlogSilent,
  loadingSilent,
  unSubscribeBlog,
}) => {
  const { id } = useParams();

  const handleSubscribe = async () => {
    await subscribeBlog(id);
    await getBlogSilent(id);
  };

  const handleCancelSubscribe = async () => {
    await unSubscribeBlog(id);
    await getBlogSilent(id);
  };

  return (
    <>
      {!isSubscriber ? (
        <Button onClick={handleSubscribe} className="owner-btn owner-btn__friends">
          {loadingSilent ? <Spinner size={20} /> : 'Подписаться'}
        </Button>
      ) : null}

      {isSubscriber ? (
        <Button onClick={handleCancelSubscribe} className="owner-btn owner-btn__was_sent">
          {loadingSilent ? <Spinner size={20} /> : 'Отписаться'}
        </Button>
      ) : null}
    </>
  );
};

const ButtonsBlock = ({
  loading,
  subscribeBlog,
  isRequestWasSent,
  unSubscribeBlog,
  getBlogSilent,
  loadingSilent,
  cancelFriendRequest,
  isSubscriber,
  blogHolder,
  blog,
}) => {
  // eslint-disable-next-line no-nested-ternary
  return loading ? (
    <Skeleton className="sk-owner-btn" />
  ) : blogHolder ? (
    <OwnerButton />
  ) : (
    <OtherUserButton
      subscribeBlog={subscribeBlog}
      isSubscriber={isSubscriber}
      isRequestWasSent={isRequestWasSent}
      unSubscribeBlog={unSubscribeBlog}
      getBlogSilent={getBlogSilent}
      loadingSilent={loadingSilent}
      cancelFriendRequest={cancelFriendRequest}
    />
  );
};

export default ButtonsBlock;
