import { catchWrapper } from '../../../helpers/utility';
import { uploadGroundImage } from '../../../api/v2';

export default ({ data, files }) => async (dispatch) => {

  const forEachLoop = () => {
    dispatch({ type: 'GROUND/UPLOAD_IMAGE/REQUESTED', payload: files.length });

    [...files].forEach(async (file) => {
      const formData = new FormData();
      formData.append('element', JSON.stringify(data));
      formData.append('image', file);
      const result = await catchWrapper(uploadGroundImage(formData));

      if (result && result.result) {
        dispatch({ type: 'GROUND/UPLOAD_IMAGE/SUCCEEDED', payload: result.image });
        formData.delete('image');
      } else {
        dispatch({
          type: 'GROUND/UPLOAD_IMAGE/FAILED',
          payload: result && result.message && result.message[0],
        });
        formData.delete('image');
      }
    });
  };
  await forEachLoop();
};
