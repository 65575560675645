import React from 'react';

import SponsorSelect from '../sponsorSelect';
import CustomInput from '../../../../../../components/input';
import CustomSelect from '../../../../../../components/select';

import './styles.scss';

const EventInfo = ({
  location,
  onChangeGeo,
  onMenuOpenCountry,
  onMenuOpenRegion,
  onMenuOpenCity,
  countries,
  regions,
  cities,
  loadOptions,
  sponsor,
  sponsors,
  onSetSponsor,
  onRemoveSponsor,
  types,
  onChangeType,
  onChangeProfile,
  profile,
  selectedType,
  errors,
}) => {
  return (
    <div className="create-event-info create-event-info__container">
      <span className="create-event-info__title">Данные об игре</span>
      <SponsorSelect
        sponsor={sponsor}
        sponsors={sponsors}
        loadOptions={loadOptions}
        onSetSponsor={onSetSponsor}
        onRemoveSponsor={onRemoveSponsor}
      />
      <div className="create-event-info__fields">
        <CustomInput
          label="Название"
          labelPosition="top"
          onChange={onChangeProfile}
          value={profile?.title}
          name="title"
          placeholder="Укажите название игры"
          required
          error={errors?.title}
        />
        <CustomSelect
          label="Страна"
          labelPosition="top"
          placeholder="Начните вводить"
          name="country"
          asyncSelect
          options={countries?.options}
          handleChange={(value) => onChangeGeo(value, 'country')}
          onMenuOpen={onMenuOpenCountry}
          value={location.init ? location?.country : null}
          required
          error={errors?.country}
        />
        <CustomSelect
          label="Регион"
          labelPosition="top"
          placeholder="Начните вводить"
          name="region"
          asyncSelect
          isDisabled={!location?.country?.id}
          options={regions?.options}
          handleChange={(value) => onChangeGeo(value, 'region')}
          onMenuOpen={onMenuOpenRegion}
          value={location?.region}
          required
          error={errors?.region}
        />
        <CustomSelect
          label="Город"
          labelPosition="top"
          placeholder="Начните вводить"
          name="city"
          asyncSelect
          options={cities?.options}
          handleChange={(value) => onChangeGeo(value, 'city')}
          isDisabled={!location?.region?.id}
          onMenuOpen={onMenuOpenCity}
          value={location?.city}
          required
          error={errors?.city}
        />
        <CustomInput
          label="Полигон"
          labelPosition="top"
          onChange={onChangeProfile}
          value={profile?.location_description}
          name="location_description"
          placeholder="Укажите название полигона"
          required
          error={errors?.location_description}
        />
        <CustomSelect
          label="Тип игры"
          labelPosition="top"
          placeholder="Выберите тип игры"
          name="types"
          options={types}
          value={selectedType}
          handleChange={onChangeType}
          required
          error={errors?.type}
        />
      </div>
    </div>
  );
};

export default EventInfo;
