import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

const CamouflageCard = ({ camouflage }) => {
  return (
    <div className="card camouflage-card">
      <Link to={{ pathname: `/camouflage/${camouflage.id}`, state: camouflage.id }}>
        <img src={camouflage.image.image} alt="" />
        <div className="camouflage-card__blur">
          <div className="camouflage-card__blur-name">{camouflage.title}</div>
          <div className="camouflage-card__blur-count">{camouflage.count}</div>
        </div>
      </Link>
    </div>
  );
};

export default CamouflageCard;
