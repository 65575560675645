import React from 'react';

import './styles.scss';
import { getDangerouslyHtml } from '../../../helpers/utility';

const StatisticsImageBlock = (props) => {
  const { text, value, src, className } = props;
  return (
    <div className="statistics-login-image">
      <div className="statistics-login-image__statistic-image">
        <div>{src && <img src={src} alt="img" />}</div>
        <div className={className}>
          <span className="statistics-login-image__action-buttons-image-number">
            {value ? <div dangerouslySetInnerHTML={getDangerouslyHtml(value)} /> : ' '}
          </span>
        </div>
      </div>
    </div>
  );
};

export default StatisticsImageBlock;
