import axios from 'axios';

const { CancelToken } = axios;
export const source = CancelToken.source();

export let globalCancelFetch;
export let cancelTokens = {};

const { host } = window.location;
const baseURL =
  host === 'new.airsoft.net' || host === 'airsoft.net'
    ? 'https://api.airsoft.net/v2'
    : 'https://devapi.airsoft.net/v2';

export const axiosWrapper = (url, data) => {
  const inst = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    cancelToken: new CancelToken((c) => {
      // An executor function receives a cancel function as a parameter
      globalCancelFetch = c;
      //console.log(url.replace('/', '_'));
      cancelTokens = {
        ...cancelTokens,
        [url]: c,
      };
    }),
  });
  return inst.post(url, data);
};

export const getUsersList = (data) => axiosWrapper('/users/list', data);
export const getFriendsList = (data) => axiosWrapper('/users/friends', data);
export const getMutualFriendsList = (data) => axiosWrapper('/users/mutual_friends', data);

export const getTeamsList = (data) => axiosWrapper('/teams/list', data);

export const countLoginPage = () => axiosWrapper('/count/login_page');

export const postSaveImage = (data) => axiosWrapper('/text/upload_image', data);
export const postSaveImageArticle = (data) => axiosWrapper('/blog/article/upload_images', data);
export const postSaveTeamMember = (data) => axiosWrapper('/team/users/edit', data);
export const getTeamRequests = (data) => axiosWrapper('/team/users/request_list', data);
export const saveTeamLink = (data) => axiosWrapper('/team/settings/link', data);
export const postSaveTeamProfile = (data) => axiosWrapper('/team/save', data);
export const getRecruitingList = (data) => axiosWrapper('/recruiting/list', data);
export const addRecruit = (url, data) => axiosWrapper(`/recruiting/${url}`, data);
export const createTeam = (data) => axiosWrapper('/team/create', data);

export const getSponsorList = (data) => axiosWrapper('/sponsor/list', data);
export const getBloggerEvents = (data) => axiosWrapper('/event/blogger_list', data);
export const getOrgEvents = (data) => axiosWrapper('/event/sponsor', data);
export const getSponsor = (data) => axiosWrapper('/sponsor/get', data);
export const getSponsorMembers = (data) => axiosWrapper('/sponsor/members', data);
export const getSponsorParticipants = (data) => axiosWrapper('/sponsor/teams', data);
export const getSponsorRequest = (data) => axiosWrapper('/sponsor/requests', data);

export const postSaveSponsorProfile = (data) => axiosWrapper('/sponsor/save', data);
export const postSaveSponsorMembers = (data) => axiosWrapper('sponsor/users/edit', data);
export const saveSponsorLink = (data) => axiosWrapper('/sponsor/settings/link', data);
export const saveBlogLink = (data) => axiosWrapper('/blog/settings/link', data);
export const postChangeParticipant = (data) => axiosWrapper('/sponsor/participant', data);
export const createSponsor = (data) => axiosWrapper('/sponsor/create', data);
export const postSaveSponsorLegal = (data) => axiosWrapper('/sponsor/save_legal', data);

export const getEventsList = (url, data) => axiosWrapper(`/event/${url}`, data);
export const getEvent = (data) => axiosWrapper('/event/get', data);
export const getEventParticipants = (data) => axiosWrapper('/event/participants/get_all', data);
export const getEventParticipantsBySide = (data) =>
  axiosWrapper('/event/participants/get_by_side', data);
export const getEventTypes = () => axiosWrapper('/event/get_types');
export const getEventYears = () => axiosWrapper('/event/years');
export const createEvent = (data) => axiosWrapper('/event/add', data);
export const editEvent = (data) => axiosWrapper('/event/edit', data);
export const onManageEventRequest = (url, data) => axiosWrapper(`/event/request/${url}`, data);
export const getEventOffer = (data) => axiosWrapper('/offer', data);

export const getText = (data) => axiosWrapper('/text/get_text', data);
export const saveText = (data) => axiosWrapper('text/save_text', data);
export const createBlogAlbum = (data) => axiosWrapper('/album/add', data);
export const createBlogVideo = (data) => axiosWrapper('/video/add', data);
export const editBlogVideo = (data) => axiosWrapper('/video/edit', data);
export const deleteBlogVideo = (data) => axiosWrapper('/video/delete', data);
export const deleteBlogAlbum = (data) => axiosWrapper('/album/delete', data);
export const editBlogAlbum = (data) => axiosWrapper('/album/edit', data);
export const getFullImageInfo = (data) => axiosWrapper('/album/image', data);
export const uploadBlogAlbumImage = (data) => axiosWrapper('/album/upload_image', data);
export const getBlogAlbumImages = (data) => axiosWrapper('/album/images', data);
export const likeAction = (data) => axiosWrapper('/like', data);
export const bookmark = (data) => axiosWrapper('/linked', data);
export const getBlogAlbumList = (data) => axiosWrapper('/album/list', data);
export const getUserPhotosList = (data) => axiosWrapper('/user/images', data);
export const getVideoList = (data) => axiosWrapper('/video/list', data);
export const getUserVideoList = (data) => axiosWrapper('/user/videos', data);
export const getFullVideoInfo = (data) => axiosWrapper('/video/get', data);
export const getCommentsList = (data) => axiosWrapper('/comments/get_list', data);
export const writeComments = (data) => axiosWrapper('/comments/write', data);
export const getBlogAlbum = (data) => axiosWrapper('/album/get', data);
export const postSponsorRequest = (data) => axiosWrapper('/sponsor/add_request', data);
export const postCancelSponsorRequest = (data) => axiosWrapper('/sponsor/cancel_request', data);
export const getBlogsList = (data) => axiosWrapper('/blog/list', data);
export const getBlog = (url) => axiosWrapper(`/blog/get${url}`);
export const getBlogSubscribes = (data) => axiosWrapper('/blog/subscribes', data);
export const getBlogTags = (data) => axiosWrapper('/blog/get_tags', data);
export const getBlogTypes = (data) => axiosWrapper('/blog/get_types', data);
export const getBlogArticleTypes = (data) => axiosWrapper('/blog/article/types', data);
export const createBlogArticle = (data) => axiosWrapper('/blog/article/add', data);
export const createBlog = (data) => axiosWrapper('/blog/add', data);
export const saveBlog = (data) => axiosWrapper('/blog/save', data);
export const getBlogArticlesList = (data) => axiosWrapper('/blog/article/list', data);
export const getBlogArticle = (data) => axiosWrapper('/blog/article/get', data);
export const editArticle = (data) => axiosWrapper('/blog/article/edit', data);
export const subscribeBlog = (data) => axiosWrapper('/blog/request/add', data);
export const unSubscribeBlog = (data) => axiosWrapper('/blog/request/remove', data);
export const getBlogSubscribersList = (data) => axiosWrapper('/blog/subscribers_list', data);
export const getAchievements = (url, data) => axiosWrapper(`/achievement/${url}`, data);
export const deleteTextImages = (data) => axiosWrapper('/text/remove_images', data);
export const deleteTextArticleImages = (data) => axiosWrapper('/blog/article/remove_images', data);
export const removeProfile = () => axiosWrapper('/user/remove');
export const onRemoveImagesFromAlbum = (data) => axiosWrapper('/album/remove_images', data);

export const postSupport = (url, data) => axiosWrapper(`/support/${url}`, data);

export const getGroundsList = (data) => axiosWrapper('/grounds/list', data);
export const getGround = (data) => axiosWrapper('/grounds/get', data);
export const uploadGroundImage = (data) => axiosWrapper('/grounds/upload_image', data);
export const clearGroundsImages = (data) => axiosWrapper('/grounds/clear_images', data);
export const removeGroundImage = (data) => axiosWrapper('/grounds/remove_image', data);
export const setGroundMainImage = (data) => axiosWrapper('/grounds/set_main_image', data);
export const saveGround = (data) => axiosWrapper('/grounds/save', data);
export const postGroundRequest = (data) => axiosWrapper('/grounds/request', data);

export const getTextPage = (data) => axiosWrapper('/page', data);

export const getNaviList = (data) => axiosWrapper('/text/get_pages', data);

export const changeTheme = (data) => axiosWrapper('/user/change_theme', data);
export const changeEventView = (data) => axiosWrapper('/user/change_event_view', data);
