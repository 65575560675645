import React from 'react';
import { Jodit } from 'jodit';
import JoditReact from 'jodit-react';
import { FileUpload } from './uploadService';

class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.editorConfig = {
      readonly: false,
      tabIndex: 1,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      // placeholder: 'Write something awesome ...',
      extraButtons: ['uploadImage'],
      autofocus: false,
      useSearch: false,
      spellcheck: false,
      language: 'ru',
      showCharsCounter: false,
      showWordsCounter: false,
      defaultActionOnPaste: 'insert_only_text',
      disablePlugins:
        'about,error-messages,iframe,search,source,stat,symbols,video,file,print,link,table-keyboard-navigation,copy-format,preview,select-cells,resize-cells,color,fullsize,inline-popup,clean-html,hr,tooltip,table,font,image',
      // disablePlugins:
      //   'about,iframe,search,source,stat,symbols,video,file,print,link,table-keyboard-navigation,copy-format,preview,select-cells,resize-cells,color,fullsize,inline-popup,hr,autofocus,indent,justify,limit,ordered-list,placeholder,redo-undo,resizer,resize-handler,sticky,tooltip,xpath,media,drag-and-drop-element,add-new-line,hotkeys,font,error-messages,enter,paste-storage,clipboard,wrap-text-nodes,delete,clean-html,class-span,drag-and-drop,mobile',
      // disablePlugins: "about,iframe,search,source,stat,symbols,video,file,print,link,table-keyboard-navigation,copy-format,preview,select-cells,resize-cells,color,fullsize,inline-popup,hr,autofocus,clipboard,indent,justify,limit,mobile,ordered-list,placeholder,redo-undo,resizer,resize-handler,sticky,tooltip,xpath,image-properties,image-processor,image,media,delete,bold,wrap-text-nodes,paste-storage,paste,font,error-messages,enter,drag-and-drop-element,add-new-line,hotkeys",
    };
  }

  componentWillMount() {
    this.uploadImageButton();
    // this.deleteImage();
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: 'Upload image',
      iconURL: 'https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png',
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }

      const imageInfo = await FileUpload(
        imageFile,
        this.props.page,
        this.props.userTeamId,
        this.props.type,
        this.props.element,
        this.props.isArticle,
      );

      this.insertImage(editor, imageInfo);
    }.bind(this);
  };

  deleteImage = (editor) => {
    Jodit.events.on('afterRemoveNode', (node) => {
      if (node.nodeName === 'IMG') {
        console.log('image deleted');
      }
    });
  };

  insertImage = (editor, data) => {
    const imageSrc = `${data?.baseurl}${
      data && data?.images && data?.images[0] && data?.images[0]?.filename
    }`;
    const imageServerId = data?.images && data?.images[0] && data?.images[0]?.id;
    const image = editor.selection.j.createInside.element('img');
    image.setAttribute('src', imageSrc);
    image.setAttribute('serverid', imageServerId);
    editor.selection.insertNode(image);
  };

  onChange = (value) => {
    this.props.onChange(value);
  };

  setContent = (newContent) => {
    this.setState({
      editorContent: newContent,
    });
  };

  render() {
    return (
      <React.Fragment>
        <JoditReact
          value={this.props.text}
          config={this.editorConfig}
          onChange={this.onChange.bind(this)}
          onBlur={(newContent) => this.props.onChange(newContent)}
        />
      </React.Fragment>
    );
  }
}

export default TextEditor;
