import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="18" cy="17.998" rx="14" ry="14" transform="rotate(-90 18 17.998)" fill="#F1F1F3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2404 14.3113L12.3146 19.1855C11.8951 19.6006 11.8951 20.2736 12.3146 20.6887C12.7341 21.1038 13.4142 21.1038 13.8337 20.6887L18 16.5661L22.1663 20.6887C22.5858 21.1038 23.2659 21.1038 23.6854 20.6887C24.1049 20.2736 24.1049 19.6006 23.6854 19.1855L18.7595 14.3113C18.3401 13.8962 17.6599 13.8962 17.2404 14.3113Z"
      fill="#C0B4AF"
    />
  </svg>
);
