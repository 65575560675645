import React, { useState } from 'react';

import { useResponsive } from '../../hooks/useResponsive';

import GroundsSectionMobile from './mobile';
import GroundsSectionDesktop from './desktop';
import Loader from '../../components/loader';
import { useSelector } from '../../hooks/store';

const GroundsPage = () => {
  const { saveRequest } = useSelector((state) => state.grounds);
  const [search, setSearch] = useState('');
  const { isMobile } = useResponsive();
  const handleChangeSearch = (v) => setSearch(v);
  return (
    <>
      {!!saveRequest && <Loader />}
      {isMobile ? (
        <GroundsSectionMobile search={search} onChangeSearch={handleChangeSearch} />
      ) : (
        <GroundsSectionDesktop search={search} onChangeSearch={handleChangeSearch} />
      )}
    </>
  );
};

export default GroundsPage;
