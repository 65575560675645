import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { globalCancelFetch } from '../../../../api/v2';
import { useActions, useSelector } from '../../../../hooks/store';
import { teamsActions } from '../../../../store/teams';
import { rankENUM } from '../../../../helpers/utility';

import TeamUserCard from '../../components/teamUserCard';
import SkeletonPersonnel from '../../components/skeletonPersonnel';

import './styles.scss';
import Trans from '../../../../components/trans';

const PersonnelTeamPage = () => {
  const { teamMembers, loading } = useSelector((state) => state.teams);
  const { userId } = useSelector((state) => state.auth);
  const { getTeamMembers, onClearTeamMembers } = useActions(teamsActions);
  const { id } = useParams();
  useEffect(() => {
    getTeamMembers(id);

    return () => {
      onClearTeamMembers();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const commander = teamMembers && teamMembers.commander;
  const deputy = teamMembers && teamMembers.deputy;
  const groups = teamMembers && teamMembers.groups;
  const recruits = teamMembers && teamMembers.users;

  return (
    <div className="personnel-page">
      {loading ? (
        <SkeletonPersonnel />
      ) : (
        <>
          <div className="personnel-page__founder">
            {commander ? (
              <TeamUserCard user={commander} rank={rankENUM.COMMANDER} userId={userId} isLink />
            ) : null}

            {!isEmpty(deputy) &&
              deputy.map((d) => (
                <TeamUserCard key={d.id} user={d} rank={rankENUM.SUB_COMMANDER} userId={userId} />
              ))}
          </div>

          {!isEmpty(groups) &&
            groups.map((p) => (
              <>
                {(p.commander || !isEmpty(p.users)) && (
                  <div key={p.id} className="personnel-page__platoon">
                    <h3 className="personnel-page__title">{p.title}</h3>
                    <div className="personnel-page__platoon-wrap">
                      {p.commander ? (
                        <TeamUserCard
                          user={p.commander}
                          rank={rankENUM.PLAT_COMMANDER}
                          userId={userId}
                        />
                      ) : null}

                      {!isEmpty(p.users) &&
                        p.users.map((u) => (
                          <TeamUserCard key={u.id} user={u} userId={userId} isLink />
                        ))}
                    </div>
                  </div>
                )}
              </>
            ))}

          {!isEmpty(recruits) && (
            <div className="personnel-page__recruits">
              <h3 className="personnel-page__title">{Trans('teams.personnel.recruits')}</h3>
              <div className="personnel-page__recruits-wrap">
                {!isEmpty(recruits) &&
                  recruits.map((r) => <TeamUserCard key={r.id} user={r} userId={userId} />)}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PersonnelTeamPage;
