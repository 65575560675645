import React from 'react';
import { connect } from 'react-redux';
import { CometChat } from '@cometchat-pro/chat';
import { withTranslation } from 'react-i18next';

import MessageBlock from '../msg-block';
import MessageDateSeparator from '../msg-date-separator';
import { getFormattedDateNumber } from '../../../../helpers/utility';
import {
  cleanMessagesAction,
  setEditModeAction,
  setMessage as setMessageAction,
  setMessageHistory as setMessageHistoryAction,
  updateMessagesAction,
} from '../../../../store/msg/actions/messages';
import * as enums from '../../../../store/msg/enums';
import { MessageListManager } from '../../../../store/msg/listeners/messageListController';
import { setReceiverInfoAction } from '../../../../store/msg/actions/users';
import { CometChatEvent } from '../../../../store/msg/listeners/chatEvent';

import './styles.scss';
import MsgBlockDeleted from '../msg-block-deleted';
import MessagesFooter from '../footer';

class MessagesContainer extends React.Component {
  lastScrollTop = 0;

  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
      messageToBeEdited: '',
    };

    this.messagesEnd = React.createRef();
  }

  componentDidMount() {
    const { receiver, cleanMessages } = this.props;
    cleanMessages();
    this.messageController = new MessageListManager(receiver, 'user');
    this.messageController.initializeMessageRequest().then(() => {
      this.messageHandler(enums.ACTIONS.MESSAGES_INITIAL_FETCH);
      this.messageController.attachListeners(this.messageUpdated);
    });
  }

  componentDidUpdate(prevProps) {
    const { messageList, receiver, scrollToBottom, cleanMessages } = this.props;

    if (prevProps.receiver.uid !== receiver.uid) {
      this.messageController.removeListeners();
      cleanMessages();
      this.messageController = new MessageListManager(receiver, 'user');
      this.messageController.initializeMessageRequest().then(() => {
        this.messageHandler(enums.ACTIONS.MESSAGES_INITIAL_FETCH);
        this.messageController.attachListeners(this.messageUpdated);
      });
    }

    if (prevProps.messageList.length !== messageList) {
      if (scrollToBottom) {
        this.scrollToBottom();
      } else {
        this.scrollToBottom(this.lastScrollTop);
      }
    }
  }

  componentWillUnmount() {
    const { setReceiverInfo } = this.props;
    if (this.messageController) {
      this.messageController.removeListeners();
      this.messageController = null;
    }
    setReceiverInfo({});
  }

  fetchMessages = () => {
    const promise = new Promise((resolve, reject) => {
      this.messageController
        .fetchPreviousMessages()
        .then((messageList) => {
          resolve(messageList);
        })
        .catch((error) => reject(error));
    });

    return Promise.resolve(promise);
  };

  onMessageReadAndDelivered = (message) => {
    const { user, receiver, messageList, updateMessages } = this.props;
    //read receipts
    if (
      message.getReceiverType() === CometChat.RECEIVER_TYPE.USER &&
      message.getSender().getUid() === receiver?.uid &&
      message.getReceiver() === user?.uid
    ) {
      const updatedMessageList = [...messageList];

      if (message.getReceiptType() === 'delivery') {
        //search for message

        const messageKey = updatedMessageList.findIndex((m) => m.id === message.messageId);

        if (messageKey > -1) {
          const messageObj = updatedMessageList[messageKey];
          const newMessageObj = { ...messageObj, deliveredAt: message.getDeliveredAt() };

          updatedMessageList.splice(messageKey, 1, newMessageObj);
          updateMessages(updatedMessageList);
        }
      } else if (message.getReceiptType() === 'read') {
        //search for message
        const messageKey = updatedMessageList.findIndex((m) => m.id === message.messageId);

        if (messageKey > -1) {
          const messageObj = { ...updatedMessageList[messageKey] };
          const newMessageObj = { ...messageObj, readAt: message.getReadAt() };

          updatedMessageList.splice(messageKey, 1, newMessageObj);
          updateMessages(updatedMessageList);
        }
      }
    }
  };

  messageHandler = (actionType) => {
    const { setMessageHistory, user } = this.props;
    this.fetchMessages().then((messageList) => {
      if (messageList?.length) {
        setMessageHistory(messageList, actionType);
      }

      messageList.forEach((message) => {
        //if the sender of the message is not the loggedin user
        if (message.getSender().getUid() !== user?.uid) {
          //mark the message as delivered
          this.markMessageAsDelivered(message);

          //mark the message as read
          if (!message?.readAt) {
            CometChat.markAsRead(message);
            this.onMessageReadAndDelivered(message);
          }
        }
      });
      this.lastScrollTop = this.messagesEnd?.scrollHeight;
    });
  };

  scrollToBottom = (scrollHeight = 0) => {
    if (this.messagesEnd && this.messagesEnd.scrollHeight) {
      this.messagesEnd.scrollTop = this.messagesEnd.scrollHeight - scrollHeight;
    }
  };

  handleScroll = (e) => {
    const { messageList } = this.props;
    const { scrollTop, scrollHeight } = e.currentTarget;

    this.lastScrollTop = scrollHeight - scrollTop;

    // if (this.lastScrollTop === clientHeight) {
    //   this.props.actionGenerated(enums.ACTIONS.CLEAR_UNREAD_MESSAGES);
    // }

    const top = Math.round(scrollTop) === 0;
    if (top && messageList?.length) {
      this.messageHandler(enums.ACTIONS.MESSAGES_FETCHED);
    }
  };

  markMessageAsDelivered = (message) => {
    const { user } = this.props;
    if (message.sender?.uid !== user?.uid && !message?.deliveredAt) {
      CometChat.markAsDelivered(message);
    }
  };

  reInitializeMessageBuilder = () => {
    const { receiver } = this.props;
    this.messageController.removeListeners();

    this.messageController = new MessageListManager(receiver, 'user');

    this.messageController.initializeMessageRequest().then(() => {
      this.messageHandler(receiver, enums.ACTIONS.MESSAGES_REFRESHED);
      this.MessageListManager.attachListeners(this.messageUpdated);
    });
  };

  markMessageAsRead = (message) => {
    if (!message.readAt) {
      CometChat.markAsRead(message);
    }
  };

  onMessageDeleted = (message) => {
    this.removeMessages([message]);
  };

  messageReceivedHandler = (message) => {
    const { setMessage } = this.props;
    //handling dom lag - increment count only for main message list
    if (!message.parentMessageId) {
      //++this.messageCount;
      //if the user has not scrolled in chat window(scroll is at the bottom of the chat window)
      if (
        this.messagesEnd.scrollHeight -
          this.messagesEnd.scrollTop -
          this.messagesEnd.clientHeight <=
        1
      ) {
        this.markMessageAsRead(message);
        setMessage(message);
      } else {
        //if the user has scrolled up in chat window
        // this.props.actionGenerated(enums.ACTIONS.NEW_MESSAGES, [message]);
        setMessage(message);
      }
    }
  };

  onMessageReceived = (message) => {
    const { user, receiver } = this.props;
    //mark the message as delivered
    this.markMessageAsDelivered(message);
    if (
      (message.getSender().uid === receiver?.uid && message.getReceiverId() === user?.uid) ||
      (message.getSender().uid === user?.uid && message.getReceiverId() === receiver?.uid)
    ) {
      this.messageReceivedHandler(message, CometChat.RECEIVER_TYPE.USER);
    }
  };

  removeMessages = (messages) => {
    const { messageList, updateMessages } = this.props;
    const updatedMessageList = [...messageList];
    const deletedMessage = messages[0];
    const messageKey = updatedMessageList.findIndex((message) => message.id === deletedMessage.id);

    if (messageKey > -1) {
      const messageObj = { ...updatedMessageList[messageKey] };
      const newMessageObj = { ...messageObj, ...deletedMessage };

      updatedMessageList.splice(messageKey, 1, newMessageObj);
      updateMessages(updatedMessageList);
    }
  };

  deleteMessage = (message) => {
    const messageId = message.id;
    const { messageToBeEdited } = this.state;
    const { messageList } = this.props;
    CometChat.deleteMessage(messageId).then((deletedMessage) => {
      if (deletedMessage.id === messageToBeEdited.id) {
        this.setState({ messageToBeEdited: '' });
      }

      const messageListOld = [...messageList];
      const messageKey = messageListOld.findIndex((m) => m.id === message.id);

      if (messageListOld.length - messageKey === 1 && !message.replyCount) {
        CometChatEvent.triggerHandler('updateLastMessage', { ...deletedMessage });
      }
      this.removeMessages([deletedMessage]);
    });
  };

  onEditMode = (message) => {
    this.setState({ isEditMode: true, messageToBeEdited: message });
  };

  offEditMode = () => {
    this.setState({ isEditMode: false, messageToBeEdited: '' });
  };

  messageEdited = (message) => {
    const { messageList, updateMessages } = this.props;
    const messageListOld = [...messageList];

    const messageKey = messageListOld.findIndex((m) => m.id === message.id);
    if (messageKey > -1) {
      const messageObj = messageListOld[messageKey];

      const newMessageObj = { ...messageObj, ...message };

      messageListOld.splice(messageKey, 1, newMessageObj);
      updateMessages(messageListOld);

      if (messageListOld.length - messageKey === 1) {
        CometChatEvent.triggerHandler('updateLastMessage', { ...newMessageObj });
      }
    }
  };

  onMessageEdited = (message) => {
    const { messageList, updateMessages } = this.props;
    const messageListOld = [...messageList];

    const messageKey = messageListOld.findIndex((m) => m.id === message.id);

    if (messageKey > -1) {
      const messageObj = messageListOld[messageKey];
      const newMessageObj = { ...messageObj, ...message };

      messageListOld.splice(messageKey, 1, newMessageObj);
      updateMessages(messageListOld);
    }
  };

  editMessage = (text) => {
    const { messageToBeEdited } = this.state;
    const { receiver } = this.props;
    const messageText = text.trim();
    const textMessage = new CometChat.TextMessage(
      receiver.uid,
      messageText,
      CometChat.RECEIVER_TYPE.USER,
    );
    textMessage.setId(messageToBeEdited.id);
    const newMessage = { ...textMessage, messageFrom: messageToBeEdited.messageFrom };
    this.messageEdited(newMessage);
    CometChat.editMessage(textMessage)
      .then((message) => {
        this.messageEdited({ ...message });
      })
      .catch((error) => console.log(error));
  };

  messageUpdated = (key, message) => {
    switch (key) {
      case enums.MESSAGE_DELETED:
        this.onMessageDeleted(message);
        break;
      case enums.MESSAGE_EDITED:
        this.onMessageEdited(message);
        break;
      case enums.MESSAGE_DELIVERED:
      case enums.MESSAGE_READ:
        this.onMessageReadAndDelivered(message);
        break;
      case enums.TEXT_MESSAGE_RECEIVED:
      case enums.MEDIA_MESSAGE_RECEIVED:
        this.onMessageReceived(message);
        break;
      default:
        break;
    }
  };

  render() {
    const { isEditMode, messageToBeEdited } = this.state;
    const { user, messageList, i18n } = this.props;
    let cDate = null;
    return (
      <>
        <div className="msg-container">
          <div
            ref={(el) => {
              this.messagesEnd = el;
            }}
            onScroll={this.handleScroll}
            className="msg-container__inner"
          >
            <div className="msg-container--margin-top" />
            {messageList.map((message) => {
              let dateSeparator = null;
              // eslint-disable-next-line no-underscore-dangle
              const dateField = message._composedAt || message.sentAt;
              const messageSentDate = getFormattedDateNumber(message.sentAt, 'dd/LL/yyyy');

              if (cDate !== messageSentDate) {
                dateSeparator = <MessageDateSeparator date={dateField} language={i18n?.language} />;
              }
              cDate = messageSentDate;

              if (message.action === 'edited' || message.action === 'deleted') {
                return null;
              }

              if (message.deletedBy) {
                return (
                  <React.Fragment key={message.id}>
                    {dateSeparator}
                    <MsgBlockDeleted
                      own={message.receiverId !== user.uid}
                      time={getFormattedDateNumber(message.sentAt, 'H:mm', i18n?.language)}
                    />
                  </React.Fragment>
                );
              }

              return (
                <React.Fragment key={message.id}>
                  {dateSeparator}
                  <MessageBlock
                    onEditMode={this.onEditMode}
                    onDeleteMessage={this.deleteMessage}
                    message={message}
                    isRead={message.readAt}
                    own={message.receiverId !== user.uid}
                    imageUrl={message?.sender?.avatar}
                    text={message.text}
                    time={getFormattedDateNumber(message.sentAt, 'H:mm', i18n?.language)}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <MessagesFooter
          messageToBeEdited={messageToBeEdited}
          editMessage={this.editMessage}
          isEditMode={isEditMode}
          offEditMode={this.offEditMode}
        />
      </>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      messageList: state.msg.messageList,
      user: state.msg.user,
      receiver: state.msg.receiver,
      scrollToBottom: state.msg.scrollToBottom,
    }),
    {
      setMessageHistory: setMessageHistoryAction,
      setMessage: setMessageAction,
      cleanMessages: cleanMessagesAction,
      updateMessages: updateMessagesAction,
      setReceiverInfo: setReceiverInfoAction,
    },
  )(MessagesContainer),
);
