import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PriceCardWrapper from '../../components/priceCardWrapper';
import { useActions, useSelector } from '../../../../hooks/store';
import { eventsActions } from '../../../../store/events';
import { getDangerouslyHtml } from '../../../../helpers/utility';
import { useResponsive } from '../../../../hooks/useResponsive';

const getPrices = (arr, type) => arr?.filter((a) => a.type === type);

const PricesPage = () => {
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { event, eventSettings } = useSelector((state) => state.events);

  const { onGetEventSettingsPrices } = useActions(eventsActions);

  const { prices } = event;
  const pricesText = eventSettings?.prices?.text;

  useEffect(() => {
    if (id) {
      onGetEventSettingsPrices('5', Number(id), 'event', '1');
    }
  }, [id]);
  return (
    <div className="events-price-page">
      <PriceCardWrapper title="Стоимость участия" list={getPrices(prices, 1)} />
      <PriceCardWrapper title="Для регионов" list={getPrices(prices, 2)} />
      <PriceCardWrapper title="Для иностранцев" list={getPrices(prices, 3)} />
      <PriceCardWrapper title="Золотой статус" list={getPrices(prices, 4)} />
      <PriceCardWrapper title="Серебряный статус" list={getPrices(prices, 5)} />
      <PriceCardWrapper title="Бронзовый статус" list={getPrices(prices, 6)} />
      <PriceCardWrapper title="Водитель" list={getPrices(prices, 7)} />
      {pricesText && (
        <div className="events-text">
          {!isMobile && <div className="info-caption">Цены</div>}
          <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(pricesText)} />
        </div>
      )}
    </div>
  );
};

export default PricesPage;
