import React from 'react';

import './styles.scss';
import { classNames as cx } from '../../helpers/utility';

export const defaultBaseColor = '#eee';

export const defaultHighlightColor = '#f5f5f5';

export default function Skeleton({
  count,
  width,
  wrapper: Wrapper,
  height,
  circle,
  style: customStyle,
  className,
}) {
  const elements = [];

  for (let i = 0; i < count; i++) {
    const style = {};

    if (width !== null) {
      style.width = width;
    }

    if (height !== null) {
      style.height = height;
    }

    if (width !== null && height !== null && circle) {
      style.borderRadius = '50%';
    }

    const classNames = cx('react-loading-skeleton', className);

    elements.push(
      <span
        key={i}
        className={classNames}
        style={{
          ...customStyle,
          ...style,
        }}
      >
        &zwnj;
      </span>,
    );
  }

  return Wrapper
    ? elements.map((element, i) => (
        <Wrapper key={i}>
          {element}
          &zwnj;
        </Wrapper>
      ))
    : elements;
}

Skeleton.defaultProps = {
  count: 1,
  width: null,
  wrapper: null,
  height: null,
  circle: false,
};
