import React from 'react';
import { NavLink } from 'react-router-dom';

import { Pages } from '../../../../routes/pages';
import Icon from '../../../../components/icon';

import './styles.scss';

const navigationMap = [
  {
    id: '1',
    icon: 'profile-30',
    label: 'Профиль',
    path: Pages.settings.profile,
  },
  {
    id: '2',
    icon: 'passport-30',
    label: 'Игровой паспорт',
    path: Pages.settings.passport,
  },
  {
    id: '3',
    icon: 'links-30',
    label: 'Ссылки',
    path: Pages.settings.links,
  },
  {
    id: '4',
    icon: 'private-30',
    path: Pages.settings.private,
    label: 'Приватность',
  },
  {
    id: '5',
    icon: 'security-30',
    path: Pages.settings.security,
    label: 'Безопасность',
  },
];

const SettingsNavigationsMobile = () => {
  return (
    <div className="settings-nav">
      <div className="settings-nav__row settings-nav__theme">{/*<ThemeSwitch />*/}</div>

      {navigationMap.map((item) => {
        return (
          <NavLink key={item.id} className="settings-nav__link" to={item.path}>
            <div className="settings-nav__row settings-nav__link__row">
              <Icon type={item.icon} />
              <span className="settings-nav__row__text">{item.label}</span>
              <Icon type="chevron" />
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default SettingsNavigationsMobile;
