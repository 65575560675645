import React from 'react';
import ReactCodeInput from 'react-verification-code-input';

import './styles.scss';

const CodeInput = ({ fields, type, ...props }) => {
  return (
    <ReactCodeInput
      {...props}
      className="custom-code-input"
      type={type || 'number'}
      fields={fields}
    />
  );
};

export default CodeInput;
