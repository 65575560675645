import React from 'react';

export default () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9998 1.07715C6.86344 1.07715 1.0767 6.8625 1.0767 14.0002C1.0767 21.1366 6.86344 26.9233 13.9998 26.9233C21.1361 26.9233 26.9229 21.1366 26.9229 14.0002C26.9229 6.8625 21.1361 1.07715 13.9998 1.07715Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0596 9.03809L11.0766 13.9996L16.0596 18.9612"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
