import React, { useState } from 'react';

import { useActions, useSelector } from '../../hooks/store';

import ModalHeader from '../components/modalHeader';
import CustomInput from '../../components/input';
import Button from '../../components/button';

import './styles.scss';
import CustomPhoneInput from '../../components/customPhoneInput';
import { groundsActions } from '../../store/grounds';
import Loader from '../../components/loader';

const init = {
  title: null,
  fio: null,
  org: null,
  /*  email: null,*/
  description: null,
  phone: null,
};

const RequestGround = ({ ground, closeModal }) => {
  const [state, setState] = useState(init);

  const { sendingRequest, error, loading } = useSelector((s) => s.grounds);
  const { onChangeError, sendGroundRequest } = useActions(groundsActions);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    if (error && error[name]) {
      const temp = {
        ...error,
        [name]: null,
      };
      onChangeError(temp);
    }

    setState({
      ...state,
      [name]: value,
    });
  };

  const handleChangePhone = (value) => {
    handleChangeInput({
      target: {
        name: 'phone',
        value,
      },
    });
  };

  const onSubmit = () => sendGroundRequest(state, ground);

  return (
    <div className="modal-wrapper">
      {sendingRequest ? (
        <Loader />
      ) : (
        <>
          <ModalHeader
            title={`Заявка на мероприятие на полигоне "${ground?.profile.title}"`}
            close={() => closeModal()}
          />
          <div className="modal-content">
            <CustomInput
              onChange={handleChangeInput}
              value={state.title}
              label="Наименование мероприятия"
              labelPosition="top"
              placeholder="Наименование мероприятия"
              name="title"
              required
              error={error?.title}
            />
            <CustomInput
              onChange={handleChangeInput}
              value={state.org}
              label="Наименование организатора"
              labelPosition="top"
              placeholder="Наименование организатора"
              name="org"
              required
              error={error?.org}
            />
            <CustomInput
              onChange={handleChangeInput}
              value={state.fio}
              label="Контактное лицо"
              labelPosition="top"
              placeholder="Введите ФИО"
              name="fio"
              required
              error={error?.fio}
            />
            <CustomPhoneInput
              name="phone"
              label="Телефон для связи"
              placeholder="Введите номер телефона"
              onChange={handleChangePhone}
              value={state?.phone}
              error={error?.phone}
              required
            />
            {/*        <CustomInput
          onChange={handleChangeInput}
          value={state.email}
          label="E-mail"
          labelPosition="top"
          placeholder="E-mail"
          name="email"
          required
          error={error?.email}
        />*/}
            <CustomInput
              onChange={handleChangeInput}
              value={state.description}
              type="textarea"
              label="Описание"
              labelPosition="top"
              placeholder="Описание мероприятия"
              name="description"
              required
              error={error?.description}
            />
          </div>
          <div className="modal-footer">
            <Button disabled={loading} onClick={onSubmit} tabIndex="0" variant="main">
              Отправить
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default RequestGround;
