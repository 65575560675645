import React from 'react';
import { isEmpty } from 'lodash';
import Button from '../../../../components/button';
import { classNames as cx } from '../../../../helpers/utility';

import '../../desktop/links/styles.scss';

const SocialDesktop = ({ handleUnLink, providers, social }) => {
  return (
    <div className="settings-links">
      <h3 className="settings-links__title">Привязка соц. сетей</h3>
      {!isEmpty(social) &&
        !isEmpty(providers) &&
        providers.map((item) => {

          const classNamesToggle = cx('settings-links__toggle', {
            'settings-links__toggle_active': social[item.id].network_id,
            'settings-links__toggle_no_active': !social[item.id].network_id,
          });

          return (
            <div key={item.id} className="settings-links__row">
              <img src={item.icon} className="settings-links__icon" alt={item.title} />
              <span className="settings-links__name">{item.title}</span>

              {social[item.id].network_id ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="settings-links__link"
                  href={social[item.id].network_link}
                >{`@id${social[item.id].network_id}`}</a>
              ) : null}

              {social[item.id].network_id ? (
                <Button
                  onClick={() => handleUnLink(item.id)}
                  variant="text"
                  className={classNamesToggle}
                >
                  Отвязать
                </Button>
              ) : (
                <a href={item.url} className={classNamesToggle}>
                  Привязать к аккаунту
                </a>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default SocialDesktop;
