import React from 'react';
import CustomInput from '../../../components/input';

import './styles.scss';

const StepPassword = ({ error, onChange, password, rePassword }) => {
  return (
    <div className="change-psw__wrap step-psw">
      <p>Пароль должен содержать минимум 8 символов</p>
      <CustomInput
        value={password}
        onChange={onChange}
        placeholder="Введите новый пароль"
        labelPosition="top"
        name="password"
        type="password"
      />
      <CustomInput
        value={rePassword}
        onChange={onChange}
        placeholder="Введите пароль еще раз"
        labelPosition="top"
        name="rePassword"
        type="password"
      />
      <div className="error__wrap">
        <span className="change-psw__error">{error}</span>
      </div>
    </div>
  );
};

export default StepPassword;
