import { batch } from 'react-redux';
import moment from 'moment';
import { catchWrapper } from '../../../helpers/utility';
import { postAuth } from '../../../api';
import { localStorageService } from '../../../services/localStorageService';
import { goToEvents, goToLogin } from '../../../routes/actions';
import { Pages } from '../../../routes/pages';

export default () => async (dispatch, getState) => {
  const { location } = getState().router;
  dispatch({ type: 'AUTH/CHECK/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'checkAuth');

  const result = await catchWrapper(postAuth(formData));
  if (result?.auth && result?.result) {
    const authInfo = {
      token: result?.user_salt,
      userId: result?.current_user.id,
    };

    localStorageService.setAuthData(authInfo);

    const user = result?.current_user;

    if (user.birth_date === 'Invalid date') {
      user.birth_date = '1900-01-01T00:00:00+03:00';
    }
    if (user.birth_date) {
      user.birth_date = moment(user.birth_date).format('YYYY-MM-DD');
    }

    const authData = {
      userSponsors: result?.user_sponsors,
      userRecruiting: result?.user_recruiting,
      userBlog: result?.user_blog,
      currentEvent: result?.current_event,
    };

    batch(() => {
      dispatch({ type: 'AUTH/CHECK/SUCCEEDED', payload: authInfo });
      dispatch({ type: 'PROFILE/SET_USER', payload: user });
      dispatch({ type: 'PROFILE/SET_AUTH_DATA', payload: authData });

      dispatch({
        type: 'THEME/TOGGLE',
        payload: {
          theme: result?.theme ?? 0,
          eventView: result?.event_view ?? 1,
        },
      });
    });

    if (location.pathname === Pages.auth.auth) {
      goToEvents();
    }
  } else {
    const auth = !!localStorageService.getAuthData();

    batch(() => {
      dispatch({
        type: 'THEME/TOGGLE',
        payload: {
          theme: result?.theme ?? 0,
          eventView: result?.event_view ?? 1,
        },
      });
    });

    dispatch({ type: 'AUTH/CHECK/FAILED' });
    localStorageService.clearAuthData();
    if (auth) goToLogin();
  }
};
