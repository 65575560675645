import React from 'react';
import CustomCheckbox from '../../../../../../components/customCheckbox';

import './styles.scss';
import { classNames as cx } from '../../../../../../helpers/utility';

const CheckBoxesSection = ({ title, list, onChangeCheckbox, state, error }) => {
  const classNamesLabel = cx('checkboxes-section__title', {
    'checkboxes-section__title--error': error,
  });

  return (
    <div className="checkboxes-section checkboxes-section__container">
      <span className={classNamesLabel}>{error || title}</span>
      <div className="checkboxes-section__list">
        {list?.map((l) => {
          return (
            <CustomCheckbox
              checked={state[l.name]}
              key={l.id}
              name={l.name}
              label={l.label}
              onChange={onChangeCheckbox}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CheckBoxesSection;
