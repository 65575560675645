import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import history from '../../../../routes/history';
import { useActions, useSelector } from '../../../../hooks/store';
import { achievementsActions } from '../../../../store/achievements';
import { getAchievementType } from '../../utils/getAchievementType';
import { globalCancelFetch } from '../../../../api/v2';
import { classNames as cx } from '../../../../helpers/utility';
import { useResponsive } from '../../../../hooks/useResponsive';
import { getAchievementTitle } from '../../utils/getAchievmentTitle';

import CustomInfiniteScroll from '../../../../components/customInfiniteScroll';
import AwardItem from './awardItem';

import './styles.scss';

const AwardsPage = () => {
  const [page, setPage] = useState(1);
  const { location } = history;
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { userId, token } = useSelector((state) => state.auth);
  const { loading, list, pagination } = useSelector((state) => state.achievements);

  const { getAchievementList, clearAchievements } = useActions(achievementsActions);

  //Если есть ID в урле, то загружаем achievements другого пользователя, если нет, то своего
  useEffect(() => {
    if (token) {
      getAchievementList({
        page,
        userId: id || userId,
        type: getAchievementType(location),
      });
    }
  }, [id, token]);

  useEffect(() => {
    return () => {
      clearAchievements();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const classNames = cx('page-awards', {
    'page-awards--mobile': isMobile,
  });

  const fetchPaginated = useCallback(() => {
    setPage(page + 1);
    getAchievementList({
      page: page + 1,
      userId: id || userId,
      type: getAchievementType(location),
    });
  }, [page]);

  const hasMore = useMemo(() => {
    return (
      (pagination && Number(pagination.current_page)) <
      (pagination && Number(pagination.total_pages))
    );
  }, [pagination]);

  return (
    <div className={classNames}>
      <div className="page-awards__title-block">
        <h2>{getAchievementTitle(location)}</h2>
        {!isEmpty(list) ? (
          <div className="page-awards__counts">
            <span>{list?.length}</span>
            <span className="page-awards__counts-from">из</span>
            <span>{pagination?.total_items}</span>
          </div>
        ) : null}
      </div>
      <div className="page-awards__content">
        <CustomInfiniteScroll
          dataLength={list?.length}
          loading={loading}
          next={fetchPaginated}
          hasMore={hasMore}
          className="page-awards__grid"
        >
          {list?.length
            ? list.map((el, idx) => {
                return <AwardItem key={idx} imageSrc={el.image.image} />;
              })
            : null}
        </CustomInfiniteScroll>
      </div>
    </div>
  );
};

export default AwardsPage;
