import { catchWrapper } from '../../../helpers/utility';
import { getBlogTags } from '../../../api/v2';

export default () => async (dispatch) => {
  dispatch({ type: 'BLOG/LOAD_BLOG_TAGS/REQUESTED' });

  const result = await catchWrapper(getBlogTags());
  if (result && result.result) {
    dispatch({ type: 'BLOG/LOAD_BLOG_TAGS/SUCCEEDED', payload: result.items });
  } else {
    dispatch({
      type: 'BLOG/LOAD_BLOG_TAGS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
