import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { eventsActions } from '../../../../../store/events';
import { useActions, useSelector } from '../../../../../hooks/store';
import { getDangerouslyHtml } from '../../../../../helpers/utility';
import { useResponsive } from '../../../../../hooks/useResponsive';
import GroundPage from '../../../../grounds/components/groundsMainPage/components/groundPage';

const Polygon = () => {
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { onGetEventSettingsPolygon } = useActions(eventsActions);
  const { eventSettings, event } = useSelector((state) => state.events);
  const polygonText = eventSettings?.polygon?.text;

  useEffect(() => {
    if (id) {
      onGetEventSettingsPolygon('4', Number(id), 'event', '1');
    }
  }, [id]);

  return (
    <>
      {!isMobile && <div className="info-caption">Полигон</div>}
      {event?.ground?.id && (
        <>
          <GroundPage ground={event.ground} isRequest={false} />
          {polygonText && <div className="info-caption">Описание</div>}
        </>
      )}
      <div className="events-text">
        {polygonText && (
          <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(polygonText)} />
        )}
      </div>
    </>
  );
};

export default Polygon;
