import React from 'react';
import Spinner from '../spinner';

import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const Loader = ({ transparent }) => {
  const className = cx('load-placeholder', {
    transparent,
  });
  return (
    <div className={className}>
      <div className="load-placeholder__loading">
        <Spinner color="#eb9330" size={90} />
      </div>
    </div>
  );
};

export default Loader;
