import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2158 4.47874C19.1356 5.21357 19.2649 6.53001 18.5048 7.41909L17.4933 8.60211L14.1498 5.95619L15.1742 4.75802C15.9343 3.86895 17.2962 3.74391 18.2158 4.47874ZM12.8393 7.48901L16.1827 10.1349L9.01311 18.5206C8.89653 18.657 8.76578 18.7754 8.62465 18.8754C8.60276 18.8926 8.5793 18.9086 8.55432 18.9232C8.46496 18.9814 8.37187 19.0326 8.27593 19.0766L4.92412 20.9198C4.43199 21.1905 3.85408 20.7313 4.03341 20.2121L5.2486 16.6943C5.32348 16.3971 5.46737 16.1112 5.68254 15.8595L12.8393 7.48901Z"
      fill="currentColor"
    />
  </svg>
);
