import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import queryString from 'query-string';
import { useActions, useSelector } from '../hooks/store';
import { authActions } from '../store/auth';
import { localStorageService } from '../services/localStorageService';
import history from '../routes/history';
import { goToEvents } from '../routes/actions';

import Unauthorised from './unauthorised';
import Authorised from './authorised';
import ErrorBoundary from '../components/errorBoundary';

import './styles.scss';

const Index = () => {
  const { onCheckAuth } = useActions(authActions);
  const { user } = useSelector((state) => state.profile);
  const { theme } = useSelector((state) => state.theme);
  const { services, token } = useSelector((state) => state.auth);
  const { location } = history;
  const actions = useActions(authActions);

  useEffect(() => {
    if (isEmpty(user)) {
      const queryObj = queryString.parse(location.search);
      if (location.search && queryObj?.code && location.pathname !== '/settings/security') {
        if (location.hash) {
          queryObj.code = `${queryObj.code}${location.hash}`;
        }
        if (location.pathname === '/') {
          if (!isEmpty(queryObj) && queryObj.code) {
            actions.onAuthInSocial(queryObj);
          }
        }
      } else {
        onCheckAuth();
      }
    }
    if (location.pathname === '/' && !location.search) {
      goToEvents();
    }
  }, [user, location.pathname, location.search, location.hash, onCheckAuth, actions]);

  //Загружаем сервисы
  useEffect(() => {
    if (token) {
      if (isEmpty(services)) actions.getServices();
    }
  }, [token, actions, services]);

  useEffect(() => {
    document.body.className = theme === 1 ? 'dark-theme' : 'white-theme';
  }, [theme]);

  const auth = !!localStorageService.getAuthData();

  return (
    <ErrorBoundary>{auth ? <Authorised /> : <Unauthorised location={location} />}</ErrorBoundary>
  );
};

export default Index;
