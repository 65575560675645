import React from 'react';
import uuid from 'react-uuid';
import { useSelector } from '../../../../hooks/store';

import './styles.scss';

const SideSwitch = ({ onChange, side }) => {
  const { event } = useSelector((state) => state.events);
  const sides = event?.sides || [];

  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className="switch-type">
      <div className="switch-type_radio_btn">
        <input
          onChange={handleChange}
          checked={!side}
          id="radio-all"
          type="checkbox"
          name="radio"
          value={undefined}
        />
        <label htmlFor="radio-all">
          <span>Все</span>
        </label>
      </div>
      {sides.map((item, index) => {
        return (
          <div className="switch-type_radio_btn" key={uuid()}>
            <input
              onChange={handleChange}
              checked={side === item.id}
              id={`radio-${index}`}
              type="checkbox"
              name="radio"
              value={item.id}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`radio-${index}`}>
              <span>{item.title}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default SideSwitch;
