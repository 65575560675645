import React from 'react';

import { useStore } from '../../../../hooks/store';
import { sidebarActions } from '../../../../store/sideber';

import Icon from '../../../icon';
import Button from '../../../button';

import './styles.scss';

const SidebarButton = () => {
  const [isOpenSidebar, actions] = useStore((state) => state.sidebar.isOpenSidebar, sidebarActions);
  const handleToggleSidebar = () => actions.onToggle(!isOpenSidebar);
  return (
    <Button onClick={handleToggleSidebar} secondVariant="icon" variant="header-menu">
      <Icon type="menu" />
    </Button>
  );
};

export default SidebarButton;
