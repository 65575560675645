import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4V6.12372V16.858L7.00039 18.2867L9.00039 19.001V16.8773V6.143L5 4.71429L3 4ZM5 6.83801V15.4485L7.00039 16.163V7.55244L5 6.83801ZM15.0007 16.8583L11.0008 18.2868L9.00081 19.0011V16.8774V6.14313L13.0012 4.71442L14.9998 4.00064V4L15.0007 4.00032L15.0012 4.00013V4.0005L16.9998 4.71429L21.0002 6.143V16.8773V19.001L19.0002 18.2867L15.0007 16.8583ZM16.9998 6.83801V15.4485L19.0002 16.163V7.55244L16.9998 6.83801ZM11.0008 7.55257V16.1631L13.0012 15.4487V6.83814L11.0008 7.55257Z"
      fill="#C0B4AF"
    />
  </svg>
);
