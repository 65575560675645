import Moment from 'moment';

import { catchWrapper } from '../../../helpers/utility';
import { postSaveProfile } from '../../../api';
import { validationPassport } from '../../settings/validation';
import { onChangeError } from '../index';

export default () => async (dispatch, getState) => {
  const { user } = getState().profile;
  const error = validationPassport({ user });

  if (error) {
    dispatch(onChangeError(error));
  } else {
    dispatch({ type: 'PROFILE/SAVE/REQUESTED' });

    const bd = user.birth_date ? Moment(user.birth_date).format() : null;

    const formData = new FormData();
    formData.append('action', 'savePassport');

    formData.append('name', user.name || null);
    formData.append('last_name', user.last_name || null);
    formData.append('patronymic', user.patronymic || null);

    formData.append('country_id', user?.country_id || null);
    formData.append('region_id', user?.region_id || null);
    formData.append('city_id', user?.city_id || null);

    formData.append('birth_date', bd);

    formData.append('gender', user.gender || null);

    formData.append('address', user.address || null);

    formData.append('vehicle_brand', user.vehicle_brand || null);
    formData.append('vehicle_number', user.vehicle_number || null);

    formData.append('sk_number', user.sk_number || null);

    // formData.append('proxy_name', user.proxy_name || null);
    // formData.append('proxy_last_name', user.proxy_last_name || null);
    // formData.append('proxy_patronymic', user.proxy_patronymic || null);
    // formData.append('proxy_phone', user.proxy_phone || null);
    //
    // formData.append('medical_policy_number', user.medical_policy_number || null);
    // formData.append('medical_diseases', user.medical_diseases || null);
    // formData.append('medical_allergy', user.medical_allergy || null);
    // formData.append('medical_vaccinations', user.medical_vaccinations || null);
    const result = await catchWrapper(postSaveProfile(formData));

    if (result.result) {
      dispatch({ type: 'PROFILE/SAVE/SUCCEEDED' });
    } else {
      dispatch({ type: 'PROFILE/SAVE/FAILED' });
    }
  }
};
