import { catchWrapper } from '../../../helpers/utility';
import { getBlogsList } from '../../../api/v2';

export default ({ page, query }) => async (dispatch) => {
  dispatch({ type: 'BLOGS/LOAD_ALL_BLOGS/REQUESTED' });

  const payloadRequest = {
    page,
    objects_per_page: 20,
    filter: {
      query,
    },
  };

  const result = await catchWrapper(getBlogsList(payloadRequest));

  if (result && result.result) {
    dispatch({ type: 'BLOGS/LOAD_ALL_BLOGS/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'BLOGS/LOAD_ALL_BLOGS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
