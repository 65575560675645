import React from 'react';
import { Link } from 'react-router-dom';

import { Pages } from '../../../../routes/pages';

import './styles.scss';

const GroundCard = ({ ground }) => {
  return (
    <div className="card ground-card">
      <Link to={{ pathname: Pages.grounds.main.replace(':id', ground.id), state: ground.id }}>
        <img src={ground?.image?.image?.preview} alt="" />
        <div className="ground-card__blur">
          <div className="ground-card__blur-name">{ground?.profile.title}</div>
          <div className="ground-card__blur-region">
            {ground?.location?.country?.title}/{ground?.location?.region?.title}/
            {ground?.location?.city?.title}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GroundCard;
