import React from 'react';

export default () => (
  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8058 16.9391C11.6492 17.4736 10.3585 17.7722 8.99713 17.7722C4.02815 17.7722 0 13.7937 0 8.88609C0 3.97844 4.02815 0 8.99713 0C13.9661 0 17.9943 3.97844 17.9943 8.88609C17.9943 11.2837 17.0328 13.4595 15.47 15.058L17.7359 18.5042C18.2279 19.2526 18.0127 20.2532 17.2549 20.7392C16.4972 21.2251 15.4841 21.0124 14.9921 20.2641L12.8058 16.9391ZM14.7226 8.88609C14.7226 12.0091 12.1592 14.5409 8.99713 14.5409C5.83505 14.5409 3.27168 12.0091 3.27168 8.88609C3.27168 5.76304 5.83505 3.2313 8.99713 3.2313C12.1592 3.2313 14.7226 5.76304 14.7226 8.88609Z"
      fill="#C0B4AF"
    />
  </svg>
);
