import React from 'react';
import { useActions, useSelector } from '../../../../hooks/store';
import { themeActions } from '../../../../store/theme';

import Icon from '../../../../components/icon';

import './styles.scss';

const ChangeEventView = () => {
  const { loading, eventView } = useSelector((state) => state.theme);
  const actions = useActions(themeActions);
  const handleChangeEventView = (view) => {
    if (loading === 0) {
      actions.changeEventView({ view });
    }
  };

  console.log(eventView);

  return (
    <div className="change-view">
      <Icon
        type="tileView"
        onClick={() => handleChangeEventView(1)}
        className={eventView === 1 ? 'active' : 'not-active'}
      />
      <Icon
        type="listView"
        onClick={() => handleChangeEventView(2)}
        className={eventView === 2 ? 'active' : 'not-active'}
      />
    </div>
  );
};

export default ChangeEventView;
