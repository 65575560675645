import FullLogo from './loginLogo';
import GamersLogin from './gamers';
import GamesLogin from './games';
import OrgLogin from './organisators';
import TeamsLogin from './teams';

export default {
  fullLogo: FullLogo,
  gamersLogin: GamersLogin,
  gamesLogin: GamesLogin,
  orgLogin: OrgLogin,
  teamsLogin: TeamsLogin,
};
