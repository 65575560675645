import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Gallery from 'react-grid-gallery';
import { useSelector } from 'react-redux';
import AlbumCard from './albumCard';
import EntitiesActions from '../../entitiesActions';
import { useActions } from '../../../../hooks/store';
import { socialActions } from '../../../../store/social';

const albumImagesRemapped = ({
  userPhotos,
  handleLike,
  handleBookmark,
  getFullImageInfo,
  imageInfo,
  videoInfo,
  getComments,
  parentType,
  comments,
  clearComments,
  sendComment,
  text,
  handleChangeText,
  clearFullImageInfo,
}) =>
  userPhotos?.map((img) => ({
    src: img?.image?.image,
    thumbnail: img?.image?.preview,
    isSelected: false,
    id: img.id,
    albumId: img.album_id,
    // Нижняя часть кастомная!!!
    caption: (
      <EntitiesActions
        handleLike={handleLike}
        handleBookmark={handleBookmark}
        id={img.id}
        getFullInfo={getFullImageInfo}
        imageInfo={imageInfo}
        videoInfo={videoInfo}
        getComments={getComments}
        parentType={parentType}
        comments={comments}
        clearComments={clearComments}
        contentType="image"
        sendComment={sendComment}
        text={text}
        handleChangeText={handleChangeText}
        clearFullInfo={clearFullImageInfo}
      />
    ),
  }));
const AlbumCardWrap = ({
  albumsList,
  fetchPaginated,
  hasMore,
  isBlogHolder,
  openModal,
  onDeleteAlbum,
  parentId,
  parentType,
  userPage,
  userPhotos,
  getFullInfo,
}) => {
  const [images, setImages] = useState([]);
  const [text, setText] = useState('');
  const {
    getComments,
    writeComments,
    likeRequest,
    bookmark,
    getFullImageInfo,
    clearComments,
    clearFullImageInfo,
  } = useActions(socialActions);
  const { videoInfo, imageInfo, comments } = useSelector((s) => s?.social);
  const handleLike = async (picId) => {
    await likeRequest({ id: picId, type: 'image' });
    // await getFullVideoInfo({ id: picId });
  };
  const handleChangeText = (e) => {
    const { value } = e.target;
    setText(value);
  };
  const sendComment = async () => {
    await writeComments({ text, id: imageInfo?.id, contentType: 'image' });
    await getFullImageInfo({ id: imageInfo?.id });
    // await clearComments();
    await setText('');
    await getComments({ id: imageInfo?.id, contentType: 'image' });
  };
  const handleBookmark = async (picId) => {
    await bookmark({ id: picId, type: 'image' });
    // await getFullVideoInfo({ id: picId });
  };

  useEffect(() => {
    if (userPhotos) {
      setImages(
        albumImagesRemapped({
          userPhotos,
          handleLike,
          handleBookmark,
          getFullInfo,
          getComments,
          getFullImageInfo,
          imageInfo,
          videoInfo,
          parentType,
          comments,
          clearComments,
          sendComment,
          text,
          handleChangeText,
          clearFullImageInfo,
        }),
      );
    }
  }, [userPhotos, imageInfo]);

  const getAlbumInfo = (img) => {
    if (userPage) {
      if (img?.length) {
        return (
          <Gallery
            images={img && img}
            // currentImageWillChange={currentImageWillChange}
            showImageCount={false}
            enableImageSelection={false}
            // onSelectImage={handleSelectImage}
            // customControls={[
            //   <LightBoxCounter
            //     key="1"
            //     current={currentImage}
            //     total={paginationAlbumImages?.total_items}
            //   />,
            // ]}
          />
        );
      }
    } else {
      return (
        <InfiniteScroll
          dataLength={albumsList?.length || 0}
          next={fetchPaginated}
          hasMore={hasMore}
          loader={<></>}
          className=""
        >
          {albumsList?.map((album) => (
            <AlbumCard
              profile={album?.profile}
              key={album?.id}
              album={album}
              parentId={parentId}
              parentType={parentType}
              openAddBlogAlbumModal={openModal}
              blogHolder={isBlogHolder}
              deleteAlbum={onDeleteAlbum}
            />
          ))}
        </InfiniteScroll>
      );
    }
  };
  return getAlbumInfo(images) || null;
};

export default AlbumCardWrap;
