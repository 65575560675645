import Count from './count';
import CountLarge from './countLarge';
import DoubleArrow from './doubleArrow';
import Done from './done';
import Finished from './finished';
import List from './list';
import Tile from './tile';
import Calendar from './calendar';
import Location from './location';
import Discovery from './dicsovery';

export default {
  count: Count,
  countLarge: CountLarge,
  doubleArrow: DoubleArrow,
  done: Done,
  finished: Finished,
  listView: List,
  tileView: Tile,
  locationNew: Location,
  calendarNew: Calendar,
  discoveryNew: Discovery,
};
