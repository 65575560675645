import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isEmpty } from 'lodash';

import { useActions, useSelector } from '../../../../hooks/store';
import { eventsActions } from '../../../../store/events';
import { useResponsive } from '../../../../hooks/useResponsive';
import { sponsorAction } from '../../../../store/sponsor';
import { globalCancelFetch } from '../../../../api/v2';

import EventCard from '../../../components/eventCard';
import SkeletonEvents from '../../../events/components/skeleton';
import ToggleFilterButton from '../../../components/toggleFilterButton';

import './styles.scss';

const EventsOrganizers = ({ isShowEventButton, handleGoToCreateEvent }) => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { pagination, loading, eventsList } = useSelector((state) => state.events);
  const { sponsor } = useSelector((state) => state.sponsor);
  const { userId } = useSelector((state) => state.auth);
  const { getSponsorEvents, clearEvents } = useActions(eventsActions);
  const { getSponsor, onClearSponsor } = useActions(sponsorAction);
  const { isTablet } = useResponsive();

  useEffect(() => {
    if (isEmpty(sponsor)) {
      getSponsor(id);
    }
  }, []);

  useEffect(() => {
    if (sponsor?.id) {
      getSponsorEvents(id);
    }
  }, [sponsor?.id]);

  useEffect(() => {
    return () => {
      clearEvents();
      onClearSponsor();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const fetchPaginated = () => {
    setPage(page + 1);
    getSponsorEvents(sponsor?.id, page + 1);
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));
  return (
    <div className="org-events-page ">
      <>
        {isTablet && (
          <ToggleFilterButton isAddButton={isShowEventButton} onClickAdd={handleGoToCreateEvent} />
        )}
        {loading && !hasMore ? (
          <SkeletonEvents />
        ) : (
          !isEmpty([1]) && (
            <InfiniteScroll
              dataLength={3}
              next={fetchPaginated}
              hasMore={hasMore}
              loader={<></>}
              className="events-section--grid"
            >
              {eventsList &&
                eventsList.map((event) => {
                  return <EventCard key={event.id} event={event} userId={userId} />;
                })}
            </InfiniteScroll>
          )
        )}
      </>
    </div>
  );
};

export default EventsOrganizers;
