import React, { memo, useState } from 'react';

import { classNames as cx } from '../../../../helpers/utility';

import Avatar from '../../../components/avatar';

import './styles.scss';

import messageRead from './image/message-read.svg';
import MessageMenu from '../menu';
import Icon from '../../../../components/icon';

function MessageBlock({
  className,
  own,
  text,
  time,
  imageUrl,
  isRead,
  hasAvatar,
  message,
  onDeleteMessage,
  onEditMode,
}) {
  const [menu, setMenu] = useState(false);
  const classNames = cx('msg-block', className, {
    'msg-block--own': own,
  });

  const handleOnMouseEnter = () => {
    setMenu(true);
  };

  const handleOnMouseLeave = () => {
    setMenu(false);
  };

  const deleteMessageAction = () => {
    onDeleteMessage(message);
  };

  const onEditModeAction = () => {
    onEditMode(message);
  };

  return (
    <div className={classNames}>
      <div className="msg-block__left">{hasAvatar ? <Avatar image={imageUrl} /> : null}</div>
      <div
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className="msg-block__right"
      >
        <div className="msg-block__text-wrap">
          <span className="msg-block__text">{text}</span>
        </div>
        <div className="msg-block__time-wrap">
          {own && isRead ? <Icon type="msgRead" /> : null}
          <span className="msg-block__time">{time}</span>
        </div>
        {menu && own ? (
          <MessageMenu onDeleteAction={deleteMessageAction} onEditModeAction={onEditModeAction} />
        ) : null}
      </div>
    </div>
  );
}

export default MessageBlock;
