import React, { useEffect, useState } from 'react';
import moment from 'moment';
import subYears from 'date-fns/subYears';

import { useActions, useSelector } from '../../../../hooks/store';
import { profileActions } from '../../../../store/profile';

import dummy from '../../../../assets/image/user.svg';

import CustomInput from '../../../../components/input';
import ContactInfo from '../../components/contactInfo';
import TransportInfo from '../../components/transportInfo';
/*import ConfidantInfo from '../../components/confidant';
import MedicalInfo from '../../components/medicalInfo';*/
import ImagePicker from '../../../../components/imagePicker';
import CustomDatePicker from '../../../../components/datePicker';
import SwitchSex from '../../components/switchSex';
import Button from '../../../../components/button';

import { classNames as cx } from '../../../../helpers/utility';
import { openFilePdf } from '../../../../api/downloadFile';

import './styles.scss';
import SkInfo from "../../components/skInfo";

const initGeo = {
  country: {},
  region: {},
  city: {},
};

const SettingPassport = () => {
  const { onUserClone, onChangeField, saveAvatar, onChangeError } = useActions(profileActions);
  const { user, error } = useSelector((state) => state.profile);

  const [geoLocal, setGeoLocal] = useState(initGeo);

  useEffect(() => {
    onUserClone();
  }, []);

  useEffect(() => {
    setGeoLocal({ ...user.location });
  }, [user.location]);

  const handleChangeCountrySelect = (value) => {
    const location = { country: value, region: {}, city: {} };

    onChangeField('country_id', value.id);
    onChangeField('location', location);
    onChangeError({
      ...error,
      country: null,
    });
  };
  const handleChangeRegionSelect = (value) => {
    const location = { ...user.location, region: value, city: {} };

    onChangeField('region_id', value.id);
    onChangeField('location', location);
    onChangeError({
      ...error,
      region: null,
    });
  };
  const handleChangeCitySelect = (value) => {
    const location = { ...user.location, city: value };

    onChangeField('city_id', value.id);
    onChangeField('location', location);
    onChangeError({
      ...error,
      city: null,
    });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    onChangeField(name, value);
    onChangeError({
      ...error,
      [name]: null,
    });
  };

  const urlPreview =
    user.image_passport && user.image_passport.preview ? user.image_passport.preview : dummy;

  const classNames = cx('settings-passport__photo-wrap', {
    'settings-passport__photo-wrap_dummy': !(user.image_passport && user.image_passport.preview),
  });

  const handleChangeGender = (value) => onChangeField('gender', value);
  //const handleChangePhone = (value) => onChangeField('proxy_phone', value);
  const handleChangeDate = (date) => {
    const t = date ? moment(date).format('YYYY-MM-DD') : null;
    onChangeField('birth_date', t);
    onChangeError({
      ...error,
      birth_date: null,
    });
  };
  const selectedDate = user.birth_date ? new Date(user.birth_date) : null;

  const getPassportPDF = () => {
    openFilePdf('user_passport.pdf');
  };

  return (
    <div className="settings-passport settings-passport__wrap">
      <div className="settings-passport__left">
        <div className="settings-passport__card">
          <div className={classNames}>
            <img src={urlPreview} alt="pic" />
            <div className="card__photo-button-wrap">
              <ImagePicker
                aspectRatio={1}
                minHeight={360}
                minWidth={360}
                onSubmit={saveAvatar}
                className="add-photo"
                pass
              />
            </div>
          </div>
          <SwitchSex gender={user.gender} onChange={handleChangeGender} />
          <CustomInput
            required
            value={user.name}
            name="name"
            labelPosition="top"
            label="Имя"
            placeholder="Введите Имя"
            error={error?.name}
            onChange={handleChangeInput}
          />
          <CustomInput
            required
            value={user.last_name}
            name="last_name"
            labelPosition="top"
            label="Фамилия"
            placeholder="Введите Фамилию"
            error={error?.last_name}
            onChange={handleChangeInput}
          />
          <CustomInput
            required
            value={user.patronymic}
            name="patronymic"
            labelPosition="top"
            label="Отчество"
            placeholder="Введите Отчество"
            error={error?.patronymic}
            onChange={handleChangeInput}
          />
          <CustomDatePicker
            required
            maxDate={subYears(new Date(), 14)}
            selected={selectedDate}
            name="birth_date"
            placeholderText="Укажите дату"
            onChange={handleChangeDate}
            customInput={
              <CustomInput
                error={error?.birth_date}
                required
                label="Дата рождения"
                labelPosition="top"
                name="birth_date"
              />
            }
          />
        </div>
        <div className="settings-passport__description-text photo-text">
          <span className="settings-passport__description-text__text">
            <p>
              Пожалуйста, загрузите свою фотографию, на которой хорошо видно лицо.
              <br />
              Фото должно быть сделано в анфас.
            </p>
            <p>
              Сведения не будут показываться в вашем общедоступном профиле, их будете видеть только
              вы и ряд организаторов.
            </p>
            <p>Данные необходимы для регистраци на некоторые крупные игры.</p>
          </span>
        </div>
      </div>
      <div className="settings-passport__right">
        <ContactInfo
          onChange={handleChangeInput}
          onChangeGender={handleChangeGender}
          user={user}
          error={error}
          geoLocal={geoLocal}
          handleChangeCitySelect={handleChangeCitySelect}
          handleChangeCountrySelect={handleChangeCountrySelect}
          handleChangeRegionSelect={handleChangeRegionSelect}
        />
        <div className="settings-passport__description-text">
          <span className="settings-passport__description-text__text">
            <p>
              * От выбора страны проживания зависит применимое законодательство и место хранения
              персональных данных
            </p>
            <p>
              ** От выбора региона и города проживания зависит отображения игр и их стоимость (в
              зависимости от настроек организатора)
            </p>
          </span>
        </div>
        <TransportInfo onChange={handleChangeInput} user={user} />
        <div className="settings-passport__description-text">
          <span className="settings-passport__description-text__text">
            <p>
              Данные о транспортном средстве могут быть необходимы некоторым организаторам для
              обеспечения въезда на мероприятия.
            </p>
          </span>
        </div>
        {user?.is_sk && <SkInfo onChange={handleChangeInput} user={user} />}
        <center>
          <Button className="button button--main" onClick={getPassportPDF}>
            Паспорт игрока в PDF
          </Button>
        </center>
        {/*<ConfidantInfo onChange={handleChangeInput} onChangePhone={handleChangePhone} user={user} />
        <MedicalInfo onChange={handleChangeInput} user={user} />*/}
      </div>
    </div>
  );
};

export default SettingPassport;
