import React from 'react';
import Icon, { icons } from '../../components/icon';

import './styles.scss';

const UiKit = () => {
  const allIcons = Object.keys(icons);

  return (
    <div className="ui-kit-container">
      {allIcons.map((a, i) => {
        const k = i + 2;
        return (
          <div key={k.toString()} className="ui-kit-item">
            <span>{a}</span>
            <Icon type={a} />
          </div>
        );
      })}
    </div>
  );
};

export default UiKit;
