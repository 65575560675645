import deleteTextEditorImages from './impl/deleteTextEditorImages';
import getChangedDataEditor from './impl/getChangedDataEditor';
import getSnapshotDataEditor from './impl/getSnapshotDataEditor';
import onUnmountEditor from './impl/onUnmountEditor';
import getMatchesDataEditor from './impl/getMatchesDataEditor';
import deleteTextEditorImagesArticle from './impl/deleteTextEditorImagesArticle';

export const editorReducer = (state = null, action) => {
  switch (action.type) {
    case 'EDITOR/DELETE_IMAGES/REQUESTED': {
      return {
        ...state,
        editor: { loading: state.editor.loading - 1 },
      };
    }

    case 'EDITOR/DELETE_IMAGES/SUCCESSFUL': {
      return {
        ...state,
        editor: { loading: state.editor.loading + 1 },
      };
    }

    case 'EDITOR/DELETE_IMAGES/FAILED': {
      return {
        ...state,
        editor: { loading: state.editor.loading - 1 },
      };
    }

    case 'EDITOR/GET_SNAPSHOT': {
      return {
        ...state,
        editorSnapshotData: action.payload,
      };
    }

    case 'EDITOR/GET_MATCHES': {
      return {
        ...state,
        matches: [...state.matches, ...action.payload],
      };
    }

    case 'EDITOR/GET_CHANGED_DATA': {
      return {
        ...state,
        editorChangesData: [...action.payload],
      };
    }

    case 'EDITOR/UNMOUNT':
      return {
        editorSnapshotData: [],
        editorChangesData: [],
        matches: [],
        loading: 0,
      };
    default:
      return state;
  }
};

export const editorActions = {
  deleteTextEditorImages,
  getChangedDataEditor,
  getSnapshotDataEditor,
  onUnmountEditor,
  getMatchesDataEditor,
  deleteTextEditorImagesArticle,
};
