import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../hooks/store';
import { sponsorAction } from '../../../../../../store/sponsor';
import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { globalCancelFetch } from '../../../../../../api/v2';

const Demands = ({ sponsorId }) => {
  const { isMobile } = useResponsive();
  const { onGetSponsorSettingsDemands } = useActions(sponsorAction);
  const { sponsorSettings } = useSelector((state) => state.sponsor);
  const demandText = sponsorSettings?.demands?.text;

  useEffect(() => {
    if (sponsorId) {
      onGetSponsorSettingsDemands('3', sponsorId, 'sponsor', '1');
    }

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [sponsorId]);

  return (
    <div>
      {demandText && (
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(demandText)} />
      )}
    </div>
  );
};

export default Demands;
