import React from 'react';

export default () => (
  <svg width="47" height="36" viewBox="0 0 47 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 36C9.4413 36 18.6873 26.1152 23.5876 16.92C28.3839 7.92 37.9097 0.36 46.7636 0H47V36H0Z"
      fill="#FAFAFA"
    />
  </svg>
);
