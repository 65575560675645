import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/button';
import { useActions, useSelector } from '../../../../hooks/store';
import CustomInput from '../../../../components/input';
import { blogActions } from '../../../../store/blog';
import UserCardInfo from '../../components/userCardInfo';
import SponsorBannerBlock from '../../../sponsors/components/sponsorBannerBlock';
import JoditEditor from '../../../../components/textEditor2';
import './styles.scss';

const initImages = {
  flag: null,
};

const NewArticlePage = () => {
  const { id } = useParams();
  const editor = useRef(null);
  const { blog, loading } = useSelector((s) => s?.blog);
  const { token } = useSelector((s) => s?.auth);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [images, setImages] = useState(initImages);
  const { getBlog, getBlogArticleTypes, onCreateBlogArticle } = useActions(blogActions);
  const newHash =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  // Если есть ID в урле, то загружаем блог
  useEffect(() => {
    if (token) {
      if (id) {
        getBlog(id);
        getBlogArticleTypes();
      }
    }
  }, [id, token]);

  const handleChangeInput = (e) => {
    const { value } = e.target;
    setTitle(value);
  };
  const handleChangeText = (value) => {
    setText(value);
  };

  const onCreateArticle = async () => {
    const payload = {
      profile: { title, text },
      type: 1,
      hash: newHash,
      blog_id: Number(id),
    };
    await onCreateBlogArticle(payload, images?.flag);
  };
  const handleChangeImages = (name, image) => {
    setImages({
      ...images,
      [name]: image,
    });
  };

  return (
    <div className="blog-page__new-article">
      <div className="blog-page__new-article__info">
        <div className="blog-page__new-article__info__card">
          <UserCardInfo blog={blog} ownerId={blog?.id} />
        </div>
        <div>
          <Button disabled={loading} onClick={onCreateArticle} tabIndex="0" variant="main">
            Создать статью
          </Button>
        </div>
      </div>
      <SponsorBannerBlock handleChangeImages={handleChangeImages} imagesState={images} isEditable />
      <div className="blog-page__new-article__title">
        <CustomInput
          labelPosition="top"
          onChange={handleChangeInput}
          value={title}
          name="title"
          placeholder="Укажите заголовок статьи"
        />
      </div>
      <div className="blog-page__new-article__editor">
        <JoditEditor
          value={text}
          ref={editor}
          onChange={handleChangeText}
          onBlur={handleChangeText}
          pageId={3}
          parentId={1}
          type="sponsor"
          languageId={1}
          name="text"
        />
      </div>
    </div>
  );
};

export default NewArticlePage;
