import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useActions, useSelector } from '../../../../hooks/store';
import { useResponsive } from '../../../../hooks/useResponsive';
import useDebounce from '../../../../hooks/useDebounce';
import HeaderTitle from '../../../../components/headerConstructor/components/title';
import Icon from '../../../../components/icon';
import Button from '../../../../components/button';

import modalService from '../../../../services/modalService';
import { blogActions } from '../../../../store/blog';

import ArticlesList from '../../components/articlesList';

import './styles.scss';

const ArticlesAllPage = ({ search }) => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { getArticlesList, clearBlog } = useActions(blogActions);
  const { articlesList, pagination, loading } = useSelector((state) => state.blog);
  const { isMobile, is2KHeight } = useResponsive();

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    return () => {
      clearBlog();
    };
  }, []);

  const asyncRequests = async () => {
    if (is2KHeight) {
      getArticlesList();
      await getArticlesList({ blogId: id, page: 1, query: debouncedSearchTerm || null });
      await getArticlesList({ blogId: id, page: 2, query: debouncedSearchTerm || null });
      await getArticlesList({ blogId: id, page: 3, query: debouncedSearchTerm || null });
      setPage(3);
    } else {
      clearBlog();
      setPage(1);
      getArticlesList({ blogId: id, page: 1, query: debouncedSearchTerm || null });
    }
  };

  useEffect(() => {
    asyncRequests();
  }, [debouncedSearchTerm]);

  const fetchPaginated = () => {
    setPage(page + 1);
    getArticlesList({ blogId: id, page: page + 1, query: debouncedSearchTerm || null });
  };
  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  const handleOpenAddCamouflageModal = () => {
    modalService.openAddCamouflage({});
  };

  return (
    <div className="articles">
      <ArticlesList
        isMobile={isMobile}
        openAddCamouflageModal={handleOpenAddCamouflageModal}
        articles={articlesList}
        fetchPaginated={fetchPaginated}
        hasMore={hasMore}
        loading={loading}
      />
    </div>
  );
};

export default ArticlesAllPage;
