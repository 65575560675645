import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      y1="-0.75"
      x2="12.5391"
      y2="-0.75"
      transform="matrix(-0.641971 0.766729 -0.836709 -0.547648 19 7.38586)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M16.6377 7L8.87922 16.3095L5 13.3697"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
