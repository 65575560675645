import React, { useState } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import { useResponsive } from '../../hooks/useResponsive';
import history from '../../routes/history';
import OrgPageMobile from './mobile';
import OrgPageDesktop from './desktop';
import { Pages } from '../../routes/pages';
import { useActions, useSelector } from '../../hooks/store';
import { sponsorAction } from '../../store/sponsor';
import { getIdFromUrl } from '../../helpers/utility';
import { editorActions } from '../../store/editor';
import Trans from '../../components/trans';

const navigationOrgMap = (sponsorId, isShowExtendedLinks) => [
  {
    id: '1',
    path: Pages.organizers.main.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.main'),
  },
  {
    id: '2',
    path: Pages.organizers.personnel.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.team'),
  },
  {
    id: '3',
    path: Pages.organizers.request.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.requests'),
    isVisible: isShowExtendedLinks,
    hiddenRequest: true,
  },
  {
    id: '4',
    path: Pages.organizers.about.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.about'),
    hash: '#history',
  },
  /*    {
      id: '5',
      path: Pages.organizers.agreements.replace(':id', sponsorId),
      state: sponsorId,
      label: 'Соглашения',
      hash: '#entry',
    },*/
  {
    id: '6',
    path: Pages.organizers.participants.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.members'),
  },
  {
    id: '7',
    path: Pages.organizers.events.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.games'),
  },
];
const navigationOrgMobileMap = (sponsorId, isShowExtendedLinks) => [
  {
    id: '1',
    path: Pages.organizers.main.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.main'),
  },
  {
    id: '2',
    path: Pages.organizers.personnel.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.team'),
  },
  {
    id: '3',
    path: Pages.organizers.request.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.requests'),
    isVisible: isShowExtendedLinks,
    hiddenRequest: true,
  },
  {
    id: '4',
    path: Pages.organizers.about.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.about'),
    hash: '#history',
    children: [
      {
        id: '5',
        label: Trans('organizers.about.history'),
        state: sponsorId,
        hash: '#history',
        path: Pages.organizers.about.replace(':id', sponsorId),
      },
      {
        id: '6',
        label: Trans('organizers.about.rules'),
        path: Pages.organizers.about.replace(':id', sponsorId),
        state: sponsorId,
        hash: '#rules',
      },
      {
        id: '7',
        label: Trans('organizers.about.requirements'),
        path: Pages.organizers.about.replace(':id', sponsorId),
        state: sponsorId,
        hash: '#demands',
      },
    ],
  },
  /*    {
      id: '7',
      path: Pages.organizers.agreements.replace(':id', sponsorId),
      state: sponsorId,
      label: 'Соглашения',
      hash: '#entry',
      children: [
        {
          id: '8',
          label: 'Вступление в клуб',
          state: sponsorId,
          hash: '#entry',
          path: Pages.organizers.agreements.replace(':id', sponsorId),
        },
        {
          id: '9',
          label: 'Персональные данные',
          path: Pages.organizers.agreements.replace(':id', sponsorId),
          state: sponsorId,
          hash: '#data',
        },
        {
          id: '10',
          label: 'Ответственность на мероприятии',
          path: Pages.organizers.agreements.replace(':id', sponsorId),
          state: sponsorId,
          hash: '#responsibility',
        },
      ],
    },*/
  {
    id: '12',
    path: Pages.organizers.participants.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.members'),
  },
  {
    id: '13',
    path: Pages.organizers.events.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.games'),
  },
];

const navigationOrgSettingsMobileMap = (sponsorId) => [
  {
    id: '1',
    path: Pages.organizers.settings.main.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.main'),
  },
  {
    id: '6',
    path: Pages.organizers.settings.legal.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.legal'),
  },
  {
    id: '2',
    path: Pages.organizers.settings.personnel.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.team'),
  },
  {
    id: '3',
    path: Pages.organizers.settings.links.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.links'),
  },
  {
    id: '4',
    path: Pages.organizers.settings.about.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.about'),
    hash: '#history',
    children: [
      {
        id: '5',
        label: Trans('organizers.about.history'),
        state: sponsorId,
        hash: '#history',
        path: Pages.organizers.settings.about.replace(':id', sponsorId),
      },
      {
        id: '6',
        label: Trans('organizers.about.rules'),
        path: Pages.organizers.settings.about.replace(':id', sponsorId),
        state: sponsorId,
        hash: '#rules',
      },
      {
        id: '7',
        label: Trans('organizers.about.requirements'),
        path: Pages.organizers.settings.about.replace(':id', sponsorId),
        state: sponsorId,
        hash: '#demands',
      },
    ],
  },
  /*    {
      id: '8',
      path: Pages.organizers.settings.agreements.replace(':id', sponsorId),
      state: sponsorId,
      label: 'Соглашения',
      hash: '#entry',
      children: [
        {
          id: '9',
          label: 'Вступление в клуб',
          state: sponsorId,
          hash: '#entry',
          path: Pages.organizers.settings.agreements.replace(':id', sponsorId),
        },
        {
          id: '10',
          label: 'Персональные данные',
          path: Pages.organizers.settings.agreements.replace(':id', sponsorId),
          state: sponsorId,
          hash: '#data',
        },
        {
          id: '11',
          label: 'Ответственность на мероприятии',
          path: Pages.organizers.settings.agreements.replace(':id', sponsorId),
          state: sponsorId,
          hash: '#responsibility',
        },
      ],
    },*/
  /*    {
      id: '11',
      path: Pages.organizers.participants.replace(':id', location.state),
      state: location.state,
      label: 'Участники',
    },
    {
      id: '12',
      path: Pages.organizers.events.replace(':id', location.state),
      state: location.state,
      label: 'Игры',
    },*/
];
const navigationOrgSettingMap = (sponsorId) => [
  {
    id: '1',
    path: Pages.organizers.settings.main.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.main'),
  },
  {
    id: '6',
    path: Pages.organizers.settings.legal.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.legal'),
  },
  {
    id: '2',
    path: Pages.organizers.settings.personnel.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.team'),
  },
  {
    id: '3',
    path: Pages.organizers.settings.links.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.links'),
  },
  {
    id: '4',
    path: Pages.organizers.settings.about.replace(':id', sponsorId),
    state: sponsorId,
    label: Trans('organizers.navigation.about'),
    hash: '#history',
  },
  /*    {
      id: '5',
      path: Pages.organizers.settings.agreements.replace(':id', sponsorId),
      state: sponsorId,
      label: 'Соглашения',
      hash: '#entry',
    },*/
];

const navigationCreateMap = () => [
  {
    id: '1',
    path: Pages.organizers.settings.create,
    label: Trans('organizers.navigation.main'),
  },
];

const getMaps = (
  isOrgPages,
  isSettingOrgPages,
  isCreatePage,
  location,
  isMobile,
  isShowExtendedLinks,
) => {
  if (isCreatePage) return navigationCreateMap();
  if (isSettingOrgPages && isEmpty(isSettingOrgPages.params))
    return isMobile
      ? navigationOrgSettingsMobileMap(getIdFromUrl(location.pathname))
      : navigationOrgSettingMap(getIdFromUrl(location.pathname));
  if (isOrgPages)
    return isMobile
      ? navigationOrgMobileMap(getIdFromUrl(location.pathname), isShowExtendedLinks)
      : navigationOrgMap(getIdFromUrl(location.pathname), isShowExtendedLinks);
};
const getActions = ({ location, sponsorId, actions, sponsorSettings, deleteTextEditorImages }) => {
  const {
    onCreate,
    onSaveSponsorSettingsHistory,
    onGetSponsorSettingsHistory,
    onSaveSponsorSettingsDemands,
    onGetSponsorSettingsDemands,
    onSaveSponsorSettingsRules,
    onGetSponsorSettingsRules,
    onGetSponsorSettingsEntry,
    onSaveSponsorSettingsEntry,
    onSaveSponsorSettingsPersonalData,
    onGetSponsorSettingsPersonalData,
    onSaveSponsorSettingsResponsibility,
    onGetSponsorSettingsResponsibility,
    onSaveSponsorProfile,
    onSaveSponsorMembers,
    onSaveSponsorLegal,
  } = actions;

  const path = location?.hash ? `${location?.pathname}${location?.hash}` : location?.pathname;

  switch (path) {
    case Pages.organizers.settings.create:
      return onCreate;
    case Pages.organizers.settings.main.replace(':id', sponsorId):
      return onSaveSponsorProfile;
    case Pages.organizers.settings.legal.replace(':id', sponsorId):
      return onSaveSponsorLegal;
    case Pages.organizers.settings.personnel.replace(':id', sponsorId):
      return () => onSaveSponsorMembers(sponsorId);
    case `${Pages.organizers.settings.about.replace(':id', sponsorId)}#history`:
      return async () => {
        const data = {
          images: [],
          page_id: 5,
          parent_id: Number(sponsorId),
          type: 'sponsor',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveSponsorSettingsHistory(
          '5',
          sponsorId,
          'sponsor',
          '1',
          sponsorSettings?.historyText?.text,
        );
        await onGetSponsorSettingsHistory('5', sponsorId, 'sponsor', '1');
      };
    case `${Pages.organizers.settings.about.replace(':id', sponsorId)}#rules`:
      return async () => {
        const data = {
          images: [],
          page_id: 2,
          parent_id: Number(sponsorId),
          type: 'sponsor',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveSponsorSettingsRules(
          '2',
          sponsorId,
          'sponsor',
          '1',
          sponsorSettings?.rules?.text,
        );
        await onGetSponsorSettingsRules('2', sponsorId, 'sponsor', '1');
      };
    case `${Pages.organizers.settings.about.replace(':id', sponsorId)}#demands`:
      return async () => {
        const data = {
          images: [],
          page_id: 3,
          parent_id: Number(sponsorId),
          type: 'sponsor',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveSponsorSettingsDemands(
          '3',
          sponsorId,
          'sponsor',
          '1',
          sponsorSettings?.demands?.text,
        );
        await onGetSponsorSettingsDemands('3', sponsorId, 'sponsor', '1');
      };
    case `${Pages.organizers.settings.agreements.replace(':id', sponsorId)}#entry`:
      return async () => {
        const data = {
          images: [],
          page_id: 7,
          parent_id: Number(sponsorId),
          type: 'sponsor',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveSponsorSettingsEntry(
          '7',
          sponsorId,
          'sponsor',
          '1',
          sponsorSettings?.entry?.text,
        );
        await onGetSponsorSettingsEntry('7', sponsorId, 'sponsor', '1');
      };
    case `${Pages.organizers.settings.agreements.replace(':id', sponsorId)}#data`:
      return async () => {
        const data = {
          images: [],
          page_id: 8,
          parent_id: Number(sponsorId),
          type: 'sponsor',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveSponsorSettingsPersonalData(
          '8',
          sponsorId,
          'sponsor',
          '1',
          sponsorSettings?.data?.text,
        );
        await onGetSponsorSettingsPersonalData('8', sponsorId, 'sponsor', '1');
      };
    case `${Pages.organizers.settings.agreements.replace(':id', sponsorId)}#responsibility`:
      return async () => {
        const data = {
          images: [],
          page_id: 9,
          parent_id: Number(sponsorId),
          type: 'sponsor',
          language_id: 1,
        };
        await deleteTextEditorImages({ data, type: 'save' });
        await onSaveSponsorSettingsResponsibility(
          '9',
          sponsorId,
          'sponsor',
          '1',
          sponsorSettings?.responsibility?.text,
        );
        await onGetSponsorSettingsResponsibility('9', sponsorId, 'sponsor', '1');
      };
    default:
      return null;
  }
};

const getIsDisabledMainPage = ({ sponsor, sponsorSnapshot, sponsorImageTemp }) => {
  const image = !!sponsorImageTemp?.image || !!sponsorImageTemp?.flag;
  let isDisabled;
  if (image && isEqual(sponsor, sponsorSnapshot)) isDisabled = false;
  if (!image && isEqual(sponsor, sponsorSnapshot)) isDisabled = true;
  if (image && !isEqual(sponsor, sponsorSnapshot)) isDisabled = false;
  return isDisabled;
};

const getIsDisabled = ({
  location,
  sponsorId,
  sponsorSettings,
  sponsorMembers,
  sponsorMembersSnapshot,
}) => {
  const { historyText, rules, demands, entry, data, responsibility } = sponsorSettings;
  const path = location?.hash ? `${location?.pathname}${location?.hash}` : location?.pathname;

  switch (path) {
    case Pages.organizers.settings.personnel.replace(':id', sponsorId):
      return isEqual(sponsorMembers, sponsorMembersSnapshot);
    case `${Pages.organizers.settings.about.replace(':id', sponsorId)}#history`:
      return isEqual(historyText?.text, historyText?.snapshot);
    case `${Pages.organizers.settings.about.replace(':id', sponsorId)}#rules`:
      return isEqual(rules?.text, rules?.snapshot);
    case `${Pages.organizers.settings.about.replace(':id', sponsorId)}#demands`:
      return isEqual(demands?.text, demands?.snapshot);
    case `${Pages.organizers.settings.agreements.replace(':id', sponsorId)}#entry`:
      return isEqual(entry?.text, entry?.snapshot);
    case `${Pages.organizers.settings.agreements.replace(':id', sponsorId)}#data`:
      return isEqual(data?.text, data?.snapshot);
    case `${Pages.organizers.settings.agreements.replace(':id', sponsorId)}#responsibility`:
      return isEqual(responsibility?.text, responsibility?.snapshot);
    default:
      return true;
  }
};

export const initGeo = {
  country: {},
  regionArr: [],
  selectedRegions: [],
  selectedCities: [],
  init: false,
};
const initSize = {
  min: 0,
  max: 1000,
};
const initType = 'any';
const rangeName = Trans('organizers.filter.members');

const OrganizersPage = () => {
  const [search, setSearch] = useState('');
  const { location } = history;
  const { isMobile } = useResponsive();

  const isSettingOrgPages = !!useRouteMatch('/organizers-settings/');
  const isOrgPages = useRouteMatch(Pages.organizers.base);
  const isSponsors = useRouteMatch(Pages.organizers.all);
  const isSettingPages = !!useRouteMatch(Pages.organizers.settings.base);
  const isSettingLinksPages = !!useRouteMatch(
    Pages.organizers.settings.links.replace(':id', getIdFromUrl(location.pathname)),
  );
  const isSettingMainPage = !!useRouteMatch(
    Pages.organizers.settings.main.replace(':id', getIdFromUrl(location.pathname)),
  );
  const isSettingsLegal = !!useRouteMatch(
    Pages.organizers.settings.legal.replace(':id', getIdFromUrl(location.pathname)),
  );
  const isSponsorEventPage = !!useRouteMatch(Pages.organizers.events);
  const isCreatePage = !!useRouteMatch(Pages.organizers.settings.create);

  const handleChangeSearch = (v) => setSearch(v);

  const actions = useActions(sponsorAction);
  const { deleteTextEditorImages } = useActions(editorActions);
  const {
    sponsor,
    sponsorSnapshot,
    sponsorSettings,
    sponsorImageTemp,
    sponsorMembers,
    sponsorMembersSnapshot,
  } = useSelector((state) => state.sponsor);

  const { userSponsors } = useSelector((state) => state.profile);

  const onSubmit = getActions({
    location,
    sponsorId: getIdFromUrl(location.pathname),
    actions,
    sponsorSettings,
    deleteTextEditorImages,
  });

  const mySponsorId = !isEmpty(userSponsors) && userSponsors[0]?.id;
  const mySponsorRole = !isEmpty(userSponsors) && userSponsors[0]?.user_role;
  const isShowEventButton = mySponsorId === getIdFromUrl(location.pathname) && mySponsorRole;

  const isDisabled =
    isSettingMainPage || isSettingsLegal
      ? getIsDisabledMainPage({ sponsor, sponsorSnapshot, sponsorImageTemp })
      : getIsDisabled({
          location,
          sponsorId: getIdFromUrl(location.pathname),
          sponsorSettings,
          sponsorMembers,
          sponsorMembersSnapshot,
        });

  // Фильтры
  const [size, setSize] = useState(initSize);
  const [type, setType] = useState(initType);
  const [isFilterOpened, toggleFilter] = useState(false);
  const [geoLocal, setGeoLocal] = useState(initGeo);
  const handleVisibleFilter = (value) => {
    toggleFilter(value);
  };
  const handleChangeCountry = (value) => {
    setGeoLocal({ ...initGeo, country: value, init: true });
  };
  const handleChangeGeo = (value) => {
    const selectedRegions = value
      ? value.filter((item) => item.isRegion).map((item) => item.region_id)
      : [];
    const selectedCities = value ? value.filter((item) => !item.isRegion) : [];
    setGeoLocal({
      ...geoLocal,
      regionArr: value
        ? value.filter((item) => item.isRegion || selectedRegions.indexOf(item.region_id) === -1)
        : value,
      selectedRegions,
      selectedCities,
      init: true,
    });
  };
  const handleClearFilter = () => {
    setType(initType);
    setSize(initSize);
    setGeoLocal({
      ...geoLocal,
      country: {},
      regionArr: [],
      selectedRegions: [],
      selectedCities: [],
      init: false,
    });
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setSize({ ...size, [name]: value });
  };
  const handleChangeSize = (value) => setSize(value);
  const handleChangeType = (value) => setType(value);

  const params = {
    rangeName,
    isFilterOpened,
    geoLocal,
    size,
    type,
    handleVisibleFilter,
    handleChangeType,
    handleChangeSize,
    handleChangeCountry,
    handleChangeGeo,
    handleClearFilter,
    handleChangeInput,
    onSubmit,
    isDisabled,
    navigationMap: getMaps(
      isOrgPages,
      isSettingOrgPages,
      isCreatePage,
      location,
      isMobile,
      isShowEventButton,
    ),
    isSettingPages,
    isOrgPages,
    isSponsors,
    isSettingLinksPages,
    search,
    onChangeSearch: handleChangeSearch,
    isCreatePage,
    isSponsorEventPage,
    isShowEventButton,
  };

  return isMobile ? <OrgPageMobile {...params} /> : <OrgPageDesktop {...params} />;
};

export default OrganizersPage;
