import onChangeField from './impl/onChangeField';
import sendRequestSponsor from './impl/sendRequestSponsor';

export const pagesReducer = (state = null, action) => {
  switch (action.type) {
    case 'PAGES/SEND_REQUEST_SPONSOR/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case 'PAGES/SEND_REQUEST_SPONSOR/FAILED':
    case 'PAGES/SEND_REQUEST_SPONSOR/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }

    case 'PAGES/CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    default:
      return state;
  }
};

export const pagesActions = {
  onChangeField,
  sendRequestSponsor,
};
