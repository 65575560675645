import { catchWrapper } from '../../../helpers/utility';
import { postUserAction } from '../../../api';

export default (id) => async (dispatch) => {
  dispatch({ type: 'USERS/SENT_FRIEND_REQUEST/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'addFriend');
  formData.append('user_id', id);

  const result = await catchWrapper(postUserAction('friends', formData));

  if (result && result.result) {
    dispatch({ type: 'USERS/SENT_FRIEND_REQUEST/SUCCEEDED', payload: id });
  } else {
    dispatch({
      type: 'USERS/SENT_FRIEND_REQUEST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
