import React from 'react';

import './styles.scss';
import { connect } from 'react-redux';
import Loader from '../../../../components/loader';

function MessagesLayout({ children, user }) {
  return <div className="msg-layout">{user?.uid ? children : <Loader />}</div>;
}

export default connect((state) => ({
  user: state.msg.user,
}))(MessagesLayout);
