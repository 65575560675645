import { catchWrapper } from '../../../../helpers/utility';
import { getSponsorList } from '../../../../api/v2';

const loadOptions = async (search, prevOptions, { page }) => {
  const payloadRequest = {
    page,
    objects_per_page: 20,
    filter: {
      search,
    },
  };

  const result = await catchWrapper(getSponsorList(payloadRequest));

  const { pagination, sponsors } = result && result;
  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  return {
    options: sponsors || [],
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

export default loadOptions;
