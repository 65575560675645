import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useActions, useSelector } from '../../../../hooks/store';
import { blogsActions } from '../../../../store/blogs';
import { globalCancelFetch } from '../../../../api/v2';
import { useResponsive } from '../../../../hooks/useResponsive';
import useDebounce from '../../../../hooks/useDebounce';

import HeaderTitle from '../../../../components/headerConstructor/components/title';
import SkeletonFriends from '../../../friends/components/skeletonFriends';
import BlogCard from '../components/blogCard';

import './styles.scss';

const BlogsSubscribedPage = ({ search }) => {
  const { isMobile } = useResponsive();

  const [page, setPage] = useState(1);
  const { pagination, loading, blogs } = useSelector((state) => state.blogs);
  const { clearBlogs, getBlogSubscribes } = useActions(blogsActions);

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    clearBlogs();
    getBlogSubscribes({ page, query: debouncedSearchTerm || null });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    return () => {
      clearBlogs();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const fetchPaginated = () => {
    setPage(page + 1);
    getBlogSubscribes({ page: page + 1, query: debouncedSearchTerm || null });
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  return (
    <div className="sub-blog">
      {isMobile ? (
        <div className="sub-blog--mobile-control">
          <HeaderTitle title="Блоги" />
          {/*<Button className="search" onClick={() => {}} variant="icon">*/}
          {/*  <Icon type="search" />*/}
          {/*</Button>*/}
          {/*<Button onClick={() => {}} variant="icon">*/}
          {/*  <Icon type="add" />*/}
          {/*</Button>*/}
        </div>
      ) : null}
      {loading && !hasMore ? (
        <SkeletonFriends />
      ) : (
        !isEmpty([1]) && (
          <InfiniteScroll
            dataLength={3}
            next={fetchPaginated}
            hasMore={hasMore}
            loader={<></>}
            className="sub-blog__cards"
          >
            {blogs && blogs.map((s) => <BlogCard key={s?.id} blog={s} />)}
          </InfiniteScroll>
        )
      )}
    </div>
  );
};

export default BlogsSubscribedPage;
