import React, { useState } from 'react';
import { Switch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';
import history from '../../../routes/history';
import { getIdFromUrl } from '../../../helpers/utility';
import useOwnerData from '../../../hooks/useOwnerData';

import HeaderLeft from '../../../components/headerConstructor/components/left';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderConstructor from '../../../components/headerConstructor';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import MobileNavigation from '../../../components/headerConstructor/components/mobileNavigation';
import Button from '../../../components/button';

import AllTeams from '../desktop/all';
import RecruitingTeams from '../desktop/recruiting';
import MainTeamPage from '../desktop/main';
import PersonnelTeamPage from '../desktop/personnel';
import IncomingRequest from '../desktop/incomingRequest';
import CreateRequestPage from './createRequest';
import ViewRequestPage from './viewRequest';
import AboutTeamPage from '../desktop/about';

import './styles.scss';
import Trans from '../../../components/trans';

const TeamSettingWrapper = React.lazy(() => import('../desktop/settingWrapper'));
const TeamCreateWrapper = React.lazy(() => import('../desktop/createWrapper'));

const getTitle = (path, id) => {
  switch (path) {
    case Pages.teams.recruiting.add:
      return Trans('actions.sendRequest');
    case Pages.teams.recruiting.view:
      return Trans('common.request');
    case Pages.team.main.replace(':id', id):
    case Pages.team.personnel.replace(':id', id):
    case Pages.team.request.replace(':id', id):
    case Pages.team.about.replace(':id', id):
    case Pages.team.equipment.replace(':id', id):
      return Trans('teams.team');
    case Pages.team.settings.main.replace(':id', id):
    case Pages.team.settings.personnel.replace(':id', id):
    case Pages.team.settings.links.replace(':id', id):
    case Pages.team.settings.about.replace(':id', id):
      return Trans('actions.settings');
    case Pages.team.create.main:
      return Trans('teams.teamCreation');
    default:
      return '';
  }
};

const getButton = ({ isCreatePages, isSettingPages, isSettingLinksPages }) => {
  if (isCreatePages) return Trans('actions.create');
  if (isSettingPages && !isSettingLinksPages) return Trans('actions.save');
  return null;
};

const TeamsSectionMobile = ({
  onSubmit,
  isTeamsPages,
  isDisabled,
  navigationMap,
  isSettingPages,
  isSettingLinksPages,
  isAddRequestPage,
  isViewRequestPage,
  isCreatePages,
  user,
  userRecruiting,
}) => {
  const { location } = history;
  const [search, setSearch] = useState('');
  const { userTeamId, isProfileLoaded } = useOwnerData();

  const handleChangeSearch = (v) => setSearch(v);

  const mainAction = getButton({
    isSettingPages,
    isCreatePages,
    isSettingLinksPages,
  });

  const isRecruitingRequest = !!userRecruiting;

  return (
    <>
      <HeaderConstructor className="team mobile">
        <HeaderLeft>
          {!isTeamsPages || isAddRequestPage || isViewRequestPage ? (
            <GoBackButton />
          ) : (
            <SidebarButton />
          )}
          <HeaderTitle title={getTitle(location.pathname, location.state)} />
        </HeaderLeft>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!isTeamsPages ? (
          <HeaderRight>
            {mainAction ? (
              <Button disabled={isDisabled} onClick={onSubmit} secondVariant="text" variant="done">
                {mainAction}
              </Button>
            ) : null}
          </HeaderRight>
        ) : isAddRequestPage || isViewRequestPage ? null : (
          <HeaderRight>
            <>
              <Controls />
              <Menu />
            </>
          </HeaderRight>
        )}
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        {!isTeamsPages ? (
          <div className="mobile-controls__wrap">
            <MobileNavigation navigationMap={navigationMap} />
          </div>
        ) : null}
        <Switch>
          <PrivateRoute
            exact
            path={Pages.teams.all}
            component={AllTeams}
            extraProps={{ navigationMap, search, onChangeSearch: handleChangeSearch }}
          />
          <PrivateRoute
            exact
            path={Pages.teams.recruiting.all}
            component={RecruitingTeams}
            extraProps={{
              navigationMap,
              isRecruitingRequest,
              user,
              userRecruiting,
              search,
              onChangeSearch: handleChangeSearch,
            }}
          />
          <PrivateRoute exact path={Pages.teams.recruiting.add} component={CreateRequestPage} />
          <PrivateRoute exact path={Pages.teams.recruiting.view} component={ViewRequestPage} />
          <PrivateRoute exact path={Pages.team.main} component={MainTeamPage} />
          <PrivateRoute exact path={Pages.team.personnel} component={PersonnelTeamPage} />
          <PrivateRoute exact path={Pages.team.request} component={IncomingRequest} />
          <PrivateRoute exact path={Pages.team.about} component={AboutTeamPage} />

          <PrivateRoute
            withAccess={isProfileLoaded}
            hasAccess={getIdFromUrl(location.pathname) === userTeamId}
            redirectPath={{
              pathname: Pages.team.main.replace(':id', getIdFromUrl(location.pathname)),
              state: getIdFromUrl(location.pathname),
            }}
            path={Pages.team.settings.base}
            component={TeamSettingWrapper}
          />

          <PrivateRoute path={Pages.team.create.base} component={TeamCreateWrapper} />
        </Switch>
      </div>
    </>
  );
};

export default TeamsSectionMobile;
