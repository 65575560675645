import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5582 9.7919H19.4946C19.7777 9.7919 20.0097 9.56566 20.0097 9.28957V8.50234C20.0097 8.22624 19.7777 8 19.4946 8H16.5582C16.275 8 16.043 8.22624 16.043 8.50234V9.28957C16.0425 9.56566 16.2746 9.7919 16.5582 9.7919Z"
      fill="#FFB800"
    />
    <path
      d="M18 10.4473C15.5092 10.4473 13.2525 11.4398 11.621 13.0398L13.5778 14.4174C14.7572 13.3787 16.3139 12.7402 18.0249 12.7402C21.6913 12.7402 24.6742 15.6487 24.6742 19.2237C24.6742 22.7987 21.6918 25.7071 18.0249 25.7071C14.3581 25.7071 11.3757 22.7991 11.3757 19.2237C11.3757 17.6421 11.9609 16.1925 12.9298 15.0659L11.4603 13.2128C9.9397 14.7852 9 16.8972 9 19.2237C9 24.0631 13.0372 28.0001 18 28.0001C22.9628 28.0001 27 24.0631 27 19.2237C27 14.3843 22.9628 10.4473 18 10.4473Z"
      fill="#FFB800"
    />
    <path
      d="M18.0652 20.664C17.6097 20.664 17.2084 20.4511 16.9472 20.1264L16.9446 20.1298L13.6924 16.0286C13.001 16.9176 12.5859 18.0211 12.5859 19.2202C12.5859 22.1429 15.0244 24.5201 18.0218 24.5201C21.0192 24.5201 23.4572 22.1425 23.4572 19.2202C23.4572 16.298 21.0188 13.9199 18.0218 13.9199C16.7168 13.9199 15.518 14.3711 14.5801 15.1215L18.7986 18.0911L18.7961 18.0945C19.209 18.3366 19.4874 18.7736 19.4874 19.2776C19.4878 20.0431 18.8515 20.664 18.0652 20.664Z"
      fill="#FFB800"
    />
  </svg>
);
