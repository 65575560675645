import { catchWrapper } from '../../../helpers/utility';
import { getText } from '../../../api/v2';

export default (pageId, parentId, type, languageId) => async (dispatch) => {
  dispatch({ type: 'SPONSOR/GET_TEXT_ENTRY/REQUESTED' });
  const formData = new FormData();
  formData.append('page_id', pageId);
  formData.append('parent_id', parentId);
  formData.append('type', type);
  formData.append('language_id', languageId);

  const result = await catchWrapper(getText(formData));
  if (result && result.result) {
    dispatch({ type: 'SPONSOR/GET_TEXT_ENTRY/SUCCEEDED', payload: result && result.text });
  } else {
    dispatch({
      type: 'SPONSOR/GET_TEXT_ENTRY/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
