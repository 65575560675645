import React from 'react';

export default () => (
  <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8102 0C24.6542 0 28.0363 3.2268 28.0363 7.8511V21.1489C28.0363 25.7732 24.6542 29 19.8102 29H8.22608C3.38203 29 0 25.7732 0 21.1489V7.8511C0 3.2268 3.38203 0 8.22608 0H19.8102ZM35.916 4.59937C36.794 4.16756 37.824 4.2127 38.662 4.72302C39.5 5.23138 40 6.11463 40 7.08032V21.9209C40 22.8885 39.5 23.7698 38.662 24.2782C38.204 24.5549 37.692 24.6962 37.176 24.6962C36.746 24.6962 36.316 24.5981 35.914 24.3998L32.9519 22.9552C31.8559 22.4174 31.1759 21.3379 31.1759 20.1387V8.86055C31.1759 7.65934 31.8559 6.57981 32.9519 6.04594L35.916 4.59937Z"
      fill="currentColor"
    />
  </svg>
);
