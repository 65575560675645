import React from 'react';
import { classNames as cx } from '../../../../../../helpers/utility';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import Button from '../../../../../../components/button';
import Icon from '../../../../../../components/icon';

import './styles.scss';

const ImagesHeader = ({
  state,
  onClickLeft,
  onClickRight,
  title,
  count,
  addButton,
  goTo,
  pageQty = 2,
  wrapRef,
  itemWidth,
}) => {
  const { isMobile } = useResponsive();
  const leftDisabled = state.currentItem === 0;
  const rightDisabled = state.totalItem - 1 === state.currentItem;
  const width = wrapRef?.current?.scrollWidth - (wrapRef?.current?.offsetWidth + wrapRef?.current?.scrollLeft);
  const widthDisabled = width < 1;
  //console.log(width, wrapRef?.current?.offsetWidth, wrapRef?.current?.scrollWidth, wrapRef?.current?.scrollLeft, itemWidth);

  const classNamesRight = cx('button--carousel__right', {
    'button--carousel__right_disable': (!isMobile && widthDisabled) || (isMobile && rightDisabled),
  });

  const classNamesLeft = cx('button--carousel__left', {
    'button--carousel__left_disable': leftDisabled,
  });

  return (
    <div className="images__header">
      <span className="images__header-title">{title}</span>
      <span className="images__header-count">{count}</span>
      {addButton && (
        <Button onClick={goTo} variant="icon">
          <Icon type="add" />
        </Button>
      )}
      {state.totalItem > pageQty && (
        <Button
          disabled={leftDisabled}
          onClick={onClickLeft}
          className={classNamesLeft}
          secondVariant="icon"
          variant="carousel"
        >
          <Icon type="chevron" />
        </Button>
      )}
      {state.totalItem > pageQty && (
        <Button
          disabled={rightDisabled}
          onClick={onClickRight}
          className={classNamesRight}
          secondVariant="icon"
          variant="carousel"
        >
          <Icon type="chevron" />
        </Button>
      )}
    </div>
  );
};

export default ImagesHeader;
