import React from 'react';

import { Pages } from '../../../../routes/pages';
import useOwnerData from '../../../../hooks/useOwnerData';

import UserCardInfo from '../userCardInfo';
import UserCardActions from '../userCardActions';
import UserCardExtended from '../userCardExtended';

import './styles.scss';

const UserCard = ({
  user,
  extendedFriendCard,
  teamBlock,
  addFriend,
  removeFriend,
  cancelRequest,
  sentRequest,
  page,
}) => {
  const { userId } = useOwnerData();
  const schema = [];

  if (page === Pages.users.all || page === Pages.users.userFriends) {
    if (user.is_friend) {
      schema.push({
        id: '1',
        label: 'Удалить из друзей',
        action: removeFriend,
      });
    }

    if (user.is_request_was_sent) {
      schema.push({
        id: '1',
        label: 'Отменить заявку',
        action: cancelRequest,
      });
    }

    if (user.is_request_received) {
      schema.push({
        id: '1',
        label: 'Добавить в друзья',
        action: addFriend,
      });
    }

    if (!user.is_friend && !user.is_request_was_sent && !user.is_request_received) {
      schema.push({
        id: '1',
        label: 'Отправить заявку',
        action: sentRequest,
      });
    }
  }

  if (page === Pages.users.main || page === Pages.users.common) {
    schema.push({
      id: '1',
      label: 'Удалить их друзей',
      action: removeFriend,
    });
  }

  if (page === Pages.users.requests) {
    schema.push({
      id: '1',
      label: 'Принять заявку в друзья',
      action: addFriend,
    });
    // schema.push({
    //   id: '2',
    //   label: 'Отклонить заявку в друзья',
    //   action: removeFriend,
    // });
  }

  if (page === Pages.users.outRequests) {
    schema.push({
      id: '1',
      label: 'Отменить заявку',
      action: removeFriend,
    });
  }

  return (
    <div className="card user-card">
      <div className="user-card__user">
        <UserCardInfo user={user} ownerId={userId} />
        <UserCardActions schema={schema} id={user?.id} />
      </div>
      {extendedFriendCard && (
        <UserCardExtended ownerId={userId} user={user} teamBlock={teamBlock} />
      )}
    </div>
  );
};

export default UserCard;
