import { FILE_SIZE, VALID_IMAGE_TYPE } from '../../../helpers/config';

import modalService from '../../../services/modalService';

export default ({ file, minWidth, minHeight, params }) => async () => {
  const fileSize = Number((file?.size / 1024 / 1024).toFixed(4));

  const showError = (content) =>
    modalService.openNotificationsModal({
      className: 'image-picker',
      content,
    });

  if (fileSize > FILE_SIZE) {
    return showError('Размер изображения не должен превышать 10 MB.');
  }

  const isValidType = VALID_IMAGE_TYPE[file?.type];

  if (file && !isValidType) {
    return showError('Разрешенные форматы JPG/PNG.');
  }

  if (file) {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    // eslint-disable-next-line consistent-return
    img.onload = await function () {
      const width = this?.naturalWidth;
      const height = this?.naturalHeight;

      const text = `Размер картинки должен быть ${minWidth}px на ${minHeight}px`;

      if (width < minWidth || height < minHeight) {
        return showError(text);
      }
      modalService.openCropper({ ...params, file });
    };
  }
};
