import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { eventsActions } from '../../../../../store/events';
import { useActions, useSelector } from '../../../../../hooks/store';
import { getDangerouslyHtml } from '../../../../../helpers/utility';
import { useResponsive } from '../../../../../hooks/useResponsive';

const Scenario = () => {
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { onGetEventSettingsScenario } = useActions(eventsActions);
  const { eventSettings } = useSelector((state) => state.events);
  const scenarioText = eventSettings?.scenario?.text;

  useEffect(() => {
    if (id) {
      onGetEventSettingsScenario('2', Number(id), 'event', '1');
    }
  }, [id]);

  return (
    <div className="events-text">
      {!isMobile && <div className="info-caption">Сценарий</div>}
      {scenarioText && (
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(scenarioText)} />
      )}
    </div>
  );
};

export default Scenario;
