import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.12C5 8.50142 5.54266 8 6.21212 8H23.7879C24.4573 8 25 8.50142 25 9.12V20.88C25 21.4985 24.4573 22 23.7879 22H6.21212C5.54266 22 5 21.4985 5 20.88V9.12ZM15.0685 14.5739L7.95329 9.09519C7.56762 8.79817 6.99433 8.84636 6.67295 9.20271C6.3515 9.55914 6.40359 10.0888 6.78933 10.3858L14.4638 16.2951C14.7942 16.5676 15.2997 16.5827 15.6502 16.3128L23.3444 10.3883C23.7301 10.0914 23.7822 9.56167 23.4608 9.20531C23.1394 8.84889 22.5662 8.80076 22.1804 9.09778L15.0685 14.5739Z"
      fill="currentColor"
    />
  </svg>
);
