import React, { useState } from 'react';

import { useResponsive } from '../../hooks/useResponsive';

import CamouflageSectionMobile from './mobile';
import CamouflageSectionDesktop from './desktop';

const CamouflagesPage = () => {
  const [search, setSearch] = useState('');
  const { isMobile } = useResponsive();
  const handleChangeSearch = (v) => setSearch(v);
  return isMobile ? (
    <CamouflageSectionMobile search={search} onChangeSearch={handleChangeSearch} />
  ) : (
    <CamouflageSectionDesktop search={search} onChangeSearch={handleChangeSearch} />
  );
};

export default CamouflagesPage;
