import { catchWrapper } from '../../../helpers/utility';
import { getBlogArticlesList } from '../../../api/v2';

export default ({ page, blogId, blogType = 1 }) => async (dispatch) => {
  dispatch({ type: 'BLOG/GET_BLOG_ARTICLES/REQUESTED' });

  const payloadRequest = {
    page,
    objects_per_page: 20,
    blog_id: Number(blogId),
    type: blogType,
  };

  const result = await catchWrapper(getBlogArticlesList(payloadRequest));
  if (result && result.result) {
    dispatch({ type: 'BLOG/GET_BLOG_ARTICLES/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'BLOG/GET_BLOG_ARTICLES/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
