import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import modalService from '../../../../services/modalService';
import { authActions } from '../../../../store/auth';
import { useActions, useSelector } from '../../../../hooks/store';
import Button from '../../../../components/button';
import { blogActions } from '../../../../store/blog';

const BlogSettingLinks = () => {
  const { id } = useParams();

  const { onSaveBlogService, getBlog } = useActions(blogActions);
  const { getServices } = useActions(authActions);

  const { services } = useSelector((state) => state.auth);
  const { blog } = useSelector((state) => state.blog);

  useEffect(() => {
    getBlog(id);
    getServices();
  }, []);

  const handleOpenModal = (key) =>
    modalService.openChangeLinkModal({
      service: key,
      onSave: onSaveBlogService,
    });

  const handleRemoveLink = (service) => {
    const payload = {
      service,
      value: '',
    };
    onSaveBlogService(payload);
  };

  return (
    <div className="settings-links">
      {blog?.social_service &&
        !isEmpty(services) &&
        services.map((item) => {
          return (
            <div key={item.id} className="settings-links__row settings-links__row_vk">
              <img src={item.icon} className="settings-links__icon" alt={item.title} />
              <span className="settings-links__name">{item.title}</span>
              {blog?.social_service[item.key].value ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="settings-links__link"
                  href={blog?.social_service[item.key].value}
                >{`${blog?.social_service[item.key].value}`}</a>
              ) : null}
              {blog?.social_service[item.key].value ? (
                <Button
                  onClick={() => handleRemoveLink(item.key)}
                  variant="text"
                  className="settings-links__link settings-links__link_remove"
                >
                  Удалить
                </Button>
              ) : (
                <Button
                  onClick={() => handleOpenModal(item.key)}
                  variant="text"
                  className="settings-links__link settings-links__link_add"
                >
                  Добавить ссылку
                </Button>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default BlogSettingLinks;
