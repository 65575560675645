import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10.7143C8 9.76751 8.77864 9 9.73913 9H26.2609C27.2214 9 28 9.76751 28 10.7143V25.2857C28 26.2325 27.2214 27 26.2609 27H9.73913C8.77863 27 8 26.2325 8 25.2857V10.7143ZM11.4783 13.7143C11.4783 13.0042 12.0622 12.4286 12.7826 12.4286C13.503 12.4286 14.087 13.0042 14.087 13.7143C14.087 14.4244 13.503 15 12.7826 15C12.0622 15 11.4783 14.4244 11.4783 13.7143ZM17.1304 12.4286C16.4101 12.4286 15.8261 13.0042 15.8261 13.7143C15.8261 14.4244 16.4101 15 17.1304 15H23.2174C23.9377 15 24.5217 14.4244 24.5217 13.7143C24.5217 13.0042 23.9377 12.4286 23.2174 12.4286H17.1304ZM11.4783 18C11.4783 17.2899 12.0622 16.7143 12.7826 16.7143C13.503 16.7143 14.087 17.2899 14.087 18C14.087 18.7101 13.503 19.2857 12.7826 19.2857C12.0622 19.2857 11.4783 18.7101 11.4783 18ZM17.1304 16.7143C16.4101 16.7143 15.8261 17.2899 15.8261 18C15.8261 18.7101 16.4101 19.2857 17.1304 19.2857H23.2174C23.9377 19.2857 24.5217 18.7101 24.5217 18C24.5217 17.2899 23.9377 16.7143 23.2174 16.7143H17.1304ZM11.4783 22.2857C11.4783 21.5757 12.0622 21 12.7826 21C13.503 21 14.087 21.5757 14.087 22.2857C14.087 22.9958 13.503 23.5714 12.7826 23.5714C12.0622 23.5714 11.4783 22.9958 11.4783 22.2857ZM17.1304 21C16.4101 21 15.8261 21.5757 15.8261 22.2857C15.8261 22.9958 16.4101 23.5714 17.1304 23.5714H23.2174C23.9377 23.5714 24.5217 22.9958 24.5217 22.2857C24.5217 21.5757 23.9377 21 23.2174 21H17.1304Z"
      fill="currentColor"
    />
  </svg>
);
