import React from 'react';
import Select from 'react-select';
import { classNames as cx } from '../../helpers/utility';

import './styles.scss';

const groupStyles = {
  fontWeight: 500,
  fontSize: 14,
};

const CustomCitySelect = ({
  className,
  name,
  label,
  labelPosition,
  isDisabled,
  asyncSelect,
  options,
  isLoading,
  loadOptions,
  valueKey,
  labelKey,
  handleChange,
  onMenuOpen,
  geoLocal,
  defaultValue,
  placeholder,
  ...props
}) => {
  const classNamesWrapper = cx('select__wrapper', className, {
    [`select__wrapper__label_${labelPosition}`]: labelPosition,
    [`select__${name}`]: name,
  });

  const classNamesLabel = cx('select__label', className, {
    [`select__label_${labelPosition}`]: labelPosition,
  });
  const style = {
    option: (styles, { data }) => {
      return data.isRegion
        ? {
            ...styles,
            ...groupStyles,
          }
        : { ...styles, fontSize: 14 };
    },
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
    }),
  };

  const filterOption = ({ data }, str) => {
    let status = true;
    if (data.isRegion) {
      status =
        !geoLocal.selectedCities ||
        geoLocal.selectedCities.filter((item) => item.region_id === data.region_id).length !==
          data.cities.length;
    } else {
      status =
        !geoLocal?.selectedRegions || geoLocal?.selectedRegions.indexOf(data.region_id) === -1;
    }
    if (status && str) {
      status = data.title.toLowerCase().indexOf(str.toLowerCase()) !== -1;
    }

    return status;
  };

  const value = geoLocal?.init ? geoLocal?.regionArr : null;

  return (
    <div className={classNamesWrapper}>
      {label && <span className={classNamesLabel}>{label}</span>}
      <Select
        {...props}
        className="select__container"
        label={label}
        name={name}
        classNamePrefix="select"
        isDisabled={isDisabled}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        onChange={handleChange}
        isSearchable
        isMulti
        value={value}
        noOptionsMessage={() => null}
        styles={style}
        components={{
          IndicatorSeparator: () => null,
        }}
        options={options}
        onMenuOpen={onMenuOpen}
        isLoading={isLoading}
        placeholder={placeholder}
        filterOption={filterOption}
      />
    </div>
  );
};

export default CustomCitySelect;
