import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6667 20C36.6667 17.5816 36.1516 15.2834 35.2251 13.2093L37.5835 10.462C39.1246 13.297 40 16.5462 40 20C40 31.0457 31.0457 40 20 40C8.95433 40 0 31.0457 0 20C0 8.95433 8.95433 3.23208e-09 20 3.23208e-09C23.4691 3.23208e-09 26.7319 0.883233 29.5757 2.4371L27.3456 5.03519C25.1296 3.94541 22.6362 3.33333 20 3.33333C10.7952 3.33333 3.33333 10.7952 3.33333 20C3.33333 29.2048 10.7952 36.6667 20 36.6667C29.2048 36.6667 36.6667 29.2048 36.6667 20ZM38.7057 0.481522C39.5443 1.20037 39.6415 2.46297 38.9227 3.30162L21.5258 23.5979C20.8069 24.4366 19.5443 24.5337 18.7057 23.8148C18.5598 23.6898 18.4363 23.5482 18.3357 23.3954C18.2996 23.3561 18.2647 23.315 18.2312 23.2722L13.4253 17.1326C12.7443 16.2628 12.8976 15.0057 13.7673 14.3249C14.6371 13.6441 15.8942 13.7973 16.5749 14.667L20.0774 19.1414L35.8856 0.698453C36.6045 -0.140198 37.8671 -0.237321 38.7057 0.481522Z"
      fill="#FF603E"
    />
  </svg>
);
