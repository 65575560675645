import { catchWrapper } from '../../../helpers/utility';
import { getEventsList } from '../../../api/v2';

export default (userId, page) => async (dispatch) => {
  dispatch({ type: 'EVENTS/GET_USER_EVENTS_LIST/REQUESTED' });

  const payloadRequest = {
    page,
    objects_per_page: 20,
    user_id: Number(userId),
  };

  const result = await catchWrapper(getEventsList('user', payloadRequest));

  if (result && result.result) {
    dispatch({ type: 'EVENTS/GET_USER_EVENTS_LIST/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'EVENTS/GET_USER_EVENTS_LIST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
