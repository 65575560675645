import { dropdownMap } from '../pages/settings/constant';

export const getObjectByPrivacy = (value) => dropdownMap.filter((f) => f.value === value)[0];

export const getLocations = (data) => {
  const city = data?.city?.title || '';
  const country = data?.country?.title || '';
  const region = data?.city?.region || '';

  return { city, country, region };
};

export const getUserRoleInTeam = (state) => {
  const { user } = state.profile;
  return user?.team_role;
};

export const getUserRoleInSponsor = (state) => {
  const { sponsor } = state.sponsor;
  return sponsor?.user_role;
};
