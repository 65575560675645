import { catchWrapper } from '../../../helpers/utility';
import { postTeams } from '../../../api';

export default (id) => async (dispatch) => {
  dispatch({ type: 'TEAMS/REQUEST_JOIN_TEAM/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'addRequest');
  formData.append('team_id', Number(id));

  const result = await catchWrapper(postTeams('team_users', formData));

  if (result && result.result) {
    dispatch({ type: 'TEAMS/REQUEST_JOIN_TEAM/SUCCEEDED' });
  } else {
    dispatch({
      type: 'TEAMS/REQUEST_JOIN_TEAM/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
