import onLogin from './impl/singIn';
import onRegister from './impl/singUp';
import onCheckAuth from './impl/checkAuth';
import onLogOut from './impl/logout';
import getProviders from './impl/getProviders';
import getServices from './impl/getServices';
import onUnlinkSocial from './impl/unlinkSocial';
import onAuthInSocial from './impl/singInSocial';
import onBindingSocial from './impl/bindingSocial';
import onSaveService from './impl/saveService';
import getSocial from './impl/getSocial';
import changePassword from './impl/changePassword';
import onChangeField from './impl/onChangeField';
import submitCode from './impl/submitCode';
import onResentCode from './impl/onResentCode';
import getEntityCounts from './impl/getEntityCounts';

const initState = {
  loading: 0,
  error: {
    login: null,
    register: null,
    emailConfirm: null,
  },
  show_captcha: false,
  providers: [],
  services: [],
  token: '',
  userId: '',
  authSocial: false,
};

export const authReducer = (state = null, action) => {
  switch (action.type) {
    case 'AUTH/LOGIN/REQUESTED':
    case 'AUTH/LOGOUT/REQUESTED':
    case 'AUTH/PROVIDERS/REQUESTED':
    case 'AUTH/SERVICES/REQUESTED':
    case 'AUTH/SOCIAL/REQUESTED':
    case 'AUTH/UN_LINK_SOCIAL/REQUESTED':
    case 'AUTH/SOCIAL_BINDING/REQUESTED':
    case 'AUTH/SERVICE_SAVE/REQUESTED':
    case 'AUTH/GET_SOCIAL/REQUESTED':
    case 'AUTH/CHECK/REQUESTED':
    case 'AUTH/SEND_CODE/REQUESTED':
    case 'AUTH/RESENT_CODE/REQUESTED':
    case 'AUTH/GET_COUNT/REQUESTED': {
      return {
        ...state,
        error: {
          login: null,
          register: null,
        },
        loading: state.loading + 1,
      };
    }
    case 'AUTH/LOGIN/SUCCEEDED':
    case 'AUTH/SOCIAL/SUCCEEDED':
    case 'AUTH/CHECK/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        token: action.payload?.token || '',
        userId: action.payload?.userId || '',
      };
    }

    case 'AUTH/PROVIDERS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        providers: action.payload,
      };
    }

    case 'AUTH/SERVICES/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        services: action.payload,
      };
    }

    case 'AUTH/GET_SOCIAL/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        social: action.payload,
      };
    }

    case 'AUTH/LOGIN/FAILED':
    case 'AUTH/SOCIAL/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: {
          ...state.error,
          login: action.payload?.message,
          register: null,
        },
        showCaptcha: action.payload?.show_captcha,
      };
    }

    case 'AUTH/REGISTER/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: {
          login: null,
          register: action.payload,
        },
      };
    }

    case 'AUTH/SEND_CODE/FAILED':
    case 'AUTH/RESENT_CODE/SUCCEEDED':
    case 'AUTH/RESENT_CODE/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: {
          ...state.error,
          emailConfirm: action.payload,
        },
      };
    }

    case 'AUTH/GET_COUNT/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        counts: action.payload,
      };
    }

    case 'AUTH/LOGOUT/SUCCEEDED': {
      return {
        ...initState,
      };
    }
    case 'AUTH/LOGOUT/FAILED':
    case 'AUTH/PROVIDERS/FAILED':
    case 'AUTH/SERVICES/FAILED':
    case 'AUTH/UN_LINK_SOCIAL/SUCCEEDED':
    case 'AUTH/SOCIAL_BINDING/SUCCEEDED':
    case 'AUTH/SERVICE_SAVE/SUCCEEDED':
    case 'AUTH/SEND_CODE/SUCCEEDED':
    case 'AUTH/SOCIAL_BINDING/FAILED':
    case 'AUTH/SERVICE_SAVE/FAILED':
    case 'AUTH/UN_LINK_SOCIAL/FAILED':
    case 'AUTH/CHECK/FAILED':
    case 'AUTH/GET_SOCIAL/FAILED':
    case 'AUTH/GET_COUNT/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
      };
    }
    case 'AUTH/CLEAR': {
      return {
        ...initState,
      };
    }
    case 'AUTH/CHANGE_FIELD': {
      const { value, field } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }
    default:
      return state;
  }
};

export const authActions = {
  onLogin,
  onLogOut,
  onRegister,
  getProviders,
  getServices,
  getSocial,
  onAuthInSocial,
  onUnlinkSocial,
  onBindingSocial,
  onSaveService,
  onCheckAuth,
  changePassword,
  onChangeField,
  submitCode,
  onResentCode,
  getEntityCounts,
};
