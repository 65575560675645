import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../../../../../hooks/store';
import { sponsorAction } from '../../../../../../store/sponsor';
import { getDangerouslyHtml } from '../../../../../../helpers/utility';
import { globalCancelFetch } from '../../../../../../api/v2';

const History = ({ sponsorId }) => {
  const { onGetSponsorSettingsHistory } = useActions(sponsorAction);
  const { sponsorSettings } = useSelector((state) => state.sponsor);
  const historyText = sponsorSettings?.historyText?.text;

  useEffect(() => {
    if (sponsorId) {
      onGetSponsorSettingsHistory('5', sponsorId, 'sponsor', '1');
    }

    return () => {
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, [sponsorId]);

  return (
    <div>
      {historyText && (
        <div className="info-text" dangerouslySetInnerHTML={getDangerouslyHtml(historyText)} />
      )}
    </div>
  );
};

export default History;
