import { Pages } from '../../../routes/pages';
import { getIdFromUrl } from '../../../helpers/utility';

const navigationTeamsMap = [
  {
    id: '1',
    path: Pages.teams.all,
    label: 'teams.navigation.teams',
  },
  {
    id: '2',
    path: Pages.teams.recruiting.all,
    label: 'teams.navigation.recruting',
  },
];
const navigationTeamMap = (teamId, user) => [
  {
    id: '1',
    path: Pages.team.main.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.main',
  },
  {
    id: '2',
    path: Pages.team.personnel.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.personnel',
  },
  {
    id: '3',
    path: Pages.team.request.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.requests',
    isVisible: user?.team?.id === teamId && user.team_role,
    hiddenRequest: true,
  },
  {
    id: '4',
    path: Pages.team.about.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.about',
    hash: '#history',
  },
];
const navigationTeamSettingMap = (teamId) => [
  {
    id: '1',
    path: Pages.team.settings.main.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.main',
  },
  {
    id: '2',
    path: Pages.team.settings.personnel.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.personnel',
  },
  {
    id: '3',
    path: Pages.team.settings.links.replace(':id', teamId),
    state: teamId,
    label: 'common.links',
  },
  {
    id: '4',
    path: Pages.team.settings.about.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.about',
    hash: '#history',
  },
];
const navigationMobileTeamSettingMap = (teamId) => [
  {
    id: '1',
    path: Pages.team.settings.main.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.main',
  },
  {
    id: '2',
    path: Pages.team.settings.personnel.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.personnel',
  },
  {
    id: '3',
    path: Pages.team.settings.links.replace(':id', teamId),
    state: teamId,
    label: 'common.links',
  },
  {
    id: '4',
    path: Pages.team.settings.about.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.about',
    hash: '#history',
    children: [
      {
        id: '5',
        label: 'teams.about.history',
        state: teamId,
        hash: '#history',
        path: Pages.team.settings.about.replace(':id', teamId),
      },
      {
        id: '6',
        label: 'teams.about.rules',
        path: Pages.team.settings.about.replace(':id', teamId),
        state: teamId,
        hash: '#charter',
      },
      {
        id: '7',
        label: 'teams.about.kit',
        path: Pages.team.settings.about.replace(':id', teamId),
        state: teamId,
        hash: '#equipment',
      },
      {
        id: '8',
        label: 'teams.about.qualifications',
        path: Pages.team.settings.about.replace(':id', teamId),
        state: teamId,
        hash: '#demands',
      },
    ],
  },
];

const navigationTeamCreateMap = () => [
  {
    id: '1',
    path: Pages.team.create.main,
    label: 'teams.navigation.main',
  },
];

const navigationMobileTeamCreateMap = () => [
  {
    id: '1',
    path: Pages.team.create.main,
    label: 'teams.navigation.main',
  },
];
const navigationMobileTeamMap = (teamId, user) => [
  {
    id: '1',
    path: Pages.team.main.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.main',
  },
  {
    id: '2',
    path: Pages.team.personnel.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.personnel',
  },
  {
    id: '3',
    path: Pages.team.request.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.requests',
    isVisible: user?.team?.id === teamId,
    hiddenRequest: true,
  },
  {
    id: '4',
    path: Pages.team.about.replace(':id', teamId),
    state: teamId,
    label: 'teams.navigation.about',
    hash: '#history',
    children: [
      {
        id: '5',
        label: 'teams.about.history',
        state: teamId,
        hash: '#history',
        path: Pages.team.about.replace(':id', teamId),
      },
      {
        id: '6',
        label: 'teams.about.rules',
        path: Pages.team.about.replace(':id', teamId),
        state: teamId,
        hash: '#charter',
      },
      {
        id: '7',
        label: 'teams.about.kit',
        path: Pages.team.about.replace(':id', teamId),
        state: teamId,
        hash: '#equipment',
      },
      {
        id: '8',
        label: 'teams.about.qualifications',
        path: Pages.team.about.replace(':id', teamId),
        state: teamId,
        hash: '#demands',
      },
    ],
  },
];

export const getTeamAboutNaviMap = (teamId) => [
  {
    id: '1',
    label: 'teams.about.history',
    route: {
      pathname: Pages.team.settings.about.replace(':id', teamId),
      state: teamId,
      hash: '#history',
    },
  },
  {
    id: '2',
    label: 'teams.about.rules',
    route: {
      pathname: Pages.team.settings.about.replace(':id', teamId),
      state: teamId,
      hash: '#charter',
    },
  },
  {
    id: '3',
    label: 'teams.about.kit',
    route: {
      pathname: Pages.team.settings.about.replace(':id', teamId),
      state: teamId,
      hash: '#equipment',
    },
  },
  {
    id: '4',
    label: 'teams.about.qualifications',
    route: {
      pathname: Pages.team.settings.about.replace(':id', teamId),
      state: teamId,
      hash: '#demands',
    },
  },
];

export const getMaps = ({
  isTeamPages,
  isSettingPages,
  isCreatePages,
  location,
  isMobile,
  user,
}) => {
  if (isCreatePages) {
    return isMobile ? navigationMobileTeamCreateMap() : navigationTeamCreateMap();
  }
  if (isSettingPages)
    return isMobile
      ? navigationMobileTeamSettingMap(getIdFromUrl(location.pathname))
      : navigationTeamSettingMap(getIdFromUrl(location.pathname));
  if (isTeamPages)
    return isMobile
      ? navigationMobileTeamMap(getIdFromUrl(location.pathname), user)
      : navigationTeamMap(getIdFromUrl(location.pathname), user);
  return navigationTeamsMap;
};
