import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useActions, useSelector } from '../../../hooks/store';
import { camouflageActions } from '../../../store/camouflages';
import useVisible from '../../../hooks/useVisible';
import useDebounce from '../../../hooks/useDebounce';

import Button from '../../../components/button';
import Icon from '../../../components/icon';
import CamouflageDropdown from './dropdown';

import dummy from '../../../assets/dummy/square.svg';

import { classNames as cx } from '../../../helpers/utility';

import './styles.scss';
import './skeleton.scss';
import Trans from '../../../components/trans';

const CamouflageSelect = ({
  selectedCamouflages,
  onChange,
  onRemoveCamouflage,
  error,
  onChangeField,
}) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const { getCamouflages, clearCamouflages } = useActions(camouflageActions);
  const { camouflages, pagination, loading } = useSelector((state) => state.camouflages);

  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (isVisible) {
      getCamouflages(page);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) {
      clearCamouflages();
      setSearch('');
    }
  }, [isVisible]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      clearCamouflages();
      getCamouflages({ page: 1, query: debouncedSearchTerm });
    } else if (isVisible) {
      clearCamouflages();
      getCamouflages({ page: 1 });
    }
  }, [debouncedSearchTerm]);

  const fetchPaginated = () => {
    setPage(page + 1);
    getCamouflages(page + 1);
  };
  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  const handleToggle = () => setIsVisible(!isVisible);

  const handleClickItem = (element) => {
    const el = {
      id: element.id,
      title: element.title,
      image: { ...element.image },
    };
    let temp = [...selectedCamouflages];

    if (temp.length < 3) {
      temp = [...temp, el];
    } else {
      temp.shift();
      temp = [...temp, el];
    }

    onChange(temp);
  };

  const handleChangeSearch = (v) => setSearch(v);

  const filtered = camouflages.filter(
    (c) => selectedCamouflages && !selectedCamouflages.some((t) => c.id === t.id),
  );

  const classNamesLabel = cx('camouflage-select__title', {
    'camouflage-select__title--error': error,
  });

  return (
    <div ref={ref} className="camouflage-select__container">
      <span className={classNamesLabel}>{error || Trans('camouflage.card.title')}</span>
      <div className="camouflage-select__row">
        {!isEmpty(selectedCamouflages)
          ? selectedCamouflages.map((c) => {
              const image = (c.image && c.image.image) || dummy;
              return (
                <div key={c.id} className="camouflage-select__preview-wrap">
                  <Button
                    className="remove-camouflage"
                    variant="icon"
                    onClick={() => onRemoveCamouflage(c.id)}
                  >
                    <Icon type="removeCam" />
                  </Button>
                  <img src={image} alt="camouflage" />
                </div>
              );
            })
          : null}

        {selectedCamouflages?.length < 3 ? (
          <Button onClick={handleToggle} variant="add">
            <Icon type="add" />
          </Button>
        ) : null}
      </div>
      {isVisible ? (
        <CamouflageDropdown
          onChangeSearch={handleChangeSearch}
          searchValue={search}
          camouflages={filtered}
          fetchPaginated={fetchPaginated}
          hasMore={hasMore}
          loading={loading}
          handleClickItem={handleClickItem}
        />
      ) : null}
    </div>
  );
};

export default CamouflageSelect;
