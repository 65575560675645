import React, { useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import history from '../../../routes/history';
import { useResponsive } from '../../../hooks/useResponsive';
import { Pages } from '../../../routes/pages';

import HeaderConstructor from '../../../components/headerConstructor';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import InputSearch from '../../../components/inputSearch';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderRight from '../../../components/headerConstructor/components/right';

import CamouflagesAllPage from './all';
import CamouflageMainPage from './main';

import './styles.scss';
import TabletNavigation from '../../../components/headerConstructor/components/tabletNavigation';
import Trans from '../../../components/trans';

const CamouflageSectionDesktop = ({ search, onChangeSearch }) => {
  const { location } = history;
  const { isDesktop } = useResponsive();
  const isCamouflageMainPage = useRouteMatch(Pages.camouflage.main.replace(':id', location.state));

  const pageTitle = isCamouflageMainPage
    ? Trans('camouflage.card.title')
    : Trans('camouflage.title');

  const isShowSearch = !isCamouflageMainPage;

  return (
    <>
      <HeaderConstructor className="camouflages">
        <HeaderLeft
          title={pageTitle}
          search={isShowSearch ? <InputSearch value={search} onChange={onChangeSearch} /> : null}
        />
        <HeaderRight>
          <Controls isShowLang />
          <Menu />
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute
            exact
            path={Pages.camouflages.all}
            component={CamouflagesAllPage}
            extraProps={{ search, onChangeSearch }}
          />
          <PrivateRoute exact path={Pages.camouflage.main} component={CamouflageMainPage} />
        </Switch>
      </div>
    </>
  );
};

export default CamouflageSectionDesktop;
