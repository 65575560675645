import React from 'react';
import MessagesContainer from '../../components/msg-container';
import MessagesLayout from '../../components/layout';

function DialogsMobile() {
  return (
    <MessagesLayout>
      <MessagesContainer />
    </MessagesLayout>
  );
}

export default DialogsMobile;
