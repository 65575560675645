export const galleryOptions = [
  {
    id: '1',
    label: 'Видео',
    value: 'video',
  },
  {
    id: '2',
    label: 'Фото',
    value: 'photo',
  },
];
