import React from 'react';

export default () => (
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5571 2.80545H12.8857V2.33322C12.8857 1.44279 12.8857 0.99758 12.6012 0.720957C12.3167 0.444336 11.8587 0.444336 10.9429 0.444336H7.05714C6.14128 0.444336 5.68332 0.444336 5.39882 0.720957C5.11429 0.99758 5.11429 1.44279 5.11429 2.33322V2.80545H2.44286C1.52698 2.80545 1.06905 2.80545 0.784524 3.08207C0.5 3.35868 0.5 3.80391 0.5 4.69434V12.7221C0.5 13.6125 0.5 14.0578 0.784524 14.3344C1.06905 14.611 1.52698 14.611 2.44286 14.611H15.5571C16.473 14.611 16.931 14.611 17.2155 14.3344C17.5 14.0578 17.5 13.6125 17.5 12.7221V4.69434C17.5 3.80391 17.5 3.35868 17.2155 3.08207C16.931 2.80545 16.473 2.80545 15.5571 2.80545ZM11.6714 8.47211C11.6714 9.90651 10.4754 11.0693 9 11.0693C7.52462 11.0693 6.32857 9.90651 6.32857 8.47211C6.32857 7.03771 7.52462 5.87489 9 5.87489C10.4754 5.87489 11.6714 7.03771 11.6714 8.47211ZM13.1286 8.47211C13.1286 10.6889 11.2801 12.486 9 12.486C6.71986 12.486 4.87143 10.6889 4.87143 8.47211C4.87143 6.25531 6.71986 4.45822 9 4.45822C11.2801 4.45822 13.1286 6.25531 13.1286 8.47211Z"
      fill="currentColor"
    />
  </svg>
);
