import React from 'react';
import { Trans as LTrans } from 'react-i18next';

//Don`t use HOOKS PLEASE!!!!!!!!!!!!!!!!!!!!

function Trans(k) {
  if (!k) {
    return null;
  }

  return <LTrans>{k}</LTrans>;
}

export default Trans;
