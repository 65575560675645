import { catchWrapper } from '../../../helpers/utility';
import { postSaveSponsorLegal } from '../../../api/v2';

export default () => async (dispatch, getState) => {
  const { sponsor } = getState().sponsor;

  dispatch({ type: 'SPONSOR/SAVE_SPONSOR_LEGAL/REQUESTED' });

  const result = await catchWrapper(postSaveSponsorLegal(sponsor));

  if (result && result.result) {
    dispatch({ type: 'SPONSOR/SAVE_SPONSOR_LEGAL/SUCCEEDED' });
  } else {
    dispatch({
      type: 'SPONSOR/SAVE_SPONSOR_LEGAL/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
