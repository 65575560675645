import React from 'react';

import history from '../../../../routes/history';

import Icon from '../../../icon';
import Button from '../../../button';

import './styles.scss';

const GoBackButton = ({ action }) => {
  const { goBack } = history;

  const handleClick = () => {
    if (action) {
      action();
      goBack();
    } else {
      goBack();
    }
  };
  return (
    <Button className="go-back" onClick={handleClick} variant="icon">
      <Icon type="goBack" />
    </Button>
  );
};

export default GoBackButton;
