import React from 'react';

import useVisible from '../../../hooks/useVisible';

import Icon from '../../../components/icon';
import Button from '../../../components/button';
import MobileNavigation from '../../../components/headerConstructor/components/mobileNavigation';
import MobileSearchRow from './searchRow';
import HeaderTitle from '../../../components/headerConstructor/components/title';

import './styles.scss';

const MobileControls = ({
  options,
  needSettings,
  handleVisibleFilter,
  isFilterOpened,
  isAddButton,
  addButtonIcon,
  addClick,
  clickAddDisabled,
  search,
  onChangeSearch,
  placeholder,
  title,
}) => {
  const { isVisible, setIsVisible } = useVisible(false);

  const handleShowSearch = () => setIsVisible(true);
  const handleCloseSearch = () => setIsVisible(false);

  return (
    <div className="mobile-controls">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      {!!options && <MobileNavigation navigationMap={options} />}
      {!!title && <HeaderTitle title={title} />}
      <div className="feed-filter-collapsed__buttons">
        {needSettings && (
          <Button className="search" onClick={handleShowSearch} variant="icon">
            <Icon type="search" />
          </Button>
        )}

        {isVisible ? (
          <MobileSearchRow
            placeholder={placeholder}
            onClose={handleCloseSearch}
            value={search}
            onChange={onChangeSearch}
          />
        ) : null}

        {needSettings && (
          <Button
            className="setting"
            onClick={() => {
              handleVisibleFilter(!isFilterOpened);
            }}
            variant="icon"
          >
            <Icon type="filterSettings" />
          </Button>
        )}

        {isAddButton ? (
          <Button disabled={clickAddDisabled} onClick={addClick} className="add" variant="icon">
            <Icon type={addButtonIcon} />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default MobileControls;
