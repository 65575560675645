import { catchWrapper } from '../../../helpers/utility';
import { clearGroundsImages } from '../../../api/v2';

export default (hash) => async (dispatch) => {
  await catchWrapper(
    clearGroundsImages({
      hash,
    }),
  );
  dispatch({ type: 'GROUNDS/NOT_SAVED_IMAGES_CLEAR' });
};
