import { createAction } from '@reduxjs/toolkit';
import { CometChat } from '@cometchat-pro/chat';
import { MSG_TYPES } from '../constants';

const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;

export const authSuccessAction = createAction(MSG_TYPES.AUTH_SUCCESS);
export const authFailAction = createAction(MSG_TYPES.AUTH_FAIL);

export const loginUserMsg = () => async (dispatch, getState) => {
  const { user: { chat } = {} } = getState().profile;
  CometChat.getLoggedinUser().then(
    (user) => {
      if (user) {
        dispatch(authSuccessAction(user));
      }
      if (!user) {
        CometChat.login(chat?.uid, AUTH_KEY).then(
          (u) => {
            dispatch(authSuccessAction(u));
            console.log('Login Successful:', { u });
          },
          (error) => {
            dispatch(authFailAction());
            console.log('Login failed with exception:', { error });
          },
        );
      }
    },
    (error) => {
      console.log('Some Error Occured', { error });
    },
  );
};
