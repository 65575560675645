import { batch } from 'react-redux';
import { catchWrapper } from '../../../helpers/utility';
import { saveSponsorLink } from '../../../api/v2';

export default (data) => async (dispatch, getState) => {
  const { sponsor } = getState().sponsor;

  dispatch({ type: 'SPONSOR/SAVE_SPONSOR_LINK/REQUESTED' });

  const result = await catchWrapper(saveSponsorLink({ id: sponsor.id, ...data }));

  if (result && result.result) {
    const newServices = { ...sponsor.social_service };
    newServices[data.service].value = data.value;

    batch(() => {
      dispatch({
        type: 'SPONSOR/SAVE_TEAM_LINK/SUCCEEDED',
        payload: newServices,
      });
    });
  } else {
    dispatch({
      type: 'SPONSOR/SAVE_SPONSOR_LINK/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
