import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routesMap } from './constants';
import useOwnerData from '../../hooks/useOwnerData';

import Icon from '../icon';
import Button from '../button';
import { useResponsive } from '../../hooks/useResponsive';
import Trans from '../trans';

const NavigationRoutes = ({ open, setOpen, closeMenu }) => {
  const { isDesktop, isMobile } = useResponsive();
  const [showQty, setShowQty] = useState(0);
  const { userId } = useOwnerData();
  const { t } = useTranslation();
  const onResize = () => {
    const height = document.getElementById('navigations').offsetHeight - 300;
    const qty = Math.floor(height / 55);
    setShowQty(qty > 3 ? qty : 3);
    if (document.getElementById('navigations'))
      document.getElementById('navigations').style.height = `${window.innerHeight}px`;
  };

  const filterRoutesMap = useMemo(
    () => routesMap(isMobile).filter((item) => (item.auth && userId) || (!userId && item.unAuth)),
    [userId],
  );

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const handleClose = useCallback(() => {
    if (closeMenu) {
      closeMenu();
    }
  }, [closeMenu]);

  return (
    <div className="navigations-routes">
      {filterRoutesMap?.map((item, index) => {
        if (item.notDesktop && isDesktop) {
          return null;
        }
        if (index + 1 <= showQty) {
          return (
            <NavLink
              onClick={handleClose}
              key={item.id}
              to={item.route}
              className="link"
              activeClassName="link--active"
            >
              <div className="link-row">
                <span className="link__label">{t(item.label)}</span>
              </div>
            </NavLink>
          );
        }
        if (index + 1 > showQty && open) {
          return (
            <NavLink
              onClick={handleClose}
              key={item.id}
              to={item.route}
              className="link"
              activeClassName="link--active"
            >
              <div className="link-row">
                <span className="link__label">{t(item.label)}</span>
              </div>
            </NavLink>
          );
        }
        return null;
      })}
      {showQty < filterRoutesMap?.length && (
        <div className="navigations-routes__else">
          <div className="row">
            <Button onClick={setOpen} variant="else" className={open && 'button--else__open'}>
              <Icon type="else" />
              <span className="row__text">
                {open ? Trans('navigation.hide') : Trans('navigation.more')}
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationRoutes;
