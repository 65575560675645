import React from 'react';

export default () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.96 8.36118C14.96 11.8707 12.0678 14.7157 8.5 14.7157C4.93224 14.7157 2.04 11.8707 2.04 8.36118C2.04 4.85169 4.93224 2.00668 8.5 2.00668C12.0678 2.00668 14.96 4.85169 14.96 8.36118ZM12.2007 15.8905C11.082 16.4235 9.82648 16.7224 8.5 16.7224C3.80559 16.7224 0 12.9789 0 8.36118C0 3.74343 3.80559 0 8.5 0C13.1944 0 17 3.74343 17 8.36118C17 10.9523 15.8018 13.2681 13.9207 14.8018L16.66 18.3946C16.998 18.8379 16.9067 19.4668 16.456 19.7993C16.0053 20.1317 15.366 20.0419 15.028 19.5986L12.2007 15.8905Z"
      fill="currentColor"
    />
  </svg>
);
