import React from 'react';

import './styles.scss';

const PriceCard = ({ value, label }) => {
  return (
    <div className="price-car-item">
      <span className="price-car-item__value">{`${value} ₽`}</span>
      <span className="price-car-item__label">{label}</span>
    </div>
  );
};

export default PriceCard;
