import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9843 23.1285H13.6C12.3399 23.1285 11.7098 23.1285 11.2285 22.8664C10.8052 22.6357 10.4609 22.2679 10.2452 21.8154C10 21.3009 10 20.6275 10 19.2807V12.8477C10 11.5009 10 10.8275 10.2452 10.313C10.4609 9.86055 10.8052 9.49269 11.2285 9.26212C11.7098 9 12.3399 9 13.6 9H24.4C25.6602 9 26.2901 9 26.7715 9.26212C27.1949 9.49269 27.5391 9.86055 27.7547 10.313C28 10.8275 28 11.5009 28 12.8477V19.2807C28 20.6275 28 21.3009 27.7547 21.8154C27.5391 22.2679 27.1949 22.6357 26.7715 22.8664C26.2901 23.1285 25.6602 23.1285 24.4 23.1285H22.2901C22.3641 23.6248 22.5858 24.2493 23.1512 24.8482C23.2902 24.9955 23.4184 25.1602 23.5092 25.3426C23.5994 25.524 23.6776 25.7751 23.6332 26.061C23.5342 26.6986 22.9805 26.8928 22.7273 26.9493C22.137 27.0809 21.2705 26.9448 20.4263 26.6736C19.5501 26.3921 18.5711 25.9243 17.7273 25.2692C17.0191 24.7193 16.3698 24.0082 15.9843 23.1285Z"
      fill="#C0B4AF"
    />
  </svg>
);
