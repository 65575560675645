import React from 'react';
import LoginMobile from './login/mobile';
import LoginDesktop from './login/desktop';
import { useResponsive } from '../../hooks/useResponsive';

import './styles.scss';

const AuthPage = () => {
  const { isDesktop, isTabletLarge } = useResponsive();

  return (
    <div className="auth-container">
      {!isDesktop && !isTabletLarge ? <LoginMobile /> : <LoginDesktop />}
    </div>
  );
};

export default AuthPage;
