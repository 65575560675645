import React from 'react';

import { useActions, useSelector } from '../../../../hooks/store';
import { geoActions } from '../../../../store/geo';

import SwitchSex from '../../../settings/components/switchSex';
import CustomInputRange from '../../../settings/components/inputRange';
import CustomCitySelect from '../../../../components/selectRegionCity';
import CustomSelect from '../../../../components/select';
import Icon from '../../../../components/icon';
import Button from '../../../../components/button';
import Trans from '../../../../components/trans';

const Filters = ({
  handleChangeGender,
  gender,
  handleChangeAge,
  age,
  handleChangeInput,
  handleChangeGeo,
  handleChangeCountry,
  geoLocal,
  rangeName,
  genderFilter,
  isRecruitment,
  minRangeValue,
  maxRangeValue,
  onChangeComplete,
  handleVisibleFilter,
  isMobile,
  recruitment,
  handleClearFilter,
  handleChangeRecruitment,
}) => {
  const { getCountry, getOpenCountry } = useActions(geoActions);
  const { countries, openCountries } = useSelector((state) => state.geo);

  const onMenuOpenCountry = () => getCountry();
  const onMenuOpenRegion = () => getOpenCountry(geoLocal.country);
  const textAge = {
    from: Trans('friends.filter.from'),
    to: Trans('friends.filter.to'),
  };

  return (
    <div className="filtersWrap">
      <div
        className="filtersWrap__filtersOverlay"
        onClick={() => {
          handleVisibleFilter();
        }}
      />

      <div className="filters">
        {isMobile && (
          <div className="filters__header">
            <div className="filters__header__SwipeRect" />
            <span className="filters__header__title">{Trans('friends.filter.filters')}</span>
            <span onClick={() => handleClearFilter()} className="filters__header__clear">
              {Trans('friends.filter.filterReset')}
            </span>
          </div>
        )}
        {genderFilter && (
          <div className="filters__gender">
            <span className="filters__label">{Trans('friends.filter.gender')}</span>
            <SwitchSex gender={gender} onChange={handleChangeGender} isFriends />
          </div>
        )}
        <div className="filters__age">
          <span className="filters__label">{rangeName}</span>
          <CustomInputRange
            value={age}
            onChange={handleChangeAge}
            minValue={minRangeValue || 18}
            maxValue={maxRangeValue || 100}
            text={textAge}
            onChangeInput={handleChangeInput}
            onChangeComplete={onChangeComplete}
          />
        </div>
        <div className="filters__country">
          <span className="filters__label">{Trans('common.country')}</span>
          <CustomSelect
            placeholder={Trans('common.countryPlaceholder')}
            name="country"
            asyncSelect
            options={countries && countries.options}
            handleChange={handleChangeCountry}
            onMenuOpen={onMenuOpenCountry}
            value={geoLocal.init ? geoLocal.country : null}
          />
        </div>
        <div className="filters__city">
          <span className="filters__label">{Trans('common.city')}</span>
          <CustomCitySelect
            placeholder={Trans('common.cityPlaceholder')}
            name="region"
            asyncSelect
            options={openCountries && openCountries.options}
            handleChange={handleChangeGeo}
            isDisabled={!geoLocal.country.id || false}
            onMenuOpen={onMenuOpenRegion}
            geoLocal={geoLocal}
          />
        </div>
        {isRecruitment && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div className="filters__recruitment" onClick={handleChangeRecruitment}>
            {recruitment ? <Icon type="check" /> : <Icon type="uncheck" />}
            <span className="filters__label">{Trans('teams.main.recruiting')}</span>
          </div>
        )}
        {isMobile && (
          <Button
            className="filters__result-button button-w100"
            onClick={() => handleVisibleFilter()}
          >
            {Trans('friends.filter.results')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Filters;
