import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { getImageFromFile } from '../../../../helpers/utility';
import ImagePicker from '../../../../components/imagePicker';
import './styles.scss';
import HintImage from '../../../components/hintImage';
import { Pages } from '../../../../routes/pages';
import Trans from '../../../../components/trans';

const ChevronAndPhoto = ({
  team,
  loading,
  isEditable,
  imagesState,
  handleChangeImages,
  errors,
}) => {
  const chevron = team?.images?.chevron?.preview;
  const photo = team?.images?.photo?.preview;

  const isSettingPages = !!useRouteMatch(Pages.team.settings.base);
  const isCreatePage = !!useRouteMatch(Pages.team.create.base);

  const tempChevron = imagesState?.chevron && getImageFromFile(imagesState.chevron.blob);
  const tempPhoto = imagesState?.photo && getImageFromFile(imagesState.photo.blob);

  const isHintChevron = (isSettingPages || isCreatePage) && !loading && !(tempChevron || chevron);
  const isHintPhoto = (isSettingPages || isCreatePage) && !loading && !(tempPhoto || photo);

  return (
    <div className="team-chevron-block">
      <div className="team-chevron__wrap">
        {isHintChevron ? (
          <HintImage
            label={Trans('teams.main.chevron')}
            width={500}
            height={500}
            error={errors?.chevron}
          />
        ) : null}
        {tempChevron || chevron ? <img src={tempChevron || chevron} alt="pic" /> : null}
        {isEditable ? (
          <ImagePicker
            onChange={handleChangeImages}
            aspectRatio={1}
            minWidth={500}
            minHeight={500}
            className="add-photo"
            fieldName="chevron"
          />
        ) : null}
      </div>
      <div className="team-chevron__chevron-wrap">
        {isHintPhoto ? (
          <HintImage
            label={Trans('teams.main.photo')}
            width={790}
            height={500}
            error={errors?.photo}
          />
        ) : null}
        {tempPhoto || photo ? <img src={tempPhoto || photo} alt="pic" /> : null}
        {isEditable ? (
          <ImagePicker
            onChange={handleChangeImages}
            aspectRatio={1.58}
            minWidth={790}
            minHeight={500}
            className="add-photo"
            fieldName="photo"
          />
        ) : null}
      </div>
    </div>
  );
};

export default ChevronAndPhoto;
