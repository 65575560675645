import React, { useEffect, useState } from 'react';
import './styles.scss';
import Button from '../button';

const ButtonGroup = ({ tags, handleChangeTags }) => {
  const [activeButtons, setActiveButtons] = useState(tags);
  useEffect(() => {
    setActiveButtons(tags);
  }, [tags]);
  const handleClick = (event, id) => {
    const changedButton = activeButtons.map((button) => {
      if (button.id === id) {
        return { ...button, active: !button.active };
      }
      return button;
    });
    setActiveButtons(changedButton);
    const tagsToSend = changedButton && changedButton.filter((tag) => tag?.active).map((t) => t.id);
    handleChangeTags(tagsToSend);
  };

  return (
    <div className="button-group">
      {activeButtons.map((button) => (
        <Button
          key={button?.id}
          name={button?.title}
          onClick={(event) => handleClick(event, button?.id)}
          className={button.active ? 'custom-button active' : 'custom-button'}
          variant="filter"
        >
          {button?.title}
        </Button>
      ))}
    </div>
  );
};

export default ButtonGroup;
