import React from 'react';
import { useParams } from 'react-router-dom';

import { Pages } from '../../../../../routes/pages';
import history from '../../../../../routes/history';
import { useResponsive } from '../../../../../hooks/useResponsive';

import RulesEvents from './sections/rules';
import AdditionalRulesText from './sections/rulesAdditional';
import AdditionalAgreementText from './sections/agreementsAdditional';
import SubNavigation from '../../../../components/subNavi';

import './styles.scss';

const naviMap = (location, id) => {
  return [
    {
      id: '1',
      label: 'Правила',
      route: {
        pathname: Pages.event.info.replace(':id', id),
        hash: '#rules',
        state: location.state,
      },
    },
    {
      id: '2',
      label: 'Правила доп.',
      route: {
        pathname: Pages.event.info.replace(':id', id),
        hash: '#rulesAdditional',
        state: location.state,
      },
    },
    // {
    //   id: '3',
    //   label: 'Соглашения доп.',
    //   route: {
    //     pathname: Pages.event.info.replace(':id', id),
    //     hash: '#agreementsAdditional',
    //     state: location.state,
    //   },
    // },
  ];
};

const renderSections = (hash) => {
  switch (hash) {
    case '#rules':
      return <RulesEvents />;
    case '#rulesAdditional':
      return <AdditionalRulesText />;
    case '#agreementsAdditional':
      return <AdditionalAgreementText />;
    default:
      return <RulesEvents />;
  }
};

const InfoEventPage = () => {
  const { location } = history;
  const { id } = useParams();
  const { isMobile } = useResponsive();
  return (
    <div className="org-page--agreements">
      {!isMobile && <SubNavigation naviMap={naviMap(location, id)} />}
      <div className="org-page--agreements__editor">{renderSections(location.hash, id)}</div>
    </div>
  );
};
export default InfoEventPage;
