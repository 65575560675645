import React from 'react';
import { useResponsive } from '../../hooks/useResponsive';
import PagesSectionMobile from './mobile';
import PagesSectionDesktop from './desktop';

const PagesSection = () => {
  const { isMobile } = useResponsive();

  return isMobile ? <PagesSectionMobile /> : <PagesSectionDesktop />;
};

export default PagesSection;
