const AUTH_DATA = 'authData';

export const localStorageService = {
  setAuthData: (authInfo) => {
    localStorage.setItem(AUTH_DATA, JSON.stringify(authInfo));
  },
  getAuthData: () => {
    const data = localStorage.getItem(AUTH_DATA);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },
  clearAuthData: () => {
    localStorage.removeItem(AUTH_DATA);
  },
};
