import { postSaveImage, postSaveImageArticle } from '../../api/v2';

export const FileUpload = async (file, page, id, type) => {
  const formData = new FormData();

  if (page) {
    formData.append('page_id', page);
  }
  if (id) {
    formData.append('parent_id', id);
  }
  if (type) {
    formData.append('type', type);
  }
  if (file) {
    formData.append('files[0]', file);
  }
  const result = await postSaveImage(formData).catch((error) => {
    console.error('Error:', error);
  });
  return result.data?.data;
};

export const FileUploadArticle = async (file, element) => {
  const formData = new FormData();
  if (element) {
    formData.append('element', JSON.stringify(element));
  }
  if (file) {
    formData.append('files[0]', file);
  }

  const result = await postSaveImageArticle(formData).catch((error) => {
    console.error('Error:', error);
  });
  return result?.data?.data;
};
