import React from 'react';

import { classNames as cx } from '../../../../helpers/utility';

import './styles.scss';

const HeaderTitle = ({ title, noWrap }) => {
  const classNames = cx('header__title', {
    'header__title--no-wrap': noWrap,
  });

  return <h3 className={classNames}>{title}</h3>;
};

export default HeaderTitle;
