import React from 'react';

export default () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill="#DE1F1F"
    />
    <path
      d="M28.5 19.134C29.1667 19.5189 29.1667 20.4811 28.5 20.866L16.5 27.7942C15.8333 28.1791 15 27.698 15 26.9282L15 13.0718C15 12.302 15.8333 11.8209 16.5 12.2058L28.5 19.134Z"
      fill="white"
    />
  </svg>
);
