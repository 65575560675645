import React from 'react';
import Avatar from '../../../components/avatar';
import { useSelector } from '../../../../hooks/store';
import NetworkState from '../../../../components/headerConstructor/components/networkState';

import './styles.scss';

function HeaderMobile() {
  const { receiver } = useSelector((state) => state.msg);
  return (
    <div className="msg-header-mobile">
      <Avatar image={receiver.avatar} />
      <div className="msg-header-mobile-info">
        <span className="msg-header-mobile__title">{receiver?.name}</span>
        <NetworkState
          className="msg-header-mobile__network"
          status={receiver?.status === 'online'}
        />
      </div>
    </div>
  );
}

export default HeaderMobile;
