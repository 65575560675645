import Menu from './menu';
import News from './news';
import Notifications from './notifications';
import Cross from './cross';
import Organizers from './organizers';
import OrganizersSmall from './organizersSmall';
import Blog from './blog';
import Blogs from './blogs';
import BlogsSmall from './blogsSmall';
import Teams from './teams';
import TeamsSmall from './teamsSmall';
import TeamLarge from './teamLarge';
import Camouflage from './camouflage';
import CamouflageSmall from './camouflageSmall';
import Chevron from './chevron';
import ChevronSmall from './chevron-small';
import Friends from './friends';
import FriendsSmall from './friendsSmall';
import Plus from './plus';
import Photo from './photo';
import Phone from './phone';
import Mail from './mail';
import MailLitt from './mailLitt';
import MailSmall from './mailSmall';
import Pass from './pass';
import Hide from './hide';
import Arrow from './arrow';
import NickName from './nickname';
import Show from './show';
import Profile from './profile';
import ProfileMobile from './profileMobile';
import Passport from './passport';
import Settings from './settings';
import Bid from './bid';
import BidSmall from './bidSmall';
import DoubleCross from './2cross';
import MoreMobile from './moreMobile';
import Add from './add';
import Else from './else';
import DownloadQr from './downloadQr';
import Chevron24 from './chevron24';
import Sponsor from './sponsor';
import Location from './location';
import Faq from './faq';

export default {
  downloadQr: DownloadQr,
  menu: Menu,
  news: News,
  notifications: Notifications,
  cross: Cross,
  organizers: Organizers,
  organizersSmall: OrganizersSmall,
  blog: Blog,
  blogs: Blogs,
  blogsSmall: BlogsSmall,
  teams: Teams,
  teamsSmall: TeamsSmall,
  teamLarge: TeamLarge,
  camouflage: Camouflage,
  camouflageSmall: CamouflageSmall,
  chevron: Chevron,
  chevronSmall: ChevronSmall,
  friends: Friends,
  friendsSmall: FriendsSmall,
  plus: Plus,
  photo: Photo,
  phone: Phone,
  mail: Mail,
  mailLitt: MailLitt,
  mailSmall: MailSmall,
  pass: Pass,
  hide: Hide,
  arrow: Arrow,
  nickName: NickName,
  show: Show,
  profile: Profile,
  profileMobile: ProfileMobile,
  passport: Passport,
  settings: Settings,
  bid: Bid,
  bidSmall: BidSmall,
  doubleCross: DoubleCross,
  moreMobile: MoreMobile,
  add: Add,
  else: Else,
  chevron24: Chevron24,
  sponsor: Sponsor,
  location: Location,
  faq: Faq,
  faqSmall: Faq,
};
