import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import { isEmpty } from 'lodash';
import { useActions, useSelector } from '../../../../hooks/store';
import { groundsActions } from '../../../../store/grounds';
import { geoActions } from '../../../../store/geo';
import CustomInput from '../../../../components/input';
import DynamicLabelRow from '../../../components/dynamicLabelRow';
import CustomSelect from '../../../../components/select';
import ImageSettings from './components/imagesSettings';
import CustomPhoneInput from '../../../../components/customPhoneInput';
import { goToGrounds } from '../../../../routes/actions';
import { globalCancelFetch } from '../../../../api/v2';
import JoditEditor from '../../../../components/textEditor2';
import './styles.scss';
import Trans from '../../../../components/trans';

const initGeo = {
  country: {},
  region: {},
  city: {},
};
const GroundsSettings = (props) => {
  const { id } = useParams();
  const editor = useRef(null);
  const { ground, groundSnapshot, error, loading } = useSelector((state) => state?.grounds);
  const { user, userSponsors } = useSelector((state) => state?.profile);

  const { countries, regions, cities } = useSelector((state) => state?.geo);
  const { clearGrounds, clearNotSavedImages, getGround, onChangeField, onChangeError } = useActions(
    groundsActions,
  );
  const { getCity, getRegion, getCountry } = useActions(geoActions);
  const [geoLocal, setGeoLocal] = useState(initGeo);
  const [hash, setHash] = useState(ground?.hash ? ground.hash : uuid());

  if (
    !isEmpty(user) &&
    !loading &&
    (!userSponsors ||
      !userSponsors.length ||
      !userSponsors[0].is_ground ||
      (userSponsors[0].user_role !== 'commander' && userSponsors[0].user_role !== 'deputy') ||
      (userSponsors[0].id !== ground?.owner?.id && ground?.id))
  ) {
    goToGrounds();
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    onChangeField(name, value);
    onChangeError({
      ...error,
      [name]: null,
    });
  };
  const handleChangePhone = (value) => {
    onChangeField('phone', value);
    onChangeError({
      ...error,
      phone: null,
    });
  };
  const handleChangeProfileInput = (e) => {
    const { name, value } = e.target;
    onChangeField(name, value, true);
    onChangeError({
      ...error,
      [name]: null,
    });
  };
  const handleChangeEditor = (value) => {
    onChangeField('description', value, true);
  };

  const handleChangeCountrySelect = (value) => {
    const location = { country: value, region: {}, city: {} };

    onChangeField('location', location);
    onChangeError({
      ...error,
      country: null,
    });
  };
  const handleChangeRegionSelect = (value) => {
    const location = { ...ground.location, region: value, city: {} };

    onChangeField('location', location);
    onChangeError({
      ...error,
      region: null,
    });
  };
  const handleChangeCitySelect = (value) => {
    const location = { ...ground.location, city: value };

    onChangeField('location', location);
    onChangeError({
      ...error,
      city: null,
    });
  };
  const onMenuOpenCountry = () => getCountry();
  const onMenuOpenRegion = () => getRegion(geoLocal.country);
  const onMenuOpenCity = () => getCity(geoLocal.country, geoLocal.region);

  useEffect(() => {
    setGeoLocal({ ...ground.location });
  }, [ground.location]);

  useEffect(() => {
    setHash(ground?.hash ? ground.hash : uuid());
  }, [ground]);

  useEffect(() => {
    if (id) getGround({ id });
  }, [id]);

  useEffect(() => {
    onChangeField('hash', hash);
    return () => {
      clearNotSavedImages(hash);
      clearGrounds();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  return (
    <div className="grounds-settings">
      <ImageSettings id={id} hash={hash} error={error?.images} />
      <div className="grounds-settings__left">
        <CustomInput
          name="title"
          label="fields.settings.title"
          labelPosition="top"
          placeholder="fields.settings.titlePlaceholder"
          value={ground?.profile?.title}
          onChange={handleChangeProfileInput}
          maxLength="32"
          error={error?.title}
          required
        />
        <CustomPhoneInput
          name="phone"
          label="common.phone"
          placeholder="common.phonePlaceholder"
          onChange={handleChangePhone}
          value={ground?.phone}
          error={error?.phone}
        />
        <CustomInput
          name="email"
          label="common.email"
          labelPosition="top"
          placeholder="common.emailPlaceholder"
          value={ground?.email}
          onChange={handleChangeInput}
          maxLength="32"
          error={error?.email}
          required
        />
        <CustomInput
          name="gps"
          label="common.locationGPS"
          labelPosition="top"
          placeholder="common.locationGPSPlaceholder"
          value={ground?.gps}
          onChange={handleChangeInput}
          maxLength="32"
          error={error?.gps}
          required
        />
      </div>
      <div className="grounds-settings__right">
        <DynamicLabelRow page="profile" label={Trans('common.country')}>
          <CustomSelect
            placeholder={ground?.location?.country?.title || Trans('common.countryPlaceholder')}
            name="country"
            asyncSelect
            value={geoLocal?.country || null}
            options={countries?.options}
            handleChange={handleChangeCountrySelect}
            onMenuOpen={onMenuOpenCountry}
            required
            labelPosition="top"
            error={error?.country}
          />
        </DynamicLabelRow>
        <DynamicLabelRow page="profile" label={Trans('common.region')}>
          <CustomSelect
            placeholder={ground?.location?.region?.title || Trans('common.regionPlaceholder')}
            name="region"
            asyncSelect
            value={geoLocal?.region || null}
            options={regions?.options}
            handleChange={handleChangeRegionSelect}
            isDisabled={!geoLocal?.country?.id}
            onMenuOpen={onMenuOpenRegion}
            required
            labelPosition="top"
            error={error?.region}
          />
        </DynamicLabelRow>
        <DynamicLabelRow page="profile" label={Trans('common.city')}>
          <CustomSelect
            placeholder={ground?.location?.city?.title || Trans('common.cityPlaceholder')}
            name="city"
            asyncSelect
            options={cities?.options}
            value={geoLocal?.city || null}
            handleChange={handleChangeCitySelect}
            isDisabled={!geoLocal?.region?.id}
            onMenuOpen={onMenuOpenCity}
            required
            labelPosition="top"
            error={error?.city}
          />
        </DynamicLabelRow>
      </div>
      <div className="editor__wrapper">
        <JoditEditor
          value={ground?.profile?.description}
          snapshotValue={groundSnapshot?.profile?.description}
          ref={editor}
          onChange={handleChangeEditor}
          onBlur={handleChangeEditor}
          pageId="1"
          parentId={id}
          type="ground"
          languageId={1}
        />
      </div>
    </div>
  );
};

export default GroundsSettings;
