import React from 'react';

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C10.6734 0 9.6 1.0746 9.6 2.4V9.6H2.4C1.0734 9.6 0 10.6746 0 12C0 13.3252 1.0734 14.4 2.4 14.4H9.6V21.6C9.6 22.2832 9.886 22.8996 10.3406 23.3366C10.7718 23.7476 11.3578 24 12 24C12.7548 24 13.4252 23.6524 13.8656 23.1094C14.1984 22.6968 14.4 22.172 14.4 21.6V14.4H21.6C22.1858 14.4 22.7252 14.189 23.1422 13.8386C23.6672 13.398 24 12.7378 24 12C24 10.6746 22.9264 9.6 21.6 9.6H14.4V2.4C14.4 1.0746 13.3264 0 12 0Z"
      fill="currentColor"
    />
  </svg>
);
