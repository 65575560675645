import React from 'react';

export default () => (
  <svg width="54" height="31" viewBox="0 0 54 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.779 17.9667C35.5608 16.2037 37.3907 13.2114 37.3907 9.81668C37.3907 4.39507 32.7232 0 26.9656 0C21.208 0 16.5406 4.39507 16.5406 9.81668C16.5406 13.2114 18.3707 16.2037 21.1523 17.9667C19.6484 18.4658 18.2319 19.1719 16.9859 20.0937C14.1583 22.1856 12.2828 25.3413 12.2828 29.45C12.2828 30.306 13.0198 31 13.9289 31H40.2659V30.9802C41.0497 30.8614 41.6486 30.2216 41.6486 29.4499C41.6486 25.3412 39.7731 22.1855 36.9453 20.0937C35.6996 19.1719 34.283 18.4658 32.779 17.9667ZM8.6221 31H1.43751C0.643597 31 0 30.3966 0 29.6522C0 26.0794 1.63785 23.3353 4.10727 21.5162C5.19535 20.7147 6.43242 20.1007 7.74577 19.6667C5.31649 18.1337 3.71835 15.5317 3.71835 12.5797C3.71835 7.86528 7.79446 4.04348 12.8226 4.04348C13.694 4.04348 14.5368 4.15826 15.3347 4.37259C14.3764 6.09812 13.837 8.04647 13.837 10.1087C13.837 12.6861 14.6795 15.0857 16.1294 17.0946C15.6818 17.3922 15.2471 17.7098 14.827 18.0474C11.1934 20.9683 8.75062 25.3347 8.6221 31ZM44.5142 31H52.5626C53.3565 31 54 30.3966 54 29.6522C54 26.0794 52.3622 23.3353 49.8928 21.5162C48.8047 20.7147 47.5676 20.1007 46.2542 19.6667C48.6836 18.1337 50.2817 15.5317 50.2817 12.5797C50.2817 7.86528 46.2056 4.04348 41.1775 4.04348C40.3147 4.04348 39.48 4.15601 38.689 4.36626C39.6627 6.09335 40.2107 8.04395 40.2107 10.1087C40.2107 12.8065 39.2751 15.3093 37.6771 17.3735C37.9709 17.5885 38.2585 17.813 38.5392 18.0474C42.0382 20.9683 44.3904 25.3347 44.5142 31Z"
      fill="currentColor"
    />
  </svg>
);
