import { catchWrapper } from '../../../helpers/utility';
import { postSaveSponsorProfile } from '../../../api/v2';

export default () => async (dispatch, getState) => {
  const { sponsor, sponsorImageTemp } = getState().sponsor;

  const formData = new FormData();

  formData.append('element', JSON.stringify(sponsor));

  if (sponsorImageTemp.image) {
    const { blob, file } = sponsorImageTemp.image;
    formData.append('imageBlob', blob);
    formData.append('imageFile', file);
  }
  if (sponsorImageTemp.flag) {
    const { blob, file } = sponsorImageTemp.flag;
    formData.append('flagBlob', blob);
    formData.append('flagFile', file);
  }

  dispatch({ type: 'SPONSOR/SAVE_SPONSOR_PROFILE/REQUESTED' });

  const result = await catchWrapper(postSaveSponsorProfile(formData));

  if (result && result.result) {
    dispatch({ type: 'SPONSOR/SAVE_SPONSOR_PROFILE/SUCCEEDED' });
  } else {
    dispatch({
      type: 'SPONSOR/SAVE_SPONSOR_PROFILE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
