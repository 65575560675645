import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1591 28.0667C18.8837 28.0667 24.3182 19.0624 24.3182 15.8381C24.3182 12.6138 21.5607 10 18.1591 10C14.7575 10 12 12.6138 12 15.8381C12 19.0624 17.4345 28.0667 18.1591 28.0667ZM18.1579 18.242C19.2917 18.242 20.2109 17.3195 20.2109 16.1815C20.2109 15.0435 19.2917 14.121 18.1579 14.121C17.024 14.121 16.1048 15.0435 16.1048 16.1815C16.1048 17.3195 17.024 18.242 18.1579 18.242Z"
      fill="currentColor"
    />
  </svg>
);
