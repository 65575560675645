import React from 'react';
import { Link } from 'react-router-dom';

import { useActions } from '../../../hooks/store';
import { msgActions } from '../../../store/msg';
import { useResponsive } from '../../../hooks/useResponsive';
import history from '../../../routes/history';
import { classNames as cx, getUrl } from '../../../helpers/utility';
import { Pages } from '../../../routes/pages';

import Icon from '../../../components/icon';
import FriendsBlock from '../profileFriendsBlock';
import Skeleton from '../../../components/skeleton';
import SkeletonFriends from './skeletonFriends';
import AccountHolder from '../../../helpers/accountHolder';
import isAccountHolder from '../../../hooks/isAccountHolder';
import ButtonsBlock from './buttonsBlock';

import dummy from '../../../assets/image/user.svg';

import './styles.scss';

const Owner = ({
  user,
  userId,
  selfId,
  loading,
  friends,
  totalFriends,
  sentFriendRequest,
  isFriend,
  isRequestWasSent,
  removeFriend,
  getUserSilent,
  loadingSilent,
  cancelFriendRequest,
  qtyMutualFriends,
  totalFriendsNumber,
}) => {
  const { setReceiverInfo } = useActions(msgActions);
  const { isMobile } = useResponsive();
  const { location } = history;
  const { accountHolder } = isAccountHolder();
  const { nickname: userNickname, image_avatar: imageAvatar, location: loc, age, id } = user;
  const city = loc?.city?.title;

  const urlPreview = imageAvatar && imageAvatar.preview ? `${imageAvatar.preview}` : dummy;

  const classNames = cx('owner__photo', {
    owner__photo_dummy: !(imageAvatar && imageAvatar.preview),
  });

  const qrLink = getUrl(location?.state || id, Pages.profile.qr);

  const nickname = userNickname ?? 'Позывной';
  const classNamesNick = cx('nick-fs', {
    'nick-fs_21': nickname?.length === 13,
    'nick-fs_19': nickname?.length === 14,
    'nick-fs_17': nickname?.length === 15,
    'nick-fs_15': nickname?.length === 16,
  });

  return (
    <div className="card owner-block">
      <div className="owner-block__top">
        <div className="owner-photo__wrap">
          {loading ? (
            <Skeleton className="profile-avatar" />
          ) : (
            <img className={classNames} src={urlPreview} alt="" />
          )}
        </div>

        {loading ? (
          <>
            <Skeleton className="sk-owner-title" />
            <Skeleton className="sk-owner-info" />
          </>
        ) : (
          <div className="owner-block__user-info-wrap">
            <h3 className={classNamesNick}>{nickname}</h3>
            {city || age ? (
              <>
                {city ? <span>{city}</span> : null}
                {age ? <span>{age}</span> : null}
              </>
            ) : (
              <AccountHolder>
                <Link className="owner-block__user-no-data" to={Pages.settings.profile}>
                  Укажите город и возраст
                </Link>
              </AccountHolder>
            )}
          </div>
        )}

        <ButtonsBlock
          isFriend={isFriend}
          isRequestWasSent={isRequestWasSent}
          loading={loading}
          accountHolder={accountHolder}
          sentFriendRequest={sentFriendRequest}
          removeFriend={removeFriend}
          getUserSilent={getUserSilent}
          loadingSilent={loadingSilent}
          cancelFriendRequest={cancelFriendRequest}
          setReceiverInfo={setReceiverInfo}
          chatUid={user?.chat?.uid}
        />

        <div className="playing-role__wrap">
          <div className="playing-role__elem">
            <Icon type={user?.player_role || 'stormtrooper'} />
          </div>
        </div>
        {isMobile && (
          <Link className="qr-code__wrap" to={qrLink}>
            <div className="qr-code__elem">
              <Icon type="qr" />
            </div>
          </Link>
        )}
      </div>

      {!!friends?.length && (
        <div className="owner-block__bottom">
          {loading ? (
            <SkeletonFriends />
          ) : (
            <FriendsBlock
              userId={userId}
              selfId={selfId}
              qtyMutualFriends={qtyMutualFriends}
              totalFriendsNumber={totalFriendsNumber}
              friends={friends}
              totalFriends={totalFriends}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Owner;
