import { getBloggerEvents } from '../../api/v2';
import { catchWrapper } from '../../helpers/utility';

export const loadOptionsBlogger = async (search, prevOptions, { page }) => {
  const payloadRequest = {
    page,
    objects_per_page: 20,
    filter: {
      search,
    },
  };

  const result = await catchWrapper(getBloggerEvents(payloadRequest));
  const { pagination, events } = result && result;
  const remappedEvents = events?.map((event) => ({
    id: event?.id,
    value: event?.id,
    title: event?.profile?.title,
  }));
  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  return {
    options: remappedEvents || [],
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

export default loadOptionsBlogger;
