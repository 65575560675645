import React from 'react';
import ImagePicker from '../../../../components/imagePicker';
import { getImageFromFile } from '../../../../helpers/utility';
import CustomInput from '../../../../components/input';
import './styles.scss';

const BlockSettingMain = ({
  blog,
  blogImageTemp,
  handleChangeInput,
  isEditable,
  handleChangeImages,
  newBlog,
}) => {
  const tempImage = getImageFromFile(blogImageTemp?.blob);
  const image = blog?.image?.image;
  return (
    <div className="card blog-setting__card">
      <div className="image-container">
        <div className="image-main__wrap">
          {tempImage || image ? <img src={tempImage || image} alt="pic" /> : null}
          {isEditable ? (
            <ImagePicker
              onChange={handleChangeImages}
              aspectRatio={1}
              minHeight={360}
              minWidth={360}
              fieldName="image"
              className="add-photo"
            />
          ) : null}
        </div>
      </div>
      <CustomInput
        name="title"
        label="Название блога"
        labelPosition="top"
        value={newBlog?.profile?.title || blog?.profile?.title}
        onChange={handleChangeInput}
      />
      <CustomInput
        name="sign"
        label="Подпись"
        labelPosition="top"
        value={newBlog?.profile?.sign || blog?.profile?.sign}
        onChange={handleChangeInput}
      />
    </div>
  );
};
export default BlockSettingMain;
