import React from 'react';

export default () => (
  <svg
    width="242"
    height="242"
    viewBox="0 0 242 242"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="170.88"
      height="170.88"
      rx="24"
      transform="matrix(-0.707327 0.706886 0.707327 0.706886 120.868 0)"
      fill="url(#paint0_linear)"
    />
    <path
      d="M112.759 148C112.676 146.411 111.808 145.045 110.563 144.253L110.562 144.253C109.81 143.775 108.916 143.499 107.966 143.499C105.431 143.499 103.276 145.472 103.172 148.052C100.513 148.342 98.2031 149.823 96.8311 151.963L96.8294 151.965C96.1412 153.042 95.6865 154.286 95.545 155.621C95.5126 155.925 95.5 156.217 95.5 156.5V184.998C95.5 189.746 99.4162 193.499 104.134 193.499H137.866C142.584 193.499 146.5 189.746 146.5 184.998V156.5C146.5 154.217 145.582 152.148 144.115 150.631L144.111 150.628C142.745 149.221 140.893 148.278 138.828 148.052C138.724 145.472 136.569 143.499 134.034 143.499C131.516 143.499 129.372 145.446 129.241 148H112.759Z"
      fill="white"
      stroke="#FFB801"
      strokeWidth="5"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="22.9642"
        y1="0.780271"
        x2="83.5516"
        y2="151.049"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6E6E6" />
        <stop offset="0.807192" stopColor="#E6E6E6" />
        <stop offset="0.807292" stopColor="#FFB800" />
        <stop offset="1" stopColor="#FFB800" />
      </linearGradient>
    </defs>
  </svg>
);
