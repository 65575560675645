import getTextPage from './impl/getTextPage';

const initState = {
  loading: 0,
  error: null,
  title: null,
  text: null,
  menu: null,
};

export const textPageReducer = (state = null, action) => {
  switch (action.type) {
    case 'TEXT/REQUESTED': {
      return {
        ...state,
        loading: 1,
      };
    }
    case 'TEXT/SUCCEEDED': {
      return {
        ...state,
        loading: 0,
        title: action.payload.title,
        text: action.payload.text,
        menu: action.payload.menu,
      };
    }
    case 'TEXT/FAILED': {
      return {
        ...state,
        loading: 0,
      };
    }
    case 'TEXT/CLEAR': {
      return {
        ...initState,
      };
    }
    default:
      return state;
  }
};

const onTextPageClear = () => (dispatch) => {
  dispatch({
    type: 'TEXT/CLEAR',
  });
};

export const textPageActions = {
  getTextPage,
  onTextPageClear,
};
