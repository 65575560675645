import { catchWrapper } from '../../../helpers/utility';
import { postSocial } from '../../../api';

export default () => async (dispatch) => {
  dispatch({ type: 'AUTH/PROVIDERS/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'getProviders');

  const result = await catchWrapper(postSocial('social_get_providers', formData));

  if (result) {
    dispatch({ type: 'AUTH/PROVIDERS/SUCCEEDED', payload: result.providers });
  } else {
    dispatch({ type: 'AUTH/PROVIDERS/FAILED' });
  }
};
