import onCheckImage from './impl/onCheckImage';

export const imagePickerReducer = (state = null, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const imagePickerActions = {
  onCheckImage,
};
