import React from 'react';

export default () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4074 11C9.63012 11 9 11.6127 9 12.3684V22.6316C9 23.3874 9.63012 24 10.4074 24H26.5926C27.3699 24 28 23.3874 28 22.6316V12.3684C28 11.6127 27.3699 11 26.5926 11H10.4074ZM20.963 15.1053C20.5743 15.1053 20.2593 15.4116 20.2593 15.7895C20.2593 16.1673 20.5743 16.4737 20.963 16.4737H24.4815C24.8701 16.4737 25.1852 16.1673 25.1852 15.7895C25.1852 15.4116 24.8701 15.1053 24.4815 15.1053H20.963ZM20.2593 19.2105C20.2593 18.8326 20.5743 18.5263 20.963 18.5263H24.4815C24.8701 18.5263 25.1852 18.8326 25.1852 19.2105C25.1852 19.5884 24.8701 19.8947 24.4815 19.8947H20.963C20.5743 19.8947 20.2593 19.5884 20.2593 19.2105ZM17.2297 16.5877C17.2297 17.3371 16.8355 17.9972 16.2354 18.3865C16.5597 18.4969 16.8658 18.6526 17.1344 18.8559C17.7442 19.3179 18.1481 20.0139 18.1481 20.921C18.1481 20.9762 18.1347 21.0279 18.1106 21.0738C18.0596 21.1708 17.9643 21.2423 17.8505 21.2632H12.1703C11.9746 21.2632 11.8148 21.1097 11.8148 20.921C11.8148 20.0139 12.2201 19.3179 12.8298 18.8559C13.0984 18.6526 13.4033 18.4969 13.7288 18.3865C13.1288 17.9972 12.7332 17.3371 12.7332 16.5877C12.7332 15.3913 13.7395 14.4211 14.9821 14.4211C16.2232 14.4211 17.2297 15.3913 17.2297 16.5877Z"
      fill="#C0B4AF"
    />
  </svg>
);
