import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { eventsActions } from '../../../../../store/events';
import { useActions, useSelector } from '../../../../../hooks/store';
import { getDangerouslyHtml } from '../../../../../helpers/utility';
import { useResponsive } from '../../../../../hooks/useResponsive';

const Offer = () => {
  const { id } = useParams();
  const { isMobile } = useResponsive();
  const { onGetEventOffer } = useActions(eventsActions);
  const { eventSettings } = useSelector((state) => state.events);
  const offer = eventSettings?.offer;

  useEffect(() => {
    if (id) {
      onGetEventOffer(Number(id), '1');
    }
  }, [id]);

  return (
    <div className="events-text">
      {!isMobile && <div className="info-caption">{offer?.title || ''}</div>}
      {offer && (
        <div
          className="info-text"
          dangerouslySetInnerHTML={getDangerouslyHtml(offer?.text || '')}
        />
      )}
    </div>
  );
};

export default Offer;
