import React, { useEffect } from 'react';
import CustomSelect from '../../../../components/select';
import CustomInput from '../../../../components/input';
import CustomDatePicker from '../../../../components/datePicker';

const ControlsSettingMain = ({
  sponsor,
  handleChangeSelect,
  geo,
  onMenuOpenCountry,
  onMenuOpenRegion,
  onMenuOpenCity,
  handleChangeDate,
  errors,
}) => {
  const { countries, regions, cities } = geo;

  const initDate = new Date();
  const selectedDate = sponsor?.date ? new Date(sponsor?.date) : null;

  useEffect(() => {
    if (!sponsor?.date) {
      handleChangeDate(initDate);
    }
  }, [sponsor]);

  return (
    <div className="sponsor-page--settings__controls">
      <div className="sponsor-page--settings__editor-container">
        <CustomSelect
          required
          label="Страна"
          labelPosition="top"
          placeholder="Начните вводить"
          value={sponsor?.location?.country}
          name="country"
          asyncSelect
          options={countries?.options}
          handleChange={(value) => handleChangeSelect(value, 'country')}
          onMenuOpen={onMenuOpenCountry}
          defaultValue="123"
          error={errors?.country}
        />
        <CustomSelect
          required
          label="Регион"
          labelPosition="top"
          placeholder="Начните вводить"
          value={sponsor?.location?.region}
          name="region"
          asyncSelect
          isDisabled={!sponsor?.location?.country?.id}
          options={regions?.options}
          handleChange={(value) => handleChangeSelect(value, 'region')}
          onMenuOpen={onMenuOpenRegion}
          defaultValue="123"
          error={errors?.region}
        />
        <CustomSelect
          required
          label="Город"
          labelPosition="top"
          placeholder="Начните вводить"
          value={sponsor?.location?.city}
          name="city"
          asyncSelect
          isDisabled={!sponsor?.location?.region?.id}
          options={cities?.options}
          handleChange={(value) => handleChangeSelect(value, 'city')}
          onMenuOpen={onMenuOpenCity}
          defaultValue="123"
          error={errors?.city}
        />
        <CustomDatePicker
          selected={selectedDate}
          onChange={handleChangeDate}
          name="date"
          customInput={
            <CustomInput
              required
              error={errors?.date}
              label="Дата основания *"
              labelPosition="top"
              name="date"
            />
          }
        />
      </div>
    </div>
  );
};

export default ControlsSettingMain;
