import React, { memo } from 'react';

import { Pages } from '../../../routes/pages';

import AwardsBlock from './awardsBlock';

import './styles.scss';

const Awards = ({
  userId,
  achievements,
  qtyOfAchievements,
  awards,
  qtyOfAwards,
  titles,
  qtyOfTitles,
}) => {
  return (
    <div className="card awards-section">
      <AwardsBlock
        type="achievements"
        link={Pages.profile.achievements.replace(':id', userId)}
        title="Достижения"
        count={qtyOfAchievements}
        collection={achievements}
      />
      <AwardsBlock
        type="awards"
        link={Pages.profile.awards.replace(':id', userId)}
        title="Награды"
        count={qtyOfAwards}
        collection={awards}
      />
      <AwardsBlock
        type="titles"
        link={Pages.profile.titles.replace(':id', userId)}
        title="Звания"
        count={qtyOfTitles}
        collection={titles}
      />
    </div>
  );
};

export default memo(Awards);
