import React, { memo } from 'react';

import { classNames as cx } from '../../../../helpers/utility';

import PanelFilter from '../panel-filter';
import Button from '../../../button';
import Icon from '../../../icon';

import './styles.scss';

function PanelControls({ onClick, open, setFilter, selectedFilter }) {
  const classNames = cx('panel-control', {
    'panel-control--open': open,
  });

  const classNamesToggle = cx('panel-control__toggle', {
    'panel-control__toggle--open': open,
  });

  return (
    <div className={classNames}>
      {open ? <PanelFilter setFilter={setFilter} selectedFilter={selectedFilter} /> : null}
      <Button onClick={onClick} variant="icon" className={classNamesToggle}>
        <Icon type="chevron" />
      </Button>
    </div>
  );
}

export default memo(PanelControls);
