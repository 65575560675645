import getBlogs from './impl/getBlogs';
import clearBlogs from './impl/clearBlogs';
import getBlogSubscribes from './impl/getBlogSubscribes';

const initState = {
  loading: 0,
  blogs: [],
  error: null,
};

export const blogsReducer = (state = null, action) => {
  switch (action.type) {
    case 'BLOGS/LOAD_ALL_BLOGS/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case 'BLOGS/LOAD_ALL_BLOGS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        blogs: [...state.blogs, ...action.payload.items],
        pagination: action.payload.pagination,
      };
    }
    case 'BLOGS/LOAD_ALL_BLOGS/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: action.payload,
      };
    }
    case 'BLOGS/CLEAR': {
      return initState;
    }
    default:
      return state;
  }
};

export const blogsActions = {
  getBlogs,
  clearBlogs,
  getBlogSubscribes,
};
