import React from 'react';

import { useTranslation } from 'react-i18next';
import Icon from '../icon';
import Button from '../button';

import './styles.scss';

const InputSearch = ({ onChange, value }) => {
  const { t } = useTranslation();
  const handleChangeInput = (e) => {
    onChange(e.target.value);
  };
  const clearValue = () => onChange('');

  return (
    <div className="search__container">
      <input
        onChange={handleChangeInput}
        value={value}
        className="search__input"
        placeholder={t('navigation.findPlaceholder')}
      />
      {!!value && (
        <Button className="search__clear" onClick={clearValue}>
          &times;
        </Button>
      )}
      <div className="search__btn">
        <Icon type="search" />
      </div>
    </div>
  );
};

export default InputSearch;
