import { catchWrapper } from '../../../helpers/utility';
import { writeComments } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ text, id, contentType }) => async (dispatch) => {
  const payloadRequest = {
    text,
    id: Number(id),
    type: contentType,
  };
  dispatch({ type: 'SOCIAL/WRITE_COMMENT/REQUESTED' });
  const result = await catchWrapper(writeComments(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'SOCIAL/WRITE_COMMENT/SUCCEEDED/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'SOCIAL/WRITE_COMMENT/FAILED',
      payload: result && result?.message && result.message[0],
    });
  }
};
