import { catchWrapper } from '../../../helpers/utility';
import { getUserVideoList } from '../../../api/v2';
import { goToNotFound } from '../../../routes/actions';

export default ({ page = 1, id }) => async (dispatch) => {
  const payloadRequest = {
    page,
    objects_per_page: 20,
    user_id: Number(id),
    // type: parentType,
  };
  dispatch({ type: 'SOCIAL/GET_USER_VIDEO_LIST/REQUESTED' });
  const result = await catchWrapper(getUserVideoList(payloadRequest));
  if (result && result?.result) {
    dispatch({
      type: 'SOCIAL/GET_USER_VIDEO_LIST/SUCCEEDED',
      payload: result,
    });
  } else {
    goToNotFound();
    dispatch({
      type: 'SOCIAL/GET_USER_VIDEO_LIST/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
