import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3901 11.3333C21.3901 13.5235 20.2685 15.454 18.5634 16.5914C19.4852 16.9134 20.3535 17.369 21.1171 17.9636C22.8504 19.3132 24 21.3491 24 23.9999C24 24.4978 23.6329 24.9106 23.1525 24.9872V25H7.00899C6.45171 25 6 24.5523 6 24C6 21.3492 7.14957 19.3133 8.88286 17.9636C9.64654 17.369 10.5149 16.9134 11.4366 16.5914C9.73157 15.454 8.60987 13.5235 8.60987 11.3333C8.60987 7.83554 11.4708 5 15 5C18.5292 5 21.3901 7.83554 21.3901 11.3333Z"
      fill="currentColor"
    />
  </svg>
);
