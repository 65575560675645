import React, { useEffect, useRef, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames as cx } from '../../helpers/utility';
import Icon from '../icon';

import './styles.scss';

const CustomInput = ({
  className,
  label,
  labelPosition,
  placeholder,
  type,
  prefix,
  value,
  onChange,
  name,
  textRows,
  extendedLabel,
  error,
  required,
  ...props
}) => {
  const [showPass, setShowPass] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation();
  const classNames = cx('input__wrap', className, {
    [`input__${type}`]: type,
    'input-prefix__wrap': prefix,
  });

  const classNamesContainer = cx('input__container', {
    [`input__container__label_${labelPosition}`]: labelPosition,
    [`input__${name}`]: name,
    'input__container--error': error,
  });

  const classNamesLabel = cx('input__label', {
    [`input__label_${labelPosition}`]: labelPosition,
    'input__label--error': error,
    'input__label--required': required,
  });

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  };

  const checkedValue = !value || value === 'null' ? '' : value;

  useEffect(() => {
    const target = ref && ref.current;
    if (target) {
      // eslint-disable-next-line no-unused-expressions
      showPass ? target.setAttribute('type', 'text') : target.setAttribute('type', 'password');
    }
  }, [showPass]);

  const customLabel = required ? `${t(label)} *` : t(label);

  return (
    <div className={classNamesContainer}>
      {(customLabel || (error && required)) && (
        <span className={classNamesLabel}>{error || customLabel}</span>
      )}
      <div className={classNames}>
        {prefix ? <span className="input__prefix">{prefix}</span> : null}
        {extendedLabel && <span className="extended-label">{extendedLabel}</span>}
        {type === 'password' && (
          <input
            ref={ref}
            className="input"
            placeholder={t(placeholder)}
            type={type}
            name={name}
            value={checkedValue}
            onChange={onChange}
            {...props}
            autoComplete="on"
          />
        )}

        {type === 'textarea' && (
          <textarea
            className="textarea"
            placeholder={t(placeholder)}
            name={name}
            value={checkedValue}
            onChange={onChange}
            rows={textRows || 8}
            {...props}
          />
        )}

        {type !== 'password' && type !== 'textarea' && (
          <input
            autoComplete="on"
            className="input"
            placeholder={t(placeholder)}
            type={type}
            name={name}
            value={checkedValue}
            onChange={onChange}
            {...props}
          />
        )}
        {type === 'password' ? (
          // eslint-disable-next-line react/button-has-type
          <button
            className="hide-pass"
            style={{ outline: 'none' }}
            tabIndex="-2"
            onClick={(e) => handleShowPassword(e)}
          >
            {showPass ? <Icon type="show" /> : <Icon type="hide" />}
          </button>
        ) : null}
      </div>
    </div>
  );
};
export default memo(CustomInput);
