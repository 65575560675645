import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';

import HeaderConstructor from '../../../components/headerConstructor';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';
import HeaderTitle from '../../../components/headerConstructor/components/title';

import history from '../../../routes/history';

import './styles.scss';
import BlogPage from './main';
import { useResponsive } from '../../../hooks/useResponsive';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import SettingBlogPage from './settingMain';
import BlogSettingLinks from './settingLinks';
import NewArticlePage from './newArticle';
import CreateBlogPage from './createBlog';
import ArticlePage from './article';
import EditArticlePage from './editArticle';

const BlogSectionDesktop = ({
  onSubmit,
  isSettingPages,
  isDisabled,
  isSettingLinksPages,
  isCreatingBlogPage,
}) => {
  const { location } = history;
  const { isTabletSmall, isDesktop, isTabletLarge } = useResponsive();
  const handleGoToMainPage = () => {
    if (location.pathname === Pages.blog.createBlog) {
      history.push({
        pathname: Pages.pages.category,
      });
    } else {
      history.push({
        pathname: Pages.blog.main.replace(':id', location.state),
        state: location.state,
      });
    }
  };

  return (
    <>
      <HeaderConstructor className="blog">
        <HeaderLeft>
          {!isDesktop ? <SidebarButton /> : null}
          <HeaderTitle title="Блог" />
          {/*{isShowSearch ? <InputSearch value={search} onChange={handleChangeInput} /> : null}*/}
        </HeaderLeft>
        <HeaderRight>
          {!isSettingPages && !isCreatingBlogPage ? (
            <>
              <Controls isShowLang />
              <Menu />
            </>
          ) : null}
          {isSettingPages || isCreatingBlogPage ? (
            <>
              {isSettingLinksPages ? null : (
                <Button disabled={isDisabled} onClick={onSubmit} variant="save">
                  {isCreatingBlogPage ? 'Создать блог' : 'Сохранить'}
                </Button>
              )}
              <Button onClick={handleGoToMainPage} className="go-back" variant="icon">
                <Icon type="add" />
              </Button>
            </>
          ) : null}
        </HeaderRight>
        {/*{isSettingPages ? (*/}
        {/*  <HeaderBottom>*/}
        {/*    {isDesktop ? <Navigation naviMap={navigationMap} /> : null}*/}
        {/*    {isTabletLarge ? (*/}
        {/*      <TabletNavigation naviMap={navigationMap} howManyLinksToShow={5} />*/}
        {/*    ) : null}*/}
        {/*    {isTabletSmall ? (*/}
        {/*      <TabletNavigation naviMap={navigationMap} howManyLinksToShow={2} />*/}
        {/*    ) : null}*/}
        {/*  </HeaderBottom>*/}
        {/*) : null}*/}
      </HeaderConstructor>
      <div className="page-container">
        <Switch>
          <PrivateRoute exact path={Pages.blog.articleEdit} component={EditArticlePage} />
          <PrivateRoute exact path={Pages.blog.createArticle} component={NewArticlePage} />
          <PrivateRoute path={Pages.blog.main} component={BlogPage} />
          <PrivateRoute path={Pages.blog.article} component={ArticlePage} />
          <PrivateRoute exact path={Pages.blog.settings.main} component={SettingBlogPage} />
          <PrivateRoute exact path={Pages.blog.settings.links} component={BlogSettingLinks} />
          <PrivateRoute exact path={Pages.blog.createBlog} component={CreateBlogPage} />
          <Redirect to={Pages.blog.all} />
        </Switch>
      </div>
    </>
  );
};

export default BlogSectionDesktop;
