import React from 'react';

import './styles.scss';

const CardPriceView = ({ price, defaultPrice }) => {
  return (
    <div className="card-price-view">
      {!price ? <span>Бесплатная игра</span> : null}
      {price ? (
        <div className="card-price-view__wrap">
          <span className="card-price-view__price">{`${price} ₽`}</span>
          {defaultPrice ? (
            <span className="card-price-view__default-price">{`(на полигоне ${defaultPrice} ₽)`}</span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const MiddleBlock = ({ price, defaultPrice, sponsor, eventTitle, type, size }) => {
  return (
    <div className="middle-block">
      <div className="middle-block__wrap">
        <div className="middle-block__left">
          <CardPriceView price={price || ''} defaultPrice={defaultPrice || ''} />
          <span className="middle-block__sponsor">{sponsor || ''}</span>
        </div>
        <div className="middle-block__right">
          <span className="middle-block__event-type">{type?.title}</span>
          <span className="middle-block__event-size">{`от ${size} чел.`}</span>
        </div>
      </div>
      <span className="middle-block__event-title">{eventTitle || ''}</span>
    </div>
  );
};
export default MiddleBlock;
