import React from 'react';
import { useSelector } from '../../hooks/store';
import { useResponsive } from '../../hooks/useResponsive';
import './styles.scss';
import MobileFeedPage from './mobileFeed';
import FeedGeneral from './feedGeneral';

const FeedPage = () => {
  const userData = useSelector((state) => state.profile.user);
  const { isMobile, isDesktop, isHD, isTablet } = useResponsive();
  return (
    <div className="page-feed">
      {isMobile && <MobileFeedPage userData={userData} />}
      {!isMobile && <FeedGeneral userData={userData} />}
    </div>
  );
};

export default FeedPage;
