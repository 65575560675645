import React from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import SubNavigation from '../../../components/subNavi';
import { Pages } from '../../../../routes/pages';
import history from '../../../../routes/history';
import Rules from './sections/rules';
import History from './sections/history';
import Demands from './sections/demands';
import { useResponsive } from '../../../../hooks/useResponsive';
import Trans from '../../../../components/trans';

const naviMap = (location, id) => [
  {
    id: '1',
    label: Trans('organizers.about.history'),
    route: {
      pathname: Pages.organizers.about.replace(':id', id),
      hash: '#history',
      state: location.state,
    },
  },
  {
    id: '2',
    label: Trans('organizers.about.rules'),
    route: {
      pathname: Pages.organizers.about.replace(':id', id),
      hash: '#rules',
      state: location.state,
    },
  },
  {
    id: '3',
    label: Trans('organizers.about.requirements'),
    route: {
      pathname: Pages.organizers.about.replace(':id', id),
      hash: '#demands',
      state: location.state,
    },
  },
];

const renderSections = (hash, sponsorId) => {
  switch (hash) {
    case '#history':
      return <History sponsorId={sponsorId} />;
    case '#rules':
      return <Rules sponsorId={sponsorId} />;
    case '#demands':
      return <Demands sponsorId={sponsorId} />;
    default:
      return <History sponsorId={sponsorId} />;
  }
};

const AboutOrgPage = () => {
  const { location } = history;
  const { id } = useParams();
  const { isMobile } = useResponsive();
  return (
    <div className="org-page--rules">
      {!isMobile && <SubNavigation naviMap={naviMap(location, id)} />}
      <div className="org-page--rules__editor">{renderSections(location.hash, id)}</div>
    </div>
  );
};
export default AboutOrgPage;
