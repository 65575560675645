import { catchWrapper } from '../../../helpers/utility';
import { getBlogArticleTypes } from '../../../api/v2';

export default () => async (dispatch) => {
  dispatch({ type: 'BLOG/LOAD_BLOG_ARTICLES_TYPES/REQUESTED' });

  const result = await catchWrapper(getBlogArticleTypes());
  if (result && result.result) {
    dispatch({ type: 'BLOG/LOAD_BLOG_ARTICLES_TYPES/SUCCEEDED', payload: result.items });
  } else {
    dispatch({
      type: 'BLOG/LOAD_BLOG_ARTICLES_TYPES/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
