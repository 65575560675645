import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useActions, useSelector } from '../../../../hooks/store';

import { groundsActions } from '../../../../store/grounds';

import { useResponsive } from '../../../../hooks/useResponsive';
import useDebounce from '../../../../hooks/useDebounce';

import useVisible from '../../../../hooks/useVisible';
import HeaderTitle from '../../../../components/headerConstructor/components/title';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import MobileSearchRow from '../../../components/mobile-controls/searchRow';
import SkeletonGrid from '../../../../components/skeletonsGrid';
import GroundCard from '../groundCard';

import './sk-styles.scss';
import './styles.scss';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import Trans from '../../../../components/trans';

const GroundsList = ({ search, onChangeSearch }) => {
  const [page, setPage] = useState(1);
  const { getGrounds, clearGrounds } = useActions(groundsActions);
  const { grounds, pagination, loading } = useSelector((state) => state.grounds);
  const { userSponsors } = useSelector((state) => state.profile);

  const { isMobile, is2KHeight } = useResponsive();
  const { isVisible, setIsVisible } = useVisible(false);

  let canUserCreate = false;
  if (
    userSponsors &&
    userSponsors.length &&
    userSponsors[0].is_ground &&
    (userSponsors[0].user_role === 'commander' || userSponsors[0].user_role === 'deputy')
  ) {
    canUserCreate = true;
  }

  const debouncedSearchTerm = useDebounce(search, 500);

  const handleShowSearch = () => setIsVisible(true);
  const handleCloseSearch = () => setIsVisible(false);
  const handleOpenAddGround = () => {
    history.push({
      pathname: Pages.grounds.create,
    });
  };

  const asyncRequests = async () => {
    if (is2KHeight) {
      clearGrounds();
      await getGrounds({ page: 1, query: debouncedSearchTerm || null });
      await getGrounds({ page: 2, query: debouncedSearchTerm || null });
      await getGrounds({ page: 3, query: debouncedSearchTerm || null });
      setPage(3);
    } else {
      clearGrounds();
      setPage(1);
      getGrounds({ page: 1, query: debouncedSearchTerm || null });
    }
  };

  useEffect(() => {
    asyncRequests();
  }, [debouncedSearchTerm]);

  const fetchPaginated = () => {
    setPage(page + 1);
    getGrounds({ page: page + 1, query: debouncedSearchTerm || null });
  };

  const hasMore =
    (pagination && Number(pagination.current_page)) <
    (pagination && Number(pagination.total_pages));

  useEffect(
    () => () => {
      clearGrounds();
    },
    [],
  );

  return (
    <div className="grounds">
      {isMobile ? (
        <div className="grounds--mobile-control">
          <HeaderTitle title={Trans('fields.pageTitle')} />
          <Button className="search" onClick={handleShowSearch} variant="icon">
            <Icon type="search" />
          </Button>
          {canUserCreate && (
            <Button onClick={handleOpenAddGround} variant="icon">
              <Icon type="add" />
            </Button>
          )}
          {isVisible ? (
            <MobileSearchRow
              placeholder={Trans('fields.search')}
              onClose={handleCloseSearch}
              value={search}
              onChange={onChangeSearch}
            />
          ) : null}
        </div>
      ) : null}
      {loading && !hasMore ? (
        <SkeletonGrid classNameElement="sk-grounds--element" classNameGrid="sk-grounds--grid" />
      ) : (
        <InfiniteScroll
          dataLength={grounds && grounds.length}
          next={fetchPaginated}
          hasMore={hasMore}
          loader={<></>}
          className=""
        >
          {isMobile || !canUserCreate ? null : (
            <div onClick={handleOpenAddGround} role="button" className="add-grounds">
              <Icon type="plusLarge" />
              <span className="add-grounds__text">{Trans('fields.addNew')}</span>
            </div>
          )}

          {grounds && grounds.map((ground) => <GroundCard key={ground.id} ground={ground} />)}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default GroundsList;
