import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';

import { useActions, useSelector } from '../../../../hooks/store';
import { geoActions } from '../../../../store/geo';
import { eventsActions } from '../../../../store/events';

import CustomCitySelect from '../../../../components/selectRegionCity';
import CustomSelect from '../../../../components/select';

import Button from '../../../../components/button';
import MultiSwitch from '../../../../components/multiSwitch';
import Trans from '../../../../components/trans';
import ButtonArrow from '../../../../components/button/buttonArrow';

import './styles.scss';
import Icon from '../../../../components/icon';

const Filters = ({
  handleChangeType,
  type,
  handleChangeSize,
  size,
  handleChangeGeo,
  handleChangeCountry,
  geoLocal,
  handleVisibleFilter,
  isMobile,
  handleClearFilter,
  handleClearCountry,
  sizes,
  handleChangeDuration,
  duration,
  durations,
  handleChangeYear,
  handleChangeMonth,
  year,
  month,
}) => {
  const yearsRef = useRef();
  const monthsRef = useRef();
  const { getCountry, getOpenCountry } = useActions(geoActions);
  const { countries, openCountries } = useSelector((state) => state.geo);
  const { getEventTypes, getEventYears } = useActions(eventsActions);
  const { types, years, yearsLoading} = useSelector((state) => state.events);
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12];

  const onMenuOpenCountry = () => getCountry();
  const onMenuOpenRegion = () => getOpenCountry(geoLocal.country);

  const initialYear = !yearsLoading ? years.findIndex((el) => el * 1 === year * 1) : 0;
  const initialMonth = months.findIndex((el) => el * 1 === month * 1);

  const baseSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <Button variant="icon">
        <Icon type="arrRight" />
      </Button>
    ),
    prevArrow: (
      <Button variant="icon">
        <Icon type="arrLeft" />
      </Button>
    ),
    centerMode: true,
    variableWidth: true,
    focusOnSelect: true,
  };

  const settingYears = {
    ...baseSettings,
    afterChange: (index) => {
      handleChangeYear(years[index]);
    },
  };

  const settingMonths = {
    ...baseSettings,
    slidesToShow: 5,
    afterChange: (index) => {
      handleChangeMonth(months[index]);
    },
  };

  useEffect(() => {
    getEventTypes();
    getEventYears();
  }, []);

  useEffect(() => {
    if (!yearsLoading && yearsRef?.current) {
      yearsRef.current.slickGoTo(initialYear);
    }
    if (monthsRef.current) monthsRef.current.slickGoTo(initialMonth);
  }, [yearsLoading]);

  return (
    <div className="filtersWrap">
      <div
        className="filtersWrap__filtersOverlay"
        onClick={() => {
          handleVisibleFilter();
        }}
      />
      <div className="filters">
        {isMobile && (
          <div className="filters__header">
            <div className="filters__header__SwipeRect" />
            <span className="filters__header__title">{Trans('events.filter.filters')}</span>
            <span onClick={() => handleClearFilter()} className="filters__header__clear">
              Очистить
            </span>
          </div>
        )}
        <div className="filters_element">
          <span className="filters__label">{Trans('events.filter.type')}</span>
          <MultiSwitch currentValue={type} onChange={handleChangeType} values={types} />
        </div>
        <div className="filters_element">
          <span className="filters__label">{Trans('events.filter.size')}</span>
          <MultiSwitch currentValue={size} onChange={handleChangeSize} values={sizes} single />
        </div>
        <div className="filters_element">
          <span className="filters__label">{Trans('events.filter.duration')}</span>
          <MultiSwitch
            currentValue={duration}
            onChange={handleChangeDuration}
            values={durations}
            single
          />
        </div>
        <div className="filters_element">
          <span className="filters__label">{Trans('events.filter.region')}</span>
          <CustomSelect
            placeholder="Страна"
            name="country"
            asyncSelect
            isOpen
            options={countries && countries.options}
            handleChange={handleChangeCountry}
            onMenuOpen={onMenuOpenCountry}
            value={geoLocal.init ? geoLocal.country : null}
            handleClear={handleClearCountry}
          />
          <CustomCitySelect
            placeholder="Область / Город"
            name="region"
            asyncSelect
            options={openCountries && openCountries.options}
            handleChange={handleChangeGeo}
            isDisabled={!geoLocal?.country?.id || false}
            onMenuOpen={onMenuOpenRegion}
            geoLocal={geoLocal}
          />
        </div>
        <div className="filter-sliders">
          {years.length && (
            <Slider {...settingYears} className="years" ref={yearsRef}>
              {years?.map((item) => (
                <div className="element" id={item}>
                  {item}
                </div>
              ))}
              <div className="bugfix" />
              <div className="bugfix" />
            </Slider>
          )}
          <Slider {...settingMonths} className="months" ref={monthsRef}>
            {months?.map((item) => (
              <div className="element" id={item}>
                {Trans(`months.month${item}`)}
              </div>
            ))}
            <div className="bugfix" />
            <div className="bugfix" />
            <div className="bugfix" />
            <div className="bugfix" />
          </Slider>
        </div>
        {isMobile && (
          <Button
            className="filters__result-button button-w100"
            onClick={() => handleVisibleFilter()}
          >
            {Trans('events.filter.results')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Filters;
