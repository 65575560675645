import uuid from 'react-uuid';

import getAllSponsors from './impl/getAllSponsors';
import getSponsor from './impl/getSponsor';
import getSponsorMembers from './impl/getSponsorMembers';
import getSponsorParticipants from './impl/getSponsorPaticipants';
import getSponsorIncomingRequest from './impl/getSponsorIncomingRequest';
import onGetSponsorSettingsHistory from './impl/onGetSponsorSettingsHistory';
import onSaveSponsorSettingsHistory from './impl/onSaveSponsorSettingsHistory';
import onGetSponsorSettingsRules from './impl/onGetSponsorSettingsRules';
import onSaveSponsorSettingsRules from './impl/onSaveSponsorSettingsRules';
import onSaveSponsorSettingsDemands from './impl/onSaveSponsorSettingsDemands';
import onGetSponsorSettingsDemands from './impl/onGetSponsorSettingsDemands';
import onGetSponsorSettingsEntry from './impl/onGetSponsorSettingsEntry';
import onSaveSponsorSettingsEntry from './impl/onSaveSponsorSettingsEntry';
import onSaveSponsorSettingsPersonalData from './impl/onSaveSponsorSettingsPersonalData';
import onGetSponsorSettingsPersonalData from './impl/onGetSponsorSettingsPersonalData';
import onSaveSponsorSettingsResponsibility from './impl/onSaveSponsorSettingsResponsibility';
import onGetSponsorSettingsResponsibility from './impl/onGetSponsorSettingsResponsibility';
import changeSponsorText from './impl/changeSponsorText';
import onSaveSponsorProfile from './impl/onSaveSponsorProfile';
import onSaveSponsorLegal from './impl/onSaveSponsorLegal';
import onChangeMembers from './impl/onChangeMembers';
import onChangeRole from './impl/onChangeRole';
import onSaveSponsorService from './impl/onSaveSponsorService';
import onSaveSponsorMembers from './impl/onSaveSponsorMembers';
import requestJoinSponsor from './impl/requestJoinSponsor';
import cancelRequestJoinSponsor from './impl/cancelRequestJoinSponsor';
import onChangeParticipants from './impl/onChangeParticipants';
import onChangeParticipantsRequest from './impl/onChangeParticipantsRequest';
import onCreate from './impl/onCreate';
import onChangeField from './impl/onChangeField';
import onChangeLegalField from './impl/onChangeLegalField';
import { sponsorTypeEnum } from '../../helpers/enums';

export const sponsorReducer = (state = null, action) => {
  switch (action.type) {
    case 'SPONSOR/LOAD_SPONSOR_PARTICIPANTS/REQUESTED':
    case 'SPONSOR/LOAD_SPONSOR/REQUESTED':
    case 'SPONSOR/SAVE_TEXT_HISTORY/REQUESTED':
    case 'SPONSOR/SAVE_TEXT_ENTRY/REQUESTED':
    case 'SPONSOR/SAVE_TEXT_RESPONSIBILITY/REQUESTED':
    case 'SPONSOR/SAVE_TEXT_DATA/REQUESTED':
    case 'SPONSOR/GET_TEXT_DATA/REQUESTED':
    case 'SPONSOR/GET_TEXT_RESPONSIBILITY/REQUESTED':
    case 'SPONSOR/GET_TEXT_HISTORY/REQUESTED':
    case 'SPONSOR/GET_TEXT_ENTRY/REQUESTED':
    case 'SPONSOR/SAVE_TEXT_DEMANDS/REQUESTED':
    case 'SPONSOR/SAVE_TEXT_RULES/REQUESTED':
    case 'SPONSOR/GET_TEXT_DEMANDS/REQUESTED':
    case 'SPONSOR/GET_TEXT_RULES/REQUESTED':
    case 'SPONSOR/LOAD_ALL_SPONSORS/REQUESTED':
    case 'SPONSOR/LOAD_SPONSOR_MEMBERS/REQUESTED':
    case 'SPONSOR/LOAD_TEAM_REQUESTS/REQUESTED':
      return {
        ...state,
        loading: state.loading + 1,
      };

    case 'SPONSOR/CREATE_SPONSOR/REQUESTED':
    case 'SPONSOR/SAVE_SPONSOR_PROFILE/REQUESTED':
    case 'SPONSOR/SAVE_SPONSOR_LINK/REQUESTED':
    case 'SPONSOR/CANCEL_REQUEST_JOIN_SPONSOR/REQUESTED':
    case 'SPONSOR/REQUEST_JOIN_SPONSOR/REQUESTED':
    case 'SPONSOR/SAVE_SPONSOR_MEMBERS/REQUESTED':
    case 'SPONSOR/ON_CHANGE_PARTICIPANTS/REQUESTED':
    case 'SPONSOR/ON_CHANGE_PARTICIPANTS_REQUEST/REQUESTED':
    case 'SPONSOR/SAVE_SPONSOR_LEGAL/REQUESTED':
      return {
        ...state,
        loadingSilent: state.loadingSilent + 1,
      };

    case 'SPONSOR/CREATE_SPONSOR/FAILED':
    case 'SPONSOR/SAVE_SPONSOR_PROFILE/FAILED':
    case 'SPONSOR/SAVE_SPONSOR_LINK/FAILED':
    case 'SPONSOR/CANCEL_REQUEST_JOIN_SPONSOR/FAILED':
    case 'SPONSOR/REQUEST_JOIN_SPONSOR/FAILED':
    case 'SPONSOR/SAVE_SPONSOR_MEMBERS/FAILED':
    case 'SPONSOR/ON_CHANGE_PARTICIPANTS/FAILED':
    case 'SPONSOR/ON_CHANGE_PARTICIPANTS_REQUEST/FAILED':
    case 'SPONSOR/SAVE_SPONSOR_LEGAL/FAILED':
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };

    case 'SPONSOR/ON_CHANGE_PARTICIPANTS_REQUEST/SUCCEEDED': {
      const filtered = state.sponsorRequests.filter((f) => f.id !== action.payload);
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        sponsorRequests: filtered,
      };
    }

    case 'SPONSOR/ON_CHANGE_PARTICIPANTS/SUCCEEDED': {
      const { currentType, newType, vipType, teamId } = action.payload;

      let newState = null;

      if (newType === sponsorTypeEnum.remove) {
        const filtered = state.sponsorParticipants[currentType].filter((f) => f.id !== teamId);
        newState = {
          ...state.sponsorParticipants,
          [currentType]: filtered,
        };
      } else {
        const currentTeam = state.sponsorParticipants[currentType].find((f) => f.id === teamId);
        const filtered = state.sponsorParticipants[currentType].filter((f) => f.id !== teamId);
        const newList = vipType
          ? [...state.sponsorParticipants[vipType], currentTeam]
          : [...state.sponsorParticipants[newType], currentTeam];

        const typeName = vipType || newType;

        newState = {
          ...state.sponsorParticipants,
          [currentType]: filtered,
          [typeName]: newList,
        };
      }

      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        sponsorParticipants: newState,
        sponsorParticipantsSnapshot: newState,
      };
    }

    case 'SPONSOR/SAVE_SPONSOR_MEMBERS/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        sponsorMembersSnapshot: state.sponsorMembers,
      };
    }
    case 'SPONSOR/CANCEL_REQUEST_JOIN_SPONSOR/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };
    }
    case 'SPONSOR/REQUEST_JOIN_SPONSOR/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };
    }
    case 'SPONSOR/SAVE_TEAM_LINK/SUCCEEDED':
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        sponsor: {
          ...state.sponsor,
          social_service: action.payload,
        },
      };

    case 'SPONSOR/LOAD_SPONSOR_PARTICIPANTS/FAILED':
    case 'SPONSOR/LOAD_SPONSOR_MEMBERS/FAILED':
    case 'SPONSOR/GET_TEXT_HISTORY/FAILED':
    case 'SPONSOR/GET_TEXT_RESPONSIBILITY/FAILED':
    case 'SPONSOR/SAVE_TEXT_HISTORY/FAILED':
    case 'SPONSOR/SAVE_TEXT_RESPONSIBILITY/FAILED':
    case 'SPONSOR/SAVE_TEXT_DATA/FAILED':
    case 'SPONSOR/SAVE_TEXT_RULES/FAILED':
    case 'SPONSOR/GET_TEXT_DATA/FAILED':
    case 'SPONSOR/GET_TEXT_RULES/FAILED':
    case 'SPONSOR/GET_TEXT_ENTRY/FAILED':
    case 'SPONSOR/GET_TEXT_DEMANDS/FAILED':
    case 'SPONSOR/SAVE_TEXT_ENTRY/FAILED':
    case 'SPONSOR/SAVE_TEXT_DEMANDS/FAILED':
    case 'SPONSOR/LOAD_SPONSOR/FAILED':
    case 'SPONSOR/LOAD_ALL_SPONSORS/FAILED':
    case 'SPONSOR/LOAD_TEAM_REQUESTS/FAILED':
      return {
        ...state,
        loading: state.loading - 1,
      };
    case 'SPONSOR/LOAD_ALL_SPONSORS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorList: [...state.sponsorList, ...action.payload.sponsors],
        pagination: action.payload.pagination,
      };
    }
    case 'SPONSOR/LOAD_TEAM_REQUESTS/SUCCEEDED':
      return {
        ...state,
        loading: state.loading - 1,
        sponsorRequests: action.payload,
      };
    case 'SPONSOR/LOAD_SPONSOR/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsor: action.payload,
        sponsorSnapshot: action.payload,
      };
    }

    case 'SPONSOR/CREATE_SPONSOR/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
      };
    }

    case 'SPONSOR/SAVE_SPONSOR_LEGAL/SUCCEEDED':
    case 'SPONSOR/SAVE_SPONSOR_PROFILE/SUCCEEDED': {
      return {
        ...state,
        loadingSilent: state.loadingSilent - 1,
        sponsorSnapshot: state.sponsor,
        sponsorImageTemp: {
          image: null,
          flag: null,
        },
      };
    }

    case 'SPONSOR/LOAD_SPONSOR_MEMBERS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorMembers: action.payload,
        sponsorMembersSnapshot: action.payload,
      };
    }
    case 'SPONSOR/LOAD_SPONSOR_PARTICIPANTS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorParticipants: action.payload,
        sponsorParticipantsSnapshot: action.payload,
      };
    }
    case 'SPONSOR/GET_TEXT_HISTORY/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorSettings: {
          ...state.sponsorSettings,
          historyText: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'SPONSOR/GET_TEXT_ENTRY/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorSettings: {
          ...state.sponsorSettings,
          entry: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'SPONSOR/GET_TEXT_RULES/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorSettings: {
          ...state.sponsorSettings,
          rules: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'SPONSOR/GET_TEXT_DEMANDS/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorSettings: {
          ...state.sponsorSettings,
          demands: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'SPONSOR/GET_TEXT_RESPONSIBILITY/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorSettings: {
          ...state.sponsorSettings,
          responsibility: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'SPONSOR/GET_TEXT_DATA/SUCCEEDED': {
      return {
        ...state,
        loading: state.loading - 1,
        sponsorSettings: {
          ...state.sponsorSettings,
          data: { text: action.payload, snapshot: action.payload },
        },
      };
    }

    case 'SPONSOR/CLEAR_SPONSOR_MEMBERS': {
      return {
        ...state,
        sponsorMembers: action.payload,
        sponsorMembersSnapshot: action.payload,
      };
    }
    case 'SPONSOR/CLEAR_SPONSOR_PARTICIPANTS': {
      return {
        ...state,
        sponsorParticipants: action.payload,
        sponsorParticipantsSnapshot: action.payload,
      };
    }
    case 'SPONSOR/CLEAR_SPONSOR': {
      return {
        ...state,
        sponsor: action.payload,
        sponsorSnapshot: action.payload,
      };
    }

    case 'SPONSOR/CLEAR_SPONSOR_LIST': {
      return {
        ...state,
        sponsorList: [],
      };
    }

    case 'SPONSOR/CHANGE_TEXT': {
      return {
        ...state,
        sponsorSettings: {
          ...state.sponsorSettings,
          [action.payload.field]: {
            ...state.sponsorSettings[action.payload.field],
            text: action.payload.text,
          },
        },
      };
    }

    case 'SPONSOR/CHANGE_SPONSOR_FIELD': {
      const { field, value } = action.payload;

      return {
        ...state,
        sponsor: {
          ...state.sponsor,
          [field]: value,
        },
      };
    }

    case 'SPONSOR/CHANGE_PROFILE_FIELD': {
      const { field, value } = action.payload;

      return {
        ...state,
        sponsor: {
          ...state.sponsor,
          profile: {
            ...state.sponsor.profile,
            [field]: value === '' ? null : value,
          },
        },
      };
    }

    case 'SPONSOR/CHANGE_ABOUT_FIELD': {
      return {
        ...state,
        sponsor: {
          ...state.sponsor,
          about: action.payload,
        },
      };
    }

    case 'SPONSOR/CHANGE_LOCATION': {
      const { field, value } = action.payload;
      return {
        ...state,
        sponsor: {
          ...state.sponsor,
          location: {
            ...state.sponsor.location,
            [field]: value,
          },
        },
      };
    }

    case 'SPONSOR/CHANGE_DATE': {
      const { value } = action.payload;
      return {
        ...state,
        sponsor: {
          ...state.sponsor,
          date: value,
        },
      };
    }

    case 'SPONSOR/CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }
    case 'SPONSOR/CHANGE_LEGAL_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        sponsor: {
          ...state.sponsor,
          legal: {
            ...state.sponsor.legal,
            [field]: value,
          },
        },
      };
    }
    case 'SPONSOR/CHANGE_SPONSOR_MEMBER': {
      return {
        ...state,
        sponsorMembers: action.payload,
      };
    }

    case 'SPONSOR/REMOVE_MEMBERS_GROUP': {
      const { groupId } = action.payload;
      const newGroups = state.sponsorMembers.groups.map((g) => {
        if (g.id === groupId) {
          return {
            ...g,
            remove: true,
          };
        }
        return g;
      });

      return {
        ...state,
        sponsorMembers: {
          ...state.sponsorMembers,
          groups: newGroups,
        },
      };
    }

    case 'SPONSOR/ADD_MEMBERS_GROUP': {
      const { title } = action.payload;
      const newItem = {
        id: uuid(),
        title,
        remove: false,
        commander: null,
        users: [],
      };
      const groups = [...state.sponsorMembers.groups, newItem];

      return {
        ...state,
        sponsorMembers: {
          ...state.sponsorMembers,
          groups,
        },
      };
    }

    case 'TEAMS/CHANGE_MEMBERS_GROUP_TITLE': {
      const { groupIndex, title } = action.payload;

      const groups = [...state.sponsorMembers.groups];
      const newGroups = groups.map((g, ind) => {
        if (groupIndex === ind) {
          return {
            ...g,
            title,
          };
        }
        return {
          ...g,
        };
      });

      return {
        ...state,
        sponsorMembers: {
          ...state.sponsorMembers,
          groups: newGroups,
        },
      };
    }

    case 'SPONSOR/CHANGE_USER_ROLE': {
      return {
        ...state,
        sponsorMembers: action.payload,
      };
    }

    default:
      return state;
  }
};

const onClearSponsorMembers = () => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CLEAR_SPONSOR_MEMBERS',
    payload: {
      commander: null,
      deputy: null,
      users: null,
      groups: null,
    },
  });
};

const onClearSponsorParticipants = () => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CLEAR_SPONSOR_PARTICIPANTS',
    payload: {
      gold: null,
      silver: null,
      bronze: null,
      members: null,
      guests: null,
    },
  });
};

const onClearSponsor = () => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CLEAR_SPONSOR',
  });
};

const onClearSponsorList = () => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CLEAR_SPONSOR_LIST',
  });
};

const onChangeProfile = (field, value) => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CHANGE_PROFILE_FIELD',
    payload: { field, value },
  });
};

const onChangeSponsorField = (field, value) => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CHANGE_SPONSOR_FIELD',
    payload: { field, value },
  });
};

const onChangeAbout = (value) => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CHANGE_ABOUT_FIELD',
    payload: value,
  });
};

const onChangeLocation = (field, value) => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CHANGE_LOCATION',
    payload: { field, value },
  });
};

const onChangeDate = (value) => (dispatch) => {
  dispatch({
    type: 'SPONSOR/CHANGE_DATE',
    payload: { value },
  });
};

const onRemoveMembersGroup = (groupId) => (dispatch) => {
  dispatch({
    type: 'SPONSOR/REMOVE_MEMBERS_GROUP',
    payload: {
      groupId,
    },
  });
};

const onAddMembersGroup = (title) => (dispatch) => {
  dispatch({
    type: 'SPONSOR/ADD_MEMBERS_GROUP',
    payload: {
      title,
    },
  });
};

const onChangeMembersGroupTitle = (groupIndex, title) => (dispatch) => {
  dispatch({
    type: 'TEAMS/CHANGE_MEMBERS_GROUP_TITLE',
    payload: {
      groupIndex,
      title,
    },
  });
};

export const sponsorAction = {
  getAllSponsors,
  getSponsorIncomingRequest,
  onChangeSponsorField,
  getSponsor,
  onChangeField,
  getSponsorMembers,
  onChangeRole,
  onRemoveMembersGroup,
  onAddMembersGroup,
  onChangeMembersGroupTitle,
  onClearSponsor,
  onClearSponsorList,
  onClearSponsorMembers,
  onClearSponsorParticipants,
  onChangeProfile,
  onChangeDate,
  onChangeLocation,
  onChangeAbout,
  getSponsorParticipants,
  onSaveSponsorSettingsHistory,
  onGetSponsorSettingsHistory,
  onGetSponsorSettingsRules,
  onSaveSponsorSettingsRules,
  onGetSponsorSettingsDemands,
  onSaveSponsorSettingsDemands,
  onSaveSponsorSettingsEntry,
  onGetSponsorSettingsEntry,
  onSaveSponsorSettingsPersonalData,
  onGetSponsorSettingsPersonalData,
  onSaveSponsorSettingsResponsibility,
  onGetSponsorSettingsResponsibility,
  changeSponsorText,
  onSaveSponsorProfile,
  onSaveSponsorService,
  onChangeMembers,
  onSaveSponsorMembers,
  requestJoinSponsor,
  cancelRequestJoinSponsor,
  onChangeParticipants,
  onChangeParticipantsRequest,
  onCreate,
  onChangeLegalField,
  onSaveSponsorLegal,
};
