import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { goToProfile } from '../../../../routes/actions';
import { achievementsActions } from '../../../../store/achievements';
import { eventsActions } from '../../../../store/events';
import { usersActions } from '../../../../store/users';
import { useActions, useSelector } from '../../../../hooks/store';
import { profileActions } from '../../../../store/profile';
import { useResponsive } from '../../../../hooks/useResponsive';
import isAccountHolder from '../../../../hooks/isAccountHolder';
import { globalCancelFetch } from '../../../../api/v2';
import Owner from '../../../components/owner';
import UserInfo from '../../../components/userInfo';
import About from '../../../components/about';
import Teams from '../../../components/teams';
import Awards from '../../../components/awards';
import Activity from '../../../components/activity';
import LinksBlock from '../../../components/links';
import { classNames as cx } from '../../../../helpers/utility';
import GalleryComponent from '../../../components/gallery';
import './styles.scss';

const ProfilePage = () => {
  const { id } = useParams();
  const { user, anotherUser, loading, loadingSilent } = useSelector((state) => state.profile);
  const { userId, services, token, loading: authLoading } = useSelector((state) => state.auth);
  const { eventsUserList, pagination } = useSelector((state) => state.events);
  const { userVideoList } = useSelector((state) => state?.social);
  const { achievements, qtyOfAchievements, awards, qtyOfAwards, titles, qtyOfTitles } = useSelector(
    (state) => state.achievements,
  );

  const { getUserData, getUserSilent } = useActions(profileActions);
  const { sentFriendRequest, cancelFriendRequest, removeFriend } = useActions(usersActions);
  const { getUserEvents, clearEvents } = useActions(eventsActions);
  const { getLastList, clearAchievements } = useActions(achievementsActions);

  const { isMobile, isTabletSmall } = useResponsive();
  const { accountHolder } = isAccountHolder();

  useEffect(() => {
    if (userId?.toString() === id?.toString()) {
      goToProfile();
    }
  }, [id, userId]);

  //Загружаем активности если они не загружены
  useEffect(() => {
    if (token) {
      clearEvents();
      getUserEvents(id || userId, 1);
    }
  }, [id, token]);

  //Если есть ID в урле, то загружаем другого юзера и его друзей
  useEffect(() => {
    if (token) {
      if (id) {
        getUserData(id);
      }
    }
  }, [id, token]);

  //Если есть ID в урле, то загружаем achievements другого пользователя, если нет, то своего
  useEffect(() => {
    if (token) {
      if (id) {
        getLastList(id);
      } else {
        getLastList(user && user.id);
      }
    }
  }, [id, token]);

  useEffect(
    () => () => {
      clearEvents();
      clearAchievements();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    },
    [],
  );

  const isLoading = loading || authLoading;

  const achievementCount = useMemo(() => qtyOfAchievements + qtyOfAwards + qtyOfTitles || 0, [
    qtyOfAchievements,
    qtyOfAwards,
    qtyOfTitles,
  ]);

  const classNames = cx('page-profile', {
    'page-profile--about-empty': id ? !anotherUser?.about : !user?.about,
    'page-profile--awards-no': !achievementCount,
  });

  return (
    <section className={classNames}>
      <Owner
        friends={accountHolder ? user.random_friends : anotherUser.random_friends}
        totalFriends={
          accountHolder ? user.qty_of_friends_string : anotherUser.qty_of_friends_string
        }
        totalFriendsNumber={accountHolder ? user.qty_of_friends : anotherUser.qty_of_friends}
        qtyMutualFriends={
          anotherUser?.qty_of_mutual_friends && anotherUser?.qty_of_mutual_friends_string
        }
        user={id ? anotherUser : user}
        userId={id}
        selfId={userId}
        loading={isLoading}
        sentFriendRequest={sentFriendRequest}
        removeFriend={removeFriend}
        cancelFriendRequest={cancelFriendRequest}
        isFriend={anotherUser.is_friend}
        isRequestWasSent={anotherUser.is_request_was_sent}
        getUserSilent={getUserSilent}
        loadingSilent={loadingSilent}
      />

      {/*{isMobile && (*/}
      {/*  <MobileNav*/}
      {/*    id={id || userId}*/}
      {/*    achievementCount={achievementCount}*/}
      {/*    eventsCount={pagination?.total_items}*/}
      {/*  />*/}
      {/*)}*/}

      <UserInfo
        user={id ? anotherUser : user}
        selfId={userId}
        services={services}
        loading={isLoading}
      />
      {(isTabletSmall || isMobile) && (
        <LinksBlock
          services={services}
          social={id ? anotherUser.social_service : user.social_service}
        />
      )}
      <About
        isLoading={isLoading}
        about={id ? anotherUser.about : user.about}
        loading={isLoading}
      />
      <Teams team={id ? anotherUser?.team : user?.team} isOwner={!id} loading={isLoading} />
      {achievementCount ? (
        <Awards
          userId={id ? anotherUser.id : user.id}
          achievements={achievements}
          qtyOfAchievements={qtyOfAchievements}
          awards={awards}
          qtyOfAwards={qtyOfAwards}
          titles={titles}
          qtyOfTitles={qtyOfTitles}
        />
      ) : null}
      {/*{!isMobile && <Catalog />}*/}
      {!isEmpty(eventsUserList) ? <Activity eventsList={eventsUserList} /> : null}
      <GalleryComponent
        parentId={id ? anotherUser.id : user.id}
        userPage
        videos={userVideoList}
        parentType="blog"
      />
      {/*{isHD && <Favorites />}*/}
    </section>
  );
};

export default ProfilePage;
