import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import { classNames as cx } from '../../helpers/utility';
import CustomInput from '../input';

const CustomPhoneInput = ({
  className,
  type,
  prefix,
  name,
  placeholder,
  value,
  onChange,
  label,
  required,
  error,
  labelPosition = 'top',
}) => {
  const { t } = useTranslation();
  const classNames = cx('input__wrap', className, {
    [`input__${type}`]: type,
    'input-prefix__wrap': prefix,
  });

  const classNamesContainer = cx('input__container', {
    [`input__container__label_${labelPosition}`]: labelPosition,
    [`input__${name}`]: name,
    'input__container--error': error,
  });

  const classNamesLabel = cx('input__label', {
    [`input__label_${labelPosition}`]: labelPosition,
    'input__label--error': error,
    'input__label--required': required,
  });

  const customLabel = required ? `${t(label)} *` : t(label);

  return (
    <div className={classNamesContainer}>
      {(label || (error && required)) && (
        <span className={classNamesLabel}>{error || customLabel}</span>
      )}
      <div className={classNames}>
        <PhoneInput
          tabIndex="0"
          name={name}
          disableDropdown
          inputClass="phone-input"
          inputComponent={<CustomInput label={label} />}
          placeholder={t(placeholder)}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default CustomPhoneInput;
