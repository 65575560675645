import React, { useEffect, useRef, forwardRef, useLayoutEffect } from 'react';
import { Jodit } from 'jodit';
import { useActions } from '../../hooks/store';
import { editorActions } from '../../store/editor';
import { regexDelImg } from '../../helpers/utility';
import { FileUpload, FileUploadArticle } from '../textEditor/uploadService';
import img from '../../assets/icon/image-white.svg';
import './styles.scss';

const getConfig = ({ imageUpload }) => {
  return {
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'ul',
      'ol',
      '|',
      'center',
      'left',
      'right',
      'justify',
      '|',
      {
        name: 'Upload image',
        iconURL: img,
        exec: async (editor) => {
          await imageUpload(editor);
        },
      },
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ['brush', 'file'],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    defaultActionOnPaste: 'insert_only_text',
    language: 'ru',
    theme: 'custom',
    disablePlugins:
      'about,error-messages,iframe,search,source,stat,symbols,video,file,print,link,table-keyboard-navigation,copy-format,preview,select-cells,resize-cells,color,fullsize,inline-popup,clean-html,hr,tooltip,table,font,image',
  };
};
let editorElement = {};
let matchesSnapshot = [];
let matches = [];
// eslint-disable-next-line react/display-name
const JoditEditor = forwardRef(
  (
    {
      value,
      snapshotValue,
      onChange,
      onBlur,
      tabIndex,
      name,
      pageId,
      parentId,
      type,
      languageId,
      element,
    },
    ref,
  ) => {
    const {
      getChangedDataEditor,
      getSnapshotDataEditor,
      deleteTextEditorImages,
      getMatchesDataEditor,
      deleteTextEditorImagesArticle,
    } = useActions(editorActions);

    const textArea = useRef(null);

    useEffect(() => {
      textArea.current.value = value;
    }, [value]);

    useEffect(() => {
      getSnapshotDataEditor(matchesSnapshot);
    }, [snapshotValue]);

    useEffect(() => {
      getMatchesDataEditor(matches);
    }, [value]);

    useEffect(() => {
      editorElement = element;
    }, [element]);

    useEffect(() => {
      matchesSnapshot = snapshotValue
        ? Array.from(snapshotValue.matchAll(regexDelImg)).map((match) => match[1])
        : [];
      matches = value ? Array.from(value?.matchAll(regexDelImg))?.map((match) => match[1]) : [];
    }, [snapshotValue, value]);

    const insertImage = (editor, data) => {
      const imageSrc = `${data?.baseurl}${
        data && data?.images && data?.images[0] && data?.images[0]?.filename
      }`;
      const imageServerId = data?.images && data?.images[0] && data?.images[0]?.id;
      const image = editor.selection.j.createInside.element('img');
      image.setAttribute('src', imageSrc);
      image.setAttribute('serverid', imageServerId);
      editor.selection.insertNode(image);
    };

    const imageUpload = (editor) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async function () {
        const imageFile = input.files[0];

        if (!imageFile) {
          return;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
          return;
        }

        if (editorElement) {
          const imageInfo = await FileUploadArticle(imageFile, editorElement);
          insertImage(editor, imageInfo);
        } else {
          const imageInfo = await FileUpload(imageFile, pageId, parentId, type, languageId);
          insertImage(editor, imageInfo);
        }
      };
    };

    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(textArea.current);
        } else {
          // eslint-disable-next-line no-param-reassign
          ref.current = textArea.current;
        }
      }
    }, [textArea]);

    useEffect(() => {
      const blurHandler = (val) => {
        onBlur(val);
      };

      const changeHandler = (val) => {
        onChange(val);
        const matchesOnChanges = Array.from(val?.matchAll(regexDelImg))?.map((match) => match[1]);
        getChangedDataEditor(matchesOnChanges);
      };

      const elementEditor = textArea.current;
      textArea.current = Jodit.make(elementEditor, getConfig({ imageUpload }));
      textArea.current.value = value;
      textArea.current.events.on('blur', () => blurHandler(textArea.current.value));
      textArea.current.events.on('change', () => changeHandler(textArea.current.value));
      textArea.current.workplace.tabIndex = tabIndex || -1;
      return () => {
        if (editorElement) {
          const formData = new FormData();
          if (matches) {
            formData.append('images', matches);
          }
          if (editorElement?.hash) {
            formData.append('hash', editorElement?.hash);
          }
          formData.append('article_id', editorElement?.article_id || null);
          deleteTextEditorImagesArticle({ formData });
        } else {
          const data = {
            images: matches,
            page_id: pageId,
            parent_id: Number(parentId),
            type,
            language_id: languageId,
          };
          deleteTextEditorImages({ data });
        }
      };
    }, []);

    return <textarea ref={textArea} name={name} className="editor" />;
  },
);

export default JoditEditor;
