import React from 'react';
import Trans from '../../../../components/trans';

const TypesSwitch = ({ onChange, type }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className="switch-type">
      <div className="switch-type_radio_btn">
        <input
          onChange={handleChange}
          checked={type === 'any'}
          id="radio-3"
          type="radio"
          name="radio"
          value="any"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="radio-3">
          <span>{Trans('organizers.filter.any')}</span>
        </label>
      </div>
      <div className="switch-type_radio_btn">
        <input
          onChange={handleChange}
          checked={type === 'open'}
          id="radio-1"
          type="radio"
          name="radio"
          value="open"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="radio-1">
          <span>{Trans('organizers.filter.open')}</span>
        </label>
      </div>

      <div className="switch-type_radio_btn">
        <input
          onChange={handleChange}
          checked={type === 'closed'}
          id="radio-2"
          type="radio"
          name="radio"
          value="closed"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="radio-2">
          <span>{Trans('organizers.filter.closed')}</span>
        </label>
      </div>
    </div>
  );
};

export default TypesSwitch;
