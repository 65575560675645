import { catchWrapper } from '../../../helpers/utility';
import { onRemoveImagesFromAlbum } from '../../../api/v2';

export default ({ images, parentId }) => async (dispatch) => {
  dispatch({ type: 'BLOG/REMOVE_IMAGES_ALBUM/REQUESTED' });

  const imagesSelectedArr = images.filter((i) => i.isSelected);
  const imagesIdArr = imagesSelectedArr.map((i) => Number(i.id));

  const payload = {
    album_id: images[0].albumId,
    images: imagesIdArr,
    parent_id: parentId,
    type: 'blog',
  };

  const result = await catchWrapper(onRemoveImagesFromAlbum(payload));

  if (result && result.result) {
    dispatch({ type: 'BLOG/REMOVE_IMAGES_ALBUM/SUCCEEDED', payload: imagesSelectedArr });
  } else {
    dispatch({
      type: 'BLOG/REMOVE_IMAGES_ALBUM/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
