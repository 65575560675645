import React from 'react';

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6667 8C14.6667 7.03263 14.4606 6.11337 14.09 5.28373L15.0334 4.1848C15.6498 5.3188 16 6.6185 16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8C0 3.58173 3.58173 1.29283e-09 8 1.29283e-09C9.38763 1.29283e-09 10.6928 0.353293 11.8303 0.97484L10.9382 2.01408C10.0518 1.57816 9.0545 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8ZM15.4823 0.192609C15.8177 0.480147 15.8566 0.985187 15.5691 1.32065L8.61033 9.43917C8.32277 9.77463 7.81773 9.81347 7.4823 9.52593C7.42393 9.47593 7.37453 9.4193 7.3343 9.35817C7.31983 9.34243 7.30587 9.326 7.29247 9.30887L5.3701 6.85303C5.09773 6.50513 5.15903 6.0023 5.5069 5.72997C5.85483 5.45763 6.35767 5.5189 6.62997 5.8668L8.03097 7.65657L14.3542 0.279381C14.6418 -0.0560793 15.1468 -0.0949283 15.4823 0.192609Z"
      fill="currentColor"
    />
  </svg>
);
