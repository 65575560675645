import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { classNames as cx } from '../../../../helpers/utility';
import history from '../../../../routes/history';
import { Pages } from '../../../../routes/pages';
import { getLastMessageDate } from '../../../../helpers/common';
import Avatar from '../../../../pages/components/avatar';

import './styles.scss';
import ButtonIcon from '../../../../pages/chat/components/buttons/button-icon';
import modalService from '../../../../services/modalService';

function PanelDialog({
  open,
  conversation,
  setReceiverInfo,
  onClosePanel,
  language,
  deleteConversation,
}) {
  const [isHovering, setIsHovering] = useState(false);
  const { conversationWith, lastMessage, unreadMessageCount } = conversation;
  const classNames = cx('panel-dialog', {
    'panel-dialog--open': open,
  });

  const handleClick = () => {
    if (!isHovering) {
      setReceiverInfo(conversationWith);
      if (open) {
        onClosePanel();
      }
      history.push({
        pathname: Pages.msg.base,
      });
    }
  };

  const handleKeyDown = () => null;

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const onDeleteConversation = () => {
    deleteConversation(conversation);
  };

  const onClickDelete = () => {
    modalService.openConfirmModal({
      title: t('pages.msg.doYouWantDelete'),
      submit: onDeleteConversation,
    });
  };

  console.log(language);

  return (
    <div
      role="link"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={classNames}
      tabIndex={0}
    >
      <div className="panel-dialog__avatar">
        <Avatar
          image={conversationWith?.avatar}
          status={conversationWith?.status === 'online'}
          withStatus
          hasCount
          count={unreadMessageCount}
        />
      </div>

      {open ? (
        <>
          <div className="panel-dialog__info">
            <span className="panel-dialog__title">{conversationWith?.name}</span>
            <span className="panel-dialog__text">{lastMessage?.data?.text}</span>
          </div>
          <div
            className="panel-dialog__menu"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isHovering ? (
              <ButtonIcon
                onClick={onClickDelete}
                iconName="delete"
                className="panel-dialog__delete"
              />
            ) : (
              <div className="panel-dialog__time">
                <span className="panel-dialog__text panel-dialog__text-time">
                  <Trans>{getLastMessageDate(lastMessage.sentAt, language)}</Trans>
                </span>
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default PanelDialog;
