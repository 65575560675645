import React from 'react';
import CustomInput from '../../../components/input';
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import { useResponsive } from '../../../hooks/useResponsive';
import send from '../../../assets/image/send.png';
import './styles.scss';

const CommentsWithAttach = ({ sendComment, handleChangeText, text }) => {
  const { isDesktop } = useResponsive();
  const dummy = isDesktop ? <Icon type="profile" /> : <Icon type="profileMobile" />;
  return (
    <div className="comments-block">
      <CustomInput
        className="comments-block__input"
        placeholder="Прокомментировать"
        name="comments"
        onChange={handleChangeText}
        value={text}
      />
      <div className="comments-block__send">
        <Button onClick={sendComment} variant="submenu">
          <img src={send} alt="send" />
        </Button>
      </div>
    </div>
  );
};

export default CommentsWithAttach;
