export default (data) => (dispatch, getState) => {
  const old = getState().teams.teamMembers;
  const { userId, group, role } = data;

  let newData;

  if (group === 'commander') {
    const commander = old[group];
    const tmp = {
      ...commander,
      player_role: role,
    };
    newData = {
      ...old,
      commander: tmp,
    };
  } else if (group === 'deputy' || group === 'users') {
    const g = old[group];
    const tmp = g.map((user) => {
      if (user.id === userId) {
        return {
          ...user,
          player_role: role,
        };
      }
      return user;
    });
    newData = {
      ...old,
      [group]: tmp,
    };
  } else {
    const g = old[group];
    const tmp = g.map((g) => {
      return {
        ...g,
        users: g.users.map((user) => {
          if (user.id === userId) {
            return {
              ...user,
              player_role: role,
            };
          }
          return user;
        }),
      };
    });
    newData = {
      ...old,
      [group]: tmp,
    };
  }

  dispatch({
    type: 'TEAMS/CHANGE_USER_ROLE',
    payload: newData,
  });
};
