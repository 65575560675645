import React from 'react';
import Button from '../../../../components/button';

import './styles.scss';

const FooterActions = ({ onLogOut, remove }) => {
  return (
    <div className="settings-security__bottom">
      <Button onClick={onLogOut} className="settings-security__logout" variant="text">
        Выйти из аккаунта
      </Button>
      <Button onClick={remove} className="settings-security__remove" variant="text">
        Удалить аккаунт
      </Button>
    </div>
  );
};

export default FooterActions;
