export const getFormData = () => {
  const formData = new FormData();
  formData.append('action', 'getUserData');
  formData.append('fields[]', 'name');
  formData.append('fields[]', 'patronymic');
  formData.append('fields[]', 'last_name');
  formData.append('fields[]', 'nickname');
  formData.append('fields[]', 'about');

  formData.append('fields[]', 'image_avatar');
  formData.append('fields[]', 'image_passport');

  formData.append('fields[]', 'location');

  formData.append('fields[]', 'birth_date');
  formData.append('fields[]', 'phone');
  formData.append('fields[]', 'email');

  formData.append('fields[]', 'social');

  formData.append('fields[]', 'state_online');
  formData.append('fields[]', 'temp_phone');
  formData.append('fields[]', 'temp_email');
  formData.append('fields[]', 'is_email_confirmed');

  formData.append('fields[]', 'gender');
  formData.append('fields[]', 'address');

  formData.append('fields[]', 'vehicle_brand');
  formData.append('fields[]', 'vehicle_number');

  formData.append('fields[]', 'proxy_name');
  formData.append('fields[]', 'proxy_last_name');
  formData.append('fields[]', 'proxy_patronymic');
  formData.append('fields[]', 'proxy_phone');

  //TODO until better times
  /*formData.append('fields[]', 'passport_number');
  formData.append('fields[]', 'passport_agency');
  formData.append('fields[]', 'passport_date');
  formData.append('fields[]', 'passport_code');
  formData.append('fields[]', 'passport_address');*/

  formData.append('fields[]', 'medical_policy_number');
  formData.append('fields[]', 'medical_diseases');
  formData.append('fields[]', 'medical_allergy');
  formData.append('fields[]', 'medical_vaccinations');

  return formData;
};

export const getFormDataToAnotherUser = () => {
  const formData = new FormData();
  formData.append('action', 'getUserData');

  formData.append('fields[]', 'name');
  formData.append('fields[]', 'last_name');
  formData.append('fields[]', 'nickname');
  formData.append('fields[]', 'about');

  formData.append('fields[]', 'image_avatar');
  formData.append('fields[]', 'location');

  formData.append('fields[]', 'birth_date');
  formData.append('fields[]', 'phone');
  formData.append('fields[]', 'email');

  formData.append('fields[]', 'gender');
  formData.append('fields[]', 'address');

  return formData;
};
