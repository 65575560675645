import React from 'react';
import Button from '../../../../../../components/button';
import Icon from '../../../../../../components/icon';
import DropdownWithSchema from '../../../../../components/dropdown';
import useVisible from '../../../../../../hooks/useVisible';
import Trans from '../../../../../../components/trans';

const ImageSettingsActions = ({ setMainImage, removeImage, id, isMain, disabled }) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const schema = [];

  if (!isMain) {
    schema.push({
      id: '1',
      label: Trans('fields.settings.coverImage'),
      action: setMainImage,
    });
  }

  schema.push({
    id: '2',
    label: Trans('fields.settings.delImage'),
    action: removeImage,
  });

  const handleOpenMenu = () => (!disabled ? setIsVisible(!isVisible) : false);

  return (
    <>
      {disabled ? (
        <></>
      ) : (
        <div className="image__action-button">
          <Button onClick={handleOpenMenu} variant="submenu">
            <Icon type="tripleDots" />
          </Button>
          {isVisible && (
            <DropdownWithSchema dropRef={ref} schema={schema} id={id} setIsVisible={setIsVisible} />
          )}
        </div>
      )}
    </>
  );
};

export default ImageSettingsActions;
