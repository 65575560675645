import { catchWrapper } from '../../helpers/utility';
import { postRegion } from '../../api';

export const geoReducer = (state = null, action) => {
  switch (action.type) {
    case 'SETTINGS/LOAD_COUNTRIES/REQUESTED': {
      return {
        ...state,
        countries: { loading: state.countries.loading - 1 },
      };
    }
    case 'SETTINGS/LOAD_OPEN_COUNTRY/REQUESTED': {
      return {
        ...state,
        openCountries: { loading: state.openCountries.loading - 1 },
      };
    }

    case 'SETTINGS/LOAD_REGIONS/REQUESTED': {
      return {
        ...state,
        regions: { loading: state.countries.loading - 1 },
      };
    }

    case 'SETTINGS/LOAD_CITIES/REQUESTED': {
      return {
        ...state,
        cities: { loading: state.countries.loading - 1 },
      };
    }
    case 'SETTINGS/LOAD_COUNTRIES/SUCCESSFUL': {
      return {
        ...state,
        countries: { options: action.payload.countries, loading: state.countries.loading + 1 },
      };
    }

    case 'SETTINGS/LOAD_REGIONS/SUCCESSFUL': {
      return {
        ...state,
        regions: { options: action.payload.regions, loading: state.regions.loading + 1 },
      };
    }
    case 'SETTINGS/LOAD_CITIES/SUCCESSFUL': {
      return {
        ...state,
        cities: { options: action.payload.cities, loading: state.cities.loading + 1 },
      };
    }

    case 'SETTINGS/LOAD_OPEN_COUNTRY/SUCCESSFUL': {
      return {
        ...state,
        openCountries: {
          options: action.payload,
          loading: state.openCountries.loading + 1,
        },
      };
    }

    case 'SETTINGS/LOAD_COUNTRIES/FAILED': {
      return {
        ...state,
        countries: { loading: state.countries.loading - 1 },
      };
    }

    case 'SETTINGS/LOAD_REGIONS/FAILED': {
      return {
        ...state,
        regions: { loading: state.countries.loading - 1 },
      };
    }

    case 'SETTINGS/LOAD_CITIES/FAILED': {
      return {
        ...state,
        cities: { loading: state.countries.loading - 1 },
      };
    }
    case 'SETTINGS/LOAD_OPEN_COUNTRY/FAILED': {
      return {
        ...state,
        openCountries: { loading: state.openCountries.loading - 1 },
      };
    }

    default:
      return state;
  }
};
const getCountry = () => async (dispatch) => {
  dispatch({ type: 'SETTINGS/LOAD_COUNTRIES/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getCountries');

  const result = await catchWrapper(postRegion(formData));
  if (result && result.result) {
    dispatch({ type: 'SETTINGS/LOAD_COUNTRIES/SUCCESSFUL', payload: result });
  } else {
    dispatch({ type: 'SETTINGS/LOAD_COUNTRIES/FAILED', payload: result.message[0] });
  }
};
const getRegion = (countryId) => async (dispatch) => {
  dispatch({ type: 'SETTINGS/LOAD_REGIONS/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getRegions');
  formData.append('country_id', countryId.id);

  const result = await catchWrapper(postRegion(formData));
  if (result && result.result) {
    dispatch({ type: 'SETTINGS/LOAD_REGIONS/SUCCESSFUL', payload: result });
  } else {
    dispatch({ type: 'SETTINGS/LOAD_REGIONS/FAILED', payload: result.message[0] });
  }
};
const getCity = (countryId, regionId) => async (dispatch) => {
  dispatch({ type: 'SETTINGS/LOAD_CITIES/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getCities');
  formData.append('country_id', countryId.id);
  formData.append('region_id', regionId.id);

  const result = await catchWrapper(postRegion(formData));
  if (result && result.result) {
    dispatch({ type: 'SETTINGS/LOAD_CITIES/SUCCESSFUL', payload: result });
  } else {
    dispatch({ type: 'SETTINGS/LOAD_CITIES/FAILED', payload: result.message[0] });
  }
};

const getOpenCountry = (countryId) => async (dispatch) => {
  dispatch({ type: 'SETTINGS/LOAD_OPEN_COUNTRY/REQUESTED' });
  const formData = new FormData();
  formData.append('action', 'getOpenCountry');
  formData.append('country_id', countryId.id);
  const result = await catchWrapper(postRegion(formData));
  const mappedResult = result && result.country && result.country.regions;

  // const regionsToSelect = mappedResult.map((region) => {
  //   return { label: region.title, options: region.cities, id: region.id };
  // });

  // @gojira : no idea how do this
  const regionsToSelect = mappedResult.reduce((previous, current) => {
    previous.push(
      {
        ...current,
        id: `region${current.id}`,
        region_id: current.id,
        isRegion: true,
      },
      ...current.cities,
    );
    return previous;
  }, []);

  if (result && result.result) {
    dispatch({ type: 'SETTINGS/LOAD_OPEN_COUNTRY/SUCCESSFUL', payload: regionsToSelect });
  } else {
    dispatch({ type: 'SETTINGS/LOAD_OPEN_COUNTRY/FAILED', payload: result.message[0] });
  }
};
export const geoActions = {
  getCountry,
  getRegion,
  getCity,
  getOpenCountry,
};
