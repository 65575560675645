import getGrounds from './impl/getGrounds';
import clearGrounds from './impl/clearGrounds';
import getGround from './impl/getGround';
import onUploadPhoto from './impl/onUploadPhoto';
import clearNotSavedImages from './impl/clearNotSavedImages';
import removeImage from './impl/removeImage';
import setMainImage from './impl/setMainImage';
import saveGround from './impl/saveGround';
import onChangeError from './impl/onChangeError';
import sendGroundRequest from './impl/sendGroundRequest';

const initState = {
  loading: 0,
  grounds: [],
  error: null,
  pagination: {},
  ground: {},
  groundSnapshot: {},
  imageLoading: 0,
  imageActionId: 0,
  saveRequest: 0,
  sendingRequest: 0,
};

export const groundsReducer = (state = null, action) => {
  switch (action.type) {
    case 'GROUND/SEND_REQUEST/REQUESTED': {
      return {
        ...state,
        sendingRequest: state.saveRequest + 1,
      };
    }
    case 'GROUND/SEND_REQUEST/SUCCEEDED': {
      return {
        ...state,
        sendingRequest: 0,
      };
    }
    case 'GROUND/SEND_REQUEST/FAILED': {
      return {
        ...state,
        sendingRequest: 0,
      };
    }
    case 'GROUND/SAVE/REQUESTED': {
      return {
        ...state,
        saveRequest: state.saveRequest + 1,
      };
    }
    case 'GROUND/SAVE/SUCCEEDED': {
      return {
        ...state,
        saveRequest: 0,
      };
    }
    case 'GROUND/SAVE/FAILED': {
      return {
        ...state,
        saveRequest: state.saveRequest - 1,
      };
    }
    case 'GROUND/SET_MAIN_IMAGE/REQUESTED':
    case 'GROUND/REMOVE_IMAGE/REQUESTED': {
      return {
        ...state,
        imageActionId: action.payload,
      };
    }
    case 'GROUND/SET_MAIN_IMAGE/SUCCEEDED':
    case 'GROUND/REMOVE_IMAGE/SUCCEEDED': {
      return {
        ...state,
        imageActionId: 0,
        ground: action.payload,
      };
    }
    case 'GROUND/SET_MAIN_IMAGE/FAILED':
    case 'GROUND/REMOVE_IMAGE/FAILED': {
      return {
        ...state,
        imageActionId: 0,
      };
    }
    case 'GROUND/UPLOAD_IMAGE/REQUESTED': {
      return {
        ...state,
        imageLoading: state.imageLoading + action.payload,
      };
    }
    case 'GROUND/UPLOAD_IMAGE/SUCCEEDED': {
      return {
        ...state,
        imageLoading: state.imageLoading - 1,
        ground: {
          ...state.ground,
          images: state?.ground?.images
            ? [...state?.ground?.images, action.payload]
            : [action.payload],
        },
      };
    }
    case 'GROUND/UPLOAD_IMAGE/FAILED': {
      return {
        ...state,
        imageLoading: state.imageLoading - 1,
      };
    }
    case 'GROUNDS/CHANGE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        ground: {
          ...state.ground,
          [field]: value,
        },
      };
    }
    case 'GROUNDS/CHANGE_PROFILE_FIELD': {
      const { field, value } = action.payload;
      return {
        ...state,
        ground: {
          ...state.ground,
          profile: {
            ...state.ground.profile,
            [field]: value,
          },
        },
      };
    }
    case 'GROUNDS/CHANGE_ERROR': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'GROUND/LOAD/REQUESTED':
    case 'GROUNDS/LOAD/REQUESTED': {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }
    case 'GROUND/LOAD/SUCCEEDED': {
      return {
        ...state,
        loading: 0,
        ground: { ...action.payload },
      };
    }
    case 'GROUND/SET_SNAPSHOT': {
      return {
        ...state,
        groundSnapshot: { ...action.payload },
      };
    }
    case 'GROUNDS/LOAD/SUCCEEDED': {
      return {
        ...state,
        loading: 0,
        grounds: [...state.grounds, ...action.payload.items],
        pagination: action.payload.pagination,
      };
    }
    case 'GROUND/LOAD/FAILED':
    case 'GROUNDS/LOAD/FAILED': {
      return {
        ...state,
        loading: state.loading - 1,
        error: action.payload,
      };
    }
    case 'GROUNDS/CLEAR': {
      return initState;
    }

    default:
      return state;
  }
};

export const onChangeField = (field, value, isProfile) => (dispatch) => {
  if (!isProfile)
    dispatch({
      type: 'GROUNDS/CHANGE_FIELD',
      payload: { field, value },
    });
  else
    dispatch({
      type: 'GROUNDS/CHANGE_PROFILE_FIELD',
      payload: { field, value },
    });
};

export const setSnapshot = (value) => (dispatch) => {
  dispatch({
    type: 'GROUND/SET_SNAPSHOT',
    payload: value,
  });
};

export const groundsActions = {
  getGround,
  getGrounds,
  clearGrounds,
  onChangeField,
  onChangeError,
  onUploadPhoto,
  setSnapshot,
  clearNotSavedImages,
  removeImage,
  setMainImage,
  saveGround,
  sendGroundRequest,
};
