import { catchWrapper } from '../../../helpers/utility';
import { saveBlog } from '../../../api/v2';

export default (data) => async (dispatch, getState) => {
  const { blogImageTemp } = getState().blog;

  const formData = new FormData();

  formData.append('element', JSON.stringify(data));
  if (blogImageTemp?.blob) {
    const { blob, file } = blogImageTemp;
    formData.append('imageBlob', blob);
    formData.append('imageFile', file);
  }

  dispatch({ type: 'BLOG/SAVE_BLOG_PROFILE/REQUESTED' });

  const result = await catchWrapper(saveBlog(formData));

  if (result && result.result) {
    dispatch({ type: 'BLOG/SAVE_BLOG_PROFILE/SUCCEEDED', payload: result.blog });
  } else {
    dispatch({
      type: 'BLOG/SAVE_BLOG_PROFILE/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
