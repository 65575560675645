import { catchWrapper } from '../../../helpers/utility';
import { postCamouflages } from '../../../api';

export default ({ query, page, pagesCount = 20 }) => async (dispatch) => {
  dispatch({ type: 'CAMOUFLAGES/LOAD/REQUESTED' });

  const formData = new FormData();
  formData.append('action', 'getCamouflageList');
  formData.append('objects_per_page', pagesCount.toString());

  if (page) {
    formData.append('page', page.toString());
  }

  if (query) {
    formData.append('filter[query]', query);
  }

  const result = await catchWrapper(postCamouflages(formData));

  if (result && result.result) {
    dispatch({ type: 'CAMOUFLAGES/LOAD/SUCCEEDED', payload: result });
  } else {
    dispatch({
      type: 'CAMOUFLAGES/LOAD/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
