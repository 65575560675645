import React, { useEffect, useRef } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { classNames as cx } from '../../helpers/utility';

const CustomInfiniteScroll = ({
  loading,
  hasMore,
  next,
  dataLength,
  className,
  children,
  skeleton,
}) => {
  const containerEl = useRef(null);

  useEffect(() => {
    if (
      dataLength &&
      hasMore &&
      document.body.offsetHeight - 220 > containerEl.current.offsetHeight
    ) {
      next();
    }
  }, [dataLength]);

  return (
    <div ref={containerEl}>
      {loading && !hasMore ? (
        skeleton || null
      ) : (
        <InfiniteScroll
          dataLength={dataLength}
          next={next}
          hasMore={hasMore}
          loader={<></>}
          className={cx('grid__list', className)}
        >
          {children}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default CustomInfiniteScroll;
