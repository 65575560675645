import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { QRCode } from 'react-qr-svg';
import './styles.scss';
import * as clipboard from 'clipboard-polyfill';
import history from '../../../../routes/history';
import { useActions, useSelector } from '../../../../hooks/store';
import { profileActions } from '../../../../store/profile';
import dummy from '../../../../assets/image/user.svg';
import Icon from '../../../../components/icon';

const MobilePageQr = () => {
  const { location } = history;
  const { id } = useParams();
  const { getUserData } = useActions(profileActions);
  const { anotherUser } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const { image_avatar: imageAvatarOther } = anotherUser;
  const urlAnotherUserPreview =
    imageAvatarOther && imageAvatarOther.preview ? `${imageAvatarOther.preview}` : dummy;
  useEffect(() => {
    if (token) {
      if (id) {
        getUserData(id);
      }
    }
  }, [id, token]);

  const qrValue = `https://airsoft.net${location?.pathname}`.replace('/qr', '');
  const copyToClipboardHandler = async () => {
    await clipboard.writeText(qrValue);
  };
  return (
    <div className="mobile-page-qr">
      <div className="mobile-page-qr__container">
        <img className="mobile-page-qr__owner__photo" src={urlAnotherUserPreview} alt="" />
        <div className="mobile-page-qr__name">{anotherUser?.nickname}</div>
        <div className="mobile-page-qr__code">
          <QRCode
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            style={{ width: 231 }}
            value={qrValue}
          />
        </div>
        <div className="mobile-page-qr__link">{qrValue}</div>
      </div>
      <div className="mobile-page-qr__action-container">
        <div className="mobile-page-qr__action-button" onClick={copyToClipboardHandler}>
          <div className="mobile-page-qr__action-button__copy">
            <Icon type="copy" />
          </div>
          <div>Скопировать ссылку</div>
        </div>
        {/*<div className="mobile-page-qr__action-button">*/}
        {/*  <div className="mobile-page-qr__action-button__download">*/}
        {/*    <Icon type="download" />*/}
        {/*  </div>*/}
        {/*  <div>Сохранить в галерею</div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default MobilePageQr;
