import React, { useMemo } from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { Pages } from '../../../routes/pages';
import { PrivateRoute } from '../../../routes/privateRoute';
import { getIdFromUrl } from '../../../helpers/utility';

import HeaderLeft from '../../../components/headerConstructor/components/left';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import HeaderConstructor from '../../../components/headerConstructor';
import SidebarButton from '../../../components/headerConstructor/components/sidebarButton';
import HeaderRight from '../../../components/headerConstructor/components/right';
import Controls from '../../../components/headerConstructor/components/controls';
import Menu from '../../../components/headerConstructor/components/menu';

import ProfilePage from '../desktop/main';
import ActivityPageMobile from './activity';
import AwardsPage from '../desktop/awards';
import MobilePageQr from './qr';
import history from '../../../routes/history';
import Button from '../../../components/button';
import Icon from '../../../components/icon';

import './styles.scss';

const getTitle = (path) => {
  const id = getIdFromUrl(path);
  switch (path) {
    case Pages.profile.activity:
      return 'Активность';
    case Pages.profile.qr.replace(':id', id):
      return 'QR-код';
    case Pages.profile.achievements.replace(':id', id):
      return 'Достижения';
    case Pages.profile.awards.replace(':id', id):
      return 'Награды';
    case Pages.profile.titles.replace(':id', id):
      return 'Звания';
    default:
      return 'Профиль';
  }
};

const getCurrentPage = (path) => {
  const id = getIdFromUrl(path);
  switch (path) {
    case Pages.profile.activity:
      return 'activity';
    case Pages.profile.qr.replace(':id', id):
      return 'qr';
    case Pages.profile.achievements.replace(':id', id):
      return 'achievements';
    case Pages.profile.awards.replace(':id', id):
      return 'awards';
    case Pages.profile.titles.replace(':id', id):
      return 'titles';
    default:
      return 'profile';
  }
};

const ProfileSectionMobile = () => {
  const { location } = history;

  const titles = useMemo(() => getTitle(location.pathname), [location.pathname]);

  const currentPage = useMemo(() => getCurrentPage(location.pathname, location.state), [
    location.pathname,
  ]);

  return (
    <>
      <HeaderConstructor className="profile mobile">
        <HeaderLeft>
          {currentPage === 'profile' ? <SidebarButton /> : <GoBackButton />}
          {currentPage === 'profile' ? null : <HeaderTitle title={titles} />}
        </HeaderLeft>
        {currentPage === 'profile' ? (
          <HeaderRight>
            <>
              <Controls />
              <Menu />
            </>
          </HeaderRight>
        ) : null}
        {currentPage === 'qr' ? (
          <HeaderRight>
            <Button className="qr-download" variant="icon">
              <Icon type="downloadQr" />
            </Button>
          </HeaderRight>
        ) : null}
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        <Switch>
          <PrivateRoute path={Pages.profile.activity} component={ActivityPageMobile} />
          <PrivateRoute path={Pages.profile.awards} component={AwardsPage} />
          <PrivateRoute path={Pages.profile.achievements} component={AwardsPage} />
          <PrivateRoute path={Pages.profile.titles} component={AwardsPage} />
          <PrivateRoute exact path={Pages.profile.qr} component={MobilePageQr} />
          <PrivateRoute exact path={Pages.profile.main} component={ProfilePage} />
        </Switch>
      </div>
    </>
  );
};

export default ProfileSectionMobile;
