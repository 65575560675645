import React from 'react';
import CustomCheckbox from '../../../../../../components/customCheckbox';
import CustomInput from '../../../../../../components/input';
import Button from '../../../../../../components/button';

import './styles.scss';

const getError = (errors, index) => {
  if (index === 0) {
    return errors?.sideF;
  }
  if (index === 1) {
    return errors?.sideS;
  }
  return null;
};

const SideSelect = ({
  sides,
  allowSideSelection,
  onChangeInput,
  onChangeCheckbox,
  onAddSide,
  onRemoveSide,
  errors,
}) => {
  return (
    <div className="side-select side-select__container">
      <div className="side-select__top">
        {sides.map((s, i) => {
          return (
            <div className="side-select__top-row" key={s.id}>
              <CustomInput
                extendedLabel={
                  i > 1 ? (
                    <Button
                      onClick={() => onRemoveSide(s.id)}
                      className="remove-side"
                      variant="text"
                    >
                      Удалить
                    </Button>
                  ) : null
                }
                name="title"
                value={s.title}
                onChange={onChangeInput}
                placeholder="Укажите название"
                label={`Сторона ${i + 1}`}
                labelPosition="top"
                data-field={s.id}
                required={i + 1 <= 2}
                error={getError(errors, i)}
              />
              <CustomInput
                name="qty"
                value={s.qty}
                labelPosition="top"
                data-field={s.id}
                label="Кол-во"
                onChange={onChangeInput}
                placeholder="Укажите кол-во"
                type="number"
              />
              <CustomInput
                name="excess"
                value={s.excess}
                labelPosition="top"
                data-field={s.id}
                label="Допустимое превышение"
                onChange={onChangeInput}
                placeholder="Укажите кол-во"
                type="number"
              />
            </div>
          );
        })}
        <Button onClick={onAddSide} variant="second">
          Добавить сторону
        </Button>
      </div>
      <div className="side-select__bottom">
        <CustomCheckbox
          name="choose_side"
          checked={allowSideSelection}
          onChange={onChangeCheckbox}
          label="Разрешить выбор стороны при подаче заявки"
        />
      </div>
    </div>
  );
};

export default SideSelect;
