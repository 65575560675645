import React from 'react';

import './styles.scss';
import Trans from '../../../../components/trans';

const SelectionTag = () => (
  <div className="selection__tag">
    <span className="selection__tag-text">{Trans('teams.main.recruiting')}</span>
  </div>
);

export default SelectionTag;
