import React, { useEffect } from 'react';
import CustomInput from '../../../components/input';

import './styles.scss';

const StepPassword = ({ onChange, passwords, error }) => {
  useEffect(() => {
    const target = document.querySelector('.input');
    if (target) {
      target.focus();
    }
  }, []);
  return (
    <div className="passwords">
      <p>Пароль должен содержать минимум 8 символов</p>
      <CustomInput
        tabIndex="0"
        value={passwords.password}
        onChange={onChange}
        placeholder="Старый пароль"
        name="password"
        type="password"
      />
      <CustomInput
        tabIndex="0"
        value={passwords.new_password}
        onChange={onChange}
        placeholder="Новый пароль"
        name="new_password"
        type="password"
      />
      <CustomInput
        tabIndex="0"
        value={passwords.re_new_password}
        onChange={onChange}
        placeholder="Новый пароль еще раз"
        name="re_new_password"
        type="password"
      />
      <span className="modal-content__text-error">{error}</span>
    </div>
  );
};

export default StepPassword;
