import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import history from '../routes/history';
import { store } from '../store';

import DumbModal from '../modals';
import WarningModal from '../modals/warningModal';
import CropperModal from '../modals/cropperModal';
import ReadAboutModal from '../modals/readAboutModal';
import SecurityModal from '../modals/securityModal';
import NotificationModal from '../modals/notificationModal';
import ChangeLinkModal from '../modals/changeLinks';
import BottomMenuModal from '../modals/bottomMenu';
import ConfirmModal from '../modals/confirmModal';
import CreateRequestTeamModal from '../modals/createRequestTeam';
import RecruitingRequestViewModal from '../modals/recruitingRequestView';
import ChangePasswordModal from '../modals/changePassword';
import AuthEmailConfirmModal from '../modals/authEmailConfirmed';
import RemoveProfileConfirmModal from '../modals/removeProfileConfirmed';
import AddCamouflageModal from '../modals/addCamouflage';
import BidSponsorModal from '../modals/bidSponsor';
import EventRegistrationModal from '../modals/eventRegistration';
import TermOfUseModal from '../modals/termOfUse';
import AddBlogAlbumModal from '../modals/addBlogAlbum';
import BlogAlbumModal from '../modals/blogAlbum';
import AddBlogVideoModal from '../modals/addBlogVideo';
import BlogVideoModal from '../modals/blogVideo';
import RequestGround from '../modals/requestGround';

const modalService = {
  id: 0,
  modals: {},
  open(options) {
    const target = document.createElement('div');
    document.body.appendChild(target);
    const { component: Component, props, onCancel, ...rest } = options;
    const { id } = this;
    this.modals[this.id] = target;
    const close = () => {
      if (onCancel) {
        onCancel();
      }
      this.close(id);
    };
    render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <DumbModal id={id} show {...rest} onCancel={close}>
            <Component {...props} onCancel={close} />
          </DumbModal>
        </ConnectedRouter>
      </Provider>,
      target,
    );
    // eslint-disable-next-line no-plusplus
    this.id++;
    return id;
  },
  close(id) {
    const container = this.modals[id];
    if (container) {
      unmountComponentAtNode(container);
      document.body.removeChild(container);
    }
    delete this.modals[id];
  },
  closeAll() {
    const keys = Object.keys(this.modals).map((key) => parseInt(key, 10));
    keys.forEach((key) => {
      this.close(key);
    });
  },
  openNotificationsModal: (props) =>
    modalService.open({
      component: NotificationModal,
      className: 'modal-notifications',
      props,
    }),
  openOversizeWarning: (props) =>
    modalService.open({
      component: WarningModal,
      className: 'modal-warning',
      props,
    }),
  openCropper: (props) =>
    modalService.open({
      component: CropperModal,
      className: 'modal-cropper',
      props,
    }),
  openReadMore: (props) =>
    modalService.open({
      component: ReadAboutModal,
      className: 'modal-read-about',
      props,
    }),
  openSecurityModal: (props) =>
    modalService.open({
      component: SecurityModal,
      className: 'modal-security',
      props,
    }),
  openChangeLinkModal: (props) =>
    modalService.open({
      component: ChangeLinkModal,
      className: 'modal-change-link',
      props,
    }),
  openBottomMenu: (props) =>
    modalService.open({
      component: BottomMenuModal,
      className: 'modal-bottom-menu',
      props,
    }),
  openConfirmModal: (props) =>
    modalService.open({
      component: ConfirmModal,
      className: 'modal-confirm',
      props,
    }),
  openCreateRequestTeamModal: (props) =>
    modalService.open({
      component: CreateRequestTeamModal,
      className: 'modal-create-request-team',
      props,
    }),
  openRecruitingRequestViewModal: (props) =>
    modalService.open({
      component: RecruitingRequestViewModal,
      className: 'modal-recruiting-request-view',
      props,
    }),
  openChangePasswordModal: (props) =>
    modalService.open({
      component: ChangePasswordModal,
      className: 'modal-change-password',
      props,
    }),
  openAuthEmailConfirm: (props) =>
    modalService.open({
      component: AuthEmailConfirmModal,
      className: 'modal-auth-email-confirm',
      shouldCloseOnOverlayClick: false,
      shouldCloseOnEsc: false,
      props,
    }),
  openRemoveProfile: (props) =>
    modalService.open({
      component: RemoveProfileConfirmModal,
      className: 'modal-remove-profile-confirm',
      props,
    }),
  openAddCamouflage: (props) =>
    modalService.open({
      component: AddCamouflageModal,
      className: 'modal-add-camouflage',
      props,
    }),
  openAddBlogAlbum: (props) =>
    modalService.open({
      component: AddBlogAlbumModal,
      className: 'modal-add-blog-album',
      props,
    }),
  openAddBlogVideo: (props) =>
    modalService.open({
      component: AddBlogVideoModal,
      className: 'modal-add-blog-video',
      props,
    }),
  openAlbum: (props) =>
    modalService.open({
      component: BlogAlbumModal,
      className: 'modal-album',
      props,
    }),

  openVideo: (props) =>
    modalService.open({
      component: BlogVideoModal,
      className: 'modal-video',
      props,
    }),
  openBidSponsor: (props) =>
    modalService.open({
      component: BidSponsorModal,
      className: 'modal-bid-sponsor',
      props,
    }),
  openGroundRequest: (props) =>
    modalService.open({
      component: RequestGround,
      className: 'modal-request-ground',
      props,
    }),
  openEventRegistration: (props) =>
    modalService.open({
      component: EventRegistrationModal,
      className: 'modal-event-registration',
      props,
    }),
  openTermOfUse: (props) =>
    modalService.open({
      component: TermOfUseModal,
      className: 'modal-term',
      props,
    }),
};

export default modalService;
