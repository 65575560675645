import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useActions, useSelector } from '../../../../hooks/store';
import { sponsorAction } from '../../../../store/sponsor';
import { rankENUM } from '../../../../helpers/utility';
import { globalCancelFetch } from '../../../../api/v2';

import SkeletonPersonnel from '../../components/skeletonPersonnel';
import SponsorUserCard from '../../components/sponsorUserCard';

import './styles.scss';
import Trans from '../../../../components/trans';

const PersonnelSponsorPage = () => {
  const { sponsorMembers, loading } = useSelector((state) => state.sponsor);
  const { userId } = useSelector((state) => state.auth);
  const { getSponsorMembers, onClearSponsorMembers } = useActions(sponsorAction);
  const { id } = useParams();

  useEffect(() => {
    getSponsorMembers(id);
    return () => {
      onClearSponsorMembers();
      if (globalCancelFetch) {
        globalCancelFetch();
      }
    };
  }, []);

  const commander = sponsorMembers && sponsorMembers.commander;
  const deputy = sponsorMembers && sponsorMembers.deputy;
  const groups = sponsorMembers && sponsorMembers.groups;
  const recruits = sponsorMembers && sponsorMembers.users;

  return (
    <div className="personnel-sponsor-page">
      {loading ? (
        <SkeletonPersonnel />
      ) : (
        <>
          <div className="personnel-sponsor-page__founder">
            {commander ? (
              <SponsorUserCard user={commander} rank={rankENUM.ORGANIZER} userId={userId} isLink />
            ) : null}

            {!isEmpty(deputy) &&
              deputy.map((d) => (
                <SponsorUserCard
                  key={d.id}
                  user={d}
                  rank={rankENUM.SUB_ORGANIZER}
                  userId={userId}
                  isLink
                />
              ))}
          </div>

          {!isEmpty(groups) &&
            groups.map((p) => (
              <>
                {(p.commander || !isEmpty(p.users)) && (
                  <div key={p.id} className="personnel-sponsor-page__platoon">
                    <h3 className="personnel-sponsor-page__title">{p.title}</h3>
                    <div className="personnel-sponsor-page__platoon-wrap">
                      {p.commander ? (
                        <SponsorUserCard
                          user={p.commander}
                          rank={rankENUM.PLAT_COMMANDER}
                          userId={userId}
                          isLink
                        />
                      ) : null}

                      {!isEmpty(p.users) &&
                        p.users.map((u) => (
                          <SponsorUserCard key={u.id} user={u} userId={userId} isLink />
                        ))}
                    </div>
                  </div>
                )}
              </>
            ))}

          {!isEmpty(recruits) && (
            <div className="personnel-sponsor-page__recruits">
              <h3 className="personnel-sponsor-page__title">{Trans('organizers.personnel.new')}</h3>
              <div className="personnel-sponsor-page__recruits-wrap">
                {!isEmpty(recruits) &&
                  recruits.map((r) => (
                    <SponsorUserCard
                      /*rank={r && r.team && r.team.title}*/ key={r.id}
                      user={r}
                      userId={userId}
                      isLink
                    />
                  ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PersonnelSponsorPage;
