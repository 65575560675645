import { catchWrapper } from '../../../helpers/utility';
import { postSaveSponsorMembers } from '../../../api/v2';

export default (sponsorId) => async (dispatch, getState) => {
  const { sponsorMembers } = getState().sponsor;

  dispatch({ type: 'SPONSOR/SAVE_SPONSOR_MEMBERS/REQUESTED' });

  const payload = {
    id: sponsorId,
    ...sponsorMembers,
  };

  const result = await catchWrapper(postSaveSponsorMembers(payload));

  if (result && result.result) {
    dispatch({ type: 'SPONSOR/SAVE_SPONSOR_MEMBERS/SUCCEEDED' });
  } else {
    dispatch({
      type: 'SPONSOR/SAVE_SPONSOR_MEMBERS/FAILED',
      payload: result && result.message && result.message[0],
    });
  }
};
