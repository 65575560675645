import { current } from '@reduxjs/toolkit';
import { Pages } from '../../../routes/pages';
import { getIdFromUrl } from '../../../helpers/utility';
import { userTypeEnum } from '../../../helpers/enums';
import history from '../../../routes/history';
import { useSelector } from '../../../hooks/store';

const navigationMap = () => {
  return [
    {
      id: '1',
      path: Pages.events.all,
      state: null,
      label: 'Игры',
    },
    // {
    //   id: '2',
    //   path: Pages.events.past,
    //   state: null,
    //   label: 'Прошедшие',
    // },
    // {
    //   id: '3',
    //   path: Pages.events.all,
    //   state: null,
    //   label: 'Все',
    // },
  ];
};

const navigationEvent = ({ eventId, userType, event }) => {
  // TODO : Добавить event.isScenario // event.isAloow и.т.п.
  return [
    {
      id: '1',
      path: Pages.event.main.replace(':id', eventId),
      state: eventId,
      label: 'Главная',
    },
    {
      id: '2',
      path: Pages.event.scenario.replace(':id', eventId),
      state: eventId,
      label: 'Сценарий',
      isVisible: true,
      hiddenRequest: true,
    },
    {
      id: '3',
      path: Pages.event.participants.replace(':id', eventId),
      state: eventId,
      label: 'Участники',
      isVisible: true,
      hiddenRequest: false,
    },
    {
      id: '4',
      path: Pages.event.price.replace(':id', eventId),
      state: eventId,
      label: 'Цены',
      isVisible: true,
      hiddenRequest: true,
    },
    {
      id: '5',
      path: Pages.event.allow.replace(':id', eventId),
      state: eventId,
      label: 'Допуск',
      isVisible: true,
      hiddenRequest: true,
    },
    // {
    //   id: '6',
    //   path: Pages.event.agreement.replace(':id', eventId),
    //   state: eventId,
    //   label: 'Соглашение',
    //   isVisible: true,
    //   hiddenRequest: true,
    //   // hash: '#entry',
    // },
    {
      id: '7',
      path: Pages.event.info.replace(':id', eventId),
      state: eventId,
      label: 'Инфо',
      isVisible: true,
      hiddenRequest: true,
      hash: '#rules',
    },
    {
      id: '8',
      path: Pages.event.location.replace(':id', eventId),
      state: eventId,
      label: 'Полигон',
      isVisible: true,
      hiddenRequest: true,
    },
    // {
    //   id: '9',
    //   path: Pages.event.results.replace(':id', eventId),
    //   state: eventId,
    //   label: 'Результаты',
    //   isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
    //   hiddenRequest: true,
    // },
    {
      id: '10',
      path: Pages.event.offer.replace(':id', eventId),
      state: eventId,
      label: 'Оферта',
      isVisible: true,
      hiddenRequest: true,
      // hash: '#entry',
    },
  ];
};

const navigationEventMobile = ({ eventId, userType, event }) => {
  // TODO : Добавить event.isScenario // event.isAloow и.т.п.
  return [
    {
      id: '1',
      path: Pages.event.main.replace(':id', eventId),
      state: eventId,
      label: 'Главная',
    },
    {
      id: '2',
      path: Pages.event.scenario.replace(':id', eventId),
      state: eventId,
      label: 'Сценарий',
      isVisible: true,
      hiddenRequest: true,
    },
    {
      id: '3',
      path: Pages.event.participants.replace(':id', eventId),
      state: eventId,
      label: 'Участники',
      isVisible: true,
      hiddenRequest: false,
    },
    {
      id: '4',
      path: Pages.event.price.replace(':id', eventId),
      state: eventId,
      label: 'Цены',
      isVisible: true,
      hiddenRequest: true,
    },
    {
      id: '5',
      path: Pages.event.allow.replace(':id', eventId),
      state: eventId,
      label: 'Допуск',
      isVisible: true,
      hiddenRequest: true,
    },
    // {
    //   id: '6',
    //   path: Pages.event.agreement.replace(':id', eventId),
    //   state: eventId,
    //   label: 'Соглашение',
    //   isVisible: true,
    //   hiddenRequest: true,
    //   // hash: '#entry',
    // },
    {
      id: '7',
      path: Pages.event.info.replace(':id', eventId),
      state: eventId,
      label: 'Инфо',
      isVisible: true,
      hiddenRequest: true,
      hash: '#rules',
      children: [
        {
          id: '11',
          label: 'Правила',
          path: Pages.event.info.replace(':id', eventId),
          hash: '#rules',
          state: eventId,
        },
        {
          id: '12',
          label: 'Правила доп.',
          path: Pages.event.info.replace(':id', eventId),
          hash: '#rulesAdditional',
          state: eventId,
        },
      ],
    },
    {
      id: '8',
      path: Pages.event.location.replace(':id', eventId),
      state: eventId,
      label: 'Полигон',
      isVisible: true,
      hiddenRequest: true,
    },
    // {
    //   id: '9',
    //   path: Pages.event.results.replace(':id', eventId),
    //   state: eventId,
    //   label: 'Результаты',
    //   isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
    //   hiddenRequest: true,
    // },
    {
      id: '10',
      path: Pages.event.offer.replace(':id', eventId),
      state: eventId,
      label: 'Оферта',
      isVisible: true,
      hiddenRequest: true,
      // hash: '#entry',
    },
  ];
};

const navigationSettingEvent = ({ eventId, userType }) => {
  return [
    {
      id: '1',
      path: Pages.event.settings.edit.replace(':id', eventId),
      state: eventId,
      label: 'Главная',
    },
    {
      id: '2',
      path: Pages.event.settings.scenario.replace(':id', eventId),
      state: eventId,
      label: 'Сценарий',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    {
      id: '4',
      path: Pages.event.settings.price.replace(':id', eventId),
      state: eventId,
      label: 'Цены',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    {
      id: '5',
      path: Pages.event.settings.allow.replace(':id', eventId),
      state: eventId,
      label: 'Допуск',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    {
      id: '6',
      path: Pages.event.settings.info.replace(':id', eventId),
      state: eventId,
      label: 'Инфо',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
      hash: '#rulesAdditional',
    },
    {
      id: '8',
      path: Pages.event.settings.location.replace(':id', eventId),
      state: eventId,
      label: 'Полигон',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    // {
    //   id: '9',
    //   path: Pages.event.settings.results.replace(':id', eventId),
    //   state: eventId,
    //   label: 'Результаты',
    //   isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
    //   hiddenRequest: true,
    // },
  ];
};

const navigationSettingEventMobile = ({ eventId, userType }) => {
  return [
    {
      id: '1',
      path: Pages.event.settings.edit.replace(':id', eventId),
      state: eventId,
      label: 'Главная',
    },
    {
      id: '2',
      path: Pages.event.settings.scenario.replace(':id', eventId),
      state: eventId,
      label: 'Сценарий',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    {
      id: '4',
      path: Pages.event.settings.price.replace(':id', eventId),
      state: eventId,
      label: 'Цены',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    {
      id: '5',
      path: Pages.event.settings.allow.replace(':id', eventId),
      state: eventId,
      label: 'Допуск',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    {
      id: '6',
      path: Pages.event.settings.info.replace(':id', eventId),
      state: eventId,
      label: 'Инфо',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
      hash: '#rulesAdditional',
      children: [
        {
          id: '11',
          label: 'Правила',
          path: Pages.event.info.replace(':id', eventId),
          hash: '#rules',
          state: eventId,
        },
        {
          id: '12',
          label: 'Правила доп.',
          path: Pages.event.info.replace(':id', eventId),
          hash: '#rulesAdditional',
          state: eventId,
        },
      ],
    },
    {
      id: '8',
      path: Pages.event.settings.location.replace(':id', eventId),
      state: eventId,
      label: 'Полигон',
      isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
      hiddenRequest: true,
    },
    // {
    //   id: '9',
    //   path: Pages.event.settings.results.replace(':id', eventId),
    //   state: eventId,
    //   label: 'Результаты',
    //   isVisible: userType === userTypeEnum.developer || userType === userTypeEnum.admin,
    //   hiddenRequest: true,
    // },
  ];
};

export const getEventMaps = ({
  location,
  isEventSettingPages,
  isAllEventsPage,
  userType,
  event,
  isMobile,
}) => {
  if (isAllEventsPage) {
    return navigationMap();
  }

  if (isEventSettingPages && !isMobile) {
    return navigationSettingEvent({ eventId: getIdFromUrl(location.pathname), userType });
  }
  if (isEventSettingPages && isMobile) {
    return navigationSettingEventMobile({ eventId: getIdFromUrl(location.pathname), userType });
  }
  if (event && !isMobile)
    return navigationEvent({ eventId: getIdFromUrl(location.pathname), userType, event });
  if (event && isMobile)
    return navigationEventMobile({ eventId: getIdFromUrl(location.pathname), userType, event });
};

export const getEventSettingPath = (location, eventId) => {
  const path = location?.hash ? `${location?.pathname}${location?.hash}` : location?.pathname;
  const hash = location?.hash || null;

  switch (path) {
    case Pages.event.main.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.edit.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.scenario.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.scenario.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.participants.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.participants.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.price.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.price.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.allow.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.allow.replace(':id', eventId),
        state: eventId,
      });

    case `${Pages.event.info.replace(':id', eventId)}${hash}`:
      return history.push({
        pathname: Pages.event.settings.info.replace(':id', eventId),
        state: eventId,
        hash,
      });

    case Pages.event.rules.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.rules.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.location.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.location.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.gallery.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.gallery.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.results.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.settings.results.replace(':id', eventId),
        state: eventId,
      });

    default:
      return history.push({
        pathname: Pages.event.settings.edit.replace(':id', eventId),
        state: eventId,
      });
  }
};

export const getEventPath = (location, eventId) => {
  const path = location?.hash ? `${location?.pathname}${location?.hash}` : location?.pathname;
  const hash = location?.hash || null;

  switch (path) {
    case Pages.event.settings.edit.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.main.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.settings.scenario.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.scenario.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.settings.participants.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.participants.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.settings.price.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.price.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.settings.allow.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.allow.replace(':id', eventId),
        state: eventId,
      });

    case `${Pages.event.settings.info.replace(':id', eventId)}${hash}`:
      return history.push({
        pathname: Pages.event.info.replace(':id', eventId),
        state: eventId,
        hash,
      });

    case Pages.event.settings.rules.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.rules.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.settings.location.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.location.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.settings.gallery.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.gallery.replace(':id', eventId),
        state: eventId,
      });

    case Pages.event.settings.results.replace(':id', eventId):
      return history.push({
        pathname: Pages.event.results.replace(':id', eventId),
        state: eventId,
      });

    default:
      return history.push({
        pathname: Pages.event.main.replace(':id', eventId),
        state: eventId,
      });
  }
};
