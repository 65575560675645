import React from 'react';
import { Link } from 'react-router-dom';
import he from 'he';

import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Pages } from '../../../../routes/pages';
import Trans from '../../../../components/trans';

const OrgCard = ({ sponsor, userId }) => {
  const sponsorId = sponsor && sponsor.id;
  const commanderId = sponsor && sponsor.commander.id;
  const { t } = useTranslation();

  const pic = sponsor && sponsor.images && sponsor.images.image && sponsor.images.image.preview;
  const title = he.decode(sponsor && sponsor.title);
  const qtyOfGames = sponsor && sponsor.qty_of_games;
  const qtyOfTeams = sponsor && sponsor.qty_of_teams;

  const orgUrl = {
    pathname: Pages.organizers.main.replace(':id', sponsorId),
    state: sponsorId,
  };

  const commanderLink = {
    pathname:
      userId === commanderId ? Pages.profile.base : Pages.profile.main.replace(':id?', commanderId),
    state: commanderId,
  };

  const gamesLink = {
    pathname: Pages.organizers.events.replace(':id', sponsorId),
    state: sponsorId,
  };

  const teamsLink = {
    pathname: Pages.organizers.participants.replace(':id', sponsorId),
    state: sponsorId,
  };

  return (
    <div className="card org-card">
      <div className="org-card__left">
        <Link to={orgUrl}>
          <div className="org-card__image_wrap">{pic ? <img src={pic} alt="" /> : null}</div>
        </Link>
      </div>
      <div className="org-card__right">
        <Link to={orgUrl}>
          <span className="org-card__title">{title}</span>
        </Link>
        <Link to={commanderLink}>
          <span className="org-card__text">{`${t('organizers.personnel.leader')}: ${
            sponsor?.commander?.nickname || ''
          }`}</span>
        </Link>
        <Link to={gamesLink}>
          <span className="org-card__text">
            {`${t('organizers.navigation.games')}: `} {qtyOfGames}
          </span>
        </Link>
        <Link to={teamsLink}>
          <span className="org-card__text">{`${t('organizers.navigation.members')}: `}</span>
        </Link>
      </div>
    </div>
  );
};

export default OrgCard;
