import React, { useState } from 'react';
import Icon from '../../../components/icon';
import './styles.scss';
import Button from '../../../components/button';
import { classNames as cx } from '../../../helpers/utility';
import Comment from '../comment';

const Comments = ({ userData }) => {
  const [isShow, setIsShow] = useState(false);
  const handleToggle = () => setIsShow(!isShow);
  const classNamesParent = cx('feed-filter', {
    'feed-filter__open': isShow,
  });

  return (
    <div className={classNamesParent}>
      <div>
        <Button onClick={handleToggle} variant="submenu">
          <div className="comments__content">
            <span className="comments__title">12 комментариев</span>
            <Icon type="chevron" />
          </div>
        </Button>
        {isShow ? <Comment userData={userData} /> : null}
      </div>
    </div>
  );
};

export default Comments;
