import React from 'react';
import RadioGroup from '../../../../../components/radioGroup';

const GalleryToggle = ({ options, toggleType, type }) => {
  return (
    <div className="gallery-toggle">
      <RadioGroup onChange={toggleType} options={options} variant="second" value={type} />
    </div>
  );
};

export default GalleryToggle;
