import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import useVisible from '../../../../hooks/useVisible';
import history from '../../../../routes/history';

import { classNames as cx, getUrlToNavi } from '../../../../helpers/utility';

import Icon from '../../../icon';

import './styles.scss';
import Trans from '../../../trans';

const MobileNavigation = ({ navigationMap }) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const [selected, setSelected] = useState(navigationMap && navigationMap[0]);

  const { location: loc } = history;

  useEffect(() => {
    if (loc.pathname !== (selected && selected.path) && navigationMap) {
      const tmp = navigationMap.find((o) => o.path === loc.pathname);
      setSelected(tmp);
    }
  }, [loc.pathname]);

  const handleToggleMenu = () => setIsVisible(!isVisible);
  const handleChangeValue = (elem) => {
    setSelected(elem);
    setIsVisible(false);
  };

  const classNames = cx('mobile-navigation', {
    'mobile-navigation__open': isVisible,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={classNames} ref={ref} onClick={handleToggleMenu}>
      <div className="mobile-navigation__content">
        <span className="mobile-navigation__title">{Trans(selected?.label) || ''}</span>
        <Icon type="chevron" />
      </div>
      {isVisible ? (
        <div className="mobile-navigation__drop">
          <ul>
            {navigationMap &&
              navigationMap.map((item) => (
                <li key={item.id}>
                  <NavLink
                    isActive={(match, location) => {
                      if (location?.hash) return false;
                      return match;
                    }}
                    className="mobile-navigation__link"
                    activeClassName="mobile-navigation__link_active"
                    to={getUrlToNavi(item)}
                    onClick={() => handleChangeValue(item)}
                    key={item.id}
                    strict
                  >
                    <div className="mobile-navigation__link__row">
                      <span className="mobile-navigation__link__text">{Trans(item.label)}</span>
                    </div>
                  </NavLink>
                  {item?.children ? (
                    <ul>
                      {item?.children?.map((subItem) => (
                        <li key={subItem.id}>
                          <NavLink
                            isActive={(match, location) => subItem.hash === location.hash}
                            className="mobile-navigation__link"
                            activeClassName="mobile-navigation__link_active"
                            to={getUrlToNavi(subItem)}
                            onClick={() => handleChangeValue(subItem)}
                            key={subItem.id}
                            strict
                          >
                            <div className="mobile-navigation__link__row">
                              <span className="mobile-navigation__link__text__sub">
                                {Trans(subItem.label)}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default MobileNavigation;
