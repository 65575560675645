import React from 'react';

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9614 17.114C18.1463 16.3178 18.9257 14.9664 18.9257 13.4333C18.9257 10.9849 16.9377 9 14.4853 9C12.033 9 10.0451 10.9849 10.0451 13.4333C10.0451 14.9664 10.8245 16.3178 12.0093 17.114C11.3688 17.3394 10.7655 17.6583 10.2348 18.0746C9.03038 19.0193 8.23157 20.4445 8.23157 22.3C8.23157 22.6866 8.54547 23 8.93268 23H20.1503V22.9911C20.4841 22.9374 20.7392 22.6485 20.7392 22.2999C20.7392 20.4444 19.9404 19.0193 18.7359 18.0746C18.2054 17.6583 17.602 17.3394 16.9614 17.114ZM6.67237 23H3.61227C3.27412 23 3 22.7275 3 22.3913C3 20.7778 3.6976 19.5385 4.74939 18.717C5.21283 18.355 5.73973 18.0777 6.29912 17.8817C5.26443 17.1894 4.58374 16.0143 4.58374 14.6812C4.58374 12.5521 6.31986 10.8261 8.46146 10.8261C8.83261 10.8261 9.19159 10.8779 9.53144 10.9747C9.12326 11.754 8.89352 12.6339 8.89352 13.5652C8.89352 14.7292 9.25239 15.8129 9.86995 16.7201C9.67929 16.8545 9.49413 16.998 9.31521 17.1504C7.76756 18.4695 6.72711 20.4415 6.67237 23ZM21.9598 23H25.3878C25.7259 23 26 22.7275 26 22.3913C26 20.7778 25.3024 19.5385 24.2506 18.717C23.7872 18.355 23.2603 18.0777 22.7009 17.8817C23.7356 17.1894 24.4163 16.0143 24.4163 14.6812C24.4163 12.5521 22.6801 10.8261 20.5386 10.8261C20.1711 10.8261 19.8156 10.8769 19.4787 10.9719C19.8934 11.7518 20.1268 12.6328 20.1268 13.5652C20.1268 14.7836 19.7283 15.9139 19.0477 16.8461C19.1728 16.9432 19.2953 17.0446 19.4149 17.1504C20.9052 18.4695 21.907 20.4415 21.9598 23Z"
      fill="currentColor"
    />
  </svg>
);
