import React, { useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useResponsive } from '../../../hooks/useResponsive';
import { Pages } from '../../../routes/pages';

import Icon from '../../../components/icon';
import SocialButtons from '../social';
import Skeleton from '../../../components/skeleton';
import AccountHolder from '../../../helpers/accountHolder';

import './styles.scss';
import RenderProfileName from './renderName';

const UserInfo = ({ user, selfId, services, loading }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (!isEmpty(user.social_service) && !isEmpty(services)) {
      let count = 0;
      services.forEach((item) => {
        if (user.social_service[item.key].value) {
          count += 1;
        }
      });
      setCounter(count);
    }
  }, [services, user.social_service]);

  const { isTabletLarge, isDesktop } = useResponsive();
  const isShowFio = user?.is_show_fio;
  return (
    <div className="card user-info">
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div className="user-info__left">
            <div className="user-info__left__row">
              <RenderProfileName user={user} selfId={selfId} isShowFio={isShowFio} />
            </div>
            <div className="user-info__left__row">
              <Icon type="phoneNoBox" />
              {user.phone ? (
                <span className="user-info__left__value">{user.phone}</span>
              ) : (
                <AccountHolder>
                  <Link className="user-info__user-no-data" to={Pages.settings.security}>
                    Укажите телефон
                  </Link>
                </AccountHolder>
              )}
            </div>
            <div className="user-info__left__row">
              <Icon type="emailNoBox" />
              {user.email ? (
                <span className="user-info__left__value">{user.email}</span>
              ) : (
                <AccountHolder>
                  <Link className="user-info__user-no-data" to={Pages.settings.security}>
                    Укажите почту
                  </Link>
                </AccountHolder>
              )}
            </div>
          </div>
          {isTabletLarge || isDesktop ? (
            <div className="user-info__right">
              <div className="user-info__right__wrap">
                <span className="user-info__right__title">Ссылки</span>
                {counter ? (
                  <SocialButtons social={user.social_service} services={services} />
                ) : (
                  <AccountHolder>
                    <Link className="user-info__user-no-data" to={Pages.settings.links}>
                      Добавить ссылки на соц сети
                    </Link>
                  </AccountHolder>
                )}
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default memo(UserInfo);
