import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../../routes/privateRoute';
import { Pages } from '../../../routes/pages';
import history from '../../../routes/history';

import SettingsNavigationsMobile from './navigation';
import SettingsProfileMobile from './profile';

import SettingsLinksMobile from './links';
import SettingsPrivateMobile from './private';
import SettingsSecurityMobile from './security';
import HeaderLeft from '../../../components/headerConstructor/components/left';
import GoBackButton from '../../../components/headerConstructor/components/goBackButton';
import HeaderTitle from '../../../components/headerConstructor/components/title';
import Button from '../../../components/button';
import HeaderConstructor from '../../../components/headerConstructor';
import HeaderRight from '../../../components/headerConstructor/components/right';

import SettingPassport from '../desktop/passport';

import './styles.scss';

const getTitle = (url) => {
  switch (url) {
    case Pages.settings.navi:
      return 'Настройки';
    case Pages.settings.profile:
      return 'Профиль';
    case Pages.settings.passport:
      return 'Игровой паспорт';
    case Pages.settings.links:
      return 'Ссылки';
    case Pages.settings.private:
      return 'Приватность';
    case Pages.settings.security:
      return 'Безопасность';
    default:
      return 'Настройки';
  }
};

const SettingsPageMobile = ({ navigationMap, onSubmit, disabled, onUserClone, isShowButton }) => {
  const { location } = history;
  return (
    <>
      <HeaderConstructor className="settings mobile">
        <HeaderLeft>
          <GoBackButton action={onUserClone} />
          <HeaderTitle
            title={getTitle(location.pathname)}
            noWrap={location.pathname === Pages.settings.passport}
          />
        </HeaderLeft>
        <HeaderRight>
          {isShowButton ? (
            <Button onClick={onSubmit} disabled={disabled} secondVariant="text" variant="done">
              Готово
            </Button>
          ) : null}
        </HeaderRight>
      </HeaderConstructor>
      <div className="page-container page-container--mobile">
        <Switch>
          <PrivateRoute exact path={Pages.settings.navi} component={SettingsNavigationsMobile} />
          <PrivateRoute exact path={Pages.settings.profile} component={SettingsProfileMobile} />
          <PrivateRoute exact path={Pages.settings.links} component={SettingsLinksMobile} />
          <PrivateRoute exact path={Pages.settings.private} component={SettingsPrivateMobile} />
          <PrivateRoute exact path={Pages.settings.security} component={SettingsSecurityMobile} />
          <PrivateRoute exact path={Pages.settings.passport} component={SettingPassport} />
          <Redirect to={navigationMap[0].path} />
        </Switch>
      </div>
    </>
  );
};

export default SettingsPageMobile;
